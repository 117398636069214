// Packages
import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';

// Utils
import abbreviate from 'common/src/app/util/locale/abbreviate';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

// Enums
import {
  measurementFields,
  userDetailFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import { MemberTypeStrings } from 'common/src/app/data/enum/MemberType';
import Configuration from 'common/src/app/config/Configuration';

// Components
import FormGroup from 'components/molecules/FormGroup';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import Toggle from 'components/atoms/Toggle';
import WeightMeasurementUnit from 'components/molecules/WeightMeasurementUnit';
import TextNew, { Colors } from 'components/atoms/TextNew';
import InformationToggle from 'components/molecules/InformationToggle';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import FormValueProvider from 'common/src/app/enhanced-redux-form/components/FormValueProvider';
import Wrapper from 'components/atoms/Wrapper';
import CheckBox from 'components/molecules/CheckBox';

import './weight-input.scss';
/**
 * Weight input, including InformationToggle and Unit selector
 * Membertype - the type of member you are displaying this message to e.g. 'group' or 'online
 * Used to switch out BMI information message
 */
const WeightInput = (
  {
    deviceState,
    memberType,
    labelLocale,
    fieldName,
    fieldInfoLocale,
    showCurrentWeightCheckbox,
    viewSettings,
    forwardRef,
    ...props
  },
  {},
  className,
) => {
  const isGroup = memberType === MemberTypeStrings.GROUP;
  const { unitToggle, displayInfoButton, fieldLabel } = viewSettings;

  return (
    <FormGroup className={className} forwardRef={forwardRef}>
      <Row className="weight-toggle" alignend={isGroup} spacebetween>
        <Row>
          <Wrapper cid="optional-label-row">
            {fieldLabel && (
              <legend className="atom-label">
                <LocaleMessage id={labelLocale} />
              </legend>
            )}
            {showCurrentWeightCheckbox && (
              <EnhancedField name={userDetailFields.USE_CURRENT_WEIGHT} component={CheckBox}>
                <TextNew.PrimaryOriginal localeId="targetWeight.useCurrentWeight" />
              </EnhancedField>
            )}
          </Wrapper>
        </Row>

        {unitToggle && (
          <Row spacebetween={isGroup}>
            <EnhancedField
              name={measurementFields.WEIGHT_UNIT}
              component={Toggle}
              legend={<LocaleMessage id="weightInput.legend" />}
              leftlabel={
                Configuration.imperialWeightUseStones ? (
                  <LocaleMessage
                    id={`general.measureUnit.weight.stone.${abbreviate(deviceState)}`}
                  />
                ) : (
                  <LocaleMessage
                    id={`general.measureUnit.weight.pounds.${abbreviate(deviceState)}`}
                  />
                )
              }
              leftname={MeasureUnit.IMPERIAL}
              rightlabel={
                <LocaleMessage
                  id={`general.measureUnit.weight.kilogram.${abbreviate(deviceState)}`}
                />
              }
              rightname={MeasureUnit.METRIC}
              cid="toggle-weight-units"
            />
          </Row>
        )}
      </Row>
      <FormValueProvider fields={[measurementFields.WEIGHT_UNIT]}>
        <WeightMeasurementUnit name={fieldName} abbreviate={true} {...props} />
      </FormValueProvider>

      <FormErrorMessages fields={[fieldName]} />

      {displayInfoButton && (
        <InformationToggle
          label={<LocaleMessage id="weightInput.label" />}
          information={<LocaleMessage id={fieldInfoLocale || `weightInput.info.${memberType}`} />}
        />
      )}
    </FormGroup>
  );
};

WeightInput.propTypes = {
  deviceState: PropTypes.number.isRequired,
  memberType: PropTypes.string,
  labelLocale: PropTypes.string,
  viewSettings: PropTypes.shape({
    unitToggle: PropTypes.bool,
    displayInfoButton: PropTypes.bool,
    fieldLabel: PropTypes.bool,
  }),
  fieldName: PropTypes.string,
  fieldInfoLocale: PropTypes.string,
  showCurrentWeightCheckbox: PropTypes.bool,
  forwardRef: PropTypes.func,
};

WeightInput.defaultProps = {
  viewSettings: {
    unitToggle: true,
    displayInfoButton: true,
    fieldLabel: true,
  },
  memberType: MemberTypeStrings.ONLINE,
  labelLocale: 'medicalCheck.groupLabels.currentWeight',
  fieldName: measurementFields.INITIAL_WEIGHT,
  showCurrentWeightCheckbox: false,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'WeightInput')(WeightInput),
);
