import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import InputType from 'common/src/app/data/enum/InputType';
import { addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { countryCode } from 'common/src/app/data/enum/Countries';
import states from 'common/src/app/data/statedata';
import InputNew from '../../../../../atoms/InputNew';
import Select from '../../../../../../../Select';

/**
 * Address component that creates either a Select for US and Eire or a text input for all other countries
 */
const Counties = (
  { isLoading, irishCounties, getIrishCounties, activeCountry, county, formProps, errorMessage },
  { getMessage },
) => {
  useEffect(() => {
    if (!isLoading && activeCountry === countryCode.IRELAND && irishCounties?.length === 0) {
      getIrishCounties();
    }
  }, [activeCountry]);

  const addressField =
    activeCountry === countryCode.US ? addressFields.STATE : addressFields.COUNTY;

  const placeHolder = getMessage(`addressForm.placeholders.${addressField}`);

  const countyOptions = activeCountry === countryCode.US ? states : irishCounties;

  return (
    <Fragment key={addressField}>
      {activeCountry === countryCode.US || (activeCountry === countryCode.IRELAND && !isLoading) ? (
        <Select
          name={addressField}
          value={county || ''}
          options={countyOptions}
          placeholder={getMessage(`addressFormGroup.placeholders.countyStateSelect`, {
            FIELD_NAME: placeHolder,
          })}
          aria-label={placeHolder}
          suppress
          {...formProps}
        />
      ) : (
        <Field
          component={InputNew}
          stacked
          name={addressField}
          value={county}
          type={InputType.TEXT}
          data-hj-suppress={true}
          placeholder={placeHolder}
          aria-label={placeHolder}
          {...formProps}
          data-testid={addressField}
        />
      )}
      {errorMessage &&
        React.cloneElement(errorMessage, {
          errorName: placeHolder,
          name: addressField,
        })}
    </Fragment>
  );
};

Counties.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

Counties.propTypes = {
  isLoading: PropTypes.bool,
  activeCountry: PropTypes.string,
  county: PropTypes.string,
  errorMessage: PropTypes.node,
  formProps: PropTypes.object,
  irishCounties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  getIrishCounties: PropTypes.func,
};

export default Counties;
