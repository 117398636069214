import React from 'react';
import PropTypes from 'prop-types';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import TextNew, { FontSizes } from '../../../../atoms/TextNew';

const MedicalCheckGreeting = ({ firstName, deviceState }) => (
  <TextNew.Serif
    localeId="medicalCheck.greeting"
    localeParams={firstName ? { NAME: firstName } : {}}
    suppress
    size={deviceState <= DeviceState.MD ? FontSizes.MD : FontSizes.XL}
    cid="title"
  />
);

MedicalCheckGreeting.propTypes = {
  firstName: PropTypes.string,
  deviceState: PropTypes.number,
};

export default withDeviceState()(MedicalCheckGreeting);
