import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import MultiFactorAuthenticationVerify from './MultiFactorAuthenticationVerify';

const mapStateToProps = (state, { location }) => ({
  location,
});

const connected = connect(mapStateToProps);

export default compose(connected)(MultiFactorAuthenticationVerify);
