import handleActions from 'redux-actions/lib/handleActions';
import { REMOVE_PURCHASE_PAYMENT } from '../actions/resources/paymentActions';

/**
 * NOTE: We do not import this constant from paymentResponseActions because
 * we are not allowed to import this action file in the web build.
 */
const SET_PAYMENT_RESPONSE = 'paymentResponseActions/SET_PAYMENT_RESPONSE';

/**
 * This reducer contains the payment response as parsed from the location query,
 * if it is present. Otherwise, this reducer will be empty.
 *
 * @function paymentReducer
 * @private
 */
const paymentReducer = handleActions(
  {
    [SET_PAYMENT_RESPONSE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [REMOVE_PURCHASE_PAYMENT]: state => ({
      ...state,
      purchaseId: null,
    }),
  },
  {},
);

export default paymentReducer;
