import React from 'react';
import LocaleMessage from '../../../../../../LocaleMessage';
import InputNew from '../../../../atoms/InputNew';
import TextNew, { TextTypes } from '../../../../../../TextNew';
import HeightInputLabel from '../HeightInputLabel';

type HeightInputMetricProps = {
  abbreviate: boolean;
  disabled?: boolean;
  values: {
    metres?: number | null;
    centimetres?: number | null;
  };
};

const HeightInputMetric = ({
  abbreviate,
  disabled,
  values,
  ...restProps
}: HeightInputMetricProps): JSX.Element => (
  <>
    <InputNew
      data-testid="metres"
      disabled={disabled}
      descriptor="static"
      name="metres"
      type="number"
      min="0"
      max="2"
      placeholder="0"
      value={values?.metres || ''}
      label={
        <>
          <span className="screen-reader">
            <LocaleMessage id="general.measureUnit.height.metres.label" />
          </span>
        </>
      }
      {...restProps}
      field={undefined} // Typescript incorrectly inferred that field is required. Setting to undefined to satisfy TS error.
    />
    <TextNew type={TextTypes.FORMAL} ariaHidden cid="dot">
      .
    </TextNew>
    <InputNew
      data-testid="centimetres"
      descriptor="static"
      name="centimetres"
      type="number"
      min="0"
      max="99"
      placeholder="00"
      value={values?.centimetres || ''}
      label={
        <>
          <span aria-hidden="true">
            <HeightInputLabel heightUnit="metres" abbreviate={abbreviate} />
          </span>
          <span className="screen-reader">
            <LocaleMessage id="general.measureUnit.height.centimetres.label" />
          </span>
        </>
      }
      disabled={disabled}
      {...restProps}
      field={undefined} // Typescript incorrectly inferred that field is required. Setting to undefined to satisfy TS error.
    />
  </>
);

HeightInputMetric.defaultProps = {
  abbreviate: true,
};

export default HeightInputMetric;
