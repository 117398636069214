import handleActions from 'redux-actions/lib/handleActions';

import { TOGGLE_COLLAPSE, SET_COLLAPSE } from 'common/src/app/actions/components/filterActions';

const searchReducer = handleActions(
  {
    [TOGGLE_COLLAPSE]: state => ({
      isCollapsed: !state.isCollapsed,
      changedByUser: true,
    }),
    [SET_COLLAPSE]: (state, { payload }) => ({
      ...state,
      isCollapsed: !state.changedByUser ? payload : state.isCollapsed,
    }),
  },
  {
    isCollapsed: true,
    changedByUser: false,
  },
);

export default searchReducer;
