import React, { useEffect, EffectCallback } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { compose } from 'redux';
import Pages from 'common/src/app/data/enum/Pages';

import Loader from 'components/atoms/Loader';

type TransferToOnlineRedirectProps = { memberHost: string };

const TransferToOnlineRedirect = (
  { memberHost }: TransferToOnlineRedirectProps,
  {},
  className: string,
): JSX.Element => {
  useEffect((): ReturnType<EffectCallback> => {
    if (window?.location) {
      window.location.href = `${memberHost}${Pages.ACCOUNT_SETTINGS_SUBSCRIPTION_STATUS}`;
    }
  }, [memberHost]);

  return (
    <div className={className}>
      <Loader />
    </div>
  );
};

export default compose(withFunctionalClassName(ComponentType.PAGE, 'TransferToOnlineRedirect'))(
  TransferToOnlineRedirect,
);
