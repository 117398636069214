import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';

const EMPTY_ARRAY = [];

/**
 * Gets the subscription package features
 */

export const getSubscriptionPackageFeaturesSelector = createSelector(
  [state => state?.entities?.packageFeatures?.featureItem],
  featureItem =>
    (featureItem &&
      Object.values(featureItem).filter(({ packageFeatureText }) => !isNil(packageFeatureText))) ||
    EMPTY_ARRAY,
);

export default getSubscriptionPackageFeaturesSelector;
