import React from 'react';
import PropTypes from 'prop-types';
import MeasureType from 'common/src/app/data/enum/MeasureType';
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import MeasurementCompositeInput from '../../organisms/MeasurementCompositeInput';

const HeightMeasurementUnit = ({ formValues: { heightUnit }, ...props }) => (
  <MeasurementCompositeInput
    unit={heightUnit}
    type={MeasureType.HEIGHT}
    name={measurementFields.HEIGHT}
    {...props}
  />
);
HeightMeasurementUnit.propTypes = {
  formValues: PropTypes.shape({
    heightUnit: PropTypes.number,
  }),
};

export default HeightMeasurementUnit;
