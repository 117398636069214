import React from 'react';
import PropTypes from 'prop-types';
import TextNew from 'components/atoms/TextNew';

const ReadOnlyField = ({ input }) => (
  <TextNew.PrimaryOriginal>{input.value}</TextNew.PrimaryOriginal>
);

ReadOnlyField.propTypes = {
  input: PropTypes.object,
};

export default ReadOnlyField;
