/* global WP_DEFINE_DEVELOPMENT */
import Cookies from 'js-cookie';
import { INDEX_KEY, KEY_PREFIX } from '../../app/data/persistCookieSettings';

/**
 * Storage engine for redux-persist, which will store secure cookies from the browser.
 *
 * Uses callback syntax for compatibility with redux-persist
 *
 * Note: only the `INDEX_KEY` is prefixed with `KEY_PREFIX`, because redux-persist will prefix
 * the other keys for us
 */
class ReduxPersistClientCookieStorage {
  useSecure = !WP_DEFINE_DEVELOPMENT && window.location.protocol === 'https:';

  // eslint-disable-next-line class-methods-use-this
  getItem = (key, callback) => {
    callback(null, Cookies.get(key) || 'null');
  };

  // eslint-disable-next-line class-methods-use-this
  setItem = (key, value, callback) => {
    Cookies.set(key, value, { secure: this.useSecure });

    this.getAllKeys((error, allKeys) => {
      if (!allKeys.includes(key)) {
        allKeys.push(key);
        Cookies.set(`${KEY_PREFIX}${INDEX_KEY}`, allKeys, { secure: this.useSecure });
      }
      callback(null);
    });
  };

  removeItem = (key, callback) => {
    Cookies.remove(key);

    this.getAllKeys((error, allKeys) => {
      Cookies.set(
        `${KEY_PREFIX}${INDEX_KEY}`,
        allKeys.filter(k => k !== key),
      );
      callback(null);
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getAllKeys = callback => callback(null, Cookies.getJSON(`${KEY_PREFIX}${INDEX_KEY}`) || []);
}

export default ReduxPersistClientCookieStorage;
