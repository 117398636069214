import handleActions from 'redux-actions/lib/handleActions';
import { STORE_IDENTITY } from 'common/src/app/actions/storeIdentity';

const initialState = {
  user: null,
};

export default handleActions(
  {
    [STORE_IDENTITY]: (state, { payload: user }) => ({
      ...state,
      user,
    }),
  },
  initialState,
);
