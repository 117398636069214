import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import LocaleMessage from '../LocaleMessage';
import TextNew, { Colors } from '../TextNew';
import './error-type.scss';

/**
 * Offset when scrolling to the error message. This is to make sure any input
 * above the error message is also visible
 * @type {number}
 */
const SCROLL_TO_OFFSET = -70;

const EMPTY_ARRAY = [];

/**
 * Component that displays errors in the `enhancedReduxForm()` it is nested in. When
 * using the wrapped version, the errors will automatically be pulled from redux state
 * using the `connect()` wrapper. This version expects different props than the
 * unconnected version:
 *  - *fields* An array of string field names that determine for which fields errors
 *  should be shown
 *  - *showGeneral* If true, will also display general form error messages (as opposed to
 *  just the message bound to specific form fields).
 */
class FormErrorMessages extends PureComponent {
  state = {
    filteredErrors: EMPTY_ARRAY,
  };

  componentDidMount() {
    this.filterErrors(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.filterErrors(this.props);
    }
  }

  filterErrors(props) {
    const { errors = [], showGeneral, generalError } = props;

    const filteredErrors = errors
      .filter(error => error.message)
      .concat(showGeneral && generalError ? [{ field: '__general', message: generalError }] : []);

    if (!this.state.filteredErrors.length && filteredErrors.length && this.rootElement) {
      // there was no error, but a new error appeared. Scroll to this element
      this.props.scrollToElement(this.rootElement, SCROLL_TO_OFFSET);
    }

    this.setState({
      filteredErrors: filteredErrors.length ? filteredErrors : EMPTY_ARRAY,
    });
  }

  render() {
    const { children, id } = this.props;
    const { filteredErrors } = this.state;

    if (!filteredErrors.length) {
      return <span ref={root => (this.rootElement = root)} />;
    }

    let content = null;

    if (Children.count(children) > 0) {
      content = <TextNew.Error color={Colors.BRAND}>{children}</TextNew.Error>;
    } else {
      content = filteredErrors.map(error => {
        let errorMessage = error.message;
        if (typeof error.message === 'object') {
          errorMessage = (
            <LocaleMessage id={error.message.locale} params={error.message.params || {}} />
          );
        }
        return (
          <TextNew.Error color={Colors.BRAND} key={error.field}>
            {errorMessage}
          </TextNew.Error>
        );
      });
    }

    return (
      <div
        id={id}
        {...componentClassNameProp(ComponentType.ATOM, this)}
        ref={root => (this.rootElement = root)}
      >
        {content}
      </div>
    );
  }
}

FormErrorMessages.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  /**
   * List of errors to display. This is passed by the `connect()` wrapper
   */
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          locale: PropTypes.string.isRequired,
          params: PropTypes.objectOf(PropTypes.any),
        }),
      ]),
      code: PropTypes.string,
      field: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * If true, will also display general form error messages (as opposed to just the message
   * bound to specific form fields).
   */
  showGeneral: PropTypes.bool,
  /**
   * A general error on the form (if it exists). This is passed by the `connect()` wrapper
   */
  generalError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      params: PropTypes.objectOf(PropTypes.any),
    }),
  ]),
  /**
   * Function to scroll to a specific element. This is passed by the `connect()` wrapper
   */
  scrollToElement: PropTypes.func.isRequired,
  /**
   * If children are passed, that content is shown instead of the actual error message. The
   * children will only be rendered if at least 1 error is present.
   */
  children: PropTypes.node,
  id: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
};

export default FormErrorMessages;
