/* eslint-disable import/prefer-default-export */
import createTransform from 'redux-persist/lib/createTransform';
import omit from 'lodash/omit';
import get from 'lodash/get';
import set from 'lodash/set';
import pickBy from 'lodash/pickBy';
import unset from 'lodash/unset';
import forIn from 'lodash/forIn';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import FormNames from '../data/enum/FormNames';

function filterObject({ path, filterFunction = () => true }, state) {
  const value = get(state, path, state);

  if (value instanceof Array) {
    return value.filter(filterFunction);
  }

  return pickBy(value, filterFunction);
}

export function persistFilter(state, paths = [], transformType = 'whitelist') {
  let subset = {};

  // support only one key
  if (typeof paths === 'string') {
    // eslint-disable-next-line no-param-reassign
    paths = [paths];
  }

  if (transformType === 'whitelist') {
    paths.forEach(path => {
      if (typeof path === 'object' && !(path instanceof Array)) {
        const value = filterObject(path, state);

        if (!isEmpty(value)) {
          set(subset, path.path, value);
        }
      } else {
        const value = get(state, path);

        if (typeof value !== 'undefined') {
          set(subset, path, value);
        }
      }
    });
  } else if (transformType === 'blacklist') {
    subset = cloneDeep(state);
    paths.forEach(path => {
      if (typeof path === 'object' && !(path instanceof Array)) {
        const value = filterObject(path, state);

        if (!isEmpty(value)) {
          if (value instanceof Array) {
            set(
              subset,
              path.path,
              get(subset, path.path, subset).filter(() => false),
            );
          } else {
            // eslint-disable-next-line no-shadow
            forIn(value, (value, key) => {
              unset(subset, `${path.path}[${key}]`);
            });
          }
        } else {
          subset = value;
        }
      } else {
        const value = get(state, path);

        if (typeof value !== 'undefined') {
          unset(subset, path);
        }
      }
    });
  } else {
    subset = state;
  }

  return subset;
}

export function createFilter(
  reducerName,
  inboundPaths,
  outboundPaths,
  transformType = 'whitelist',
) {
  return createTransform(
    // inbound
    inboundState =>
      inboundPaths ? persistFilter(inboundState, inboundPaths, transformType) : inboundState,

    // outbound
    outboundState =>
      outboundPaths ? persistFilter(outboundState, outboundPaths, transformType) : outboundState,

    { whitelist: [reducerName] },
  );
}

export const shopBasketForm = createFilter('form', [FormNames.SHOP_BASKET]);

export const viewFilter = createTransform((inboundState, key) => {
  if (key !== 'view') return inboundState;
  return omit(inboundState, ['components.lottie', 'components.modal', 'collectionPagination']);
});
