// These statuses apply to both actvity goals & activities themselves

const ActivityStatus = {
  /**
   * Commited - Planned, in progress, or elapsed but not explicitly forfeited - this is the
   * base status for a new goal/award and won't change without some kind of
   * intervention
   */
  COMMITTED: 0,
  /**
   * Achieved - All criteria for the award/goal have been met. Set by adding the
   * activity that finally satisfies the goal criteria (edited)
   */
  ACHIEVED: 1,
  /**
   * forfeited -The member has explicitly decided to cancel doing the award
   * * Important this status is only valid for goals!
   */
  FORFEITED: 2,
};

export default ActivityStatus;

export const IS_BLOCKING_ERROR = 'isBlockingError';
