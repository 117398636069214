import lowerFirst from 'lodash/lowerFirst';
import { createSelector } from 'reselect';
import { makeCollectionSelector } from './collectionSelector';
import { BOOKMARKS } from '../data/collectionIds';
import { BOOKMARKS_PAGINATION_LIMIT } from '../actions/resources/bookmarkActions';

export const bookmarksCollectionSelector = makeCollectionSelector();

export const bookmarksOfTypeSelector = createSelector(
  state => bookmarksCollectionSelector(state, { collectionId: BOOKMARKS }).entityRefs,
  (state, props) => props.tileType,
  (state, props) => props.page || 0,
  (state, props) => props.limit || BOOKMARKS_PAGINATION_LIMIT,
  (allBookmarks, tileType, page, limit) => {
    const tilesOfType = tileType
      ? allBookmarks.filter(bookmark => bookmark.type === lowerFirst(tileType))
      : allBookmarks;

    const numTilesVisible = limit * (page + 1);
    const hasMore = numTilesVisible < tilesOfType.length;
    const articles = tilesOfType
      .slice(0, numTilesVisible)
      .map(tile => tile.data)
      .filter(_ => _);

    return { hasMore, articles, total: tilesOfType.length };
  },
);
