import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  PASSWORD_DIGIT_REGEX,
  PASSWORD_LETTER_REGEX,
  PASSWORD_MIN_MAX_REGEX,
} from 'common/src/app/data/regexPatterns';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { passwordFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import FormNames from 'common/src/app/data/enum/FormNames';
import passwordValidationConfigShape from 'common/src/app/validation/passwordValidationConfigShape';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import FormGroup from '../FormGroup';
import PasswordInput from '../../atoms/PasswordInput';
import PasswordRequirement from '../PasswordRequirement';
import LocaleMessage from '../../atoms/LocaleMessage';
import Row from '../../atoms/Row';

const GroupedPasswordInput = (
  { customPasswordValidation, showAll, label, formName, forwardRef, passwordValidationConfig },
  { getMessage },
) => {
  const passwordField =
    formName === FormNames.PERSONAL_DETAILS ? passwordFields.PASSWORD : passwordFields.NEW_PASSWORD;

  return (
    <>
      <FormGroup label={label} type="stacked" cid="password-strength">
        <Row column>
          {showAll && (
            <>
              <PasswordInput
                name={passwordFields.OLD_PASSWORD}
                placeholder={getMessage('groupedPasswordInput.form.oldPlaceholder')}
                showVisibilityToggle
              />
              <FormErrorMessages fields={[passwordFields.OLD_PASSWORD]} />
            </>
          )}
          <PasswordInput
            placeholder={getMessage('groupedPasswordInput.form.enterPlaceholder')}
            name={passwordField}
            showVisibilityToggle
            inputRef={forwardRef?.passwordRef}
          />
          {customPasswordValidation && customPasswordValidation}
          <PasswordInput
            placeholder={getMessage('groupedPasswordInput.form.confirmPlaceholder')}
            name={passwordFields.CONFIRM_PASSWORD}
            visibilityFrom={passwordField}
            inputRef={forwardRef?.confirmPasswordRef}
          />
          <FormErrorMessages fields={[passwordFields.CONFIRM_PASSWORD]} />
        </Row>
      </FormGroup>
      <PasswordRequirement
        formName={formName}
        passwordField={passwordField}
        passwordValidationConfig={passwordValidationConfig}
      />
    </>
  );
};

GroupedPasswordInput.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

GroupedPasswordInput.propTypes = {
  showAll: PropTypes.bool,
  label: PropTypes.object,
  customPasswordValidation: PropTypes.node,
  formName: PropTypes.string,
  forwardRef: PropTypes.object,
  passwordValidationConfig: passwordValidationConfigShape,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'GroupedPasswordInput',
)(GroupedPasswordInput);
