/* global 'template' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import Link from 'react-router/lib/Link';
import TextNew from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import FormGroup from 'components/molecules/FormGroup';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import CheckBox from 'components/molecules/CheckBox';
import Input from 'components/atoms/Input';
import PasswordInput from 'components/atoms/PasswordInput';
import Button from 'components/atoms/Button';
import Pages, {
  GROUP_REGISTRATION_FIRST_STEP,
  REGISTRATION_FIRST_STEP,
} from 'common/src/app/data/enum/Pages';
import { loginFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import LoginErrorCode from 'common/src/app/data/enum/LoginErrorCode';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import InputType from 'common/src/app/data/enum/InputType';
import AccountFrozenModalContent from 'components/atoms/AccountFrozenModalContent';
import errorBoundary from 'components/hoc/ErrorBoundary';
import { compose } from 'redux';
import WebHostLink from 'components/atoms/WebHostLink';
import WebHost from 'common/src/app/data/enum/WebHost';
import LocalStorageKeys from 'common/src/app/data/enum/BrowserStorageKeys';
import LegacyAppInfo from '../LegacyAppInfo';
import './login.scss';

const renderRegisterLink = (isWebview, isGroupRegOpen) => {
  // hide register link for apple submission
  if (isWebview) {
    return null;
  }

  const options = [
    { locale: 'login.linkToGroupReg', link: GROUP_REGISTRATION_FIRST_STEP, hide: !isGroupRegOpen },
    {
      locale: 'login.linkToOnlineRegistration',
      link: REGISTRATION_FIRST_STEP,
    },
    {
      locale: 'login.findNearestGroup',
      link: Pages.UK_PUBLIC_NEAREST_GROUP_LANDING,
      host: WebHost.PUBLIC,
    },
  ];

  return options.map(
    item =>
      !item.hide && (
        <Row justifycenter key={item.link}>
          <TextNew.Formal>
            <WebHostLink link={item.link} webHost={item.host || WebHost.ACCOUNT}>
              <LocaleMessage id={item.locale} />
            </WebHostLink>
          </TextNew.Formal>
        </Row>
      ),
  );
};

const renderForgotPasswordLink = isWebView => {
  let linkProps;

  if (isWebView) {
    linkProps = {
      to: {
        pathname: Pages.FORGOT_PASSWORD,
        state: { prevPath: Pages.LOGIN },
      },
    };
  } else {
    linkProps = {
      to: Pages.FORGOT_PASSWORD,
    };
  }

  return (
    <TextNew.Formal>
      <Link {...linkProps}>
        <LocaleMessage id="login.linkToForgotPassword" />
      </Link>
    </TextNew.Formal>
  );
};

class Login extends PureComponent {
  componentDidMount() {
    const { setInitialValuesOnMount, isWebView } = this.props;
    setInitialValuesOnMount && setInitialValuesOnMount(isWebView);

    /**
     * Workaround to fix the issue where the user could navigate back to this page from the
     * mfa/register and  mfa/verify page
     *
     * Somehow the server does not have the correct identity state when you navigate back to
     * the previous page
     */
    if (localStorage.getItem(LocalStorageKeys.MFA)) {
      localStorage.removeItem(LocalStorageKeys.MFA);
      window.location.reload();
    }
  }

  componentDidUpdate({ generalErrorCode, openModal, redirectToLegacyLoginFallbackPage }) {
    if (generalErrorCode === LoginErrorCode.FROZEN_IS_Pregnant) {
      openModal(
        ModalNames.CONTENT,
        {
          showWrapperCloseButton: false,
          children: <AccountFrozenModalContent />,
        },
        false,
        false,
        true,
      );
    }

    // User is trying to login with a legacy member
    if (this.props.legacyLoginAttempt) {
      redirectToLegacyLoginFallbackPage();
    }
  }

  render() {
    const { isWebView, submitting, handleSubmit, isGroupRegOpen, legacyLoginAttempt } = this.props;
    const { getMessage } = this.context;

    return (
      <form
        {...componentClassNameProp('template', this, ['isWebView'])}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {!isWebView && <TextNew.Loud localeId="login.title" />}

        <FormGroup label={<LocaleMessage id="login.loginFormHeader" />} type="stacked">
          <Row column>
            <EnhancedField
              name={loginFields.USER_NAME}
              placeholder={getMessage('login.usernamePlaceholder')}
              id="username"
              component={Input}
              type={InputType.TEXT}
              autocomplete="off"
              suppress
            />
            {!legacyLoginAttempt && (
              <FormErrorMessages id="formUsernameError" fields={[loginFields.USER_NAME]} />
            )}
            <PasswordInput
              placeholder={getMessage('login.passwordPlaceholder')}
              name={loginFields.PASSWORD}
              id="password"
              showVisibilityToggle
              autocomplete="off"
            />
            <FormErrorMessages id="formPasswordError" fields={[loginFields.PASSWORD]} />
          </Row>
        </FormGroup>

        {legacyLoginAttempt ? <LegacyAppInfo homepage /> : <FormErrorMessages showGeneral />}

        <Row spacebetween className="keep-logged-in">
          <EnhancedField name={loginFields.REMEMBER_ME} component={CheckBox}>
            <TextNew.PrimaryOriginal localeId="login.keepLoggedIn" />
          </EnhancedField>
          {!isWebView && renderForgotPasswordLink()}
        </Row>

        <Row justifycenter>
          <Button
            ripple
            type="submit"
            id="signIn"
            disabled={submitting || legacyLoginAttempt}
            isLoading={submitting}
          >
            <LocaleMessage id="login.signIn" />
          </Button>
        </Row>

        {renderRegisterLink(isWebView, isGroupRegOpen)}

        {isWebView && <Row justifycenter>{renderForgotPasswordLink(isWebView)}</Row>}
      </form>
    );
  }
}

Login.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

Login.propTypes = {
  isWebView: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  legacyLoginAttempt: PropTypes.bool,
  setInitialValuesOnMount: PropTypes.func,
  redirectToLegacyLoginFallbackPage: PropTypes.func,
  openModal: PropTypes.func,
  generalErrorCode: PropTypes.string,
  isGroupRegOpen: PropTypes.bool.isRequired,
};

export default compose(errorBoundary({ wholePageError: true }))(Login);
