import { connect } from 'react-redux';
import { setMemberType } from 'common/src/app/actions/resources/accountActions';
import DualAccess from './DualAccess';

const mapDispatchToProps = (dispatch, ownProps) => {
  const redirectUri = ownProps.location.query.redirect_uri;
  return {
    continueAsOnline: () =>
      dispatch(setMemberType('online')).then(() => (window.location = redirectUri)),
    continueAsGroup: () =>
      dispatch(setMemberType('group')).then(() => (window.location = redirectUri)),
  };
};

const connected = connect(null, mapDispatchToProps);

export default connected(DualAccess);
