import React from 'react';
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { change } from 'redux-form';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import { openModal } from 'common/src/app/actions/components/modalActions';
import * as ValidateOn from 'common/src/app/enhanced-redux-form/data/ValidateOn';
import getRegisteredFields from 'common/src/app/enhanced-redux-form/utils/getRegisteredFields';
import {
  validateForm,
  clearValidation,
} from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import isFieldRegistered from 'common/src/app/enhanced-redux-form/utils/isFieldRegistered';
import { initialMedicalCheckValuesSelector } from 'common/src/app/selectors/registrationSelector';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import {
  ERROR_AGE_NOT_ALLOWED,
  ERROR_BMI_NOT_ALLOWED,
  ERROR_IS_PREGNANT,
  ERROR_BMI_TOO_LOW,
  ERROR_HAS_MEDICAL_CONDITIONS,
  ERROR_HAS_READ_CONDITIONS_DISCLAIMER,
  ERROR_HAS_EATING_DISORDER,
} from 'common/src/app/data/validationErrorCodes';
import formValueSelector from 'redux-form/lib/formValueSelector';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import FormNames from 'common/src/app/data/enum/FormNames';
import { GROUP } from 'common/src/app/data/enum/registrationFlowTypes';
import { isOnRegistrationFlow } from 'common/src/app/selectors/registrationFlowTypeSelector';
import {
  userDetailFields,
  medicalFields,
  measurementFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';

import { isEligibleToRegisterAsAFree2GoMember } from 'common/src/app/util/ageUtils/ageUtils';
import { setRegistrationFree2Go } from 'common/src/app/actions/registrationActions';
import { isFree2GoRegisteringMember } from 'common/src/app/selectors/free2GoSelector';

import {
  dateOfBirthVal,
  pregnancy,
  simpleMedical,
  hasReadConditionsDisclaimer,
  hasEatingDisorder,
  initialWeight,
  height,
} from 'common/src/app/validation/accountValidation';
import MedicalCheck from './MedicalCheck';

const medicalCheckValidation = {
  ...createSimpleRequiredValidation(userDetailFields.FIRST_NAME),
  ...createSimpleRequiredValidation(userDetailFields.LAST_NAME),
  ...dateOfBirthVal,
  ...createSimpleRequiredValidation(userDetailFields.GENDER, ValidateOn.CHANGE),
  ...pregnancy,
  ...createSimpleRequiredValidation(userDetailFields.IS_BREASTFEEDING, ValidateOn.CHANGE),
  ...simpleMedical,
  ...hasReadConditionsDisclaimer,
  ...hasEatingDisorder,
  ...initialWeight(FormNames.MEDICAL_CHECK),
  ...height(FormNames.MEDICAL_CHECK),
};

const errorScrollOrder = [
  userDetailFields.FIRST_NAME,
  userDetailFields.LAST_NAME,
  userDetailFields.DATE_OF_BIRTH,
  userDetailFields.GENDER,
  userDetailFields.IS_PREGNANT,
  medicalFields.HAS_EATING_DISORDER,
  medicalFields.HAS_MEDICAL_CONDITIONS,
  measurementFields.INITIAL_WEIGHT,
  measurementFields.HEIGHT,
];

const enhanced = enhancedReduxForm(
  {
    form: FormNames.MEDICAL_CHECK,
    destroyOnUnmount: false,
  },
  medicalCheckValidation,
);

const getErrorsForFields = makeGetErrorsForFields();

const formSelector = formValueSelector(FormNames.MEDICAL_CHECK);

const introductionSelector = formValueSelector(FormNames.INTRODUCTION);

const mapStateToProps = state => {
  const blockingErrors = [
    ERROR_AGE_NOT_ALLOWED,
    ERROR_IS_PREGNANT,
    ERROR_HAS_EATING_DISORDER,
    ERROR_HAS_MEDICAL_CONDITIONS,
    ERROR_HAS_READ_CONDITIONS_DISCLAIMER,
  ];
  const isGroupRegister = isOnRegistrationFlow(state, GROUP);
  const errors = getErrorsForFields(state.enhancedForm.validation, {
    form: FormNames.MEDICAL_CHECK,
    fields: [
      userDetailFields.DATE_OF_BIRTH,
      userDetailFields.IS_PREGNANT,
      medicalFields.HAS_MEDICAL_CONDITIONS,
      medicalFields.HAS_EATING_DISORDER,
      medicalFields.HAS_READ_CONDITIONS_DISCLAIMER,
      measurementFields.INITIAL_WEIGHT,
      measurementFields.HEIGHT,
    ],
  });

  const isFree2GoRegistration = isFree2GoRegisteringMember(state) || false;

  const registeredFields = getRegisteredFields(state, FormNames.MEDICAL_CHECK);

  if (!isGroupRegister()) {
    blockingErrors.push(ERROR_IS_PREGNANT, ERROR_BMI_NOT_ALLOWED, ERROR_BMI_TOO_LOW);
  }

  return {
    isGroupRegister: isGroupRegister(),
    medicalCheckValidation,
    initialValues: {
      groupSearchPostcode: '',
      weightUnit: 0,
      heightUnit: 0,
      ...(isGroupRegister() ? initialMedicalCheckValuesSelector(state) : {}),
    },
    hasBlockingError: errors.some(
      error =>
        error.code &&
        blockingErrors.includes(error.code) &&
        isFieldRegistered(registeredFields, error.field),
    ),
    hasBmiError: errors.some(
      error => error.code === ERROR_BMI_TOO_LOW || error.code === ERROR_BMI_NOT_ALLOWED,
    ),
    publicHost: state.config.environmentConfig.web.public.host,
    isFree2GoRegistration,
    dateOfBirth: formSelector(state, userDetailFields.DATE_OF_BIRTH),
    firstName:
      formSelector(state, userDetailFields.FIRST_NAME) ||
      introductionSelector(state, userDetailFields.FIRST_NAME),
    errorScrollOrder,
    compositeErrors: state.enhancedForm.validation?.medicalCheck?.hasCompositeError,
  };
};

const mapDispatchToProps = dispatch => ({
  resetFemaleOnlyData: () => {
    dispatch(change(FormNames.MEDICAL_CHECK, userDetailFields.IS_PREGNANT, null));
    dispatch(clearValidation(FormNames.MEDICAL_CHECK, userDetailFields.IS_PREGNANT));
    dispatch(change(FormNames.MEDICAL_CHECK, userDetailFields.IS_BREASTFEEDING, null));
    dispatch(clearValidation(FormNames.MEDICAL_CHECK, userDetailFields.IS_BREASTFEEDING));
  },
  setInitialDisclaimer: () =>
    dispatch(change(FormNames.MEDICAL_CHECK, medicalFields.HAS_READ_CONDITIONS_DISCLAIMER, false)),
  setFree2GoState: dateOfBirth => {
    // Check that they are elegible for free to go membership
    if (dateOfBirth && isEligibleToRegisterAsAFree2GoMember(dateOfBirth)) {
      dispatch(setRegistrationFree2Go(true));
    } else {
      // free to go flow must be false
      dispatch(setRegistrationFree2Go(false));
    }
  },
  validateStep: () => dispatch(validateForm(medicalCheckValidation, FormNames.MEDICAL_CHECK)),
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected, enhanced)(MedicalCheck);
