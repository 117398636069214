/* global 'template','DualAccess' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew from 'components/atoms/TextNew';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import Configuration from 'common/src/app/config/Configuration';
import './dual-access.scss';

const DualAccess = ({ continueAsOnline, continueAsGroup }, {}, className) => (
  <div className={className}>
    <header>
      <TextNew.Confident localeId="dualAccess.title" />
    </header>

    <TextNew.PrimaryOriginal localeId="dualAccess.subTitle" />

    <Row spacebetween>
      <Button
        ripple
        type="button"
        onClick={continueAsGroup}
        params={{
          GROUP_SERVICE: Configuration.groupServiceName,
        }}
      >
        <LocaleMessage id="dualAccess.group" />
      </Button>
      <Button ripple type="button" onClick={continueAsOnline}>
        <LocaleMessage id="dualAccess.online" />
      </Button>
    </Row>
  </div>
);

DualAccess.propTypes = {
  continueAsGroup: PropTypes.func,
  continueAsOnline: PropTypes.func,
};

export default withFunctionalClassName('template','DualAccess')(DualAccess);
