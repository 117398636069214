/**
 * Properties that used to be market-specific before the removal of the USA, but are now common to all.
 * These should be migrated to more appropriate enums over time.
 */

export default {
  amFormat: 'am',
  // the at risk BMI that shows a warning below it
  atRiskBMI: 20,
  brandColor: 'ff1541',
  breastfeedingArticle: '/features/breastfeeding',
  ctaButtonColor: 'dark-tone',
  // the BMI that shows a warning below it
  dangerousBMI: 18.5,
  dateDayMonthFormat: 'ddddDoMMMM',
  dateDayMonthYearFormat: 'DoMMMMYYYY',
  dateDayShortMonthYearFormat: 'DMMMYYYY',
  dateFormat: 'DDMMYYYY',
  defaultRegion: 1,
  defaultSynsEstimatorPortionMeasurementUnit: 0,
  defaultWeightUnit: 0,
  excludedFilters: {
    recipes: {
      foodOptimizing: ['LessThan5Ingredients', 'FamilyFavourites', 'Vegetarian'],
      cuisine: ['Greek', 'MiddleEastern', 'French'],
      mealType: ['Snack', 'Appetizer', 'SaucesAndCondiments'],
      seasonal: [
        'NewYear',
        'Thanksgiving',
        'ValentinesDay',
        'Spring',
        'StPatricksDay',
        'Easter',
        'Summer',
        'fall',
      ],
      cookingType: ['Grilling', 'NoCook'],
    },
    features: {
      features: ['FoodOptimizing'],
      seasonal: ['Thanksgiving', 'NewYear', 'Winter', 'Spring', 'Fall'],
    },
    successStories: {
      stories: ['GeneralWellBeing'],
    },
  },
  favicon: 'heart',
  generalButtonColor: 'mid-tone',
  groupServiceName: 'LifelineOnline',
  hasGroups: true,
  hasRegionPicker: true,
  headerBackgroundColor: 'primary-light',
  imperialWeightUseStones: true,
  // when set to true, it will include a final step in the weighIn to post a personal note
  includePersonalNoteInWeighIn: false,
  legacyLoginUrl: 'https://login.slimmingworld.co.uk',
  logoAvatarAdmin: 'ADMIN_ICON_UK',
  logoIconName: 'logo-classic',
  logoMobileIconName: 'logo-classic-mobile',
  magazineEditorialEmail: 'editorial@slimmingworld.co.uk',
  menuFixed: false,
  onlineServiceName: 'Slimming World Online',
  pageTitle: 'Slimming World',
  pmFormat: 'pm',
  publicRelationsContactEmail: 'julian.oiller@slimmingworld.co.uk',
  publicRelationsContactNumber: '07770 807760',
  publicRelationsGeneralEmail: 'public.relations@slimmingworld.co.uk',
  redirectRecipeAliases: false,
  // the minimal BMI you need to pass registration online
  registerBMI: 23,
  /**
   * This setting determines whether or not the user is prompted to record the reason for skipping
   * a weigh-in for the current week. When this is false the skipping reason is set as
   * NOT_REQUESTED (4)
   */
  selectIconName: 'chevron',
  showCountryInAddressForm: true,
  showHallOfFameOptIn: false,
  showInterimTargetModalSection: false,
  // This setting determines whether or not the user is prompted to import legacy weigh-in history
  legacyImportEnabled: false,
  smartBanner: {
    member: {
      title: 'SW Online',
      id: '1380628670',
    },
    planner: {
      title: 'SW Planner',
      id: '1250889779',
    },
  },
  /**
   * The syn values are used in the syns allowance block and the modal in the Slimming World
   * Essentials page. The values are needed for showing the correct syn values between the gender
   *
   * See SynsAllowanceBlock.js for the exact usage
   */
  synValue: [
    {
      weight: 0,
      male: 25,
      female: 15,
    },
    {
      weight: 101.605,
      male: 30,
      female: 20,
    },
    {
      weight: 127.006,
      male: 35,
      female: 25,
    },
  ],
  // the minimal BMI you need to pass group to online transfer
  uniqueAddressShop: true,
  useDefaultStepIndicatorInWeighIn: true,
  useGlobalTimeZones: true,
  voucherPhoneNumber: '0344 892 0400',
  // the minimal BMI to allow you to weigh-in
  weighInBMI: 17,
  earliestJourneyStart: '2000-01-03T00:00:00Z',
  icons: {
    comment: 'comment-border',
    commented: 'commented-filled',
    like: 'heart-like-border',
    liked: 'heart-liked-filled',
    profile: 'person-border',
    swEssentials: 'menu-foodlists',
    healthyExtraA: 'healthy-extra-a',
  },
  meta: {
    title: 'Slimming World',
    description:
      "If you'd like to lose weight - without ever feeling hungry - welcome to Slimming World. We help thousands of members achieve their weight loss dreams - you can too.",
    twitter: {
      site: '@SlimmingWorld',
    },
    facebook: {
      site: 'Slimming World',
      locale: 'en_GB',
    },
    themeColor: '#d33a29',
  },
};
