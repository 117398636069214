import MultiFactorAuthenticationType from 'common/src/app/data/enum/MultiFactorAuthenticationType';
import { apiGet, apiPost } from './apiActions/apiRequest';
import { loginCallback } from './accountActions';
import { GATEWAY_ACCOUNT_WITHOUT_PATH } from '../../data/Injectables';

/**
 * Get authenticator key for manual QR code register
 */
export const GET_AUTHENTICATOR_DETAILS =
  'multiFactorAuthenticationActions/GET_AUTHENTICATOR_DETAILS';
export const getAuthenticatorDetails = () => dispatch =>
  dispatch(
    apiGet(GET_AUTHENTICATOR_DETAILS, GATEWAY_ACCOUNT_WITHOUT_PATH, '/mfa/authenticator-details'),
  );

/**
 * Action to either register or verify the 6 digit code
 *
 * @type {string}
 */
export const MFA_REGISTER_OR_VERIFY = 'multiFactorAuthenticationActions/MFA_REGISTER_OR_VERIFY';
export const mfaRegisterOrVerify =
  (values, postType = MultiFactorAuthenticationType.REGISTER) =>
  dispatch =>
    dispatch(
      apiPost(MFA_REGISTER_OR_VERIFY, GATEWAY_ACCOUNT_WITHOUT_PATH, `/mfa/${postType}`, {
        ...values,
      }),
    );

export const mfaLoginCallBack = location => (dispatch, getState) =>
  loginCallback(getState(), location);
