// Constants used for conversion
const POUNDS_IN_A_KILOGRAM = 2.20462262185;
const POUNDS_IN_A_STONE = 14;
const CM_IN_AN_INCH = 2.54;
const CM_IN_A_METRE = 100;
const INCHES_IN_A_FOOT = 12;
const MILES_IN_A_KILOMETRE = 0.62137119223733;
const DAYS_IN_A_YEAR = 365;

/**
 * Converts inches to feet & inches
 *
 * @param {number|string} [inches] - The value to be converted
 * @returns {(number[]|undefined)} [feet, fullInches, remainingInches]
 */
export const inchesToFeet = (inches?: number | string): number[] | undefined =>
  parseAndConvert(inches, parsedValue => {
    let feet = Math.trunc(parsedValue / INCHES_IN_A_FOOT);

    const inchesWithoutFeet = parsedValue % INCHES_IN_A_FOOT;
    let fullInches = Math.trunc(inchesWithoutFeet);
    let remainingInches = nearestHalf(inchesWithoutFeet % 1) ?? 0;

    if (remainingInches === 1) {
      fullInches += 1;
      remainingInches = 0;
    }

    // If, due to metric to imperial conversion and rounding, we end up with more than 11 full inches
    // then add an extra foot and subtract a foot from fullInches.
    if (fullInches >= INCHES_IN_A_FOOT) {
      feet += 1;
      fullInches -= INCHES_IN_A_FOOT;
    }

    return [feet, fullInches, remainingInches];
  });

/**
 * Converts inches to centimetres
 *
 * @param {(number|string)} [inches] - The value to be converted
 * @returns {(number|undefined)} Value converted to centimetres
 */
export const inchesToCentimeters = (inches?: number | string): number | undefined =>
  parseAndConvert(inches, parsedValue => parsedValue * CM_IN_AN_INCH);

/**
 * Converts centimetres to inches
 *
 * @param {(number|string)} [centimetres] - The value to be converted
 * @returns {(number|undefined)} Value converted to inches
 */
export const centimetersToInches = (centimetres?: number | string): number | undefined =>
  parseAndConvert(centimetres, parsedValue => parsedValue / CM_IN_AN_INCH);

/**
 * Converts centimetres to metres & centimetres
 *
 * @param {(number|string)} [centimetres] - The value to be converted
 * @returns {(number[]|undefined)} [metres, centimetres]
 */
export const centimetresToMetres = (centimetres?: number | string): number[] | undefined =>
  parseAndConvert(centimetres, parsedValue => {
    let metres = Math.trunc(parsedValue / CM_IN_A_METRE);
    let centimetreRemainder = Number((parsedValue % CM_IN_A_METRE).toFixed());

    if (centimetreRemainder >= CM_IN_A_METRE) {
      metres += 1;
      centimetreRemainder -= CM_IN_A_METRE;
    }

    return [metres, centimetreRemainder];
  });

/**
 * Converts pounds to kilograms
 *
 * @param {(number|string)} [pounds] - The value to be converted
 * @returns {(number|undefined)} Value converted to kilograms
 */
export const poundsToKilograms = (pounds?: number | string): number | undefined =>
  parseAndConvert(pounds, parsedValue => parsedValue / POUNDS_IN_A_KILOGRAM);

/**
 * Converts kilograms to pounds
 *
 * @param {(number|string)} [kilograms] - The value to be converted
 * @returns {(number|undefined)} Value converted to pounds
 */
export const kilogramsToPounds = (kilograms?: number | string): number | undefined =>
  parseAndConvert(kilograms, parsedValue => parsedValue * POUNDS_IN_A_KILOGRAM);

/**
 * Converts pounds to stones
 *
 * @param {(number|string)} [pounds] - The value to be converted
 * @returns {(number[]|undefined)} [stones, pounds]
 */
export const poundsToStones = (pounds?: number | string): number[] | undefined =>
  parseAndConvert(pounds, parsedValue => {
    const stones = Math.trunc(parsedValue / POUNDS_IN_A_STONE);
    const poundsRemainder = parsedValue % POUNDS_IN_A_STONE;

    return [stones, poundsRemainder];
  });

/**
 * Converts kilograms to stones
 *
 * @param {(number|string)} [kilograms] - The value to be converted
 * @returns {(number|undefined)} Value converted to stones
 */
export const kilogramsToStones = (kilograms?: number | string): number | undefined =>
  parseAndConvert(
    kilograms,
    parsedValue => (kilogramsToPounds(parsedValue) ?? 0) / POUNDS_IN_A_STONE,
  );

/**
 * Converts stones to pounds
 *
 * @param {(number|string)} [stones] - The value to be converted
 * @returns {(number|undefined)} Value converted to pounds
 */
export const stonesToPounds = (stones?: number | string): number | undefined =>
  parseAndConvert(stones, parsedValue => parsedValue * POUNDS_IN_A_STONE);

/**
 * Converts kilometres to miles
 *
 * @param {(number|string)} [kilometres] - The value to be converted
 * @returns {(number|undefined)} Value converted to miles
 */
export const kilometresToMiles = (kilometres?: number | string): number | undefined =>
  parseAndConvert(kilometres, parsedValue => parsedValue * MILES_IN_A_KILOMETRE);

/**
 * Converts days to years and days
 *
 * @param {number} [days] - The value to be formatted
 * @returns {(string|undefined)} Period written as years and days
 */
export const daysToYearsAndDays = (days?: number | string): string | undefined =>
  parseAndConvert(
    days,
    parsedValue =>
      `${Math.trunc(parsedValue / DAYS_IN_A_YEAR)} years ${parsedValue % DAYS_IN_A_YEAR} days`,
  );

/**
 * Parses a provided value to either number or undefined
 *
 * @param {(number|string)} [value] - The provided value
 * @returns {(number|undefined|null)} The parsed value
 */
const parseValue = (value?: number | string | null): number | undefined => {
  const parsedValue = Number(value);

  if (value === undefined || value === null || value === '' || Number.isNaN(parsedValue)) {
    return undefined;
  }

  return parsedValue;
};

/**
 * Parses a value to number before performing a conversion using the provided method
 *
 * @param {(number|string)} [value] The value to be parsed and converted
 * @param {function} converter The method to be used for the conversion
 * @returns {(T|undefined)} The parsed and converted value
 */
function parseAndConvert<ConvertedType>(
  value: number | string | undefined | null,
  converter: (parsedValue: number) => ConvertedType,
): ConvertedType | undefined {
  const parsedValue = parseValue(value);
  return parsedValue === undefined ? parsedValue : converter(parsedValue);
}

/**
 * Round a decimal to the nearest half
 *
 * @param {(number|string)} [value] The number to be rounded
 * @returns {(number|undefined)} The rounded number
 */
const nearestHalf = (value?: number | string): number | undefined =>
  parseAndConvert(value, parsedValue => Number((parsedValue * 2).toFixed()) / 2);
