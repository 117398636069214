import {
  RECIPE,
  FEATURE,
  POST,
  SUCCESS_STORY,
  PLANNER_ACTIVITY,
  FOOD_OPTIMISING,
  ACTIVITY_VIDEO,
} from '../entityTypes';

const BookmarkTileType = {
  [RECIPE]: 'BookmarkRecipeTile',
  [FEATURE]: 'BookmarkFeatureTile',
  [SUCCESS_STORY]: 'BookmarkSuccessStoryTile',
  [POST]: 'Post',
  [PLANNER_ACTIVITY]: 'PlannerActivity',
  [FOOD_OPTIMISING]: 'BookmarkFoodOptimisingTile',
  [ACTIVITY_VIDEO]: 'ActivityVideoTile',
};

export default BookmarkTileType;
