/* global 'atom','Select' */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import Configuration from 'common/src/app/config/Configuration';
import StyleType from 'common/src/app/data/enum/StyleType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import { removeKey } from 'common/src/app/util/objectUtils';
import Icon from '../Icon';
import './select.scss';

/**
 * The select element is used to create a drop-down list.
 */

const Select = props => {
  const {
    input: inputProps = {},
    name,
    meta,
    options,
    placeholder,
    styleType,
    arrowType,
    dataTestid,
    value,
    suppress,
    id = name,
    customLabel,
    visibleLabel,
    selectRef,
    ...otherProps
  } = props;

  const placeholderOption = placeholder && (
    <option value="" disabled>
      {placeholder}
    </option>
  );

  const defaultValue = options.find(item => item?.selected)?.value || otherProps?.defaultValue;

  // Create a unique id for the select box if it does not have an id yet. Using react hooks here so it doesn't happen on each render
  const [numberedId] = useState(() => uniqueId('select-'));

  return (
    <div
      className={functionalComponentClassName('atom','Select', props, {
        dirty: meta && meta.dirty,
        pristine: meta && meta.pristine,
        [`${styleType}`]: true,
      })}
    >
      {!visibleLabel && (
        <label className="screen-reader" htmlFor={id || numberedId}>
          {customLabel || placeholder}
        </label>
      )}
      <select
        id={id || numberedId}
        value={value}
        {...removeKey('descriptorType', otherProps)}
        {...inputProps}
        name={name}
        data-testid={dataTestid}
        data-hj-suppress={suppress}
        defaultValue={defaultValue}
        className={suppress && 'sw-masked'}
        ref={selectRef}
      >
        {placeholderOption}
        {options &&
          Object.keys(options).length !== 0 &&
          options.map((item, index) => (
            <option
              data-testid={dataTestid !== undefined ? `${dataTestid}-${item.value}` : item.value}
              disabled={item.disabled}
              key={index}
              value={item.value}
              aria-label={item?.ariaLabel}
            >
              {item.title}
            </option>
          ))}
        <optgroup label="" />
      </select>
      <Icon name={arrowType} />
    </div>
  );
};

Select.defaultProps = {
  styleType: StyleType.PRIMARY,
  arrowType: Configuration.selectIconName,
  visibleLabel: false,
};

Select.propTypes = {
  name: PropTypes.string,
  arrowType: PropTypes.string,
  meta: PropTypes.object,
  styleType: PropTypes.oneOf([StyleType.PRIMARY, StyleType.SECONDARY]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      disabled: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      selected: PropTypes.bool,
      ariaLabel: PropTypes.string,
    }),
  ),
  input: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  dataTestid: PropTypes.string,
  value: PropTypes.string,
  // Suppress is used for both our 3rd party tracking services (hotjar and glassbox)
  // - For hotjar we add a data-atrribute of data-hj-suppres
  // - For glassbox we append a className of 'sw-masked'
  suppress: PropTypes.bool,
  visibleLabel: PropTypes.bool,
  customLabel: PropTypes.string,
  selectRef: PropTypes.func,
};

export default Select;
