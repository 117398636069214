/* global 'template','RevealEmail' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import WebHostLink from 'components/atoms/WebHostLink';
import WebHost from 'common/src/app/data/enum/WebHost';
import Pages from 'common/src/app/data/enum/Pages';
import { createPath } from 'common/src/app/util/routeUtils';
import './email-access.scss';

const RevealEmail = ({ handleSubmit, userEmail, setSessionEmail }, {}, className) => (
  <form onSubmit={handleSubmit} className={className} autoComplete="off" noValidate>
    <TextNew.Loud element={ElementTypes.h1} localeId="revealEmail.title" />

    <TextNew.PrimaryElegant localeId="revealEmail.body" />

    <TextNew.Sans.SM localeId="revealEmail.yourEmail" />

    <TextNew.Sans.LG element={ElementTypes.P} cid="obsfucated-email">
      {userEmail}
    </TextNew.Sans.LG>

    <TextNew.PrimaryElegant cid="instructions" localeId="revealEmail.instructions" />

    <TextNew.PrimaryElegant
      cid="instructions"
      localeId="revealEmail.changeInstructions"
      localeParams={{
        LINK: (
          <WebHostLink webHost={WebHost.MEMBER} link={Pages.ACCOUNT_SETTINGS_ACCOUNT_DETAILS}>
            <LocaleMessage id="revealEmail.updateLinkText" />
          </WebHostLink>
        ),
      }}
    />

    <Button ripple href={'../login'} onClick={() => setSessionEmail()}>
      <LocaleMessage id="revealEmail.button" />
    </Button>

    <TextNew.Serif.MD localeId="revealEmail.cantRemember" cid="subtitle" />
    <TextNew.PrimaryElegant
      localeId="revealEmail.contactSupport"
      localeParams={{
        LINK: (
          <WebHostLink webHost={WebHost.PUBLIC} link={createPath(Pages.CONTACT_US)}>
            <LocaleMessage id="revealEmail.contactSupportLinkText" />
          </WebHostLink>
        ),
      }}
    />
  </form>
);

RevealEmail.propTypes = {
  handleSubmit: PropTypes.func,
  setSessionEmail: PropTypes.func,
  userEmail: PropTypes.string,
};

RevealEmail.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName('template','RevealEmail')(RevealEmail);
