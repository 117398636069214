import moment from 'moment';
import get from 'lodash/get';
import { LOCATION_CHANGE } from 'react-router-redux';
import { WIZARD_STEP_SUBMIT } from 'common/src/app/enhanced-redux-form/actions/wizardFormActions';
import { VALIDATE_FORM } from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import { dataLayerCustomEvent } from 'common/src/app/util/tracking/dataLayerUtils';
import { calculateBmi } from 'common/src/app/util/BmiUtils';
import {
  ERROR_AGE_NOT_ALLOWED,
  ERROR_IS_PREGNANT,
  ERROR_HAS_EATING_DISORDER,
  ERROR_HAS_MEDICAL_CONDITIONS,
  ERROR_BMI_TOO_LOW,
  ERROR_BMI_NOT_ALLOWED,
} from 'common/src/app/data/validationErrorCodes';
import { MALE } from 'common/src/app/data/enum/Gender';
import { Category, DataLayerKeys, CommonValues } from 'common/src/app/data/enum/Tracking';
import { metaHasForm, isFulfilled, hasErrorCode } from 'common/src/app/util/actionFilters';
import { APPLY_VOUCHER } from 'common/src/app/actions/resources/voucherActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';

export default addListener => {
  /**
   * Track invite link, market name
   */
  addListener(
    {
      actionType: LOCATION_CHANGE,
      filter: ({ payload: { pathname } }) => pathname === '/register/health-check',
    },
    (action, getState) => {
      const state = getState();
      const invited = get(state, 'registration.invite');

      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.INVITED]: invited ? CommonValues.TRUE : CommonValues.FALSE,
          [DataLayerKeys.MARKET_NAME]: marketConfig.marketNameTracking,
          ...(invited
            ? { [DataLayerKeys.USER_LABEL]: get(state, 'registration.invite.label') }
            : {}),
        },
        getState(),
      );
    },
  );

  /**
   * Form tracking: Medical Check
   */
  addListener(
    {
      actionType: WIZARD_STEP_SUBMIT,
      filter: metaHasForm(FormNames.MEDICAL_CHECK),
    },
    ({ payload: { formValues } }, getState) => {
      const isBreastFeeding =
        (formValues.isBreastfeeding && formValues.isBreastfeeding === '1') || false;

      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.DATE_OF_BIRTH_CHECK]: CommonValues.PASS,
          [DataLayerKeys.PREGNANCY_CHECK]: CommonValues.PASS,
          [DataLayerKeys.MEDICAL_CRITERIA_CHECK]: CommonValues.PASS,
          [DataLayerKeys.DATE_OF_BIRTH]: moment(formValues.dateOfBirth).year(),
          [DataLayerKeys.GENDER]:
            formValues.gender === MALE ? CommonValues.MALE : CommonValues.FEMALE,
          [DataLayerKeys.BREASTFEEDING]: isBreastFeeding ? CommonValues.TRUE : CommonValues.FALSE,
          [DataLayerKeys.BMI_CHECK]: CommonValues.PASS,
          [DataLayerKeys.STARTING_BMI]: calculateBmi(
            formValues.initialWeight,
            formValues.height,
          ).toFixed(1),
        },
        getState(),
      );
    },
  );

  /**
   * @description Track ERROR_AGE_NOT_ALLOWED
   */
  addListener(
    {
      actionType: VALIDATE_FORM,
      filter: [
        isFulfilled,
        metaHasForm(FormNames.MEDICAL_CHECK),
        hasErrorCode(ERROR_AGE_NOT_ALLOWED),
      ],
    },
    (_, getState) =>
      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.DATE_OF_BIRTH_CHECK]: CommonValues.FAIL,
        },
        getState(),
      ),
  );

  /**
   * @description Track ERROR_IS_PREGNANT
   */
  addListener(
    {
      actionType: VALIDATE_FORM,
      filter: [isFulfilled, metaHasForm(FormNames.MEDICAL_CHECK), hasErrorCode(ERROR_IS_PREGNANT)],
    },
    (_, getState) =>
      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.PREGNANCY_CHECK]: CommonValues.FAIL,
        },
        getState(),
      ),
  );

  /**
   * @description Track ERROR_HAS_EATING_DISORDER
   */
  addListener(
    {
      actionType: VALIDATE_FORM,
      filter: [
        isFulfilled,
        metaHasForm(FormNames.MEDICAL_CHECK),
        hasErrorCode(ERROR_HAS_EATING_DISORDER),
      ],
    },
    (_, getState) =>
      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.MEDICAL_CRITERIA_CHECK]: CommonValues.FAIL,
        },
        getState(),
      ),
  );

  /**
   * @description Track ERROR_HAS_MEDICAL_CONDITIONS
   */
  addListener(
    {
      actionType: VALIDATE_FORM,
      filter: [
        isFulfilled,
        metaHasForm(FormNames.MEDICAL_CHECK),
        hasErrorCode(ERROR_HAS_MEDICAL_CONDITIONS),
      ],
    },
    (_, getState) =>
      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.MEDICAL_CRITERIA_CHECK]: CommonValues.FAIL,
        },
        getState(),
      ),
  );

  /**
   * @description Track BMI Errors
   */
  addListener(
    {
      actionType: VALIDATE_FORM,
      filter: [
        isFulfilled,
        metaHasForm(FormNames.MEDICAL_CHECK),
        hasErrorCode([ERROR_BMI_TOO_LOW, ERROR_BMI_NOT_ALLOWED]),
      ],
    },
    (_, getState) =>
      dataLayerCustomEvent(
        {
          category: Category.ONLINE_REGISTRATION,
          [DataLayerKeys.BMI_CHECK]: CommonValues.FAIL,
        },
        getState(),
      ),
  );

  /**
   * @description Track Voucher
   */
  addListener(
    {
      actionType: APPLY_VOUCHER,
      filter: isFulfilled,
    },
    ({ payload }, getState) => {
      if (payload?.data) {
        dataLayerCustomEvent(
          {
            [DataLayerKeys.VOUCHER]: payload.data.code,
          },
          getState(),
        );
      }
    },
  );

  /**
   * Form tracking: Personal Details
   */
  addListener(
    {
      actionType: WIZARD_STEP_SUBMIT,
      filter: metaHasForm(FormNames.PERSONAL_DETAILS),
    },
    ({ payload: { formValues } }, getState) => {
      if (formValues?.billingAddress) {
        dataLayerCustomEvent(
          {
            [DataLayerKeys.TOWNCITY]: formValues.billingAddress.city,
            [DataLayerKeys.STATE]: formValues.billingAddress.state,
            [DataLayerKeys.COUNTRY]: formValues.billingAddress.country,
            ...(formValues.billingAddress.county
              ? { [DataLayerKeys.COUNTY]: formValues.billingAddress.county }
              : {}),
          },
          getState(),
        );
      }
    },
  );

  /**
   * Form tracking: Summary
   */
  addListener(
    {
      actionType: WIZARD_STEP_SUBMIT,
      filter: metaHasForm(FormNames.SUMMARY),
    },
    ({ payload: { formValues } }, getState) => {
      dataLayerCustomEvent(
        {
          [DataLayerKeys.PACKAGE_SELECTED]: formValues.package,
        },
        getState(),
      );
    },
  );
};
