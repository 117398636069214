import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
// Style
import './icon-button.scss';

/**
 * IconButton Molecule
 * This component is for icons that when selected trigger an action
 * Passing false/null in the icon component renders the molecule without a icon.
 * Not adding the icon prop will show the default icon.
 */
const IconButton = (
  {
    buttonLabel,
    children,
    icon,
    width,
    height,
    iconDirection,
    iconPosition,
    type,
    rounded,
    /* Props that are also used as class modifiers */
    /* eslint-disable no-unused-vars */
    isInteractive,
    isActive,
    /* eslint-enable no-unused-vars */
    ...props
  },
  context,
  className,
  dataTestid,
) => (
  <div className={className} data-testid={dataTestid}>
    <Button dataTestid={`${dataTestid}-button`} {...props} isTertiary type={type}>
      <Icon
        dataTestid={`${dataTestid}-icon`}
        width={width}
        height={height}
        name={icon}
        direction={iconDirection}
        rounded={rounded}
        iconPosition={iconPosition}
      />
      {buttonLabel && <span className="screen-reader">{buttonLabel}</span>}
      {children}
    </Button>
  </div>
);

IconButton.defaultProps = {
  rounded: false,
  icon: 'bookmark',
  iconDirection: DirectionType.DOWN,
};

IconButton.propTypes = {
  /**
   * Name of the icon
   */
  icon: PropTypes.string.isRequired,
  /**
   * Type of the button
   */
  type: PropTypes.string,
  /**
   * Type of the button
   */
  iconPosition: PropTypes.string,
  /**
   * Size of the icon
   */
  width: PropTypes.number,
  /**
   * Boolean indicating whether the icon is rounded
   */
  rounded: PropTypes.bool,
  /**
   * Icon height
   */
  height: PropTypes.number,
  /**
   * Children of the button
   */
  children: PropTypes.node,
  /**
   * Direction of the icon
   */
  iconDirection: PropTypes.oneOf([
    DirectionType.UP,
    DirectionType.RIGHT,
    DirectionType.DOWN,
    DirectionType.LEFT,
  ]),
  /**
   * ClassName modifier
   */
  isInteractive: PropTypes.bool,
  /**
   * ClassName modifier
   */
  isActive: PropTypes.bool,
  /**
   * Alternative label that screenreader will use
   */
  buttonLabel: PropTypes.node,
};

export default memo(
  withFunctionalClassName(ComponentType.MOLECULE, 'IconButton', ['isActive', 'isInteractive'])(
    IconButton,
  ),
);
