import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import formFieldPropTypes from 'common/src/app/util/form/formFieldPropTypes';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from '../TextNew';
import Icon from '../Icon';
import RadioStyleType from './RadioStyleType';
import './radio-button.scss';

/**
 * A radio button allows the user to change a setting between two states.
 * Component RadioButtonGroup is required.
 */
class RadioButton extends Component {
  componentWillUnmount() {
    this.context.radioButtonGroup.handleUnmount(this.props.value);
  }

  onChange = () => {
    this.context.radioButtonGroup.setValue(this.props.value);
  };

  render() {
    const {
      radioButtonGroup: { name: groupName, type: typeFromContext, value: contextValue },
    } = this.context;

    const {
      value,
      label,
      ariaLabelledBy,
      onClick,
      icon,
      type: typeFromProps,
      width,
      height,
      children,
      suppress,
    } = this.props;

    let radioType;

    const newName = `radio-button-${groupName}-${value}`;

    switch (typeFromContext || typeFromProps) {
      case RadioStyleType.ICON:
        radioType = (
          <label htmlFor={newName}>
            <Icon rounded name={icon} width={width} height={height} />
            {label && <TextNew.Formal element={ElementTypes.SPAN}>{label}</TextNew.Formal>}
            {children}
          </label>
        );
        break;
      case RadioStyleType.BUTTON:
        radioType = (
          <label htmlFor={newName}>
            {label ? <div className="visual-button formal">{label}</div> : null}
            {children}
          </label>
        );
        break;
      case RadioStyleType.PILL:
        radioType = (
          <label htmlFor={newName}>
            <div className="visual-pill">
              {label && (
                <TextNew.PrimaryOriginal element={ElementTypes.SPAN}>
                  {label}
                </TextNew.PrimaryOriginal>
              )}
              {children}
            </div>
          </label>
        );
        break;
      default:
        radioType = (
          <label htmlFor={newName}>
            <span className="radio-button" />
            {label && (
              <TextNew.PrimaryOriginal element={ElementTypes.SPAN}>{label}</TextNew.PrimaryOriginal>
            )}
            {children}
          </label>
        );
        break;
    }

    return (
      <div
        {...componentClassNameProp(ComponentType.ATOM, this, [
          ({ type: radioTypeFromProps }, { radioButtonGroup: { type: radioTypeFromContext } }) =>
            radioTypeFromContext || radioTypeFromProps,
          ({ color: radioColorFromProps }, { radioButtonGroup: { radioColorFromContext } }) =>
            radioColorFromContext || radioColorFromProps,
          { 'is-checked': contextValue === value },
          { 'sw-masked-element': suppress },
        ])}
      >
        <input
          id={newName}
          name={groupName}
          value={value}
          type="radio"
          onClick={onClick}
          onChange={this.onChange}
          onFocus={this.context.radioButtonGroup.handleFocus}
          onBlur={this.context.radioButtonGroup.handleBlur}
          checked={contextValue === value}
          data-testid={`${newName}-input`}
          aria-labelledby={ariaLabelledBy}
        />

        {radioType}
      </div>
    );
  }
}

RadioButton.defaultProps = {
  type: RadioStyleType.STANDARD,
};

RadioButton.propTypes = {
  ...formFieldPropTypes,
  /**
   * Radio button group groupName
   */
  groupName: PropTypes.string,
  /**
   * Radio button label
   */
  label: PropTypes.node,
  /**
   * Radio button value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    RadioStyleType.STANDARD,
    RadioStyleType.BUTTON,
    RadioStyleType.ICON,
    RadioStyleType.PILL,
  ]),
  color: PropTypes.oneOf(['primary-dark', 'dark-tone', 'mid-tone', 'light-tone', 'callout']),
  // Suppress is used for our 3rd party tracking service - glassbox
  // - To mask the field we append a className of 'sw-masked-element'
  suppress: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
};

RadioButton.contextTypes = {
  radioButtonGroup: PropTypes.shape({
    groupName: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default RadioButton;
