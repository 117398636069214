/* global 'molecule','TileCarousel' */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import breakpoints from 'common/src/app/data/enum/Breakpoints';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Configuration from 'common/src/app/config/Configuration';
import Carousel from '../../organisms/Carousel';
import NavigationButton from '../../atoms/NavigationButton';
import './tile-carousel.scss';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  swipeToSlide: true,
  responsive: [
    { breakpoint: breakpoints.MD, settings: { slidesToShow: 1 } },
    { breakpoint: breakpoints.XL, settings: { slidesToShow: 2 } },
    { breakpoint: breakpoints.XXL, settings: { slidesToShow: 3 } },
  ],
  nextArrow: <NavigationButton direction={DirectionType.RIGHT} />,
  prevArrow: <NavigationButton direction={DirectionType.LEFT} />,
};

/**
 * Carousel for different type of Tile content (articles, recipes, success stories etc.),
 * using the standard gridSizes
 */
const TileCarousel = ({ children, customSettings }, context, className) => (
  <div className={className}>
    {children && (
      <div
        className={classNames('carousel-wrapper', {
          'has-alternate-slide-count': Configuration.menuFixed,
        })}
      >
        {/* TODO: Replace instance with carousel component as part of DF-151 */}
        <Carousel
          settings={{
            ...settings,
            ...customSettings,
          }}
        >
          {children}
        </Carousel>
      </div>
    )}
  </div>
);

TileCarousel.propTypes = {
  children: PropTypes.node,
  /**
   * Object with specific settings that you want to overwrite
   */
  customSettings: PropTypes.object,
};

export default withFunctionalClassName('molecule','TileCarousel')(TileCarousel);
