import CompositeInputFormatterError from '../CompositeInputFormatterError';
import { inchesToCentimeters } from '../../util/conversionUtils';

/**
 * Converts values from the HeightImperialFieldGroup input into a number value in metres
 * @param {object} formValues
 * @param {string} formValues.feet A string that contains an integer representing
 * the amount of feet
 * @param {string} formValues.inches A string that contains an integer representing
 * the amount of inches
 *
 * @returns {number} The resulting number value in centimetres
 */
const composeHeightImperialToMetric = ({ feet, inches, halfInch = 0 }) => {
  if (typeof feet === 'undefined' && typeof inches === 'undefined') {
    return feet;
  }

  const parsedFeet = feet ? parseInt(feet, 10) : 0;
  const parsedInches = inches ? parseInt(inches, 10) : 0;

  if (Number.isNaN(parsedFeet)) {
    throw new CompositeInputFormatterError({ feet: 'Feet should be a number' });
  }
  if (Number.isNaN(parsedInches)) {
    throw new CompositeInputFormatterError({ inches: 'Inches should be a number' });
  }
  if (parsedInches >= 12) {
    throw new CompositeInputFormatterError({ inches: 'Inches should be smaller than 12' });
  }

  const totalInches = parsedFeet * 12 + parsedInches + parseFloat(halfInch);

  // convert to cm
  return inchesToCentimeters(totalInches);
};

export default composeHeightImperialToMetric;
