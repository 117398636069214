// auto generated please run `yarn icon-sprite`
const iconSizing = {
  'active-arrow-link': {
    width: 219,
    height: 12,
  },
  'admin-icon-uk': {
    width: 316,
    height: 316,
  },
  android: {
    width: 19,
    height: 31,
  },
  'app-global-icon': {
    width: 20,
    height: 16,
  },
  'app-mobile': {
    width: 14,
    height: 20,
  },
  'app-planner-icon': {
    width: 17,
    height: 16,
  },
  'app-store': {
    width: 521.71,
    height: 170.66,
  },
  apple: {
    width: 19,
    height: 25,
  },
  'arrow-drawn': {
    width: 55,
    height: 61,
  },
  'arrow-flip': {
    width: 22,
    height: 10,
  },
  'arrow-new': {
    width: 24,
    height: 12,
  },
  arrow: {
    width: 32,
    height: 32,
  },
  audio: {
    width: 24,
    height: 28,
  },
  basket: {
    width: 22,
    height: 21,
  },
  before: {
    width: 18,
    height: 17,
  },
  'black-and-white': {
    width: 24,
    height: 24,
  },
  'block-arrow': {
    width: 10,
    height: 6,
  },
  blockquote: {
    width: 99.3,
    height: 102,
  },
  'body-magic-award': {
    width: 16.65,
    height: 19.57,
  },
  'body-magic-man': {
    width: 247,
    height: 333,
  },
  'body-magic': {
    width: 17,
    height: 24,
  },
  bookmark: {
    width: 13.3,
    height: 23.6,
  },
  'bulleted-list': {
    width: 32,
    height: 32,
  },
  calcium: {
    width: 24,
    height: 24,
  },
  'calendar-1': {
    width: 72,
    height: 75,
  },
  'calendar-large': {
    width: 46,
    height: 48,
  },
  calendar: {
    width: 16,
    height: 18,
  },
  camera: {
    width: 20,
    height: 16,
  },
  checkmark: {
    width: 10,
    height: 9,
  },
  'chefs-hat': {
    width: 20.2,
    height: 19.7,
  },
  'chevron-large': {
    width: 22,
    height: 8,
  },
  chevron: {
    width: 12,
    height: 6,
  },
  chilli: {
    width: 27.6,
    height: 25.8,
  },
  'circle-minus': {
    width: 16,
    height: 16,
  },
  'circle-plus': {
    width: 16,
    height: 16,
  },
  clock: {
    width: 53.76247,
    height: 53.76247,
  },
  'comment-border': {
    width: 20,
    height: 16,
  },
  'comment-filled': {
    width: 16,
    height: 12,
  },
  'commented-filled': {
    width: 12,
    height: 10,
  },
  'community-mention': {
    width: 45,
    height: 50,
  },
  copy: {
    width: 17.64,
    height: 22.12,
  },
  cross: {
    width: 14,
    height: 14,
  },
  'current-weight': {
    width: 16,
    height: 15,
  },
  'cyp-flag': {
    width: 25,
    height: 24,
  },
  'dairy-free': {
    width: 22,
    height: 26,
  },
  'download-large': {
    width: 24,
    height: 24,
  },
  download: {
    width: 8,
    height: 12,
  },
  edit: {
    width: 16,
    height: 16,
  },
  'emotion-disapointed': {
    width: 48,
    height: 48,
  },
  'emotion-frustrated': {
    width: 48,
    height: 48,
  },
  'emotion-pleased': {
    width: 48,
    height: 48,
  },
  'emotion-thrilled': {
    width: 48,
    height: 48,
  },
  'emotion-upset': {
    width: 48,
    height: 48,
  },
  enlarge: {
    width: 12,
    height: 12,
  },
  'envelope-email': {
    width: 31.302704,
    height: 22.765602,
  },
  'every-mind-matters': {
    width: 755,
    height: 519,
  },
  'eye-hide': {
    width: 18,
    height: 7,
  },
  'eye-open': {
    width: 18,
    height: 8,
  },
  facebook: {
    width: 10,
    height: 20,
  },
  'family-affair-logo': {
    width: 431,
    height: 339,
  },
  female: {
    width: 19,
    height: 40,
  },
  fibre: {
    width: 24,
    height: 24,
  },
  filter: {
    width: 18,
    height: 19,
  },
  flag: {
    width: 12,
    height: 16,
  },
  'fo-step-one-circle': {
    width: 202,
    height: 202,
  },
  'fo-step-three-circle': {
    width: 200,
    height: 200,
  },
  'fo-step-two-circle': {
    width: 201,
    height: 201,
  },
  'food-bowl': {
    width: 18.9,
    height: 19.8,
  },
  'food-guide-icon': {
    width: 17,
    height: 20,
  },
  'food-optimising-bookmark-icon': {
    width: 17,
    height: 20,
  },
  'food-optimising-hero-award': {
    width: 102,
    height: 112,
  },
  'food-optimising-menu': {
    width: 17,
    height: 20,
  },
  footprints: {
    width: 54,
    height: 79,
  },
  'free-foods-new': {
    width: 35,
    height: 13,
  },
  'free-foods': {
    width: 32,
    height: 32,
  },
  'freezer-friendly': {
    width: 24,
    height: 24,
  },
  'full-colour': {
    width: 24,
    height: 24,
  },
  'gluten-free': {
    width: 24,
    height: 28,
  },
  'group-support': {
    width: 55.045063,
    height: 55.188885,
  },
  'half-fraction-award': {
    width: 19.3619,
    height: 42.5003,
  },
  'half-fraction': {
    width: 12,
    height: 21,
  },
  'hall-of-fame': {
    width: 489.9,
    height: 268.88,
  },
  'hamburger-menu': {
    width: 109,
    height: 103,
  },
  hamburger: {
    width: 71,
    height: 66,
  },
  'hand-drawn-arrow': {
    width: 39,
    height: 49,
  },
  'healthy-extra-a': {
    width: 10.3,
    height: 22,
  },
  'healthy-extras': {
    width: 16,
    height: 28,
  },
  'heart-like-border': {
    width: 22,
    height: 20,
  },
  'heart-liked-filled': {
    width: 12,
    height: 10,
  },
  'heart-white-border': {
    width: 56,
    height: 56,
  },
  height: {
    width: 18,
    height: 20,
  },
  highlighter: {
    width: 469.78,
    height: 438.31,
  },
  'iceland-logo': {
    width: 328.8,
    height: 73.5,
  },
  'iceland-sp': {
    width: 32.7,
    height: 24.7,
  },
  'iceland-vegan': {
    width: 41.7,
    height: 22.7,
  },
  'iceland-vegetarian': {
    width: 68.7,
    height: 21.7,
  },
  idea: {
    width: 24,
    height: 23,
  },
  information: {
    width: 6.83,
    height: 6.83,
  },
  'instagram-camcorder': {
    width: 165.3,
    height: 120,
  },
  instagram: {
    width: 24,
    height: 24,
  },
  ipso: {
    width: 207,
    height: 75,
  },
  lightbulb: {
    width: 26,
    height: 24,
  },
  'list-heart': {
    width: 24,
    height: 24,
  },
  'list-search': {
    width: 24,
    height: 24,
  },
  'location-target': {
    width: 44,
    height: 44,
  },
  lock: {
    width: 16,
    height: 20,
  },
  'logo-classic-mobile': {
    width: 165,
    height: 82,
  },
  'logo-classic': {
    width: 165,
    height: 82,
  },
  'logo-modern-mobile': {
    width: 35.4,
    height: 25.9,
  },
  logout: {
    width: 22,
    height: 15,
  },
  'magnify-glass': {
    width: 18,
    height: 18,
  },
  male: {
    width: 18,
    height: 40,
  },
  'map-marker-symbol': {
    width: 29,
    height: 39,
  },
  'map-pin': {
    width: 209.25,
    height: 283.99,
  },
  'menu-community': {
    width: 24,
    height: 18,
  },
  'menu-contact-us': {
    width: 25,
    height: 21,
  },
  'menu-features': {
    width: 16,
    height: 22,
  },
  'menu-foodlists': {
    width: 18,
    height: 22,
  },
  'menu-home': {
    width: 16,
    height: 18,
  },
  'menu-recipes': {
    width: 16,
    height: 24,
  },
  'menu-slimming-group': {
    width: 24,
    height: 18,
  },
  'menu-success': {
    width: 20,
    height: 19,
  },
  'menu-weightlossplanner': {
    width: 16,
    height: 20,
  },
  minus: {
    width: 10,
    height: 2,
  },
  'mobile-support-arrow': {
    width: 267,
    height: 240,
  },
  muscle: {
    width: 18,
    height: 22,
  },
  mute: {
    width: 24,
    height: 28,
  },
  notifications: {
    width: 24,
    height: 26,
  },
  'online-member-events': {
    width: 24,
    height: 24,
  },
  paperclip: {
    width: 57,
    height: 69,
  },
  pause: {
    width: 6.4,
    height: 10,
  },
  pdf: {
    width: 32,
    height: 40,
  },
  'person-border': {
    width: 18,
    height: 18,
  },
  'person-filled': {
    width: 18,
    height: 18,
  },
  pin: {
    width: 10,
    height: 17,
  },
  pinterest: {
    width: 24,
    height: 24,
  },
  play: {
    width: 16,
    height: 16,
  },
  plus: {
    width: 10,
    height: 10,
  },
  'podcast-heart-dark': {
    width: 82,
    height: 49,
  },
  'podcast-heart': {
    width: 82,
    height: 49,
  },
  podcast: {
    width: 82,
    height: 50,
  },
  'pregnancy-roundel': {
    width: 88.88,
    height: 88.88,
  },
  print: {
    width: 24,
    height: 24,
  },
  protein: {
    width: 24,
    height: 24,
  },
  'recurring-payment': {
    width: 31.1,
    height: 31.1,
  },
  'registered-trademark-symbol': {
    width: 11.4,
    height: 11.4,
  },
  reply: {
    width: 16,
    height: 15,
  },
  reset: {
    width: 16.91,
    height: 14.44,
  },
  'roi-flag': {
    width: 25,
    height: 24,
  },
  'scale-border': {
    width: 74,
    height: 48,
  },
  'scale-pin': {
    width: 74,
    height: 48,
  },
  scale: {
    width: 74,
    height: 48,
  },
  'share-export': {
    width: 24,
    height: 24,
  },
  share: {
    width: 18,
    height: 18,
  },
  'sign-up': {
    width: 53.677994,
    height: 55.551891,
  },
  signin: {
    width: 23,
    height: 18,
  },
  'smiles-charity-logo': {
    width: 218.56,
    height: 144.01,
  },
  'sound-off': {
    width: 81.33,
    height: 56.38,
  },
  'sound-on': {
    width: 76.33,
    height: 56.38,
  },
  'sp-secondary': {
    width: 24.81,
    height: 18.49,
  },
  sp: {
    width: 32,
    height: 22,
  },
  speed: {
    width: 24,
    height: 24,
  },
  support: {
    width: 67,
    height: 67,
  },
  'syns-allowance': {
    width: 24,
    height: 24,
  },
  tags: {
    width: 18,
    height: 19,
  },
  tick: {
    width: 16,
    height: 18,
  },
  time: {
    width: 18,
    height: 18,
  },
  trash: {
    width: 18,
    height: 18,
  },
  trophy: {
    width: 74,
    height: 74,
  },
  'trust-pilot': {
    width: 75,
    height: 18,
  },
  tumblr: {
    width: 15.26478,
    height: 22.887873,
  },
  twitter: {
    width: 24,
    height: 20,
  },
  'uk-flag': {
    width: 24,
    height: 24,
  },
  'underline-swoosh-small': {
    width: 97,
    height: 11,
  },
  'underline-swoosh': {
    width: 199,
    height: 37,
  },
  unlocked: {
    width: 13,
    height: 19,
  },
  vegan: {
    width: 21,
    height: 24,
  },
  vegetarian: {
    width: 18,
    height: 24,
  },
  'video-camera': {
    width: 36.67,
    height: 35,
  },
  watch: {
    width: 11,
    height: 16,
  },
  'weight-loss-roundel': {
    width: 139,
    height: 140,
  },
  'magic-mover-award': {
    width: 200,
    height: 200,
  },
};

export default iconSizing;
