import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew from 'components/atoms/TextNew';
import MultiFactorAuthenticationForm from '../../../../../organisms/MultiFactorAuthenticationForm';

const componentName = 'multi-factor-authentication-register';
const MultiFactorAuthenticationRegister = (
  { location, qrCodeUrl, manualCode },
  context,
  className,
) => (
  <div className={className}>
    <TextNew.Loud
      localeId="multiFactorAuthenticationRegister.title"
      dataTestid={`${componentName}-title`}
    />
    <TextNew.Sans.SM localeId="multiFactorAuthenticationRegister.body" />
    <MultiFactorAuthenticationForm
      isRegisterForm
      qrCodeUrl={qrCodeUrl}
      manualCode={manualCode}
      location={location}
    />
  </div>
);

MultiFactorAuthenticationRegister.propTypes = {
  location: PropTypes.string,
  qrCodeUrl: PropTypes.string.isRequired,
  manualCode: PropTypes.string.isRequired,
};

export default withFunctionalClassName(
  ComponentType.TEMPLATE,
  'MultiFactorAuthenticationRegister',
)(MultiFactorAuthenticationRegister);
