import * as ValidateOn from '../enhanced-redux-form/data/ValidateOn';
import { EXTRACT_BOTH_PARTS_OF_UK_POSTCODE_REGEX } from '../data/regexPatterns';
import { createRequiredRule } from './validationUtils';
import CountryPostalCode from '../data/enum/CountryPostalCode';
import { countryCodeValue } from '../data/enum/Countries';
import { isValidUkPostcode } from './addressValidation';

const isNullOrWhitespace = value => (!value || !value.trim()) && value !== 0;

export const isValidPostcodeFormat = value => {
  if (isNullOrWhitespace(value)) {
    return true;
  }

  const match = (value || '').match(EXTRACT_BOTH_PARTS_OF_UK_POSTCODE_REGEX);
  return match && match.length === 3;
};

export const isValidPostcode = (value, values, fieldName, dispatch) =>
  new Promise(resolve => {
    if (isNullOrWhitespace(value)) {
      resolve(true);
      return;
    }

    dispatch(isValidUkPostcode(value)).then(result => {
      resolve(result);
    });
  });

/*
 * The countryCode paramater is used to drive the validation messages field name:
 * e.g. postcode, zipcode or eircode
 */
export const createPostcodeFormatRule = (fieldName, formName = 'validation', countryCode) => ({
  rule: isValidPostcodeFormat,
  message: {
    locale: `${formName}.errors.${fieldName}.formatError`,
    params: {
      POSTAL_CODE: () =>
        CountryPostalCode[countryCodeValue[countryCode]]?.toLowerCase() || 'postcode',
    },
  },
});

/*
 * The countryCode paramater is used to drive the validation messages field name:
 * e.g. postcode, zipcode or eircode
 */
export const createPostcodeValidRule = (fieldName, formName = 'validation', countryCode) => ({
  rule: isValidPostcode,
  message: {
    locale: `${formName}.errors.${fieldName}.formatError`,
    params: {
      POSTAL_CODE: () =>
        CountryPostalCode[countryCodeValue[countryCode]]?.toLowerCase() || 'postcode',
    },
  },
});

/*
 * Simple helper function to create a complete 'required' validation config,
 * when there is no other validations needed on the field.
 * The countryCode paramater is used to drive the validation messages field name:
 *  e.g. postcode, zipcode or eircode
 */
const createPostcodeValidation = ({ fieldName, validateOn, formName, countryCode }) => ({
  [fieldName]: {
    validators: [
      createRequiredRule(fieldName, undefined, {
        POSTAL_CODE: () =>
          CountryPostalCode[countryCodeValue[countryCode]]?.toLowerCase() || 'postcode',
      }),
      createPostcodeFormatRule(fieldName, formName, countryCode),
      createPostcodeValidRule(fieldName, formName, countryCode),
    ],
    validateOn: validateOn || [ValidateOn.BLUR],
  },
});

export default createPostcodeValidation;
