/**
 * Create an id from a given string
 *
 * @param name
 * @returns {string}
 */

const cleanseId = name =>
  name
    .replace(/[\W_]+/g, ' ') // replace all non alphanumeric characters
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();

const createId = name => `id-${cleanseId(name)}`;

export default createId;
