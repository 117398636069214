import React from 'react';
import PropTypes from 'prop-types';
import LocaleMessage from '../../../../atoms/LocaleMessage';
import Button from '../../../../atoms/Button';

const SubmitButton = ({ submitting, hasBlockingError }) => (
  <Button ripple type="submit" disabled={submitting || hasBlockingError}>
    <LocaleMessage id="general.cta.continue" />
  </Button>
);

SubmitButton.propTypes = {
  submitting: PropTypes.bool,
  hasBlockingError: PropTypes.bool,
};

export default SubmitButton;
