import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { debounce } from 'throttle-debounce';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import formFieldPropTypes from 'common/src/app/util/form/formFieldPropTypes';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import './toggle.scss';

const ToggleTypes = {
  CLASSIC: 'classic',
  CONTEMPORARY: 'contemporary',
};

class Toggle extends Component {
  setValue = value => {
    this.props.input.onChange(value);
  };

  handleFocus = () => {
    this.focusCount += 1;
    this.scheduleBlurUpdate();
  };

  handleBlur = () => {
    this.focusCount -= 1;
    this.scheduleBlurUpdate();
  };

  scheduleBlurUpdate = debounce(1, () => {
    if (this.focusCount && !this.hasFocus && typeof this.props.input.onFocus === 'function') {
      this.props.input.onFocus();
      this.hasFocus = true;
    } else if (!this.focusCount && this.hasFocus) {
      this.props.input.onBlur();
      this.hasFocus = false;
    }
  });

  focusCount = 0;

  hasFocus = false;

  render() {
    const { legend, leftlabel, leftname, rightlabel, rightname, input, type } = this.props; // eslint-disable-line no-unused-vars
    return (
      <fieldset {...componentClassNameProp(ComponentType.ATOM, this, ['{type}-type'])}>
        {legend && <legend className="screen-reader">{legend}</legend>}
        <input
          type="radio"
          onChange={() => this.setValue(input.value === leftname ? rightname : leftname)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          checked={input.value === leftname}
          name={input.name}
          id={`toggle-${input.name}-${leftname}`}
        />
        <label htmlFor={`toggle-${input.name}-${leftname}`}>{leftlabel}</label>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
        <a
          tabIndex="-1"
          className="toggle-slider"
          onClick={() => this.setValue(input.value === leftname ? rightname : leftname)}
        >
          <div
            className={`toggle-ball ${
              input.value === leftname ? DirectionType.LEFT : DirectionType.RIGHT
            }`}
          />
        </a>
        <input
          type="radio"
          onChange={() => this.setValue(input.value === leftname ? rightname : leftname)}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          checked={input.value === rightname}
          name={input.name}
          id={`toggle-${input.name}-${rightname}`}
        />
        <label htmlFor={`toggle-${input.name}-${rightname}`}>{rightlabel}</label>
      </fieldset>
    );
  }
}

Toggle.propTypes = {
  ...formFieldPropTypes,
  legend: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  leftlabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  leftname: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightlabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rightname: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  input: PropTypes.object,
  type: PropTypes.oneOf(Object.values(ToggleTypes)),
};

Toggle.defaultProps = {
  type: ToggleTypes.CLASSIC,
};

export { Toggle as default, ToggleTypes };
