/* global 'page','GroupRegistration' */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import RegistrationTemplate from 'components/organisms/RegistrationTemplate';
import errorBoundary from 'components/hoc/ErrorBoundary';
import { compose } from 'redux';
import './group-registration.scss';

const GroupRegistration = (
  { children, stepPaths, lastMountedStepIndex, publicHost, deviceState, background, setFlowType },
  { getMessage },
  className,
) => {
  useEffect(() => {
    setFlowType();

    if (typeof window !== 'undefined' && window.olark) {
      window.olark('api.box.hide');
    }
  }, [setFlowType]);

  const pageDetails =
    stepPaths.length > 0 &&
    [
      { title: getMessage('groupRegistration.steps.cardCheck') },
      { title: getMessage('groupRegistration.steps.medicalCheck') },
      { title: getMessage('groupRegistration.steps.securityInformation') },
      { title: getMessage('groupRegistration.steps.regionInformation') },
    ].map((data, index) => ({ ...data, url: stepPaths[index] }));

  const registrationTemplate = (
    <RegistrationTemplate
      publicHost={publicHost}
      children={children}
      pageDetails={pageDetails}
      lastMountedStepIndex={lastMountedStepIndex}
    />
  );

  return deviceState >= DeviceState.LG ? (
    <BackgroundImageWrapper className={className} image={background && background}>
      {registrationTemplate}
    </BackgroundImageWrapper>
  ) : (
    <div className={className}>{registrationTemplate}</div>
  );
};

GroupRegistration.propTypes = {
  background: ImagePropTypes,
  children: PropTypes.node,
  stepPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  lastMountedStepIndex: PropTypes.number.isRequired,
  publicHost: PropTypes.string.isRequired,
  deviceState: PropTypes.number,
  setFlowType: PropTypes.func,
};

GroupRegistration.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName('page','GroupRegistration'),
)(GroupRegistration);
