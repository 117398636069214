/**
 * We often link to pages from other microservices or from pattern-lib component.
 * Currently they would need to import the Pages file from member or account, which is not
 * what we want; account or member files should only import files from their own folder
 * So instead this file lives in Common, so it can be properly imported from everywhere.
 */

const Pages = {
  HOME: '/',
  MENU: '/menu',

  // ACCOUNT
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_EMAIL: '/change-email',
  CONFIRM_EMAIL_CHANGE: '/confirm-email-change',
  CONFIRMED_EMAIL: '/confirm-email',
  LEGACY_LOGIN: '/legacy-login',
  LOGIN: '/login',
  ALREADY_LOGGED_IN: '/logged-in',
  LOGOUT: '/logout',
  LOGGED_OUT: '/logged-out',
  DUAL_ACCESS: '/dual-access',
  SITEMAP: '/sitemap',

  // NEWSLETTER SIGNUP EMAIL-CONFIRMATION
  CONFIRM_NEWSLETTER_SUBSCRIPTION: '/confirm-seven-day-subscription',

  // ONLINE REGISTER
  REGISTRATION: '/register',
  REGISTRATION_MEDICAL_CHECK: '/register/health-check',
  REGISTRATION_ACCOUNT_DETAILS: '/register/account-details',
  REGISTRATION_SUMMARY: '/register/summary',
  REGISTRATION_PACKAGE_SELECTION: '/register/package-selection',
  REGISTRATION_CHECKOUT: '/register/payment',
  REGISTRATION_PAYMENT: '/register/payment/form',
  REGISTRATION_FINISHED: '/register/finished',
  REGISTRATION_INTRODUCTION: '/register/introduction',

  PAYMENT: '/payment',

  // GROUP REGISTER
  GROUP_REGISTRATION: '/group-register',
  GR_CARD_CHECK: '/group-register/card-check',
  GR_MEDICAL_CHECK: '/group-register/medical-check',
  GR_PERSONAL_DETAILS: '/group-register/personal-details',
  GR_CONTACT_DETAILS: '/group-register/contact-details',
  GR_REGISTRATION_ERROR: '/action-required',
  GR_CARD_TRIES_EXCEEDED: '/card-try-exceeded',
  GR_SUCCESSFULLY_REGISTERED_FREE2GO: '/free-2-go-registration-success',

  // WELCOME BACK
  WELCOME_BACK: '/welcome-back',

  // RECOVER EMAIL
  RE_RECOVER_EMAIL: '/email-recovery',
  RE_SECURITY_CHECK: '/email-recovery/security-check',
  RE_EMAIL_ACCESS: '/email-recovery/email-access',
  RE_SECURITY_QUESTION: '/email-recovery/security-question',
  RE_UPDATE_EMAIL: '/email-recovery/update-email',
  RE_SUCCESS: '/email-recovery/success',

  // PREVIOUS GROUP TO ONLINE TRANSFER - ENTRY POINT
  // - Used to redirect app members to the new flow
  TRANSFER_TO_ONLINE: '/transfer-to-online',

  // UK PUBLIC
  UK_PUBLIC_ADVERTISE_WITH_US: '/advertise-with-us',
  UK_PUBLIC_ADVERTISING: '/advertise-with-us',
  UK_PUBLIC_BMI_CALCULATOR: '/bmi-calculator',
  UK_PUBLIC_HOW_IT_WORKS: '/how-it-works',
  UK_PUBLIC_COMPARISON: '/group-or-online-membership',
  UK_PUBLIC_ONLINE_INFORMATION: '/what-happens-online',
  UK_PUBLIC_FAQ_CAT_QUESTION: '/faqs/:categories/:question',
  UK_PUBLIC_FAQ_CAT: '/faqs/:categories',
  UK_PUBLIC_FAQ_CAN_MY_CHILD_JOIN: '/faqs/before-you-join/can-my-child-join',
  UK_PUBLIC_FEATURES: '/features',
  UK_PUBLIC_HEALTH_PROFESSIONALS: 'https://www.slimmingworld.co.uk/health/',
  UK_PUBLIC_HOME: '/',
  UK_PUBLIC_ICELAND: '/food-range',
  UK_PUBLIC_ICELAND_OUR_STORY: '/food-range/our-story',
  UK_PUBLIC_ICELAND_OUR_PRODUCTS: '/food-range/our-products',
  UK_PUBLIC_ICELAND_OUR_RANGE: '/food-range/our-range',
  UK_PUBLIC_OFFERS: '/special-offers',
  UK_PUBLIC_OUR_STORY: '/our-story',
  UK_PUBLIC_OVERVIEW_CATEGORY: '/overview/:category',
  UK_PUBLIC_PRESS_MEDIA: '/press-and-media',
  UK_PUBLIC_RECIPE_DETAIL: '/recipes/:slug',
  UK_PUBLIC_RECIPE_SEARCH: '/recipes',
  UK_PUBLIC_SCIENCE: '/the-science-behind-slimming-world',
  UK_PUBLIC_STORY: '/our-story',
  UK_PUBLIC_WHAT_CAN_I_EAT: '/what-can-i-eat',
  UK_PUBLIC_SUCCESS_STORIES: '/real-life-stories',
  UK_PUBLIC_SUCCESS_STORIES_MALE_FILTER: '/real-life-stories/male',
  UK_PUBLIC_SUCCESS_STORIES_FEMALE_FILTER: '/real-life-stories/female',
  UK_PUBLIC_SUCCESS_STORIES_DETAIL: '/real-life-stories/:slug',
  UK_PUBLIC_WHAT_HAPPENS_IN_GROUP: '/what-happens-in-group',
  UK_PUBLIC_SEVEN_DAY_MENU: '/seven-day-menus(/:day)(/:vegetarian)',
  UK_PUBLIC_MAGAZINE: '/magazine',
  UK_PUBLIC_GOLDEN_BUS: '/golden',
  UK_PUBLIC_MAGAZINE_COMPETITIONS: '/magcomps',
  UK_PUBLIC_USER_TESTING: '/userexperience',
  UK_PUBLIC_BETTER_HEALTH: '/betterhealth',
  UK_PUBLIC_PODCASTS: '/podcast',
  BLOG: '/blog/',

  // UK PUBLIC NEAREST GROUP SEARCH
  UK_PUBLIC_NEAREST_GROUP_LANDING: '/nearest-group-search',
  UK_PUBLIC_NEAREST_GROUP_DETAIL: '/group/:id',
  UK_PUBLIC_NEAREST_CONSULTANT_LANDING: '/consultant/:id',
  UK_PUBLIC_NEAREST_GROUP_COUNTIES: '/counties',
  UK_PUBLIC_NEAREST_GROUP_TOWNS_IN_COUNTY: '/counties/:county',
  UK_PUBLIC_NEAREST_GROUP_GROUPS_IN_TOWNS: '/counties/:county/:town',
  UK_PUBLIC_CYPRUS_GROUP_SEARCH: '/cyprus',

  // UK PUBLIC CAMPAIGN LANDING PAGES
  UK_JAN_2019_CAMPAIGN_LANDINGS: '/landing/jan-2019/:campaign',
  UK_PUBLIC_ONE_YOU: '/news/oneyou',
  UK_PUBLIC_FREE_FOOD_FEBRUARY: '/freefoodfeb',
  UK_PUBLIC_MENTAL_HEALTH: '/mentalhealth',

  // MEMBER
  COMING_SOON: '/coming-soon',
  WEEK_ZERO: '/week-zero',
  WEEK_ZERO_MODAL_QUICK_START: '/week-zero?modal=quick-start',
  TWELVE_WEEK_PROGRAM_HOME: '/?twelve-week-program',
  WEIGHT_LOSS_PLANNER: '/weight-loss-planner',
  FOOD_SYNS: '/food-lists-synds-tools',
  NOTIFICATIONS: '/notifications',
  WEIGH_IN_HISTORY: '/weigh-in-history',
  FAQS: '/faqs',

  // COMMUNITY
  COMMUNITY: '/community',
  COMMUNITY_DETAIL: '/community/:postId',
  COMMUNITY_EDIT_POST: '/community/:editPostId/edit',
  COMMUNITY_CREATE_POST: '/community/create-post',
  COMMUNITY_CREATE_POST_IN_GROUP: '/community/create-post/:slug',
  COMMUNITY_GROUP_POSTS: '/community/group/:slug',
  COMMUNITY_DETAIL_REPORT: '/community/:postId/report',

  // Profile
  MEMBER_PROFILE: '/profile(/:id)',
  PROFILE: '/profile',

  // Profile Builder
  PROFILE_BUILDER_ONLINE: '/profile/setup',
  PROFILE_BUILDER_GROUP: '/group-profile/setup',

  RECIPE_LANDING: '/recipes',
  RECIPE_OVERVIEW: '/recipes/overview',
  RECIPE_DETAIL: '/recipes/:slug',

  SEVEN_DAY_MENU_DETAIL: '/seven-day-menus/:slug',

  FEATURE_LANDING: '/features',
  FEATURE_OVERVIEW: '/features/overview',
  FEATURE_DETAIL: '/features/:slug',
  FEATURE_APPS: '/features/download-our-planner-app',
  FEATURE_ONLINE_MEMBER_EVENTS: '/features/online-member-events',

  SUCCESS_STORY_LANDING: '/success-stories',
  SUCCESS_STORY_OVERVIEW: '/success-stories/overview',
  SUCCESS_STORY_DETAIL: '/success-stories/:slug',

  // STATIC PAGES
  COMMUNITY_GUIDELINES: '/community-guidelines',
  ACCESSIBILITYPOLICY: '/accessibility-policy',
  MODERNSLAVERYACT: '/modern-slavery-statement',
  CONTACT_US: '/contact-us(/:contactType)',
  BODY_MAGIC_ESSENTIALS: '/body-magic-essentials',
  FOOD_OPTIMISING_DIARY_APP_SIGN_UP: '/download-slimming-world-apps',
  USER_SUBSCRIPTION_AGREEMENT: '/user-subscription-agreement',
  FAQ: '/faq',
  ABOUT: '/about',
  LANDING_PAGE: '/landing-page',
  GETTING_STARTED: '/getting-started',

  // FOOD_OPTIMISING_ESSENTIALS
  FOOD_OPTIMISING_ESSENTIALS: '/food-optimising-essentials',

  // POLICY CONTENT
  TERMS_OF_USE: '/terms-of-use',
  PRIVACYPOLICY: '/privacy-policy',
  COOKIESPOLICY: '/cookies-policy',

  // PRODUCT SHOP
  SHOP_LANDING: '/',
  SHOP_LANDING_FOOD: '/food',
  SHOP_LANDING_PRINT: '/books',
  SHOP_LANDING_MEMBERSHIP: '/membership',
  PRODUCT_DETAIL: '/products/:category/:productId',
  PRODUCT_CHECKOUT: '/checkout',
  PRODUCT_PAYMENT: '/checkout/payment',
  PRODUCT_CHECKOUT_PERSONAL: '/checkout/personal/edit',
  PRODUCT_CHECKOUT_YOUR_ADDRESS: '/checkout/address/edit',
  PRODUCT_CHECKOUT_SHIPPING: '/checkout/shipping/edit',
  PRODUCT_CHECKOUT_BILLING: '/checkout/billing/edit',
  PRODUCT_CHECKOUT_CONFIRMATION: '/order/confirm',

  // SW PLANNER
  FOOD_SEARCH: '/search/food',
  RECIPE_SEARCH: '/search/recipe',
  ACTIVITY_SEARCH: '/search/activity',
  ACTIVITY_SEARCH_BOOKMARKS: '/search/activity/bookmarked/:bookmarkType',
  DAILY_SUMMARY: '/summary',
  AWARDS: '/awards',
  AWARD_DETAILS: '/award-detail',

  // SYNS ESTIMATOR
  SYNS_ESTIMATOR: '/syns-estimator',
  SYNS_ESTIMATOR_PRODUCT_INFO: '/syns-estimator/product-info',
  SYNS_ESTIMATOR_NUTRITIONAL_INFO: '/syns-estimator/nutritional-info',
  SYNS_ESTIMATOR_RESULTS: '/syns-estimator/results',
  SYNS_ESTIMATOR_UPLOAD_PHOTO: '/syns-estimator/upload-photo',
  SYNS_ESTIMATOR_UPLOAD_PHOTO_SUCCESS: '/syns-estimator/upload-successful',

  // HEALTHY_EXTRAS
  HEALTHY_EXTRAS: '/healthy-extras',
  HEALTHY_EXTRA: '/healthy-extras/:id',

  // ACCOUNT SETTINGS
  ACCOUNT_SETTINGS: '/account-settings',
  ACCOUNT_SETTINGS_ACCOUNT_DETAILS: '/account-settings/account-details',
  ACCOUNT_SETTINGS_PERSONAL_DETAILS: '/account-settings/personal-details',
  ACCOUNT_SETTINGS_WEIGH_IN: '/account-settings/weigh-in',
  ACCOUNT_SETTINGS_ORDER_HISTORY: '/account-settings/order-history',
  ACCOUNT_SETTINGS_PURCHASE_RETURNS: '/account-settings/purchase-returns',
  ACCOUNT_SETTINGS_RETURN_CONFIRMED: '/account-settings/return-confirmed',
  ACCOUNT_SETTINGS_WEIGHT_LOSS_JOURNEY: '/account-settings/my-weight-loss-journey',
  ACCOUNT_SETTINGS_MEDICAL: '/account-settings/medical-details',
  ACCOUNT_SETTINGS_DIETARY: '/account-settings/dietary-requirements',
  ACCOUNT_SETTINGS_SUBSCRIPTION_STATUS: '/account-settings/subscription-status',
  ACCOUNT_SETTINGS_PAYMENT_HISTORY: '/account-settings/payment-history',
  ACCOUNT_SETTINGS_SHIPPING: '/account-settings/shipping/edit',
  ACCOUNT_SETTINGS_EMAILS: '/account-settings/emails',
  ACCOUNT_SETTINGS_PREGNANCY: '/account-settings/pregnancy',
  ACCOUNT_SETTINGS_HALL_OF_FAME: '/account-settings/hall-of-fame',
  ACCOUNT_SETTINGS_PRIVACY: '/account-settings/privacy',
  ACCOUNT_SETTINGS_SHARE_STORY: '/account-settings/your-story',
  ACCOUNT_SETTINGS_COMPETITION_ENTRIES: '/account-settings/competitions',

  // Group start / restart weight loss journey
  NEW_GROUP_JOURNEY: '/start',
  NEW_GROUP_JOURNEY_DATE: '/start/start-date',
  NEW_GROUP_JOURNEY_CURRENT_HEIGHT: '/start/current-height',
  NEW_GROUP_JOURNEY_START_WEIGHT: '/start/start-weight',
  NEW_GROUP_JOURNEY_CURRENT_WEIGHT: '/start/current-weight',
  NEW_GROUP_JOURNEY_TARGET_WEIGHT: '/start/target-weight',
  NEW_GROUP_JOURNEY_CONFIRMATION: '/start/confirmation',

  // QUICK START
  QUICK_START: '/quick-start',
  QUICK_START_START: '/quick-start/start',
  QUICK_START_FREE_FOODS: '/quick-start/free-foods',
  QUICK_START_FREE_FOOD_QUIZ: '/quick-start/free-food-quiz',
  QUICK_START_SYNS: '/quick-start/syns',
  QUICK_START_HEALTHY_EXTRAS: '/quick-start/healthy-extras',
  QUICK_START_COMPLETE: '/quick-start/complete',

  // Confirm or change initial weight and height before first weigh in
  FIRST_WEIGH_CONFIRMATION: '/a-quick-check',
  FIRST_WEIGH_WELCOME: '/a-quick-check/welcome',
  FIRST_WEIGH_WEIGHT: '/a-quick-check/weight',
  FIRST_WEIGH_HEIGHT: '/a-quick-check/height',
  FIRST_WEIGH_BMI_OK_PROCEED: '/a-quick-check/great',
  FIRST_WEIGH_BMI_TOO_LOW: '/a-quick-check/fantastic',
  FIRST_WEIGH_REFUND_CLOSE: '/a-quick-check/refund-and-close',

  // Weigh in flow
  WEIGHIN: '/weighin',
  WEIGHIN_ENTERWEIGHT: '/weighin/enter-weight',
  WEIGHIN_CONFIRMWEIGHT: '/weighin/confirm-weight',
  WEIGHIN_AWARD: '/weighin/award',
  WEIGHIN_MOOD: '/weighin/mood',
  WEIGHIN_PREGNANT_FINISH: '/weighin/success',
  WEIGHIN_GOALS: '/weighin/goals',
  WEIGHIN_PERSONALNOTE: '/weighin/personal-note',
  WEIGHIN_PERSONALFEEDBACK: '/weighin/personal-feedback',
  WEIGHIN_SKIPPINGREASON: '/weighin/skip',
  WEIGHIN_SKIPPED: '/weighin/skipped',

  // Edit weigh-in
  EDIT_WEIGHIN: '/edit-weighin',
  EDIT_WEIGHIN_EDIT_WEIGHT: '/edit-weighin/edit-weight',
  EDIT_WEIGHIN_EDIT_WEIGHT_CONFIRMATION: '/edit-weighin/confirmation',
  EDIT_WEIGHIN_EDIT_WEIGHT_UPDATED: '/edit-weighin/updated',

  // Edit start or target weight
  EDIT_START_WEIGHT: '/edit-start-weight',
  EDIT_START_WEIGHT_INFO: '/edit-start-weight-info',
  EDIT_TARGET_WEIGHT: '/edit-target-weight',

  // Add missing weigh-in
  ADD_WEIGHIN: '/add-weighin',
  ADD_WEIGHIN_ADD_WEIGHT: '/add-weighin/add-weight',
  ADD_WEIGHIN_ADD_WEIGHT_CONFIRMATION: '/add-weighin/confirmation',
  ADD_WEIGHIN_ADD_WEIGHT_UPDATED: '/add-weighin/updated',

  // Virtual consultant
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT: '/weighin/personal-feedback/virtual-consultant',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_TIPS_AND_RECOMMENDATIONS:
    '/weighin/personal-feedback/virtual-consultant/tips-and-recommendations',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_TIPS_AND_RECOMMENDATIONS_ARTICLE:
    '/weighin/personal-feedback/virtual-consultant/tips-and-recommendations/article/:link',

  // Search
  SEARCH: '/search',
  SEARCH_RECIPES: '/search/recipes',
  SEARCH_FEATURES: '/search/features',
  SEARCH_SUCCESS_STORIES: '/search/success-stories',
  SEARCH_COMMUNITY_POSTS: '/search/community-posts',

  // Bookmarks
  BOOKMARK: '/bookmarks',
  BOOKMARK_RECIPES: '/bookmarks/recipes',
  BOOKMARK_FEATURES: '/bookmarks/features',
  BOOKMARK_SUCCESS_STORIES: '/bookmarks/success-stories',
  BOOKMARK_COMMUNITY_POSTS: '/bookmarks/community-posts',
  BOOKMARK_FOOD_OPTIMISING: '/bookmarks/food-optimising',
  BOOKMARK_ACTIVITY_VIDEOS: '/bookmarks/activity-videos',

  LEGACY_WEIGH_INS: '/legacy-weigh-ins',
  LEGACY_WEIGH_INS_BEGIN: '/legacy-weigh-ins/begin',
  LEGACY_WEIGH_INS_CONFIRM_DONT_IMPORT: '/legacy-weigh-ins/confirm-dont-import',
  LEGACY_WEIGH_INS_COMPLETE: '/legacy-weigh-ins/complete',

  // General invitation error
  INVITATION_ERROR: '/invitation/error',

  // Free 2 go landing
  FREE_2_GO_LANDING: '/free-2-go-landing',

  // Debug
  PAGE_STATUS: '/status',

  // Access Denied
  ACCESS_DENIED: '/access-denied',

  // Publicity
  SHARE_STORY_OVERVIEW: '/share-story',
  SHARE_STORY_ENTRY: '/share-story/entry',
  SHARE_STORY_ENTRY_DETAILS: '/share-story/entry/details',
  SHARE_STORY_ENTRY_QUESTIONNAIRE: '/share-story/entry/questionnaire',
  SHARE_STORY_READONLY_QUESTIONNAIRE: '/share-story/:entryId/questionnaire',
  SHARE_STORY_ENTRY_PHOTOS: '/share-story/entry/photos',
  COMPETITION_OVERVIEW: '/competition',
  COMPETITION_ENTRY: '/competition/entry',
  COMPETITION_ENTRY_DETAILS: '/competition/entry/details',
  COMPETITION_ENTRY_QUESTIONNAIRE: '/competition/entry/questionnaire',
  COMPETITION_READONLY_QUESTIONNAIRE: '/competition/:entryId/questionnaire',
  COMPETITION_ENTRY_PHOTOS: '/competition/entry/photos',
  CRITERIA_INFORMATION: '/criteria-information',
  PUBLIC_AGREEMENT: '/publicity-agreement',
  SUCCESS_STORY_AGREEMENT: '/success-story-agreement',

  // Multi Factor Authentication
  MFA_REGISTER: '/mfa/register',
  MFA_VERIFY: '/mfa/verify',

  // Advertisement
  ADVERTISEMENT_DETAIL_PAGE: '/detail/:type/:countryId',
  ADS_GUIDE: '/advertising-guide',

  // Podcast
  PODCAST: '/podcast',
  PODCAST_DETAIL: '/podcast/:slug',

  // Activity videos
  ACTIVITY_VIDEOS_LIBRARY: '/activity-videos/library',
  ACTIVITY_VIDEOS_COOLDOWN: '/activity-videos/cool-down',
  ACTIVITY_VIDEOS_FEATURED: '/activity-videos/featured',
  ACTIVITY_VIDEOS_INDIVIDUAL: '/activity-videos/:videoId',

  // Virtual consultant flow
  // - Preview personalized support messages
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_PREVIEW_LANDING: '/preview-vc',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_PREVIEW: '/virtual-consultant-preview/:bucket',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_TIPS_AND_RECOMMENDATIONS_PREVIEW:
    '/personal-feedback-preview/virtual-consultant/tips-and-recommendations/:bucket',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_TIPS_AND_RECOMMENDATIONS_ARTICLE_PREVIEW:
    '/personal-feedback-preview/virtual-consultant/tips-and-recommendations/article/:link/:bucket',

  FILTER_POC: '/filter-poc',

  // Trust Pilot
  TRUST_PILOT: '/trust-pilot-review',

  // Food guide
  FOOD_GUIDE_OVERVIEW: '/food-guide',
  FOOD_GUIDE_FREE_FOOD: '/food-guide/free-food',
  FOOD_GUIDE_HEALTHY_EXTRA_A: '/food-guide/healthy-extra-a',
  FOOD_GUIDE_HEALTHY_EXTRA_B: '/food-guide/healthy-extra-b',
  FOOD_GUIDE_SYNS: '/food-guide/syns',

  FOOD_GUIDE_CATEGORY: '/food-guide/:category',
  FOOD_GUIDE_SUB_CATEGORY: '/food-guide/:category/:subCategory',

  // SMART 404
  SMART_CONTENT_NOT_FOUND_PAGE: '/content-not-found/:slug',

  // Learning
  LEARNING: '/learning',
  LEARNING_MODULE: '/learning/:moduleSlug',
  LEARNING_CHAPTER: '/learning/:moduleSlug/:chapterSlug',
  // A page which can be used to link into the first module
  LEARNING_MODULE_FOOD_OPTIMISING_ESSENTIALS: '/learning/food-optimising-essentials',

  // A heavenly Slimming World Free Food day page
  // - Shown at the end of the syns chapter of the food-optimising-essentials module
  LEARNING_MODULE_FOOD_OPTIMISING_ESSENTIALS_FREE_FOOD_DAY:
    '/learning/food-optimising-essentials/step-3-syns',

  // Reactivate membership
  REACTIVATE_MEMBERSHIP_LANDING_PAGE: '/reactivate-membership-page',
  REACTIVATE_MEMBERSHIP: '/reactivate-membership',
  REACTIVATE_MEMBERSHIP_PAYMENT_CONFIRMATION: '/reactivate-membership/payment-confirmation',

  // Group to online move/transfer
  GROUP_TO_ONLINE_MEMBERSHIP_LANDING_PAGE: '/group-to-online-membership-page',
  GROUP_TO_ONLINE_MEMBERSHIP: '/group-to-online-membership',
  GROUP_TO_ONLINE_MEMBERSHIP_PAYMENT_CONFIRMATION:
    '/group-to-online-membership/payment-confirmation',
  GROUP_TO_ONLINE_MEMBERSHIP_STEPS_TO_SUCCESS_LANDING_PAGE:
    '/group-to-online-membership/steps-to-success-landing-page',

  // Online to group move/transfer
  ONLINE_TO_GROUP_MEMBERSHIP_LANDING_PAGE: '/online-to-group-membership-page',
  ONLINE_TO_GROUP_MEMBERSHIP: '/online-to-group-membership',
  ONLINE_TO_GROUP_MEMBERSHIP_CONFIRMATION: '/online-to-group-membership/confirmation',

  // Shared MembershipWizards - pages
  WIZARD_STEPS_TO_SUCCESS_LANDING_PAGE: '/membership-wizards/steps-to-success-landing-page',

  // Membership payment page
  // - used for the following wizard flows:
  // - Reactivation and the group to online move/transfer
  MEMBERSHIP_PAYMENT_PAGE: '/membership-payment',

  // Advertising artwork (ads)
  ADS_CONTACT_US: '/contact-us/advertisement',
};

export const GROUP_REGISTRATION_FIRST_STEP = Pages.GR_CARD_CHECK;
export const REGISTRATION_FIRST_STEP = Pages.REGISTRATION_PACKAGE_SELECTION;
export default Pages;
