/* global 'template','AlreadyLoggedIn' */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import TextNew from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import MemberType from 'common/src/app/data/enum/MemberType';
import Loader from 'components/atoms/Loader';

import './already-logged-in.scss';

/**
 * Accessing either registration flow and already logged in, this component
 * gets member type and redirects or renders content accordingly
 * If you're logged in online and accessing group reg, you should be allowed to continue.
 */
const AlreadyLoggedIn = (
  {
    submitting = false,
    handleSubmit,
    name,
    userName,
    getMemberType,
    memberPath,
    memberType,
    userId,
    neverPaid,
  },
  context,
  className,
) => {
  useEffect(() => userId && getMemberType(userId), [getMemberType, userId]);

  const isGroupMember = memberType === MemberType.GROUP;

  return memberType === undefined ? (
    <Loader />
  ) : (
    <form className={className} onSubmit={handleSubmit} autoComplete="off">
      <TextNew.Loud
        localeId={
          isGroupMember
            ? `loggedInGroupMember.title`
            : `loggedInOnlineMember.title${neverPaid ? 'NeverPaid' : ''}`
        }
      />
      <TextNew.PrimaryElegant
        localeId={
          isGroupMember
            ? 'loggedInGroupMember.intro'
            : `loggedInOnlineMember.intro${neverPaid ? 'NeverPaid' : ''}`
        }
        localeParams={{
          NAME: name,
          USER_NAME: userName,
        }}
      />
      <Row>
        <Button to={memberPath}>
          <LocaleMessage
            id={isGroupMember ? 'loggedInGroupMember.button' : 'general.cta.continue'}
            params={{ NAME: name }}
          />
        </Button>
        {!isGroupMember && !neverPaid && (
          <Button type="submit" isSecondary disabled={submitting}>
            <LocaleMessage id="loggedInOnlineMember.logoutAndCreateNewAccount" />
          </Button>
        )}
      </Row>
      <FormErrorMessages showGeneral />
    </form>
  );
};

AlreadyLoggedIn.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  name: PropTypes.string,
  neverPaid: PropTypes.bool,
  userName: PropTypes.string,
  getMemberType: PropTypes.func.isRequired,
  memberPath: PropTypes.string,
  memberType: PropTypes.number,
  userId: PropTypes.string,
};

export default withFunctionalClassName('template','AlreadyLoggedIn')(AlreadyLoggedIn);
