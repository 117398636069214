module.exports = {
  "webHost": "account",
  "useServerAuthentication": false,
  "useClientAuthentication": true,
  "persistClientAuthentication": false,
  "useOidcDiscovery": true,
  "clientScopes": [
    "openid"
  ],
  "preloadCriticalCss": false,
  "oneTrustCookieConsentId": "28654904-ed42-44ac-acc1-99207a518450"
};