import { savePurchaseInfo } from 'common/src/app/actions/resources/paymentActions';
import { RESET_AUTH_QUERY } from 'common/src/server/util/AuthenticationHelper/constants';
import Pages from 'common/src/app/data/enum/Pages';
import { createPurchaseSubscription } from 'common/src/app/actions/resources/shopActions';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { userAddressFieldsSelector } from 'common/src/app/selectors/userAccountSelectors';
import isEqual from 'lodash/isEqual';
import { refreshLogin, updateAccount } from 'common/src/app/actions/resources/accountActions';
import TransferTypes, { CanTransferErrors } from 'common/src/app/data/enum/TransferEnums';
import { hasFlag } from 'common/src/app/util/bitwiseUtils';

import { getUTCDateAsISOString } from 'common/src/app/util/dateUtils';

import apiGetCollection from './apiActions/apiGetCollection';
import {
  GATEWAY_SHOP_AUTH,
  GATEWAY_COMMUNITY_AUTH,
  GATEWAY_ACCOUNT_AUTH,
} from '../../data/Injectables';
import { SUBSCRIPTION_PACKAGES } from '../../data/collectionIds';
import { SUBSCRIPTION_PACKAGE } from '../../data/entityTypes';
import { apiGet, apiPost } from './apiActions/apiRequest';

export const TRANSFER_SUBSCRIPTIONS = 'transferActions/TRANSFER_SUBSCRIPTIONS';

export const getRejoinSubscriptionPackages = regionCode => async dispatch =>
  dispatch(
    apiGetCollection(
      TRANSFER_SUBSCRIPTIONS,
      GATEWAY_SHOP_AUTH,
      `/products/subscriptions?subscriptionProductType=extension&countryCode=${regionCode}`,
      SUBSCRIPTION_PACKAGES,
      {},
      {
        entityType: SUBSCRIPTION_PACKAGE,
      },
    ),
  );

export const getRejoinSubscriptionPackagesFromCountryCode = countryCode => async dispatch =>
  dispatch(
    apiGetCollection(
      TRANSFER_SUBSCRIPTIONS,
      GATEWAY_SHOP_AUTH,
      `/products/subscriptions?subscriptionProductType=extension&countryCode=${countryCode}&sort=code`,
      SUBSCRIPTION_PACKAGES,
      {},
      {
        entityType: SUBSCRIPTION_PACKAGE,
      },
    ),
  ).then(response => response);

const createTransferErrorFromResponse = code => {
  const result = CanTransferErrors.find(element => hasFlag(code, element.bitwise));
  return result.error;
};

export const GET_CAN_TRANSFER = 'transferActions/CAN_TRANSFER';

// This call will still return a 200 in a can't transfer situation,
// so we have to map bitwise value to a new error

export const canTransfer = transferType => dispatch =>
  dispatch(apiGet(GET_CAN_TRANSFER, GATEWAY_COMMUNITY_AUTH, '/profile/me/can-transfer')).then(
    response => {
      const responseData = response && response.data;

      const responseCanTransfer =
        transferType === TransferTypes.ONLINE_TO_GROUP
          ? responseData.canTransferToGroup
          : responseData.canTransferToOnline;
      const responseTransferStatusType =
        transferType === TransferTypes.ONLINE_TO_GROUP
          ? responseData.transferToGroupStatusType
          : responseData.transferToOnlineStatusType;

      if (responseData && responseCanTransfer === false) {
        throw new Error(createTransferErrorFromResponse(responseTransferStatusType));
      }
      return response;
    },
  );

export const GET_VOUCHER_INFO = 'transferActions/GET_VOUCHER_INFO';

export const getOnlineVoucherWeeks = userId => dispatch =>
  dispatch(
    apiGet(
      GET_VOUCHER_INFO,
      GATEWAY_ACCOUNT_AUTH,
      `/accounts/${userId}/subscription/transfer-voucher-info`,
    ),
  );

export const TRANSFER_TO_GROUP = 'transferActions/TRANSFER_TO_GROUP';

export const transferToGroup = values => (dispatch, getState) => {
  const state = getState();
  const userId = state.authentication?.userInfo?.sub;

  return dispatch(
    apiPost(
      TRANSFER_TO_GROUP,
      GATEWAY_ACCOUNT_AUTH,
      `/accounts/${userId}/transfer-to-group`,
      values,
    ),
  );
};

export const TRANSFER_TO_ONLINE = 'transferActions/TRANSFER_TO_ONLINE';

const transferToOnline = values => (dispatch, getState) => {
  const state = getState();
  const userId = state.authentication?.userInfo?.sub;

  return dispatch(
    apiPost(
      TRANSFER_TO_ONLINE,
      GATEWAY_ACCOUNT_AUTH,
      `/accounts/${userId}/transfer-to-online`,
      values,
    ),
  );
};

export const CREATE_PACKAGES = 'transferActions/CREATE_PACKAGES';

export const doOnlineTransfer = values => (dispatch, getState) => {
  const {
    targetWeight,
    journeyStartWeight,
    packageId,
    countryCode,
    confirmPickupPreviousJourney,
    startWeight,
    journeyStartDate,
    billingAddress,
  } = values;

  const state = getState();
  const userId = state.authentication?.userInfo?.sub;
  const accountAddress = userAddressFieldsSelector(state, userId);

  const submitNewAddress = !isEqual(billingAddress, accountAddress);

  if (submitNewAddress) {
    dispatch(updateAccount(billingAddress, userId));
  }

  let transferToOnlinePayload;

  // The member has:
  // - chosen to NOT continue their existing journey
  // - or they CANNOT continue and must restart or start again
  // Values
  // - use journeyStartWeight if one has been set - otherwise use the startWeight
  // - use journeyStartDate if one has been set - otherwise use todays date
  if (!confirmPickupPreviousJourney) {
    transferToOnlinePayload = {
      journeyStartDetails: {
        currentWeight: journeyStartWeight || startWeight,
        startDateUTC: journeyStartDate || getUTCDateAsISOString(),
        targetWeight,
      },
    };
  }

  return dispatch(transferToOnline(transferToOnlinePayload))
    .then(() => dispatch(refreshLogin()))
    .then(() =>
      dispatch(
        createPurchaseSubscription(
          {
            personalDetails: {
              ...values.names,
            },
            billingAddress: {
              ...billingAddress,
            },
            shippingAddress: {
              ...billingAddress,
            },
          },
          packageId,
        ),
      ),
    )
    .then(response => {
      savePurchaseInfo(response.id, {
        [shopFields.PACKAGE]: packageId,
        [shopFields.VOUCHER_CODE]: '',
        countryCode,
        successRedirect: Pages.GROUP_TO_ONLINE_MEMBERSHIP_STEPS_TO_SUCCESS_LANDING_PAGE,
      });

      // Save the wizardDataFromBeforePayment data into a session storage item
      // - this data is to be used on the restart page and also the steps to success landing page
      if (typeof sessionStorage !== 'undefined') {
        // Clean out old wizardDataFromBeforePayment if a new one is created
        if (sessionStorage.getItem('wizardDataFromBeforePayment')) {
          sessionStorage.removeItem('wizardDataFromBeforePayment');
        }

        const wizardDataFromBeforePayment = {
          purchaseId: response.id,
        };

        sessionStorage.setItem(
          'wizardDataFromBeforePayment',
          JSON.stringify(wizardDataFromBeforePayment),
        );
      }
      window.location.href = `${Pages.MEMBERSHIP_PAYMENT_PAGE}?${RESET_AUTH_QUERY}`;
    });
};
