import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import GeneralError from 'components/templates/GeneralError';
import Application from './Application';

const mapStateToProps = state => ({
  hasGeneralError: !!state?.error?.length,
  memberHost: state.config.environmentConfig.web.member.host,
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(GeneralError)),
});

export default compose(addInitAction, connected)(Application);
