import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import BodyMagicIcons from 'common/src/app/enums/BodyMagicIcons';
import BodyMagicAwardGradients from 'common/src/app/data/enum/BodyMagicAwardGradients';
import BodyMagicAwards from 'common/src/app/data/enum/BodyMagicAwards';

import './body-magic-award-icon.scss';

const BodyMagicAwardIcon = (
  { colourId, width, height, fill, iconType },
  context,
  className,
  dataTestId,
) => {
  const colourIndex = BodyMagicAwardGradients[colourId];

  if (!colourIndex) return null;

  const isStarWithStand = iconType === BodyMagicIcons.StarWithStand;
  const viewBox = isStarWithStand ? '0 0 19 25' : '0 0 17 17';

  const linearGradient = (
    <linearGradient
      x1="50%"
      y1="0%"
      x2="50%"
      y2="50%"
      id={`linearGradient-${colourId}-${uniqueId()}`}
    >
      <stop
        data-testid={`dark-${colourId}`}
        stopColor={`${colourIndex.stopColourDark}`}
        offset="0%"
      />
      <stop
        data-testid={`light-${colourId}`}
        stopColor={`${colourIndex.stopColourLight}`}
        offset="100%"
      />
    </linearGradient>
  );

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      data-testid={dataTestId}
      aria-hidden="true"
    >
      <defs>{linearGradient}</defs>
      {isStarWithStand ? (
        <>
          <path
            className="stand"
            d="M4.8,23.2c0.4-1.9,1.8-3.9,4.8-3.9c3.1,0,4.4,1.9,4.8,3.9C14.9,25.2,4.5,25.1,4.8,23.2z"
          />
          <path
            data-testid="star"
            className="star"
            stroke={linearGradient ? `url(#${linearGradient.props.id})` : ''}
            fill={linearGradient && fill ? `url(#${linearGradient.props.id})` : 'none'}
            d="
      M9.7,3.8l4.5-3c0.2-0.1,0.4-0.2,0.7-0.1c0.5,0.1,0.8,0.6,0.7,1.1l-1.3,5.6l4.1,3.8c0.2,0.2,0.3,0.4,0.3,0.6c0,0.5-0.3,0.9-0.8,1
      l-5.4,0.4l-2,5.3c-0.1,0.2-0.3,0.4-0.5,0.5c-0.4,0.2-1,0-1.1-0.5c0,0,0,0,0,0l-2-5.3l-5.4-0.4c-0.2,0-0.4-0.1-0.6-0.3
      c-0.3-0.4-0.3-0.9,0.1-1.3l4.1-3.8L3.8,1.8c-0.1-0.2,0-0.5,0.1-0.7c0.2-0.4,0.8-0.5,1.2-0.3c0,0,0,0,0,0L9.7,3.8L9.7,3.8z"
          />
        </>
      ) : (
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.2251 13.6431L12.0897 15.9205C12.8152 16.3481 13.6858 15.6871 13.5209 14.8341L12.6422 10.2897L15.9093 7.15298C16.5227 6.56414 16.1901 5.49471 15.3627 5.39501L10.955 4.86394L9.1096 0.647853C8.76317 -0.143603 7.68703 -0.143604 7.3406 0.647852L5.49518 4.86394L1.0875 5.39501C0.260083 5.49471 -0.0724644 6.56414 0.540853 7.15298L3.808 10.2897L2.92932 14.8341C2.76438 15.6871 3.63499 16.3481 4.36047 15.9205L8.2251 13.6431Z"
          fill={linearGradient && fill ? `url(#${linearGradient.props.id})` : 'none'}
          stroke={linearGradient ? `url(#${linearGradient.props.id})` : ''}
        />
      )}
    </svg>
  );
};

BodyMagicAwardIcon.defaultProps = {
  width: 60,
  height: 60,
  fill: false,
  iconType: BodyMagicIcons.StarWithStand,
};

BodyMagicAwardIcon.propTypes = {
  colourId: PropTypes.oneOf(Object.values(BodyMagicAwards)),
  fill: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  iconType: PropTypes.string,
};

export const BodyMagicAwardIconProps = BodyMagicAwardIcon;

export default withFunctionalClassName(
  ComponentType.ATOM,
  'BodyMagicAwardIcon',
)(BodyMagicAwardIcon);
