import createAction from 'redux-actions/lib/createAction';

export const SET_PASSWORD_VISIBILITY = 'passwordVisibilityActions/SET_PASSWORD_VISIBILITY';

export const showPassword = createAction(
  SET_PASSWORD_VISIBILITY,
  () => true,
  (form, field) => ({ form, field }),
);

export const hidePassword = createAction(
  SET_PASSWORD_VISIBILITY,
  () => false,
  (form, field) => ({ form, field }),
);
