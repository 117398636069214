const UserRole = {
  ADMINISTRATOR: 'Administrator',
  DEVELOPER: 'Developer',
  GROUP_MODERATOR: 'GroupModerator',
  SITE_MODERATOR: 'SiteModerator',
  CUSTOMER_SUPPORT: 'CustomerSupport',
  CONTENT_EDITOR: 'ContentEditor',
  MEMBER: 'Member',
  DATA_ANALYST: 'DataAnalyst',
  MAGAZINE_ADMINISTRATOR: 'MagazineAdministrator',
  SINGLE_MAGAZINE_ADMINISTRATOR: 'SingleMagazineAdministrator',
  PRODUCT_ADMINISTRATOR: 'ProductAdministrator',
  CONSULTANT: 'Consultant',
  FREE2GO: 'Free2Go',
};

export const ElevatedRole = [
  UserRole.ADMINISTRATOR,
  UserRole.DEVELOPER,
  UserRole.GROUP_MODERATOR,
  UserRole.SITE_MODERATOR,
  UserRole.CUSTOMER_SUPPORT,
  UserRole.CONTENT_EDITOR,
  UserRole.DATA_ANALYST,
  UserRole.MAGAZINE_ADMINISTRATOR,
  UserRole.SINGLE_MAGAZINE_ADMINISTRATOR,
  UserRole.PRODUCT_ADMINISTRATOR,
];

export default UserRole;
