export default {
  EXISTING_ACCOUNT_VALID_ONLINE_SUBSCRIPTION: 1,
  EXISTING_ACCOUNT_NO_VALID_ONLINE_OR_GROUP: 2,
  CARD_ASSOCIATED_WITH_ANOTHER_EMAIL_ADDRESS: 3,
  NEW_USER_NEW_CARD: 4,
  CARD_AND_PIN_COMBINATION_NOT_VALID: 5,
  CONTACT_SUPPORT: 6,
  EXISTING_ACCOUNT_NEW_CARD: 7,
  ACCOUNT_EXISTS_IN_THE_LEGACY_SYSTEM: 8,
};
