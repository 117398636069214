import createAction from 'redux-actions/lib/createAction';
import { apiGet } from './resources/apiActions/apiRequest';
import { GATEWAY_SELF } from '../data/Injectables';

export const PROCESS_STAGE_STATUS_CONFIG = 'processPageStatusConfig/PROCESS_STAGE_STATUS_CONFIG';

export const processPageStatusConfig = createAction(
  PROCESS_STAGE_STATUS_CONFIG,
  (config, [rootRoute, ...otherRoutes]) => {
    const pageStatusRoute = otherRoutes[otherRoutes.length - 1];
    const paths = rootRoute.childRoutes.reduce(function processRoute(result, currentRoute) {
      const newPaths = [];

      if (
        currentRoute.path &&
        currentRoute.path !== '*' &&
        currentRoute.path !== pageStatusRoute.path &&
        (currentRoute.component || currentRoute.getComponent)
      ) {
        newPaths.push(currentRoute.path);
      }

      if (currentRoute.childRoutes) {
        newPaths.push(...currentRoute.childRoutes.reduce(processRoute, []));
      }

      result.push(...newPaths);
      return result;
    }, []);

    return { paths };
  },
);

export const RUN_STATUS_CHECK = 'pageStatusActions/RUN_STATUS_CHECK';

export const runStatusCheck = config => (dispatch, getState) => {
  const {
    pageStatus: { pages },
  } = getState();

  return dispatch({
    type: RUN_STATUS_CHECK,
    payload: Object.keys(pages).reduce(
      (last, path) => last.then(() => dispatch(runPageStatusCheck(path, config[path]))),
      Promise.resolve(),
    ),
  });
};

export const RUN_PAGE_STATUS_CHECK = 'pageStatusActions/RUN_PAGE_STATUS_CHECK';

export const runPageStatusCheck =
  (page, config = {}) =>
  dispatch => {
    // remove optional parameters
    const routePath = page.replace(/\([^)]+\)/g, '');

    if (routePath.includes(':')) {
      if (config.getParams) {
        return Promise.resolve(dispatch(config.getParams())).then(params => {
          const newPath = Object.keys(params).reduce(
            (result, param) => result.replace(`:${param}`, params[param]),
            routePath,
          );

          if (newPath.indexOf(':') >= 0) {
            return dispatch({
              type: RUN_PAGE_STATUS_CHECK,
              payload: Promise.resolve({ status: 'ignored', reason: 'params' }),
              meta: { page },
            });
          }

          return dispatch(
            apiGet(
              RUN_PAGE_STATUS_CHECK,
              GATEWAY_SELF,
              newPath,
              { debugMode: 1 },
              { redirect: 'error' },
              { page },
            ),
          ).catch(() => {});
        });
      }

      return dispatch({
        type: RUN_PAGE_STATUS_CHECK,
        payload: Promise.resolve({ status: 'ignored', reason: 'params' }),
        meta: { page },
      });
    }

    return dispatch(
      apiGet(
        RUN_PAGE_STATUS_CHECK,
        GATEWAY_SELF,
        routePath,
        { debugMode: 1 },
        { redirect: 'error' },
        { page },
      ),
    ).catch(() => {});
  };
