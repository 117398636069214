/* global 'molecule','CloseButton' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Button from '../../atoms/Button';
import TextNew, { Colors, TextTypes } from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import './close-button.scss';

const CloseButton = (
  {
    label,
    screenreaderLabel,
    type,
    labelColor,
    to,
    onClick,
    icon,
    customIconSize,
    isSmall,
    deviceState,
    reference,
  },
  context,
  className,
) => {
  const iconSize =
    !isSmall && deviceState > DeviceState.SM ? customIconSize ?? 50 : customIconSize ?? 30;

  return (
    <div className={className}>
      <Button isTertiary to={to} onClick={onClick} reference={reference} id="closeButton">
        {screenreaderLabel && <div className="screen-reader">{screenreaderLabel}</div>}
        {label && (
          <TextNew color={labelColor} type={type}>
            {label}
          </TextNew>
        )}
        {icon &&
          React.cloneElement(icon, {
            width: iconSize,
            height: iconSize,
          })}
      </Button>
    </div>
  );
};

CloseButton.defaultProps = {
  type: TextTypes.FORMAL_ORIGINAL,
  labelColor: Colors.PRIMARY_DARK,
  icon: <Icon rounded name={IconName.CROSS} />,
};

CloseButton.propTypes = {
  /**
   * Color
   */
  labelColor: PropTypes.string,
  /**
   * Styles type
   */
  type: PropTypes.string,
  /**
   * Option label next to the close button, Allows LocaleMessage
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Turns the button from <button> to <Link>
   */
  to: PropTypes.string,
  /**
   * Click handler
   */
  onClick: PropTypes.func,
  /**
   * Custom icon component can be passed
   */
  icon: PropTypes.node,
  /**
   * Custom icon size to override defaults
   */
  customIconSize: PropTypes.number,
  /**
   * Boolean indicating whether to show the small version all the time or not
   */
  isSmall: PropTypes.bool,
  deviceState: PropTypes.number,
  screenreaderLabel: PropTypes.node,
  reference: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default withDeviceState()(withFunctionalClassName('molecule','CloseButton')(CloseButton));
