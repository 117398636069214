import { connect } from 'react-redux';
import { compose } from 'redux';
import { getConfirmEmailChange } from 'common/src/app/actions/resources/accountActions';
import ConfirmEmailChange from './ConfirmEmailChange';

const mapDispatchToProps = {
  getConfirmEmail: getConfirmEmailChange,
};

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(ConfirmEmailChange);
