/**
 * Available unit types
 * @type {{PIXEL: string, PERCENTAGE: string, EM: string}}
 */
const Unit = {
  PIXEL: 'px',
  PERCENTAGE: '%',
  EM: 'em',
};

Unit.propTypes = Object.values(Unit);

export default Unit;
