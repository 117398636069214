import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import classNames from 'classnames';
import Link, { LinkProps } from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import prependWebHost from 'common/src/app/util/prependWebHost';

import { WebHostLinkProps } from '.';

const WebHostLink = (
  {
    environmentConfig,
    children,
    link,
    webHost,
    newWindow,
    innerRef,
    onClick,
    shouldPrependWebHost = true,
    ...props
  }: WebHostLinkProps,
  _context: any,
  className: string,
  dataTestid: string,
) => {
  const path = shouldPrependWebHost ? prependWebHost(environmentConfig, link, webHost) : link;
  const linkProps: LinkProps = {
    [path === link && (!newWindow || newWindow === 'self') ? 'to' : 'href']: path,
  } as unknown as LinkProps;

  if (!link) {
    return (
      <button onClick={onClick} {...props} className={classNames(className, props.className)}>
        {children}
      </button>
    );
  }

  if (newWindow === 'blank') {
    linkProps.target = path;
  }

  return (
    <Link
      data-testid={dataTestid}
      ref={innerRef || null}
      onClick={onClick}
      {...linkProps}
      {...props}
      className={classNames(className, props.className)}
    >
      {children}
    </Link>
  );
};

export default withFunctionalClassName(ComponentType.ATOM, 'WebHostLink')(WebHostLink);
