import React from 'react';
import PropTypes from 'prop-types';
import Configuration from 'common/src/app/config/Configuration';
import { ERROR_BMI_NOT_ALLOWED, ERROR_BMI_TOO_LOW } from 'common/src/app/data/validationErrorCodes';
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import BlockingFormError from '../BlockingFormError';
import TextNew from '../../atoms/TextNew';
import Button from '../../atoms/Button';
import LocaleMessage from '../../atoms/LocaleMessage';

const ReturnButton = ({ href }) => (
  <Button href={href} cid="back-public">
    <LocaleMessage id="bmiError.backPublic" />
  </Button>
);

const OnlineBmiHealthy = () => (
  <>
    <TextNew.Loud localeId="bmiError.onlineBmiHealthly.fantastic" />
    <TextNew.PrimaryElegant localeId="bmiError.onlineBmiHealthly.healthyWeight" />
    <TextNew.PrimaryElegant localeId="bmiError.onlineBmiHealthly.healthProblems" />
  </>
);

const OnlineBmiLow = () => (
  <>
    <TextNew.PrimaryElegant localeId="bmiError.onlineBmiLow.underweight" />
    <TextNew.PrimaryElegant localeId="bmiError.onlineBmiLow.concernWeight" />
    <TextNew.PrimaryElegant localeId="bmiError.onlineBmiLow.unableMembership" />
  </>
);

const GroupBmiLow = () => (
  <>
    <TextNew.PrimaryElegant localeId="bmiError.groupBmiLow.title" />
    <TextNew.PrimaryElegant localeId="bmiError.groupBmiLow.message" />
  </>
);

const BmiError = ({ fieldName, publicHost, isGroupRegister }) => (
  <span>
    <BlockingFormError
      errorCode={ERROR_BMI_NOT_ALLOWED}
      fields={[measurementFields.HEIGHT, fieldName]}
      cid="bmi-ok-message"
    >
      <OnlineBmiHealthy />
      <ReturnButton href={publicHost} />
    </BlockingFormError>
    <BlockingFormError
      errorCode={ERROR_BMI_TOO_LOW}
      fields={[measurementFields.HEIGHT, fieldName]}
      cid="bmi-too-low-message"
    >
      {isGroupRegister ? <GroupBmiLow /> : <OnlineBmiLow />}
      <ReturnButton href={publicHost} />
    </BlockingFormError>
  </span>
);

BmiError.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isGroupRegister: PropTypes.bool,
  publicHost: PropTypes.string,
};

ReturnButton.propTypes = {
  href: PropTypes.string.isRequired,
};

export default BmiError;
