/* global 'molecule','RegistrationError' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import CustomScrollBar from 'components/molecules/CustomScrollBar';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { ElementTypes, Colors } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';

import './registration-error.scss';

/**
 * Failed group registration card check
 */
const RegistrationError = (
  { accountLoginUrl, updateGroupCardUrl, updateGroupCard },
  context,
  className,
) => (
  <div className={className}>
    <CustomScrollBar height="100%" position="absolute">
      <Wrapper padding="xxl">
        <TextNew.Confident
          localeId="cardCheck.registrationError.title"
          element={ElementTypes.H1}
          color={Colors.DARK_TONE}
          cid="title"
        />
        <TextNew.PrimaryElegant localeId="cardCheck.registrationError.content" cid="body" />
        <Button href={updateGroupCard ? updateGroupCardUrl : accountLoginUrl}>
          <LocaleMessage id="cardCheck.registrationError.continueToLogin" />
        </Button>
      </Wrapper>
    </CustomScrollBar>
  </div>
);

RegistrationError.propTypes = {
  accountLoginUrl: PropTypes.string,
  updateGroupCardUrl: PropTypes.string,
  updateGroupCard: PropTypes.bool,
};

export default withFunctionalClassName('molecule','RegistrationError')(RegistrationError);
