import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Configuration from 'common/src/app/config/Configuration';
import DataTestidOverides from 'common/src/app/data/enum/DataTestidOverides';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import { getLinkPropsFromUrl } from 'common/src/app/util/urlUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from '../Icon';

import './slimming-world-logo.scss';

// Todo: SWO-6453 remove these values when correctly sized logo svg added
const CLASSIC_LOGO_HEIGHT = 49;
const CLASSIC_LOGO_WIDTH = 100;

const SlimmingWorldLogo = ({ link, disableMobileLogo, themedLogo, deviceState, children }) => {
  const useClassicLogo = Configuration.logoIconName === IconName.LOGO_CLASSIC;

  const { name, height, width } = themedLogo || '';

  const defaultSizes = () => {
    if (height && width) {
      return { height, width };
    }
    if (useClassicLogo) {
      return { height: CLASSIC_LOGO_HEIGHT, width: CLASSIC_LOGO_WIDTH };
    }
    return { height: null, width: null };
  };

  return (
    <Link
      {...getLinkPropsFromUrl(link)}
      data-testid={DataTestidOverides.LOGO}
      className={functionalComponentClassName(ComponentType.ATOM, 'SlimmingWorldLogo', ['href'], {
        'no-link': !link,
        'mobile-logo': deviceState <= DeviceState.LG && !disableMobileLogo,
        'is-classic': useClassicLogo,
      })}
      aria-label="Home"
    >
      {deviceState <= DeviceState.LG && !disableMobileLogo ? (
        <Icon name={name || Configuration.logoMobileIconName} {...defaultSizes()} />
      ) : (
        <Icon name={name || Configuration.logoIconName} {...defaultSizes()} />
      )}
      {children}
    </Link>
  );
};

SlimmingWorldLogo.defaultProps = {
  disableMobileLogo: false,
};

SlimmingWorldLogo.propTypes = {
  link: PropTypes.string,
  deviceState: PropTypes.number,
  disableMobileLogo: PropTypes.bool,
  themedLogo: PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  children: PropTypes.node,
};

export default withDeviceState()(SlimmingWorldLogo);
