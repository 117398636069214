// these numbers are expected from the API.
// use '0' for general
import fromPairs from 'lodash/fromPairs';

export const ContactFormSubjectIds = {
  ONLINE_TECHNICAL_HELP: 'id-1',
  GROUP_INFORMATION: 'id-7',
  GROUP_CUSTOMER_SERVICES: 'id-8',
  HI_FI_BARS: 'id-10',
  GROUP_TECHNICAL_HELP: 'id-12',
  ACCOUNT_AND_MEMBERSHIP_QUERIES: 'id-20',
  TRANSFERING_TO_ONLINE: 'id-22',
};

export const ContactFormSubjectCategory = {
  [ContactFormSubjectIds.ONLINE_TECHNICAL_HELP]: 'onlineTechnicalHelp',
  [ContactFormSubjectIds.GROUP_INFORMATION]: 'groupInformation',
  [ContactFormSubjectIds.GROUP_CUSTOMER_SERVICES]: 'groupCustomerServices',
  [ContactFormSubjectIds.HI_FI_BARS]: 'hiFiBars',
  [ContactFormSubjectIds.GROUP_TECHNICAL_HELP]: 'groupTechnicalHelp',
  [ContactFormSubjectIds.ACCOUNT_AND_MEMBERSHIP_QUERIES]: 'accountAndMembershipQueries',
  [ContactFormSubjectIds.TRANSFERING_TO_ONLINE]: 'transferingToOnline',
};

const toCategories = (names = []) =>
  fromPairs(
    names.map(name => [
      ContactFormSubjectIds[name],
      ContactFormSubjectCategory[ContactFormSubjectIds[name]],
    ]),
  );

export const ContactFormCategory = {
  online: toCategories(['ONLINE_TECHNICAL_HELP', 'ACCOUNT_AND_MEMBERSHIP_QUERIES']),
  group: toCategories([
    'GROUP_INFORMATION',
    'GROUP_CUSTOMER_SERVICES',
    'GROUP_TECHNICAL_HELP',
    'HI_FI_BARS',
    'TRANSFERING_TO_ONLINE',
  ]),
};

export const getSubjectOptions = ({ userType, getMessage }) =>
  Object.keys(ContactFormCategory[userType]).reduce(
    (subjects, key) => [
      ...subjects,
      {
        value: key,
        title: getDropdownLabel(userType, getMessage, key),
      },
    ],
    [],
  );

export const getDropdownLabel = (userType, getMessage, key) => {
  // The public form has different wording for the online and group technical help drop down options
  if (userType === 'public') {
    if (key === ContactFormSubjectIds.ONLINE_TECHNICAL_HELP) {
      return getMessage(`contactUsForm.categories.${'publicOnlineTechnicalHelp'}`);
    }

    if (key === ContactFormSubjectIds.GROUP_TECHNICAL_HELP) {
      return getMessage(`contactUsForm.categories.${'publicGroupTechnicalHelp'}`);
    }
  }

  return getMessage(`contactUsForm.categories.${ContactFormCategory[userType][key]}`);
};

export default ContactFormCategory;
