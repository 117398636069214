/* global WP_DEFINE_IS_NODE */
import { connect } from 'react-redux';
import groupOrOnlineSelector, {
  memberIsAccountType,
} from 'common/src/app/selectors/accountStateSelectors';
import AccountState, { accountStateTypeStrings } from 'common/src/app/data/enum/AccountState';
import React from 'react';
import { userAccountSelector } from 'common/src/app/selectors/userAccountSelectors';
import MemberType, { MemberTypeStrings } from 'common/src/app/data/enum/MemberType';

/**
 * HoC for providing account state as a string - also called a claim by the BE
 * Also provides function for appending account type on to the end of a locale e.g. locale.group or locale.online
 * @returns accountType: "string" getClaimSpecificLocale: fn
 * @example in Component.js
 * export default compose(
 * anotherHoC(),
 * accountStateProvider(),
 * )(Component);
 * @example or without other HoCs
 * export default accountStateProvider()(Component)
 */
const accountStateProvider = () => WrappedComponent => {
  const HandleStateProvider = props => <WrappedComponent {...props} />;

  HandleStateProvider.displayName = `HandleStateProvider(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;
  return connect(state => {
    if (WP_DEFINE_IS_NODE) {
      return {};
    }
    const userAccount = userAccountSelector(state);
    const accountType = userAccount && groupOrOnlineSelector(userAccount.memberType);

    const isGroupMember = accountType === MemberTypeStrings.GROUP;
    const isOnlineMember = accountType === MemberTypeStrings.ONLINE;

    const memberTypeString = () => {
      if (isGroupMember) {
        return MemberTypeStrings.GROUP;
      }
      if (isOnlineMember) {
        return MemberTypeStrings.ONLINE;
      }
      return MemberTypeStrings.PUBLIC;
    };
    const getClaimSpecificLocale = localeId => `${localeId}.${memberTypeString()}`;
    return {
      isOnlineMember,
      isGroupMember,
      accountType,
      getClaimSpecificLocale,
      memberTypeString: memberTypeString(),
    };
  })(HandleStateProvider);
};
export {
  AccountState,
  memberIsAccountType,
  accountStateTypeStrings,
  MemberType,
  MemberTypeStrings,
};
export default accountStateProvider;
