import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';
import FormGroup from '../FormGroup';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import LocaleMessage from '../../atoms/LocaleMessage';
import Row from '../../atoms/Row';
import InformationToggle from '../InformationToggle';

const SecurityQuestionAnswerEntry = (
  { securityQuestions, titleLocale, forwardRef },
  { getMessage },
) => (
  <FormGroup label={<LocaleMessage id={titleLocale} />} type="stacked">
    <Row column>
      <EnhancedField
        name={userDetailFields.SECURITY_QUESTION}
        id="securityQuestion"
        placeholder={getMessage('general.securityQuestion.securityQuestionsPlaceholder')}
        component={Select}
        options={securityQuestions || {}}
        selectRef={forwardRef?.questionRef}
        cid="choose-personal-question"
        suppress
      />

      <FormErrorMessages fields={[userDetailFields.SECURITY_QUESTION]} id="securityQuestionError" />

      <EnhancedField
        name={userDetailFields.SECURITY_ANSWER}
        id="securityQuestionAnswer"
        placeholder={getMessage('general.securityQuestion.securityAnswerPlaceholder')}
        component={Input}
        type="text"
        inputRef={forwardRef?.answerRef}
        suppress
      />

      <FormErrorMessages fields={[userDetailFields.SECURITY_ANSWER]} id="securityAnswerError" />
    </Row>

    <InformationToggle
      id="security-question-info"
      label={<LocaleMessage id="general.securityInfoLabel" />}
      information={<LocaleMessage id="general.securityInfo" />}
    />
  </FormGroup>
);

SecurityQuestionAnswerEntry.defaultProps = {
  hasGroups: false,
  route: {
    showSecurityQuestion: false,
  },
  titleLocale: 'general.securityQuestion.securityLabel',
};

SecurityQuestionAnswerEntry.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

SecurityQuestionAnswerEntry.propTypes = {
  securityQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  titleLocale: PropTypes.string.isRequired,
  forwardRef: PropTypes.object,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'SecurityQuestionAnswerEntry',
)(SecurityQuestionAnswerEntry);
