import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { getPackage, getPurchase } from 'common/src/app/actions/resources/shopActions';
import Pages from 'common/src/app/data/enum/Pages';
import { push as historyPush } from 'react-router-redux';

import { getInviteRegistration } from 'common/src/app/actions/registrationActions';
import { commonTrackEvent } from 'common/src/app/actions/trackingActions';
import DataLayerKeys from 'common/src/app/data/enum/Tracking/DataLayerKeys';
import { CREATE_ACCOUNT } from 'common/src/app/data/purchaseTypes';
import Finished from './Finished';

const connected = connect(
  () =>
    (state, { location: { query } }) => {
      const packageDetail = state.entities?.package?.[query?.packageType] || {};
      return {
        packageDetail,
        memberHost: state.config.environmentConfig.web.member.host,
        skipPayment: query?.skipReason,
      };
    },
  (dispatch, { location: { query } }) => ({
    loginRedirect: () =>
      dispatch(() =>
        dispatch(
          historyPush({
            pathname: `${Pages.LOGIN}`,
          }),
        ),
      ),
    init: () =>
      dispatch((__, getState) => {
        const promises = [];
        const state = getState();

        const inviteId = state.registration?.invite?.id || '';

        // only load packages when displaying a payment success
        if (query?.purchaseId && query?.packageType) {
          const { purchaseId, packageType } = query;
          if (inviteId) {
            promises.push(dispatch(getInviteRegistration(inviteId)));
          }
          promises.push(
            dispatch(getPurchase(purchaseId)).then(purchase => {
              if (purchase) {
                dispatch(
                  commonTrackEvent(null, {
                    [DataLayerKeys.PURCHASE_TYPE]: CREATE_ACCOUNT,
                    [DataLayerKeys.PURCHASE_ID]: purchase.entity.id,
                    [DataLayerKeys.PURCHASE_VALUE]: purchase.entity.price.amount,
                    [DataLayerKeys.PURCHASE_NAME]: purchase.entity.name,
                    [DataLayerKeys.PURCHASE_CURRENCY]: purchase.entity.price.currencyCode,
                  }),
                );
              }
            }),
          );

          promises.push(
            dispatch(getPackage(packageType)).then(result => {
              if (result) {
                dispatch(
                  commonTrackEvent(null, {
                    [DataLayerKeys.PACKAGE_NAME]: result.entity.name,
                  }),
                );
              }
            }),
          );
        }
        return Promise.all(promises);
      }),
  }),
);

export default compose(connected)(Finished);
