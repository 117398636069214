module.exports = function() {var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error('Can\'t apply offset:' + offset + ' to argument `' + name +
                                    '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function(value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  if (key in data) return data[key];
  return data.other;
};
var select = function(value, data) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  return data.other;
};
var fmt = {
  comma: function (value, ls, args) {
    var subtract = 0;
    [].concat(args).forEach(function (arg) {
      var match = arg && arg.match(/subtract:([0-9]+)/);
      if (match) {
        subtract = parseInt(match[1], 10);
      }
    });
    var numValue = typeof value === 'number' ? value : parseInt(value, 10);
    return (numValue - subtract).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

return {
  multiFactorAuthenticationForm: {
    description: function(d) { return "After scanning the barcode image, the app will display a 6-digit code that you can enter below. If you have problems scanning the QR code, do a manual setup with code " + d.MANUAL_CODE; },
    descriptionError: function(d) { return "QR code failed to generate, we are missing some information to generate the QR code."; },
    form: {
      placeholder: {
        code: function(d) { return "Enter the 6-digit code from your app"; }
      },
      label: {
        code: function(d) { return "6-digit verification code"; }
      },
      error: {
        code: {
          validator: function(d) { return "Please enter 6-digit code from your app"; },
          match: function(d) { return "The entered 6-digit code does not match with the authenticator"; }
        }
      },
      cta: {
        submit: function(d) { return "Verify"; }
      }
    }
  },
  emailVerificationResult: {
    title: function(d) { return "You’re good to go!"; },
    subTitle: function(d) { return "Thanks for confirming your email."; },
    linkLabel: function(d) { return "Back to home page"; },
    errors: {
      invalid: {
        title: function(d) { return "Confirming email failed"; },
        subTitle: function(d) { return "Your email verification link has expired; please click on the link on your homepage to receive a new email verification message."; }
      },
      alreadyprocessed: {
        title: function(d) { return "Confirming email failed"; },
        subTitle: function(d) { return "Don’t worry - your email address has already been verified. Log onto the web or app and continue your weight loss journey."; }
      },
      required: {
        title: function(d) { return "Confirming email failed"; },
        subTitle: function(d) { return "The token is missing."; }
      }
    },
    errorBoundaryTitle: function(d) { return "confirm email"; }
  },
  loggedInGroupMember: {
    title: function(d) { return "Save time on your next log in"; },
    intro: function(d) { return "You're already registered with us, the quickest way to log in is to use the log in link from the Slimming World homepage"; },
    button: function(d) { return "Continue"; }
  },
  loggedInOnlineMember: {
    title: function(d) { return "Logged in"; },
    titleNeverPaid: function(d) { return "Nearly there!"; },
    intro: function(d) { return "Hey " + d.NAME + ", We noticed you are trying to register an account but you are already logged in as " + d.USER_NAME + ". How would you like to proceed?"; },
    introNeverPaid: function(d) { return "There are just a few more steps to complete your Slimming World Online registration process."; },
    logoutAndCreateNewAccount: function(d) { return "Logout and create new account"; },
    continueAsMember: function(d) { return "Continue as " + d.NAME; },
    goBack: function(d) { return "Go Back"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  changeEmail: {
    title: function(d) { return "Change email"; },
    newEmail: function(d) { return "New Email"; },
    originalPassword: function(d) { return "Current Password"; },
    succeeded: function(d) { return "Please click the confirmation link in the email we've just sent to your new email address to complete this update."; },
    goBack: function(d) { return "Go Back"; },
    errors: {
      password: {
        required: function(d) { return "Please enter your current password"; }
      }
    },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    errorBoundaryTitle: function(d) { return "change email"; }
  },
  changePassword: {
    title: function(d) { return "Update password"; },
    confirmation: function(d) { return "Password updated"; },
    introText: function(d) { return "Hi " + d.NAME + ", please choose your new password"; },
    infoText: function(d) { return "Just so you know, you’ll be logged out of all active sessions including those on other devices."; },
    submitButton: function(d) { return "Change my password"; },
    errors: {
      oldPassword: {
        required: function(d) { return "Please enter your current password"; }
      }
    },
    goBack: function(d) { return "Go Back"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    youHaveChangedYourPassword: function(d) { return "Great – you’ve successfully changed your password. Please sign in again to get back to the Slimming World website."; },
    errorBoundaryTitle: function(d) { return "change password"; }
  },
  dualAccess: {
    title: function(d) { return "You have access to both Slimming World Online and " + d.GROUP_SERVICE + " "; },
    subTitle: function(d) { return "Please choose which version of the site you'd like to log into, and we'll provide stories, features and support tailored to you"; },
    group: function(d) { return d.GROUP_SERVICE; },
    online: function(d) { return "Slimming World Online"; }
  },
  forgotPassword: {
    title: function(d) { return "Forgot Password"; },
    introText: function(d) { return "Please enter your email address and we'll email you a link to reset your password."; },
    submitButton: function(d) { return "Submit"; },
    backLink: function(d) { return "Back to login"; },
    emailPlaceholder: function(d) { return "name@domain.com"; },
    confirmationMessage: function(d) { return "If your email is linked to a Slimming World account we’ve sent you instructions on how to reset your password."; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    errorBoundaryTitle: function(d) { return "forgot password"; }
  },
  legacyAppInfo: {
    singleApp: {
      notAvailable: function(d) { return "We're afraid this version of our app isn't available to you yet."; },
      workingHard: function(d) { return "We're working hard to make it available to all our members and in the meantime you can still use our Slimming World app."; }
    },
    free2Go: {
      notAvailable: function(d) { return "We're afraid this app isn’t available to you yet."; },
      workingHard: function(d) { return "Our Free2Go app is specifically designed for our young group members registered on our brand new service. If you would like more information, please speak to your Slimming World Consultant."; }
    },
    rightApp: function(d) { return "Get the right app"; }
  },
  legacyLogin: {
    title: function(d) { return "Oops!"; },
    intro: function(d) { return "You’ve tried to log in to our new website, which isn’t available to you yet. Please click on the link below to access our original website."; },
    cta: function(d) { return "Log in to Slimming World"; },
    fromWebView: function(d) { return "We think you might be looking for our current app for group members."; },
    link: function(d) { return "Go to the app store"; }
  },
  loggedOut: {
    title: function(d) { return "You successfully logged out"; },
    subTitle: function(d) { return "We hope to see you again soon!"; },
    linkLabel: function(d) { return "Back to login page"; }
  },
  login: {
    title: function(d) { return "Sign in"; },
    usernamePlaceholder: function(d) { return "Username or email address"; },
    passwordPlaceholder: function(d) { return "Password"; },
    keepLoggedIn: function(d) { return "Keep me logged in"; },
    submitButton: function(d) { return "Login"; },
    linkToForgotPassword: function(d) { return "Forgot your password"; },
    linkToGroupReg: function(d) { return "Already a group member? Register your group card"; },
    findNearestGroup: function(d) { return "Find your nearest group"; },
    linkToOnlineRegistration: function(d) { return "Join our digital service"; },
    loginFormHeader: function(d) { return " Your login details"; },
    reminderSuccess: function(d) { return " Thank you! We'll send you a reminder after six weeks of your baby's birth"; },
    signIn: function(d) { return "Sign in"; },
    validators: {
      username: function(d) { return "Please enter your email or username"; },
      password: function(d) { return "Your password is required"; }
    },
    errorBoundaryTitle: function(d) { return "login"; }
  },
  iFrameLogout: {
    loggingOut: function(d) { return "Logging out..."; }
  },
  logout: {
    title: function(d) { return "Log out"; },
    description: function(d) { return "Would you like to logout?"; },
    no: function(d) { return "Stay online"; },
    yes: function(d) { return "Continue to log out"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  multiFactorAuthenticationRegister: {
    title: function(d) { return "Setup two-factor authentication using TOTP"; },
    body: function(d) { return "To activate two-factor authentication, scan the QR code with your authentication app."; }
  },
  multiFactorAuthenticationVerify: {
    title: function(d) { return "Verify authentication code"; },
    body: function(d) { return "Please verify using your authentication code"; }
  },
  resetPassword: {
    title: function(d) { return "Password Reset"; },
    introText: function(d) { return "Please choose a new password for your account."; },
    passwordPlaceholder: function(d) { return "Password"; },
    confirmPasswordPlaceholder: function(d) { return "Confirm password"; },
    changedPassword: function(d) { return "Great – you’ve successfully changed your password. Please sign in again to get back to the Slimming World website."; },
    submitButton: function(d) { return "Reset password"; },
    backLink: function(d) { return "Back to login"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    succeeded: function(d) { return "Great – you’ve successfully changed your password. Please sign in again to get back to the Slimming World website."; },
    errorVerifyToken: function(d) { return "Oops, seems your token is invalid."; },
    linkLabel: function(d) { return "Back to home page"; },
    errorBoundaryTitle: function(d) { return "reset password"; }
  },
  successfullyRegisteredFree2Go: {
    comingSoon: {
      title: function(d) { return "You’re now registered for Family Affair!"; },
      description: function(d) { return "We’re launching a brand new app, especially for Free2Go members – your Consultant will let you know as soon as it’s ready!"; }
    },
    released: {
      title: function(d) { return "You’re now registered for Family Affair!"; },
      description: function(d) { return "Log in to the app to get started."; },
      buttonLabel: function(d) { return "Go to the app"; }
    },
    generalErrorMessage: function(d) { return "Sorry, something has gone wrong! Please try again"; }
  },
  cardCheck: {
    title: function(d) { return "Group members register here!"; },
    intro: function(d) { return "If you’re a group member here’s where you sign up for your free access to the Slimming World website and app. "; },
    body: function(d) { return "Your Consultant gave you a membership card and PIN when you joined your group. To help us set up your online account, please fill in the details below."; },
    emailLabel: function(d) { return "What’s your email address?"; },
    emailInformation: function(d) { return "You can use your email address to log on, and we’ll send essential membership info to this address, too."; },
    registrationError: {
      title: function(d) { return "You're already registered"; },
      content: function(d) { return "Good news! it looks like you already have an account. Just log in to continue your Slimming World journey."; },
      continueToLogin: function(d) { return "Go to login"; }
    },
    cardRetryError: {
      body: function(d) { return "There’s been a problem with your card and PIN, please email:"; }
    },
    generalErrorMessage: function(d) { return "Sorry, something has gone wrong! Please try again"; }
  },
  groupRegistration: {
    steps: {
      cardCheck: function(d) { return "Membership card"; },
      medicalCheck: function(d) { return "Personal details"; },
      securityInformation: function(d) { return "Password and security question"; },
      regionInformation: function(d) { return "Contact details"; }
    },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    errorBoundaryTitle: function(d) { return "group registration"; }
  },
  onlineRegistration: {
    steps: {
      introduction: function(d) { return "Introduction"; },
      medicalCheck: function(d) { return "About you"; },
      personalDetails: function(d) { return "Create account"; },
      summary: function(d) { return "Order details"; },
      packageSelection: function(d) { return "Package selection"; },
      checkout: function(d) { return "Payment"; }
    },
    freePackage: {
      id: function(d) { return "Free"; },
      name: function(d) { return "FREE"; },
      slug: function(d) { return "Free"; },
      duration: function(d) { return "P3M"; },
      description: function(d) { return "Get a taste of Slimming World with no strings attached!"; }
    },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    groupRegistrationCTA: {
      title: function(d) { return "Not what you were looking for?"; },
      findAGroup: function(d) { return "Find a group"; },
      findOutMoreDigital: function(d) { return "Find out more about our digital service"; },
      registerCard: function(d) { return "Register your group card"; }
    }
  },
  checkout: {
    title: function(d) { return "If you'd love to join our digital service..."; },
    subTitle: function(d) { return "Start by choosing your perfect package, and we'll get your weight loss journey up and running."; },
    voucherGift: function(d) { return "Your voucher gives you " + plural(d.MONTHS, 0, en, { one: number(d.MONTHS, "MONTHS") + " month", other: number(d.MONTHS, "MONTHS") + " months'" }) + " free access to our digital service. You're just a few minutes away from starting your Slimming World journey."; },
    form: {
      submit: {
        payment: {
          label: function(d) { return "Continue to payment"; }
        }
      },
      input: {
        package: {
          error: {
            required: function(d) { return "Please select a package"; }
          }
        }
      }
    },
    packages: {
      title: function(d) { return "Select your package"; },
      disclaimer: function(d) { return "After your initial 3 months, your membership continues on a monthly basis at £20/€25 per month."; }
    },
    next: {
      title: {
        payment: function(d) { return "Final step"; },
        packageSelection: function(d) { return "What you’ll need"; }
      },
      description: {
        payment: function(d) { return "Time to get your card details ready..."; },
        packageSelection: function(d) { return "On the next few screens we'll ask for your details, including height and weight, so get ready with your tape measure and scales if you need to double check this."; }
      }
    }
  },
  finished: {
    btnContinue: function(d) { return "Set up my profile"; },
    title: function(d) { return "Great news – you’re all signed up!"; },
    description: function(d) { return "There are just a few more questions to complete before you're ready to rock and roll with your " + d.PACKAGE_NAME + " Slimming World Online package."; },
    fallbackPackageName: function(d) { return ""; },
    paymentSkipped: {
      title: function(d) { return "Welcome to Slimming World!"; },
      description: function(d) { return "Now let's get your Slimming World experience up and running."; }
    }
  },
  accountRecoveryErrorModal: {
    title: function(d) { return "We're sorry, we're unable to confirm your details"; },
    body: function(d) { return "We've been unable to match your date of birth to our records. For security reasons we've now locked your account.\n\nPlease contact our support team."; },
    contactSupport: function(d) { return "Contact support"; },
    contactSupportMessage: function(d) { return "If you're still unable to recover your account, please contact our support team."; }
  },
  recoverEmailDOB: {
    title: function(d) { return "Struggling to log in?"; },
    desc: function(d) { return "We’ve got a couple of questions to help us check you out and get you logged in."; },
    label: function(d) { return "What’s your date of birth?"; },
    button: function(d) { return "Continue"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  recoverEmailSecurityQuestion: {
    title: function(d) { return "Your security question"; },
    body: function(d) { return "Please tell us the answer to your security question below."; },
    button: function(d) { return "Continue"; },
    forgot: function(d) { return "I've forgotten my security details"; },
    modal: {
      forgot: {
        title: function(d) { return "Forgotten security details"; },
        body: function(d) { return "Please contact our support team for more information"; }
      }
    },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  revealEmail: {
    title: function(d) { return "Have we met already?"; },
    body: function(d) { return "It looks like you might have registered these card details already."; },
    yourEmail: function(d) { return "Your registered email address is..."; },
    instructions: function(d) { return "All you need to do now is log in using the email address above and enter your password."; },
    changeInstructions: function(d) { return "You can update your email address from the " + d.LINK + " section on the website."; },
    button: function(d) { return "Log in"; },
    updateLinkText: function(d) { return "'Settings'"; },
    cantRemember: function(d) { return "I can't remember"; },
    contactSupport: function(d) { return "If you don’t have access to this email any more, please " + d.LINK; },
    contactSupportLinkText: function(d) { return "contact our digital support team."; }
  },
  recoverEmail: {
    steps: {
      recoverEmailDOB: function(d) { return "Security check"; },
      revealEmail: function(d) { return "Email access"; },
      securityQuestion: function(d) { return "Security question"; }
    },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  accountSettingsCategories: {
    title: function(d) { return "Settings"; },
    sections: {
      dietaryRequirements: function(d) { return "Dietary requirements"; },
      preferences: function(d) { return "Preferences"; },
      healthAndDiet: function(d) { return "Health & Diet"; },
      account: function(d) { return "Account & Membership"; },
      shareYourStory: function(d) { return "Your story"; }
    },
    paths: {
      accountSettingsAccountDetails: function(d) { return "Account details"; },
      accountSettingsPersonalDetails: function(d) { return "Personal details"; },
      accountSettingsSubscriptionStatus: function(d) { return "Membership status"; },
      accountSettingsWeightLossJourney: function(d) { return "Restart progress"; },
      accountSettingsGroupJourneyStart: function(d) { return "Start your journey"; },
      accountSettingsOrderHistory: function(d) { return "Orders & Payments"; },
      accountSettingsHallOfFame: function(d) { return "Hall of fame"; },
      accountSettingsEmails: function(d) { return "Emails"; },
      accountSettingsPurchaseReturns: function(d) { return "Returns"; },
      accountSettingsReturnConfirmed: function(d) { return "Return confirmed"; },
      accountSettingsWeighIn: function(d) { return "Weigh-in"; },
      accountSettingsPregnancy: function(d) { return "Pregnancy"; },
      accountSettingsMedical: function(d) { return "Health"; },
      accountSettingsDietary: function(d) { return "Dietary"; },
      accountSettingsShareStory: function(d) { return "Share my story"; },
      accountSettingsPrivacy: function(d) { return "Privacy"; },
      accountSettingsCompetitionEntries: function(d) { return "Competitions"; }
    }
  },
  dietaryPreferences: {
    title: function(d) { return "Your dietary preferences"; },
    subtitle: function(d) { return "Let us know your dietary requirements and we’ll save these as part of your personal profile. This will help us tailor the recipes and content we show."; },
    disclaimer: function(d) { return "When you search for recipes, we’ll try to exclude the ingredients you’ve told us about here."; },
    disclaimerItalic: function(d) { return "If you have an allergy or intolerance please do check recipes carefully to make sure they’re suitable."; },
    informationBox: function(d) { return "Let us know which foods you can’t eat and we’ll save this as part of your personal profile and use that information to help tailor the content we show you."; },
    settingsIntro: function(d) { return "Let us know, using the tick boxes below, about those foods you can’t eat and we’ll save those as part of your profile. We’ll use your profile information when you search for recipes to give you the results that best match your needs."; },
    groupLabels: {
      no: function(d) { return "I don't have any of the dietary preferences listed below."; },
      yes: function(d) { return "I have the following dietary preferences:"; },
      vegetarian: function(d) { return "I prefer a vegetarian diet"; },
      redmeat: function(d) { return "Exclude red meat"; },
      pork: function(d) { return "Exclude pork"; },
      poultry: function(d) { return "Exclude poultry"; },
      fish: function(d) { return "Exclude fish / shellfish"; },
      eggs: function(d) { return "Exclude eggs"; },
      dairy: function(d) { return "Exclude dairy"; },
      nuts: function(d) { return "Exclude nuts"; },
      gluten: function(d) { return "Exclude gluten / wheat"; },
      vegan: function(d) { return "I prefer a vegan diet"; }
    },
    errorBoundaryTitle: function(d) { return "dietary preferences"; }
  },
  errors: {
    requiredField: function(d) { return "Oops, this field is blank! Please fill in " + d.FIELD_NAME + " to continue."; },
    invalidField: function(d) { return "Please enter a valid " + d.FIELD_NAME; },
    unauthorized: function(d) { return "You are not authorized to view this page."; },
    notFound: function(d) { return "Something you were looking for could not be found.."; },
    unexpected: function(d) { return "We're sorry for the error. Please close your browser and try again shortly."; },
    invite: {
      required: function(d) { return "You need an invite to register at this time"; },
      notFound: function(d) { return "The invite could not be found"; },
      used: function(d) { return "This invite is already used"; }
    }
  },
  general: {
    post: {
      isEdited: function(d) { return "This post has been edited"; },
      isDeleted: function(d) { return "This post has been deleted"; },
      createdDate: function(d) { return "Posted " + d.DATE; },
      latestActivity: function(d) { return "Latest activity " + d.DATE; },
      like: {
        cta: function(d) { return plural(d.TOTAL_LIKES, 0, en, { "0": "Like", one: d.TOTAL_LIKES + " Like", other: fmt.comma(d.TOTAL_LIKES, "en") + " Likes" }); },
        info: function(d) { return plural(d.TOTAL_LIKES, 0, en, { "0": "0 Likes", one: d.TOTAL_LIKES + " Like", other: fmt.comma(d.TOTAL_LIKES, "en") + " Likes" }); },
        ctaUnlike: function(d) { return plural(d.TOTAL_LIKES, 0, en, { "0": "(Unlike)", one: d.TOTAL_LIKES + " (Unlike)", other: fmt.comma(d.TOTAL_LIKES, "en") + " (Unlike)" }); },
        label: function(d) { return plural(d.TOTAL_LIKES, 0, en, { "0": "No likes just yet", one: d.TOTAL_LIKES + " Like", other: fmt.comma(d.TOTAL_LIKES, "en") + " Likes" }); },
        labelSentence: function(d) { return plural(d.TOTAL_LIKES, 0, en, { "0": "No likes just yet", one: d.TOTAL_LIKES + " member liked this", other: fmt.comma(d.TOTAL_LIKES, "en") + " members liked this" }); }
      },
      noLike: {
        label: function(d) { return "No likes just yet"; }
      },
      comment: {
        cta: function(d) { return plural(d.TOTAL_COMMENTS, 0, en, { "0": "Comment", one: d.TOTAL_COMMENTS + " Comment", other: d.TOTAL_COMMENTS + " Comments" }); },
        info: function(d) { return plural(d.TOTAL_COMMENTS, 0, en, { "0": "0 Comments", one: d.TOTAL_COMMENTS + " Comment", other: d.TOTAL_COMMENTS + " Comments" }); },
        label: function(d) { return plural(d.TOTAL_COMMENTS, 0, en, { "0": "No comments just yet", one: d.TOTAL_COMMENTS + " Comment", other: d.TOTAL_COMMENTS + " Comments" }); }
      },
      reply: {
        label: function(d) { return "Reply"; }
      },
      read: function(d) { return "Read this post"; },
      readShort: function(d) { return "Read"; },
      postedTo: function(d) { return "Posted to "; },
      slimmingWorldCommunity: function(d) { return "Slimming World Community"; }
    },
    tiles: {
      foodRange: function(d) { return "Food Range"; }
    },
    sort: {
      label: function(d) { return "Sort by"; },
      relevance: function(d) { return "Relevance"; },
      newest: function(d) { return "Most recent"; },
      oldest: function(d) { return "Oldest"; },
      latestActivity: function(d) { return "Latest activity"; },
      mostLiked: function(d) { return "Most liked"; }
    },
    postType: {
      post: function(d) { return "Post"; },
      "weigh-in": function(d) { return "Weigh-in"; }
    },
    cta: {
      backToCommunity: function(d) { return "Back to community"; },
      bookmark: function(d) { return "Bookmark"; },
      bookmarked: function(d) { return "Bookmarked"; },
      startAChat: function(d) { return "Start a chat"; },
      back: function(d) { return "Back"; },
      save: function(d) { return "Save"; },
      clear: function(d) { return "Clear"; },
      add: function(d) { return "Add"; },
      remove: function(d) { return "Remove"; },
      reject: function(d) { return "Reject"; },
      removeQuestion: function(d) { return "Remove this question"; },
      next: function(d) { return "Next"; },
      close: function(d) { return "Close"; },
      loadMore: function(d) { return "Load more"; },
      contactSupport: function(d) { return "Contact our support team"; },
      readMore: function(d) { return "Read more"; },
      cancel: function(d) { return "Cancel"; },
      print: function(d) { return "Print"; },
      share: function(d) { return "Share"; },
      retry: function(d) { return "Retry"; },
      "continue": function(d) { return "Continue"; },
      finish: function(d) { return "Finish"; },
      edit: function(d) { return "Edit"; },
      ok: function(d) { return "Ok"; },
      gotIt: function(d) { return "Okay, got it!"; },
      confirm: function(d) { return "Confirm"; },
      viewDish: function(d) { return "View the dish"; },
      copy: function(d) { return "Copy"; },
      oops: function(d) { return "Oops!"; },
      previousSlide: function(d) { return "Previous slide"; },
      nextSlide: function(d) { return "Next slide"; }
    },
    inlineText: {
      contactSupport: function(d) { return "contact" + d.TENSE + " our support team"; },
      viewPregnancySettings: function(d) { return "pregnancy settings"; },
      of: function(d) { return "of"; }
    },
    syns: {
      label: function(d) { return plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn " + d.SYNSTYPE, other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }) + " " + d.SYNSTYPE, other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns " + d.SYNSTYPE }); },
      shortLabel: function(d) { return plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn", other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns" }); },
      noLabel: function(d) { return plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½", other: "0" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + select(d.HALF, { "5": "", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) }); },
      perPerson: function(d) { return "per serving"; },
      each: function(d) { return "each"; }
    },
    forms: {
      savedSuccessfully: function(d) { return "The form has been saved successfully"; },
      sentSuccesfully: function(d) { return "The form has been sent successfully"; },
      generalErrorMessage: function(d) { return "Oops, something's gone wrong! Please try again..."; }
    },
    measureUnit: {
      height: {
        feet: {
          label: function(d) { return "feet"; },
          labelAbbreviation: function(d) { return "ft"; }
        },
        inches: {
          label: function(d) { return "inches"; },
          labelZeroInch: function(d) { return "zero inch"; },
          labelHalfInch: function(d) { return "half inch"; },
          labelAbbreviation: function(d) { return "in"; },
          name: function(d) { return select(d.HEIGHT, { null: "inches", other: plural(d.HEIGHT, 0, en, { one: "inche", other: "inches" }) }); },
          abbreviation: function(d) { return select(d.HEIGHT, { null: "ins", other: plural(d.HEIGHT, 0, en, { one: "in", other: "ins" }) }); }
        },
        metres: {
          label: function(d) { return "metres"; },
          labelAbbreviation: function(d) { return "m"; },
          name: function(d) { return select(d.HEIGHT, { null: "metres", other: plural(d.HEIGHT, 0, en, { one: "metre", other: "metres" }) }); },
          abbreviation: function(d) { return select(d.HEIGHT, { null: "ms", other: plural(d.HEIGHT, 0, en, { one: "m", other: "ms" }) }); }
        },
        centimetres: {
          label: function(d) { return "centimetres"; },
          labelAbbreviation: function(d) { return "cm"; },
          name: function(d) { return select(d.HEIGHT, { null: "centimetres", other: plural(d.HEIGHT, 0, en, { one: "centimetre", other: "centimetres" }) }); },
          abbreviation: function(d) { return d.HEIGHT + " cm"; }
        }
      },
      weight: {
        pounds: {
          label: function(d) { return "pounds"; },
          labelZeroPound: function(d) { return "zero pound"; },
          labelHalfPound: function(d) { return "half pound"; },
          labelAbbreviation: function(d) { return "lbs"; },
          labelAbbreviationSingle: function(d) { return "lb"; },
          name: function(d) { return select(d.WEIGHT, { null: "pounds", other: plural(d.WEIGHT, 0, en, { one: "pound", other: "pounds" }) }); },
          abbreviation: function(d) { return select(d.WEIGHT, { null: "lbs", other: plural(d.WEIGHT, 0, en, { one: "lb", other: "lbs" }) }); },
          abbreviatedNumber: function(d) { return plural(d.NUMBER, 0, en, { "0": select(d.HALF, { "5": "½ lb", other: "" }), "1": d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " lb" + select(d.HALF, { "5": "s", other: "" }), other: d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " lbs" }); }
        },
        stone: {
          label: function(d) { return "stones"; },
          labelSingular: function(d) { return "stone"; },
          labelAbbreviation: function(d) { return "st"; },
          name: function(d) { return select(d.WEIGHT, { null: "pounds", other: plural(d.WEIGHT, 0, en, { one: "pound", other: "pounds" }) }); },
          abbreviation: function(d) { return "st"; },
          abbreviatedNumber: function(d) { return plural(d.NUMBER, 0, en, { "0": select(d.HALF, { "5": "½ st", other: "" }), "1": d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " st" + select(d.HALF, { "5": "s", other: "" }), other: d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " st" }); }
        },
        kilogram: {
          label: function(d) { return "kilograms"; },
          labelKilos: function(d) { return "kilos"; },
          labelGrams: function(d) { return "grams"; },
          labelAbbreviation: function(d) { return "kg"; },
          name: function(d) { return select(d.WEIGHT, { null: "kilograms", other: plural(d.WEIGHT, 0, en, { one: "kilogram", other: "kilograms" }) }); },
          abbreviation: function(d) { return select(d.WEIGHT, { null: "kgs", other: plural(d.WEIGHT, 0, en, { one: "kg", other: "kgs" }) }); },
          abbreviatedNumber: function(d) { return d.NUMBER + " kg"; },
          screenReader: {
            kilo: function(d) { return "kilos"; },
            grams: function(d) { return "grams"; }
          }
        }
      },
      energy: {
        kcal: function(d) { return "kcal"; },
        kj: function(d) { return "kJ"; },
        "100g": function(d) { return "per 100g"; },
        "100ml": function(d) { return "per 100ml"; },
        serving: function(d) { return "per serving"; }
      }
    },
    portionSize: function(d) { return select(d.TYPE, { perperson: "Serves", each: "Makes", other: "Serves" }) + " " + d.SERVINGS; },
    weighIn: {
      error: {
        gainExcess: function(d) { return "Oops, it seems as though there has been a typing error. Please make sure that you have entered your current weight."; },
        lossExcess: function(d) { return "Oops, it seems as though there has been a typing error. Please make sure that you have entered your current weight."; }
      },
      warning: {
        gain: function(d) { return "The amount of weight you've gained since you last updated your progress is more than we would normally expect.\nPlease double check you have entered your weight correctly."; },
        loss: function(d) { return "This number seems high – is it definitely the one you want?"; },
        incorrectLoss: function(d) { return "This number seems high – is it definitely the one you want?"; },
        incorrectGain: function(d) { return "The amount of weight you've gained since you last updated your progress looks incorrect.\nPlease double check you have entered your weight correctly."; }
      },
      bmiLow: function(d) { return "We’re sorry, your BMI is below what is considered a healthy weight for your height. Intentionally trying to lose weight to reach and stay within this range can be unhealthy. \n\nPlease double check the weight you’ve entered, or get in touch with our support team " + d.SUPPORT_EMAIL + " to talk through your options. "; }
    },
    commitment: {
      error: {
        gainExcess: function(d) { return "Oops, it seems as though there has been a typing error. Please make sure that you have entered a valid commitment."; },
        lossExcess: function(d) { return "We’re sorry the weight you’d like to lose this week is more than we’d recommend. Please double check you’ve entered your weight loss commitment correctly. If you’d like to talk about this please contact our support team " + d.SUPPORT_EMAIL + "."; }
      },
      warning: {
        gainExcess: function(d) { return "The amount of weight you’d like to lose is more than we would normally expect. Please double check you’ve entered your weight loss commitment correctly. \nPlease click Finish to continue."; },
        lossExcess: function(d) { return "The amount of weight you’d like to lose is more than we would normally expect. Please double check you’ve entered your weight loss commitment correctly. \nPlease click Finish to continue."; }
      },
      bmiLow: function(d) { return "We’re sorry the weight you’d like to lose this week would take you below our lowest acceptable BMI for your height. Please double check you’ve entered your weight loss commitment correctly."; }
    },
    user: {
      isAdmin: function(d) { return "SW team"; },
      isNewMember: function(d) { return "New member"; }
    },
    date: {
      year: function(d) { return "Year"; },
      month: function(d) { return "Month"; },
      day: function(d) { return "Day"; },
      fullDate: function(d) { return "Date"; },
      error: {
        ageNotAllowed: function(d) { return "Age below 18 years is not allowed"; }
      }
    },
    securityInfoLabel: function(d) { return "Why do we ask for a security question?"; },
    securityInfo: function(d) { return "We ask for this information so we can help identify you and recover your account if you lose access for any reason."; },
    securityQuestion: {
      securityQuestionsPlaceholder: function(d) { return "Select your security question"; },
      securityAnswerPlaceholder: function(d) { return "Enter answer"; },
      securityLabel: function(d) { return "Choose a security question"; }
    },
    error: {
      title: function(d) { return "Oops, something went wrong!"; },
      description: function(d) { return "We're sorry for the error. Please close your browser and try again shortly."; },
      inline: function(d) { return "Oops, something went wrong!\nPlease close your browser and try again shortly."; },
      contactSupport: function(d) { return "If you continue to experience problems please contact our support team: "; }
    },
    video: {
      play: function(d) { return "Play"; }
    },
    duration: {
      minutes: function(d) { return plural(d.MINUTES, 0, en, { one: d.MINUTES + " minute", other: d.MINUTES + " minutes" }); },
      hours: function(d) { return plural(d.HOURS, 0, en, { "0": "", one: d.HOURS + " hour", other: d.HOURS + " hours" }); }
    }
  },
  medicalConditions: {
    title: function(d) { return "Your health"; },
    healthWarning: function(d) { return "Slimming World is not intended as a substitute for any advice or treatment prescribed by a doctor. If you have a medical condition which may be adversely affected by exercise or losing weight, please consult your doctor before following any information given here."; },
    groupLabels: {
      no: function(d) { return "I don't have any of the health conditions listed below."; },
      yes: function(d) { return "I have the following conditions:"; },
      arthritis: function(d) { return "Arthritis"; },
      coeliacDisease: function(d) { return "Coeliac disease"; },
      diabetes: function(d) { return "Diabetes"; },
      foodAllergies: function(d) { return "Food allergies"; },
      heartDisease: function(d) { return "Heart disease / heart attack"; },
      highCholesterol: function(d) { return "High cholesterol"; },
      highBloodPressure: function(d) { return "High blood pressure"; },
      ibs: function(d) { return "IBS"; },
      lactoseIntolerance: function(d) { return "Lactose intolerance"; },
      osteoporosis: function(d) { return "Osteoporosis"; },
      pcos: function(d) { return "PCOS"; },
      stroke: function(d) { return "Stroke"; },
      weightLossSurgery: function(d) { return "I’ve had weight loss surgery"; }
    },
    informationBox: function(d) { return "We have some extra information available for our members with these health conditions, these articles will be added to the My Bookmarks section of the website."; },
    online: {
      intro: function(d) { return "This helps us tailor the information we provide to you."; }
    },
    group: {
      intro: function(d) { return "This helps us tailor the information we provide to you."; }
    },
    errorBoundaryTitle: function(d) { return "medical conditions"; }
  },
  planner: {
    search: function(d) { return "Search & track"; },
    bodyMagic: function(d) { return "Body Magic"; },
    summary: function(d) { return "Daily Summary"; },
    searchFood: function(d) { return "Food Search"; },
    searchActivity: function(d) { return "Activity Search"; },
    searchRecipes: function(d) { return "Recipes Search"; },
    awards: function(d) { return "Awards"; },
    backToFoodSearch: function(d) { return "Back to Food Search"; },
    backToRecipeSearch: function(d) { return "Back to Recipe Search"; },
    tabLabel: function(d) { return "Planner navigation"; }
  },
  searchFilter: {
    title: function(d) { return "Filter "; },
    successStories: {
      name: function(d) { return "Success stories"; },
      stories: {
        name: function(d) { return "Motivation"; },
        health: function(d) { return "Health"; },
        wedding: function(d) { return "Getting married"; },
        becomingAParent: function(d) { return "Becoming a parent"; },
        vacation: function(d) { return "Going on holiday"; },
        generalWellBeing: function(d) { return "General well-being"; }
      },
      gender: {
        name: function(d) { return "Gender"; },
        male: function(d) { return "Male"; },
        female: function(d) { return "Female"; }
      },
      weightLoss: {
        name: function(d) { return "Weight loss"; },
        underOneStoneHalf: function(d) { return "1½ st"; },
        oneHalfThreeHalf: function(d) { return "1½ st - 3½ st"; },
        threeHalfFiveHalf: function(d) { return "3½ st - 5½ st"; },
        fiveHalfSevenHalf: function(d) { return "5½ st - 7½ st"; },
        overSevenHalfStone: function(d) { return "7½ st+"; }
      },
      age: {
        name: function(d) { return "Age"; },
        under20: function(d) { return "Under 20"; },
        twenties: function(d) { return "20s"; },
        thirties: function(d) { return "30s"; },
        forties: function(d) { return "40s"; },
        fifties: function(d) { return "50s"; },
        sixtiesAndMore: function(d) { return "60s+"; }
      }
    },
    recipes: {
      name: function(d) { return "Recipes"; },
      useFoodPreferences: {
        name: function(d) { return "Dietary Preferences"; },
        useFoodPreferences: function(d) { return "Use my dietary preferences"; },
        uncheckFoodPreferences: function(d) { return "(uncheck to search all recipes)"; }
      },
      foodOptimising: {
        name: function(d) { return "Food Optimising"; },
        lessThan5Ingredients: function(d) { return "Less than 5 ingredients"; },
        free: function(d) { return "Free"; },
        familyFavourites: function(d) { return "Family favourites"; },
        foodOptimizingSp: function(d) { return "Extra Easy SP"; },
        vegetarian: function(d) { return "Vegetarian"; },
        freezerFriendly: function(d) { return "Freezer friendly"; }
      },
      cuisine: {
        name: function(d) { return "Cuisine"; },
        freezerFriendly: function(d) { return "Freezer friendly"; },
        italian: function(d) { return "Italian"; },
        chinese: function(d) { return "Chinese"; },
        mexican: function(d) { return "Mexican"; },
        greek: function(d) { return "Greek"; },
        thai: function(d) { return "Thai"; },
        indian: function(d) { return "Indian"; },
        middleEastern: function(d) { return "Middle eastern"; },
        american: function(d) { return "American"; },
        mediterranean: function(d) { return "Mediterranean"; },
        french: function(d) { return "French"; },
        asian: function(d) { return "Asian"; },
        britishIsles: function(d) { return "British Isles"; }
      },
      mealType: {
        name: function(d) { return "Meal type"; },
        breakfast: function(d) { return "Breakfast"; },
        lunch: function(d) { return "Lunch"; },
        dinner: function(d) { return "Dinner"; },
        snack: function(d) { return "Snack"; },
        dessert: function(d) { return "Dessert"; },
        appetizer: function(d) { return "Appetiser"; },
        saucesAndCondiments: function(d) { return "Sauces & condiments"; },
        drinks: function(d) { return "Drinks"; },
        sideDishes: function(d) { return "Side dishes"; }
      },
      seasonal: {
        name: function(d) { return "Seasonal"; },
        christmas: function(d) { return "Christmas"; },
        newYear: function(d) { return "New year's"; },
        halloween: function(d) { return "Halloween"; },
        stPatricksDay: function(d) { return "St. Patrick's Day"; },
        easter: function(d) { return "Easter"; },
        valentinesDay: function(d) { return "Valentine's Day"; },
        summer: function(d) { return "Summer"; },
        fall: function(d) { return "Autumn"; },
        winter: function(d) { return "Winter"; },
        spring: function(d) { return "Spring"; }
      },
      cookingType: {
        name: function(d) { return "Cooking type"; },
        grilling: function(d) { return "Grilling"; },
        fakeaway: function(d) { return "Fakeaway"; },
        onePot: function(d) { return "One pot"; },
        slowCooker: function(d) { return "Slow cooker"; },
        noCook: function(d) { return "No cooking"; }
      },
      time: {
        name: function(d) { return "Time"; },
        lessThan30: function(d) { return "30 mins or less"; },
        lessThan60: function(d) { return "30-60 min"; },
        moreThan60: function(d) { return "60+ min"; }
      }
    },
    features: {
      name: function(d) { return "Features"; },
      features: {
        name: function(d) { return "Features"; },
        foodOptimizing: function(d) { return "Food Optimising"; },
        foodOptimizingSp: function(d) { return "Extra Easy SP"; },
        gettingStarted: function(d) { return "Getting started"; },
        travelAndVacation: function(d) { return "Travel & holidays"; },
        foodIdeas: function(d) { return "Food ideas"; },
        exercise: function(d) { return "Getting active"; },
        trickyTimes: function(d) { return "Tricky times"; },
        health: function(d) { return "Health"; },
        eatingOut: function(d) { return "Eating out"; },
        targetMembers: function(d) { return "Target members"; },
        seasonal: function(d) { return "Seasonal"; }
      },
      seasonal: {
        name: function(d) { return "Seasonal"; },
        christmas: function(d) { return "Christmas"; },
        newYear: function(d) { return "New year's"; },
        thanksgiving: function(d) { return "Thanksgiving"; },
        halloween: function(d) { return "Halloween"; },
        valentinesDay: function(d) { return "Valentine's Day"; },
        summer: function(d) { return "Summer"; },
        fall: function(d) { return "Autumn"; },
        winter: function(d) { return "Winter"; },
        spring: function(d) { return "Spring"; }
      }
    },
    community: {
      name: function(d) { return "Community"; },
      tagsFilter: {
        name: function(d) { return "Tags"; }
      }
    },
    foodRange: {
      name: function(d) { return "Food Range recipes"; },
      categories: {
        name: function(d) { return "Range"; }
      },
      foodRangeDiet: {
        name: function(d) { return "Dietary preferences"; },
        eggFree: function(d) { return "No egg ingredients*"; },
        dairyFree: function(d) { return "No dairy ingredients*"; },
        glutenFree: function(d) { return "No gluten ingredients*"; },
        speedFood: function(d) { return "Suitable for SP"; },
        vegan: function(d) { return "Vegan"; },
        vegetarian: function(d) { return "Vegetarian"; }
      }
    },
    advertisement: {
      name: function(d) { return "Artwork"; },
      orientation: {
        name: function(d) { return "Orientation"; },
        landscape: function(d) { return "Landscape"; },
        portrait: function(d) { return "Portrait"; }
      },
      imagery: {
        name: function(d) { return "Imagery"; },
        food: function(d) { return "Food"; },
        people: function(d) { return "People"; }
      }
    },
    nearestGroupSearch: {
      name: function(d) { return "Group search"; },
      days: {
        name: function(d) { return "Day"; },
        monday: function(d) { return "Monday"; },
        tuesday: function(d) { return "Tuesday"; },
        wednesday: function(d) { return "Wednesday"; },
        thursday: function(d) { return "Thursday"; },
        friday: function(d) { return "Friday"; },
        saturday: function(d) { return "Saturday"; },
        sunday: function(d) { return "Sunday"; }
      },
      times: {
        name: function(d) { return "Time"; },
        morning: function(d) { return "Morning"; },
        afternoon: function(d) { return "Afternoon"; },
        evening: function(d) { return "Evening"; }
      },
      venueAccessibility: {
        name: function(d) { return "Venue accessibility"; },
        wheelchairAccess: function(d) { return "Wheelchair access"; },
        disabledToilet: function(d) { return "Disabled toilet"; },
        disabledParking: function(d) { return "Disabled parking"; },
        hearingLoop: function(d) { return "Hearing loop"; }
      }
    }
  },
  validation: {
    requiredField: function(d) { return "*"; },
    errors: {
      general: function(d) { return "Oops, something went wrong, please try again later"; },
      region: {
        required: function(d) { return "Please select your region"; }
      },
      package: {
        required: function(d) { return "Oops, this field is blank! Please select a package to continue"; }
      },
      firstName: {
        required: function(d) { return "Please fill in your first name"; }
      },
      lastName: {
        required: function(d) { return "Please fill in your surname"; }
      },
      fullName: {
        required: function(d) { return "Oops, this field is blank! Please fill in your full name to continue"; }
      },
      dateOfBirth: {
        required: function(d) { return "Please enter your date of birth"; },
        past: function(d) { return "Please enter a date in the past"; },
        maxAge: function(d) { return "Please enter a valid year"; },
        notAllowed: function(d) { return "We’re sorry, we’re only able to offer membership to people 18 or over"; }
      },
      gender: {
        required: function(d) { return "Please choose an option to continue"; }
      },
      isPregnant: {
        required: function(d) { return "Please let us know if you are pregnant"; }
      },
      isBreastfeeding: {
        required: function(d) { return "Please let us know if you are breastfeeding"; }
      },
      hasEatingDisorder: {
        required: function(d) { return "Oops, this field is blank! Please let us know if you have any eating disorders to continue"; }
      },
      hasMedicalConditions: {
        required: function(d) { return "Oops, this field is blank! Please let us know if you have any medical conditions to continue"; },
        noConditions: function(d) { return "Please make your selection, then click Save"; }
      },
      noDietaryPreference: {
        required: function(d) { return "Please let us know if you have any dietary preferences"; },
        noPreference: function(d) { return "Please select your dietary preferences"; }
      },
      initialWeight: {
        required: function(d) { return "Oops, this field is blank! Please fill in your current weight to continue"; },
        tooHigh: function(d) { return "The weight you’ve entered seems unusually high – please double-check"; },
        tooLow: function(d) { return "The weight you’ve entered seems unusually low – please double-check"; },
        bmiTooLow: function(d) { return "We're sorry, your chosen weight means your BMI will be below what is considered healthy"; },
        bmiDangerous: function(d) { return "We’re sorry, your chosen weight means your BMI will be below what is considered a healthy weight for your height"; }
      },
      currentWeight: {
        required: function(d) { return "Oops, this field is blank! Please fill in your current weight to continue"; },
        bmiTooLow: function(d) { return "We're sorry, your chosen weight means your BMI will be below what is considered healthy"; },
        bmiDangerous: function(d) { return "We’re sorry, your chosen weight means your BMI will be below what is considered a healthy weight for your height"; }
      },
      startWeight: {
        required: function(d) { return "Oops, this field is blank! Please fill in your start weight to continue"; },
        tooHigh: function(d) { return "The weight you’ve entered seems unusually high – please double-check"; },
        tooLow: function(d) { return "The weight you’ve entered seems unusually low – please double-check"; },
        bmiTooLow: function(d) { return "We’re sorry, this weight shows that your BMI falls below what’s considered healthy for your height – please double-check what you’ve entered. If this weight is correct, we’re afraid we’re unable to offer you membership at this time."; },
        bmiDangerous: function(d) { return "We’re sorry, this weight falls below our minimum acceptable starting weight (which corresponds with a BMI of 20 – just above the lowest cut-off point of a healthy weight range). Please check you’ve entered it correctly."; }
      },
      height: {
        required: function(d) { return "Please enter your height"; },
        tooHigh: function(d) { return "The height you’ve entered seems unusually high – please double-check"; },
        tooLow: function(d) { return "The height you’ve entered seems unusually low – please double-check"; }
      },
      commitment: {
        required: function(d) { return "Oops, this field is blank! Please enter your commitment to continue"; }
      },
      termsOfUse: {
        required: function(d) { return "Please agree to our terms and conditions to continue"; }
      },
      privacyPolicy: {
        required: function(d) { return "Please agree to our Privacy Policy"; }
      },
      receiveConsultantSupportEmails: {
        required: function(d) { return "Please agree to our Privacy Policy"; }
      },
      userSubscription: {
        required: function(d) { return "Please agree to our User Subscription Agreement"; }
      },
      securityQuestionId: {
        required: function(d) { return "Please choose a security question"; }
      },
      securityQuestionAnswer: {
        required: function(d) { return "Please enter the answer to your security question"; },
        whitespace: function(d) { return "Your answer cannot contain any spaces at the start or end"; },
        minlength: function(d) { return "Your answer should be at least " + d.LENGTH + " characters in length"; }
      },
      email: {
        required: function(d) { return "Please fill in your email address"; },
        validator: function(d) { return "Please enter a valid email address"; }
      },
      newEmail: {
        required: function(d) { return "Please fill in your email address"; },
        validator: function(d) { return "Please enter a valid email address"; }
      },
      emailConfirm: {
        required: function(d) { return "Please retype your email address"; },
        match: function(d) { return "Oops, these email addresses don't match"; }
      },
      cardNumber: {
        range: function(d) { return "Your card number should be either 8 or 12 digits"; },
        required: function(d) { return "Please enter your membership card number"; }
      },
      pin: {
        length: function(d) { return "Your PIN should be 5 digits"; },
        required: function(d) { return "Please enter your PIN"; }
      },
      phoneNumber: {
        required: function(d) { return "Oops, this field is blank! Please fill in a phone number to continue"; },
        invalid: function(d) { return "Oops, this is not a valid phone number, please enter a valid phone number ( " + d.PHONE_EXAMPLE + " )"; },
        maxlength: function(d) { return "Phone number must be less than 20 characters in length"; },
        validator: {
          GB: function(d) { return "Oops, this is not a valid phone number, please enter a valid phone number ( 01234 567 890 )"; },
          US: function(d) { return "Oops, this is not a valid phone number, please enter a valid phone number ( 012 345 6789 )"; },
          IE: function(d) { return "Oops, this is not a valid phone number, please enter a valid phone number ( 012 3456789 )"; },
          CY: function(d) { return "Oops, this is not a valid phone number, please enter a valid phone number ( 01 234567 )"; }
        }
      },
      password: {
        required: function(d) { return "Please enter a password"; },
        validator: function(d) { return "Create a strong password: between 8-64 characters with a mix of letters and numbers"; }
      },
      newPassword: {
        required: function(d) { return "Please enter a password"; },
        validator: function(d) { return "Create a strong password: between 8-64 characters with a mix of letters and numbers"; }
      },
      passwordConfirm: {
        required: function(d) { return "Please enter a password"; },
        match: function(d) { return "Your passwords don’t match, please try again"; }
      },
      billingAddress: {
        addressLine1: {
          required: function(d) { return "Oops, this field is blank! Please fill in your address to continue"; },
          maxLength: function(d) { return "Address line 1 must not exceed 30 characters in length"; }
        },
        addressLine2: {
          maxLength: function(d) { return "Address line 2 must not exceed 30 characters in length"; }
        },
        addressLine3: {
          maxLength: function(d) { return "Address line 3 must not exceed 30 characters in length"; }
        },
        city: {
          required: function(d) { return "Oops, this field is blank! Please fill in your town or city to continue"; }
        },
        country: {
          required: function(d) { return "Oops, this field is blank! Please fill in your country to continue"; }
        },
        state: {
          required: function(d) { return "Oops, this field is blank! Please fill in your county to continue"; }
        },
        zip: {
          required: function(d) { return "Please fill in your zip or postal code to continue"; }
        }
      },
      addressLine1: {
        required: function(d) { return "Please fill in your address to continue"; },
        maxLength: function(d) { return "Address line 1 must not exceed 30 characters in length"; }
      },
      addressLine2: {
        maxLength: function(d) { return "Address line 2 must not exceed 30 characters in length"; }
      },
      addressLine3: {
        maxLength: function(d) { return "Address line 3 must not exceed 30 characters in length"; }
      },
      city: {
        required: function(d) { return "Please fill in your town or city to continue"; }
      },
      county: {
        required: function(d) { return "Please fill in your county to continue"; }
      },
      country: {
        required: function(d) { return "Please fill in your country to continue"; }
      },
      state: {
        required: function(d) { return "Please fill in your state to continue"; }
      },
      zip: {
        required: function(d) { return "Please fill in your Zipcode"; },
        formatError: function(d) { return "Please double-check your Zipcode"; },
        invalid: function(d) { return "Please check that all your characters are correct (eg, are you typing a zero instead of an O?)"; }
      },
      contactMessage: {
        required: function(d) { return "Message is required"; }
      },
      userName: {
        required: function(d) { return "Please choose a username to continue"; },
        minlength: function(d) { return "Your username should be at least " + d.LENGTH + " characters in length"; },
        maxlength: function(d) { return "Username must be less than " + d.LENGTH + " characters in length"; },
        invalid: function(d) { return "Your username should contain a mix of numbers and letters. You can also use full stops and underscores. Please note you can't use spaces in usernames."; },
        alreadyregistered: function(d) { return "Sorry, this username already exists. Please choose another one."; }
      },
      timeZoneId: {
        required: function(d) { return "Please select your timezone to continue"; }
      },
      targetWeight: {
        required: function(d) { return "Please fill in your target weight to continue"; },
        bmiTooLow: function(d) { return "We’re sorry, this weight falls below our minimum acceptable target weight (which corresponds with a BMI of 20, just above the lowest cut-off point of a healthy weight range – this is the level we’re advised is the safest lower target weight for the widest range of slimmers). Please check you’ve entered it correctly."; },
        bmiDangerous: function(d) { return "We’re sorry, this weight falls below our minimum acceptable target weight (which corresponds with a BMI of 20, just above the lowest cut-off point of a healthy weight range – this is the level we’re advised is the safest lower target weight for the widest range of slimmers). Please check you’ve entered it correctly."; }
      },
      lbs: {
        minExceeded: function(d) { return "lbs should be at least 0"; },
        maxExceeded: function(d) { return "lbs should be smaller than 14"; }
      },
      inches: {
        minExceeded: function(d) { return "Inches should be at least 0"; },
        maxExceeded: function(d) { return "Inches should be smaller than 12"; }
      },
      groupJoinDate: {
        required: function(d) { return "Oops, this field is blank! Please enter the date of your first group"; },
        past: function(d) { return "Please enter a date in the past"; }
      },
      groupDay: {
        required: function(d) { return "Oops, this field is blank! Please select the day you normally go to group"; }
      },
      productName: {
        required: function(d) { return "Please fill in a product name to continue"; }
      },
      freeFoodAllowance: {
        required: function(d) { return "Please select a Free Food allowance to continue"; }
      },
      calories: {
        required: function(d) { return "Please fill in an energy value to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Energy value must not exceed 5 characters in length"; }
      },
      fat: {
        required: function(d) { return "Please fill in a fat value to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Fat value must not exceed 5 characters in length"; }
      },
      carbohydrate: {
        required: function(d) { return "Please fill in a carbohydrate value to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Carbohydrate value must not exceed 5 characters in length"; }
      },
      sugar: {
        required: function(d) { return "Please fill in a sugar value to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Sugar value must not exceed 5 characters in length"; }
      },
      protein: {
        required: function(d) { return "Please fill in a protein value to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Protein value must not exceed 5 characters in length"; }
      },
      calcium: {
        required: function(d) { return "Please fill in a calcium value to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Calcium value must not exceed 5 characters in length"; }
      },
      portionSize: {
        required: function(d) { return "Please fill in a portion size to continue"; },
        invalid: function(d) { return "This is not valid, please enter a valid number or decimal"; },
        maxLength: function(d) { return "Portion size value must not exceed 5 characters in length"; }
      },
      category: {
        required: function(d) { return "So we can direct your question to the right team please select a category"; }
      },
      postcode: {
        required: function(d) { return "Please enter a valid postcode"; },
        formatError: function(d) { return "Please enter a valid postcode"; }
      },
      groupSearch: {
        searchTypeUnknown: function(d) { return "Please enter a valid postcode"; },
        failedGetTowns: function(d) { return "Something went wrong, please search by postcode or location"; },
        noResultsForTownCity: function(d) { return "Sorry, we can’t find your town or city. Please check the spelling or try a postcode search."; }
      },
      journeyStartDate: {
        required: function(d) { return "Oops, this field is blank! Please enter the date you would like to track your weight from"; },
        past: function(d) { return "Please enter a date in the past"; },
        longAgo: function(d) { return "Please enter a date after 3rd January 2000"; },
        ageInvalid: function(d) { return "Your journey cannot start before your 16th birthday"; }
      },
      foodCategories: {
        required: function(d) { return "Please select a meal"; }
      },
      startDateUTC: {
        required: function(d) { return "Please choose a start date"; }
      }
    }
  },
  accountFrozenModalContent: {
    title: function(d) { return "Your account is on hold"; },
    description: function(d) { return "It looks like your account is on hold. If you'd like to restart your Slimming World Online membership, please get in touch."; },
    or: function(d) { return "Or"; },
    button: function(d) { return "Contact our support team"; }
  },
  award: {
    lost: {
      label: function(d) { return d.WEIGHT_MILESTONE; },
      description: function(d) { return "For reaching the " + d.WEIGHT_MILESTONE + " milestone"; }
    },
    hello: {
      label: function(d) { return "Hello!"; },
      description: function(d) { return "description yet to come"; }
    },
    profileCompleted: {
      label: function(d) { return "Profile completed"; },
      description: function(d) { return "You've learned the Food Optimising essentials"; }
    },
    firstPost: {
      label: function(d) { return "First post"; },
      description: function(d) { return "description yet to come"; }
    },
    lifeline: {
      label: function(d) { return "Lifeline"; },
      description: function(d) { return "description yet to come"; }
    },
    club: {
      label: function(d) { return "Club"; },
      description: function(d) { return "You've lost 10% of your body weight"; }
    },
    target: {
      label: function(d) { return "Target"; },
      description: function(d) { return "Celebrate your success!"; }
    },
    previousTarget: {
      label: function(d) { return "Target"; },
      description: function(d) { return "For reaching your " + d.TARGET_WEIGHT + " target!"; }
    },
    foodDude: {
      label: function(d) { return "Food dude"; },
      description: function(d) { return "description yet to come"; }
    },
    graduate: {
      label: function(d) { return "Graduate"; },
      description: function(d) { return "description yet to come"; }
    },
    bronze: {
      label: function(d) { return "Bronze Award"; },
      description: function(d) { return "Brilliant! You’ve added amazing activity to your week"; }
    },
    silver: {
      label: function(d) { return "Silver Award"; },
      description: function(d) { return "Super! You’re sticking to an awesome activity plan"; }
    },
    gold: {
      label: function(d) { return "Gold Award"; },
      description: function(d) { return "Fantastic! You’ve made great progress – keep going"; }
    },
    platinum: {
      label: function(d) { return "Platinum Award"; },
      description: function(d) { return "Incredible! Activity is now a positive part of your life"; }
    },
    hero: {
      label: function(d) { return "Hero"; },
      description: function(d) { return "description yet to come"; }
    },
    lost_10Percent: {
      label: function(d) { return "Club 10"; },
      description: function(d) { return "You've lost 10% of your body weight"; }
    },
    fabulous: {
      date: function(d) { return "W/C " + d.DATE; },
      label: function(d) { return ""; },
      description: function(d) { return "Congrats on your super start"; }
    },
    slimmerOfTheWeek: {
      label: function(d) { return "w/c " + d.DATE; },
      description: function(d) { return "7 days of fabulous Food Optimising"; }
    },
    slimmerOfTheMonth: {
      label: function(d) { return d.DATE; },
      description: function(d) { return "A month of superstar slimming!"; }
    },
    navigationKnowHow: {
      label: function(d) { return "Explorer"; },
      description: function(d) { return "Congratulations! You’ve earned your Explorer award"; },
      startAward: {
        buttonLabel: function(d) { return "Start your Explorer award"; }
      }
    },
    activeBodyMagicAward: {
      label: function(d) { return "Started a " + d.AWARD; },
      buttonText: function(d) { return "See your progress"; }
    },
    bodyMagicNextAward: {
      buttonText: function(d) { return "Start a new award"; }
    },
    foodOptimisingAward: {
      label: function(d) { return "Food Optimising Hero"; },
      description: function(d) { return "Congratulations! You’ve nailed the Food Optimising essentials"; }
    },
    magicMoverAward: {
      label: function(d) { return "You’re a Magic Mover"; },
      description: function(d) { return "Every journey begins with a single step, and you're on your way!"; }
    },
    bodyMagicAwardPlaceholder: {
      label: function(d) { return "Start a new award"; },
      description: function(d) { return "Start a new award"; }
    }
  },
  awardSuccessModal: {
    header: function(d) { return "Congratulations"; },
    subText: {
      bronzeAward: function(d) { return "You’re off the starting blocks and you’ve bagged a Bronze award – well done!"; },
      silverAward: function(d) { return "You’ve smashed it... the Silver award has got your name on it!"; },
      goldAward: function(d) { return "What an achievement! You’ve gone Gold – well done!"; },
      platinumAward: function(d) { return "You’re amazing! Keeping up that activity means you’ve reached Platinum. What an inspiration!"; }
    },
    cta: function(d) { return "View your award"; }
  },
  billingAddressModal: {
    title: function(d) { return "Edit billing address"; },
    groupLabels: {
      billingAddress: function(d) { return "Your billing address"; }
    },
    placeholders: {
      updateProfile: function(d) { return "Update my details on my Slimming World profile"; }
    }
  },
  contactUsCallUs: {
    header: function(d) { return "Call us"; },
    body: function(d) { return "Pick up the phone and call " + d.PHONE_NUMBER + " if you’d like to speak to a member of the team."; }
  },
  contactUsChatNow: {
    header: function(d) { return "Chat with us"; },
    body: function(d) { return "To speak to one of the team on live chat, use the " + d.LIVE_CHAT + " in the bottom right corner of the website."; },
    liveChat: function(d) { return "red chat button"; }
  },
  contactUsTechnicalMemberSupport: {
    header: function(d) { return "Digital support"; }
  },
  contactUsWhatsApp: {
    header: function(d) { return "WhatsApp us"; },
    body: function(d) { return "You can message us via WhatsApp by using the button below."; },
    button: function(d) { return "Start a conversation"; }
  },
  emailInput: {
    emailLabel: function(d) { return "Email address"; },
    emailPlaceholder: function(d) { return "Email address"; },
    emailConfirmPlaceholder: function(d) { return "Confirm email address"; },
    emailConfirmNote: function(d) { return "Because your email address is linked to your original invite it can only be changed from your Settings page, once you've completed the registration process."; },
    informationToggleLabel: function(d) { return "Why do we ask for your email address?"; },
    emailInformation: function(d) { return "You can use your email address to log on to the site, plus we'll send essential emails about your membership to this address."; }
  },
  addressFormGroup: {
    placeholders: {
      addressLine1: function(d) { return "Address line 1"; },
      addressLine2: function(d) { return "Address line 2"; },
      addressLine3: function(d) { return "Address line 3"; },
      city: function(d) { return "Town or City"; },
      state: function(d) { return "State"; },
      county: function(d) { return "County"; },
      zip: {
        us: function(d) { return "Zipcode"; },
        gb: function(d) { return "Postcode"; },
        ie: function(d) { return "Eircode"; },
        cy: function(d) { return "Postal code"; },
        "default": function(d) { return "Zipcode"; }
      },
      country: function(d) { return "Country"; },
      countyIe: function(d) { return "Please select"; },
      countyStateSelect: function(d) { return "Please select your " + d.FIELD_NAME; }
    }
  },
  galleryModal: {
    slideIndex: function(d) { return d.currentSlide + " of " + d.totalSlides; }
  },
  imageInput: {
    errors: {
      wrongFileFormat: function(d) { return "Wrong file format supplied: " + d.fileType; },
      fileApiUnsupported: function(d) { return "This browser is outdated and doesn't support uploading files."; }
    },
    loadingTitle: function(d) { return "Uploading your image"; }
  },
  joinNowTab: function(d) { return "Join Now"; },
  magicMoverAwardModal: {
    header: function(d) { return "You're a\n" + d.SCRIPT; },
    script: function(d) { return "Magic Mover!"; },
    subText: {
      magicMoverAward: function(d) { return "You did it! Every journey begins with a single step, and you're already on your way to building an enjoyable activity routine that boosts your health and happiness – for life!"; }
    },
    cta: function(d) { return "Done"; }
  },
  membershipCardInput: {
    title: function(d) { return "Enter your membership card number"; },
    cardTitle: function(d) { return "What’s your membership card number?"; },
    pinTitle: function(d) { return "What’s your 5-digit PIN?"; },
    pinPlaceholder: function(d) { return "5-digit PIN"; },
    cardPlaceholder: function(d) { return "8 or 12 digit card number"; },
    informationLabel: function(d) { return "Where will I find my PIN?"; },
    cardInformationLabel: function(d) { return "Where can I find my membership number?"; },
    cardInformation: function(d) { return "You’ll find this 8 or 12 digit number on your Slimming World membership card."; },
    pinInformation: function(d) { return "Your Consultant will write your 5-digit PIN in the back of your ‘Discover your freedom with your Slimming World Group’ book. If your Consultant hasn’t supplied you with a PIN, please contact them."; }
  },
  myWeekWeighInCTA: {
    weighIn: function(d) { return "Weigh in now"; },
    completeWeighIn: function(d) { return "Complete your weigh-in"; },
    viewWeightLossTable: function(d) { return "View my weight loss table"; }
  },
  openingTimes: {
    openingDays: {
      monToThurs: function(d) { return "Monday to Thursday"; },
      monToFri: function(d) { return "Monday to Friday"; },
      friday: function(d) { return "Friday"; },
      satSun: function(d) { return "Saturday to Sunday"; },
      satSunBank: function(d) { return "Saturday, Sunday and bank holidays"; }
    }
  },
  orderConfirmationModal: {
    orderNumber: function(d) { return "Your order number"; },
    status: {
      Authorised: {
        title: function(d) { return "Congratulations!"; },
        message: function(d) { return "Your order is being processed. You will soon receive a confirmation email from us."; },
        button: {
          close: function(d) { return "Close"; }
        }
      },
      Cancelled: {
        title: function(d) { return "We’re sorry"; },
        message: function(d) { return "The payment was cancelled"; },
        button: {
          close: function(d) { return "Check out again"; }
        }
      },
      Received: {
        title: function(d) { return "Order pending"; },
        message: function(d) { return "Your order is now pending. You will receive a confirmation via email shortly"; },
        button: {
          close: function(d) { return "Close"; }
        }
      }
    }
  },
  packageIndicator: {
    altText: function(d) { return "Included as part of our " + d.PACKAGE + " package"; }
  },
  passwordInput: {
    hide: function(d) { return "Hide password"; },
    show: function(d) { return "View password"; }
  },
  personalDetailsModal: {
    title: function(d) { return "Edit personal details"; },
    groupLabels: {
      name: function(d) { return "Your name"; },
      phone: function(d) { return "Phone number"; }
    },
    placeholders: {
      updateProfile: function(d) { return "Update my details on my Slimming World profile"; },
      firstName: function(d) { return "Forename"; },
      lastName: function(d) { return "Surname"; },
      phoneNumber: function(d) { return "Phone number"; }
    }
  },
  phoneNumberDetails: {
    intro: {
      onlineMemberSupport: function(d) { return "We’d love to hear from you! Our friendly support team is available…"; },
      technicalMemberSupport: function(d) { return "If you need help with our website or app, our digital support team is available..."; },
      groupEnquiries: function(d) { return ""; }
    }
  },
  profileButton: {
    buttonText: function(d) { return "My profile"; }
  },
  progressBarMini: {
    label: function(d) { return d.CURRENT + " out of " + d.TOTAL + " steps"; }
  },
  recipeDurationDisplay: {
    totalTime: {
      duration: function(d) { return plural(d.HOURS, 0, en, { "0": "", one: d.HOURS + " hour", other: d.HOURS + " hours" }) + " " + plural(d.MINUTES, 0, en, { "0": "", one: d.MINUTES + " minute", other: d.MINUTES + " minutes" }); }
    },
    additionalTime: {
      duration: function(d) { return " plus " + plural(d.HOURS, 0, en, { "0": "", one: d.HOURS + " hour", other: d.HOURS + " hours" }) + " " + plural(d.MINUTES, 0, en, { "0": "", one: d.MINUTES + " minute", other: d.MINUTES + " minutes" }) + " " + d.SUFFIX; }
    }
  },
  shippingAddressModal: {
    title: function(d) { return "Edit delivery address"; },
    groupLabels: {
      residencyInfo: function(d) { return "Your address"; },
      billingAddress: function(d) { return "Billing address"; },
      shippingAddress: function(d) { return "Delivery address"; }
    },
    placeholders: {
      updateProfile: function(d) { return "Update my details on my Slimming World profile"; },
      sameAsShipping: function(d) { return "Same as delivery address"; }
    }
  },
  siteMapCategories: {
    title: function(d) { return "Categories"; },
    sections: {
      shop: function(d) { return "Shop"; },
      recipes: function(d) { return "Recipes"; },
      features: function(d) { return "Support articles"; },
      successStories: function(d) { return "Weight loss stories"; },
      settings: function(d) { return "Settings"; },
      planner: function(d) { return "Planner"; }
    },
    shopSections: {
      membership: function(d) { return "Membership"; },
      food: function(d) { return "Food"; },
      printLong: function(d) { return "Books & mags"; }
    }
  },
  successStatsTile: {
    age: function(d) { return "Age"; },
    startWeight: function(d) { return "Starting weight"; },
    lostWeight: function(d) { return "Weight loss"; },
    timePeriod: function(d) { return "Time period"; },
    timePeriodMeasurement: function(d) { return "months"; }
  },
  synsAllowanceModal: {
    title: function(d) { return "Daily Syns allowance"; },
    genderLabel: {
      male: function(d) { return "Men"; },
      female: function(d) { return "Women"; }
    },
    weight: function(d) { return "Weight"; },
    synsPerDay: function(d) { return "Syns per day"; },
    upToWeight: function(d) { return "up to "; },
    overWeight: function(d) { return "over "; },
    upToSyn: function(d) { return "up to " + d.SYN_VALUE; }
  },
  weightLossMessage: {
    lost: function(d) { return "lost " + d.WEIGHT_DIFFERENCE; },
    gained: function(d) { return "gained " + d.WEIGHT_DIFFERENCE; },
    body: {
      "default": function(d) { return "If this looks right, please continue or go back a step to edit your weight."; },
      groupAwards: function(d) { return "If this looks right, please tell us if you achieved Slimmer of the Week or Month and click continue. To edit your weight, you can go back a step."; }
    },
    "default": {
      sentence: function(d) { return "Since your last weigh-in you’ve " + d.DIFFERENCE; },
      maintained: function(d) { return "maintained your weight."; }
    },
    isWeightConfirmation: {
      sentence: function(d) { return "You've " + d.DIFFERENCE + " this week."; },
      maintained: function(d) { return "maintained"; }
    }
  },
  blockContent: {
    errorBoundaryTitle: function(d) { return "block content"; }
  },
  myWeekWrapper: {
    errorBoundaryTitle: function(d) { return "my week"; }
  },
  detailTopBar: {
    errorBoundaryTitle: function(d) { return "the top bar"; }
  },
  heroBar: {
    errorBoundaryTitle: function(d) { return "header background image"; }
  },
  policyContent: {
    errorBoundaryTitle: function(d) { return "policy content"; }
  },
  filterPageExample: {
    filters: {
      title: function(d) { return "Filter Activity Videos"; },
      activityType: function(d) { return "Activity type"; },
      activityTypeLabel: {
        cardio: function(d) { return "Cardio"; },
        buildingStrength: function(d) { return "Building strength"; },
        balanceFlexibility: function(d) { return "Balance & flexibility"; },
        dance: function(d) { return "Dance"; },
        cooldown: function(d) { return "Cool-down"; }
      },
      bodyMagicLevel: function(d) { return "Body Magic level"; },
      bodyMagicLevelLabel: {
        gettingStarted: function(d) { return "Getting started"; },
        bronze: function(d) { return "Bronze"; },
        silver: function(d) { return "Silver"; },
        gold: function(d) { return "Gold"; }
      },
      sessionDurations: function(d) { return "Maximum Time"; },
      sessionDurationsLabel: {
        fiveMinutes: function(d) { return "5 Minutes"; },
        tenMinutes: function(d) { return "10 Minutes"; },
        fifteenMinutes: function(d) { return "15 Minutes"; },
        thirtyMinutes: function(d) { return "30 Minutes"; }
      }
    }
  },
  actionPlan: {
    errorBoundaryTitle: function(d) { return "My Action Plan"; },
    title: function(d) { return "My action plan"; },
    addButton: function(d) { return "Add an item"; },
    body: function(d) { return "What I'm going to do to stay on track this week..."; }
  },
  activityDuration: {
    label: function(d) { return "Activity duration"; },
    requiredField: function(d) { return "*"; },
    hoursPlaceholder: function(d) { return "Hours"; },
    minsPlaceholder: function(d) { return "Minutes"; }
  },
  addActivityForm: {
    completeLabel: function(d) { return "Activity complete"; },
    newActivityTitle: function(d) { return "Your Magic Mover award is just a step away"; },
    newActivityLabel: function(d) { return "Is this a new active habit for you? Let us know and celebrate your Magic Mover award today. You deserve it!"; },
    cta: {
      add: function(d) { return "Add to Planner"; }
    },
    errors: {
      duration: {
        required: function(d) { return "Activity entries need to be 1 minute or more."; }
      }
    }
  },
  addressForm: {
    placeholders: {
      addressLine1: function(d) { return "Address line 1"; },
      addressLine2: function(d) { return "Address line 2"; },
      addressLine3: function(d) { return "Address line 3"; },
      city: function(d) { return "Town or City"; },
      state: function(d) { return "State"; },
      county: function(d) { return "County"; },
      zip: {
        us: function(d) { return "Zipcode"; },
        gb: function(d) { return "Postcode"; },
        ireland: function(d) { return "Eircode"; },
        cyprus: function(d) { return "Postal code"; },
        "default": function(d) { return "Zipcode"; }
      },
      country: function(d) { return "Country"; },
      countyIe: function(d) { return "Please select"; }
    }
  },
  addressLookup: {
    searchToggle: {
      manual: function(d) { return "Enter address manually"; },
      search: function(d) { return "Search for your address"; }
    },
    searchErrorMessage: function(d) { return "Please verify your address details."; }
  },
  adyenPaymentError: {
    title: function(d) { return "Payment"; },
    description: {
      generalError: function(d) { return "We’re sorry, we’re experiencing a problem with our payment provider. Please try again in a few minutes."; },
      paymentError: function(d) { return "We’re sorry, your payment has been unsuccessful. Your bank hasn’t given us a reason, but you might want to double-check that your details were entered correctly or try again with a different card."; },
      sessionError: function(d) { return "We’re sorry your payment session has expired. Please click on the Try Again button."; },
      invalidRecaptchaToken: function(d) { return "Please follow the reCAPTCHA validation, as it’s an essential bit of security – just hit the Try Again button and complete the challenge or puzzle."; }
    },
    button: function(d) { return "Try Again"; }
  },
  adyenPrebuiltPayment: {
    adyenDisclaimer: function(d) { return "Secure payment powered by"; }
  },
  allergyInfo: {
    toggleButtonText: function(d) { return "Allergens and dietary info"; }
  },
  avatar: {
    memberSince: function(d) { return "Member since " + d.DATE; },
    memberSinceComposingComment: function(d) { return "You've been a member since " + d.DATE; },
    detailAwardsTitle: function(d) { return "Awards"; },
    errorBoundaryTitle: function(d) { return "the avatar"; },
    readOnlyProfileCTA: function(d) { return "View " + d.USERNAME + "'s profile"; }
  },
  avatarUpload: {
    form: {
      button: {
        upload: {
          create: function(d) { return "Upload your photo"; },
          update: function(d) { return "Change your photo"; },
          "delete": function(d) { return "Delete your photo"; }
        }
      }
    }
  },
  betterHealthTile: {
    titleSerif: function(d) { return "It's better"; },
    titleScript: function(d) { return "when we’re together!"; },
    specialOffer: function(d) { return "Special offer"; },
    body: function(d) { return "Slimming World is proud to support the Better Health campaign once again. Discover our amazing support and a new healthy mindset to help you achieve your goals."; },
    cta: function(d) { return "Find out more"; },
    terms: function(d) { return "T&Cs apply"; }
  },
  imageWithCaptionCarouselBlock: {
    counter: function(d) { return d.CURRENT_SLIDE + "/" + d.SLIDE_COUNT; }
  },
  synsAllowance: {
    greeting: function(d) { return "Hi " + d.USER; },
    description: function(d) { return "Based on your gender and weight you can have up to..."; },
    ctaLabel: function(d) { return "See all Syn allowances"; },
    syns: function(d) { return "Syns per day"; },
    noWeighInData: function(d) { return "No weigh in data yet"; }
  },
  videoBlock: {
    play: function(d) { return "Play video"; }
  },
  bmiError: {
    backPublic: function(d) { return "Back to the public site"; },
    onlineBmiHealthly: {
      fantastic: function(d) { return "Fantastic!"; },
      healthyWeight: function(d) { return "From the weight and height you’ve provided, your BMI indicates you are a healthy weight for your height."; },
      healthProblems: function(d) { return "In this range you're at least risk of developing weight-related health problems and your health is unlikely to benefit from further weight loss. It's important not to aim to go below this weight range. If you do have concerns about your weight, please discuss them with your GP."; }
    },
    onlineBmiLow: {
      underweight: function(d) { return "From the weight and height you’ve provided, your BMI indicates that you’re underweight for your height. Intentionally trying to lose weight to reach, and stay, within this range can be unhealthy. For that reason, we're unable to offer you membership at this time."; },
      concernWeight: function(d) { return "If you do have concerns about your weight, we recommend you discuss them with your doctor."; },
      unableMembership: function(d) { return "Although we’re unable to offer you membership of Slimming World, you’re welcome to enjoy our healthy recipes."; }
    },
    groupBmiLow: {
      title: function(d) { return "Your weight appears lower than what we allow for group membership. Please double-check the details you've entered."; },
      message: function(d) { return "If the information is correct and your BMI is less than 20, please discuss this with your Consultant during your next group session. \n\nIn the meantime, feel free to explore our healthy recipes on the public website."; }
    }
  },
  bookmarkButtonInteraction: {
    bookmark: function(d) { return "Bookmark this content"; },
    unbookmark: function(d) { return "Remove this content from your Bookmarks"; }
  },
  breastFeedingWarning: {
    title: function(d) { return "Slimming World is a safe, healthy way to lose weight while you're breastfeeding your little one. Please make sure you’ve had your post-natal check and been told it’s safe to lose weight by your GP or other healthcare professional before joining."; },
    additionalMessage: function(d) { return "When you’re breastfeeding, your body needs extra energy and extra calcium to help you stay healthy while producing milk for your baby - and we'll send you an email to let you know more once your enrolment's finished."; },
    buttonMessage: function(d) { return "You'll find really helpful information for new mums, including recipes and dietary tips on our Mums' website."; },
    button: function(d) { return "Visit our Mums' site"; }
  },
  cartButton: {
    addToBasket: function(d) { return "Add to basket"; },
    addedToBasket: function(d) { return "Added to basket"; },
    outOfStock: function(d) { return "Out of stock"; },
    basketLimitReached: function(d) { return "Limit reached"; },
    notify: function(d) { return "Notify me"; },
    notifyConfirm: function(d) { return "We will notify you"; },
    orderLimitReached: function(d) { return "You have already purchased the maximum available quantity of this product. Your allowance will reset " + d.RESET_TIME + ", when you will be able to purchase more."; },
    modal: {
      title: function(d) { return "Please note:"; },
      body: function(d) { return "Boxes of Hi-fi bars are available to buy in multiples of " + d.STEP_COUNT + ", with a maximum order of " + d.MAXIMUM_AMOUNT + " boxes per month. Each box contains 6 bars, and you can order any combo of flavours."; },
      close: function(d) { return "Close"; }
    }
  },
  comment: {
    interaction: {
      loadMore: function(d) { return "Load more replies"; },
      loadNewer: function(d) { return "Load newer replies"; },
      loadOlder: function(d) { return "Load older replies"; }
    }
  },
  commentContent: {
    deletedByModerator: function(d) { return "This comment was deleted by the - " + d.MODERATOR; },
    deletedByMember: function(d) { return "This comment was deleted by the member"; }
  },
  communityInteraction: {
    actions: {
      "delete": function(d) { return "Delete"; },
      report: function(d) { return "Report"; }
    },
    confirm: {
      "delete": function(d) { return "Are you sure you want to delete this?"; },
      report: function(d) { return "Are you sure you want to report this?"; }
    },
    buttonLabel: {
      all: function(d) { return "Edit, delete or report"; },
      isRemovable: function(d) { return "delete"; },
      isReportable: function(d) { return "report"; },
      isEditable: function(d) { return "edit"; },
      format: function(d) { return d.CONTENT + " a post"; }
    }
  },
  competitionEntryBanner: {
    title: function(d) { return "Congratulations!"; },
    description: {
      single: function(d) { return "You have been crowned your group’s " + d.COMPETITION_NAME + ". You have " + d.REMAINING_DAYS + " days to fill out an entry form for your chance to go through to the national awards."; },
      couplesPrimaryMember: function(d) { return "You and your partner have been crowned your group’s " + d.COMPETITION_NAME + ". You have " + d.REMAINING_DAYS + " days to fill out an entry form for your chance to go through to the national awards."; },
      couplesSecondaryMember: function(d) { return "You and your partner have been crowned your group’s " + d.COMPETITION_NAME + ". Your partner has " + d.REMAINING_DAYS + " days to check that both your details are correct and fill out an entry form for your chance to go through to the national awards."; }
    },
    outOfCriteriaDescription: {
      single: function(d) { return "You have been crowned your group’s " + d.COMPETITION_NAME + ". The story of your success has clearly inspired your fellow members, and we’d love to hear it too."; },
      couplesPrimaryMember: function(d) { return "You and your partner have been crowned your group’s " + d.COMPETITION_NAME + ". The story of your success has clearly inspired your fellow members, and we’d love to hear it too."; },
      couplesSecondaryMember: function(d) { return "You and your partner have been crowned your group’s " + d.COMPETITION_NAME + ". The story of your success has clearly inspired your fellow members. We’ve asked your partner to share your story with us as we’d love to hear it too."; }
    },
    buttonText: function(d) { return "Complete your entry"; },
    outOfCriteriaButtonText: function(d) { return "Share your story"; }
  },
  confirmTerms: {
    termsOfUse: {
      preButtononline: function(d) { return "I have read the "; },
      preButtongroup: function(d) { return "By joining Slimming World, you agree to our "; },
      preButton2online: function(d) { return " and our "; },
      preButton2group: function(d) { return " and our "; },
      button: function(d) { return "Terms and Conditions"; },
      button2: function(d) { return "Privacy Promise"; },
      postButtononline: function(d) { return ", including my cancellation rights."; },
      postButtongroup: function(d) { return ""; },
      preAgreement: function(d) { return ""; }
    },
    privacyPromise: {
      preButtononline: function(d) { return "I have read and understand the "; },
      preButtongroup: function(d) { return "I have read and understood the "; },
      preButton2online: function(d) { return ""; },
      preButton2group: function(d) { return ""; },
      button: function(d) { return "Privacy Promise"; },
      button2: function(d) { return ""; },
      postButtononline: function(d) { return ""; },
      postButtongroup: function(d) { return ""; }
    },
    receiveConsultantSupportEmails: {
      preButtononline: function(d) { return ""; },
      preButtongroup: function(d) { return "I'd like to receive ecards from my Slimming World Consultant"; },
      preButton2online: function(d) { return ""; },
      preButton2group: function(d) { return ""; },
      button: function(d) { return ""; },
      button2: function(d) { return ""; },
      postButtononline: function(d) { return ""; },
      postButtongroup: function(d) { return ""; },
      preAgreementonline: function(d) { return ""; },
      preAgreementgroup: function(d) { return "As a member of your group, your Consultant may send you emails and text messages and make contact using social media. If you no longer wish to receive these messages, please contact your Consultant directly."; }
    },
    userSubscription: {
      preButtononline: function(d) { return "I have read and understand the "; },
      preButtongroup: function(d) { return "I have read and understood the "; },
      preButton2online: function(d) { return ""; },
      preButton2group: function(d) { return ""; },
      button: function(d) { return "User Subscription Agreement"; },
      button2: function(d) { return ""; },
      postButtononline: function(d) { return ""; },
      postButtongroup: function(d) { return ""; }
    },
    receiveSupportEmails: {
      preButtononline: function(d) { return "I'd like to receive emails containing exclusive Slimming World offers and updates."; },
      preButtongroup: function(d) { return "I'd like to receive emails containing exclusive Slimming World offers and updates."; },
      preButton2online: function(d) { return ""; },
      preButton2group: function(d) { return ""; },
      button: function(d) { return ""; },
      button2: function(d) { return ""; },
      postButtononline: function(d) { return ""; },
      postButtongroup: function(d) { return ""; },
      preAgreementonline: function(d) { return "As part of your exclusive membership package with Slimming World, we will get in touch with important messages designed to help you lose weight, achieve your dream target and stay slim for life."; },
      preAgreementgroup: function(d) { return "As part of your exclusive membership package with Slimming World, we will occasionally get in touch with important messages about your service. These messages are designed to complement the invaluable support you receive in group and help you lose weight, achieve your dream target and stay slim for life."; }
    }
  },
  contactUsGroupEnquiries: {
    title: function(d) { return "Group enquiries"; },
    body: {
      info: function(d) { return "You can also " + d.FOCUS + " using the search below."; },
      focus: function(d) { return "look for your nearest group"; }
    }
  },
  contactUsSuccessMessage: {
    header: function(d) { return "Thank you"; },
    success: function(d) { return "Your email has been sent to the relevant Slimming World department."; },
    veryBusy: function(d) { return "We are receiving an exceptionally high volume of emails at the moment and it it is taking us longer to respond. We’re sure you’ll understand why, and we thank you for bearing with us at this time."; },
    body: {
      "default": function(d) { return "We aim to respond to all emails within 3 days, sooner if we can! If your query is urgent either call us on 0344 892 0430 or live chat with us."; },
      workDayDisclaimer: function(d) { return "(Monday to Friday - except bank holidays) before we can respond."; }
    }
  },
  createComment: {
    form: {
      input: {
        comment: {
          placeholder: function(d) { return "Type your comment ..."; },
          error: {
            fieldBlank: function(d) { return "Oops, this field is blank! Please fill in your message to continue."; }
          }
        }
      }
    },
    commentButton: function(d) { return "Add comment"; },
    replyButton: function(d) { return "Reply"; }
  },
  freeBookOfferRoundel: {
    free: function(d) { return "FREE"; },
    book: function(d) { return d.BOOK; },
    worth: function(d) { return "worth "; },
    price: function(d) { return d.PRICE; },
    moreDetails: function(d) { return "when you buy a 12-week\nCountdown 26th May -\n15th June 2019"; }
  },
  freeFestivePlannerOfferRoundel: {
    free: function(d) { return "FREE"; },
    planner: function(d) { return "Festive planner with a 12-week Countdown"; },
    until: function(d) { return "until 2nd November 2019"; }
  },
  FreeFreshStartMenuPlannerRoundel: {
    plus: function(d) { return "plus"; },
    free: function(d) { return "free"; },
    "fresh-start": function(d) { return "fresh-start"; },
    menu: function(d) { return "menu"; },
    planner: function(d) { return "planner*"; }
  },
  FreeMembershipRoundel: {
    save: function(d) { return "Save"; },
    price: function(d) { return "£5"; }
  },
  halfPriceOfferRoundel: {
    top: function(d) { return "Special offer"; },
    center: function(d) { return "Half-price\nmembership"; },
    bottom: {
      saving: function(d) { return "That’s a saving of"; },
      price: function(d) { return d.PRICE; }
    }
  },
  saveUpToOfferRoundel: {
    saveUpTo: function(d) { return "Save up to"; },
    save: function(d) { return "Save"; },
    price: function(d) { return d.PRICE; },
    moreDetails: function(d) { return "ask in group for details"; }
  },
  virtualGroupRoundel: {
    group: function(d) { return "Our groups\nhave gone"; },
    virtual: function(d) { return "virtual"; },
    trail: function(d) { return "for now"; }
  },
  dailyInspiration: {
    inspirationLabel: function(d) { return "Your daily"; },
    inspiration: function(d) { return "inspiration"; },
    moreButton: function(d) { return "Tell me more"; },
    errorBoundaryTitle: function(d) { return "Daily inspiration"; }
  },
  dailyInspirationMyWeekWrapper: {
    errorBoundaryTitle: function(d) { return "Daily inspiration and My Week"; }
  },
  dayPicker: {
    todayCta: function(d) { return "Today"; }
  },
  diabetesUKNutritionalInformation: {
    cta: function(d) { return "Nutrition Information"; },
    grid: {
      title: function(d) { return "each serving contains"; },
      key: {
        low: function(d) { return "low"; },
        medium: function(d) { return "medium"; },
        high: function(d) { return "high"; }
      }
    }
  },
  diabetesUKNutritionalInformationModal: {
    title: function(d) { return "each serving contains"; }
  },
  editableTagList: {
    selectTags: function(d) { return "Select Tags"; }
  },
  editProfileModal: {
    form: {
      biography: {
        label: function(d) { return "Biography"; },
        error: function(d) { return "Only a maximum of 140 characters is allowed"; }
      },
      profileLabel: function(d) { return "Change your profile background image"; },
      username: {
        label: function(d) { return "Username"; }
      }
    }
  },
  existingLegacyAccountPromptModal: {
    legacy: {
      title: function(d) { return "You're already a member"; },
      intro: function(d) { return "Our records show that you already have an account with us."; },
      description: function(d) { return "To make sure all your data moves with you to our new digital service please log into the existing website as normal. We'll let you know when we're ready to move your information over to our new service."; },
      button: function(d) { return "Login to " + d.GROUP_SERVICE; }
    },
    fresh: {
      title: function(d) { return "Do you want to start afresh?"; },
      intro: function(d) { return "If you're happy to lose all the information we've stored about you on the existing website (including your weight loss data, your awards and your favourites) then you could choose to start afresh as a brand new member."; },
      checkBox: function(d) { return "I confirm I'm happy to lose all my existing information and start afresh"; },
      button: function(d) { return "Start afresh"; }
    },
    errors: {
      confrmStartAfresh: {
        required: function(d) { return "Please confirm that you are happpy to continue."; }
      }
    },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  finishOnboarder: {
    finish: function(d) { return "Continue your tour?"; },
    "continue": function(d) { return "Let’s go!"; },
    close: function(d) { return "Don’t show this again"; }
  },
  foodRange: {
    title: function(d) { return "Buy me, or make me!"; },
    imgAlt: function(d) { return "Slimming World food range"; },
    wholeDescription: function(d) { return "This tasty dish is part of our Free Food range, available exclusively at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland. But it’s also a doddle to make at home when you have a little more time."; },
    partDescription: function(d) { return "Look out for our Slimming World food range, available at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland, where you’ll find Free sauces/meat products to make this meal."; }
  },
  foodRangeCta: {
    title: function(d) { return "Find me at Iceland, the Food Warehouse and Dunnes Stores"; },
    imgAlt: function(d) { return "Slimming World food range"; },
    wholeDescription: function(d) { return "This tasty dish is part of our Free Food range, available exclusively at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland. But it’s also a doddle to make at home when you have a little more time."; },
    partDescription: function(d) { return "Look out for our Slimming World food range, available exclusively at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland, where you’ll find Free sauces/meat products to make this meal."; },
    button: function(d) { return "Buy from " + d.RETAILER; }
  },
  inputDateSelector: {
    today: function(d) { return "Today"; },
    yesterday: function(d) { return "Yesterday"; },
    joinDateDetails: function(d) { return "This date will be the start of your weight loss journey on your progress chart."; }
  },
  groupAwardsCheckBox: {
    label: function(d) { return "Did you achieve a Slimmer of the Week or Slimmer of the Month award?"; },
    month: function(d) { return "Slimmer of the Month"; },
    week: function(d) { return "Slimmer of the Week"; }
  },
  groupedPasswordInput: {
    form: {
      oldPlaceholder: function(d) { return "Old password"; },
      enterPlaceholder: function(d) { return "Enter password"; },
      confirmPlaceholder: function(d) { return "Confirm password"; }
    },
    validators: {
      password: function(d) { return "Your password is required"; }
    }
  },
  groupRegRegionPicker: {
    label: function(d) { return "Please choose your region from these options"; },
    regions: {
      uk: function(d) { return "UK"; },
      roi: function(d) { return "Ireland"; },
      outside: function(d) { return "Rest of Europe"; }
    }
  },
  groupTimetable: {
    groupTimes: function(d) { return "Group Times"; },
    opens: function(d) { return "New group opening – " + d.DATE; }
  },
  hallOfFame: {
    body: function(d) { return "We think it's important to put hard working Slimmers in the spotlight, which is why we have Slimmer of the Week and Slimmer of the Month awards. This could be you too! Check the box below to take part."; },
    checkbox: function(d) { return "Yes! I want to be eligible for these awards"; }
  },
  handleErrorComponent: {
    title: function(d) { return "We’re so sorry..."; },
    descriptionError: function(d) { return "We’re unable to show you " + d.TITLE + " right now – please come back later and try again. In the meantime, we’d love you to carry on exploring the rest of the " + d.COMPONENT_TYPE + "."; },
    keepExperiencingProblems: function(d) { return "If you keep having this problem, " + d.CONTACT_US + " and we’ll help to get it sorted."; },
    contactUs: function(d) { return "contact us"; },
    errorLevel: {
      site: function(d) { return "site"; },
      page: function(d) { return "page"; }
    }
  },
  healthyExtraFlag: function(d) { return "Deduct " + plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn", other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns" }) + " if using " + d.NAME + " as a Healthy Extra " + d.HEALTHY_EXTRA + "."; },
  hereForYouVideoBlock: {
    title1: function(d) { return "We’ll always be"; },
    title2: function(d) { return "here for you"; },
    cta: function(d) { return "find a virtual group"; }
  },
  icelandPromotion: {
    sw: function(d) { return "Slimming World"; },
    freeFood: function(d) { return "Free Food"; },
    description: function(d) { return "Our delicious Free Food range, available exclusively at Iceland"; },
    cta: function(d) { return "Find out more"; }
  },
  icelandUpSellBlock: {
    exclusive: function(d) { return "exclusively availble at"; },
    title: function(d) { return "Love food"; },
    subtitle: {
      one: function(d) { return "love the"; },
      two: function(d) { return "Slimming World"; },
      three: function(d) { return "food range"; }
    },
    link: function(d) { return "find out more"; }
  },
  implicitRedirectCallback: {
    errorMessage: function(d) { return "Sorry, something went wrong while logging you in"; }
  },
  informationToggle: {
    defaultLabel: function(d) { return "Why do we ask for this?"; }
  },
  instagramTrending: {
    instagram: function(d) { return "on Instagram"; },
    description: function(d) { return "We have lots to tell you about… the very latest from us, and our members!"; },
    cta: function(d) { return "More from our Instagram"; }
  },
  lineChart: {
    noWeighIns: function(d) { return "Please weigh-in first"; },
    loadingData: function(d) { return "Loading your weigh-in data..."; },
    chart: {
      vAxis: {
        label: {
          metricFormat: function(d) { return "#" + "#" + "#" + "kg"; },
          imperialFormat: function(d) { return "#" + "#" + "#" + "lbs"; }
        }
      },
      hAxis: {
        label: {
          weekFormat: function(d) { return "W" + "#" + "#"; }
        }
      }
    },
    weightUnit: {
      label: {
        metric: function(d) { return "kilograms"; },
        imperial: function(d) { return "pounds"; },
        stones: function(d) { return "stones"; },
        abbreviation: {
          metric: function(d) { return "kg"; },
          imperial: function(d) { return "lbs"; },
          stones: function(d) { return "st"; }
        }
      }
    }
  },
  weighIn: {
    stepIndicator: {
      week: function(d) { return "Week"; },
      weightLoss: function(d) { return "Weight Loss"; },
      weightChange: function(d) { return "Weight Change"; },
      weightHidden: function(d) { return "Hidden"; },
      weighInEditedTitle: function(d) { return "Just to let you know,"; },
      weighInEditedLongDescription: function(d) { return "this weigh-in has been updated by the member, so this card may not reflect their actual weight loss progress."; },
      weighInEditedShortDescription: function(d) { return "Edited"; }
    }
  },
  magazinePromotion: {
    discover: function(d) { return "Discover the UK's No 1"; },
    header: function(d) { return "slimming\nmagazine"; },
    issue: function(d) { return "Our " + d.ISSUE + " edition is out now"; },
    currentIssue: function(d) { return "Jan/Feb"; },
    inside: function(d) { return "How our real-life cover star lost 3st 6lbs\nJump-start your weight loss in just 7 days with our supercharged plan"; },
    cta: function(d) { return "Find out more"; }
  },
  memberNavigationLinks: {
    search: function(d) { return "Search"; },
    browse: function(d) { return "Browse"; }
  },
  memberProfileMenu: {
    profile: function(d) { return "My profile"; },
    settings: function(d) { return "Settings"; },
    logout: function(d) { return "Logout"; }
  },
  MentalHealthPromotion: {
    body: function(d) { return "Feeling good mentally is just as vital for all of us as feeling good physically. That’s why we’ve teamed up with Public Health England on their Every Mind Matters campaign.\n\nFind out how to create your own ‘My Mind Plan’ here."; },
    cta: function(d) { return "Create your Mind Plan"; }
  },
  modifiedGroupTimetable: {
    venueChanged: function(d) { return "We’re in a different venue on " + d.DAYS + ". Please call me for details."; },
    additionalGroups: function(d) { return "I’m running extra sessions this week – please call me to find out more."; },
    groupNotRunning: function(d) { return "Sorry, our group won’t be running this week. Hope to see you in the near future."; },
    christmasGroupTimes: function(d) { return "Christmas Week Group Times"; },
    newYearGroupTimes: function(d) { return "New Year Week Group Times"; },
    hasNotConfirmed: function(d) { return "Please call the consultant for details"; }
  },
  moodIndicator: {
    defaultLabel: function(d) { return "Mood"; }
  },
  myWeekAward: {
    title: function(d) { return "My next award"; },
    awardsButton: function(d) { return "See all my awards"; }
  },
  commitmentDetails: {
    copyBeforeLoss: function(d) { return "I want to lose"; },
    copyAfterLoss: function(d) { return "this week!"; },
    notSet: function(d) { return "Set this week’s\nweight loss\ngoal"; },
    cantSet: function(d) { return "Set your goal\nat your next\nweigh-in"; },
    firstWeighIn: function(d) { return "Set your goal\nat your first\nweigh-in"; },
    maintain: function(d) { return "I’d like to\nmaintain\nthis week"; }
  },
  myWeekCommitment: {
    title: function(d) { return "My weight loss goal"; }
  },
  nearestGroupGeoLocation: {
    cta: function(d) { return "Use my location"; },
    label: function(d) { return "Find your nearest Slimming World group by location"; }
  },
  virtualGroupBar: {
    cta: function(d) { return "find out more"; },
    description: function(d) { return "Join a warm and friendly Slimming World group today "; }
  },
  nearestGroupSearchBar: {
    title: function(d) { return "Join a warm and friendly Slimming World group today "; },
    titleAlt: function(d) { return "Find your nearest group"; },
    searchPlaceholder: function(d) { return "Postcode"; },
    button: function(d) { return "Find your group"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    search: {
      resultsLabel: function(d) { return "Your search results for"; }
    }
  },
  publicGroupSearch: {
    label: function(d) { return "Please enter a postcode or town"; },
    errors: {
      geoLocation: {
        error: function(d) { return "We're sorry we can't pinpoint your location, please type in your postcode"; }
      },
      query: {
        required: function(d) { return "Please enter a valid postcode"; }
      },
      "query-landing": {
        required: function(d) { return "Please enter a valid postcode"; }
      }
    }
  },
  nextStep: {
    defaultTitle: function(d) { return "Next step"; }
  },
  onboarderIntro: {
    start: function(d) { return "Start the tour"; },
    postpone: function(d) { return "I’ll come back later"; }
  },
  onboarderIntroNew: {
    start: function(d) { return "Let's go"; },
    postpone: function(d) { return "Close"; }
  },
  onboarderStepContent: {
    step: function(d) { return "Step " + d.STEP_NUMBER + " of " + d.TOTAL_STEPS; },
    finish: function(d) { return "Start exploring"; }
  },
  onboarderStepContentNew: {
    step: function(d) { return "Step " + d.STEP_NUMBER + " of " + d.TOTAL_STEPS; },
    finish: function(d) { return "Finish"; },
    "continue": function(d) { return "Continue"; }
  },
  freePackageGift: {
    title: function(d) { return "Free for " + plural(d.MONTHS, 0, en, { one: number(d.MONTHS, "MONTHS") + " month", other: number(d.MONTHS, "MONTHS") + " months" }); },
    description: function(d) { return "This code gives you " + plural(d.MONTHS, 0, en, { one: number(d.MONTHS, "MONTHS") + " month", other: number(d.MONTHS, "MONTHS") + " months'" }) + " free access to our digital service."; }
  },
  packageSummary: {
    package: function(d) { return d.PACKAGE + " membership"; },
    total: function(d) { return "Total payment amount "; }
  },
  pagePromotion: {
    bmi: {
      header: function(d) { return "BMI\nCalculator"; },
      subHeader: function(d) { return "Body Mass Index – (BMI) is a handy guide to the healthy weight range for your height"; },
      intro: function(d) { return "Use your height and weight to find out which range you’re currently in, where you’d love to be and to track your progress"; }
    },
    cta: function(d) { return "Try it now"; }
  },
  paginationLabel: {
    showAll: function(d) { return "Show all"; }
  },
  passwordRequirement: {
    title: function(d) { return "Your password should..."; },
    "default": function(d) { return d.VALIDATOR_MESSAGE; },
    validator: {
      letter: function(d) { return "contain at least 1 letter"; },
      digit: function(d) { return "contain at least 1 number"; },
      minMax: function(d) { return "be between " + d.MIN_LENGTH + " and " + d.MAX_LENGTH + " characters"; }
    }
  },
  paymentTile: {
    paymentStatus: {
      "new": function(d) { return "Unfinished payment"; },
      pending: function(d) { return "Payment has not been processed by the financial institution yet."; },
      authorized: function(d) { return "Payment successful"; },
      refused: function(d) { return "Payment refused"; },
      "cancelled-by-shopper": function(d) { return "You canceled the payment"; },
      cancelled: function(d) { return "Payment is canceled after authorisation."; },
      refunded: function(d) { return "Slimming World refunded your subscription. Please find more details below"; },
      "refunded-partially": function(d) { return "Slimming World partially refunded your subscription. Please find more details below"; },
      "charge-back": function(d) { return "You requested a refund directly to your payment institution."; },
      error: function(d) { return "Payment error"; },
      abandoned: function(d) { return "Payment abandoned"; }
    },
    paymentMethod: {
      mc: function(d) { return "MasterCard"; },
      visa: function(d) { return "Visa"; },
      amex: function(d) { return "American Express"; }
    },
    paymentEvent: {
      PaymentAuthorizedEvent: function(d) { return "The payment was successful"; },
      PaymentCancelledByShopperEvent: function(d) { return "You canceled the payment"; },
      PaymentCancelledEvent: function(d) { return "Payment is canceled after authorisation."; },
      PaymentChargebackEvent: function(d) { return "You requested a refund directly to your payment institution."; },
      PaymentPendingEvent: function(d) { return "Payment has not been processed by the financial institution yet."; },
      PaymentRefundedEvent: function(d) { return "Slimming World refunded your subscription. Please find more details below"; },
      PaymentRefusedEvent: function(d) { return "The payment was refused"; },
      PaymentAbandonedEvent: function(d) { return "You abandoned the payment"; }
    },
    eventsTitle: function(d) { return "Events"; },
    noEvents: function(d) { return "There are no events available for this payment"; }
  },
  pinButtonInteraction: {
    pin: function(d) { return "Pin this content"; },
    unpin: function(d) { return "Remove this content from pins"; }
  },
  pregnancyWarning: {
    group: {
      title: function(d) { return "Congratulations from all of us at Slimming World!"; },
      message: function(d) { return "Your first port of call is your Consultant, who will give you a pregnancy booklet and chat to you about gaining the support of your midwife. We'll also update the online food planner to incorporate the additional Healthy Extras you'll need."; },
      additionalMessage: function(d) { return "You'll also find lots of advice on our Mums site."; },
      button: function(d) { return "Mum's site"; }
    },
    online: {
      title: function(d) { return "Congratulations from all of us at Slimming World!"; },
      message: function(d) { return "Your first port of call is your Consultant, who will give you a pregnancy booklet and chat to you about gaining the support of your midwife. We'll also update our Slimming World Planner to incorporate the additional Healthy Extras you'll need."; },
      additionalMessage: function(d) { return "You'll also find lots of advice on our Mums to be site."; },
      button: function(d) { return "Mums-to-be website"; }
    }
  },
  productInfo: {
    membershipIncludes: function(d) { return "You’re just a few minutes away from beginning your Slimming World journey! Your membership includes:"; },
    monthly: function(d) { return "After your initial membership, your subscription will continue on a monthly basis at £10 per month. You can cancel your membership at any time."; }
  },
  promotionText: {
    head: function(d) { return "Free Food February"; },
    body: function(d) { return "During the whole of February we'll be celebrating our famous Free Foods in a Slimming World group near you."; }
  },
  publicHorizontalSearchBar: {
    search: function(d) { return "Search"; },
    title: function(d) { return "Search for more delicious recipes"; },
    intro: function(d) { return "We've got a taster of just some of our recipes, join for access to hundreds more..."; },
    placeholder: function(d) { return "Search for recipes"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
  },
  searchRecipes: {
    errors: {
      query: {
        required: function(d) { return "Let us know what you want search for"; }
      }
    }
  },
  publicNavigationLinks: {
    home: function(d) { return "Home"; },
    howItWorks: function(d) { return "How it works"; },
    recipes: function(d) { return "Recipes"; },
    successStories: function(d) { return "Success Stories"; },
    signIn: function(d) { return "Sign in"; },
    groupRegister: function(d) { return "Register your group card"; }
  },
  recipeDetailModal: {
    contentNotFound: {
      script: function(d) { return "We're sorry,"; },
      text: function(d) { return "this content isn't available right now."; }
    }
  },
  recommendedReadingNavigation: {
    header: function(d) { return "Recommended reading for"; },
    sectionLabel: {
      next: function(d) { return "Next section"; },
      previous: function(d) { return "Previous section"; }
    }
  },
  regionPicker: {
    title: function(d) { return "You're just a few steps away from making the best decision of your life.\n\nTo get started choose one of our three packages and you're on your way to making your weight loss dreams a reality."; },
    groupLabels: {
      region: function(d) { return "Please choose your region"; }
    },
    regions: {
      uk: {
        name: function(d) { return "UK"; }
      },
      europe: {
        name: function(d) { return "Europe"; },
        note: {
          internationalDeliveryPolicy: {
            title: function(d) { return "International delivery policy"; },
            description: function(d) { return "Orders from our international members may be subject to local customs or import duties that will need to be paid before your items can be delivered. As these vary by country, including EU countries, we’re unable to predict what fees will be applied. Your local customs office should be in touch, once your order reaches them, to let you know more details."; }
          },
          barcodeScanner: {
            title: function(d) { return "Slimming World’s barcode scanner"; },
            description: function(d) { return "Our barcode scanner is based on foods available in the UK and Ireland, so some foods local to you may not be included.\n\nIf you find a food that’s not on our database, you can use our Syns Estimator tool to work out the Syn value."; }
          }
        }
      },
      outside: {
        name: function(d) { return "Outside Europe"; },
        note: {
          internationalDeliveryPolicy: {
            title: function(d) { return "International delivery policy"; },
            description: function(d) { return "Orders from our international members may be subject to local customs or import duties that will need to be paid before your items can be delivered. As these vary by country, we’re unable to predict what fees will be applied. Your local customs office should be in touch, once your order reaches them, to let you know more details."; }
          },
          currencyConversion: {
            title: function(d) { return "Currency conversion"; },
            description: function(d) { return "The conversion rate you pay will be set by your credit card provider. The total subscription fee will show on your bank/credit statement in your currency."; }
          },
          barcodeScanner: {
            title: function(d) { return "Slimming World’s barcode scanner"; },
            description: function(d) { return "Our barcode scanner is based on foods available in the UK and Ireland, so some foods local to you may not be included.\n\nIf you find a food that’s not on our database, you can use our Syns Estimator tool to work out the Syn value."; }
          }
        }
      }
    }
  },
  returnToComparison: {
    comparisonText: function(d) { return "Still not sure? Revisit our group and online comparison page"; },
    buttonLabel: function(d) { return "Comparison page"; }
  },
  securityAddressOrPostcode: {
    groupLabels: {
      addressLine1: function(d) { return "What is the first line of your address?"; },
      postCode: function(d) { return "What's your postcode?"; }
    },
    placeholders: {
      addressLine1: function(d) { return "Enter the first line of your address"; },
      postCode: function(d) { return "Postcode"; }
    },
    information: function(d) { return "It’s just for identification purposes, really – if you contact us, we’ll ask you for this info to make sure we’re talking to the right person."; },
    generalErrorMessage: function(d) { return "Sorry, something has gone wrong! Please try again"; }
  },
  selectedPackageCost: {
    subTitle: function(d) { return "Fabulous! You’re about to start your Slimming World journey with a " + d.PACKAGE_NAME + " membership."; },
    packageType: function(d) { return d.PACKAGE_TYPE + " membership"; },
    prices: {
      discount: function(d) { return "Promotional code discount"; },
      total: function(d) { return "Total to pay today"; },
      separator: function(d) { return "- "; }
    }
  },
  skipLinks: {
    navigation: function(d) { return "Skip to main navigation"; },
    subNavigation: function(d) { return "Skip to sub navigation"; },
    content: function(d) { return "Skip to content"; },
    footer: function(d) { return "Skip to footer"; }
  },
  socialShare: {
    title: function(d) { return "Share this " + d.PAGE; },
    typesOfPost: {
      successStory: function(d) { return "story"; },
      recipe: function(d) { return "recipe"; },
      "default": function(d) { return "page"; }
    }
  },
  staticContentMenu: {
    title: function(d) { return "Contents"; }
  },
  subscriptionPackage: {
    "for": function(d) { return "for " + d.TIME + "' Digital membership"; },
    gift: {
      duration: function(d) { return d.TIME + " online access for free"; }
    },
    freePackage: {
      trial: function(d) { return "trial for " + d.TIME; }
    }
  },
  successStoryData: {
    member: {
      startingWeight: function(d) { return "starting weight"; },
      currentWeight: function(d) { return "weight now"; },
      timePeriod: function(d) { return "time period"; },
      height: function(d) { return "height"; },
      months: function(d) { return d.PERIOD + " months"; }
    },
    public: {
      startingWeight: function(d) { return "Starting weight"; },
      currentWeight: function(d) { return "Weight now"; },
      timePeriod: function(d) { return "Time period"; },
      height: function(d) { return "Height"; },
      months: function(d) { return d.PERIOD + " months"; }
    }
  },
  successStoryStats: {
    age: function(d) { return "Age"; },
    startWeight: function(d) { return "Starting weight"; },
    lostWeight: function(d) { return "Weight Loss"; },
    timePeriod: function(d) { return "Time period"; },
    timePeriodMeasurement: function(d) { return "months"; }
  },
  tagList: {
    label: function(d) { return "Tags"; }
  },
  todoList: {
    placeholder: function(d) { return "Example: “Decide how to use my Syns allowance each day - and stick to it!”"; },
    removeLabel: function(d) { return "Remove this item from your action plan"; }
  },
  transferMembershipLauncher: {
    groupToOnline: {
      title: function(d) { return "Move to Online membership"; },
      intro: function(d) { return ""; },
      listOne: function(d) { return "You can move your group membership to online-only membership right here.​"; },
      listTwo: function(d) { return "It only takes a few minutes to set up – keep your group book pack to hand to make it even easier​"; },
      button: function(d) { return "Move to Slimming World Online"; }
    },
    onlineToGroup: {
      title: function(d) { return "Move to group membership"; },
      intro: function(d) { return "If you’d like to move your membership to a real-life group, we can arrange this for you."; },
      listOne: function(d) { return "We’ll issue you with a voucher to take along to your chosen group and hand to the Consultant.\nAt your group you’ll be given a card and PIN – you’ll need to enter this number within three days to access LifelineOnline (the group members’ site). In the meantime, you’ll still be able to access Slimming World Online."; },
      listTwo: function(d) { return "You’ll find lots of familiar features – like recipes, articles and the Planner – over on LifelineOnline. You’ll say goodbye to our Community and live events, though, as these are exclusive to Slimming World Online."; },
      button: function(d) { return "Move to group membership"; }
    }
  },
  ukPublicNavigationLinks: {
    home: function(d) { return "Home"; },
    howItWorks: function(d) { return "How does it work?"; },
    whatCanIEat: function(d) { return "What can I eat?"; },
    successStories: function(d) { return "Real-life stories"; },
    ourStory: function(d) { return "Our story"; },
    signIn: function(d) { return "Log in"; },
    join: function(d) { return "Join today"; },
    findGroup: function(d) { return "Find my group"; },
    recipies: function(d) { return "Recipes"; },
    specialOffers: function(d) { return "Special offers"; },
    groupCard: function(d) { return "Register your group card"; },
    contactUs: function(d) { return "Contact us"; },
    blog: function(d) { return "Blog"; },
    consultantLogin: function(d) { return "Consultants' log in"; },
    countrySwitch: {
      gb: function(d) { return "UK"; },
      ireland: function(d) { return "IE"; },
      cyprus: function(d) { return "CY"; }
    }
  },
  groupCardDetails: {
    title: function(d) { return "Update card and PIN​"; },
    success: function(d) { return "Successfully updated card and PIN"; }
  },
  upgradePackage: {
    viewPackages: function(d) { return "View all packages"; },
    hidePackages: function(d) { return "Hide packages"; }
  },
  userTimeZone: {
    placeholder: function(d) { return "Please select a timezone from the list"; }
  },
  videoList: {
    noResults: function(d) { return "We’re so sorry, we can’t show you our " + d.VIDEO_TYPE + " videos right now. Please come back later!"; }
  },
  videoMeta: {
    tagLabel: {
      equipmentRequired: function(d) { return "Equipment:"; },
      activityType: function(d) { return "Activity type:"; },
      segmentDuration: function(d) { return "Time:"; },
      bodyMagicLevels: function(d) { return "Body Magic level:"; }
    }
  },
  voucher: {
    formGroup: {
      heading: function(d) { return "Enter a promotional code here (only one promotional code per order)"; },
      check: function(d) { return "*Please check you have the correct code for the correct package"; }
    },
    form: {
      input: {
        voucherCode: {
          placeholder: function(d) { return "Promotional code"; }
        },
        submit: {
          label: {
            "default": function(d) { return "Apply promotional code"; },
            reset: function(d) { return "Change promotional code"; }
          }
        }
      }
    },
    errors: {
      voucherCode: {
        required: function(d) { return "Please enter a promotional code"; },
        invalid: function(d) { return "We're sorry that's not a valid promotional code."; },
        basketItemsNotEligible: function(d) { return "We’re sorry, none of the items in your basket are eligible for this voucher code"; },
        packageNotEligible: function(d) { return "We're sorry, the package you've selected is not eligible for this promotional code."; },
        regionNotEligible: function(d) { return "We're sorry, the selected region is not eligible for this voucher code"; },
        "default": function(d) { return "We're sorry, this promotional code does not apply to this item"; }
      }
    }
  },
  voucherToggle: {
    button: {
      label: function(d) { return "Do you have a promotional code?"; }
    }
  },
  weekNumberIndicator: {
    week: function(d) { return "Week"; }
  },
  weighInTodoList: {
    description: function(d) { return "What I'm going to do to stay on track this week..."; },
    form: {
      button: {
        add: function(d) { return "Add " + d.AMOUNT + " item"; },
        first: function(d) { return "an"; },
        multiple: function(d) { return "another"; }
      },
      input: {
        item: {
          placeholder: function(d) { return "Example: “Decide how to use my Syns allowance each day - and stick to it!”"; }
        }
      }
    }
  },
  journeyCTA: {
    title: function(d) { return "Let’s go!"; },
    body: function(d) { return "Add your details here and track\nyour progress to your dream weight"; },
    button: function(d) { return "Start your journey now"; }
  },
  yourAddressModal: {
    title: function(d) { return "Edit your address"; },
    groupLabels: {
      residencyInfo: function(d) { return "Your address information"; },
      billingAddress: function(d) { return "Billing address"; },
      shippingAddress: function(d) { return "Delivery address"; }
    },
    placeholders: {
      updateProfile: function(d) { return "Update my details on my Slimming World profile"; },
      sameAsShipping: function(d) { return "Same as delivery address"; }
    }
  },
  activityPlannerModal: {
    errorBoundaryTitle: function(d) { return "activity planner modal"; }
  },
  addOwnActivityForm: {
    title: function(d) { return "Add your own Activity"; },
    namePlaceholder: function(d) { return "Activity name"; },
    descriptionPlaceholder: function(d) { return "Activity description"; },
    muscleLabel: function(d) { return "Muscle strengthening"; },
    completeLabel: function(d) { return "Activity complete"; },
    newActivityTitle: function(d) { return "Your Magic Mover award is just a step away"; },
    newActivityLabel: function(d) { return "Is this a new active habit for you? Let us know and celebrate your Magic Mover award today. You deserve it!"; },
    errors: {
      duration: {
        required: function(d) { return "Activity entries need to be 1 minute or more."; }
      },
      activityName: {
        required: function(d) { return "Oops, this field is blank! Please enter activity name."; }
      },
      activityDescription: {
        required: function(d) { return "Oops, this field is blank! Please enter activity description."; }
      }
    },
    errorBoundaryTitle: function(d) { return "add own activity form"; }
  },
  campaignBlock: {
    title: function(d) { return "be slim\nfor life"; },
    "with": function(d) { return "with"; },
    sw: function(d) { return "Slimming\nWorld"; },
    subtitle: {
      one: function(d) { return "with"; },
      two: function(d) { return "Slimming World"; }
    },
    personalisation: {
      main: function(d) { return d.NAME + " lost " + d.LOST + "\n in " + d.TIME + "*"; },
      link: function(d) { return "- read her story " + d.HERE; }
    }
  },
  commentSection: {
    title: function(d) { return "Comments"; },
    alternateTitle: function(d) { return "Feeling inspired? "; },
    interaction: {
      loadMore: function(d) { return "Load more comments"; },
      loadNewer: function(d) { return "Load newer comments"; },
      loadOlder: function(d) { return "Load older comments"; }
    },
    errorBoundaryTitle: function(d) { return "the comment section"; }
  },
  contactUsForm: {
    contactMessage: function(d) { return "Your query"; },
    contactMessageTechnicalHelp: function(d) { return "The more information you give us the quicker we can resolve your issue. Please let us know what you were doing that led to the error, what’s happening now and any error messages you’ve seen."; },
    body: function(d) { return d.PREFER + ", choose your subject below. Please note, it can take up to three days to respond to email enquiries."; },
    prefer: function(d) { return "If you’d prefer to email us"; },
    error: {
      message: function(d) { return "Add the details of your question above"; },
      cardNumber: function(d) { return "Your card number must contain numbers only"; },
      required: {
        message: function(d) { return "message"; }
      }
    },
    placeholder: {
      subject: function(d) { return "Choose a subject"; }
    },
    categories: {
      onlineTechnicalHelp: function(d) { return "App/website support"; },
      groupInformation: function(d) { return "Group information"; },
      groupCustomerServices: function(d) { return "Group customer service"; },
      hiFiBars: function(d) { return "Hi-fi bars"; },
      groupTechnicalHelp: function(d) { return "App/website support"; },
      accountAndMembershipQueries: function(d) { return "Accounts and membership"; },
      transferingToOnline: function(d) { return "Move to online-only service"; }
    },
    cta: function(d) { return "Send"; }
  },
  createPost: {
    heading: {
      create: function(d) { return select(d.FIRST_NAME, { undefined: "Hi", other: "Hi " + d.FIRST_NAME }) + "! Start your post"; },
      edit: function(d) { return select(d.FIRST_NAME, { undefined: "Hi", other: "Hi " + d.FIRST_NAME }) + "! Edit your post"; }
    },
    headingShort: {
      create: function(d) { return "Start post"; },
      edit: function(d) { return "Edit post"; }
    },
    form: {
      generalErrorMessage: function(d) { return "Couldn't create a post please try again later."; },
      input: {
        title: {
          placeholder: function(d) { return "Click here to add a title to your post"; },
          hint: function(d) { return "In a nutshell, tell us what your post is about (max " + d.maxTitleCharacterCount + " characters)"; },
          error: {
            minLength: function(d) { return "Please fill in a title with at least 6 characters"; }
          }
        },
        body: {
          placeholder: function(d) { return "Start your post here..."; },
          error: {
            minWords: function(d) { return "Please fill in at least 5 words"; }
          }
        },
        tags: {
          label: function(d) { return "Select tags"; },
          hint: function(d) { return "Tags are important to help others find your messages."; }
        },
        image: {
          label: function(d) { return "Attach a photo"; },
          hint: {
            availableSlots: function(d) { return plural(d.maxUploadImageAmount, 0, en, { one: "", other: "Upload max. " + d.maxUploadImageAmount + " images" }); },
            slotsFull: function(d) { return "You've reached the maximum amount of images."; }
          }
        }
      },
      tooManyMentionsModal: {
        heading: function(d) { return "Oops!"; },
        body: function(d) { return "Your have reached your limit - You can only mention up to 20 members per post."; },
        buttonText: function(d) { return "Okay, got it!"; }
      },
      submit: {
        label: {
          create: function(d) { return "Post message"; },
          edit: function(d) { return "Save changes"; }
        },
        labelShort: function(d) { return "Post"; }
      }
    },
    groupPost: function(d) { return "Your post will be made to the " + d.GROUP_NAME + " group"; }
  },
  detailPostGroupCTA: {
    body: function(d) { return "Join the " + d.GROUP + " group to comment,\nlike or bookmark"; },
    view: function(d) { return "View group"; },
    join: function(d) { return "Join"; }
  },
  detailPostTile: {
    errorBoundaryTitle: function(d) { return "this community post"; },
    postedTo: function(d) { return "Posted to " + d.LINK; },
    enlargeImage: function(d) { return "Enlarge image"; }
  },
  externalSubscriptionInformation: {
    shop: function(d) { return "You will only see our resubscription offers once your current membership to Slimming World Online ends. You can manage your recurring subscription from the " + d.STORE + "."; },
    settings: function(d) { return "You can manage your recurring subscription to Slimming World Online from the " + d.STORE + "."; },
    stores: {
      appStore: function(d) { return "App Store"; },
      playStore: function(d) { return "Google Play Store"; }
    }
  },
  foodOptimisingStepContent: {
    step: function(d) { return "step" + d.STEP_NUMBER; },
    video: {
      linkText: function(d) { return "Watch the video"; }
    }
  },
  footer: {
    app: {
      download: function(d) { return "Download our app"; },
      Support: function(d) { return "Support your weight loss journey with our app."; },
      cta: function(d) { return "Find out more"; }
    },
    service: {
      currentlyUsing: function(d) { return "You are currently using"; },
      group: function(d) { return d.GROUP_SERVICE; },
      online: function(d) { return "Slimming World " + d.SCRIPT; },
      script: function(d) { return "Online"; }
    },
    disclaimer: function(d) { return "© Slimming World. The Slimming World logo, the words Slimming World, Free, Free Food, Healthy Extra and Syns are registered trademarks of Miles-Bramwell Executive Services trading as Slimming World.\n\n*Weight loss will vary due to your individual circumstances and how much weight you have to lose."; },
    disclaimer1Copy: function(d) { return "© Slimming World. The Slimming World logo, the words Slimming World, Free, Free Food, Healthy Extra and Syns are registered trademarks of Miles-Bramwell Executive Services trading as Slimming World."; },
    disclaimer2Copy: function(d) { return "*Weight loss will vary due to your individual circumstances and how much weight you have to lose."; },
    socialsTitle: function(d) { return "Follow us on"; },
    statistic: function(d) { return "†More people in the UK choose to attend a Slimming World group each week than any other weight loss group. "; },
    ipso: function(d) { return "Slimming World is a member of the Independent Press Standards Organisation (IPSO) and we subscribe to its Editors’ Code of Practice. Find out more " + d.LINK; },
    here: function(d) { return "here"; },
    magazine: {
      subscribe: function(d) { return "Find out more"; },
      subtitle: function(d) { return "Discover the UK’s number 1"; },
      title: function(d) { return "slimming magazine"; }
    },
    consultantLogin: function(d) { return "Consultants' log in"; }
  },
  goalTileContent: {
    label: function(d) { return "Inspiration"; },
    addGoal: function(d) { return "Add to My action plan"; },
    goalAdded: function(d) { return "Added to My action plan"; }
  },
  groupSearchConsultantDetails: {
    consultantName: function(d) { return "Your Consultant is"; },
    consultantTelephone: function(d) { return "Telephone"; },
    consultantLocation: function(d) { return d.NAME + " at " + d.PLACE; },
    welcomeText: {
      mobile: function(d) { return "A warm welcome..."; },
      desktop: function(d) { return "A really warm welcome..."; }
    }
  },
  groupSessionExplained: {
    titleBegin: function(d) { return "Your first time at a "; },
    titleEnd: function(d) { return "Slimming World group"; },
    modalLaunch: function(d) { return "Find out more"; },
    intro: function(d) { return "As soon as you walk through the doors, you’ll get a warm welcome from your Consultant and fellow members. Before you go ahead and join, your Consultant will explain everything you need to know about our delicious Food Optimising plan."; },
    part0: function(d) { return "You’ll then be invited to enjoy our support session where our members share ideas, recipes and their inspirational stories – this is where the magic happens!"; },
    part1: function(d) { return "Along with other new members, you’ll enrol and weigh-in privately – your weight always remains confidential."; },
    part2: function(d) { return "The session will last around 1½ hours – there's no obligation to join, but you’ll be so glad you did!"; }
  },
  emailConfirmed: {
    message: function(d) { return "Your email has not been confirmed yet."; },
    cta: function(d) { return "Resend"; }
  },
  firstWeighIn: {
    message: function(d) { return "Hey " + d.FIRST_NAME + "! It's time for your first weekly weigh in!"; },
    cta: function(d) { return "Weigh in now"; }
  },
  heightInput: {
    title: function(d) { return "What is your height?"; },
    legend: function(d) { return "Choose height units:"; },
    info: {
      online: function(d) { return "We'll use your weight and your height to work out your Body Mass Index (which for the majority of adults indicates whether our weight may be affecting our health)."; },
      group: function(d) { return "We need this to work out your Body Mass Index (BMI)"; }
    }
  },
  imageFlip: {
    label: {
      text: function(d) { return "Image of " + d.PERSON + " " + d.TYPE + " Slimming World diet"; },
      after: function(d) { return "after"; },
      before: function(d) { return "before"; }
    }
  },
  inAppBrowserSubscriptionInformation: {
    message: function(d) { return "Please contact our support team on 0344 892 0430 for help with your membership."; }
  },
  masonryOverview: {
    errorBoundaryTitle: function(d) { return "Whats trending"; }
  },
  notableDetails: {
    healthyExtra: function(d) { return "Deduct " + plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn", other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns" }) + " if using " + d.NAME + " as a Healthy Extra " + d.HEALTHY_EXTRA + "."; }
  },
  notificationIndicator: {
    unReadNotifications: function(d) { return "Notifications " + d.TOTAL_UNREAD; },
    noNotifications: function(d) { return "Notifications"; }
  },
  notificationMenuBlock: {
    allNotifications: function(d) { return "See all notifications"; },
    noNewNotifications: function(d) { return "There are no new notifications"; }
  },
  nutritionSymbols: {
    vegan: function(d) { return "Vegan"; },
    "dairy-free": function(d) { return "Dairy free"; },
    "gluten-free": function(d) { return "Gluten free"; },
    "freezer-friendly": function(d) { return "Freezer friendly"; },
    vegetarian: function(d) { return "Vegetarian"; },
    sp: function(d) { return "Extra Easy SP"; }
  },
  onboarderCommunity: {
    intro: {
      title: function(d) { return "Community"; },
      text: function(d) { return "Our brand-new forum where you can chat to fellow members, swap recipes and share your weight loss journey."; }
    },
    steps: {
      guidelines: {
        title: function(d) { return "Community guidelines"; },
        text: function(d) { return "Read our ground rules, designed to keep our Community a friendly and inspiring place to be."; }
      }
    }
  },
  onboarderEssentials: {
    intro: {
      title: function(d) { return "Food Optimising Essentials"; },
      onlineText: function(d) { return "Everything you need to start Food Optimising, including downloadable food lists and your personal Syns allowance."; },
      groupText: function(d) { return "A recap of Free Food, Healthy Extras and Syns. Plus, your personal Syns allowance."; },
      buttonLabel: function(d) { return "OK"; }
    }
  },
  onboarderHome: {
    intro: {
      title: function(d) { return "Take the"; },
      titleBig: function(d) { return "quick tour..."; },
      text: function(d) { return "and discover how to get the most from our service."; },
      viewLater: function(d) { return "If you'd like to take the tour later, you can close the screen and it will be available from your homepage."; }
    },
    steps: {
      profile: {
        title: function(d) { return "It’s all about"; },
        titleBig: function(d) { return "you"; },
        text: {
          online: function(d) { return "View your progress, be motivated by your dazzling awards and keep your profile info bang up-to-date."; },
          group: function(d) { return "View your progress, be motivated by your dazzling awards and keep your profile info bang up-to-date."; }
        }
      },
      notifications: {
        titleBig: function(d) { return "Spot the bell!"; },
        text: {
          online: function(d) { return "Check here for new messages. It could be a weigh-in reminder, a mention in the Community or an exciting newsflash!"; },
          group: function(d) { return "Check here for new messages. It could be a weigh-in reminder or an exciting newsflash!"; }
        }
      },
      homepage: {
        title: function(d) { return "Welcome to your"; },
        titleBig: function(d) { return "homepage"; },
        text: function(d) { return "Your go-to place to weigh in and create your action plan for a fab week ahead. Plus check out the latest articles recipes and success stories - inspiration galore!"; }
      },
      search: {
        title: function(d) { return "and you will find..."; },
        titleBig: function(d) { return "Search"; },
        text: {
          online: function(d) { return "Recipes, real-life slimming stories, supportive articles – find them all using our site-wide search."; },
          group: function(d) { return "Recipes, real-life slimming stories, supportive articles – find them all using our site-wide search."; }
        }
      },
      explore: {
        title: function(d) { return "It’s time to"; },
        titleBig: function(d) { return "explore"; },
        text: function(d) { return "Your gateway to everything our site has to offer. Happy browsing!"; }
      },
      finished: {
        title: function(d) { return "Congratulations! You've earned your " + d.EXPLORER + " award!"; },
        awardName: function(d) { return "explorer"; }
      }
    }
  },
  onboarderPlanner: {
    intro: {
      title: function(d) { return "Slimming World Planner"; },
      text: function(d) { return "Plan and track your daily food and activity."; }
    },
    steps: {
      summary: {
        title: function(d) { return "Track my food"; },
        text: function(d) { return "As well as all the lovely Free Food you’ll be eating, we’ll help you keep track of your Healthy Extras and Syns."; }
      },
      tabBar: {
        title: function(d) { return "Search"; },
        text: function(d) { return "This is where you search for food, recipes and activity. Food search is the place to look up Syn values too."; }
      }
    }
  },
  overviewSortBar: {
    sortByLabel: function(d) { return "Sort by"; },
    label: function(d) { return plural(d.numberOfPosts, 0, en, { "0": "There are no posts matching your criteria.", one: d.numberOfPosts + " post in total", other: d.numberOfPosts + " posts in total" }); },
    labelShort: function(d) { return plural(d.numberOfPosts, 0, en, { "0": "There are no posts matching your criteria.", one: d.numberOfPosts + " post", other: d.numberOfPosts + " posts" }); }
  },
  overviewSortBarNew: {
    sortByLabel: function(d) { return "Sort by"; },
    label: function(d) { return plural(d.numberOfPosts, 0, en, { "0": "There are no posts matching your criteria.", one: d.numberOfPosts + " post in total", other: d.numberOfPosts + " posts in total" }); },
    labelShort: function(d) { return plural(d.numberOfPosts, 0, en, { "0": "There are no posts matching your criteria.", one: d.numberOfPosts + " post", other: d.numberOfPosts + " posts" }); }
  },
  plannerItemInfo: {
    iconExplanations: {
      muscle: function(d) { return "This is a muscle strengthening activity"; },
      free: function(d) { return "We call them Free Foods, because you can eat as much of them as you like – no weighing, no counting, no measuring!"; },
      syns: function(d) { return "Counting syns are the way you can enjoy the foods many diets ban – and without a shred of guilt. All food that isn't Free has a Syn value and you can choose how to spend your Syns each day."; },
      heA: function(d) { return "Healthy Extra ‘a’ choices are dairy foods that are a good source of calcium."; },
      heB: function(d) { return "Healthy Extra ‘b’ choices are foods that are a good source of fibre or important nutrients for a healthy diet."; },
      SP: function(d) { return "Extra Easy SP optimises the super-slimming power of Speed foods and the super-satisfying power of Protein-rich foods – boosting your weight loss."; },
      speed: function(d) { return "Speed Free Foods are very low in energy density (calories), so they have extra slimming power. For the best results, enjoy at least one-third of a plate of Speed Free Foods each meal time and reach for them first between meals."; },
      calcium: function(d) { return "These foods are a good source of calcium, essential for healthy bones and teeth."; },
      fibre: function(d) { return "These foods are a good source of fibre, so they’re filling and good for the digestive system as well as helping prevent heart disease, type 2 diabetes and some cancers."; },
      protein: function(d) { return "Protein-rich foods are packed with filling power, helping you stay fuller for longer."; },
      wearable: function(d) { return "This activity was recorded on your fitness app and can’t be edited."; }
    },
    free: function(d) { return "Free"; },
    errorBoundaryTitle: function(d) { return "planner item info"; }
  },
  postFilter: {
    filterButtonLabel: function(d) { return "All filters"; },
    filterExpandedButtonLabel: function(d) { return "Close filters"; },
    filterButtonLabelMobileExpanded: function(d) { return "Show posts"; },
    filterButtonLabelMobile: function(d) { return "Filters (" + d.FILTER_TAG_TOTAL + ")"; },
    toggle: {
      filterAllMessages: function(d) { return "All messages"; },
      filterMyMessages: function(d) { return "My messages"; }
    }
  },
  postOverviewNavigation: {
    postType: {
      posts: function(d) { return "Posts"; },
      weighIns: function(d) { return "Weigh-ins"; }
    }
  },
  postTile: {
    errorBoundaryTitle: function(d) { return "this community post"; }
  },
  pregnancyNotificationModal: {
    title: function(d) { return "Congratulations"; },
    subTitle: function(d) { return "Thank you for letting us know about your pregnancy"; },
    description: function(d) { return "We recommend " + d.GROUP_MEMBERSHIP + " for our expectant mums – in group, with the guidance of your midwife, you’ll follow any recommendations regarding weight, diet and activity, supported by your Slimming World Consultant."; },
    nextStep: {
      app: function(d) { return "We can’t, unfortunately, provide this same level of support on our online-only service, which is why we will now freeze your Slimming World Online account.Our support team will be in touch to talk you through your options. If you need to get hold of us at any time email the " + d.CONTACT_SUPPORT; },
      web: function(d) { return "We can’t, unfortunately, provide this same level of support on our online-only service. We can arrange to either pause your Slimming World Online membership (and welcome you back after you’ve had your baby), or we can arrange a refund. Let us know which option you’d prefer."; }
    },
    groupMemberShip: function(d) { return "group membership"; },
    support: function(d) { return "support team"; },
    package: {
      freeze: {
        title: function(d) { return "Pause membership"; },
        description: function(d) { return "Your membership will be put on hold during your pregnancy"; }
      },
      cancel: {
        title: function(d) { return "Cancel and refund"; },
        description: function(d) { return "We'll cancel your online membership and provide a refund"; }
      },
      transfer: {
        title: function(d) { return "Move to group"; },
        description: function(d) { return "We can transfer your details over to our Group Membership service"; }
      }
    },
    confirm: {
      freeze: {
        title: function(d) { return "Your membership is now on hold"; },
        description: function(d) { return "We've paused your online membership until you're ready to start again. Let us know when you're ready to restart (We'll send you a confirmation email too)."; }
      },
      cancel: {
        title: function(d) { return "Cancel request confirmed"; },
        description: function(d) { return "We've cancelled your Slimming World Online membership - keep an eye on your emails as we'll be in touch to arrange a refund of your remaining membership. All the best for your pregnancy, and we'd love to welcome you back after the birth of your baby."; }
      }
    }
  },
  ProductTileContent: {
    specialOffer: function(d) { return "Special offer"; }
  },
  recipeDetailContent: {
    ingredients: function(d) { return "Ingredients"; },
    method: function(d) { return "Method"; },
    diabetesUKModal: {
      cta: function(d) { return "Nutrition Information"; },
      screenReader: function(d) { return "Display Nutrition Information"; }
    }
  },
  relatedPosts: {
    title: function(d) { return "Related posts"; },
    errorBoundaryTitle: function(d) { return "related posts"; }
  },
  searchFilterBar: {
    labels: {
      filterTitle: function(d) { return "Filters"; },
      open: function(d) { return "Open search filter"; }
    },
    searchResults: function(d) { return plural(d.NUM_RESULTS, 0, en, { "0": "No results", one: "1 result", other: number(d.NUM_RESULTS, "NUM_RESULTS") + " results" }); },
    totalFilters: function(d) { return " (" + d.NUM_FILTERS + ")"; }
  },
  searchPageTile: {
    goToThePage: function(d) { return "Go to the page"; }
  },
  transferError: {
    generic: {
      title: function(d) { return "We’re sorry, we can’t process your transfer"; }
    },
    roleCannotTransfer: {
      title: function(d) { return "We’re sorry, we’re unable to transfer your account."; }
    },
    exceedTransferLimit: {
      title: function(d) { return "We’re sorry, we’re unable to transfer your account."; },
      description: function(d) { return "This is because you've already been transferred in the past 12 months."; }
    },
    youngMember: {
      title: function(d) { return "We’re sorry, we’re unable to transfer your account."; }
    },
    cannotTransferPregnant: {
      title: function(d) { return "We’re sorry, we’re unable to transfer your account."; },
      description: function(d) { return "This is because you've let us know that you’re expecting a baby, and our online service is unsuitable at this time."; }
    },
    secondaryContent: {
      contactSupport: function(d) { return "Please " + d.CONTACT_SUPPORT + " for further help."; },
      pregnancy: function(d) { return "View your " + d.PREGNANCY_SETTINGS; }
    }
  },
  typicalDay: {
    intro: function(d) { return "At last, an eating plan that fits into your life..."; },
    subtitle: function(d) { return "Use the filter below to see examples of how to plan your day depending on specific circumstance."; },
    filters: {
      budget: function(d) { return "On a budget"; },
      family: function(d) { return "Family"; },
      vegetarian: function(d) { return "Vegetarian"; },
      thirtyMins: function(d) { return "30 minutes or less"; }
    },
    recipeTiles: {
      categories: {
        breakfast: function(d) { return "Breakfast"; },
        lunch: function(d) { return "Lunch"; },
        dinner: function(d) { return "Dinner"; }
      }
    },
    description: {
      "0": function(d) { return "Kick off your day with a colourful breakfast that’ll keep you full all morning. If you fancy something extra, add some flat mushrooms to the grill."; },
      "1": function(d) { return "It’s easy to transform the humble spud into a satisfying meal. There are loads of Free toppings to choose from – here we’ve got rich, tomatoey beans. That’s lunch sorted!"; },
      "2": function(d) { return "You can still enjoy a creamy-tasting curry at Slimming World (and rice, too)! Spice up dinner time with our Free take on this super-popular dish."; }
    }
  },
  weightInput: {
    title: function(d) { return "What is your height?"; },
    legend: function(d) { return "Choose weight units:"; },
    label: function(d) { return "Why do we ask for your weight?"; },
    info: {
      online: function(d) { return "We'll use your weight and your height to work out your Body Mass Index (which for the majority of adults indicates whether our weight may be affecting our health)."; },
      group: function(d) { return "We need this to work out your Body Mass Index (BMI)"; }
    }
  },
  wideTileContent: {
    pinnedPost: function(d) { return "Pinned post"; }
  },
  bodyMagicHub: {
    title: function(d) { return "Body Magic essentials"; },
    subtitle: function(d) { return "the first step"; },
    cooldown: function(d) { return "View all cool-down videos"; },
    activityVideos: {
      title: function(d) { return "activity videos"; },
      subtitle: function(d) { return "exclusively for our members"; }
    },
    filters: {
      title: function(d) { return "Explore our videos"; },
      description: function(d) { return "Our activity videos cover a range of fitness and confidence levels. Choose between 5, 10, 15 or 30 minutes depending on which award you’re working towards or how much time you have today."; },
      activityType: function(d) { return "Activity type"; },
      activityTypeLabel: {
        cardio: function(d) { return "Cardio"; },
        buildingStrength: function(d) { return "Building strength"; },
        balanceFlexibility: function(d) { return "Balance & flexibility"; },
        dance: function(d) { return "Dance"; }
      },
      bodyMagicLevel: function(d) { return "Body Magic level"; },
      bodyMagicLevelLabel: {
        gettingStarted: function(d) { return "Getting started"; },
        bronze: function(d) { return "Bronze"; },
        silver: function(d) { return "Silver"; },
        gold: function(d) { return "Gold"; }
      },
      segmentDuration: function(d) { return "Time"; },
      segmentDurationLabel: {
        fiveMinutes: function(d) { return "5 minutes"; },
        tenMinutes: function(d) { return "10 minutes"; },
        fifteenMinutes: function(d) { return "15 minutes"; },
        thirtyMinutes: function(d) { return "30 minutes"; }
      }
    }
  },
  contactUs: {
    header: function(d) { return "Contact us"; }
  },
  smartNotFound: {
    header: function(d) { return "Oops!"; },
    body: function(d) { return "This page isn’t loading right now"; },
    foodGuide: {
      button: function(d) { return "Take me back to the Food Guides"; }
    },
    learningContent: {
      button: function(d) { return "Take me back to Food Optimising essentials"; }
    }
  },
  accessDenied: {
    title: function(d) { return "This part’s for our online-only members."; },
    communityTitle: function(d) { return "We’re sorry, the Community is for our online-only members"; },
    description: function(d) { return "Need a chat? Ask your Consultant for access to your group’s private Facebook page, where you’ll share all things Slimming World with your fellow members."; },
    buttonLabel: function(d) { return "Return to homepage"; }
  },
  addVideoToPlannerButton: {
    addToPlannerCta: function(d) { return "Add to Planner"; }
  },
  videoCardStacked: {
    tagTime: {
      "5": function(d) { return "5 minutes"; },
      "15": function(d) { return "15 minutes"; },
      "30": function(d) { return "30 minutes"; }
    },
    tagLabel: {
      bodyMagicLevel: function(d) { return "Body Magic level:"; }
    }
  },
  contactUsFaqs: {
    header: function(d) { return "Have you got a niggling question?"; },
    body: function(d) { return "Find all the information you need to help with your query in our FAQs"; },
    cta: function(d) { return "Browse our FAQs"; }
  },
  faqCategory: {
    breadcrumb: {
      home: function(d) { return "Home"; },
      faq: function(d) { return "FAQ"; }
    },
    "return": function(d) { return "Return to FAQs"; }
  },
  faqLanding: {
    searchButton: function(d) { return "View all"; }
  },
  faqQuestionAnswer: {
    lastUpdated: function(d) { return "last updated: " + d.DATE; },
    backButton: function(d) { return "Return to FAQs"; }
  },
  recipePanel: {
    introduction: {
      heavenly: function(d) { return "a heavenly"; },
      slimmingWorld: function(d) { return "Slimming World"; },
      freeFoodDay: function(d) { return "Free Food day…"; }
    },
    preference: {
      vegan: {
        mealTypes: {
          breakfast: {
            tip1: function(d) { return "Plain or smoked tofu, cooked " + d.BOLD; },
            tip1bold: function(d) { return "using low-calorie cooking spray"; },
            tip2: function(d) { return "Tomatoes and mushrooms cooked " + d.BOLD; },
            tip2bold: function(d) { return "using low-calorie cooking spray"; }
          },
          lunch: {
            tip1: function(d) { return "Peppers roasted " + d.BOLD; },
            tip1bold: function(d) { return "without fat or oil"; },
            tip2: function(d) { return "Add beans or lentils for an even more satisfying soup"; }
          },
          dinner: {
            tip1: function(d) { return "Mushrooms and onions cooked " + d.BOLD; },
            tip1bold: function(d) { return "without fat or oil"; },
            tip2: function(d) { return d.BOLD + " for Free ones made with fat-free plain soya yogurt"; },
            tip2bold: function(d) { return "Swap high-calorie creamy sauces"; }
          }
        }
      },
      vegetarian: {
        mealTypes: {
          breakfast: {
            tip1: function(d) { return "Potatoes fried using low-calorie cooking spray, " + d.BOLD; },
            tip1bold: function(d) { return "not fat or oil"; },
            tip2: function(d) { return "Poached egg (or fried egg cooked " + d.BOLD; },
            tip2bold: function(d) { return "using low-calorie cooking spray)"; },
            tip3: function(d) { return "Grilled mushrooms and tomatoes, cooked " + d.BOLD; },
            tip3bold: function(d) { return "using low-calorie cooking spray"; }
          },
          lunch: {
            tip1: function(d) { return "Lots of filling beans and vegetables cooked in stock " + d.BOLD; },
            tip1bold: function(d) { return "with no fat or oil"; },
            tip2: function(d) { return d.BOLD + " for fat-free natural yogurt or plain quark – both Free!"; },
            tip2bold: function(d) { return "Swap high-calorie cream or crème fraîche"; }
          },
          dinner: {
            tip1: function(d) { return "Mushrooms and onions cooked " + d.BOLD; },
            tip1bold: function(d) { return "without fat or oil"; },
            tip2: function(d) { return d.BOLD + " for Free ones made with fat-free natural fromage frais or plain quark"; },
            tip2bold: function(d) { return "Swap high-calorie creamy sauces"; }
          }
        }
      },
      meat: {
        mealTypes: {
          breakfast: {
            tip1: function(d) { return "Slimming World or other Free sausages, cooked " + d.BOLD; },
            tip1bold: function(d) { return "without fat or oil"; },
            tip2: function(d) { return "Grilled back bacon " + d.BOLD; },
            tip2bold: function(d) { return "with all visible fat removed"; },
            tip3: function(d) { return "Egg fried " + d.BOLD; },
            tip3bold: function(d) { return "using low-calorie cooking spray"; },
            tip4: function(d) { return "Grilled mushrooms and tomatoes, cooked " + d.BOLD; },
            tip4bold: function(d) { return "using low-calorie cooking spray"; }
          },
          lunch: {
            tip1: function(d) { return "Lots of filling beans and vegetables cooked in stock " + d.BOLD; },
            tip1bold: function(d) { return "with no fat or oil"; },
            tip2: function(d) { return d.BOLD + " for fat-free natural yogurt or plain quark – both Free!"; },
            tip2bold: function(d) { return "Swap high-calorie cream or crème fraîche"; }
          },
          dinner: {
            tip1: function(d) { return "Chicken (skin and all visible fat removed) cooked " + d.BOLD; },
            tip1bold: function(d) { return "using low-calorie cooking spray"; },
            tip2: function(d) { return "Lots of filling vegetables, all " + d.BOLD; },
            tip2bold: function(d) { return "cooked without fat or oil"; }
          }
        }
      }
    },
    lockups: {
      breakfast: {
        yes: function(d) { return "Yes! "; },
        itsAll: function(d) { return "It’s all"; },
        freeFood: function(d) { return "Free Food..."; }
      },
      veganLunch: {
        noSynsInSight: function(d) { return "No Syns in sight"; },
        souper: function(d) { return "souper!"; }
      },
      meatVegiterianLunch: {
        allFree: function(d) { return "All Free"; },
        wouldYou: function(d) { return "would you"; },
        beanLieveIt: function(d) { return "bean-lieve it!"; }
      },
      dinner: {
        you: function(d) { return "You "; },
        really: function(d) { return "really"; },
        can: function(d) { return " can"; },
        enjoyFreePasta: function(d) { return "enjoy Free pasta"; },
        dishes: function(d) { return "dishes..."; }
      }
    }
  },
  free2GoLanding: {
    comingSoon: {
      title: function(d) { return "Coming soon…"; },
      description: function(d) { return "We’re launching a brand new app, especially for Free2Go members – your Consultant will let you know as soon as it’s ready!"; }
    },
    released: {
      title: function(d) { return "We have support waiting for you…"; },
      description: function(d) { return "Log in to our Family Affair app for recipes, top swaps and Body Magic inspiration."; },
      buttonLabel: function(d) { return "Go to the app"; }
    }
  },
  introduction: {
    greeting: function(d) { return "Getting to know you"; },
    subtitle: function(d) { return "We need a few details to personalise your journey and get you off to the very best start. This will only ever be seen by you, though."; },
    groupLabels: {
      name: function(d) { return "Firstly, what's your name?"; }
    },
    placeholders: {
      firstName: function(d) { return "First name"; },
      lastName: function(d) { return "Surname"; }
    },
    emailLabel: function(d) { return "Enter your email address"; },
    errors: {
      emailInUse: function(d) { return "The email address is already registered"; }
    },
    privacyPromise: {
      title: function(d) { return "I have read and understood the " + d.LINK; },
      link: function(d) { return "Privacy Promise"; }
    },
    nextDescription: function(d) { return "Your height, weight and medical check."; },
    errorBoundaryTitle: function(d) { return "introduction"; }
  },
  InvitationError: {
    title: function(d) { return "Invitation error"; },
    description: function(d) { return "There's been a problem with your Slimming World invitation. We are working hard to fix the problem and we'll be in touch soon to let you know what's happening."; },
    button: {
      label: function(d) { return "Go to the homepage"; }
    }
  },
  recipeSearch: {
    hero: {
      title: function(d) { return "Recipes"; },
      content: function(d) { return "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit inventore corporis facilis pariatur ad tenetur expedita culpa consequatur sed et nostrum aliquid rem incidunt, neque maxime sequi doloribus a obcaecati."; }
    },
    sortBy: function(d) { return "Sort by"; },
    pagination: function(d) { return d.limit + " Recipes of  " + d.total; },
    results: {
      serves: function(d) { return "Serves:"; },
      readyIn: function(d) { return "Ready in:"; },
      synsPerServing: function(d) { return "Syns per serving:"; }
    },
    loadAll: function(d) { return "Show all"; }
  },
  nonBlockingAgeNotification: function(d) { return "Hello, you must be one of our Free2Go members. We’re looking forward to having you on the website and hope you find our features and recipes useful.\n\nPlease note: the content on the site is designed for our adult members, so won’t be fully in line with your Free2Go plan and some features have been turned off for our younger members. If you see anything that doesn’t seem quite right, or concerns you, please do chat to your parent/guardian or your group Consultant."; },
  medicalCheck: {
    greeting: function(d) { return "Nice to meet you" + select(d.NAME, { undefined: "", other: ", " + d.NAME }) + "!"; },
    subtitle: function(d) { return "To get you off to the very best start and give you the very best service, we need a few details from you. We promise your information will always be kept private."; },
    groupDataCollectionInfo1: function(d) { return "This is where you sign up for your free access to the Slimming World website and app. Your Consultant gave you a membership card and PIN when you joined your group. To help us set up your online account, please fill in the details below."; },
    groupDataCollectionInfo2: function(d) { return "You’ll need to answer a few questions about your weight loss journey, too, so that we can provide you with the best possible online support."; },
    groupLabels: {
      name: function(d) { return "First, what's your name?"; },
      dateOfBirth: function(d) { return "What's your date of birth?"; },
      gender: function(d) { return "Are you?"; },
      isPregnant: function(d) { return "Are you pregnant?"; },
      isBreastfeeding: function(d) { return "Are you breastfeeding?"; },
      currentWeight: function(d) { return "What’s your current weight?"; },
      startWeight: function(d) { return "Please share your starting weight"; },
      height: function(d) { return "How tall are you?"; },
      hasMedicalConditions: function(d) { return "Medical information"; },
      eatingDisorders: function(d) { return "Eating disorders"; },
      liverKidneyDiseaseCancer: function(d) { return "Liver/kidney disease and cancer"; }
    },
    blockingErrors: {
      isPregnant: {
        primary1: function(d) { return "Congratulations from all of us at Slimming World!"; },
        primary2: function(d) { return "We recommend group membership for our mums-to-be. In group, with the guidance of your midwife, your Slimming World Consultant will support you with any recommendations regarding your weight, diet and activity. We can’t, unfortunately, provide this same level of support through Slimming World Online."; },
        secondary1: function(d) { return "If you'd like to join Slimming World Online after your little one arrives we'd be delighted to welcome you as a member."; },
        secondary2: function(d) { return "In the meantime you’ll find loads of helpful information, including recipes, healthy eating advice and pregnancy dietary tips on our website for mums-to-be and new mums."; },
        mumsCTA: function(d) { return "Visit our Mums' site"; }
      },
      hasMedicalConditions: {
        para1: function(d) { return "Thank you for letting us know. We’d love to help you achieve your weight loss dreams."; },
        para2: function(d) { return "Before joining Slimming World Online please get the support of your healthcare team."; },
        para3: function(d) { return "We’re aware that members with certain medical conditions, including cancer, liver disease or kidney disease, may experience unexpected weight changes as a consequence of their health condition."; },
        para4: function(d) { return "As part of our online service, we don’t have the ability to monitor members’ health, weight changes, or concerns about weight changes in relation to their health condition on a personal level. It’s possible the support and ideas suggested through the online system may not be appropriate if any weight loss was unintentional and occurred as a result of your health condition, and not as a result of following our healthy weight loss plan. That’s why we encourage you to keep in regular contact with your healthcare team and get in touch with them if you have any concerns about your health or experience any unexpected or rapid weight changes."; },
        checkbox: function(d) { return "Please confirm you’ve read the above information before proceeding with your registration."; }
      },
      hasEatingDisorder: {
        para1: function(d) { return "Thank you for letting us know. Eating disorders aren’t our area of expertise and we don’t have the ability as part of our online service to monitor members on a personal level."; },
        para2: function(d) { return "That means, unfortunately, we’re unable to offer you membership."; },
        para3: function(d) { return "Joining an online weight management programme may not be the best solution for your condition at this time. Please contact your GP to discuss what local support services are available to you."; },
        para4: function(d) { return "Although we’re unable to offer you membership to Slimming World online, you’re welcome to enjoy our healthy recipes on the public website."; },
        button: function(d) { return "Back to public website"; }
      },
      ageNotAllowed: {
        online: {
          main: function(d) { return "We’re sorry we’re only able to offer Slimming World Online membership to people aged 18 and over."; },
          findOutMoreCTA: function(d) { return "Find out more about Slimming World group membership."; }
        },
        group: {
          title: function(d) { return "Sorry"; },
          main: function(d) { return "We’re sorry our Free2Go service for young people is only available for " + d.FREE_2_GO_MIN + "-" + d.FREE_2_GO_MAX + " year olds."; }
        }
      }
    },
    gender: {
      label: function(d) { return "Why do we ask for this?"; },
      information: function(d) { return "As you continue your enrolment, you’ll spot that members who are male, if they wish, can have an additional 5-10 Syns a day and this is because they generally have more lean tissue and a higher metabolic rate than female members."; }
    },
    medicalConditionCopy: function(d) { return "Do you have an active medical diagnosis of cancer, liver or kidney disease, or any eating disorder including anorexia nervosa or bulimia?"; },
    eatingDisordersCopy: function(d) { return "Do you have an active eating disorder including anorexia or bulimia?"; },
    liverKidneyDiseaseCancer: {
      copy: function(d) { return "Do you have an active medical diagnosis of any of the following medical conditions:"; },
      conditions: function(d) { return "Cancer\nLiver disease\nKidney disease"; }
    },
    weightHeight: {
      label: function(d) { return "Weight and height"; },
      informationToggleLabel: function(d) { return "Why do we ask for your height?"; },
      notice: function(d) { return "If you don’t have your exact starting weight, no worries – you can change it from your profile page before your first weigh-in."; },
      informationOnline: function(d) { return "We'll use your weight and your height to work out your Body Mass Index (which for the majority of adults indicates whether our weight may be affecting our health)."; },
      informationGroup: function(d) { return "We need this to work out your Body Mass Index (BMI)"; }
    },
    placeholders: {
      firstName: function(d) { return "First name"; },
      lastName: function(d) { return "Surname"; },
      groupSearchPostcode: function(d) { return "postcode"; }
    },
    options: {
      gender: {
        female: function(d) { return "Female"; },
        male: function(d) { return "Male"; }
      },
      toggle: {
        yes: function(d) { return "Yes"; },
        no: function(d) { return "No"; }
      }
    },
    nextDescription: function(d) { return "Let's set up your account."; },
    errorBoundaryTitle: function(d) { return "medical check"; }
  },
  notFound: {
    "default": {
      title: function(d) { return "Sorry, this page isn't available"; },
      buttonLabel: function(d) { return "Return to homepage"; }
    },
    communityPost: {
      title: function(d) { return "Sorry, this post is no longer available"; },
      buttonLabel: function(d) { return "Return to community"; }
    },
    description: function(d) { return "The link you followed may be broken, or the page may have been removed."; }
  },
  personalDetails: {
    greeting: function(d) { return d.NAME + ", now for \nyour account details"; },
    subCopy: function(d) { return "To keep your personal info secure and protect your LifelineOnline account, please enter the following details…"; },
    passwordLabel: function(d) { return "Choose your password"; },
    passwordPlaceholder: function(d) { return "Password"; },
    passwordConfirmPlaceholder: function(d) { return "Confirm Password"; },
    passwordInfo: function(d) { return "This is used to log in to your account"; },
    passwordHelper: function(d) { return "Create a strong password – between " + d.MIN_LENGTH + " and " + d.MAX_LENGTH + " characters with a mixture of letters and numbers."; },
    emailLabel: function(d) { return "Email address"; },
    emailTitle: function(d) { return "Email address"; },
    emailInformation: function(d) { return "You can use your email address to log on, and we’ll send essential membership info to this address, too."; },
    contact: {
      label: function(d) { return "Contact number"; },
      informationToggleLabel: function(d) { return "Why do we ask for a contact number?"; },
      information: function(d) { return "It’s just for identification purposes, really – if you contact us, we’ll ask you for this info to make sure we’re talking to the right person! We’d never cold call, and we won’t pass your number on to anyone else."; },
      placeholder: function(d) { return "Phone number (Optional)"; }
    },
    confirmTerms: {
      title: function(d) { return "Our Terms and Conditions"; }
    },
    nextDescription: function(d) { return "Continue to your membership summary page."; },
    errorBoundaryTitle: function(d) { return "personal details"; }
  },
  summary: {
    registration: {
      title: function(d) { return "Here's your order summary"; },
      inviteTitle: function(d) { return "You’re almost there…"; },
      orderSummary: {
        voucherGift: function(d) { return "Your voucher gives you " + plural(d.MONTHS, 0, en, { one: number(d.MONTHS, "MONTHS") + " month", other: number(d.MONTHS, "MONTHS") + " months'" }) + " free access to our digital service. You're just a few minutes away from starting your Slimming World journey."; },
        invite: function(d) { return "You've been invited to join Slimming World Online and you're just a few minutes away from starting your exciting Slimming World journey."; },
        subTitle: function(d) { return "Fabulous! You’re about to start your Slimming World journey with a " + d.PACKAGE_NAME + " membership."; },
        description: function(d) { return "If you'd like to change your membership option, you'll see details of all our packages below. Just pick the one that best suits your needs..."; }
      },
      billing: {
        label: function(d) { return "Your delivery address"; },
        searchPlaceholder: function(d) { return "Search for your address"; }
      }
    },
    address: {
      searchPlaceholder: function(d) { return "Type the first line of your address"; }
    },
    billing: {
      information: function(d) { return "We will only use your mailing address to help us confirm your identity"; }
    },
    delivery: {
      label: function(d) { return "Your delivery address"; },
      toggleUseBilling: function(d) { return "Same as above"; },
      toggleUseDelivery: function(d) { return "Add new address"; },
      searchPlaceholder: function(d) { return "Search for your address"; },
      informationToggleLabel: function(d) { return "Why do we ask for a delivery address?"; },
      information: function(d) { return "Any goodies you order from our shop will be posted to this address. And if you contact us, we’ll ask you for this info to make sure we’re talking to the right person."; }
    },
    contact: {
      label: function(d) { return "Contact number"; },
      informationToggleLabel: function(d) { return "Why do we ask for a contact number?"; },
      information: function(d) { return "It’s just for identification purposes, really – if you contact us, we’ll ask you for this info to make sure we’re talking to the right person! We’d never cold call, and we won’t pass your number on to anyone else."; },
      placeholder: function(d) { return "Phone number"; }
    },
    submit: {
      payment: function(d) { return "Continue to payment"; }
    },
    almostDone: function(d) { return "Almost done—you’ll be redirected to our secure payment provider to complete your purchase."; },
    next: {
      title: {
        payment: function(d) { return "Final step"; },
        buildProfile: function(d) { return "Getting to know you"; }
      },
      description: {
        payment: function(d) { return "Time to get your card details ready..."; },
        buildProfile: function(d) { return "Tell us a bit more about yourself, so we can personalise your journey."; }
      }
    },
    errorBoundaryTitle: function(d) { return "registration summary"; }
  },
  welcomeBack: {
    title: function(d) { return "Welcome Back"; },
    greatNews: function(d) { return "Great news, you already have an account with us."; },
    greeting: function(d) { return "Sign in by clicking on the button below. Don't worry if you've forgotten your password, you'll be able to reset it."; },
    login: function(d) { return "Login"; },
    backToRegistration: function(d) { return "Back to registration"; },
    errorBoundaryTitle: function(d) { return "welcome back"; }
  }
}};