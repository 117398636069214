import React, { ReactNode, useEffect, useState } from 'react';
import AdyenPrebuiltPayment from 'components/molecules/AdyenPrebuiltPayment';
import AdyenPaymentError from 'components/molecules/AdyenPaymentError';
import ReCaptchaCheckbox from 'components/atoms/ReCaptchaCheckbox';

import Pages from 'common/src/app/data/enum/Pages';
import useExtendAdyenConfig from 'common/src/app/adyen/hooks/useExtendAdyenConfig';
import AdyenConfig from 'common/src/app/adyen/types/AdyenConfig.d';
import './adyen-payment.scss';
import useRecaptchaCloseListener from './useRecaptchaCloseListener';

const onReCAPTCHAClose = (
  recaptchaToken: string | null,
  setReCAPTCHACancelled: (cancel: boolean) => void,
) => {
  if (!recaptchaToken) {
    return setReCAPTCHACancelled(true);
  }
  if (recaptchaToken) {
    return setReCAPTCHACancelled(false);
  }
  return null;
};

export type AdyenPaymentProps = {
  purchaseId: string | null;
  paymentRecaptchaSiteKey: string;
  paymentResponse?: (purchaseId: string | null, resultCode: string) => void;
  redirectToPaymentSuccessPage: (resultCode: string) => void;
  recaptchaToken: string | null;
  recaptchaExecuted: boolean;
  getSessionDetails: (
    purchaseId: string,
    returnUrl: string,
    recaptchaToken: string,
  ) => Promise<{ data: AdyenConfig }>;
  pageUrl: string;
  fallbackRedirect?: () => void;
  query?: {
    sessionId?: string;
    redirectResult?: string;
  };
  children?: (config: AdyenConfig) => ReactNode;
};

const AdyenPayment = ({
  purchaseId,
  paymentRecaptchaSiteKey,
  paymentResponse,
  redirectToPaymentSuccessPage,
  getSessionDetails,
  pageUrl,
  fallbackRedirect,
  query,
  recaptchaToken,
  recaptchaExecuted,
  ...props
}: AdyenPaymentProps): JSX.Element => {
  const [reCAPTCHACancelled, setReCAPTCHACancelled] = useState(false);
  const [reCAPTCHALoading, setReCAPTCHALoading] = useState(true);
  const [config, setConfig] = useState<AdyenConfig>();
  const [tokenNotValid, setTokenNotValid] = useState(false);

  useRecaptchaCloseListener(onReCAPTCHAClose, recaptchaToken, setReCAPTCHACancelled);

  const { extendConfig, error, setError, paymentStatus, processingPayment, retrigger, unmount } =
    useExtendAdyenConfig({
      config,
      paymentSuccessRedirect: redirectToPaymentSuccessPage,
      paymentResponse,
    });

  useEffect(() => {
    const noRecaptchaToken: boolean = !recaptchaToken || reCAPTCHALoading || reCAPTCHACancelled;

    const getConfig = async () => {
      if (!purchaseId || processingPayment || noRecaptchaToken) return null;

      try {
        if (typeof recaptchaToken === 'string') {
          const state = await getSessionDetails(purchaseId, pageUrl, recaptchaToken);
          return setConfig(state.data);
        }
        return null;
      } catch (e) {
        const { response } = e as {
          response?: { status?: number; parsed?: { error?: { code?: string } } };
        };
        const { status, parsed } = response || {};
        const { error: errorDetail } = parsed || {};

        if (status === 400 && errorDetail?.code === 'invalid') {
          return setError('sessionError');
        }

        if (status === 400 && errorDetail?.code === 'validation-error') {
          return setTokenNotValid(true);
        }

        return setError('generalError');
      }
    };

    // if they have a purchaseId but no config go and fetch the config
    purchaseId && !config && getConfig();
    // if there is not purchase id and a payment is not in process take
    // them back to reg/login
    !processingPayment && !purchaseId && fallbackRedirect && fallbackRedirect();
  }, [
    pageUrl,
    reCAPTCHALoading,
    recaptchaToken,
    processingPayment,
    purchaseId,
    getSessionDetails,
    fallbackRedirect,
    config,
    setError,
  ]);

  const authFailed = paymentStatus === 'Refused' && !!query?.sessionId;

  return (
    <>
      {config && props.children?.(config)}
      {(paymentStatus || error) && (
        <AdyenPaymentError
          authFailed={authFailed}
          paymentStatus={paymentStatus}
          error={error}
          link={Pages.REGISTRATION_CHECKOUT}
        />
      )}
      {config && (
        <AdyenPrebuiltPayment
          unmount={unmount}
          retrigger={retrigger}
          paymentStatus={paymentStatus}
          sessionId={query?.sessionId}
          redirectResult={query?.redirectResult}
          config={extendConfig}
          dropInConfig={{
            instantPaymentTypes: ['applepay', 'googlepay'],
          }}
          authFailed={authFailed}
        />
      )}
      {(tokenNotValid || reCAPTCHACancelled) && <AdyenPaymentError error="invalidRecaptchaToken" />}
      <div className="adyen-recaptcha">
        <ReCaptchaCheckbox
          setLoading={setReCAPTCHALoading}
          size="invisible"
          siteKey={paymentRecaptchaSiteKey}
        />
      </div>
    </>
  );
};

export default AdyenPayment;
