/**
 * Takes an input string and formats it to fit the rules for a UK postal code.
 * Space between blocks will only be introduced if both outcode and incode exist without overlap.
 * This is not intended to replace validation, but just to assist the user with formatting.
 *
 * @param {string} value The original string value to be formatted
 * @returns {string} The formatted string
 */
export const ukPostCodeFormatter = value => {
  if (!value) return value;

  let cleanString = value.toUpperCase().replace(/\s+/g, ' ');

  const wordCount = cleanString.trim().split(' ').length;
  cleanString = wordCount > 1 ? cleanString.trim() : cleanString.trimStart();

  const incodeRegex = /(\d[A-Z]{2})$/;
  const outCodeRegex = /^([A-Z]{1,2}\d{1,2}[A-Z]?|GIR)/;

  const withoutIncode = removeWhiteSpace(cleanString).replace(incodeRegex, '');
  const hasInCode = removeWhiteSpace(cleanString) !== withoutIncode;
  const hasOutCode = outCodeRegex.test(withoutIncode);

  return hasOutCode && hasInCode
    ? removeWhiteSpace(cleanString).replace(incodeRegex, ' $1')
    : cleanString;
};

const removeWhiteSpace = stringValue => stringValue.replace(/\s+/g, '');
