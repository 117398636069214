import handleActions from 'redux-actions/lib/handleActions';
import { SAVE_SECURITY_QUESTIONS } from '../actions/resources/securityQuestionActions';

/**
 * @function securityQuestionReducer
 */
const securityQuestionReducer = handleActions(
  {
    [SAVE_SECURITY_QUESTIONS]: (state, { payload }) => [...payload],
  },
  [],
);

export default securityQuestionReducer;
