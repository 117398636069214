import React, { Fragment } from 'react';
import TextNew, { ElementTypes, FontFamily, FontSizes, FontWeight } from 'components/atoms/TextNew';
import currencySymbolProvider from 'common/src/app/util/currencySymbolProvider';
import calculateDiscountedPackagePrice from 'common/src/app/util/calculateDiscountedPackagePrice';
import LocaleMessage from 'components/atoms/LocaleMessage';
import './selected-package-cost.scss';

export type SelectedPackageCostProps = {
  voucherGift?: boolean;
  selectedPackage: {
    name: string;
    discount?: {
      amount: number;
      isRelative: boolean;
    };
    price: {
      currencyCode: string;
      amount: number;
    };
  };
};

const SelectedPackageCost = ({
  selectedPackage,
  voucherGift,
}: SelectedPackageCostProps): JSX.Element => {
  const { name, discount, price } = selectedPackage || {};

  const [discountPrice, discountAmount] = discount
    ? calculateDiscountedPackagePrice(discount, price)
    : [];

  const rows = [
    {
      label: <LocaleMessage id="selectedPackageCost.packageType" params={{ PACKAGE_TYPE: name }} />,
      value: selectedPackage?.price?.amount,
      visible: true,
    },
    {
      label: <LocaleMessage id="selectedPackageCost.prices.discount" />,
      valueSeparator: <LocaleMessage id="selectedPackageCost.prices.separator" />,
      value: discountAmount,
      visible: !voucherGift && discount,
    },
    {
      label: <LocaleMessage id="selectedPackageCost.prices.total" />,
      value: discountPrice || selectedPackage?.price?.amount,
      visible: !voucherGift,
      fontWeight: FontWeight.BOLD,
    },
  ];
  return (
    <div className="molecule-selected-package-cost">
      <dl>
        <TextNew
          cid="order-summary-intro"
          family={FontFamily.SERIF}
          size={FontSizes.SM}
          localeId="selectedPackageCost.subTitle"
          localeParams={{ PACKAGE_NAME: name }}
        />
        {rows.map(
          ({ label, value, visible, fontWeight }, index) =>
            visible && (
              <Fragment key={index}>
                <TextNew
                  weight={fontWeight}
                  family={FontFamily.SANS}
                  size={FontSizes.MD}
                  element={ElementTypes.DT}
                >
                  {label}
                </TextNew>
                <TextNew family={FontFamily.SANS} size={FontSizes.MD} element={ElementTypes.DD}>
                  {currencySymbolProvider(price?.currencyCode)}
                  {value}
                </TextNew>
              </Fragment>
            ),
        )}
      </dl>
    </div>
  );
};

SelectedPackageCost.defaultProps = {
  voucherGift: false,
};

export default SelectedPackageCost;
