import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import FormGroup from 'components/molecules/FormGroup';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import { passwordFields, emailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import TextNew from 'components/atoms/TextNew';
import Input from 'components/atoms/Input';
import PasswordInput from 'components/atoms/PasswordInput';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import errorBoundary from 'components/hoc/ErrorBoundary';
import { compose } from 'redux';
import './change-email.scss';

const ChangeEmail = (
  { submitting = false, handleSubmit, submitSucceeded, fromUrl },
  { getMessage },
) => (
  <form
    className={functionalComponentClassName(ComponentType.TEMPLATE, 'ChangeEmail')}
    onSubmit={handleSubmit}
  >
    <TextNew.Loud localeId="changeEmail.title" />

    <FormGroup type="stacked">
      <Row column>
        <PasswordInput
          name={passwordFields.PASSWORD}
          placeholder={getMessage('changeEmail.originalPassword')}
          showVisibilityToggle
        />
        <FormErrorMessages fields={[passwordFields.PASSWORD]} />
        <EnhancedField
          name={emailFields.NEW_EMAIL_ADDRESS}
          placeholder={getMessage('changeEmail.newEmail')}
          component={Input}
          type="email"
          autocomplete="off"
          suppress
        />
        <FormErrorMessages fields={[emailFields.NEW_EMAIL_ADDRESS]} />
      </Row>
    </FormGroup>
    {submitSucceeded ? (
      <>
        <TextNew.Formal localeId="changeEmail.succeeded" />
        <TextNew.Formal>
          <Link href={fromUrl}>
            <LocaleMessage id="changeEmail.goBack" />
          </Link>
        </TextNew.Formal>
      </>
    ) : (
      <>
        <Row justifycenter>
          <Button type="submit" disabled={submitting}>
            <LocaleMessage id="general.cta.save" />
          </Button>
        </Row>
        <FormErrorMessages showGeneral />
      </>
    )}
  </form>
);

ChangeEmail.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

ChangeEmail.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  fromUrl: PropTypes.string,
};

export default compose(errorBoundary({ wholePageError: true }))(ChangeEmail);
