import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import IconName from 'common/src/app/data/enum/IconName';

import Icon from '../Icon';
import Button from '../Button';
import TextNew, { Colors, ElementTypes } from '../TextNew';
import './magic-mover-award-modal.scss';

const MagicMoverAwardModal = ({ closeModal, onClose }, context, className, dataTestId) => {
  const close = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <section className={className} data-testid={dataTestId}>
      <TextNew.Serif.MD
        cid="header"
        element={ElementTypes.H1}
        color={Colors.BRAND}
        localeId="magicMoverAwardModal.header"
        localeParams={{
          SCRIPT: <TextNew.Script.SM color={Colors.BRAND} localeId="magicMoverAwardModal.script" />,
        }}
      />
      <Icon
        dataTestid="magic-mover-award"
        name={IconName.MAGIC_MOVER_AWARD}
        width={124}
        height={124}
      />
      <TextNew.PrimaryElegant
        cid="sub-text"
        localeId="magicMoverAwardModal.subText.magicMoverAward"
      />

      <Button onClick={close}>
        <TextNew.Sans.SM localeId="magicMoverAwardModal.cta" color={Colors.PRIMARY_LIGHT} />
      </Button>
    </section>
  );
};

MagicMoverAwardModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

MagicMoverAwardModal.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'MagicMoverAwardModal',
)(MagicMoverAwardModal);
