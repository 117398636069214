import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { regionCode } from 'common/src/app/data/enum/Regions';
import { ukPostCodeFormatter } from 'common/src/app/util/form/ukPostCodeFormatter';
import LocaleMessage from '../../atoms/LocaleMessage';
import InformationToggle from '../InformationToggle';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import Input, { descriptorTypes } from '../../atoms/Input';
import Row from '../../atoms/Row';

const SecurityAddressOrPostcode = (
  { region, forwardRef },
  { getMessage },
  className,
  dataTestId,
) => {
  const postalCodeInput = (
    <Row column>
      <EnhancedField
        descriptorType={descriptorTypes.STATIC}
        label={<LocaleMessage id="securityAddressOrPostcode.groupLabels.postCode" />}
        name={addressFields.ZIP_OR_POSTAL}
        placeholder={getMessage('securityAddressOrPostcode.placeholders.postCode')}
        component={Input}
        inputRef={forwardRef?.zipRef}
        dataTestid="postcode-input"
        type="text"
        format={ukPostCodeFormatter}
        suppress
      />
      <InformationToggle
        id="postcode-input-info"
        information={<LocaleMessage id="securityAddressOrPostcode.information" />}
      />
      <FormErrorMessages fields={[addressFields.ZIP_OR_POSTAL]} />
    </Row>
  );
  const addressLine1Input = (
    <Row column>
      <EnhancedField
        descriptorType={descriptorTypes.STATIC}
        label={<LocaleMessage id="securityAddressOrPostcode.groupLabels.addressLine1" />}
        name={addressFields.ADDRESS_LINE_1}
        placeholder={getMessage('securityAddressOrPostcode.placeholders.addressLine1')}
        component={Input}
        inputRef={forwardRef?.addressRef}
        dataTestid="address-line1-input"
        type="text"
        suppress
      />
      <InformationToggle
        id="address-input-info"
        information={<LocaleMessage id="securityAddressOrPostcode.information" />}
      />
      <FormErrorMessages fields={[addressFields.ADDRESS_LINE_1]} />
    </Row>
  );

  return (
    <Row justifystart column className={className} data-testid={dataTestId}>
      {region === regionCode.UK ? postalCodeInput : addressLine1Input}
    </Row>
  );
};

SecurityAddressOrPostcode.propTypes = {
  region: PropTypes.number.isRequired,
  forwardRef: PropTypes.object,
};

SecurityAddressOrPostcode.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.MOLECULE,
    'SecurityAddressOrPostcode',
  )(SecurityAddressOrPostcode),
);
