/* global 'molecule','PasswordRequirement' */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import {
  isLessThanOrEqualToMaxLength,
  isGreaterThanOrEqualToMinLength,
  containsDigit,
  containsLetter,
} from 'common/src/app/validation/passwordValidation';
import passwordValidationConfigShape from 'common/src/app/validation/passwordValidationConfigShape';
import { DEFAULT_MAX_LENGTH } from 'common/src/app/validation/passwordValidationConstants';

import Row from '../../atoms/Row';
import TextNew from '../../atoms/TextNew';
import Icon from '../../atoms/Icon';
import LocaleMessage from '../../atoms/LocaleMessage';

import './password-requirement.scss';

const PasswordRequirement = (
  { password = '', passwordValidationConfig: { requiredLength, maxLength = DEFAULT_MAX_LENGTH } },
  context,
  className,
) => {
  const requirements = [
    {
      title: <LocaleMessage key="letter" id="passwordRequirement.validator.letter" />,
      isValid: containsLetter(password),
    },
    {
      title: <LocaleMessage key="digit" id="passwordRequirement.validator.digit" />,
      isValid: containsDigit(password),
    },
    {
      title: (
        <LocaleMessage
          key="min-max"
          id="passwordRequirement.validator.minMax"
          params={{
            MIN_LENGTH: requiredLength,
            MAX_LENGTH: maxLength,
          }}
        />
      ),
      isValid:
        isGreaterThanOrEqualToMinLength(password, requiredLength) &&
        isLessThanOrEqualToMaxLength(password, maxLength),
    },
  ];

  const messages = requirements.reduce(
    (accumulator, { isValid, title }, index) => [
      ...accumulator,
      <Row key={index} className={isValid ? 'is-valid' : 'is-false'}>
        {isValid ? (
          <Icon name={IconName.CHECKMARK} width={15} height={15} />
        ) : (
          <Icon name={IconName.CROSS} width={15} height={15} />
        )}

        <TextNew.FormalOriginal
          localeId="passwordRequirement.default"
          localeParams={{ VALIDATOR_MESSAGE: title }}
        />
      </Row>,
    ],
    [],
  );

  return (
    <div className={className}>
      <TextNew.PrimaryOriginal localeId="passwordRequirement.title" />
      {messages}
    </div>
  );
};

PasswordRequirement.propTypes = {
  password: PropTypes.string,
  passwordValidationConfig: passwordValidationConfigShape,
};

export default withFunctionalClassName('molecule','PasswordRequirement')(PasswordRequirement);
