/* global WP_DEFINE_IS_NODE */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'common/src/app/util/deviceUtil';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import TextNew from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Row from 'components/atoms/Row';
import { WEB_VIEW_FREE_2_GO_REDIRECT_URI } from 'common/src/server/util/AuthenticationHelper/constants';

const InnerInfo = ({ homepage, referredFrom }) => {
  const freeToGo = referredFrom === WEB_VIEW_FREE_2_GO_REDIRECT_URI;
  const appContent = freeToGo ? 'free2Go' : 'singleApp';

  return (
    <>
      <TextNew.PrimaryElegant localeId={`legacyAppInfo.${appContent}.notAvailable`} />
      <TextNew.PrimaryElegant localeId={`legacyAppInfo.${appContent}.workingHard`} />

      {!freeToGo && (
        <Row justifycenter>
          <Button
            href={
              !WP_DEFINE_IS_NODE && isIOS
                ? PagesExternal.LEGACY_GROUP_IOS
                : PagesExternal.LEGACY_GROUP_ANDROID
            }
            isGhostBrand={homepage}
          >
            <LocaleMessage id="legacyAppInfo.rightApp" />
          </Button>
        </Row>
      )}
    </>
  );
};

InnerInfo.propTypes = {
  homepage: PropTypes.bool,
  referredFrom: PropTypes.string.isRequired,
};

const LegacyAppInfo = ({ homepage, referredFrom }) =>
  homepage ? (
    <div className="template-legacy-info">
      <TextNew.Error cid="template-legacy-info">
        <InnerInfo homepage referredFrom={referredFrom} />
      </TextNew.Error>
    </div>
  ) : (
    <InnerInfo referredFrom={referredFrom} />
  );

LegacyAppInfo.propTypes = {
  homepage: PropTypes.bool,
  referredFrom: PropTypes.string.isRequired,
};

export default memo(LegacyAppInfo);
