import { NUMERIC_OR_DECIMAL_POINT } from 'common/src/app/data/regexPatterns';

export const staticDecimalProps = {
  type: 'text',
  inputMode: 'decimal',
};

type DecimalFieldFuncProps = typeof staticDecimalProps & {
  onKeyPress: (event: KeyboardEvent) => void;
};

const decimalFieldProps = (fieldType: string): DecimalFieldFuncProps | Record<string, never> => {
  const isDecimalInput = fieldType === 'decimal';

  const handleKeyPress = (event: KeyboardEvent) => {
    // Only allows keys:
    // A numeric value or a decimal point to be entered
    // ** any other entry will not be allowed
    // This is vaidated onkey press - so will validate each keypress at a time
    if (!NUMERIC_OR_DECIMAL_POINT.test(event.key)) {
      event.preventDefault();
    }
  };

  return isDecimalInput
    ? {
        ...staticDecimalProps,
        onKeyPress: handleKeyPress,
      }
    : {};
};

export default decimalFieldProps;
