import { connect } from 'react-redux';
import EmailVerificationResult from './EmailVerificationResult';

type ReduxState = {
  config: {
    environmentConfig: {
      web: {
        member: {
          host: string;
        };
      };
    };
  };
  emailConfirmation?: {
    error: string;
  };
};

const mapStateToProps = (state: ReduxState) => ({
  memberHost: state.config.environmentConfig.web.member.host,
  validationError: state.emailConfirmation?.error,
});

export default connect<unknown, unknown, unknown, ReduxState>(mapStateToProps)(
  EmailVerificationResult,
);
