import moment from 'moment';
import createAction from 'redux-actions/lib/createAction';
import authenticate from '../../util/auth/authenticate';
import { FOOD_PLANNER, PLANNED_DAY } from '../../data/entityTypes';
import { userIdSelector } from '../../selectors/userAccountSelectors';
import {
  plannedDayByDateSelector,
  FORMAT_DATE,
  debug,
  correctId,
} from '../../util/plannerActionUtils';
import { apiPost, apiPut, apiDelete, apiGet } from './apiActions/apiRequest';
import { mergeFood } from '../../util/mergeItemsInEntity';
import { GATEWAY_FOOD_AUTH } from '../../data/Injectables';
import { foodPlannerResultsCollectionId } from '../../data/collectionIds';
import { setEntity, updateEntities } from '../entities/entityActions';
import { Filters } from '../../data/enum/PlannerSearch';
import { getPlannerDayFoodStats, getPlannerDayFood } from './plannerFoodSummaryActions';
import batchLoadPlannerDays from '../../util/batchLoadPlannerDays';
import { collectionRemoveRefs, collectionUnshift } from '../entities/collectionActions';

export const POST_FOOD = 'plannerFoodActions/POST_FOOD';
export const addFood =
  (portionId, mealCategoryId, portion, customFoodId) => (dispatch, getState) => {
    const state = getState();
    const planDate = state.planner.activePlannedDay || moment().format(FORMAT_DATE);
    const createPlannedDayByDateSelector = plannedDayByDateSelector(planDate);
    const userId = userIdSelector(state);
    const plannedDay = createPlannedDayByDateSelector(state);
    const params = {
      plannedDayId: plannedDay?.id || 0,
      portionId,
      mealCategoryId,
      planDate,
      multiplePortionOrFractionOfSynValue: portion,
      customFoodId,
    };

    return dispatch(
      apiPost(POST_FOOD, GATEWAY_FOOD_AUTH, `/members/${userId}/plannedfoods`, params),
    )
      .then(([result]) => {
        const promises = [dispatch(getPlannerDayFoodStats(planDate))];
        if (state.entities.plannedDay?.[result.plannedDayId]) {
          const date = moment(result.planDateUtc).format('YYYY-MM-DD');
          promises.push(dispatch(setEntity(PLANNED_DAY, date, result, mergeFood)));
        } else {
          promises.push(dispatch(getPlannerDayFood(planDate)));
        }

        // add food in recent food results if it is not there yet
        const foodId = correctId('foods', portionId);
        const collectionId = foodPlannerResultsCollectionId({
          filters: Filters.RECENT,
          limit: 30,
        });
        const isInCollection = (state.collections?.[collectionId].refs || { refs: [] }).some(
          item => item.id === foodId,
        );
        if (!customFoodId && !isInCollection) {
          promises.push(
            dispatch(collectionUnshift(collectionId, [{ id: foodId, type: FOOD_PLANNER }])),
          );
        }

        return promises;
      })
      .catch(err => debug(err));
  };

export const UPDATE_FOOD = 'plannerFoodActions/UPDATE_FOOD';
export const updateFood = (id, mealCategoryId, portion) => (dispatch, getState) => {
  const state = getState();
  const planDate = state.planner?.activePlannedDay || moment().format(FORMAT_DATE);
  const userId = userIdSelector(state);
  return dispatch(
    apiPut(UPDATE_FOOD, GATEWAY_FOOD_AUTH, `/members/${userId}/plannedfoods`, {
      id,
      mealCategoryId,
      multiplePortionOrFractionOfSynValue: portion,
    }),
  )
    .then(() => dispatch(getPlannerDayFood(planDate)))
    .catch(err => debug(err));
};

export const DELETE_FOOD = 'plannerFoodActions/DELETE_FOOD';
export const deleteFood = (activeDate, id, plannedDayId) => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  return dispatch(
    apiDelete(
      DELETE_FOOD,
      GATEWAY_FOOD_AUTH,
      `/members/${userId}/plannedfoods?plannedDayId=${plannedDayId}`,
      {
        ids: [id],
      },
    ),
  )
    .then(() => dispatch(setEntity(PLANNED_DAY, activeDate, { id, delete: true }, mergeFood)))
    .catch(err => debug(err));
};

// Delete multiple food items
export const DELETE_FOOD_MULTIPLE = 'plannerFoodActions/DELETE_FOOD_MULTIPLE';
export const deleteFoodMultiple = (ids, plannedDayId) => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const activeDate = state.planner?.activePlannedDay;

  return dispatch(
    apiDelete(DELETE_FOOD_MULTIPLE, GATEWAY_FOOD_AUTH, `/members/${userId}/plannedfoods`, {
      ids,
      plannedDayId,
    }),
  )
    .then(() =>
      Promise.all([
        batchLoadPlannerDays(activeDate, dispatch),
        dispatch(updateEntities(PLANNED_DAY)),
      ]),
    )
    .catch(err => debug(err));
};

export const GET_BOOKMARK_FOOD = 'plannerFoodActions/GET_BOOKMARK_FOOD';
export const getBookmarkFood = item => async (dispatch, getState) => {
  await authenticate();
  const state = getState();
  const userId = userIdSelector(state);
  return dispatch(
    apiGet(GET_BOOKMARK_FOOD, GATEWAY_FOOD_AUTH, `/members/${userId}/favourites`, {
      portionId: item.portionId,
    }),
  )
    .then(result => {
      dispatch(
        setEntity(
          FOOD_PLANNER,
          correctId('foods', item.portionId),
          { ...item, isBookmarked: !!result, bookmarkedId: result?.id },
          true,
        ),
      );
    })
    .catch(err => debug(err));
};

export const ADD_BOOKMARK_FOOD = 'plannerFoodActions/ADD_BOOKMARK_FOOD';
export const addBookmarkFood = portionId => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  return dispatch(
    apiPost(ADD_BOOKMARK_FOOD, GATEWAY_FOOD_AUTH, `/members/${userId}/favourites`, {
      portionId,
    }),
  ).then(result => {
    const promises = [];
    const id = correctId('foods', portionId);
    const food = state.entities.foodPlanner?.[id];

    if (food) {
      promises.push(
        dispatch(
          setEntity(FOOD_PLANNER, id, { bookmarkedId: result.id, isBookmarked: true }, true),
        ),
      );
    }

    // add food in bookmark food results
    promises.push(
      dispatch(
        collectionUnshift(
          foodPlannerResultsCollectionId({
            filters: Filters.BOOKMARKED,
            limit: 30,
          }),
          [{ id, type: FOOD_PLANNER }],
        ),
      ),
    );

    return Promise.all(promises);
  });
};

export const REMOVE_BOOKMARK_FOOD = 'plannerFoodActions/REMOVE_BOOKMARK_FOOD';
export const removeBookmarkFood = (id, portionId) => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  return dispatch(
    apiDelete(REMOVE_BOOKMARK_FOOD, GATEWAY_FOOD_AUTH, `/members/${userId}/favourites/${id}`),
  ).then(() => {
    const promises = [];
    const foodId = correctId('foods', portionId);
    const food = state.entities.foodPlanner?.[foodId];

    if (food) {
      promises.push(dispatch(setEntity(FOOD_PLANNER, foodId, { isBookmarked: false }, true)));
    }

    // remove food from bookmark food results
    promises.push(
      dispatch(
        collectionRemoveRefs(
          foodPlannerResultsCollectionId({
            filters: Filters.BOOKMARKED,
            limit: 30,
          }),
          [{ id: foodId, type: FOOD_PLANNER }],
        ),
      ),
    );

    return Promise.all(promises);
  });
};

export const OPEN_MODAL_FOOD_TRACKING = 'plannerActions/OPEN_MODAL_FOOD_TRACKING';
export const openModalFoodTracking = createAction(OPEN_MODAL_FOOD_TRACKING);

export const UPLOAD_PRODUCT_PHOTO = 'plannerFoodActions/UPLOAD_PRODUCT_PHOTOS';
export const trackProductFoodUpload = createAction(
  UPLOAD_PRODUCT_PHOTO,
  totalPhotos => totalPhotos,
);
