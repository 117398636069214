import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './rich-text-block.scss';

const RichTextBlock = ({ text, title, itemProp }, context, className, dataTestid) => (
  <div className={className} data-testid={dataTestid}>
    {title && <h3>{title}</h3>}
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: text }}
      itemProp={itemProp}
    />
  </div>
);

RichTextBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  palette: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  itemProp: PropTypes.string,
};

export default memo(
  withFunctionalClassName(ComponentType.ATOM, 'RichTextBlock', [
    ({ palette, _type }) =>
      palette || _type === 'RichTextWithBackgroundBlock' ? 'background' : '',
  ])(RichTextBlock),
);
