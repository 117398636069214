import React from 'react';
import PropTypes from 'prop-types';

import './ripple.scss';

class Ripple extends React.Component {
  constructor() {
    super();
    this.state = {
      animate: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { cursorPos } = this.props;

    // Prevent Component duplicates do ripple effect at the same time
    if (cursorPos.time !== prevProps.cursorPos.time) {
      // If Has Animated, set state to "false" First
      if (this.state.animate) {
        this.setState({ animate: false }, () => {
          this.rippling(cursorPos);
        });
      } else {
        this.rippling(cursorPos);
      }
    }
  }

  rippling(cursorPos) {
    // Get the element
    const $ripple = this.refs.ripple; // eslint-disable-line react/no-string-refs
    const $button = $ripple.parentElement;

    // const buttonStyle = window.getComputedStyle($button);
    const buttonPos = $button.getBoundingClientRect();

    const buttonWidth = $button.offsetWidth;
    const buttonHeight = $button.offsetHeight;

    // Make a Square Ripple
    const rippleSize = Math.max(buttonHeight, buttonWidth);

    // Make Ripple Position to be center
    const centerize = rippleSize / 2;

    this.setState({
      animate: true,
      width: rippleSize,
      height: rippleSize,
      top: cursorPos.top - buttonPos.top - centerize,
      left: cursorPos.left - buttonPos.left - centerize,
    });
  }

  render() {
    return (
      <div
        className={`ripple ${this.state.animate && 'is-rippling'}`}
        ref="ripple"
        style={{
          // eslint-disable-line react/no-string-refs
          top: `${this.state.top}px`,
          left: `${this.state.left}px`,
          width: `${this.state.width}px`,
          height: `${this.state.height}px`,
        }}
      />
    );
  }
}

Ripple.propTypes = {
  cursorPos: PropTypes.object,
};

export default Ripple;
