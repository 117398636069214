import PropTypes from 'prop-types';

const passwordValidationConfigShape = PropTypes.shape({
  maxLength: PropTypes.number,
  requireDigit: PropTypes.bool.isRequired,
  requiredLength: PropTypes.number.isRequired,
  requiredUniqueCharacters: PropTypes.number.isRequired,
  requireLetter: PropTypes.bool.isRequired,
  requireLowerCase: PropTypes.bool.isRequired,
  requireUppercase: PropTypes.bool.isRequired,
  requireNonAlphanumeric: PropTypes.bool.isRequired,
});

export default passwordValidationConfigShape;
