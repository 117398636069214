import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import StatusCode from 'common/src/app/data/enum/StatusCode';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, {
  Colors,
  ElementTypes,
  FontFamily,
  FontSizes,
  TextTypes,
} from 'components/atoms/TextNew';
import IconInput from '../IconInput';
import { descriptorTypes } from '../../atoms/Input';
import Button from '../../atoms/Button';

import Product from './types/Product';
import VoucherType from './types/Voucher';

import voucherAppliesToProducts from './utils/voucherAppliesToProducts';
import generateVoucherError from './utils/generateVoucherError';

import './voucher.scss';

type WrappedInputProps = {
  resetVoucher: () => void;
  currentVoucher: string;
  hasError: boolean;
};

const WrappedInput = ({
  resetVoucher,
  currentVoucher,
  hasError,
  ...props
}: WrappedInputProps): JSX.Element => {
  let transformedProps = props;

  if (hasError) {
    transformedProps = {
      ...transformedProps,
      disabled: false,
      isIconButton: true,
      onClick: resetVoucher,
      icon: IconName.CROSS,
      meta: {
        error: true,
      },
    };
  } else if (currentVoucher) {
    transformedProps = {
      ...transformedProps,
      icon: IconName.CHECKMARK,
      iconSize: { height: 14 },
      showIconLockWhenDisabled: false,
    };
  }

  return <IconInput {...transformedProps} />;
};

/**
 * Voucher component for redeeming promotional codes.
 */

type VoucherProps = {
  currentVoucher: string;
  editValue: string;
  products: Product | Product[];
  applyVoucher: (voucher: string) => Promise<{ entity: VoucherType }>;
  updateVoucherFieldValue: (voucher: string) => void;
  softReset: () => void;
  hardReset: () => void;
  isLoading: boolean;
};

const Voucher = (
  {
    currentVoucher,
    editValue,
    products,
    applyVoucher,
    updateVoucherFieldValue,
    softReset,
    hardReset,
    isLoading,
  }: VoucherProps,
  { getMessage }: { getMessage: (id: string, params?: Record<string, string>) => string },
  className: string,
): JSX.Element => {
  const [errorLocale, setErrorLocale] = useState<string>();

  useEffect(() => {
    if (editValue && currentVoucher !== editValue) {
      validateVoucher(editValue);
    }
  }, [products]);

  const validateVoucher = (value: string) => {
    applyVoucher(value)
      .then(({ entity }) => {
        if (errorLocale) setErrorLocale(undefined);

        if (voucherAppliesToProducts(entity, products) || entity?.gift?.duration) {
          updateVoucherFieldValue(value);
          return;
        }

        setErrorLocale(generateVoucherError(products));
      })
      .catch(err => {
        if (err?.response?.status === StatusCode.STATUS_404) {
          setErrorLocale(`voucher.errors.${shopFields.VOUCHER_CODE}.invalid`);
        }
      });
  };

  return (
    <section className={className}>
      <TextNew
        family={FontFamily.SANS}
        size={FontSizes.SM}
        localeId="voucher.formGroup.heading"
        cid="voucher"
      />
      <TextNew
        family={FontFamily.SANS}
        size={FontSizes.SM}
        color={Colors.DARK_TONE}
        localeId="voucher.formGroup.check"
        cid="voucher"
      />
      <EnhancedField
        name={shopFields.VOUCHER_CODE_EDIT}
        placeholder={getMessage('voucher.form.input.voucherCode.placeholder')}
        component={WrappedInput}
        disabled={!!currentVoucher}
        currentVoucher={currentVoucher}
        hasError={!!errorLocale}
        normalize={(value: string) => value.toUpperCase()}
        descriptorType={descriptorTypes.ANIMATED}
        type="text"
        resetVoucher={hardReset}
        onKeyDown={softReset}
      />
      {currentVoucher ? (
        <Button isGhostBrand type="button" key="reset" onClick={hardReset} cid="voucher-submit">
          <TextNew
            family={FontFamily.SANS}
            size={FontSizes.SM}
            element={ElementTypes.SPAN}
            color={Colors.BRAND}
            localeId="voucher.form.input.submit.label.reset"
          />
        </Button>
      ) : (
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => validateVoucher(editValue)}
          key="submit"
          isGhostBrand
          cid="voucher-submit"
        >
          <TextNew
            family={FontFamily.SANS}
            size={FontSizes.SM}
            element={ElementTypes.SPAN}
            color={Colors.BRAND}
            localeId="voucher.form.input.submit.label.default"
          />
        </Button>
      )}

      {errorLocale && (
        <TextNew type={TextTypes.ERROR} color={Colors.BRAND} localeId={errorLocale} />
      )}
    </section>
  );
};

Voucher.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'Voucher', ['submitSucceeded'])(
  Voucher,
);
