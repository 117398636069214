/* global 'molecule','PublicNavigationLinks' */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from '../../atoms/Icon';
import TextNew from '../../atoms/TextNew';
import HeadingButton from '../../atoms/HeadingButton';
import './public-navigation-links.scss';

const PublicNavigationLinks = ({ publicHost, accountHost, showMenu }, {}, className) => {
  const active = showMenu ? 'active' : '';
  return (
    <div className={className}>
      <HeadingButton />
      <nav className={active}>
        <Link href={publicHost + Pages.HOME}>
          <Icon name={IconName.MENU_HOME} width={22} height={22} />
          <TextNew.Formal localeId="publicNavigationLinks.home" />
        </Link>
        <Link href={publicHost + Pages.UK_PUBLIC_HOW_IT_WORKS}>
          <Icon name={IconName.MENU_FEATURES} width={22} height={22} />
          <TextNew.Formal localeId="publicNavigationLinks.howItWorks" />
        </Link>
        <Link href={publicHost + Pages.UK_PUBLIC_RECIPE_SEARCH}>
          <Icon name={IconName.MENU_RECIPES} width={22} height={22} />
          <TextNew.Formal localeId="publicNavigationLinks.recipes" />
        </Link>
        <Link href={publicHost + Pages.UK_PUBLIC_SUCCESS_STORIES}>
          <Icon name={IconName.MENU_SUCCESS} width={22} height={22} />
          <TextNew.Formal localeId="publicNavigationLinks.successStories" />
        </Link>
        <Link href={accountHost + Pages.LOGIN} className="sign-in">
          <Icon name={IconName.SIGNIN} />
          <TextNew.Formal localeId="publicNavigationLinks.signIn" />
        </Link>
      </nav>
    </div>
  );
};

PublicNavigationLinks.propTypes = {
  /**
   * The host for the account pages
   */
  accountHost: PropTypes.node,

  /**
   * The host for the public pages
   */
  publicHost: PropTypes.node,
  showMenu: PropTypes.bool,
};

export default withFunctionalClassName('molecule','PublicNavigationLinks')(PublicNavigationLinks);
