import { connect } from 'react-redux';
import { INIT_SELF_BLOCKING, withInitAction } from '@mediamonks/react-redux-component-init';
import compose from 'redux/lib/compose';
import { getAuthenticatorDetails } from 'common/src/app/actions/resources/multiFactorAuthenticationActions';
import MultiFactorAuthenticationRegister from './MultiFactorAuthenticationRegister';

const mapStateToProps = (state, { location }) => {
  const manualCode = state?.multiFactorAuthentication?.key;
  const email = state?.multiFactorAuthentication?.email;
  const environment = state?.multiFactorAuthentication?.environment;
  const authenticationName = encodeURIComponent(`Slimming World ${environment}`);
  const qrCodeUrl =
    manualCode &&
    email &&
    `otpauth://totp/${authenticationName}:${email}?secret=${manualCode}&issuer=${authenticationName}&digits=6`;

  return {
    // Add dashes after every 4th character for readability
    manualCode: manualCode?.match(new RegExp('.{4}', 'g')).join(' '),
    qrCodeUrl,
    location,
  };
};

const addInitAction = withInitAction(
  {
    clientOnly: ({}, dispatch) => dispatch(getAuthenticatorDetails()),
  },
  { initSelf: INIT_SELF_BLOCKING },
);

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(MultiFactorAuthenticationRegister);
