/* global 'template','Logout' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import TextNew from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Pages from 'common/src/app/data/enum/Pages';
import WebHost from 'common/src/app/data/enum/WebHost';
import prependWebHost from 'common/src/app/util/prependWebHost';
import IframeLogout from './components/molecules/IframeLogout';
import './logout.scss';

const Logout = (
  { environmentConfig, submitting = false, handleSubmit, showLogoutPrompt, logoutId },
  context,
  className,
) => {
  const path = prependWebHost(environmentConfig, Pages.HOME, WebHost.MEMBER);
  return (
    <div className={className}>
      {showLogoutPrompt ? (
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextNew.Loud localeId="logout.title" />
          <TextNew.PrimaryElegant localeId="logout.description" />
          <Row>
            <Button type="submit" isSecondary disabled={submitting}>
              <LocaleMessage id="logout.yes" />
            </Button>
            <Button href={path}>
              <LocaleMessage id="logout.no" />
            </Button>
          </Row>
          <FormErrorMessages showGeneral />
        </form>
      ) : (
        <IframeLogout logoutId={logoutId} />
      )}
    </div>
  );
};

Logout.defaultProps = {
  currentUser: '',
  showLogoutPrompt: false,
  logoutId: null,
};

Logout.propTypes = {
  environmentConfig: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showLogoutPrompt: PropTypes.bool,
  logoutId: PropTypes.string,
};

export default withFunctionalClassName('template','Logout')(Logout);
