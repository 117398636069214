export default {
  YOUTUBE: 'YoutubeVideo',
  BRIGHTCOVE: 'BrightcoveVideo',
  LEGACY: 'Video',
};

export const Controls = {
  HIDE: 0,
  SHOW: 2,
};
