import {
  RECIPE,
  FEATURE,
  SUCCESS_STORY,
  COMMENT,
  POST,
  PLANNER_ACTIVITY,
  FAVOURITE,
  SEVEN_DAY_MENU,
  FOOD_LIST,
  ACTIVITY_VIDEO,
} from '../entityTypes';

const RelatedEntityKind = {
  RECIPE: 0,
  FEATURE: 1,
  SUCCESS_STORY: 2,
  COMMENT: 3,
  POST: 4,
  PLANNER_ACTIVITY: 5,
  FAVOURITE: 6,
  SEVEN_DAY_MENU: 7,
  FOOD_LIST: 8,
  ACTIVITY_VIDEO: 9,
};

export const getEntityTypeFromRelatedEntityKind = entityKind =>
  [
    RECIPE,
    FEATURE,
    SUCCESS_STORY,
    COMMENT,
    POST,
    PLANNER_ACTIVITY,
    FAVOURITE,
    SEVEN_DAY_MENU,
    FOOD_LIST,
    ACTIVITY_VIDEO,
  ][entityKind];

export default RelatedEntityKind;
