import { compose } from 'redux';
import { closeModal } from 'common/src/app/actions/components/modalActions';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import FormNames from 'common/src/app/data/enum/FormNames';

import { membershipCardFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';

import ExistingLegacyAccountPromptModal from './ExistingLegacyAccountPromptModal';

const existingLegacyAccountValidation = {
  [membershipCardFields.CONFIRM_START_AFRESH]: {
    validators: [
      {
        rule: value => value === true,
        message: { locale: 'existingLegacyAccountPromptModal.errors.confrmStartAfresh.required' },
      },
    ],
  },
};

const enhanced = enhancedReduxForm(
  {
    form: FormNames.START_AFRESH,
    onSubmit: (formValues, dispatch) => {
      // close the modal if the member has confirmed
      if (formValues && formValues.confrmStartAfresh) {
        dispatch(closeModal());
      }
    },
    destroyOnUnmount: false,
  },
  existingLegacyAccountValidation,
);

export default compose(enhanced)(ExistingLegacyAccountPromptModal);
