import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import BmiError from './BmiError';

const mapStateToProps = state => ({
  publicHost: state.config.environmentConfig.web.public.host,
});

const connected = connect(mapStateToProps, null);

export default compose(connected)(BmiError);
