import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import './static-progress-bar.scss';

/**
 * Simple progress indicator
 * Useful for steps indication or weight progress indication etc.
 */
const StaticProgressBar = (
  { value, maxValue, percentageWeightLoss, children, containerRtl, dataTestId },
  context,
  className,
) => (
  <div className={className} data-testid={dataTestId}>
    <div className="progress-bar">
      <div
        style={{ width: `${getPercentile(value, maxValue, percentageWeightLoss)}%` }}
        className="progress-bar-progress"
      />
    </div>
    {children ? (
      <div
        className={`progress-label${
          containerRtl || getPercentile(value, maxValue, percentageWeightLoss) < 50
            ? ' direction-rtl'
            : ''
        }`}
        style={{
          width: `${
            containerRtl || getPercentile(value, maxValue, percentageWeightLoss) < 50
              ? 100 - getPercentile(value, maxValue, percentageWeightLoss)
              : getPercentile(value, maxValue, percentageWeightLoss)
          }%`,
        }}
      >
        {children}
      </div>
    ) : null}
  </div>
);

const getPercentile = (value, maxValue, percentageWeightLoss) =>
  percentageWeightLoss || Math.min((value / maxValue) * 100, 100);

StaticProgressBar.defaultProps = {
  maxValue: 100,
};

StaticProgressBar.propTypes = {
  /**
   * Maximum value defaults to 100%
   */
  maxValue: PropTypes.number,
  /**
   * Current value
   */
  value: PropTypes.number.isRequired,
  percentageWeightLoss: PropTypes.number,
  /**
   * Position the label container to the right
   * (label will be shown on the right side of progress bar)
   */
  containerRtl: PropTypes.bool,
  /**
   * Child component(s) useful for rendering a custom label
   */
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.ATOM, 'StaticProgressBar')(StaticProgressBar);
