import { createSelector } from 'reselect';

export const getRecaptchaToken = createSelector(
  [state => state?.config?.recaptchaToken],
  recaptchaToken => recaptchaToken,
);

export const getRecaptchaExecuted = createSelector(
  [state => state?.config?.recaptchaExecuted],
  recaptchaExecuted => recaptchaExecuted,
);

export default getRecaptchaToken;
