import React from 'react';
import camelCase from 'lodash/camelCase';
import BodyMagicAwards from './BodyMagicAwards';
import BodyMagicAwardIcon from '../../../../../components/atoms/BodyMagicAwardIcon';

/**
 * Body Magic levels
 * Defines an abbreviation used to communicate the level of each video to Members
 */
export const BodyMagicLevelEnum = {
  GETTING_STARTED: 1,
  BRONZE: 2,
  SILVER: 4,
  GOLD: 8,
};

export const BodyMagicLevels = {
  [BodyMagicLevelEnum.GETTING_STARTED]: {
    title: 'Getting started',
    colourId: BodyMagicAwards.GETTING_STARTED,
  },
  [BodyMagicLevelEnum.BRONZE]: {
    title: 'Bronze',
    colourId: BodyMagicAwards.BRONZE,
  },
  [BodyMagicLevelEnum.SILVER]: {
    title: 'Silver',
    colourId: BodyMagicAwards.SILVER,
  },
  [BodyMagicLevelEnum.GOLD]: {
    title: 'Gold',
    colourId: BodyMagicAwards.GOLD,
  },
};

/**
 * Activity video types
 * Defines categories that will be assigned to each activity video
 */
export const VideoTypes = {
  ACTIVITY: 'activity',
  FEATURED: 'featured',
  COOL_DOWN: 'cool-down',
};

export const ActivityTypeEnum = {
  CARDIO: 1,
  BUILDING_STRENGTH: 2,
  BALANCE_FLEXIBILITY: 4,
  DANCE: 8,
};

export const COOL_DOWN_ACTIVITY = 16;

export const AllActivityTypesEnum = {
  ...ActivityTypeEnum,
  COOL_DOWN: COOL_DOWN_ACTIVITY,
};

export const ActivityVideoTypes = {
  [ActivityTypeEnum.CARDIO]: 'Cardio',
  [ActivityTypeEnum.BUILDING_STRENGTH]: 'Building strength',
  [ActivityTypeEnum.BALANCE_FLEXIBILITY]: 'Balance & flexibility',
  [ActivityTypeEnum.DANCE]: 'Dance',
};

export const AllActivityVideoTypes = {
  ...ActivityVideoTypes,
  [AllActivityTypesEnum.COOL_DOWN]: 'Cool-down',
};

/**
 * Segments within a activity video
 */
export const SegmentDurationEnum = {
  FIVE_MINUTES: 1,
  TEN_MINUTES: 2,
  FIFTEEN_MINUTES: 4,
  THIRTY_MINUTES: 8,
};

export const SegmentDurationTypes = {
  [SegmentDurationEnum.FIVE_MINUTES]: '5',
  [SegmentDurationEnum.TEN_MINUTES]: '10',
  [SegmentDurationEnum.FIFTEEN_MINUTES]: '15',
  [SegmentDurationEnum.THIRTY_MINUTES]: '30',
};

const generateValues = ({ types, name, getMessage, icon, section }) =>
  Object.keys(types).map(key => {
    const flag = name === 'bodyMagicLevelLabel' && BodyMagicLevelEnum[key];
    return {
      name: key,
      text: getMessage(`${section}.filters.${name}.${camelCase(key)}`),
      icon: icon && (
        <BodyMagicAwardIcon
          fill
          width={18}
          height={26}
          colourId={BodyMagicLevels?.[flag]?.colourId}
        />
      ),
    };
  });

export const activityTypesValues = (getMessage, section) =>
  generateValues({ types: ActivityTypeEnum, name: 'activityTypeLabel', getMessage, section });

export const segmentDurationValues = (getMessage, section) =>
  generateValues({
    types: SegmentDurationEnum,
    name: 'segmentDurationLabel',
    getMessage,
    section,
  });

export const bodyMagicLevelValues = (getMessage, section, icon) =>
  generateValues({
    types: BodyMagicLevelEnum,
    name: 'bodyMagicLevelLabel',
    getMessage,
    icon,
    section,
  });

/**
 * Presentational types
 * Defines different options to determine which UI elements/functionality to utilise
 */

export const CardTypes = {
  LIST_ITEM: 'listItem',
  STACKED: 'stacked',
};

export const PresentationTypes = {
  LIST: 'list',
  CAROUSEL: 'carousel',
};

export const FieldNames = {
  SEGMENT_DURATION: 'segmentDuration',
  ACTIVITY: 'activityType',
  BODY_MAGIC: 'bodyMagicLevel',
};
