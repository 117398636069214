export default {
  RECEIVED_OPT_IN_HALL_OF_FAME_NOTIFICATION: 2,
  PLANNER_ONBOARDER_COMPLETED: 4,
  COMMUNITY_ONBOARDER_COMPLETED: 5,
  ESSENTIALS_ONBOARDER_COMPLETED: 6,
  COMMUNITY_GROUPS_WELCOME_SHOWN: 8,
  COMMUNITY_GROUP_SHOW_ENROLEMENT_TEXT: 9,
  HOME_ONBOARDER_COMPLETED: 12,
  HOME_ONBOARDER_DISMISSED: 13,
  CLOSED_FOLLOWING_INTRO: 16,
  CLOSED_PLANNER_VIDEO_SEARCH_INTRO: 17,
  COMPLETED_STS_FOOD_OPTIMISING: 18,
  FORCED_TO_START_STS_FOOD_OPTIMISING: 19,
};
