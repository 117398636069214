import { handleActions } from 'redux-actions';
import { REGISTRATION_FLOW_TYPE } from 'common/src/app/actions/registrationActions';

const initialState = {
  flow: null,
};

export default handleActions(
  {
    [REGISTRATION_FLOW_TYPE]: (state, { payload }) => ({
      ...state,
      flow: payload,
    }),
  },
  initialState,
);
