/* global WP_DEFINE_MARKET */

/**
 * This lives in application folder because it needs an import to a file in the application folder
 * We cannot pass the path dynamically.
 *
 * Require returns a JS object via a webpack loader
 */
export default function () {
  if (WP_DEFINE_MARKET === 'us') {
    return require('../../locale/en-US.i18n')(); // eslint-disable-line global-require
  }
  if (WP_DEFINE_MARKET === 'uk') {
    return require('../../locale/en-GB.i18n')(); // eslint-disable-line global-require
  }

  throw new ReferenceError(`Unknown locale id "${WP_DEFINE_MARKET}"`);
}
