import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withFormik } from 'formik';
import { object, string } from 'yup';
import FormNames from 'common/src/app/data/enum/FormNames';
import MultiFactorAuthenticationType from 'common/src/app/data/enum/MultiFactorAuthenticationType';
import { multiFactorAuthenticationFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { ONLY_SIX_NUMERIC } from 'common/src/app/data/regexPatterns';
import {
  mfaRegisterOrVerify,
  mfaLoginCallBack,
} from 'common/src/app/actions/resources/multiFactorAuthenticationActions';
import LocalStorageKeys from 'common/src/app/data/enum/BrowserStorageKeys';
import MultiFactorAuthenticationForm from './MultiFactorAuthenticationForm';

const mfaRegisterSchema = object().shape({
  [multiFactorAuthenticationFields.CODE]: string()
    .required('errors.requiredField')
    .matches(ONLY_SIX_NUMERIC, 'multiFactorAuthenticationForm.form.error.code.validator'),
});

const enhanced = withFormik({
  displayName: FormNames.MULTI_FACTOR_AUTHENTICATION_FORM,
  mapPropsToValues: () => ({
    [multiFactorAuthenticationFields.CODE]: '',
  }),
  validationSchema: mfaRegisterSchema,
  handleSubmit: async (values, { setSubmitting, setErrors, props }) => {
    try {
      if (props.isRegisterForm) {
        await props.mfaRegisterAndVerifyAction(values, props?.location);
      } else {
        await props.mfaVerifyAction(values, props?.location);
      }
      localStorage.removeItem(LocalStorageKeys.MFA);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setErrors({
        id: 'multiFactorAuthenticationForm.form.error.code.match',
      });
    }
  },
});

const mapDispatchToProps = dispatch => ({
  /**
   * Register -> Verify -> login callBack
   * @param values
   * @param location
   * @returns {Promise<void>}
   */
  mfaRegisterAndVerifyAction: async (values, location) => {
    await dispatch(mfaRegisterOrVerify(values));
    await dispatch(mfaRegisterOrVerify(values, MultiFactorAuthenticationType.VERIFY));
    dispatch(mfaLoginCallBack(location));
  },
  /**
   * Verify -> Login callBack
   * @param values
   * @param location
   * @returns {Promise<void>}
   */
  mfaVerifyAction: async (values, location) => {
    await dispatch(mfaRegisterOrVerify(values, MultiFactorAuthenticationType.VERIFY));
    dispatch(mfaLoginCallBack(location));
  },
});

const connected = connect(null, mapDispatchToProps);

export default compose(connected, enhanced)(MultiFactorAuthenticationForm);
