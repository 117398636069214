import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import './form-group.scss';

export const FormGroupType = {
  INLINE: 'inline',
  STACKED: 'stacked',
};

/**
 * Main form group - serves the purpose of <FieldSet />
 * This should be the common wrapping element to use within any form
 *
 * @param {string} label - the title of the form section
 * @param {string} type - stacked or inline, It will 'stack' or place inline
 *        and add any styling to common form fields
 * @param {node} children - (Input / Select / InputGroup) & any other elements required
 * @param context object - the components context
 * @param className string - the components className
 *
 * TODO add more accepted form elements to the scss
 */

const FormGroup = ({ children, label, id, forwardRef }, context, className) => (
  <fieldset className={className} id={id} ref={forwardRef}>
    {label && <legend className="atom-label">{label}</legend>}
    {children}
  </fieldset>
);

// todo add the missing proptypes later on when we use redux-form
FormGroup.propTypes = {
  /*
   * The form inputs / selects to be added
   */
  children: PropTypes.node,
  /*
   * the form section title (password, username etc erc)
   */
  label: PropTypes.node,
  /*
   * defines layout of inputs + selects,
   * omit if only using one input (but are still grouping error messages, need title etc)
   */
  // eslint-disable-next-line react/no-unused-prop-types
  type: PropTypes.oneOf(Object.values(FormGroupType)),
  id: PropTypes.string,
  forwardRef: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'FormGroup', [
  ({ type }) => type,
  ({ className }) => className,
])(FormGroup);
