import { handleActions } from 'redux-actions';

import { SET_RESULT_CODE } from '../actions/shopCheckoutActions';

export default handleActions(
  {
    [SET_RESULT_CODE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);
