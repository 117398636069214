import React from 'react';
import PropTypes from 'prop-types';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import createId from 'common/src/app/util/createId';
import Icon from '../Icon';
import Button from '../Button';

const ActiveMobileTab = ({ tabs, activeTab, openMenu, reference, topDistance }) => {
  const activeTitle = tabs && Object.keys(tabs).map(tab => createId(tab) === activeTab && tab);
  return (
    <Button style={{ top: `${topDistance}px` }} isTertiary onClick={openMenu} reference={reference}>
      <Icon name={IconName.CHEVRON_LARGE} direction={DirectionType.LEFT} />
      {activeTitle}
    </Button>
  );
};

ActiveMobileTab.propTypes = {
  tabs: PropTypes.shape({
    nestedContent: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  activeTab: PropTypes.string,
  openMenu: PropTypes.func,
  reference: PropTypes.func,
  topDistance: PropTypes.number,
};

export default ActiveMobileTab;
