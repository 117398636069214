/* eslint no-shadow: ["error", { "allow": ["ActivitySources"] }] */

enum ActivitySources {
  StandardActivity,
  ActivityVideo,
  Wearable,
  Steps,
  Custom,
}

export default ActivitySources;
