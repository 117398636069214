/* eslint-disable no-bitwise */
const NONE = 0;
const ONLINE_PROFILE_COMPLETE = 1 << 0;
const ONLINE_SUBSCRIPTION_VALID = 1 << 1;
const ONLINE_ALL_SET = ONLINE_PROFILE_COMPLETE | ONLINE_SUBSCRIPTION_VALID;
const GROUP_PROFILE_COMPLETE = 1 << 16;
const GROUP_MEMBERSHIP_VALID = 1 << 17;
const GROUP_ALL_SET = GROUP_PROFILE_COMPLETE | GROUP_MEMBERSHIP_VALID;

const AccountState = {
  NONE,
  ONLINE_PROFILE_COMPLETE,
  ONLINE_SUBSCRIPTION_VALID,
  ONLINE_ALL_SET,
  GROUP_PROFILE_COMPLETE,
  GROUP_MEMBERSHIP_VALID,
  GROUP_ALL_SET,
};

export default AccountState;

/**
 * GROUP & ONLINE do not map exactly to accounStates recieved from the BE
 * They are used by groupOnlineSelector and accountStateProvider HoC
 */
export const accountStateTypeStrings = {
  NONE: 'public',
  ONLINE_PROFILE_COMPLETE: 'onlineProfileComplete',
  ONLINE_SUBSCRIPTION_VALID: 'onlineSubscriptionValid',
  ONLINE_ALL_SET: 'onlineAllSet',
  GROUP_PROFILE_COMPLETE: 'groupProfileComplete',
  GROUP_MEMBERSHIP_VALID: 'groupMembershipValid',
  GROUP_ALL_SET: 'groupAllSet',
};

export const AccountStateStrings = {
  [AccountState.NONE]: accountStateTypeStrings.NONE,
  [AccountState.ONLINE_PROFILE_COMPLETE]: accountStateTypeStrings.ONLINE_PROFILE_COMPLETE,
  [AccountState.ONLINE_SUBSCRIPTION_VALID]: accountStateTypeStrings.ONLINE_SUBSCRIPTION_VALID,
  [AccountState.ONLINE_ALL_SET]: accountStateTypeStrings.ONLINE_ALL_SET,
  [AccountState.GROUP_PROFILE_COMPLETE]: accountStateTypeStrings.GROUP_PROFILE_COMPLETE,
  [AccountState.GROUP_MEMBERSHIP_VALID]: accountStateTypeStrings.GROUP_MEMBERSHIP_VALID,
  [AccountState.GROUP_ALL_SET]: accountStateTypeStrings.GROUP_ALL_SET,
};
