/**
 * Registration age requirements
 * For group within the 'warning' range (11 and 15) we display an
 * additional message but don't block registration
 */

const AgeRanges = {
  GROUP: {
    REQUIRED: 11,
    FREE_2_GO_MIN: 11,
    FREE_2_GO_MAX: 15,
  },
  ONLINE: {
    REQUIRED: 18,
  },
  GROUP_AND_ONLINE: {
    MAX: 122,
  },
};

export default AgeRanges;

// Your birthday plus this value = earliest journey start date
export const journeyStartAge = 16;
