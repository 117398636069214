import React, { memo } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './loader.scss';

const spinner = Array.from(new Array(9).keys());

const Loader = ({}, {}, className: string) => (
  <div data-testid="loader" className={className}>
    <div className="loader-circle">
      {spinner.map(element => (
        <div className={`loader-circle${element} loader-child`} key={element} />
      ))}
    </div>
  </div>
);

export default memo(withFunctionalClassName(ComponentType.ATOM, 'Loader')(Loader));
