import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import createAction from 'redux-actions/lib/createAction';
import authenticate from 'common/src/app/util/auth/authenticate';
import {
  GATEWAY_ACTIVITY_AUTH,
  GATEWAY_ACTIVITY_V2_AUTH,
  GATEWAY_COMMUNITY_V2_AUTH,
} from '../../data/Injectables';
import { apiGet, apiPost, apiDelete, apiHead } from './apiActions/apiRequest';
import { debug } from '../../util/plannerActionUtils';
import { getPlannerDayActivity } from './plannerActivitySummaryActions';
import {
  PLANNER_ACTIVITY,
  PLANNER_ACTIVITY_TYPE,
  PLANNER_ACTIVITY_GOAL,
} from '../../data/entityTypes';
import ActivityStatus, { IS_BLOCKING_ERROR } from '../../data/enum/ActivityStatus';
import { AwardMap } from '../../data/enum/AwardType';
import {
  activeAwardSelector,
  hasMagicMoverAwardSelector,
  hasSavedCompletedActivitiesSelector,
} from '../../selectors/plannerSelectors';
import { setSummaryLoading } from './plannerFoodSummaryActions';
import { retriggerAnimation } from '../components/lottieActions';
import { userIdSelector } from '../../selectors/userAccountSelectors';
import { removeEntities, setEntity } from '../entities/entityActions';

dayjs.extend(utc);

export const POST_ACTIVITY_TYPE = 'plannerManageActivityActions/POST_ACTIVITY_TYPE';
export const createActivity = values => dispatch =>
  dispatch(
    apiPost(POST_ACTIVITY_TYPE, GATEWAY_ACTIVITY_AUTH, `/activity-types`, {
      description: values.activityDescription,
      categories: values.muscleStrengthening ? 1 : 0,
      title: values.activityName,
    }),
  )
    .then(response => {
      dispatch(setEntity(PLANNER_ACTIVITY_TYPE, response.data.id, response.data));
      return response;
    })
    .catch(err => debug(err));

// Set if the member has any saved completed activities
export const SET_IF_HAS_ANY_SAVED_COMPLETED_ACTIVITIES =
  'plannerManageActivityActions/SET_IF_HAS_ANY_SAVED_COMPLETED_ACTIVITIES';

const setIfTheMemberHasAnySavedCompletedActivities = createAction(
  SET_IF_HAS_ANY_SAVED_COMPLETED_ACTIVITIES,
);

const ACTIVITY_CHECK_EXISTING_COMPLETED_ACTIVITIES =
  'plannerManageActivityActions/ACTIVITY_CHECK_EXISTING_COMPLETED_ACTIVITIES';

export const checkAnyExistingCompletedActivities = () => async (dispatch, getState) => {
  await authenticate();
  const profileId = userIdSelector(getState());

  dispatch(
    apiHead(ACTIVITY_CHECK_EXISTING_COMPLETED_ACTIVITIES, GATEWAY_ACTIVITY_AUTH, '/activities', {
      profileId,
      activityStatus: 'Completed',
    }),
  )
    .catch(error => {
      // If the member does NOT have any existing completed activities:
      // - the api will return a 404 response
      if (error?.response?.status === 404) {
        return false;
      }
      throw error;
    })
    .then(response => {
      // the response will only ever be a boolean if a 404 is returned.
      // and if it is a 404 - we set return false
      // else the response will be a 204 and we can default to setting this to true
      const result = typeof response === 'boolean' ? response : true;
      dispatch(setIfTheMemberHasAnySavedCompletedActivities(result));
      return result;
    });
};

export const POST_ACTIVITY = 'plannerManageActivityActions/POST_ACTIVITY';

export const postActivity =
  ({ values, activityTypeId, plannedDate }) =>
  (dispatch, getState) => {
    const state = getState();
    const date = plannedDate || state.planner.activePlannedDay;
    const userId = userIdSelector(getState());

    const hasMagicMoverAward = hasMagicMoverAwardSelector(state);

    // If in state the member has NOT completed any saved activities
    // or if the newActivity checkbox bool is passed
    const firstOrNewActivity =
      hasSavedCompletedActivitiesSelector(state) === false || values.newActivity;

    return dispatch(
      apiPost(POST_ACTIVITY, GATEWAY_ACTIVITY_V2_AUTH, '/activities', {
        activityTypeId,
        duration: values.duration,
        status: values.activityComplete ? ActivityStatus.ACHIEVED : ActivityStatus.COMMITTED,
        plannedDate: date || dayjs().utc(true).format('YYYY-MM-DD'),
        profileId: userId,
        isNewActivity: !!(firstOrNewActivity && !hasMagicMoverAward),
        activitySource: values.activitySource,
        activityMetadata: {
          ...values.activityMetadata,
        },
      }),
    )
      .catch(err => {
        Promise.all([
          dispatch(validateDuration(JSON.parse(err.response.text).error.fields[0].message)),
          dispatch(setSummaryLoading(false)),
          dispatch(retriggerAnimation(false)),
        ]);
        return JSON.parse(err.response.text);
      })
      .then(
        postResponse =>
          new Promise(resolve => {
            dispatch(checkComplete(postResponse)).then(checkCompleteResponse => {
              resolve({
                id: postResponse?.data?.id,
                hasCompleted: checkCompleteResponse,
              });
            });
          }),
      )
      .then(result => {
        dispatch(getPlannerDayActivity(date, true));

        // Member who saves their first completed activity
        // If the activity is complete and the member has not saved any completed activities
        // - Set that they have a completed activity
        // - and also set that they have achieved the award
        if (values?.activityComplete && hasSavedCompletedActivitiesSelector(state) === false) {
          dispatch(setIfTheMemberHasAnySavedCompletedActivities(true));
          dispatch(setIfMagicMoverAwardAwarded(true));
        }

        // Member with existing saved activities - who tells us that they have logged a new completed activity
        // If the activity is complete and the member has ticket that this is a newActivity
        // - Set that they have achieved the award
        if (values?.activityComplete && values?.newActivity) {
          dispatch(setIfMagicMoverAwardAwarded(true));
        }

        return result;
      });
  };

export const DELETE_ACTIVITY = 'plannerManageActivityActions/DELETE_ACTIVITY';
export const deleteActivity = (activity, date) => dispatch =>
  dispatch(apiDelete(DELETE_ACTIVITY, GATEWAY_ACTIVITY_AUTH, `/activities/${activity.id}`))
    .then(() => dispatch(removeEntities([activity.id], PLANNER_ACTIVITY)))
    .then(() => dispatch(getPlannerDayActivity(date, true)))
    .catch(err => debug(err));

export const DELETE_ACTIVITY_TYPE = 'plannerManageActivityActions/DELETE_ACTIVITY';
export const deleteActivityType = activity => async (dispatch, getState) => {
  const state = getState();
  const recentActivities = state.entities?.[PLANNER_ACTIVITY];
  const recentActivity = Object.values(recentActivities).find(
    item => item.activityTypeId === activity.activityTypeId || item.activityTypeId === activity.id,
  );

  await dispatch(
    apiDelete(
      DELETE_ACTIVITY_TYPE,
      GATEWAY_ACTIVITY_AUTH,
      `/activity-types/${activity.activityTypeId || activity.id}`,
    ),
  ).catch(err => debug(err));

  if (recentActivity) {
    await dispatch(removeEntities([recentActivity.id], PLANNER_ACTIVITY));
  }

  if (activity.activityTypeId) {
    return dispatch(removeEntities([activity.activityTypeId], PLANNER_ACTIVITY_TYPE));
  }

  return dispatch(removeEntities([activity.id], PLANNER_ACTIVITY_TYPE));
};

/**
 * TODO: this should not be here! SWO-6268
 *
 * this should be a backend task - whenever passing something into the api - we should have a response for
 * the current status of the award! Current we send activity data to the api, then ask the api what the status is after that data.
 */
export const CHECK_COMPLETE = 'plannerManageActivityActions/CHECK_COMPLETE';
export const checkComplete = response => (dispatch, getState) => {
  const activeAwardInState = activeAwardSelector(getState());

  // If the user has not got an award active then abort check
  if (typeof activeAwardInState !== 'object' || Object.keys(activeAwardInState).length === 0) {
    return Promise.resolve(response.error ? IS_BLOCKING_ERROR : false);
  }

  return dispatch(
    apiGet(CHECK_COMPLETE, GATEWAY_ACTIVITY_AUTH, `/activity-goals/${activeAwardInState.id}`),
  ).then(({ data }) => {
    //  Check to see if the api has changed the status of the active award
    if (data.status === ActivityStatus.ACHIEVED && data.status !== activeAwardInState.status) {
      // Store the id of the award this is used to get information about the
      // award in the modal
      dispatch(setLatestAchievedAward(data.id));
      // Change the active award status in state to achieved
      dispatch(setEntity(PLANNER_ACTIVITY_GOAL, data.id, { ...data }, true));

      return true; // Mark the award has completed this will fire off a modal
    }

    // The award was not completed
    return response.error ? IS_BLOCKING_ERROR : false;
  });
};

// Store id of last completed award
export const SET_LATEST_ACHIEVED_AWARD = 'plannerManageActivityActions/SET_LATEST_ACHIEVED_AWARD';
const setLatestAchievedAward = createAction(SET_LATEST_ACHIEVED_AWARD);

export const VALIDATE_DURATION = 'plannerManageActivityActions/VALIDATE_DURATION';
export const validateDuration = createAction(VALIDATE_DURATION);

// ---- Checks for if awards have been awarded ---- //

export const SET_IF_MAGIC_MOVER_AWARD_AWARDED =
  'plannerManageActivityActions/SET_IF_MAGIC_MOVER_AWARD_AWARDED';

const setIfMagicMoverAwardAwarded = createAction(SET_IF_MAGIC_MOVER_AWARD_AWARDED);

export const CHECK_IF_MAGIC_MOVER_AWARD_AWARDED =
  'plannerManageActivityActions/CHECK_IF_MAGIC_MOVER_AWARD_AWARDED';

export const checkIfMagicMoverAwardAwarded = () => async (dispatch, getState) => {
  await authenticate();
  const state = getState();
  const profileId = userIdSelector(state);

  dispatch(
    apiHead(
      CHECK_IF_MAGIC_MOVER_AWARD_AWARDED,
      GATEWAY_COMMUNITY_V2_AUTH,
      `/profiles/${profileId}/awards?awardTypeId=${AwardMap.MAGIC_MOVER}`,
    ),
  )
    .then(() => dispatch(setIfMagicMoverAwardAwarded(true)))
    .catch(error => {
      // If the member does NOT have the award awarded to them,
      // the api will return a 404 response
      if (error?.response?.status === 404) {
        dispatch(setIfMagicMoverAwardAwarded(false));
      } else {
        throw error;
      }
    });
};
