import { createSelector } from 'reselect';
import formValueSelector from 'redux-form/lib/formValueSelector';
import FormNames from '../data/enum/FormNames';
import BasketFields from '../data/enum/FieldNames/BasketFields';
import ProductType from '../data/enum/ProductType';
import { PRODUCT } from '../data/entityTypes';

const basketValueSelector = formValueSelector(FormNames.SHOP_BASKET);
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const basketProductsSelector = createSelector(
  state => basketValueSelector(state, BasketFields.ITEMS),
  state => (state.entities && state.entities[PRODUCT]) || EMPTY_OBJECT,
  (items = [], productEntities) =>
    items.map(item => ({
      ...item,
      product: productEntities[item.id],
    })),
);

export const basketHifiQuantitySelector = createSelector(
  state =>
    Object.values(state.entities[PRODUCT] || EMPTY_OBJECT).filter(
      item => item.category === ProductType.HIFI,
    ),
  state => basketValueSelector(state, BasketFields.ITEMS) || EMPTY_ARRAY,
  (hifiProducts, hifiProductsAdded) => {
    let countQuantity = 0;

    hifiProducts.forEach(prod => {
      hifiProductsAdded.forEach(prodAdded => {
        if (prod.id === prodAdded.id) {
          countQuantity += prodAdded.quantity;
        }
      });
    });

    return countQuantity;
  },
);

export default basketProductsSelector;
