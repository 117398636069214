import debugLib from 'debug';
import moment from 'moment';
import { createSelector } from 'reselect';

export const debug = debugLib('SlimmingWorld:foodActions');

export const correctId = (type, key) => `${type}-${key}`;

export const FORMAT_DATE = 'YYYY-MM-DD';

export const plannedDayByDateSelector = date =>
  createSelector(
    state => state.entities.plannedDay,
    plannedDay => {
      if (plannedDay) {
        const key = Object.keys(plannedDay).find(
          item => moment(plannedDay[item].planDateUtc).format(FORMAT_DATE) === date,
        );

        return plannedDay[key];
      }
      return null;
    },
  );
