import React from 'react';
import compose from 'redux/lib/compose';
import htmlEscape from 'html-escape';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { transformUbbToHTML } from 'common/src/app/util/content/transformUbbToHtml';
import chopMemberNames from 'common/src/app/util/chopMemberNamesForMentions';
import detectUrl from 'common/src/app/util/messageParser/detectUrl';
import { parseMessage } from 'common/src/app/util/messageParser';
import { SEGMENT_RAW, SEGMENT_URL } from 'common/src/app/data/enum/SegmentType';
import { INHERIT } from 'common/src/app/data/enum/Colors';
import TextNew, { AccentColors, Colors, ElementTypes, TextTypes } from '../TextNew';
import './rich-text.scss';

const MEMBER_UNAVAILABLE = 'SlimmingWorldMember';

// returns strings for specific parsed tags, can be extended
// note, some rich text is rendered with "word-wrap: break-word;" and "white-space: pre-wrap;"
// so we should not add any whitespace here
const messageResult = {
  /* eslint-disable react/prop-types */
  [SEGMENT_RAW]: data => data,
  [SEGMENT_URL]: data =>
    `<a href=${data.url} rel="external noopener noreferrer" target="_blank">${data.text}</a>`,
  /* eslint-enable react/prop-types */
};

/**
 * RichText parses text and outputs it's valid HTML
 */
const RichText = (
  { id, text, type, color, title, escape, linkify, mentions },
  context,
  className,
  dataTestid,
) => {
  let html = '';
  if (text) {
    html = text;
  }

  // when user input, escape any potential harmful HTML
  if (escape) {
    html = htmlEscape(html);
  }

  // parse UBB for HTML
  html = transformUbbToHTML(html);

  if (typeof mentions !== 'undefined') {
    const mentionedChopTable = chopMemberNames(text); // use raw text NOT html

    let html2 = html;
    for (let i = 0; i < mentionedChopTable.length; i++) {
      const mention = mentionedChopTable[i];
      const mentionedMember = mentions.mentionedMembers.find(member => member.id === mention.id);
      if (typeof mentionedMember !== 'undefined') {
        const memberIdentifier = `&lt;@!${mentionedMember.id}>`;
        const regexMemberId = new RegExp(memberIdentifier, 'g');
        html2 = html2.replace(
          regexMemberId,
          mentionedMember.userName !== MEMBER_UNAVAILABLE
            ? `<span class='draftJsMentionPlugin__mention__29BEd'><a href='/profile/${mentionedMember.id}' id='mentionLink-${mentionedMember.userName}'>@${mentionedMember.userName}</a></span>`
            : `<span>@${mentionedMember.userName}</span>`,
        );
      }
    }
    html = html2;
  }

  // options for custom parsers where we need to render something custom based on plain
  // normal string patterns. Uses the same parsing logic as live event, but can be composed
  // dynamically depending on the usage of the RichText component
  const parsers = [];
  if (linkify) {
    parsers.push(detectUrl());
  }

  // if we have parsers, parse the message and build it up again, inserting custom HTML
  if (parsers.length) {
    const parser = compose(...parsers);

    const parsedMessage = parseMessage(html, parser);

    // since we already have mixed html in our RAW segments, we need to put it back as a string,
    // since we cannot render individual partial RAW segments as HTML.
    html = parsedMessage.reduce(
      (acc, { type: messageType, data }) =>
        acc + (messageResult[messageType] && messageResult[messageType](data)),
      '',
    );
  }

  return (
    <TextNew id={id} type={type} color={color} element={ElementTypes.DIV}>
      {title ? <h3 id={id && `${id}-title`}>{title}</h3> : null}
      <div
        id={id && `${id}-text`}
        className={className}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
        data-testid={dataTestid}
      />
    </TextNew>
  );
};

RichText.propTypes = {
  /**
   * A unique id for the response elements
   */
  id: PropTypes.string,
  /**
   * The title of section if stated
   */
  title: PropTypes.string,
  /**
   * Text that needs to be parsed
   */
  text: PropTypes.string.isRequired,
  /**
   * Type setting of text
   */
  type: PropTypes.oneOf(TextTypes.propTypes),
  /**
   * Color of the rendered contents
   */
  color: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes, INHERIT]),
  /**
   * Escape dangerous content from user
   */
  escape: PropTypes.bool,
  /**
   * Add clickable links
   */
  linkify: PropTypes.bool,
  mentions: PropTypes.object,
};

export default withFunctionalClassName(ComponentType.ATOM, 'RichText')(RichText);
