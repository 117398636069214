import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { RoleType } from 'common/src/app/data/enum/AccessibilityEnum';
import TextNew, { Colors } from '../TextNew';

const FormikError = ({
  formik,
  name,
  errorName,
  dataTestid = 'validationError',
  params,
  triggerUntouched = false,
}) => {
  // This component renders an error message if a field has
  // an error and it's already been touched.

  const error = getIn(formik?.errors, name);
  const touch = getIn(formik?.touched, name);

  return (triggerUntouched || touch) && error ? (
    <TextNew.Error
      role={RoleType.ALERT}
      dataTestid={`${dataTestid ?? ''}-${name}`}
      id={`${dataTestid ?? ''}-${name}`}
      localeId={error}
      localeParams={{ FIELD_NAME: errorName, ...params }}
      cid="error"
      color={Colors.BRAND}
    />
  ) : null;
};

FormikError.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  errorName: PropTypes.string,
  dataTestid: PropTypes.string,
  triggerUntouched: PropTypes.bool,
  params: PropTypes.object,
};

export default FormikError;
