import RouteRequirement from 'common/src/app/util/route-requirements/RouteRequirement';
import Pages from 'common/src/app/data/enum/Pages';
import { hasFlag } from 'common/src/app/util/bitwiseUtils';
import AccountState from 'common/src/app/data/enum/AccountState';
import { hasElevatedRoles } from 'common/src/app/util/userRoleUtil';

const getMemberUrl = getState => getState().config.environmentConfig.web.member.host;

/**
 * Checks if you are logged in to account with an ASP.NET identity cookie.
 * This is similar to the "authenticated" routeRequirement, but it does not check if you have
 * an access token. Instead, the cookie is decrypted directly on the Node.JS side.
 * See identityActions.js
 *
 * @type {RouteRequirement}
 */
export const hasIdentity = new RouteRequirement(
  'hasIdentity',
  [],
  ({ getState }) => {
    const hasIdentityUser = getState()?.identity?.user;
    const isTwoFactorUser = getState()?.identity?.user?.isTwoFactor;
    return hasIdentityUser && !isTwoFactorUser;
  },
  ({}, { redirect }) => redirect(Pages.LOGIN),
);

/**
 * The mfa/register page is only availabe when the user has not registered the QR code before
 *
 * @type {RouteRequirement}
 */
export const hasIdentityNotMfaRegistered = new RouteRequirement(
  'hasDefaultAndTwoFactorIdentity',
  [],
  ({ getState }) => {
    const hasIdentityUser = getState()?.identity?.user;
    // amr is a default OIDC claim to determine if it's pwd or mfa enabled
    const isNotMfaRegistered = getState()?.identity?.user?.amr === 'pwd';

    return (
      hasIdentityUser && isNotMfaRegistered && hasElevatedRoles(getState()?.identity?.user?.role)
    );
  },
  ({}, { redirect }) => redirect(Pages.LOGIN),
);

export const hasTwoFactorIdentity = new RouteRequirement(
  'hasTwoFactorIdentity',
  [],
  ({ getState }) => getState()?.identity?.user?.isTwoFactor,
  ({}, { redirect }) => redirect(Pages.LOGIN),
);

/**
 * Custom requirement for the payment result page
 * - when no account, you cannot pay, so you are redirected to start of registration
 * - when we already have a valid subscription, continue to the finished page or redirect to member
 *   if we land on this page
 *
 * @type {RouteRequirement}
 */
export const hasIdentityAndNoValidSubscription = new RouteRequirement(
  'hasIdentityAndNoValidSubscription',
  [hasIdentity],
  ({ accountState: userPermissionAccountState, getState }) => {
    // eslint-disable-next-line camelcase
    const accountState = userPermissionAccountState || getState()?.identity.user.account_state;

    if (typeof accountState !== 'number') {
      return true;
    }

    const onlineSubscriptionValid = hasFlag(accountState, AccountState.ONLINE_SUBSCRIPTION_VALID);
    const groupSubscriptionvalid = hasFlag(accountState, AccountState.GROUP_MEMBERSHIP_VALID);

    return !onlineSubscriptionValid && !groupSubscriptionvalid; // return if we have no valid group or online subscripion
  },
  ({ getState }, { redirect }) => {
    redirect(getMemberUrl(getState));
  },
);

/**
 * Redirect the user to the correct routing
 *
 * @type {RouteRequirement}
 */
export const isLoggedIn = new RouteRequirement(
  'isLoggedIn',
  [],
  ({ getState }) => !getState()?.identity?.user,
  ({ getState, renderProps }, { redirect }) => {
    const isTwoFactor = getState()?.identity?.user?.isTwoFactor;
    const isNotMfaRegistered = getState()?.identity?.user?.amr === 'pwd';
    let redirectTo = getState().config.environmentConfig.web.member.host;

    if (isTwoFactor) {
      redirectTo = Pages.MFA_VERIFY;
    } else if (isNotMfaRegistered && hasElevatedRoles(getState()?.identity?.user?.role)) {
      redirectTo = Pages.MFA_REGISTER;
    }

    return redirect(`${redirectTo}${renderProps.location.search}`);
  },
);
/**
 * Prompt user if they are already logged in.
 * The "you're already logged in" page will check membertype and prompt user
 * or in the case of being online + attempting to access group reg redirect them
 * So this route req will check the route history for the query string
 * added by the 'already logged in' page
 */
export const promptIfLoggedIn = new RouteRequirement(
  'promptIfLoggedIn',
  [],
  async ({ getState }) => {
    const state = await getState();
    return !state.identity.user;
  },

  (getState, { redirect }) => redirect(Pages.ALREADY_LOGGED_IN),
);
