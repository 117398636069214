import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import NotFoundEntityType from 'common/src/app/data/enum/NotFoundEntityTypeEnum';
import ApplicationError from '../ApplicationError';
import Button from '../../atoms/Button';
import TextNew from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import './not-found.scss';

const NotFound = ({ entityType }) => (
  <div
    className={functionalComponentClassName(ComponentType.PAGE, 'NotFound')}
    data-testid="template-not-found"
  >
    <ApplicationError>
      <TextNew.Loud cid="title" localeId={`notFound.${entityType}.title`} />
      {entityType !== NotFoundEntityType.COMMUNITYPOST && (
        <>
          <TextNew.PrimaryElegant localeId={'notFound.description'} />
          <TextNew.PrimaryElegant>
            <LocaleMessage id="general.error.contactSupport" />
            &nbsp;
            <Link href={`mailto:${marketConfig.supportEmail}`} target="_blank">
              {marketConfig.supportEmail}
            </Link>
          </TextNew.PrimaryElegant>
        </>
      )}
      <Button
        href={entityType !== NotFoundEntityType.COMMUNITYPOST ? Pages.HOME : Pages.COMMUNITY}
        ripple
      >
        <LocaleMessage id={`notFound.${entityType}.buttonLabel`} />
      </Button>
    </ApplicationError>
  </div>
);

NotFound.defaultProps = {
  entityType: NotFoundEntityType.DEFAULT,
};

NotFound.propTypes = {
  entityType: PropTypes.oneOf([NotFoundEntityType.COMMUNITYPOST, NotFoundEntityType.DEFAULT]),
};

export default NotFound;
