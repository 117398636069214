import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import PasswordRequirement from './PasswordRequirement';

const connector = connect((state, { formName, passwordField }) => {
  const passwordSelectorSelector = formValueSelector(formName);
  const password = passwordSelectorSelector(state, passwordField);

  return { password };
});

export default compose(connector)(PasswordRequirement);
