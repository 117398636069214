/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* global WP_DEFINE_ENABLE_REACT_HOT_LOADER, WP_DEFINE_DEVELOPMENT, WP_DEFINE_PUBLIC_PATH */
import React from 'react';
import { syncHistoryWithStore } from 'react-router-redux';
import { useRouterHistory as routerHistory, match, RouterContext } from 'react-router';
import { createHistory } from 'history';
import { setInitMode, MODE_INIT_SELF } from '@mediamonks/react-redux-component-init';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import promisify from 'es6-promisify';
import debugLib from 'debug';

import Configuration from 'common/src/app/config/Configuration';

import { configureSentryOnClient } from '../app/util/sentry/sentry-client';
import processQueryRouting from '../app/util/QueryRouting/processQueryRouting';

import clientConfig from './client.configdefinitions';
import serviceConfig from '../app/config/service.configdefinitions';

import parseRouteRequirements from '../app/util/route-requirements/parseRouteRequirements';
import processRequirements from '../app/util/route-requirements/processRequirements';
import createStoreInstance from './util/createStoreInstance';
import setupInjects from '../app/util/setupInjects';
import patchLocalStorageDebug from './util/patchLocalStorageDebug';
import { AUTHENTICATION_MANAGER } from '../app/data/Injectables';
import { getValue } from '../app/util/injector';
import { authenticated } from '../app/config/routeRequirements';
import RenderMode from '../app/data/enum/RenderMode';
import RedirectError from '../app/util/RedirectError';
import QueryRoutingProvider from '../app/util/QueryRouting/QueryRoutingProvider';
import { getPropOnDeepestRoute } from '../app/util/routeUtils';
import { clearSEO } from '../app/actions/seoActions';
import { pageRenderComplete, setTrackingPersistentData } from '../app/actions/trackingActions';
import { enable as enableReactRenderPerf } from './util/react-render-perf';
import prependWebHost from '../app/util/prependWebHost';
import '../app/util/customizeMomentLocale';
import PathAliasManager from '../app/util/PathAliasManager';
import { getUserPermissionStoredData } from '../app/util/userPermissionStateUtil';

const spriteSrc = require('../../../components/atoms/Icon/sprite.svg').default;

const debug = debugLib('SlimmingWorld:Client');
const matchPromisified = promisify(match, { multiArgs: true });

// Conditionally load AppContainer from react-hot-loader, if it's enabled.
let AppContainer;
if (WP_DEFINE_ENABLE_REACT_HOT_LOADER) {
  // eslint-disable-next-line global-require
  AppContainer = require('react-hot-loader').AppContainer;
}
if (!WP_DEFINE_ENABLE_REACT_HOT_LOADER) {
  AppContainer = ({ children }) => children;
}

if (WP_DEFINE_DEVELOPMENT) {
  patchLocalStorageDebug();
}

/* eslint-disable no-underscore-dangle */
if (window.__REACT_HOT_LOADER__) {
  // warnings={false} on AppContainer should do this as well, but unfortunately that code
  // runs a little too late, causing some warnings to display anyway
  window.__REACT_HOT_LOADER__.warnings = false;
}
/* eslint-enable no-underscore-dangle */

class Client {
  /**
   * The react-router configuration. Passed in from outside for HMR purposes.
   */
  Routes = null;

  /**
   * The react-router config processed with processQueryRouting(). This is the configuration
   * that is used for main route matching
   */
  processedRoutes = null;

  /**
   * Instance of the Redux store. Created as soon as the routing config and reducers
   * are passed
   */
  store = null;

  /**
   * Main history (browerHistory) instance. Created as soon as the routing config and reducers
   * are passed
   */
  history = null;

  /**
   * Boolean indicating if the first client-side render has already been started.
   * @type {boolean}
   */
  firstRenderStarted = false;

  /**
   * HTML element the application will be mounted on. Set in the init() method
   */
  mountNode = null;

  /**
   * Boolean to detect and pre
   * @type {boolean}
   */
  renderInProgress = false;

  /**
   * Setup function(s) for redux-listeners-middleware. Is passed in from the outside because this
   * differs per microservice
   * @type {function|function[]}
   */
  reduxListenersSetup = [];

  /**
   * Root epic function to use in redux-observable setup. Should be set using the
   * enableReduxObservable() method
   * @type {function}
   */
  rootEpic = null;

  /**
   * Manages "path aliases", mapping a specific path to always redirect to another path. See
   * PathAliasManager class
   * @type {PathAliasManager}
   */
  pathAliases = new PathAliasManager();

  /**
   * Bootstraps the client. Should be called after the react router config and reducers are
   * passed in.
   */
  init() {
    if (WP_DEFINE_DEVELOPMENT && localStorage.getItem('reactRenderPerf')) {
      enableReactRenderPerf();
    }

    if (!this.store || !this.Routes) {
      throw new Error('Cannot initialize Client. Routes and reducer have not yet been passed.');
    }

    this.mountNode = document.getElementById('app');
    if (!this.mountNode) {
      throw new Error('Cannot find mount node to render application.');
    }

    const { environmentConfig } = this.store.getState().config;

    if (clientConfig.useSentry) {
      try {
        configureSentryOnClient(Sentry, environmentConfig.sentry.client, window.buildInfo);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    this.history.listen(this.handleRequest);
    this.handleRequest();

    const div = document.createElement('div');
    div.className = 'svg-sprite';
    div.innerHTML = spriteSrc;
    document.body.insertBefore(div, document.body.firstChild);
  }

  /**
   * Enables the redux-observable module when creating a new store. Should be called before
   * init().
   * @param rootEpic {function} The root epic function that should be used during initialization
   */
  enableReduxObservable(rootEpic) {
    if (this.firstRenderStarted) {
      throw new Error('client.enableReduxObservable() should be called before client.init()');
    }

    this.rootEpic = rootEpic;
  }

  /**
   * Handles the initial page render and consequent requests when the history changes location.
   * Will execute the renderPage() function and redirect if it throws any RedirectError.
   * @returns {Promise<void>}
   */
  handleRequest = async () => {
    try {
      await this.renderPage();
    } catch (error) {
      if (error instanceof RedirectError) {
        debug(`RedirectError thrown. Redirecting request to "${error.path}"`);
        this.history.redirect(error.path);
      } else {
        debug(`Error during page rendering: ${error.message}`);
        throw error;
      }
    }
  };

  /**
   * Will match the current route using react-router and re-render the page. Called
   * on initialization, on history change and on HMR hot update.
   */
  renderPage = async () => {
    const { renderMode } = this.store.getState().config;
    const isServerRendered = renderMode === RenderMode.SERVER && !this.firstRenderStarted;

    const aliasRedirect = await this.pathAliases.handleRequest(
      this.history.getCurrentLocation().pathname,
      { getState: this.store.getState, dispatch: this.store.dispatch },
    );
    if (aliasRedirect) {
      this.history.replace(aliasRedirect);
      return;
    }

    if (this.renderInProgress) {
      debug(
        'WARNING: renderPage() called while a page render was already in progress. This is likely due to a duplicate client-side history push. Aborted page rendering.',
      );
      return;
    }

    this.renderInProgress = true;
    this.firstRenderStarted = true;

    const [redirect, mainRenderProps] = await matchPromisified({
      history: this.history,
      routes: this.processedRoutes,
    });

    if (redirect) {
      this.renderInProgress = false;
      this.history.replace(redirect);
      return;
    }

    if (!mainRenderProps) {
      throw new Error(
        'No route matched. Please attach a catch-all 404 route to prevent this error.',
      );
    }

    const queryRouteMatchingResults = await this.matchQueryRouting(mainRenderProps);
    const queryRoutingState = await this.processQueryRouteMatching(queryRouteMatchingResults);
    if (!queryRoutingState) return;

    const queryRoutingResults = {};
    queryRoutingState.forEach(
      ({ renderProps, queryParam }) => (queryRoutingResults[queryParam] = renderProps),
    );

    let meetsRequirements = true;
    if (isServerRendered) {
      debug('Rendering page pre-rendered by server');
    } else {
      debug('Rendering page on Client');

      if (serviceConfig.useClientAuthentication) {
        const authenticationManager = getValue(AUTHENTICATION_MANAGER);
        authenticationManager.setFirstRenderComplete();
      }
      meetsRequirements = await this.processRouteRequirements([
        { renderProps: mainRenderProps, history: this.history },
        ...queryRoutingState,
      ]);
    }

    if (!meetsRequirements) {
      debug('route requirements not met. stopping page render.');
      return;
    }

    // before rendering the new page, clear all SEO info
    // otherwise we might be overriding the newly set page title with an old SEO title
    this.store.dispatch(clearSEO());

    let pageTitle = document.title;
    if (!isServerRendered) {
      // find page title on main routes, only when not rendered on the server
      // otherwise we will overwrite it.
      pageTitle = getPropOnDeepestRoute(mainRenderProps.routes, 'title');
    }
    // find page title on query route (pick first match for now)
    // queryRoutes will only be rendered on the client, so we can overwrite with this
    const queryRoutes = queryRoutingState?.[0]?.renderProps?.routes;
    if (queryRoutes) {
      const pagePath = mainRenderProps.routes[mainRenderProps.routes.length - 1].path;
      const queryPath = `?${queryRoutes[0].query}=${queryRoutes[queryRoutes.length - 1].path}`;
      pageTitle = getPropOnDeepestRoute(queryRoutes, 'title') || pageTitle;

      // Set current path
      this.store.dispatch(
        setTrackingPersistentData({
          pathname: `${pagePath}${queryPath}`,
        }),
      );
    }
    document.title = pageTitle.replace('{pageTitle}', Configuration.pageTitle);

    debug('route requirements met. Rendering page.');

    hydrate(
      <AppContainer warnings={false}>
        <QueryRoutingProvider queryRoutingResults={queryRoutingResults}>
          <Provider store={this.store}>
            <RouterContext {...mainRenderProps} />
          </Provider>
        </QueryRoutingProvider>
      </AppContainer>,
      this.mountNode,
    );

    // Set the tokens after the very first render
    // This will fix the difference between the client and server at the first render
    if (serviceConfig.useClientAuthentication) {
      const authenticationManager = getValue(AUTHENTICATION_MANAGER);
      authenticationManager.setFirstRenderComplete();
    }

    this.store.dispatch(pageRenderComplete());
    this.store.dispatch(setInitMode(MODE_INIT_SELF));
    this.renderInProgress = false;
  };

  /**
   * Run processRequirements for the routeRequirements configured for the current routes
   * of the main routing and each of the routes for the query routing (if any)
   * @param {Array} allRoutingResults The routing results, starting with the main routing
   * and any query routing afterwards.
   * @param {Object} allRoutingResults[].history The history instance that controls this
   * routing. Instance of browserHistory for the main history, memoryHistory for all query
   * routing.
   * @param {Object} allRoutingResults[].renderProps The renderProps that resulted from the
   * react-router match() call for this routing
   * @param {string} [allRoutingResults[].queryParam] The name of the query param this route
   * matching is for. If it is the main routing, this is omitted.
   * @returns {Promise<boolean>|boolean} A boolean or Promise that resolves with a boolean that
   * indicates if rendering should continue or if it should be aborted.
   */
  processRouteRequirements = async allRoutingResults => {
    // retrieve the authenticationManager instance for routeRequirements use
    let authenticationManager;
    if (serviceConfig.useClientAuthentication) {
      authenticationManager = getValue(AUTHENTICATION_MANAGER);
    }

    for (const { renderProps, history, queryParam } of allRoutingResults) {
      if (renderProps) {
        const routeRequirements = parseRouteRequirements(renderProps.routes);
        debug(
          `Processing routeRequirements for ${
            queryParam ? `queryParam "${queryParam}"` : 'main routing'
          }`,
        );

        if (serviceConfig.useClientAuthentication) {
          // We pass the auth requirement to the ClientAuthenticationManager instance
          // If auth is required, the authenticationManager will redirect to login if needed
          authenticationManager.onRouteChange(routeRequirements.includes(authenticated));
        }

        const meetsRequirements = await processRequirements(
          routeRequirements,
          {
            dispatch: this.store.dispatch,
            getState: this.store.getState,
            accountState: getUserPermissionStoredData({ getState: this.store.getState })
              .accountState,
            renderProps,
          },
          {
            // the redirect function redirects the main history or the query routing history,
            // depending on which requirements we are testing
            redirect: (location_, webHost = null) => {
              const state = this.store.getState();
              const location = prependWebHost(state.config.environmentConfig, location_, webHost);
              this.renderInProgress = false;

              // history.push doesn't work with full paths
              if (location.startsWith('http')) {
                document.location.href = location;
                return;
              }
              history.push(location);
            },
            // when calling redirectToLogin, we probably always want to redirect the main
            // routing history
            redirectToLogin: () => {
              if (serviceConfig.useClientAuthentication) {
                authenticationManager.executeRedirectSignin();
              } else {
                throw new Error('Authentication is not set up');
              }
            },
          },
        );

        if (!meetsRequirements) {
          return false;
        }
      }
    }

    return true;
  };

  /**
   * Processes the query routing results
   *
   *  - Updates the memoryHistory of the query routing to match any changes in the query route
   *  - Performs a redirect on the main routing if one of the routes returned a redirect
   *  - Stores the routing results on the routing config object so it can be read by
   *    QueryRoutingContainer
   *
   * @param {object} results The resolved value of matchQueryRouting()
   * @param {Array<Array>} results.queryRoutingResults An array of return values of the routing
   * match() call for each query routing
   * @param {Object} results.queryRoutingConfigs An object containing all query routing configs
   * which are scoped under the current route, mapped by parameter name
   * @param {Array<string>} results.routeQueryParams All query parameter names which are in the
   * current query string and have configuration for query routing
   * @param {Object} results.queryParams All query params as parsed by the react-router match()
   * call
   * @returns {Array} array of { renderProps, history, queryParam } for each query
   */
  processQueryRouteMatching = results => {
    const { queryRoutingResults, queryRoutingConfigs, routeQueryParams, queryParams } = results;

    // Loop through all query routing (for loop so we can return)
    for (let i = 0; i < queryRoutingResults.length; i++) {
      const [queryRedirect, queryRenderProps] = queryRoutingResults[i];
      const queryRoute = queryRoutingConfigs[routeQueryParams[i]];
      const queryValue = queryParams[routeQueryParams[i]];

      // Update the query memoryHistory if it does not match the current path in the query param
      if (queryRoute.history.getCurrentLocation().pathname !== queryValue && queryValue !== null) {
        queryRoute.history.push(queryValue);
      }

      // If the query result is a redirect, perform a redirect on the main history object
      if (queryRedirect) {
        this.renderInProgress = false;
        queryRoute.history.replace(queryRedirect.pathname);
        return null;
      }

      // Update the routing result in the render props. This causes the view to update accordingly
      queryRoute.renderProps = queryRenderProps || null;
    }

    return queryRoutingResults.map(([, renderProps], index) => ({
      renderProps,
      history: queryRoutingConfigs[routeQueryParams[index]].history,
      queryParam: routeQueryParams[index],
    }));
  };

  /**
   * Perform route matching on all the query parameters of the current location that
   * have been configured using <QueryRouting>
   * @param renderProps The renderProps result of the main route match
   * @returns Promise<Object>
   */
  // eslint-disable-next-line class-methods-use-this
  matchQueryRouting = async renderProps => {
    // lookup all <QueryRouting> config on the current route
    const queryRoutingConfigs = {};
    if (renderProps) {
      renderProps.routes.forEach(route => {
        if (route.queryRoutes) {
          Object.keys(route.queryRoutes).forEach(queryRoutDef => {
            queryRoutingConfigs[queryRoutDef] = route.queryRoutes[queryRoutDef];
          });
        }
      });
    }
    // reset all query routing match results to null
    Object.keys(queryRoutingConfigs).forEach(
      queryParam => (queryRoutingConfigs[queryParam].renderProps = null),
    );
    // get the query string from the main route matching result
    const queryParams = renderProps ? renderProps.location.query : {};
    // find all current query params that have <QueryRouting /> config
    const routeQueryParams = Object.keys(queryParams).filter(
      queryParam => !!queryRoutingConfigs[queryParam],
    );
    // run route matching for all query params
    const queryRoutingResults = await Promise.all(
      routeQueryParams.map(queryParam =>
        matchPromisified({
          routes: queryRoutingConfigs[queryParam].routes,
          history: queryRoutingConfigs[queryParam].history,
          location: queryParams[queryParam],
        }),
      ),
    );

    return {
      queryRoutingResults,
      queryRoutingConfigs,
      routeQueryParams,
      queryParams,
    };
  };

  /**
   * Sets the react-router Routes config used for resolving routes. Can be updated at runtime
   * when using hot module reloading in development.
   * @param Routes A react-router configuration component
   */
  setReactRoutes = Routes => {
    this.Routes = Routes;
    if (this.history) {
      this.processedRoutes = processQueryRouting(this.Routes, this.history);
    }

    if (this.firstRenderStarted && WP_DEFINE_ENABLE_REACT_HOT_LOADER) {
      this.renderPage();
    }
  };

  /**
   * Creates a Redux.JS store with the given reducer or updates the current store if it already
   * exists. Can be called at runtime when using hot module reloading in development.
   * @param reduxReducer The main redux reducer
   */
  setReduxReducer = reduxReducer => {
    if (this.store) {
      this.store.replaceReducer(reduxReducer);
    } else {
      const browserHistory = routerHistory(createHistory)({
        basename: (WP_DEFINE_PUBLIC_PATH || '').replace(/\/$/gi, ''), // remove trailing /
      });

      // Check to see if C0003 is within the OnetrustActiveGroups string
      // - if so pass this as true into the createStoreInstance function

      this.store = createStoreInstance(
        reduxReducer,
        browserHistory,
        this.reduxListenersSetup,
        this.rootEpic,
        window.OnetrustActiveGroups?.includes('C0003') || false,
      );
      if (WP_DEFINE_DEVELOPMENT) {
        // eslint-disable-next-line no-underscore-dangle
        window.__debugStoreReference = this.store;
      }

      // must be placed here, because we need the created store for config,
      // but it must be done before any actions are dispatched (like the
      // history line after this)
      const config = this.store.getState().config.environmentConfig;
      setupInjects({ config, dispatch: this.store.dispatch });

      this.history = syncHistoryWithStore(browserHistory, this.store);
      if (this.Routes) {
        this.processedRoutes = processQueryRouting(this.Routes, this.history);
      }
    }
  };
}

export default Client;
