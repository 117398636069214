import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { makeEntitySelector } from 'common/src/app/reducers/view/entityViewReducer';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_COMPONENT_STATIC_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import StaticContent from './StaticContent';

const connected = connect(() => {
  const entitySelector = makeEntitySelector();
  return state => ({
    page: entitySelector(state, state.view.pages.staticContent.page),
    background: state.entities?.[COMPONENT]?.[API_COMPONENT_STATIC_PAGE_BACKGROUND]?.image,
  });
});

const addInitAction = withInitAction(
  ['location', 'routes'],
  {
    prepared: ({ location }, dispatch) =>
      Promise.all([
        dispatch(getStaticContent(location.pathname.substr(1), true)),
        dispatch(getComponent(API_COMPONENT_STATIC_PAGE_BACKGROUND)),
      ]),
  },
  { getPrepareKey: componentId => componentId },
);

export default compose(addInitAction, connected)(StaticContent);
