/* eslint-disable no-console, no-param-reassign, no-useless-escape, max-len, space-infix-ops, global-require, comma-spacing */
/**
 * This file should check if the browser is supported and if not, redirect to an appropriate
 * error page. This code will be executed before all other JS, as it is added to the top of
 * the exported JS bundle.
 */

import parser from 'ua-parser-js';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';

// check list of available values:
// https://github.com/faisalman/ua-parser-js#methods
const Browser = (() => {
  const { document } = window;

  function parse(userAgent, platform) {
    const ua = userAgent.toLowerCase();
    platform = platform ? platform.toLowerCase() : '';
    let platformVersion = '';

    // parse using us-parse lib
    const result = parser(userAgent);

    // some platform checks that are not available on the useragent string
    platform = ua.match(/ip(?:ad|od|hone)/)
      ? 'ios'
      : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0];
    if (platform === 'win') {
      platform = 'windows';
    } else if (platform === 'ios') {
      const iOSVersion = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      platformVersion = parseInt(iOSVersion[1], 10);
    }

    const info = {
      ...result,
      isWebview:
        // Mozilla/5.0 (Linux; Android 4.4.4; One Build/KTU84L.H4) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/33.0.0.0 Mobile Safari/537.36 [FB_IAB/FB4A;FBAV/28.0.0.20.16;]
        // Mozilla/5.0 (iPhone; CPU iPhone OS 8_2 like Mac OS X) AppleWebKit/600.1.4 (KHTML, like Gecko) Mobile/12D508 [FBAN/FBIOS;FBAV/27.0.0.10.12;FBBV/8291884;FBDV/iPhone7,1;FBMD/iPhone;FBSN/iPhone OS;FBSV/8.2;FBSS/3; FBCR/vodafoneIE;FBID/phone;FBLC/en_US;FBOP/5]
        ua.indexOf('fb4a') !== -1 ||
        ua.indexOf('fbav') !== -1 || // FB
        ua.indexOf('pinterest') !== -1 ||
        ua.indexOf('instagram ') !== -1 ||
        (result.browser.name || '').toLowerCase().indexOf('webview') !== -1,
      platform,
      platformVersion,
    };

    // lowercase, make major int, parse major.minor as float
    info.browser.name = info.browser.name.toLowerCase();
    info.browser.major = parseInt(info.browser.major, 10);
    info.browser.minor = parseFloat(
      info.browser.version && info.browser.version.split('.').slice(0, 2).join('.'),
    );

    info.os.name = info.os.name.toLowerCase();
    info.os.major = parseInt(info.os.version, 10);
    info.os.minor = parseFloat(info.os.version && info.os.version.split('.').slice(0, 2).join('.'));

    return info;
  }

  const browser = parse(navigator.userAgent, navigator.platform);

  browser.isMobile = browser.device.type === 'mobile';
  browser.isTablet = browser.device.type === 'tablet';
  browser.isDesktop = !browser.isMobile && !browser.isTablet;

  // For compatibility browsing, not used that much anymore
  if (browser.browser.name === 'ie' && browser.platform === 'windows') {
    browser.version = document.documentMode;
  }

  // set booleans for easier checks
  browser[browser.browser.name] = true;
  browser[browser.browser.name + browser.browser.major] = true;
  browser[browser.os.name] = true;
  // shortcut browser version
  browser.version = browser.browser.minor;

  if (browser.browser.name === 'chrome webview') {
    browser.chrome = true;
    browser[`chrome${browser.browser.major}`] = true;
  }

  return browser;
})();

const appNode = document.getElementById('app');

function showPage(template) {
  if (appNode.parentNode?.contains(appNode)) {
    appNode.parentNode.removeChild(appNode);
  }
  document.write(template);
}

if (Browser.ie && Browser.version < 11) {
  // old IE
  // tested with Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.1; WOW64; Trident/6.0)
  const template = require('raw-loader!./browser-not-supported.html');

  const parsedTemplate = template.replace(/%EMAIL%/g, marketConfig.supportEmail);

  showPage(parsedTemplate);
} else if (Browser.platform === 'ios' && Browser.platformVersion < 9) {
  // old IOS
  // tested with Mozilla/5.0 (iPad; CPU OS 8_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/8.0 Mobile/13B137 Safari/601.1
  const template = require('raw-loader!./ios-not-supported.html');

  const parsedTemplate = template.replace(/%EMAIL%/g, marketConfig.supportEmail);

  showPage(parsedTemplate);
}
