import { DURATION_HOURS, DURATION_MINUTES } from '../data/regexPatterns';

/**
 * This util takes time durations stored in the ISO 8601 format and returns a hours and minutes object
 *
 * @param {String} duration - The duration stored in ISO 8601 format see https://en.wikipedia.org/wiki/ISO_8601 for more info
 * @returns {object} - Returns an object of hours and mintues
 * @example 'PT1H10M' would return {minutues: 10, hours: 1}
 */

const formatTimeDuration = duration => {
  const durationSplit = {};
  const [minutes] = duration?.match(DURATION_MINUTES) ?? [0];
  const [hours] = duration?.match(DURATION_HOURS) ?? [0];

  if (minutes && minutes[0]) {
    durationSplit.minutes = minutes;
  }

  if (hours && hours[0]) {
    durationSplit.hours = hours;
  }

  return durationSplit;
};

/**
 * This util takes time durations stored in the ISO 8601 format and returns a
 * number of total minutes
 *
 * @param {String} duration - The duration stored in ISO 8601 format see https://en.wikipedia.org/wiki/ISO_8601 for more info
 * @returns {number} - Return the total number of minutes based off the ISO 8601 duration
 * @example 'PT1H10M' would return 70
 */
export const convertDurationToMinutes = durationISO => {
  const duration = formatTimeDuration(durationISO);
  const minutes = parseInt(duration.minutes || 0, 10);
  const hoursInMinutes = parseInt(duration.hours || 0, 10) * 60;
  return minutes + hoursInMinutes;
};

export default formatTimeDuration;
