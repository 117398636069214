/* global 'molecule' */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import IconButton from '../IconButton';
import LocaleMessage from '../../atoms/LocaleMessage';
import './information-toggle.scss';

/**
 * Information toggle component, showing a clickable icon to see more info
 */
class InformationToggle extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  toggleActive = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }));
  };

  render() {
    const { id, label, information, size } = this.props;
    const { isActive } = this.state;

    return (
      <div {...componentClassNameProp('molecule', this)}>
        <IconButton
          id={id}
          aria-labelledby={id ? `${id}-text-content` : undefined}
          icon={IconName.INFORMATION}
          onClick={this.toggleActive}
          width={size}
          rounded
        >
          <TextNew.FormalOriginal
            id={id ? `${id}-text-content` : undefined}
            element={ElementTypes.DIV}
          >
            {label}
          </TextNew.FormalOriginal>
        </IconButton>
        {isActive && (
          <TextNew.FormalOriginal cid="information-box" element={ElementTypes.DIV}>
            {information}
            <IconButton icon={IconName.CROSS} width={10} onClick={this.toggleActive} />
          </TextNew.FormalOriginal>
        )}
      </div>
    );
  }
}

InformationToggle.defaultProps = {
  label: <LocaleMessage id="informationToggle.defaultLabel" />,
  size: 20,
};

InformationToggle.propTypes = {
  id: PropTypes.string,
  /**
   * Label before the 'i' icon, can be a string or a LocaleMessage
   */
  label: PropTypes.node,
  /**
   * The information to toggle, can be a string or a LocaleMessage
   */
  information: PropTypes.node.isRequired,
  size: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  dataTestid: PropTypes.string, // Used by componentClassNameProp
};

export default InformationToggle;
