import React from 'react';
import { compose } from 'redux';
import { SubmissionError } from 'redux-form';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import FormNames from 'common/src/app/data/enum/FormNames';
import { verifySecurityDetails } from 'common/src/app/actions/resources/accountActions';
import RecoverEmailErrorCode from 'common/src/app/data/enum/RecoverEmailErrorCode';
import { accountRecoveryBirthdate } from 'common/src/app/validation/accountValidation';
import { setSecurityQuestion } from 'common/src/app/actions/components/emailRecoveryActions';
import Pages from 'common/src/app/data/enum/Pages';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ModalNames from 'common/src/app/data/enum/ModalNames';

import AccountRecoveryErrorModal from '../../molecules/AccountRecoveryErrorModal';
import RecoverEmailDOB from './RecoverEmailDOB';

const enhanced = enhancedReduxForm(
  {
    form: FormNames.RE_SECURITY_CHECK,
    destroyOnUnmount: false,
    onSubmit: async (values, dispatch) =>
      await dispatch(verifySecurityDetails(values))
        .then(({ securityQuestion }) => {
          if (securityQuestion !== null) {
            dispatch(setSecurityQuestion(securityQuestion));
          }

          // If the user does not have a security question then they need to
          // contact support
          if (securityQuestion === null) {
            dispatch(
              openModal(
                ModalNames.CONTENT,
                {
                  showCloseButton: false,
                  showWrapperCloseButton: false,
                  children: <AccountRecoveryErrorModal />,
                },
                false,
                false,
                false,
              ),
            );
            throw new SubmissionError(Pages.RE_SECURITY_CHECK);
          }
        })
        .catch(error => {
          const {
            error: { code, message },
          } = JSON.parse(error.response.text);

          if (code === RecoverEmailErrorCode.SECURITY_QUESTION_MAX_RETRIES) {
            dispatch(
              openModal(
                ModalNames.CONTENT,
                {
                  showCloseButton: false,
                  showWrapperCloseButton: false,
                  children: (
                    <AccountRecoveryErrorModal bodyLocale={message} showContactMessage={true} />
                  ),
                },
                true,
                false,
                false,
              ),
            );
            throw new SubmissionError(Pages.RE_SECURITY_CHECK);
          }
          /** If error code does not match 'security-question-max-retries' then
           * throw the error so it can be visable to the user */
          throw error;
        }),
    generalErrorMessage: { locale: 'recoverEmailDOB.generalErrorMessage' },
  },
  {
    ...accountRecoveryBirthdate,
  },
);

export default compose(enhanced)(RecoverEmailDOB);
