import { connect } from 'react-redux';
import { compose } from 'redux';
import { adyenClientSession } from 'common/src/app/actions/resources/paymentActions';
import {
  getRecaptchaToken,
  getRecaptchaExecuted,
} from 'common/src/app/selectors/recaptchaSelector';
import AdyenPayment from './AdyenPayment';

const mapStateToProps = (state: any) => ({
  query: state.routing?.locationBeforeTransitions.query,
  recaptchaToken: getRecaptchaToken(state),
  recaptchaExecuted: getRecaptchaExecuted(state),
  paymentRecaptchaSiteKey: state.config.environmentConfig?.paymentRecaptcha?.siteKey,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSessionDetails: (purchaseId: string, returnUrl: string, recaptchaToken: string) =>
    dispatch(adyenClientSession(purchaseId, returnUrl, recaptchaToken)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(AdyenPayment);
