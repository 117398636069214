import BodyMagicAwards from './BodyMagicAwards';

// Color ids used in body magic awards in the planner and the acitivty video
export default {
  [BodyMagicAwards.GETTING_STARTED]: {
    stopColourLight: '#FCCE07',
    stopColourDark: '#FF6100',
  },
  [BodyMagicAwards.BRONZE]: {
    stopColourLight: '#C8A293',
    stopColourDark: '#6E4E60',
  },
  [BodyMagicAwards.SILVER]: {
    stopColourLight: '#C9DCDA',
    stopColourDark: '#AFAFAF',
  },
  [BodyMagicAwards.GOLD]: {
    stopColourLight: '#FAD199',
    stopColourDark: '#A1723D',
  },
};
