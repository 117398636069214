import handleActions from 'redux-actions/lib/handleActions';
import { ADD_VERIFY_PASSWORD_TOKEN_ERROR } from 'common/src/app/actions/resources/accountActions';

const initialState = {
  error: false,
};

export default handleActions(
  {
    [ADD_VERIFY_PASSWORD_TOKEN_ERROR]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
  initialState,
);
