/* global 'molecule','PackageGift' */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew from '../../atoms/TextNew';
import './package-gift.scss';

const PackageGift = ({ duration: isoDuration }, context, className) => {
  const momentDuration = moment.duration(isoDuration);

  return (
    <div className={className}>
      <TextNew.PrimaryElegant
        cid="title"
        localeId="freePackageGift.title"
        localeParams={{ MONTHS: momentDuration.months() }}
      />

      <TextNew.Formal
        cid="description"
        localeId="freePackageGift.description"
        localeParams={{ MONTHS: momentDuration.months() }}
      />
    </div>
  );
};

PackageGift.propTypes = {
  duration: PropTypes.string.isRequired,
};

export default withFunctionalClassName('molecule','PackageGift')(PackageGift);
