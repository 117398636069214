import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import { noAccount } from 'common/src/app/config/routeRequirements';
import Pages from 'common/src/app/data/enum/Pages';
import OnlineRegistration from 'bundle-loader?lazy&reactHot&name=OnlineRegistration!./index';
import MedicalCheck from 'bundle-loader?lazy&reactHot&name=MedicalCheck!components/templates/MedicalCheck';
import PersonalDetails from 'bundle-loader?lazy&reactHot&name=PersonalDetails!components/templates/PersonalDetails';
import Summary from 'bundle-loader?lazy&reactHot&name=Summary!components/templates/Summary';
import Introduction from 'bundle-loader?lazy&reactHot&name=Introduction!components/templates/Introduction';
import WelcomeBack from 'bundle-loader?lazy&reactHot&name=WelcomeBack!components/templates/WelcomeBack';
import PackageSelection from 'bundle-loader?lazy&reactHot&name=PackageSelection!./components/templates/PackageSelection';
import { promptIfLoggedIn } from '../../../config/accountRouteRequirements';

const routes = (
  <Route
    path={Pages.REGISTRATION}
    {...getRouteComponentProp(OnlineRegistration)}
    isWizardForm
    requirements={[promptIfLoggedIn, noAccount]}
    title="Registration | {pageTitle}"
  >
    <Route
      path={Pages.REGISTRATION_PACKAGE_SELECTION}
      {...getRouteComponentProp(PackageSelection)}
      wizardFormOrder={0}
      excludeFromWizard={state => !!state.registration?.invite?.skipPaymentDuringRegistration}
      title="Package Selection | Registration | {pageTitle}"
    />
    <Route
      path={Pages.REGISTRATION_INTRODUCTION}
      {...getRouteComponentProp(Introduction)}
      wizardFormOrder={1}
      requirements={[noAccount]}
      title="Package Selection | Registration | {pageTitle}"
    />
    <Route
      path={Pages.REGISTRATION_MEDICAL_CHECK}
      {...getRouteComponentProp(MedicalCheck)}
      wizardFormOrder={2}
      requirements={[noAccount]}
      title="Medical Check | Registration | {pageTitle}"
    />
    <Route
      path={Pages.REGISTRATION_ACCOUNT_DETAILS}
      {...getRouteComponentProp(PersonalDetails)}
      wizardFormOrder={3}
      requirements={[noAccount]}
      showSecurityQuestion
      title="Personal Details | Registration | {pageTitle}"
      showCountry
    />
    <Route
      path={Pages.REGISTRATION_SUMMARY}
      {...getRouteComponentProp(Summary)}
      wizardFormOrder={4}
      requirements={[noAccount]}
      title="Summary | Registration | {pageTitle}"
    />
    <Route
      path={Pages.WELCOME_BACK}
      {...getRouteComponentProp(WelcomeBack)}
      title="Welcome Back | Registration | {pageTitle}"
    />
  </Route>
);

export default routes;
