/* global WP_DEFINE_IS_NODE */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollBehavior from 'scroll-behavior';
import ScrollBehaviorContext from 'react-router-scroll/lib/ScrollBehaviorContext';
import GlobalStyling from 'components/atoms/GlobalStyling';
import ScrollManager from 'common/src/app/hoc/ScrollManager';
import ModalRoot from 'components/atoms/ModalRoot';
import DeviceStateProvider from 'common/src/app/hoc/DeviceStateProvider';
import LocaleProvider from 'common/src/app/hoc/LocaleProvider';
import shouldUpdateScroll from 'common/src/app/util/shouldUpdateScroll';
import ContentModal from 'components/atoms/ContentModal';
import QueryRoutingContainer from 'common/src/app/util/QueryRouting/QueryRoutingContainer';
import Pollyfills from 'common/src/app/util/Pollyfills';
import ExistingLegacyAccountPromptModal from 'components/molecules/ExistingLegacyAccountPromptModal';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import GeneralError from 'components/templates/GeneralError';
import getMessages from '../../utils/locale/getMessages';
import RegistrationError from '../pages/GroupRegistration/components/molecules/RegistrationError';
import SuccessfullyRegisteredFree2Go from '../pages/GroupRegistration/components/molecules/SuccessfullyRegisteredFree2Go';

const modalMap = {
  [ModalNames.CONTENT]: ContentModal,
  [ModalNames.REGISTRATION_ERROR]: RegistrationError,
  [ModalNames.EXISTING_LEGACY_ACCOUNT_PROMPT_MODAL]: ExistingLegacyAccountPromptModal,
  [ModalNames.SUCCESSFULLY_REGISTERED_FREE2GO]: SuccessfullyRegisteredFree2Go,
};

/**
 * The root component of the application.
 *
 * As this is a different component in every microservice, avoid creating functionality here
 * that is needed across all microservices. Instead, consider moving shared functionality to
 * a component that is in the common codebase.
 */
const Application = props => {
  const { children, hasGeneralError } = props;

  const content = (
    <DeviceStateProvider>
      <LocaleProvider getMessages={getMessages}>
        <GlobalStyling>
          <div className="component-application">
            <Pollyfills />
            <ScrollManager />
            <QueryRoutingContainer query="modal" />
            <ModalRoot componentMap={modalMap} />
            {hasGeneralError ? <GeneralError /> : children}
          </div>
        </GlobalStyling>
      </LocaleProvider>
    </DeviceStateProvider>
  );

  return WP_DEFINE_IS_NODE ? (
    content
  ) : (
    <ScrollBehaviorContext
      routerProps={props}
      shouldUpdateScroll={shouldUpdateScroll}
      createScrollBehavior={config => new ScrollBehavior(config)}
    >
      {content}
    </ScrollBehaviorContext>
  );
};

Application.propTypes = {
  children: PropTypes.node.isRequired,
  hasGeneralError: PropTypes.bool,
};

export default Application;
