import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { multiFactorAuthenticationFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew, { ElementTypes, Colors } from 'components/atoms/TextNew';
import { InputNew } from 'components/atoms/Form';
import FormikError from 'components/atoms/FormikError';
import FormGroup from 'components/molecules/FormGroup';
import Row from 'components/atoms/Row';
import Label from 'components/atoms/Label';
import Button from 'components/atoms/Button';
import LocalStorageKeys from 'common/src/app/data/enum/BrowserStorageKeys';
import './multi-factor-authentication-form.scss';

const componentName = 'multi-factor-authentication-form';
class MultiFactorAuthenticationForm extends Component {
  componentDidMount() {
    localStorage.setItem(LocalStorageKeys.MFA, true);
  }

  render() {
    const {
      handleSubmit,
      handleBlur,
      handleChange,
      isSubmitting,
      errors,
      values,
      deviceState,
      isRegisterForm,
      qrCodeUrl,
      manualCode,
    } = this.props;
    const { getMessage } = this.context;

    return (
      <div {...componentClassNameProp(ComponentType.ORGANISM, this)}>
        {qrCodeUrl && manualCode && (
          <>
            <div className="qr-code-wrapper" data-testid={`${componentName}-qr-code-wrapper`}>
              <QRCode value={qrCodeUrl} size={deviceState < DeviceState.MD ? 128 : 256} />
            </div>

            <TextNew.Sans.SM
              dataTestid={`${componentName}-qr-manual-code`}
              localeId="multiFactorAuthenticationForm.description"
              localeParams={{
                MANUAL_CODE: (
                  <TextNew.Sans.SM element={ElementTypes.SPAN} color={Colors.BRAND}>
                    {manualCode}
                  </TextNew.Sans.SM>
                ),
              }}
            />
          </>
        )}

        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label
              text={getMessage('multiFactorAuthenticationForm.form.placeholder.code')}
              htmlFor={`${componentName}-${multiFactorAuthenticationFields.CODE}`}
            />
            <InputNew
              id={`${componentName}-${multiFactorAuthenticationFields.CODE}`}
              data-testid={`${componentName}-${multiFactorAuthenticationFields.CODE}-input`}
              disabled={isRegisterForm && !qrCodeUrl && !manualCode}
              value={values[multiFactorAuthenticationFields.CODE]}
              name={multiFactorAuthenticationFields.CODE}
              placeholder={getMessage('multiFactorAuthenticationForm.form.placeholder.code')}
              onBlur={handleBlur}
              onChange={handleChange}
              maxLength={6}
            />
            <FormikError
              dataTestid={`${componentName}-error`}
              errorName={getMessage('multiFactorAuthenticationForm.form.label.code')}
              name={multiFactorAuthenticationFields.CODE}
            />
          </FormGroup>
          <Row justifycenter>
            <Button
              ripple
              type="submit"
              dataTestid={`${componentName}-submit-button`}
              disabled={isSubmitting || (isRegisterForm && !qrCodeUrl && !manualCode)}
              isLoading={isSubmitting}
            >
              <LocaleMessage id="multiFactorAuthenticationForm.form.cta.submit" />
            </Button>
          </Row>
        </form>

        {errors?.id && (
          <TextNew.Error localeId={errors.id} dataTestid={`${componentName}-error-message`} />
        )}
      </div>
    );
  }
}

MultiFactorAuthenticationForm.defaultProps = {
  isRegisterForm: false,
};

MultiFactorAuthenticationForm.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

MultiFactorAuthenticationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  values: PropTypes.object,
  deviceState: PropTypes.number,
  qrCodeUrl: PropTypes.string,
  manualCode: PropTypes.string,
  isRegisterForm: PropTypes.bool,
};

export default withDeviceState()(MultiFactorAuthenticationForm);
