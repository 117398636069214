/* global 'molecule','BlockingFormError' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

/**
 * A full-width error block that shows up in a form when a user is not allowed to
 * continue submitting the form. This error will automatically show or hide based
 * on the `errorCode` and `fields` props. See propTypes in `./index.js` for more info
 *
 * Needs overflow-x: hidden on the consuming page template wrapper to stop scrollbar
 */
const BlockingFormError = ({ children, showError }, context, className) => (
  <div className={className}>{showError && children}</div>
);
BlockingFormError.propTypes = {
  /**
   * The contents to display in the error block. Will only display if `showError` is
   * true.
   */
  children: PropTypes.node,
  /**
   * A boolean indicating if the error should be shown. This is passed by the
   * connect wrapper based on the `errorCode` and `field` props
   */
  showError: PropTypes.bool,
};

export default withFunctionalClassName('molecule','BlockingFormError')(BlockingFormError);
