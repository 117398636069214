import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import MedicalCheck from 'bundle-loader?lazy&reactHot&name=MedicalCheck!components/templates/MedicalCheck';
import PersonalDetails from 'bundle-loader?lazy&reactHot&name=PersonalDetails!components/templates/PersonalDetails';
import GroupRegistration from 'bundle-loader?lazy&reactHot&name=GroupRegistration!./index';
import CardCheck from 'bundle-loader?lazy&reactHot&name=CardCheck!./components/templates/CardCheck';
import RegistrationError from 'bundle-loader?lazy&reactHot&name=RegistrationError!./components/molecules/RegistrationError';
import CardRetryError from 'bundle-loader?lazy&reactHot&name=CardRetryError!./components/molecules/CardRetryError';
import SuccessfullyRegisteredFree2Go from 'bundle-loader?lazy&reactHot&name=SuccessfullyRegisteredFree2Go!./components/molecules/SuccessfullyRegisteredFree2Go';
import QueryRouting from 'common/src/app/util/QueryRouting';
import StaticModalRoot from 'components/atoms/ModalRoot/StaticModalRoot';

const routes = (
  <>
    <Route
      path={Pages.GROUP_REGISTRATION}
      {...getRouteComponentProp(GroupRegistration)}
      isWizardForm
      title="Registration | {pageTitle}"
    >
      <Route
        path={Pages.GR_CARD_CHECK}
        {...getRouteComponentProp(CardCheck)}
        wizardFormOrder={0}
        title="Membership Card Check | Registration | {pageTitle}"
      />
      <Route
        path={Pages.GR_MEDICAL_CHECK}
        {...getRouteComponentProp(MedicalCheck)}
        wizardFormOrder={1}
        title="Medical Check | Registration | {pageTitle}"
      />
      <Route
        path={Pages.GR_PERSONAL_DETAILS}
        {...getRouteComponentProp(PersonalDetails)}
        wizardFormOrder={2}
        title="Personal Details | Registration | {pageTitle}"
        showSecurityQuestion
      />
    </Route>

    <QueryRouting query="modal">
      <Route component={StaticModalRoot}>
        <Route
          path={Pages.GR_REGISTRATION_ERROR}
          {...getRouteComponentProp(RegistrationError)}
          title="Registration"
        />
        <Route
          path={Pages.GR_CARD_TRIES_EXCEEDED}
          {...getRouteComponentProp(CardRetryError)}
          title="Membership card retry error"
        />
      </Route>
    </QueryRouting>

    <Route
      path={Pages.GR_SUCCESSFULLY_REGISTERED_FREE2GO}
      {...getRouteComponentProp(SuccessfullyRegisteredFree2Go)}
      title="Successfully registered Free2Go member"
    />
  </>
);

export default routes;
