export const staticNumberProps = {
  type: 'text',
  inputMode: 'numeric',
  pattern: '[0-9]*',
};

type NumberFieldFuncProps = typeof staticNumberProps & {
  onKeyPress: (event: KeyboardEvent) => void;
};

const numberFieldProps = (fieldType: string): NumberFieldFuncProps | Record<string, never> => {
  const isNumberInput = fieldType === 'number';
  const handleKeyPress = (event: KeyboardEvent) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  };

  return isNumberInput
    ? {
        ...staticNumberProps,
        onKeyPress: handleKeyPress,
      }
    : {};
};

export default numberFieldProps;
