/* global 'template','LegacyLogin' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import LegacyAppInfo from '../LegacyAppInfo';

/**
 * Legacy Login - a page where the user goes to when trying to log in with legacy credentials
 */

const LegacyLogin = ({ isWebView, appReferral }, context, className) => (
  <div className={className}>
    <TextNew.Loud localeId="legacyLogin.title" />
    {!isWebView ? (
      <>
        <TextNew.PrimaryOriginal localeId="legacyLogin.intro" />

        <Row justifycenter>
          <Button href={Configuration.legacyLoginUrl}>
            <LocaleMessage id="legacyLogin.cta" />
          </Button>
        </Row>
      </>
    ) : (
      <LegacyAppInfo referredFrom={appReferral} />
    )}
  </div>
);
LegacyLogin.propTypes = {
  isWebView: PropTypes.bool.isRequired,
  appReferral: PropTypes.string,
};

export default withFunctionalClassName('template','LegacyLogin')(LegacyLogin);
