import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { applyVoucher, APPLY_VOUCHER } from 'common/src/app/actions/resources/voucherActions';
import injectFormNameToProps from 'common/src/app/enhanced-redux-form/utils/injectFormNameToProps';
import { clearValidation } from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';

import Voucher from './Voucher';

type ReduxState = {
  form: {
    voucher: {
      values: {
        voucherCode: string;
        voucherCodeEdit: string;
      };
    };
  };
};

const mapStateToProps = (state: ReduxState, { form }: { form: string }) => {
  const loadingSelector = makeIsLoadingSelector(APPLY_VOUCHER);
  const valueSelector = formValueSelector(form);

  return {
    editValue: valueSelector(state, shopFields.VOUCHER_CODE_EDIT),
    currentVoucher: valueSelector(state, shopFields.VOUCHER_CODE),
    isLoading: loadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any, { form }: { form: string }) => ({
  applyVoucher: (value: string) => dispatch(applyVoucher(value)),
  updateVoucherFieldValue: (value: string) => {
    dispatch(change(form, shopFields.VOUCHER_CODE, value));
  },
  softReset: () => {
    dispatch(change(form, shopFields.VOUCHER_CODE, null));
    dispatch(clearValidation(form, [shopFields.VOUCHER_CODE_EDIT, shopFields.VOUCHER_CODE]));
  },
  hardReset: () => {
    dispatch(change(form, shopFields.VOUCHER_CODE, null));
    dispatch(change(form, shopFields.VOUCHER_CODE_EDIT, null));
    dispatch(clearValidation(form, [shopFields.VOUCHER_CODE_EDIT, shopFields.VOUCHER_CODE]));
  },
});

export default compose(
  injectFormNameToProps,
  connect(mapStateToProps, mapDispatchToProps),
)(Voucher);
