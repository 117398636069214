import React from 'react';
import PropTypes from 'prop-types';
import numberFieldProps from 'components/atoms/utils/numberFieldProps';
import AnimatedInput from './components/atoms/AnimatedInput';
import StaticInput from './components/atoms/StaticInput';

export const descriptorTypes = {
  ANIMATED: 'animated',
  STATIC: 'static',
};

const InputNew = ({ field, descriptor = descriptorTypes.ANIMATED, ...props }) => {
  const conditionalNumberProps = numberFieldProps(props.type);

  if (descriptor === descriptorTypes.ANIMATED) {
    return <AnimatedInput {...field} {...props} {...conditionalNumberProps} />;
  }

  return <StaticInput {...field} {...props} {...conditionalNumberProps} />;
};

descriptorTypes.PropTypes = Object.values(descriptorTypes);

InputNew.propTypes = {
  descriptor: PropTypes.oneOf(descriptorTypes.PropTypes),
  field: PropTypes.object,
  type: PropTypes.string,
};

export default InputNew;
