import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import { noValidPaymentResponse } from 'common/src/app/config/routeRequirements';
import Pages from 'common/src/app/data/enum/Pages';

import Payment from 'bundle-loader?lazy&reactHot&name=Payment!./index';
import Checkout from 'bundle-loader?lazy&reactHot&name=Checkout!./components/templates/Checkout';
import Finished from 'bundle-loader?lazy&reactHot&name=Finished!./components/templates/Finished';
import PaymentForm from 'bundle-loader?lazy&reactHot&name=PaymentForm!./components/templates/PaymentForm';

import { hasIdentityAndNoValidSubscription } from '../../../config/accountRouteRequirements';

const routes = (
  <Route path={Pages.PAYMENT} {...getRouteComponentProp(Payment)}>
    <IndexRedirect to={Pages.REGISTRATION_CHECKOUT} />
    <Route
      requirements={[noValidPaymentResponse, hasIdentityAndNoValidSubscription]}
      path={Pages.REGISTRATION_CHECKOUT}
      {...getRouteComponentProp(Checkout)}
      title="Checkout | Registration | {pageTitle}"
    />
    <Route
      path={Pages.REGISTRATION_PAYMENT}
      {...getRouteComponentProp(PaymentForm)}
      title="Payment | Registration | {pageTitle}"
    />
    <Route
      path={Pages.REGISTRATION_FINISHED}
      {...getRouteComponentProp(Finished)}
      title="Finished | Registration | {pageTitle}"
    />
  </Route>
);

export default routes;
