import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import WebHost from 'common/src/app/data/enum/WebHost';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import enhancedFormWizard from 'common/src/app/enhanced-redux-form/enhancedFormWizard';
import Pages from 'common/src/app/data/enum/Pages';
import { createAccount, getAccountState } from 'common/src/app/actions/resources/accountActions';
import { setRegistrationFlowType } from 'common/src/app/actions/registrationActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import { GROUP } from 'common/src/app/data/enum/registrationFlowTypes';
import ReactRouterWizardRoutingAdapter from 'common/src/app/enhanced-redux-form/enhancedFormWizard/ReactRouterWizardRoutingAdapter';
import { API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND } from 'common/src/app/data/apiComponents';
import RegistrationTemplate from 'components/organisms/RegistrationTemplate';
import { isFree2GoRegisteringMember } from 'common/src/app/selectors/free2GoSelector';
import { membershipCardFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { stripSpaces } from 'common/src/app/validation/validationUtils';

import GroupRegistration from './GroupRegistration';

const enhanced = enhancedFormWizard({
  name: FormNames.GROUP_REGISTRATION,
  routingAdapter: new ReactRouterWizardRoutingAdapter(),
  onSubmit: (dispatch, values) => {
    const newValues = {
      ...values,
      ...(values[membershipCardFields.CARD_NUMBER] && {
        [membershipCardFields.CARD_NUMBER]: stripSpaces(values[membershipCardFields.CARD_NUMBER]),
      }),
    };

    const processedValues = Object.keys(newValues).reduce((processed, field) => {
      const value = newValues[field];
      // flatten objects (created by FormSection)
      if (typeof value === 'object') {
        return {
          ...processed,
          ...value,
        };
      }
      processed[field] = value; // eslint-disable-line no-param-reassign
      return processed;
    }, {});

    const memberHost = () => (__, getState) =>
      getState().config.environmentConfig.web[WebHost.MEMBER].host;

    const isFree2GoRegistration = () => (__, getState) => isFree2GoRegisteringMember(getState());

    // Set the url if the member is a free to go registering member
    const redirectAfterAccountCreation =
      dispatch(isFree2GoRegistration()) === true
        ? Pages.GR_SUCCESSFULLY_REGISTERED_FREE2GO
        : `${dispatch(memberHost())}${Pages.PROFILE_BUILDER_GROUP}`;

    return dispatch(createAccount(processedValues, null, redirectAfterAccountCreation, true));
  },
  generalErrorMessage: { locale: 'groupRegistration.generalErrorMessage' },
});

const mapStateToProps = state => ({
  publicHost: state.config.environmentConfig.web.public.host,
  background: state.entities?.[COMPONENT]?.[API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND]?.image,
});

const mapDispatchToProps = dispatch => ({
  setFlowType: () => dispatch(setRegistrationFlowType(GROUP)),
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getAccountState()),
      dispatch(getComponent(API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND)),
      dispatch(prepareComponent(RegistrationTemplate)),
    ]),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(addInitAction, connected, enhanced)(GroupRegistration);
