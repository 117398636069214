import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import {
  registerFixedHeaderElement,
  unregisterFixedHeaderElement,
} from 'common/src/app/actions/scrollActions';
import { userAccountSelector } from 'common/src/app/selectors/userAccountSelectors';
import { headerToggleFixed } from 'common/src/app/actions/components/headerActions';
import OnboarderStatus from 'common/src/app/data/enum/OnboarderStatus';
import Header from './Header';

type ReduxState = {
  view: {
    components: {
      header: {
        isFixed: boolean;
        height: number;
      };
    };
  };
  onboarder: {
    home: {
      status: string;
    };
    loaded: boolean;
  };
};

const mapStateToProps = (state: ReduxState) => {
  const { isFixed = false, height } = state.view.components.header;
  const account = userAccountSelector(state);

  return {
    isFixed,
    height,
    emailConfirmed: account?.emailConfirmed,
    homeOnboarderPaused: state?.onboarder?.home?.status === OnboarderStatus.PAUSED,
    onboarderLoaded: state?.onboarder?.loaded ?? true,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<unknown>) => ({
  headerToggleFixed: (isFixed: boolean, height: number) =>
    dispatch(headerToggleFixed(isFixed, height)),
  register: (bottomY: number) => dispatch(registerFixedHeaderElement('Header', bottomY)),
  unregister: () => dispatch(unregisterFixedHeaderElement('Header')),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(Header);
