import React from 'react';
import PropTypes from 'prop-types';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Icon from '../Icon';
import LocaleMessage from '../LocaleMessage';

/**
 * Custom navigation buttons for the carousel
 */

const defaultLabel = direction =>
  direction === DirectionType.LEFT ? 'general.cta.previousSlide' : 'general.cta.nextSlide';

const NavigationButton = ({
  className,
  onClick,
  buttonLabel,
  onCustomClick,
  direction,
  iconName,
  iconWidth,
  disabled,
  square,
  uniqueId,
  ...props
}) => {
  // This ensures navigation buttons have unique IDs
  // if more than one carousel component is present on one page.
  // Otherwise this will fail the accessibility test for unique IDs
  const id = uniqueId
    ? `navigation-button-${uniqueId}-${direction}`
    : `navigation-button-${direction}`;

  return (
    <button
      className={className}
      data-testid={`navigation-button-${direction}`}
      id={id}
      type="button"
      disabled={disabled}
      onClick={() => (onCustomClick ? onCustomClick(props) : onClick())}
    >
      <div className="screen-reader">
        {buttonLabel || <LocaleMessage id={defaultLabel(direction)} />}
      </div>
      <Icon
        width={iconWidth}
        height={square ? iconWidth : undefined}
        name={iconName}
        direction={direction}
      />
    </button>
  );
};

NavigationButton.defaultProps = {
  iconWidth: 24,
  iconName: IconName.ARROW_NEW,
  disabled: false,
  square: false,
};

NavigationButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  square: PropTypes.bool,
  iconName: PropTypes.string,
  iconWidth: PropTypes.number,
  buttonLabel: PropTypes.node,
  uniqueId: PropTypes.string,
  // For custom click behavior
  onCustomClick: PropTypes.func,
};

export default NavigationButton;
