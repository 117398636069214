import { userProfileSelector } from '../selectors/userProfileSelectors';

// TODO TAT-658 Turn thesr functions into selectors
/**
 * Wizard step disable the start journey if member already has once
 */
export const isOnJourney = state => {
  const { profile } = state.entities;
  const currentJourney = profile && profile[Object.keys(profile)[0]].currentJourney;
  return !!currentJourney;
};

/**
 * Does the user selected a emotion?
 */
export const hasWeighInEmotion = getState => {
  const state = getState();
  const { savedWeighIn } = state.weighIn;
  return savedWeighIn && savedWeighIn.emotion !== null;
};

/**
 * Wizard step disable the pregnant weigh in final screen
 */
export const isNotPregnant = getState => {
  const state = getState();
  const profile = userProfileSelector(state);
  return profile && !profile.isPregnant;
};

/**
 * Check if user has a commitment/goal
 * @param getState
 */
export const hasCommitment = getState => !!getState().weighIn.savedWeighIn.commitment;

/**
 * Check if a form is submitted
 */
export const formIsSubmitted = (getState, formName) => {
  const { form } = getState();
  return form[formName] && form[formName].submitSucceeded;
};

/**
 * Has the user started a weigh-in?
 */
export const hasConfirmedWeighInWeight = getState => {
  const state = getState();
  const { savedWeighIn } = state.weighIn;
  const { isCurrentWeighInFinished } = userProfileSelector(state)?.weighInSituation;

  return savedWeighIn && isCurrentWeighInFinished !== null;
};
