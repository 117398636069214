module.exports = {
  "marketName": "UK",
  "marketNameTracking": "UK",
  "locale": "en-GB",
  "legacyNearestGroupSearchUrl": "http://www.slimmingworld.co.uk/joining-a-group/nearest-group.aspx?",
  "publicUrl": "http://www.slimmingworld.co.uk",
  "pregnancyCTAUrl": "https://www.slimmingworld.co.uk/mums",
  "supportEmail": "online.support@slimmingworld.co.uk",
  "currencyCode": "GBP",
  "digitalSupportTeamPhoneNumber": "0344 892 0430",
  "technicalMemberSupportPhoneNumber": "0344 892 0431",
  "defaultCountry": "GB",
  "defaultOfferCopy": true,
  "textBasedGroupSearch": true,
  "dropDownBasedGroupSearch": false,
  "voucherPhoneNumber": "0344 892 0400",
  "groupEnquiriesNumber": "0344 897 8000",
  "headOfficeNumber": "0344 892 0400",
  "hasCyprusGroups": true,
  "hasGeolocationGroupSearch": true,
  "homepageDefaultVideo": true,
  "magazineRoundel": true,
  "offerRoundels": false,
  "virtualGroups": true
};