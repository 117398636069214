import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { string, object } from 'yup';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import ContactUsFieldNames from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import ContactUsReferences from 'common/src/app/data/enum/ContactUsReferences';
import { setSubject } from 'common/src/app/actions/components/contactUsActions';
import { userAccountSelector } from 'common/src/app/selectors/userAccountSelectors';
import sendContact, { SEND_CONTACT } from 'common/src/app/actions/contactActions';
import { ContactFormSubjectIds } from 'common/src/app/data/enum/ContactFormCategory';
import { MemberTypeStrings } from 'common/src/app/data/enum/MemberType';
import ContactUsMemberForm from './ContactUsMemberForm';

const isLoadingSearchSelector = makeIsLoadingSelector(SEND_CONTACT);

const mapStateToProps = (state, { errorBoundaryData }) => {
  const hasErrorBoundary =
    errorBoundaryData && errorBoundaryData.location && errorBoundaryData.componentName;

  return {
    hasErrorBoundary,
    account: userAccountSelector(state),
    successMessageSubjectId: state.view?.components?.contactUs?.subjectId,
    loading: isLoadingSearchSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  setSucessMessage: subjectId => dispatch(setSubject(subjectId)),
  sendMessage: values => dispatch(sendContact(values)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const ContactUsMemberFormSchema = object().shape({
  [ContactUsFieldNames.MESSAGE]: string().required('contactUsForm.error.message'),
});

const EnhancedContactUsMemberForm = withFormik({
  mapPropsToValues: ({ hasErrorBoundary, userType }) => {
    let contactFormCategory = '';

    if (hasErrorBoundary) {
      if (userType === MemberTypeStrings.ONLINE) {
        contactFormCategory = ContactFormSubjectIds.ONLINE_TECHNICAL_HELP;
      } else {
        contactFormCategory = ContactFormSubjectIds.GROUP_TECHNICAL_HELP;
      }
    }

    return { contactFormCategory, icelandTerms: false, message: '' };
  },
  validationSchema: ContactUsMemberFormSchema,
  handleSubmit: async (values, { props, setSubmitting, setErrors }) => {
    const { setSucessMessage, sendMessage, account, errorBoundaryData, userType } = props;
    const { contactFormCategory } = values;

    // Add these data when using the errorBoundary flow
    let messageForSupport = '';
    if (errorBoundaryData) {
      const { location, componentName } = errorBoundaryData;
      if (location && componentName) {
        messageForSupport = messageForSupport.concat(
          `The component ${componentName} in ${location} has failed\n\r`,
        );
      }
    }

    try {
      await sendMessage({
        ...values,
        contactFormCategory: contactFormCategory.replace('id-', ''),
        email: account.email,
        profileId: account.id,
        postcode: account.zip,
        telephone: account.phoneNumber,
        name: `${account.firstName} ${account.lastName}`,
        message: messageForSupport.concat(values[ContactUsFieldNames.MESSAGE]),
        reference:
          userType === MemberTypeStrings.ONLINE
            ? ContactUsReferences.ONLINE
            : ContactUsReferences.GROUP,
      });
      await setSucessMessage(contactFormCategory);
      setSubmitting(false);
    } catch (error) {
      setErrors({ generalError: 'validation.errors.general' });
      setSubmitting(false);
    }
  },
})(ContactUsMemberForm);

export default compose(connected)(EnhancedContactUsMemberForm);
