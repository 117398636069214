import { connect } from 'react-redux';
import { userAccountSelector } from 'common/src/app/selectors/userAccountSelectors';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import MeasureType from 'common/src/app/data/enum/MeasureType';
import MeasurementCompositeInput from './MeasurementCompositeInput';

export default connect((state, ownProps) => {
  if (typeof ownProps.unit !== 'undefined') {
    // the unit is already passed, when using this with the toggle
    return {};
  }

  // this section should be replaced -- see ACB-258

  const accountInfo = userAccountSelector(state);

  // can be null if authentication didn't happen yet
  // or object with potential missing keys if account info is not done loading
  if (accountInfo) {
    if (ownProps.type === MeasureType.WEIGHT) {
      return {
        unit: accountInfo.weightUnit || MeasureUnit.IMPERIAL,
      };
    }
    if (ownProps.type === MeasureType.HEIGHT) {
      return {
        unit: accountInfo.heightUnit || MeasureUnit.IMPERIAL,
      };
    }
  }

  // default to imperial
  return {
    unit: MeasureUnit.IMPERIAL,
  };
})(MeasurementCompositeInput);
