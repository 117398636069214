import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import { connect } from 'react-redux';
import { closeModal, isOpenedModal } from 'common/src/app/actions/components/modalActions';
import ModalRoot from './ModalRoot';

class StaticModalRoot extends Component {
  componentDidMount() {
    this.props.isOpened(true);
  }

  componentWillUnmount() {
    this.props.isOpened(false);
  }

  render() {
    const { children, closeStaticModal, routes = [] } = this.props;
    const staticModal = Children.only(children);
    const modalProperties = [
      'showCloseButton',
      'isFullBleed',
      'enableCloseOnBackdrop',
      'alignLeft',
      'alignRight',
    ].reduce(
      (resultProps, propName) => {
        const routesWithProp = routes.filter(route => typeof route[propName] !== 'undefined');
        resultProps[propName] = routesWithProp.length // eslint-disable-line no-param-reassign
          ? routesWithProp[routesWithProp.length - 1][propName]
          : false;

        return resultProps;
      },
      { staticModal },
    );

    return <ModalRoot {...modalProperties} componentMap={{}} closeModal={closeStaticModal} />;
  }
}

StaticModalRoot.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.array,
  closeStaticModal: PropTypes.func.isRequired,
  /*
   * Add bool to state so we know when trigger behaviour based
   * on modal status
   */
  isOpened: PropTypes.func.isRequired,
};

export default connect(null, dispatch => ({
  closeStaticModal: () => dispatch(closeModal()),
  isOpened: opened => dispatch(isOpenedModal(opened)),
}))(StaticModalRoot);
