import React from 'react';

import ComponentType from 'common/src/app/data/enum/ComponentType';

import TextNew, { ElementTypes, FontFamily, FontSizes, FontWeight } from 'components/atoms/TextNew';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import formatValue from './utils/formatValue';

type PackageSummaryProps = {
  packageName?: string;
  amount: string;
  currency: string;
};

const PackageSummary = ({ packageName, amount, currency }: PackageSummaryProps) => (
  <aside data-testid="package-info" className="package-info">
    {packageName && (
      <TextNew
        dataTestid="package-title"
        family={FontFamily.SERIF}
        size={FontSizes.SM}
        cid="package-title"
        localeParams={{
          PACKAGE: packageName,
        }}
        localeId="packageSummary.package"
      />
    )}
    <p data-testid="package-total" className="total">
      <TextNew
        element={ElementTypes.SPAN}
        family={FontFamily.SANS}
        size={FontSizes.SM}
        localeId="packageSummary.total"
        weight={FontWeight.LIGHT}
      />

      <TextNew element={ElementTypes.SPAN} family={FontFamily.SANS} size={FontSizes.SM}>
        {formatValue(amount, currency)}
      </TextNew>
    </p>
  </aside>
);

export default withFunctionalClassName(ComponentType.MOLECULE, 'PackageSummary')(PackageSummary);
