import PasswordValidationConfig from './passwordValidationConfig';

export function isLessThanOrEqualToMaxLength(password: string, maxLength: number): boolean {
  return password.trim().length <= maxLength;
}

export function containsDigit(password: string) {
  return /\d/.test(password);
}

export function isGreaterThanOrEqualToMinLength(password: string, minLength: number): boolean {
  return password.trim().length >= minLength;
}

export function hasMinUniqueCharacters(password: string, minUniqueCharacters: number): boolean {
  return [...new Set(password)].length >= minUniqueCharacters;
}

export function containsLetter(password: string): boolean {
  return /[a-zA-Z]/.test(password);
}

export function containsLowerCase(password: string): boolean {
  return /[a-z]/.test(password);
}

export function containsUpperCase(password: string): boolean {
  return /[A-Z]/.test(password);
}

export function containsNonAlphanumeric(password: string): boolean {
  return /[^a-zA-Z\d]/.test(password);
}

/**
 * isPasswordValid validates a password based on a set of cofiguration values
 * that should be retreived from the account service to keep the server-side and
 * client-side validation logic aligned and returns whether the validation was successful or not
 */
export default function isPasswordValid(
  password: string,
  config: PasswordValidationConfig,
): boolean {
  const {
    maxLength,
    requireDigit,
    requiredLength,
    requiredUniqueCharacters,
    requireLetter,
    requireLowerCase,
    requireUppercase,
    requireNonAlphanumeric,
  } = config;

  if (!isGreaterThanOrEqualToMinLength(password, requiredLength)) {
    return false;
  }

  if (!hasMinUniqueCharacters(password, requiredUniqueCharacters)) {
    return false;
  }

  if (maxLength && !isLessThanOrEqualToMaxLength(password, maxLength)) {
    return false;
  }

  if (requireDigit && !containsDigit(password)) {
    return false;
  }

  if (requireLetter && !containsLetter(password)) {
    return false;
  }

  if (requireLowerCase && !containsLowerCase(password)) {
    return false;
  }

  if (requireUppercase && !containsUpperCase(password)) {
    return false;
  }

  if (requireNonAlphanumeric && !containsNonAlphanumeric(password)) {
    return false;
  }

  return true;
}
