import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import packagePropTypes from 'common/src/app/util/proptypes/packagePropTypes';
import TextNew, { FontWeight, ElementTypes } from 'components/atoms/TextNew';
import subscriptionPackageShape from 'common/src/app/util/proptypes/apiEntities/subscriptionPackage';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Row from 'components/atoms/Row';
import { IconChevronDown, IconChevronUp } from '@slimming-world/ui-library-components';
import { ColorRed400 } from '@slimming-world/ui-library-tokens';
import Button from '../../atoms/Button';
import SelectedPackageCost from '../SelectedPackageCost';
import SubscriptionComparisonTable from '../SubscriptionComparisonTable';
import Voucher from '../Voucher';
import PackageGift from '../PackageGift';

import './upgrade-package.scss';

const UpgradePackage = (
  { voucherGift, selectedPackage, onRegistrationRoute, packages, packageFeatures },
  context,
  className,
) => {
  const flowTypes = { REGISTRATION: 'registration' };
  const pageType = onRegistrationRoute ? flowTypes.REGISTRATION : flowTypes.TRANSFER_TO_ONLINE;

  const [showPackageSelection, setShowPackages] = useState(false);

  const buttonOnClick = () => {
    setShowPackages(!showPackageSelection);
  };

  return (
    <div className={className}>
      <Row className="package-summary">
        {voucherGift ? (
          <PackageGift {...voucherGift} />
        ) : (
          <SelectedPackageCost selectedPackage={selectedPackage} />
        )}
      </Row>

      {!voucherGift && (
        <>
          <TextNew.Sans.MD
            weight={FontWeight.LIGHT}
            localeId={`summary.${pageType}.orderSummary.description`}
          />
          <Button isGhostDark cid="view-packages" onClick={buttonOnClick}>
            <TextNew.Sans.SM
              element={ElementTypes.SPAN}
              localeId={
                !showPackageSelection
                  ? 'upgradePackage.viewPackages'
                  : 'upgradePackage.hidePackages'
              }
            />
            {!showPackageSelection ? (
              <IconChevronDown color={ColorRed400} className="chevron-down" />
            ) : (
              <IconChevronUp color={ColorRed400} className="chevron-up" />
            )}
          </Button>
        </>
      )}

      {/* Only show the subscription packages when:
      - The entered voucher is not a gift / free time
      - Packages are not null
      - The member is within the online registraiton flow */}
      {!voucherGift && packages && onRegistrationRoute && showPackageSelection && (
        <SubscriptionComparisonTable
          packages={Object.values(packages)}
          packageFeatures={packageFeatures}
        />
      )}

      {/* Only show the voucher entry when:
      - The member is within the online registraiton flow */}
      {onRegistrationRoute && <Voucher products={selectedPackage} />}
    </div>
  );
};

UpgradePackage.propTypes = {
  selectedPackage: packagePropTypes,
  voucherGift: PropTypes.object,
  onRegistrationRoute: PropTypes.bool.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape(subscriptionPackageShape)),
  packageFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      packageFeatureText: PropTypes.string,
      bronze: PropTypes.bool,
      silver: PropTypes.bool,
      gold: PropTypes.bool,
    }),
  ),
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'UpgradePackage')(UpgradePackage);
