const PagesExternal = {
  OUR_WORLD_LOGIN: 'https://ourworld.slimmingworld.co.uk/applications/login',
  HEALTH_PROFESSIONALS: 'https://www.slimmingworld.co.uk/health/',
  PRESS: 'https://www.slimmingworld.co.uk/press',
  ICELAND_BONUS_CARD: 'https://www.iceland.co.uk/bonus-card',
  MAGAZINE_SUB_PRINT:
    'https://www.selectmagazines.co.uk/product/slimming-world/?main_page=index&cPath=6_9_29',
  MAGAZINE_GOOGLE_PLAY:
    'https://play.google.com/store/apps/details?id=com.triactivemedia.slimmingworld',
  MAGAZINE_WINDOWS: 'https://www.microsoft.com/en-gb/p/slimming-world/9wzdncrdnwk4?rtc=1',
  MAGAZINE_POCKET_MAG:
    'http://www.pocketmags.com/viewmagazine.aspx?titleid=599&title=Slimming+World',
  MAGAZINE_AMAZON:
    'https://www.amazon.co.uk/Slimming-World/dp/B01N7L6QAG/ref=as_li_ss_tl?s=digital-text&ie=UTF8&qid=1508323439&sr=1-1&keywords=slimming+world+magazine&linkCode=sl1&tag=skinnyjimmy-21&linkId=d14b1da0d82f3db573452cffa78ef6f9',
  MAGAZINE_APPLE: 'https://itunes.apple.com/us/app/slimming-world-magazine/id455230600?ls=1&mt=8',
  PRODUCTION_IE: 'https://www.slimmingworld.ie',
  PRODUCTION_UK: 'https://www.slimmingworld.co.uk',
  ADVERTISING_ARTWORK: 'https://artwork.slimmingworld.co.uk/',
  ONE_YOU_CAMPAIGN: 'https://www.slimmingworld.co.uk/OneYou',
  INSTAGRAM: 'https://www.instagram.com/slimmingworld/',
  NEWSROOM: 'https://www.slimmingworld.co.uk/press/',
  CAREERS: 'https://careers.slimmingworld.co.uk/',
  MUMS: 'https://www.slimmingworld.co.uk/mums',
  GOLDEN_BUS: 'https://www.slimmingworld.co.uk/golden',
  NHS_MENTAL_HEALTH_SERVICES:
    'https://www.nhs.uk/using-the-nhs/nhs-services/mental-health-services/how-to-access-mental-health-services/',
  ONE_YOU_MENTAL_HEALTH: 'https://www.nhs.uk/oneyou/mental-health',
  MENTAL_HEALTH_UK: 'https://mentalhealth-uk.org/',
  MIND: 'https://www.mind.org.uk/',
  SAMARITANS: 'https://www.samaritans.org/',
  MENTAL_HEALTH_HELPLINE_FOR_WALES: 'http://callhelpline.org.uk/',
  MENTAL_HEALTH_WALES: 'https://www.mentalhealthwales.net/',
  NHS_INFORM_MENTAL_HEALTH: 'https://www.nhsinform.scot/illnesses-and-conditions/mental-health',
  BREATHING_SPACE: 'https://breathingspace.scot/',
  SCOTTISH_ASSOCIATION_FOR_MENTAL_HEALTH: 'https://www.samh.org.uk/',
  ACTION_MENTAL_HEALTH: 'https://www.amh.org.uk/',
  LIFELINE: 'https://www.lifelinehelpline.info/',
  MENTAL_HEALTH_IRELAND: 'https://www.mentalhealthireland.ie/',
  YOUR_MENTAL_HEALTH: 'https://www2.hse.ie/mental-health/',
  ICELAND: 'https://www.iceland.co.uk/slimming-world',
  DUNNES: 'https://www.dunnesstoresgrocery.com/slimming-world',
  LEGACY_GROUP_ANDROID: 'https://play.google.com/store/apps/details?id=com.slimmingworld.app',
  LEGACY_GROUP_IOS: 'https://apps.apple.com/gb/app/slimming-world/id414646757',
  MIND_YOUR_LANGUAGE: 'https://www.slimmingworld.co.uk/blog/mind-your-language/',
  HEALTH_LEGACY_URL: 'https://www.slimmingworld.co.uk/health/',
  CLEAR_YOUR_HEAD: 'https://clearyourhead.scot/',
  PUBLIC_HEALTH_WALES:
    'https://phw.nhs.wales/topics/latest-information-on-novel-coronavirus-covid-19/how-are-you-doing/',
  NI_DIRECT:
    'https://www.nidirect.gov.uk/articles/coronavirus-covid-19-taking-care-your-mental-health-and-wellbeing',
  TRUST_PILOT_TERMS: 'https://uk.legal.trustpilot.com/for-reviewers/end-user-terms-and-conditions',
  SW_KITCHEN: 'https://slimmingworldkitchen.co.uk/',
};

export default PagesExternal;
