import handleActions from 'redux-actions/lib/handleActions';
import {
  GET_CONFIRM_EMAIL,
  GET_CONFIRM_EMAIL_CHANGE,
} from 'common/src/app/actions/resources/accountActions';

const initialState = {
  error: null,
};

const NO_ERROR = { code: 'no-error' };

export default handleActions(
  {
    [GET_CONFIRM_EMAIL]: (state, { payload: { response }, meta }) => {
      if (meta?.isFulfilled) {
        return {
          ...state,
          error: response?.parsed.error || NO_ERROR,
        };
      }
      return state;
    },
    [GET_CONFIRM_EMAIL_CHANGE]: (state, { payload: { response }, meta }) => {
      if (meta && meta.isFulfilled) {
        return {
          ...state,
          error: response?.parsed.error || NO_ERROR,
        };
      }
      return state;
    },
  },
  initialState,
);
