// Packages
import React from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';
import ComponentType from 'common/src/app/data/enum/ComponentType';

// Utils
import abbreviate from 'common/src/app/util/locale/abbreviate';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

// Enums
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';

// Components
import FormGroup from 'components/molecules/FormGroup';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import Toggle from 'components/atoms/Toggle';
import HeightMeasurementUnit from 'components/molecules/HeightMeasurementUnit';
import InformationToggle from 'components/molecules/InformationToggle';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import FormValueProvider from 'common/src/app/enhanced-redux-form/components/FormValueProvider';

import './height-input.scss';

/**
 * Height input, including InformationToggle and Unit selector
 * Membertype - the type of member you are displaying this message to e.g. 'group' or 'online
 * Used to switch out BMI information message
 */
const HeightInput = (
  { memberType, deviceState, viewSettings, forwardRef, ...props },
  {},
  className,
) => {
  const localeMsg = abbreviate(deviceState);

  return (
    <FormGroup className={className} forwardRef={forwardRef}>
      {viewSettings.measurementUnit && (
        <Row aligncenter spacebetween>
          {viewSettings.fieldLabel && (
            <legend className="atom-label">
              <LocaleMessage id="medicalCheck.groupLabels.height" />
            </legend>
          )}
          <EnhancedField
            name={measurementFields.HEIGHT_UNIT}
            component={Toggle}
            legend={<LocaleMessage id="heightInput.legend" />}
            leftlabel={<LocaleMessage id={`general.measureUnit.height.feet.${localeMsg}`} />}
            leftname={MeasureUnit.IMPERIAL}
            rightlabel={<LocaleMessage id={`general.measureUnit.height.metres.${localeMsg}`} />}
            rightname={MeasureUnit.METRIC}
          />
        </Row>
      )}

      <FormValueProvider fields={[measurementFields.HEIGHT_UNIT]}>
        <HeightMeasurementUnit {...props} />
      </FormValueProvider>

      <FormErrorMessages fields={[measurementFields.HEIGHT]} />

      {viewSettings.informationToggle && (
        <InformationToggle
          label={<LocaleMessage id={`medicalCheck.weightHeight.informationToggleLabel`} />}
          information={
            <LocaleMessage id={`medicalCheck.weightHeight.information${upperFirst(memberType)}`} />
          }
        />
      )}
    </FormGroup>
  );
};

HeightInput.propTypes = {
  deviceState: PropTypes.number.isRequired,
  memberType: PropTypes.string,
  viewSettings: PropTypes.object,
  forwardRef: PropTypes.func,
};

HeightInput.defaultProps = {
  viewSettings: {
    informationToggle: true,
    measurementUnit: true,
    fieldLabel: true,
  },
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'HeightInput')(HeightInput),
);
