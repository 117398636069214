import React from 'react';
import LocaleMessage from '../../../../../../LocaleMessage';

type HeightInputLabelProps = {
  heightUnit: string;
  abbreviate: boolean;
};

const HeightInputLabel = ({ heightUnit, abbreviate }: HeightInputLabelProps): JSX.Element => {
  const id = `general.measureUnit.height.${heightUnit}.${
    abbreviate ? 'labelAbbreviation' : 'label'
  }`;
  return <LocaleMessage id={id} />;
};

export default HeightInputLabel;
