/* global 'page','AccountLogin' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import Pages from 'common/src/app/data/enum/Pages';

//  Layout
import Wrapper from 'components/atoms/Wrapper';
import Row from 'components/atoms/Row';
import SlimmingWorldLogo from 'components/atoms/SlimmingWorldLogo';
import Image from 'components/atoms/Image';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import './account-login.scss';

const AccountLogin = (
  { children, location, accountHost, background, isFree2GoApp },
  {},
  className,
) => (
  <div className={className}>
    <Image isBackgroundImage isLazy={false} {...background}>
      <Wrapper width="sm">
        <Row column className="main-content">
          <div className="main-content-wrapper">
            {React.cloneElement(children, { key: location.pathname })}
            <Row justifycenter className="main-content-footer">
              {!isFree2GoApp ? (
                <SlimmingWorldLogo href={accountHost} />
              ) : (
                <Icon name={IconName.FAMILY_AFFAIR_LOGO} width={150} />
              )}
            </Row>
          </div>
        </Row>
      </Wrapper>
    </Image>
  </div>
);

AccountLogin.propTypes = {
  background: ImagePropTypes,
  children: PropTypes.node,
  location: PropTypes.objectOf(PropTypes.any),
  accountHost: PropTypes.string,
  isWebView: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  isFree2GoApp: PropTypes.bool,
};

export default withFunctionalClassName('page','AccountLogin', [
  {
    'no-scroll': ({ location }) => location.pathname === Pages.LOGOUT,
  },
  'isWebView',
])(AccountLogin);
