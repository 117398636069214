import { useCallback, useState } from 'react';
import AdyenConfig, {
  AdyenConfigWithEventHandlers,
} from 'common/src/app/adyen/types/AdyenConfig.d';
import adyenSetError, { AdyenCheckoutErrorExtended } from '../adyenSetError';

/* Full list of resultCodes -
https://docs.adyen.com/development-resources/testing/result-code-testing/adyen-response-codes/
 currently any code aside from the two in the enum show the error message */
// eslint-disable-next-line no-shadow
enum ResultCodeEnum {
  Authorised = 'Authorised',
  Received = 'Received',
}
type useExtendAdyenConfigConfigReturn = {
  extendConfig: AdyenConfigWithEventHandlers | undefined;
  error: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  paymentStatus: string | undefined;
  processingPayment: boolean;
  retrigger: boolean;
  unmount: boolean;
};

type useExtendAdyenConfigConfigProps = {
  config: AdyenConfig | undefined;
  paymentSuccessRedirect: (resultCode: string) => void;
  paymentResponse?: (purchaseId: string | null, resultCode: string) => void;
};

/**
 * Hooks which is used to extend the configuration we get from the backend to
 * pass into adyen drop in payment component. It handles various payment-related events such
 * as payment completion and error handling
 * and errors.
 */
const useExtendAdyenConfigConfig = ({
  config,
  paymentSuccessRedirect,
  paymentResponse,
}: useExtendAdyenConfigConfigProps): useExtendAdyenConfigConfigReturn => {
  const [paymentStatus, setPaymentStatus] = useState<string>();
  const [retrigger, setRetrigger] = useState<boolean>(false);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [unmount, setUnmount] = useState<boolean>(false);

  const updateError = useCallback(() => {
    setPaymentStatus(undefined);
    setError(undefined);
  }, []);

  if (!config)
    return {
      extendConfig: undefined,
      error,
      setError,
      paymentStatus,
      processingPayment,
      retrigger,
      unmount,
    };

  const extendConfig = {
    clientKey: config?.clientKey,
    environment: config?.environment,
    locale: config?.locale,
    showPayButton: true,
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: 'Credit or debit card',
        amount: config?.paymentMethodsConfiguration.card.amount,
      },
      googlepay: {
        buttonType: 'pay',
      },
      applepay: {
        buttonType: 'pay',
      },
    },
    session: {
      sessionData: config?.session.sessionData,
      id: config?.session.id,
      amount: config?.session.amount,
    },
    onPaymentCompleted: async (result: { resultCode: string; sessionData?: string }) => {
      const { resultCode } = result;
      if (resultCode === ResultCodeEnum.Authorised || resultCode === ResultCodeEnum.Received) {
        setProcessingPayment(true);
        // call the backend to grant temporary access in case of delays
        // in adyen calling the backend back with the payment result
        paymentResponse && (await paymentResponse(config?.session.reference || '', resultCode));
        // unmount the adyen checkout instance to avoid errors before navigating
        // to the finished screen
        setUnmount(true);
        return paymentSuccessRedirect(resultCode);
      }
      // resultCode is an error, clean the adyen prebuilt component to ensure
      // validation triggers as expected
      await setRetrigger(true);
      /* Retrigger an update and switch it back to false in case of addtional
       incorrect attempts */
      setRetrigger(false);
      // set the payment error to display to the user
      return setPaymentStatus(result.resultCode);
    },
    onError: (paymentError: AdyenCheckoutErrorExtended) => {
      adyenSetError(paymentError, setError);
    },
    beforeSubmit: (
      state: unknown,
      element: unknown,
      actions: { resolve: (paymentData?: unknown) => Promise<void> },
    ) => {
      // when user is trying again clear any prior errors
      updateError();
      return actions?.resolve(state);
    },
  };
  return {
    extendConfig,
    error,
    setError,
    paymentStatus,
    processingPayment,
    retrigger,
    unmount,
  };
};

export default useExtendAdyenConfigConfig;
