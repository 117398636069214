import createAction from 'redux-actions/lib/createAction';

export const SET_ENVIRONMENT_CONFIG = 'renderActions.SET_ENVIRONMENT_CONFIG';
export const SET_VIEWER_COUNTRY = 'renderActions.SET_VIEWER_COUNTRY';
export const ENABLE_PERFORMANCE_MEASUREMENTS = 'configActions/ENABLE_PERFORMANCE_MEASUREMENTS';
export const SET_WEBVIEW_COOKIE = 'configActions/SET_WEBVIEW_COOKIE';
export const SET_FREE_2_GO = 'configActions/SET_FREE_2_GO';
export const SET_RECAPTCHA_TOKEN = 'configActions/SET_RECAPTCHA_TOKEN';
export const SET_RECAPTCHA_STATUS = 'configActions/SET_RECAPTCHA_STATUS';
export const SET_IS_IN_APP_BROWSER = 'configActions/SET_IS_IN_APP_BROWSER';

export const enablePerformanceMeasurements = createAction(ENABLE_PERFORMANCE_MEASUREMENTS);
export const setEnvironmentConfig = createAction(SET_ENVIRONMENT_CONFIG);
export const setViewerCountry = createAction(SET_VIEWER_COUNTRY);
export const setWebViewCookie = createAction(SET_WEBVIEW_COOKIE);
export const setFree2GoApp = createAction(SET_FREE_2_GO);
export const setRecaptchaToken = createAction(SET_RECAPTCHA_TOKEN);
export const setRecaptchaStatus = createAction(SET_RECAPTCHA_STATUS);
export const setIsInAppBrowser = createAction(SET_IS_IN_APP_BROWSER);
