import React from 'react';
import PropTypes from 'prop-types';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import WeightInputImperial from './components/WeightInputImperial';
import WeightInputMetric from './components/WeightInputMetric';
import WeightInputValidation from './components/WeightInputValidation';
import WeightInputUnitToggle from './components/WeightInputUnitToggle';

import './weight-input.scss';

const WeightInput = (
  { toggleWeightUnit, weightUnit, legend, showValidationErrors, onToggleChange, ...restProps },
  {},
  className,
  dataTestid,
) => (
  <fieldset className={className} data-testid={dataTestid}>
    {toggleWeightUnit && (
      <WeightInputUnitToggle
        weightUnit={weightUnit}
        {...restProps}
        onChange={onToggleChange || restProps.onChange}
      />
    )}
    <legend className="screen-reader">{legend}</legend>
    <div className="weight-inputs">
      {weightUnit === MeasureUnit.METRIC ? (
        <WeightInputMetric {...restProps} />
      ) : (
        <WeightInputImperial {...restProps} />
      )}
    </div>
    {showValidationErrors && <WeightInputValidation weightUnit={weightUnit} />}
  </fieldset>
);

WeightInput.defaultProps = {
  toggleWeightUnit: false,
  showValidationErrors: true,
};

WeightInput.propTypes = {
  legend: PropTypes.string,
  toggleWeightUnit: PropTypes.bool,
  weightUnit: PropTypes.oneOf([MeasureUnit.IMPERIAL, MeasureUnit.METRIC]),
  onToggleChange: PropTypes.func,
  showValidationErrors: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'WeightInput')(WeightInput);
