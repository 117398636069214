interface ErrorObject {
  response: {
    status: number;
  };
  request: {
    url: string;
    method: string;
  };
}

type DisallowListType = Record<string, '*' | number[]>;

interface IgnoreErrorInput {
  error: ErrorObject;
  disallowList: DisallowListType;
  errorEndpointsToLog: string[];
}

const ignoreXhrError = ({
  error,
  disallowList,
  errorEndpointsToLog,
}: IgnoreErrorInput): boolean => {
  const isPutOrPost = ['PUT', 'POST'].includes(error.request.method);
  if (
    isPutOrPost &&
    error.response.status === 400 &&
    errorEndpointsToLog.some(elem => error.request.url.includes(elem))
  ) {
    return false;
  }
  return (
    (error.response.status >= 400 && error.response.status < 500) ||
    Object.keys(disallowList).some(key => {
      const value = disallowList[key];
      if (value === '*') {
        return error.request.url.includes(key);
      }
      if (Array.isArray(value)) {
        return error.request.url.includes(key) && value.includes(error.response.status);
      }
      return false;
    })
  );
};

export default ignoreXhrError;
