import React from 'react';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import LocaleMessage from 'components/atoms/LocaleMessage';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import abbreviate from 'common/src/app/util/locale/abbreviate';
import './height-input-unit-toggle.scss';

type HeightInputUnitToggleProps = {
  heightUnit: string;
  deviceState: number;
  onClick: () => void;
  onChange: () => void;
};

const HeightInputUnitToggle = ({
  onClick,
  heightUnit,
  deviceState,
  onChange,
}: HeightInputUnitToggleProps): JSX.Element => {
  const imperialValue = `${MeasureUnit.IMPERIAL}`;
  const metricValue = `${MeasureUnit.METRIC}`;

  return (
    <div className="atom-height-input-unit-toggle">
      <input
        name={measurementFields.HEIGHT_UNIT}
        id="imperial"
        type="radio"
        checked={heightUnit === imperialValue}
        value={imperialValue}
        onChange={onChange}
        data-testid="height-unit-imperial"
      />
      <label htmlFor="imperial">
        <LocaleMessage id={`general.measureUnit.height.feet.${abbreviate(deviceState)}`} />
      </label>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
      <a tabIndex={-1} className="toggle-slider" onClick={onClick}>
        <div
          className={`toggle-ball ${
            heightUnit === imperialValue ? DirectionType.LEFT : DirectionType.RIGHT
          }`}
        />
      </a>
      <input
        name={measurementFields.HEIGHT_UNIT}
        id="metric"
        type="radio"
        value={metricValue}
        checked={heightUnit === metricValue}
        onChange={onChange}
        data-testid="height-unit-metric"
      />
      <label htmlFor="metric">
        <LocaleMessage id={`general.measureUnit.height.metres.${abbreviate(deviceState)}`} />
      </label>
    </div>
  );
};

export default withDeviceState()(HeightInputUnitToggle);
