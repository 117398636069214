import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getVariantUrl } from 'common/src/app/util/imageSizeUtils';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import debugLib from 'debug';
import debounce from 'lodash/debounce';
import './background-image-wrapper.scss';

const DEBOUNCE_TIMEOUT = 250;
const IMAGE_THRESHOLD = 160;
const MAX_IMAGE_WIDTH = 2000;
const debug = debugLib('SlimmingWorld:BackgroundImage');
/**
 * Wrapper with image background scaled to size of component
 *
 * Takes either a url, string or object to display a responsive background image
 */
class BackgroundImageWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      backgroundWidth: 0,
      backgroundRatio: 0,
      imageQualityRatio: 1,
    };
    this.container = null;
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkImageRatio);
    this.checkImageRatio();
  }

  componentDidUpdate() {
    this.checkImageRatio();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkImageRatio);
  }

  getBackgroundImage = (image, setWidth) => {
    if (image && typeof image === 'string') {
      return image;
    }
    // If we have a set width container that is not the width of the viewport
    // get that size
    if (image && image.src && setWidth) {
      return getVariantUrl({
        url: image.src,
        width: setWidth,
        ratio: this.state.backgroundRatio,
        useWebp: this.props.hasWebpSupport || image.useWebp,
      });
    }
    if (image && image.src && this.state.backgroundWidth > 0) {
      return getVariantUrl({
        url: image.src,
        width: this.state.backgroundWidth,
        ratio: this.state.backgroundRatio,
        useWebp: this.props.hasWebpSupport || image.useWebp,
      });
    }
    if (!image) {
      debug(`please pass either image to BackgroundImageWrapper; We've got ${image}`);
    }
    return null;
  };

  checkImageRatio = debounce(() => {
    if (!this.container) return;

    const {
      container: { clientWidth, clientHeight },
      state: { imageQualityRatio },
    } = this;

    const roundedWidth = Math.min(
      this.props.useThreshold
        ? Math.ceil(clientWidth / IMAGE_THRESHOLD) * IMAGE_THRESHOLD
        : clientWidth,
      MAX_IMAGE_WIDTH,
    );

    const roundedHeight = this.props.useThreshold
      ? Math.ceil(clientHeight / IMAGE_THRESHOLD) * IMAGE_THRESHOLD
      : clientHeight;

    this.setState({
      backgroundWidth: roundedWidth * imageQualityRatio,
      backgroundRatio: roundedWidth / roundedHeight,
    });
  }, DEBOUNCE_TIMEOUT);

  render() {
    const { children, image, dummy, setWidth } = this.props;

    const background = image ? this.getBackgroundImage(image, setWidth) : dummy;

    return (
      <div
        {...componentClassNameProp(ComponentType.ATOM, this, [({ className }) => className])}
        style={{ backgroundImage: background && `url(${background})` }}
        ref={container => {
          this.container = container;
        }}
      >
        {children}
      </div>
    );
  }
}

BackgroundImageWrapper.defaultProps = {
  useThreshold: true,
};

BackgroundImageWrapper.propTypes = {
  /**
   * Image object
   */
  image: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  dummy: PropTypes.string,
  children: PropTypes.node,
  useThreshold: PropTypes.bool,
  setWidth: PropTypes.number,
  hasWebpSupport: PropTypes.bool,
};

export default BackgroundImageWrapper;
