import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import SubscriptionPackages from 'common/src/app/enums/SubscriptionPackages';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import subscriptionPackageShape from 'common/src/app/util/proptypes/apiEntities/subscriptionPackage';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import classNames from 'classnames';

import TextNew from 'components/atoms/TextNew';
import RadioButtonGroup from '../RadioButtonGroup';
import SubscriptionPackage from '../SubscriptionPackage';
import PackageIndicator from '../../atoms/PackageIndicator';

import './subscription-comparison-table.scss';

const SubscriptionComparisonTable = (
  { packages, packageFeatures, limboCheckout },
  context,
  className,
) => (
  <div className={classNames(className, { 'limbo-checkout': limboCheckout })}>
    <div className="comparison-table">
      <table className="table-content">
        <thead>
          <tr>
            <th className="feature-name-header"></th>
            <th className="feature-item-header" colSpan="3">
              <EnhancedField component={RadioButtonGroup} name={shopFields.PACKAGE}>
                {packages?.map(subscriptionPackage => (
                  <SubscriptionPackage {...subscriptionPackage} key={subscriptionPackage.id} />
                ))}
              </EnhancedField>
            </th>
          </tr>
        </thead>
        <tbody>
          {packageFeatures?.map((values, index) => {
            const { packageFeatureText, bronze, silver, gold } = values;
            // Return null if we have no package feature text
            if (!packageFeatureText) {
              return null;
            }
            return (
              <tr key={`package-feature-row-${index}`}>
                <td className="feature-name">
                  <TextNew.Sans.XS>{packageFeatureText}</TextNew.Sans.XS>
                </td>
                <td className="feature-item">
                  {bronze && <PackageIndicator subscriptionPackage={SubscriptionPackages.Bronze} />}
                </td>
                <td className="feature-item">
                  {silver && <PackageIndicator subscriptionPackage={SubscriptionPackages.Silver} />}
                </td>
                <td className="feature-item">
                  {gold && <PackageIndicator subscriptionPackage={SubscriptionPackages.Gold} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
);

SubscriptionComparisonTable.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.shape(subscriptionPackageShape)).isRequired,
  limboCheckout: PropTypes.bool,
  packageFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      packageFeatureText: PropTypes.string,
      bronze: PropTypes.bool,
      silver: PropTypes.bool,
      gold: PropTypes.bool,
    }),
  ),
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'SubscriptionComparisonTable',
)(SubscriptionComparisonTable);
