import {
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION_COUNT,
} from '../actions/resources/notificationActions';
import { handleActionsAsync } from '../util/asyncReducer';

const initialState = {
  totalRead: 0,
  totalUnread: 0,
};

const notificationsReducer = handleActionsAsync(
  {
    [GET_NOTIFICATIONS]: {
      resolved: (
        state,
        {
          payload: {
            data: { totalRead, totalUnread },
          },
        },
      ) => ({
        ...state,
        totalRead,
        totalUnread,
      }),
    },
    [UPDATE_NOTIFICATION_COUNT]: (state, { payload: { totalRead, totalUnread } }) => ({
      ...state,
      totalRead,
      totalUnread,
    }),
  },
  initialState,
);

export default notificationsReducer;
