import { connect } from 'react-redux';
import free2GoAppReleasedEnum from 'common/src/app/data/free2GoAppReleasedEnum';

import Free2GoLanding from './Free2GoLanding';

const mapStateToProps = state => ({
  androidAppUrl: state.config.environmentConfig.free2GoApp?.androidAppUrl,
  iosAppUrl: state.config.environmentConfig.free2GoApp?.iosAppUrl,
  free2GoAppIsReleased:
    state.config.environmentConfig.free2GoApp?.isReleased === free2GoAppReleasedEnum.RELEASED,
});

export default connect(mapStateToProps)(Free2GoLanding);
