import React, { useEffect } from 'react';
import TextNew, { TextTypes } from 'components/atoms/TextNew';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import LocaleMessage from 'components/atoms/LocaleMessage';
import {
  getLocaleMessagePrefix,
  ValidationProps,
} from 'common/src/app/util/form/emailConfirmValidation';
import errorBoundary from 'components/hoc/ErrorBoundary';
import { ONLY_NUMERIC } from 'common/src/app/data/regexPatterns';
import { compose } from 'redux';
import Loader from 'components/atoms/Loader';

import './email-verification-result.scss';

export type EmailVerificationResultProps = {
  memberHost: string;
  validationError?: ValidationProps;
  location: {
    query: {
      userId?: string;
    };
    hash?: string;
  };
  getConfirmEmail: (userId: string, token: string) => void;
};

/**
 * Renders the email verification result content - used in scenarios where a user is
 * confirming their email after signing up or changing it in the account settings.
 */
const EmailVerificationResult = (
  { location, getConfirmEmail, memberHost, validationError }: EmailVerificationResultProps,
  {},
  className: string,
): JSX.Element => {
  useEffect(() => {
    // Call getConfirmEmail function when the component mounts or when the location changes.
    const userId = location.query.userId || '';
    const token = (location.hash || '#').substr(1);

    if (ONLY_NUMERIC.test(userId)) {
      getConfirmEmail(userId, token);
    }
  }, [location, getConfirmEmail]);

  const copy = validationError && getLocaleMessagePrefix(validationError);

  return (
    <div className={className}>
      {!validationError ? (
        <Loader />
      ) : (
        <>
          <TextNew type={TextTypes.LOUD} localeId={`${copy}.title`} />
          <TextNew type={TextTypes.PRIMARY_ORIGINAL} localeId={`${copy}.subTitle`} />
          <TextNew type={TextTypes.FORMAL}>
            <a className="text-link" href={memberHost}>
              <LocaleMessage id="emailVerificationResult.linkLabel" />
            </a>
          </TextNew>
        </>
      )}
    </div>
  );
};

type EmailVerificationResultType = (props: EmailVerificationResultProps) => JSX.Element;

export default compose<EmailVerificationResultType>(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.ORGANISM, 'EmailVerificationResult'),
)(EmailVerificationResult);
