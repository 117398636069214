import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import TextNew, { TextTypes, Colors } from 'components/atoms/TextNew';

import './adyen-payment-error.scss';

type AdyenPaymentErrorProps = {
  paymentStatus: string;
  error: 'generalError' | 'sessionError' | 'invalidRecaptchaToken' | 'cancelError';
  link: string;
  authFailed: boolean;
};

const AdyenErrorMessage = ({ error }: { error: string }): JSX.Element => (
  <TextNew
    dataTestid="payment-error"
    type={TextTypes.ERROR}
    color={Colors.BRAND}
    localeId={`adyenPaymentError.description.${error}`}
  />
);

const AdyenPaymentError = (
  { paymentStatus, error, link, authFailed }: AdyenPaymentErrorProps,
  {},
  className: string,
): JSX.Element => (
  <div role="alert" className={className}>
    {/* Hiding the error when a member closes the digital wallet ui as not a genuine error */}
    {error && error !== 'cancelError' ? <AdyenErrorMessage error={error} /> : null}
    {paymentStatus ? <AdyenErrorMessage error="paymentError" /> : null}

    {authFailed && (
      <Button
        onClick={() => (window.location.href = window.location.origin + window.location.pathname)}
      >
        <LocaleMessage id="adyenPaymentError.button" />
      </Button>
    )}

    {authFailed === false && error && error === 'sessionError' && (
      <Button onClick={() => null} to={link}>
        <LocaleMessage id="adyenPaymentError.button" />
      </Button>
    )}

    {authFailed === false && error && error === 'invalidRecaptchaToken' && (
      <Button onClick={() => window.location.reload()} to={null}>
        <LocaleMessage id="adyenPaymentError.button" />
      </Button>
    )}
  </div>
);

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'AdyenPaymentError',
)(AdyenPaymentError);
