import { createSelector } from 'reselect';

const registrationFlowTypeSelector = createSelector(
  state => state.registration?.flow,
  flowType => flowType,
);

export const isOnRegistrationFlow = (state, targetFlow) =>
  createSelector(
    [() => registrationFlowTypeSelector(state)],
    currentFlow => currentFlow === targetFlow,
  );

export default registrationFlowTypeSelector;
