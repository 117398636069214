/* global 'molecule','HandleErrorComponent' */
import React from 'react';
import PropTypes from 'prop-types';
import WebHostLink from 'components/atoms/WebHostLink';
import WebHost from 'common/src/app/data/enum/WebHost';
import { createPath } from 'common/src/app/util/routeUtils';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import TextNew, { Colors, TextTypes } from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import './handle-error-component.scss';

const HandleErrorComponent = ({ title, wholePageError }, context, className) => (
  <div data-testid="handle-error-component" className={className}>
    <div className="content-emotion">
      <TextNew.Script.MD
        cid="formal-original-margin"
        color={Colors.BRAND}
        localeId="handleErrorComponent.title"
      />
    </div>
    <div className="content-text">
      <TextNew.PrimaryOriginal
        cid="formal-original-margin"
        localeId="handleErrorComponent.descriptionError"
        localeParams={{
          TITLE: title,
          COMPONENT_TYPE: (
            <LocaleMessage
              id={`handleErrorComponent.errorLevel.${wholePageError ? 'site' : 'page'}`}
            />
          ),
        }}
      />

      <TextNew
        localeId="handleErrorComponent.keepExperiencingProblems"
        localeParams={{
          CONTACT_US: () => (
            <WebHostLink webHost={WebHost.MEMBER} link={createPath(Pages.CONTACT_US)}>
              <LocaleMessage id="handleErrorComponent.contactUs" />
            </WebHostLink>
          ),
        }}
        type={TextTypes.PRIMARY_ORIGINAL}
      />
    </div>
  </div>
);

HandleErrorComponent.propTypes = {
  title: PropTypes.node.isRequired,
  wholePageError: PropTypes.bool,
};

export default withFunctionalClassName('molecule','HandleErrorComponent', ['isColumn'])(HandleErrorComponent);
