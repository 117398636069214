import { LOCATION_CHANGE } from 'react-router-redux/lib/reducer';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { handleActionsAsync } from '../util/asyncReducer';
import groupOrOnlineSelector from '../selectors/accountStateSelectors';
import DataLayerKeys from '../data/enum/Tracking/DataLayerKeys';
import UserOrigin from '../data/enum/UserOrigin';
import { GET_ACCOUNT, GET_ACCOUNT_ORIGIN } from '../actions/resources/accountActions';
import { SET_AUTH_TOKENS } from '../actions/authenticationActions';
import {
  SET_TRACKING_PERSISTENT_DATA,
  SET_TRACKING_PAGE_DATA,
  PAGE_LOAD_COMPLETE,
} from '../actions/trackingActions';

export default handleActionsAsync(
  {
    [SET_TRACKING_PERSISTENT_DATA]: (state, { payload: { persistentData, merge } }) => ({
      ...state,
      persistentData: merge
        ? {
            ...state.persistentData,
            ...persistentData,
          }
        : persistentData,
    }),
    [SET_AUTH_TOKENS]: (state, { payload: { idToken } }) => {
      // Member id is an alias for PII data reasons. ua => UserAlias
      const memberId = jwtDecode(idToken)?.aua;
      const roles = jwtDecode(idToken)?.role;

      return {
        ...state,
        persistentData: {
          ...state.persistentData,
          ...{
            [DataLayerKeys.MEMBER_ID]: memberId,
            [DataLayerKeys.ROLE]: roles && roles.toString(),
          },
        },
      };
    },
    [SET_TRACKING_PAGE_DATA]: (state, { payload: { pathname, pageData, merge } }) => {
      const index = state.pageData.findIndex(page => page.pathname === pathname);

      const parseData = () => {
        const copy = state.pageData.slice(0);

        if (pageData) {
          copy[index].data = merge ? { ...copy[index].data, ...pageData } : pageData;
        }

        return copy;
      };

      return {
        ...state,
        pageData: state.pageData[index]
          ? parseData()
          : [
              ...state.pageData,
              ...[
                {
                  pathname,
                  ...(pageData ? { data: pageData } : {}),
                },
              ],
            ],
      };
    },
    [LOCATION_CHANGE]: (state, { payload }) => {
      // When a modal is triggered within a page, don't reset the pageLoadComplete parameter
      if (payload.search.indexOf('modal') > -1 && state.pageData.length > 0) {
        return state;
      }

      return { ...state, pageLoadComplete: false };
    },
    [PAGE_LOAD_COMPLETE]: state => ({ ...state, pageLoadComplete: true }),
    [GET_ACCOUNT]: {
      resolved: (state, { payload: { data } }) => ({
        ...state,
        persistentData: {
          ...state.persistentData,
          ...{
            [DataLayerKeys.MEMBER_WEEK_NUMBER]: Math.floor(
              moment.duration(moment.utc().diff(moment(data.createdDateUTC))).asWeeks(),
            ),
            [DataLayerKeys.MEMBER_WEEK_DAYS]: Math.floor(
              moment.duration(moment.utc().diff(moment(data.createdDateUTC))).asDays(),
            ),
            [DataLayerKeys.MEMBER_TYPE]: groupOrOnlineSelector(data.memberType),
          },
        },
      }),
    },
    [GET_ACCOUNT_ORIGIN]: {
      resolved: (state, { payload: { data: { userOrigin } } = {} }) => ({
        ...state,
        persistentData: {
          ...state.persistentData,
          [DataLayerKeys.USER_ORIGIN]: UserOrigin[userOrigin],
        },
      }),
    },
  },
  { persistentData: {}, pageData: [], pageLoadComplete: false },
);
