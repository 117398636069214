import moment from 'moment';
import React from 'react';
import { createAction } from 'redux-actions';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import MagicMoverAwardModal from '../../../../../components/atoms/MagicMoverAwardModal';

import { setSummaryLoading } from './plannerFoodSummaryActions';
import { retriggerAnimation } from '../components/lottieActions';
import {
  checkComplete,
  checkIfMagicMoverAwardAwarded,
  validateDuration,
} from './plannerManageActivityActions';
import { YYYYMMDD } from '../../data/enum/dateFormats';
import { IS_BLOCKING_ERROR } from '../../data/enum/ActivityStatus';
import { userIdSelector } from '../../selectors/userAccountSelectors';
import { hasMagicMoverAwardSelector } from '../../selectors/plannerSelectors';
import { GATEWAY_ACTIVITY_AUTH } from '../../data/Injectables';
import { PLANNER_ACTIVITY, PLANNER_ACTIVITY_TYPE, WEARABLE_DEVICE } from '../../data/entityTypes';
import { debug } from '../../util/plannerActionUtils';
import { PLANNER_ACTIVITIES } from '../../data/collectionPaginationViews';
import { activitiesCollectionId } from '../../data/collectionIds';
import { apiPatch } from './apiActions/apiRequest';
import { setEntity } from '../entities/entityActions';
import apiGetCollection, { collectionCachingNoPagination } from './apiActions/apiGetCollection';
import apiGetEntity from './apiActions/apiGetEntity';
import authenticate from '../../util/auth/authenticate';
import ActivitySources from '../../enums/ActivitySources';
import GatewayError from '../../net/gateway/GatewayError';

// Returns the activities tracked by a user between a date range
export const GET_PLANNER_ACTIVITY = 'plannerActivitySummaryActions/GET_PLANNER_ACTIVITY';
export const getPlannerDayActivity =
  (date, getFresh = false) =>
  async (dispatch, getState) => {
    await authenticate();
    const state = getState();
    const userId = userIdSelector(state);

    const requestData = () => {
      if (!date) {
        return {
          profileId: userId,
          excludeDeletedTypes: false,
        };
      }

      const startDate = date.start || date;
      const endDate = date.end ? date.end : moment(date).add(1, 'day').format(YYYYMMDD);

      return {
        profileId: userId,
        startDate,
        endDate,
        excludeDeletedTypes: false,
      };
    };

    return dispatch(
      apiGetCollection(
        GET_PLANNER_ACTIVITY,
        GATEWAY_ACTIVITY_AUTH,
        `/activities`,
        activitiesCollectionId({ userId }),
        { offset: 0, limit: 100 },
        {
          requestData: requestData(),
          updatePaginationView: {
            target: PLANNER_ACTIVITIES,
            extend: true,
          },
          entityType: PLANNER_ACTIVITY,
          // if the item is new get it, if its not default to item in the cache
          caching: !getFresh ? collectionCachingNoPagination : false,
        },
      ),
    ).catch(err => debug(err.message || 'Error when requesting planned day activities'));
  };

// Update planned activity:
// - duration and/or change the status of an activity to & from completed
// - Also display the magic mover award modal - if the member meets criteria for it to be displayed
export const PATCH_ACTIVITY = 'plannerActivitySummaryActions/PATCH_ACTIVITY';
export const setActivityStatusDuration =
  (activityId, duration, status, activitySource, isNewActivity = false) =>
  (dispatch, getState) => {
    const state = getState();

    // Check if the member has the magicMoverAward
    const hasMagicMoverAward = hasMagicMoverAwardSelector(state);

    const isWearableActivity = activitySource === ActivitySources.Wearable;

    if (isWearableActivity && !isNewActivity) {
      return Promise.resolve(false);
    }

    // the isNewActivity value is set by the member when they add an activity to their plan
    // by the time they get around to setting this to complete - they may already been granted the magic mover award
    // if the member has the magicMoverAward - we can send isNewActivity as false as
    // isNewActivity is used on the api to determine if the member should be granted the award
    return dispatch(
      apiPatch(PATCH_ACTIVITY, GATEWAY_ACTIVITY_AUTH, `/activities/${activityId}`, {
        ...(isWearableActivity
          ? { isNewActivity: true }
          : {
              duration,
              status,
              isNewActivity: hasMagicMoverAward ? false : isNewActivity,
            }),
      }),
    )
      .catch(err => {
        Promise.all([
          dispatch(validateDuration(JSON.parse(err.response.text)?.error?.fields?.[0]?.message)),
          dispatch(setSummaryLoading(false)),
          dispatch(retriggerAnimation(false)),
        ]);
        return JSON.parse(err.response.text);
      })
      .then(response => {
        if (response?.error) {
          return IS_BLOCKING_ERROR;
        }

        dispatch(
          setEntity(PLANNER_ACTIVITY, activityId, { duration, status, isNewActivity }, true),
        );

        // Display the magic mover award modal & refresh magic mover award state if:
        // - hasMagicMoverAward = false
        // - status = 1 (complete)
        // - isNewActivity = true
        if (!hasMagicMoverAward && status === 1 && isNewActivity) {
          dispatch(checkIfMagicMoverAwardAwarded());
          dispatch(
            openModal(
              ModalNames.MAGIC_MOVER_MODAL,
              {
                showWrapperCloseButton: false,
                children: <MagicMoverAwardModal />,
              },
              false,
              false,
              false,
            ),
          );
        }

        return response;
      })
      .then(response => dispatch(checkComplete(response)));
  };

// Returns a single activity type by passing in an id
export const GET_PLANNER_ACTIVITY_TYPE = 'plannerActivitySummaryActions/GET_PLANNER_ACTIVITY_TYPE';
export const getPlannerActivityType =
  (id, isFavourite = false) =>
  dispatch =>
    dispatch(
      apiGetEntity(
        GET_PLANNER_ACTIVITY_TYPE,
        GATEWAY_ACTIVITY_AUTH,
        `/activity-types/${id}`,
        PLANNER_ACTIVITY_TYPE,
        id,
        {
          caching: false,
          transformEntity: entityApi => {
            if (isFavourite) {
              return {
                ...entityApi,
                favourite: true,
              };
            }
            return entityApi;
          },
        },
      ),
    );

// Gets current wearable device for profile
export const GET_WEARABLE_DEVICE = 'plannerActivitySummaryActions/GET_WEARABLE_DEVICE';
export const getWearableDevice = () => async (dispatch, getState) => {
  await authenticate();
  const state = getState();
  const profileId = userIdSelector(state);

  return dispatch(
    apiGetEntity(
      GET_WEARABLE_DEVICE,
      GATEWAY_ACTIVITY_AUTH,
      `/wearable-devices/`,
      WEARABLE_DEVICE,
      profileId,
      {
        requestData: {
          profileId,
        },
        caching: false,
      },
    ),
  )
    .catch(error => {
      // 404 is expected behavior if user does not have an active wearable device
      if (error instanceof GatewayError && error?.response?.status === 404) {
        return null;
      }

      throw error;
    })
    .then(response => dispatch(setWearableDevice(response?.data)));
};

export const SET_WEARABLE_DEVICE = 'plannerActivitySummaryActions/SET_WEARABLE_DEVICE';
const setWearableDevice = createAction(SET_WEARABLE_DEVICE);
