const testImageUrl = (url: string): Promise<boolean> => {
  if (!url || typeof url !== 'string') return Promise.resolve(false);
  return new Promise(resolve => {
    const imgElement = new Image();
    imgElement.addEventListener('load', () => resolve(true));
    imgElement.addEventListener('error', () => resolve(false));
    imgElement.src = url;
  });
};

export default testImageUrl;
