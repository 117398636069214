import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';

import Ripple from '../../molecules/Ripple';
import Loader from '../Loader';

import './button.scss';

const CLASS_MODIFIERS = [
  'is-primary-inverted',
  'is-secondary',
  'is-tertiary',
  'is-ghost-dark',
  'is-ghost-light',
  'is-ghost-brand',
  'is-ghost-brand-dark',
  'is-on-background-color',
  'is-imitation-link',
  'is-block',
  'is-small',
  'is-large',
  'is-tiny',
  'is-shade-pink',
  'is-ghost-pink',
  'is-shade-red',
  'is-shade-purple',
  'reduced-padding',
  {
    'has-loader': 'isLoading',
    'is-disabled': 'disabled',
  },
];

/**
 * Renders buttons with a predefined style depending on the type that is
 * passed in.
 * Children:
 * Text and an Icon,
 * Icon without Text,
 * Text without Icon.
 */
class Button extends Component {
  state = {
    cursorPos: {},
  };

  /**
   * Handles touchend and mouseup events. Sets the current cursor position for a ripple
   * effect.
   */
  handlePointerUp = e => {
    const cursorPos = {
      top: e.clientY,
      left: e.clientX,
      time: Date.now(),
    };

    if (['touchend', 'mouseup'].includes(e.type)) {
      e.currentTarget.blur();
    }

    this.setState(() => ({ cursorPos }));
  };

  // eslint-disable-next-line class-methods-use-this
  preventClick = e => e.preventDefault();

  render() {
    const {
      children,
      onClick,
      ripple,
      type = 'button',
      target,
      reference,
      to,
      /* Props that are also used as class modifiers */
      /* eslint-disable no-unused-vars */
      disabled,
      isLoading,
      isSecondary,
      isTertiary,
      isGhostDark,
      isGhostLight,
      isGhostBrand,
      isGhostBrandDark,
      isOnBackgroundColor,
      isBlock,
      isSmall,
      isLarge,
      isTiny,
      isShadePink,
      isGhostPink,
      isShadePurple,
      reducedPadding,
      isImitationLink,
      isPrimaryInverted,
      isActive,
      isInitializing,
      targetId,
      targetTitle,
      tabIndex,
      interactionType,
      targetType,
      dataTestid,
      cid,
      /* eslint-enable no-unused-vars */
      /* Rest props */
      ...props
    } = this.props;

    const buttonContent = (
      <span className="flex-wrapper">
        {children}
        {isLoading && <Loader />}
        {!isLoading && ripple && <Ripple cursorPos={this.state.cursorPos} />}
      </span>
    );

    if (to) {
      return (
        <Link
          {...props}
          {...componentClassNameProp(ComponentType.ATOM, this, CLASS_MODIFIERS)}
          to={to}
          ref={reference}
          onClick={disabled ? this.preventClick : onClick}
          id={props?.id}
        >
          {buttonContent}
        </Link>
      );
    }

    if (this.props.href) {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <a
          {...props}
          {...componentClassNameProp(ComponentType.ATOM, this, CLASS_MODIFIERS)}
          onClick={disabled ? this.preventClick : onClick}
          ref={reference}
          target={target || null} // only specifically want to pass target to <a> element
          tabIndex={tabIndex}
        >
          {buttonContent}
        </a>
      );
    }

    return (
      <button
        {...props}
        {...componentClassNameProp(ComponentType.ATOM, this, CLASS_MODIFIERS)}
        onClick={disabled ? this.preventClick : onClick}
        type={type}
        disabled={disabled || null}
        onMouseUp={this.handlePointerUp}
        onTouchEnd={this.handlePointerUp}
        ref={reference}
        tabIndex={tabIndex}
      >
        {buttonContent}
      </button>
    );
  }
}

Button.defaultProps = {
  type: 'button',
};

Button.propTypes = {
  style: PropTypes.object,
  reference: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * pass onclick from consuming parent component
   */
  onClick: PropTypes.func,
  /**
   * The text for the button
   */
  children: PropTypes.node,
  /**
   * if `true`, triggers a ripple effect on pointer up.
   */
  ripple: PropTypes.bool,
  /*
   * Add loading component
   */
  isLoading: PropTypes.bool,
  /**
   * Link the button to an external web address. Must be an absolute url. Turns button from
   * <button> to <a>
   */
  href: PropTypes.string,
  /**
   * Only used when the `href` prop is set. Sets the target attribute of <a>
   */
  target: PropTypes.string,
  /**
   * Links the button to a page in our application. Turns the button from <button> to <Link>.
   * See the documentation for the `to` parameter of `<Link />` in the
   * [react-router docs](https://github.com/ReactTraining/react-router/blob/v3/docs/API.md#link)
   */
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.any,
    }),
  ]),
  /**
   * Button type, only  used if no `to` or `href` prop provided
   . Defaults to ' button'
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**
   * Toggles button disabled
   */
  disabled: PropTypes.bool,
  /**
   * Title for button (Hover tooltip)
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * The buttons value when submitting a form
   */
  value: PropTypes.string,

  /* eslint-disable react/no-unused-prop-types */
  /**
   * Used for the button variations
   */
  isPrimaryInverted: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isTertiary: PropTypes.bool,
  isGhostDark: PropTypes.bool,
  isGhostLight: PropTypes.bool,
  isGhostBrand: PropTypes.bool,
  isGhostBrandDark: PropTypes.bool,
  isOnBackgroundColor: PropTypes.bool,
  isBlock: PropTypes.bool,
  isSmall: PropTypes.bool,
  isLarge: PropTypes.bool,
  isTiny: PropTypes.bool,
  isImitationLink: PropTypes.bool,
  isShadePink: PropTypes.bool,
  isGhostPink: PropTypes.bool,
  isShadePurple: PropTypes.bool,
  reducedPadding: PropTypes.bool,
  /**
   * Component function reference
   */
  ref: PropTypes.func,
  /* eslint-enable react/no-unused-prop-types */
  dataTestid: PropTypes.string,
  cid: PropTypes.string,
  isActive: PropTypes.bool,
  isInitializing: PropTypes.bool,
  targetId: PropTypes.string,
  targetTitle: PropTypes.string,
  interactionType: PropTypes.string,
  targetType: PropTypes.number,
  tabIndex: PropTypes.string,
};

export default Button;
