import lowerFirst from 'lodash/lowerFirst';
import { getEntityTypeFromRelatedEntityKind } from '../../data/enum/RelatedEntityKind';
import { RECIPE, FEATURE, POST, SUCCESS_STORY, ACTIVITY_VIDEO } from '../../data/entityTypes';
import apiGetCollection, {
  addEntitiesFromApiData,
  collectionCachingNoPagination,
} from './apiActions/apiGetCollection';
import {
  GATEWAY_COMMUNITY_AUTH,
  GATEWAY_CONTENT_AUTH,
  GATEWAY_CONTENT_V2_AUTH,
} from '../../data/Injectables';
import { BOOKMARKS } from '../../data/collectionIds';
import BookmarkTileType from '../../data/enum/BookmarkTileType';
import { apiGet } from './apiActions/apiRequest';
import getDefaultApiEntityTransform from './apiActions/getDefaultApiEntityTransform';
import { bookmarksCollectionSelector } from '../../selectors/bookmarkSelectors';

export const GET_BOOKMARKS = 'bookmarkActions/GET_BOOKMARKS';
export const GET_TILES = 'bookmarkActions/GET_TILES';

export const BOOKMARKS_PAGINATION_LIMIT = 8;

export const getBookmarkTileTypeFromSubjectKind = subjectKind => {
  const entityType = getEntityTypeFromRelatedEntityKind(subjectKind);
  return entityType ? lowerFirst(BookmarkTileType[entityType]) : entityType;
};

export const getBookmarks = () =>
  apiGetCollection(
    GET_BOOKMARKS,
    GATEWAY_COMMUNITY_AUTH,
    '/profiles/me/bookmarks',
    BOOKMARKS,
    {},
    {
      caching: collectionCachingNoPagination,
      entityType: entity => getBookmarkTileTypeFromSubjectKind(entity.subjectKind),
      storeEntities: false,
    },
  );

const tileEndpoints = [
  {
    gateway: GATEWAY_CONTENT_V2_AUTH,
    path: '/articles',
    parameterName: 'ids',
    tileTypes: [
      BookmarkTileType[SUCCESS_STORY],
      BookmarkTileType[RECIPE],
      BookmarkTileType[FEATURE],
      BookmarkTileType[ACTIVITY_VIDEO],
    ].map(type => lowerFirst(type)),
  },
  {
    gateway: GATEWAY_COMMUNITY_AUTH,
    path: '/posts',
    parameterName: 'ids',
    tileTypes: [lowerFirst(BookmarkTileType[POST])],
  },
  {
    gateway: GATEWAY_CONTENT_AUTH,
    path: '/activity-videos/search-ids',
    parameterName: 'videoIds',
    tileTypes: [lowerFirst(BookmarkTileType[ACTIVITY_VIDEO])],
  },
];

export const getBookmarkTiles =
  (tileType_, page = 0, limit = BOOKMARKS_PAGINATION_LIMIT) =>
  (dispatch, getState) => {
    const tileType = lowerFirst(tileType_);
    const state = getState();
    const bookmarks = bookmarksCollectionSelector(state, { collectionId: BOOKMARKS }).entityRefs;
    const bookmarksOfType = tileType
      ? bookmarks.filter(bookmark => bookmark.type === tileType)
      : bookmarks;

    const tilesOnPage = bookmarksOfType.slice(0, (page + 1) * limit);
    const tilesToRequest = tilesOnPage.filter(tile => tile.data === null);

    const tilePromises = tileEndpoints.map(endpoint => {
      const idsToRequest = tilesToRequest
        .filter(tile => endpoint.tileTypes.includes(tile.type))
        .map(tile => tile.id);

      if (idsToRequest.length) {
        return dispatch(
          apiGet(GET_TILES, endpoint.gateway, endpoint.path, {
            [endpoint.parameterName]: idsToRequest.join(','),
          }),
        ).then(({ data }) => {
          dispatch(
            addEntitiesFromApiData(
              data,
              entity => lowerFirst(entity._type), // eslint-disable-line no-underscore-dangle
              entity => entity.id,
              getDefaultApiEntityTransform(endpoint.path, GET_TILES),
            ),
          );
        });
      }

      return Promise.resolve();
    });

    return Promise.all(tilePromises);
  };
