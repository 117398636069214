// auto generated please run `yarn icon-sprite`
const IconName = {
  ACTIVE_ARROW_LINK: 'active-arrow-link',
  ADMIN_ICON_UK: 'admin-icon-uk',
  ANDROID: 'android',
  APP_GLOBAL_ICON: 'app-global-icon',
  APP_MOBILE: 'app-mobile',
  APP_PLANNER_ICON: 'app-planner-icon',
  APP_STORE: 'app-store',
  APPLE: 'apple',
  ARROW_DRAWN: 'arrow-drawn',
  ARROW_FLIP: 'arrow-flip',
  ARROW_NEW: 'arrow-new',
  ARROW: 'arrow',
  AUDIO: 'audio',
  BASKET: 'basket',
  BEFORE: 'before',
  BLACK_AND_WHITE: 'black-and-white',
  BLOCK_ARROW: 'block-arrow',
  BLOCKQUOTE: 'blockquote',
  BODY_MAGIC_AWARD: 'body-magic-award',
  BODY_MAGIC_MAN: 'body-magic-man',
  BODY_MAGIC: 'body-magic',
  BOOKMARK: 'bookmark',
  BULLETED_LIST: 'bulleted-list',
  CALCIUM: 'calcium',
  CALENDAR_1: 'calendar-1',
  CALENDAR_LARGE: 'calendar-large',
  CALENDAR: 'calendar',
  CAMERA: 'camera',
  CHECKMARK: 'checkmark',
  CHEFS_HAT: 'chefs-hat',
  CHEVRON_LARGE: 'chevron-large',
  CHEVRON: 'chevron',
  CHILLI: 'chilli',
  CIRCLE_MINUS: 'circle-minus',
  CIRCLE_PLUS: 'circle-plus',
  CLOCK: 'clock',
  COMMENT_BORDER: 'comment-border',
  COMMENT_FILLED: 'comment-filled',
  COMMENTED_FILLED: 'commented-filled',
  COMMUNITY_MENTION: 'community-mention',
  COPY: 'copy',
  CROSS: 'cross',
  CURRENT_WEIGHT: 'current-weight',
  CYP_FLAG: 'cyp-flag',
  DAIRY_FREE: 'dairy-free',
  DOWNLOAD_LARGE: 'download-large',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  EMOTION_DISAPOINTED: 'emotion-disapointed',
  EMOTION_FRUSTRATED: 'emotion-frustrated',
  EMOTION_PLEASED: 'emotion-pleased',
  EMOTION_THRILLED: 'emotion-thrilled',
  EMOTION_UPSET: 'emotion-upset',
  ENLARGE: 'enlarge',
  ENVELOPE_EMAIL: 'envelope-email',
  EVERY_MIND_MATTERS: 'every-mind-matters',
  EYE_HIDE: 'eye-hide',
  EYE_OPEN: 'eye-open',
  FACEBOOK: 'facebook',
  FAMILY_AFFAIR_LOGO: 'family-affair-logo',
  FEMALE: 'female',
  FIBRE: 'fibre',
  FILTER: 'filter',
  FLAG: 'flag',
  FO_STEP_ONE_CIRCLE: 'fo-step-one-circle',
  FO_STEP_THREE_CIRCLE: 'fo-step-three-circle',
  FO_STEP_TWO_CIRCLE: 'fo-step-two-circle',
  FOOD_BOWL: 'food-bowl',
  FOOD_GUIDE_ICON: 'food-guide-icon',
  FOOD_OPTIMISING_BOOKMARK_ICON: 'food-optimising-bookmark-icon',
  FOOD_OPTIMISING_HERO_AWARD: 'food-optimising-hero-award',
  FOOD_OPTIMISING_MENU: 'food-optimising-menu',
  FOOTPRINTS: 'footprints',
  FREE_FOODS_NEW: 'free-foods-new',
  FREE_FOODS: 'free-foods',
  FREEZER_FRIENDLY: 'freezer-friendly',
  FULL_COLOUR: 'full-colour',
  GLUTEN_FREE: 'gluten-free',
  GROUP_SUPPORT: 'group-support',
  HALF_FRACTION_AWARD: 'half-fraction-award',
  HALF_FRACTION: 'half-fraction',
  HALL_OF_FAME: 'hall-of-fame',
  HAMBURGER_MENU: 'hamburger-menu',
  HAMBURGER: 'hamburger',
  HAND_DRAWN_ARROW: 'hand-drawn-arrow',
  HEALTHY_EXTRA_A: 'healthy-extra-a',
  HEALTHY_EXTRAS: 'healthy-extras',
  HEART_LIKE_BORDER: 'heart-like-border',
  HEART_LIKED_FILLED: 'heart-liked-filled',
  HEART_WHITE_BORDER: 'heart-white-border',
  HEIGHT: 'height',
  HIGHLIGHTER: 'highlighter',
  ICELAND_LOGO: 'iceland-logo',
  ICELAND_SP: 'iceland-sp',
  ICELAND_VEGAN: 'iceland-vegan',
  ICELAND_VEGETARIAN: 'iceland-vegetarian',
  IDEA: 'idea',
  INFORMATION: 'information',
  INSTAGRAM_CAMCORDER: 'instagram-camcorder',
  INSTAGRAM: 'instagram',
  IPSO: 'ipso',
  LIGHTBULB: 'lightbulb',
  LIST_HEART: 'list-heart',
  LIST_SEARCH: 'list-search',
  LOCATION_TARGET: 'location-target',
  LOCK: 'lock',
  LOGO_CLASSIC_MOBILE: 'logo-classic-mobile',
  LOGO_CLASSIC: 'logo-classic',
  LOGO_MODERN_MOBILE: 'logo-modern-mobile',
  LOGOUT: 'logout',
  MAGNIFY_GLASS: 'magnify-glass',
  MALE: 'male',
  MAP_MARKER_SYMBOL: 'map-marker-symbol',
  MAP_PIN: 'map-pin',
  MENU_COMMUNITY: 'menu-community',
  MENU_CONTACT_US: 'menu-contact-us',
  MENU_FEATURES: 'menu-features',
  MENU_FOODLISTS: 'menu-foodlists',
  MENU_HOME: 'menu-home',
  MENU_RECIPES: 'menu-recipes',
  MENU_SLIMMING_GROUP: 'menu-slimming-group',
  MENU_SUCCESS: 'menu-success',
  MENU_WEIGHTLOSSPLANNER: 'menu-weightlossplanner',
  MINUS: 'minus',
  MOBILE_SUPPORT_ARROW: 'mobile-support-arrow',
  MUSCLE: 'muscle',
  MUTE: 'mute',
  NOTIFICATIONS: 'notifications',
  ONLINE_MEMBER_EVENTS: 'online-member-events',
  PAPERCLIP: 'paperclip',
  PAUSE: 'pause',
  PDF: 'pdf',
  PERSON_BORDER: 'person-border',
  PERSON_FILLED: 'person-filled',
  PIN: 'pin',
  PINTEREST: 'pinterest',
  PLAY: 'play',
  PLUS: 'plus',
  PODCAST_HEART_DARK: 'podcast-heart-dark',
  PODCAST_HEART: 'podcast-heart',
  PODCAST: 'podcast',
  PREGNANCY_ROUNDEL: 'pregnancy-roundel',
  PRINT: 'print',
  PROTEIN: 'protein',
  RECURRING_PAYMENT: 'recurring-payment',
  REGISTERED_TRADEMARK_SYMBOL: 'registered-trademark-symbol',
  REPLY: 'reply',
  RESET: 'reset',
  ROI_FLAG: 'roi-flag',
  SCALE_BORDER: 'scale-border',
  SCALE_PIN: 'scale-pin',
  SCALE: 'scale',
  SHARE_EXPORT: 'share-export',
  SHARE: 'share',
  SIGN_UP: 'sign-up',
  SIGNIN: 'signin',
  SMILES_CHARITY_LOGO: 'smiles-charity-logo',
  SOUND_OFF: 'sound-off',
  SOUND_ON: 'sound-on',
  SP_SECONDARY: 'sp-secondary',
  SP: 'sp',
  SPEED: 'speed',
  SUPPORT: 'support',
  SYNS_ALLOWANCE: 'syns-allowance',
  TAGS: 'tags',
  TICK: 'tick',
  TIME: 'time',
  TRASH: 'trash',
  TROPHY: 'trophy',
  TRUST_PILOT: 'trust-pilot',
  TUMBLR: 'tumblr',
  TWITTER: 'twitter',
  UK_FLAG: 'uk-flag',
  UNDERLINE_SWOOSH_SMALL: 'underline-swoosh-small',
  UNDERLINE_SWOOSH: 'underline-swoosh',
  UNLOCKED: 'unlocked',
  VEGAN: 'vegan',
  VEGETARIAN: 'vegetarian',
  VIDEO_CAMERA: 'video-camera',
  WATCH: 'watch',
  WEIGHT_LOSS_ROUNDEL: 'weight-loss-roundel',
  MAGIC_MOVER_AWARD: 'magic-mover-award',
};

export default IconName;
