/* eslint-disable no-bitwise */
const Medical = {
  none: 0,
  bones: 1 << 0,
  celiacdisease: 1 << 1,
  diabetes: 1 << 2,
  heartdisease: 1 << 3,
  highbloodpresure: 1 << 4,
  highcholesterol: 1 << 5,
  osteoporosis: 1 << 6,
  stroke: 1 << 7,
  foodAllergies: 1 << 8,
  irritableBowelSyndrome: 1 << 9,
  lactoseIntolerance: 1 << 10,
  polycysticOvarySyndrome: 1 << 11,
  weightLossSurgery: 1 << 12,
  pregnant: 1 << 13,
  breastFeeding: 1 << 14,
};

export default Medical;
