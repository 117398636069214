import React, { useEffect } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import kebabCase from 'lodash/kebabCase';
import classNames from 'classnames';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import RegistrationTemplate from 'components/organisms/RegistrationTemplate';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { Colors } from 'components/atoms/TextNew';
import Pages, { GROUP_REGISTRATION_FIRST_STEP } from 'common/src/app/data/enum/Pages';
import WebHostLink from 'components/atoms/WebHostLink';
import WebHost from 'common/src/app/data/enum/WebHost';

import './online-registration.scss';

const OnlineRegistration = (
  { children, stepPaths, lastMountedStepIndex, publicHost, deviceState, background, setFlowType },
  { getMessage },
  className,
) => {
  useEffect(() => {
    setFlowType();
  });

  const groupRegistrationCTAs = (
    <Wrapper width="ls" background={Colors.PRIMARY_LIGHT} cid="group-registration-cta">
      <TextNew.Serif.XS localeId="onlineRegistration.groupRegistrationCTA.title" />

      <TextNew.Sans.SM cid="group-registration-cta-link">
        <WebHostLink link={Pages.UK_PUBLIC_NEAREST_GROUP_LANDING} webHost={WebHost.PUBLIC}>
          {getMessage('onlineRegistration.groupRegistrationCTA.findAGroup')}
        </WebHostLink>
      </TextNew.Sans.SM>

      <TextNew.Sans.SM cid="group-registration-cta-link">
        <WebHostLink link={GROUP_REGISTRATION_FIRST_STEP} webHost={WebHost.ACCOUNT}>
          {getMessage('onlineRegistration.groupRegistrationCTA.registerCard')}
        </WebHostLink>
      </TextNew.Sans.SM>

      <TextNew.Sans.SM cid="group-registration-cta-link">
        <WebHostLink link={Pages.UK_PUBLIC_ONLINE_INFORMATION} webHost={WebHost.PUBLIC}>
          {getMessage('onlineRegistration.groupRegistrationCTA.findOutMoreDigital')}
        </WebHostLink>
      </TextNew.Sans.SM>
    </Wrapper>
  );
  const pageDetails =
    stepPaths.length > 0 &&
    [
      { title: getMessage('onlineRegistration.steps.packageSelection') },
      { title: getMessage('onlineRegistration.steps.introduction') },
      { title: getMessage('onlineRegistration.steps.medicalCheck') },
      { title: getMessage('onlineRegistration.steps.personalDetails') },
      { title: getMessage('onlineRegistration.steps.summary') },
    ].map((data, index) => ({ ...data, url: stepPaths[index] }));
  const activePage = pageDetails?.[lastMountedStepIndex];

  const regTemplate = (
    <RegistrationTemplate
      publicHost={publicHost}
      children={children}
      pageDetails={pageDetails}
      lastMountedStepIndex={lastMountedStepIndex}
      groupRegistrationCTAs={groupRegistrationCTAs}
    />
  );

  return (
    <BackgroundImageWrapper
      className={classNames(className, `${kebabCase(activePage?.title)}`)}
      image={deviceState >= DeviceState.LG ? background : null}
    >
      {regTemplate}
    </BackgroundImageWrapper>
  );
};

OnlineRegistration.propTypes = {
  background: ImagePropTypes,
  children: PropTypes.node,
  stepPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  lastMountedStepIndex: PropTypes.number.isRequired,
  publicHost: PropTypes.string.isRequired,
  deviceState: PropTypes.number,
  setFlowType: PropTypes.func,
};

OnlineRegistration.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.PAGE, 'OnlineRegistration')(OnlineRegistration),
);
