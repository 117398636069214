/* eslint no-underscore-dangle: ["error", { "allow": ["_type"] }] */
import React, { Fragment, MouseEventHandler } from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import DeepLink from 'components/organisms/DeepLink';
import TextNew, { TextTypes } from 'components/atoms/TextNew';
import BlockContentComponentMap, { excludeBlockFromWrapper, types } from './enum/BlockTypes';
import errorBoundary from '../../hoc/ErrorBoundary/index';

import {
  BlockContentType,
  BlockContentProps,
  CreateBlocksProps,
  ContentBlock,
  DataBlock,
  RenderComponentProps,
} from './BlockContentTypes';

import './block-content.scss';

/**
 * Assign the current data 'block' a component based on data _type (mapped from BlockContentComponentMap)
 */
export const getBlocks = (data: ContentBlock[]): DataBlock[] =>
  data.reduce<DataBlock[]>((blocks, element) => {
    const component = BlockContentComponentMap[element._type];

    if (component) {
      const block: DataBlock = {
        data: element,
        component,
      };
      blocks.push(block);
    }

    return blocks;
  }, []);

/**
 * renderComponent renders the component assigned from getBlocks function
 * n.b. We are also adding a wrapper based on a prop and if the component _type is added to a blacklist
 * This should ideally be a prop on each component from umbraco or an actual wrapper block
 */
export const renderComponent = ({
  errorTitleType,
  block,
  key,
  noWrapper,
  addDeepLinkSection,
}: RenderComponentProps): JSX.Element => {
  const renderedBlock = (
    <Fragment key={key}>
      {/* this is for the policy pages to work manually */}
      {addDeepLinkSection && block.data.title && block.data._type !== types.DEEP_LINK_BLOCK && (
        <DeepLink shouldRegisterDeepLink transformTitleForId title={block.data.title} />
      )}
      <block.component {...block.data}>
        {/* this line adds recursion */}
        {block.data.content &&
          createBlocks({
            errorTitleType,
            data: block.data.content,
            noWrapper,
            addDeepLinkSection,
          })}
      </block.component>
    </Fragment>
  );

  // TODO: REMOVE, SHOULD COME FROM UMBRACO AS RECURSIVE BLOCK
  if (!excludeBlockFromWrapper.includes(block.data._type) && noWrapper === false) {
    return (
      <Wrapper cid="block-content-wrapper" key={key}>
        {renderedBlock}
      </Wrapper>
    );
  }

  return renderedBlock;
};

/**
 * createBlocks chains the getBlocks & renderComponent functions together, this allows us to reuse the
 * mapping and rendering of _types to component and reuse it within the actual component and
 * recursively in in the renderComponent section
 */
export const createBlocks = ({
  errorTitleType,
  data,
  noWrapper,
  addDeepLinkSection,
}: CreateBlocksProps): JSX.Element[] =>
  data &&
  getBlocks(data).map((block, i) =>
    renderComponent({
      errorTitleType,
      block,
      key: `${block.data._type}${i}`,
      noWrapper,
      addDeepLinkSection,
    }),
  );

/**
 * Block Content
 *
 * This component maps over a data object and recursively creates components.
 * We check for a component _type, passing it's data through.
 * If the component data also has a _type, we run the component generation down until
 * the last child
 *
 * "data": [
 *   {
 *     "content": [
 *       {
 *         "_type": "RichText",
 *         "data": {
 *            ...
 *         }
 *       },
 *       {...}
 *     ],
 *   }
 * ]
 *
 * Please note, for recursion to work we must allow our components to render children.
 * Currently some do not, manually tackling this step!
 */

const BlockContent = (
  { errorTitleType, data, historyPush, noWrapper = false, addDeepLinkSection }: BlockContentProps,
  _context: unknown,
  className: string,
): JSX.Element => {
  if (!data) {
    return (
      <div className={className}>
        <TextNew type={TextTypes.ERROR} localeId="errors.notFound" />
      </div>
    );
  }

  const onClickHandler: MouseEventHandler<HTMLDivElement> = clickEvent => {
    const clickTarget = clickEvent.target as HTMLElement;
    if (clickTarget?.tagName === 'A') {
      const href = clickTarget.getAttribute('href');
      const target = clickTarget.getAttribute('target');

      if ((!target || target === '_self') && !!href && !href.match(/^[a-zA-Z\d]+:/)) {
        clickEvent.preventDefault();
        historyPush(href);
      }
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={className} onClick={onClickHandler}>
      {createBlocks({ errorTitleType, data, noWrapper, addDeepLinkSection })}
    </div>
  );
};

export default compose<BlockContentType>(
  errorBoundary(),
  withFunctionalClassName(ComponentType.MOLECULE, 'BlockContent'),
)(BlockContent);
