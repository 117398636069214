import createAction from 'redux-actions/lib/createAction';
import { GATEWAY_ACCOUNT } from '../data/Injectables';
import { apiGet } from './resources/apiActions/apiRequest';

export const REGISTRATION_FLOW_TYPE = 'registrationActions/REGISTRATION_FLOW_TYPE';
export const SAVE_REFERER = 'registrationActions/SAVE_REFERER';
export const SAVE_UTM = 'registrationActions/SAVE_UTM';
export const SAVE_PREFILL_SUBSCRIPTION = 'registrationActions/SAVE_PREFILL_SUBSCRIPTION';
export const GET_INVITE_REGISTRATION = 'registrationActions/GET_INVITE_REGISTRATION';
export const SET_INVITE_REGISTRATION = 'registrationActions/SET_INVITE_REGISTRATION';
export const SAVE_INITIAL_SELECTED_SUBSCRIPTION =
  'registrationActions/SAVE_INITIAL_SELECTED_SUBSCRIPTION';
export const SAVE_UPGRADED_SUBSCRIPTION = 'registrationActions/SAVE_UPGRADED_SUBSCRIPTION';
export const TOGGLE_INITAL_SELECTED_VOUCHER = 'registrationActions/TOGGLE_INITAL_SELECTED_VOUCHER';

export const REGISTRATION_FREE_2_GO = 'registrationActions/REGISTRATION_FREE_2_GO';

export const savePrefillSubscription = createAction(
  SAVE_PREFILL_SUBSCRIPTION,
  subscriptionSlug => ({ subscriptionSlug }),
);

export const saveReferer = createAction(SAVE_REFERER, referer => ({ referer }));

export const saveUtm = createAction(SAVE_UTM, (source, campaign) => ({
  source,
  campaign,
}));

export const setInviteRegistration = createAction(SET_INVITE_REGISTRATION);
export const setRegistrationFlowType = createAction(REGISTRATION_FLOW_TYPE);

export const getInviteRegistration = inviteId => dispatch =>
  dispatch(apiGet(GET_INVITE_REGISTRATION, GATEWAY_ACCOUNT, `/invites/${inviteId}`)).then(
    ({ data }) => dispatch(setInviteRegistration(data)),
  );

export const saveSelectedSubscription = createAction(
  SAVE_INITIAL_SELECTED_SUBSCRIPTION,
  subscriptionSlug => ({ subscriptionSlug }),
);

export const saveUpgradedSubscription = createAction(
  SAVE_UPGRADED_SUBSCRIPTION,
  subscriptionSlug => ({ subscriptionSlug }),
);

export const toggleInitialSelectedVoucher = createAction(TOGGLE_INITAL_SELECTED_VOUCHER);

export const setRegistrationFree2Go = createAction(REGISTRATION_FREE_2_GO);
