/* global 'molecule','WeightMetricFieldGroup' */
import React from 'react';
import PropTypes from 'prop-types';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from '../../atoms/LocaleMessage';
import TextNew from '../../atoms/TextNew';
import Input, { descriptorTypes } from '../../atoms/Input';
import Row from '../../atoms/Row';

import './weight-metric-field-group.scss';

const KILOS_LENGTH = 3;
const GRAMS_LENGTH = 2;

const WeightMetricFieldGroup = (
  { abbreviate, disabled, hidePlaceholder },
  { getMessage },
  className,
) => (
  <Row className={className}>
    <EnhancedField
      component={Input}
      name={getMessage('general.measureUnit.weight.kilogram.labelKilos')}
      type="number"
      min="0"
      max="318"
      ariaLabel={getMessage('general.measureUnit.weight.kilogram.labelKilos')}
      placeholder={hidePlaceholder ? ' ' : '000'}
      descriptorType={descriptorTypes.STATIC}
      onKeyPress={event => handleFieldGroupInput(event, KILOS_LENGTH)}
      disabled={disabled}
    />
    <TextNew.Formal aria-hidden="true">.</TextNew.Formal>
    <EnhancedField
      component={Input}
      name={getMessage('general.measureUnit.weight.kilogram.labelGrams')}
      type="number"
      min="0"
      max="99"
      ariaLabel={getMessage('general.measureUnit.weight.kilogram.labelGrams')}
      placeholder={hidePlaceholder ? ' ' : '00'}
      placeholderAbbreviation={
        <LocaleMessage
          id={`general.measureUnit.weight.kilogram.${abbreviate ? 'abbreviation' : 'name'}`}
        />
      }
      descriptorType={descriptorTypes.STATIC}
      onKeyPress={event => handleFieldGroupInput(event, GRAMS_LENGTH)}
      disabled={disabled}
    />
  </Row>
);

WeightMetricFieldGroup.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

WeightMetricFieldGroup.propTypes = {
  disabled: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  abbreviate: PropTypes.bool,
};

export default withFunctionalClassName('molecule','WeightMetricFieldGroup')(WeightMetricFieldGroup);
