import React from 'react';
import PropTypes from 'prop-types';
import {
  measurementFields,
  medicalFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import FormValueProvider from 'common/src/app/enhanced-redux-form/components/FormValueProvider';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import LocaleMessage from '../../../../atoms/LocaleMessage';
import Row from '../../../../atoms/Row';
import TextNew from '../../../../atoms/TextNew';
import CheckBox from '../../../../molecules/CheckBox';
import FormGroup from '../../../../molecules/FormGroup';
import BmiError from '../../../../molecules/BmiError';
import ConfirmTerms from '../../../../molecules/ConfirmTerms';
import HeightInput from '../../../../organisms/HeightInput';
import WeightInput from '../../../../organisms/WeightInput';

const WeightHeightQuestions = ({
  formValues: {
    [medicalFields.HAS_MEDICAL_CONDITIONS]: hasMedicalConditions,
    [medicalFields.HAS_READ_CONDITIONS_DISCLAIMER]: hasReadMedicalDisclaimer,
  },
  hasBmiError,
  isGroupRegister,
  isFree2GoRegistration,
  registrationType,
  forwardRef,
}) => {
  const confirmPrivacyPromise = (
    <FormGroup>
      <Row aligncenter spacebetween>
        <ConfirmTerms
          showPrivacyPromise
          showTermsAgreement={false}
          isGroupRegister={isGroupRegister}
        />
      </Row>
    </FormGroup>
  );

  return (
    <>
      {hasMedicalConditions === '1' && (
        <div className="error-content-wrapper">
          {[...Array(4).keys()].map(index => (
            <TextNew.PrimaryElegant
              key={`medical-condition-para-${index + 1}`}
              localeId={`medicalCheck.blockingErrors.hasMedicalConditions.para${index + 1}`}
            />
          ))}
          <EnhancedField name={medicalFields.HAS_READ_CONDITIONS_DISCLAIMER} component={CheckBox}>
            <TextNew.PrimaryElegant localeId="medicalCheck.blockingErrors.hasMedicalConditions.checkbox" />
          </EnhancedField>
        </div>
      )}
      {(hasMedicalConditions !== '1' || !!hasReadMedicalDisclaimer) && (
        <>
          {!isFree2GoRegistration && (
            <FormGroup
              label={<LocaleMessage id="medicalCheck.weightHeight.label" />}
              className="weight-height"
            >
              <TextNew.PrimaryElegant
                cid="weightinfo"
                localeId="medicalCheck.weightHeight.notice"
              />

              <WeightInput
                memberType={registrationType}
                labelLocale={`medicalCheck.groupLabels.${
                  isGroupRegister ? 'currentWeight' : 'startWeight'
                }`}
                forwardRef={forwardRef?.weightRef}
              />

              <HeightInput memberType={registrationType} forwardRef={forwardRef?.heightRef} />
            </FormGroup>
          )}

          <BmiError
            fieldName={measurementFields.INITIAL_WEIGHT}
            isGroupRegister={isGroupRegister}
          />
          {isGroupRegister && !hasBmiError ? confirmPrivacyPromise : null}
        </>
      )}
    </>
  );
};

WeightHeightQuestions.propTypes = {
  formValues: PropTypes.shape({
    [medicalFields.HAS_MEDICAL_CONDITIONS]: PropTypes.string,
    [medicalFields.HAS_READ_CONDITIONS_DISCLAIMER]: PropTypes.bool,
  }),
  isGroupRegister: PropTypes.bool,
  hasBmiError: PropTypes.bool,
  isFree2GoRegistration: PropTypes.bool,
  registrationType: PropTypes.string,
  forwardRef: PropTypes.object,
};

export default WeightHeightQuestions;
