import React, { useEffect } from 'react';
import createId from 'common/src/app/util/createId';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import { DeepLinkProps } from './DeepLinkTypes';

export const EXTRA_OFFSET_TOP = 32;

/**
 * This component has two purposes:
 * - a reference block for navigating to the correct section of the page
 * - used to create the section navigation (TabBar)
 *
 * Dependency:
 * - Use together with the DeepLinkHandler
 * - Pass a title property for the label in the navigation
 *
 * props that can be passed for creating the navigation:
 * - title
 * - buttonLabel
 * - buttonLink
 * - icon,
 *
 * Example usage:
 * <DeepLinkWithIconAndMediaButtonBlock title="Your title" />
 *
 * See Slimming World Essentials page and Home page
 */
const DeepLink = (
  {
    title = '',
    buttonLabel,
    buttonLink,
    icon,
    registerDeepLink,
    currentRoute,
    headerHeight,
    shouldRegisterDeepLink = false,
    transformTitleForId = false,
  }: DeepLinkProps,
  _context: unknown,
  className: string,
  dataTestid: string,
): JSX.Element => {
  useEffect(() => {
    shouldRegisterDeepLink &&
      registerDeepLink({
        [title]: {
          buttonLabel,
          buttonLink,
          icon,
        },
        currentRoute,
      });
  }, []);

  const styles = {
    scrollMarginTop: `${headerHeight + EXTRA_OFFSET_TOP}px`,
  };

  return (
    <div
      id={transformTitleForId ? createId(title) : title}
      className={className}
      data-testid={dataTestid}
      style={styles}
    />
  );
};

export default withFunctionalClassName(ComponentType.ATOM, 'DeepLink')(DeepLink);
