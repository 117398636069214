import mapValues from 'lodash/mapValues';
import { handleActions, combineActions } from 'redux-actions';
import { actionTypes as formActionTypes } from 'redux-form';
import {
  REGISTER_COMPOSITE_INPUT,
  UNREGISTER_COMPOSITE_INPUT,
  COMPOSE_COMPOSITE_INPUT,
} from '../actions/compositeInputActions';

const compositeInput = handleActions(
  {
    [formActionTypes.INITIALIZE]: state => ({
      ...state,
    }),
    [REGISTER_COMPOSITE_INPUT]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [COMPOSE_COMPOSITE_INPUT]: (state, { payload: { value } }) => ({
      ...state,
      composedValue: value,
    }),
  },
  {},
);

const formCompositeInputs = handleActions(
  {
    [formActionTypes.INITIALIZE]: (state, action) =>
      mapValues(state, subState => compositeInput(subState, action)),

    [combineActions(REGISTER_COMPOSITE_INPUT, COMPOSE_COMPOSITE_INPUT)]: (state, action) => {
      const {
        payload: { field },
      } = action;

      return {
        ...state,
        [field]: compositeInput(state[field], action),
      };
    },

    [UNREGISTER_COMPOSITE_INPUT]: (state, { payload: { field } }) => {
      // eslint-disable-next-line no-unused-vars
      const { [field]: toRemove, ...newState } = state;
      return newState;
    },
  },
  {},
);

const compositeInputs = handleActions(
  {
    [combineActions(formActionTypes.INITIALIZE, REGISTER_COMPOSITE_INPUT, COMPOSE_COMPOSITE_INPUT)]:
      (state, action) => {
        const {
          meta: { form },
        } = action;

        return {
          ...state,
          [form]: formCompositeInputs(state[form], action),
        };
      },

    [UNREGISTER_COMPOSITE_INPUT]: (state, action) => {
      const {
        meta: { form },
      } = action;

      const formState = formCompositeInputs(state[form], action);

      if (!Object.keys(formState).length) {
        // eslint-disable-next-line no-unused-vars
        const { [form]: toRemove, ...newState } = state;
        return newState;
      }

      return {
        ...state,
        [form]: formState,
      };
    },
  },
  {},
);

export default compositeInputs;
