import { handleActions } from 'redux-actions';
import { SET_SECURITY_QUESTION } from '../../../actions/components/emailRecoveryActions';

const initialState = {
  securityQuestion: null,
  email: null,
};

const emailRecoveryReducer = handleActions(
  {
    [SET_SECURITY_QUESTION]: (state, { payload }) => ({
      ...state,
      securityQuestion: payload,
    }),
  },
  initialState,
);

export default emailRecoveryReducer;
