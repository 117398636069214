import React from 'react';
import { Route, Redirect } from 'react-router';
import { IMPLICIT_ROUTE_CALLBACK } from 'common/src/app/data/AuthConstants';
import Pages from 'common/src/app/data/enum/Pages';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import NotFound from 'bundle-loader?lazy&reactHot&name=NotFound!components/templates/NotFound';
import InvitationError from 'bundle-loader?lazy&reactHot&name=InvitationError!components/templates/InvitationError';
import ImplicitRedirectCallback from 'components/molecules/ImplicitRedirectCallback';
import Free2GoLanding from 'bundle-loader?lazy&reactHot&name=Free2GoLandingPage!components/templates/Free2GoLanding';
import TransferToOnlineRedirect from 'bundle-loader?lazy&reactHot&name=TransferToOnlineRedirect!../pages/TransferToOnlineRedirect';
import PolicyContentRoutes from '../pages/PolicyContent/routes';
import OnlineRegistrationRoutes from '../pages/OnlineRegistration/routes';
import GroupRegistrationRoutes from '../pages/GroupRegistration/routes';
import RecoverEmailRoutes from '../pages/RecoverEmail/routes';
import AccountLogin from '../pages/AccountLogin/routes';
import PaymentRoutes from '../pages/Payment/routes';
import StaticContentRoutes from '../pages/StaticContent/routes';
import Application from '../Application';

export default (
  <Route component={Application} title="{pageTitle}">
    {AccountLogin}
    {OnlineRegistrationRoutes}
    {GroupRegistrationRoutes}
    {PaymentRoutes}
    {PolicyContentRoutes}
    {StaticContentRoutes}
    {RecoverEmailRoutes}
    {/* Route added to intercept the old transfer route and route the member to a redirector page
    - the redirector page will send the member onto the new move flow - located on the member service */}
    <Route
      path={Pages.TRANSFER_TO_ONLINE}
      {...getRouteComponentProp(TransferToOnlineRedirect)}
      title="Moving to Slimming World Online Membership | {pageTitle}"
    />
    <Redirect from="/" to={Pages.LOGIN} />
    <Route path={IMPLICIT_ROUTE_CALLBACK} {...getRouteComponentProp(ImplicitRedirectCallback)} />
    <Route
      {...getRouteComponentProp(InvitationError)}
      path={Pages.INVITATION_ERROR}
      title="Invitation | Error | {pageTitle}"
    />
    <Route
      {...getRouteComponentProp(Free2GoLanding)}
      path={Pages.FREE_2_GO_LANDING}
      title="Free2Go Landing | {pageTitle}"
    />
    <Route
      status={404}
      path="*"
      {...getRouteComponentProp(NotFound)}
      title="Not Found | {pageTitle}"
    />
  </Route>
);
