import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import matchRoute from 'common/src/app/util/routeCheckUtils';
import Pages from 'common/src/app/data/enum/Pages';
import enhancedFormWizard from 'common/src/app/enhanced-redux-form/enhancedFormWizard';
import FormNames from 'common/src/app/data/enum/FormNames';
import ReactRouterWizardRoutingAdapter from 'common/src/app/enhanced-redux-form/enhancedFormWizard/ReactRouterWizardRoutingAdapter';
import { API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND } from 'common/src/app/data/apiComponents';

import RecoverEmail from './RecoverEmail';

const enhanced = enhancedFormWizard({
  name: FormNames.RE_RECOVER_EMAIL,
  routingAdapter: new ReactRouterWizardRoutingAdapter(),
  generalErrorMessage: { locale: 'recoverEmail.generalErrorMessage' },
});

const mapStateToProps = state => ({
  isSuccessStepIndex: matchRoute(Pages.RE_SUCCESS, state),
  publicHost: state.config.environmentConfig.web.public.host,
  background: state.entities?.[COMPONENT]?.[API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND]?.image,
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND)),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected, enhanced)(RecoverEmail);
