import { useRef, useEffect } from 'react';
import { useMutationObserver } from '@react-hooks-library/core';

declare global {
  interface Window {
    recaptchaCloseListener?: boolean;
  }
}

/**
 * Custom hook to listen for closing of ReCAPTCHA iframe.
 *
 * @param {boolean} recaptchaExecuted - Variable indicating whether ReCAPTCHA execution is triggered.
 * @param {(recaptchaToken: string | null, setReCAPTCHACancelled: React.Dispatch<React.SetStateAction<boolean>>) => void} onClose - Callback function to execute when ReCAPTCHA is closed.
 * @param {string | null} recaptchaToken - The token you get after passing
 * recaptcha successfully
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setReCAPTCHACancelled - Function to update state for ReCAPTCHA cancellation.
 *
 * @returns {void}
 */

const useRecaptchaCloseListener = (
  onClose: (
    recaptchaToken: string | null,
    setReCAPTCHACancelled: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void,
  recaptchaToken: string | null,
  setReCAPTCHACancelled: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  // Reference to the ReCAPTCHA token, to persist value across re-renders.
  const recaptchaTokenRef = useRef<string | null>(recaptchaToken);
  useEffect(() => {
    recaptchaTokenRef.current = recaptchaToken;
  }, [recaptchaToken]);

  useMutationObserver(
    document.body as HTMLElement | null,
    mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes') {
          const target = mutation.target as HTMLElement;
          if (target.style.opacity === '0') {
            const iframes = target.getElementsByTagName('iframe');
            Array.from(iframes).forEach(iframe => {
              if (iframe.src.includes('recaptcha')) {
                onClose(recaptchaTokenRef.current, setReCAPTCHACancelled);
              }
            });
          }
        }
      });
    },
    { attributes: true, childList: true, subtree: true, attributeFilter: ['style'] },
  );
};
export default useRecaptchaCloseListener;
