import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import ApplicationError from '../ApplicationError';
import GeneralError from './GeneralError';

const mapStateToProps = state => ({
  error: state.error && state.error[0],
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(ApplicationError)),
});

export default compose(addInitAction, connected)(GeneralError);
