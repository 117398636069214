/* global 'atom','QuotationBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import Icon from '../../../../../atoms/Icon';
import TextNew, { AccentColors, Colors } from '../../../../../atoms/TextNew';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import './quotation-block.scss';

const QuotationBlock = (
  { quotation, author, authorTextColor, authorPhoto, note, hasLargeQuoteStyle },
  context,
  className,
) => (
  <div className={classNames(className, { 'has-small-quotes': !hasLargeQuoteStyle })}>
    <blockquote>
      {hasLargeQuoteStyle && <Icon name={IconName.BLOCKQUOTE} width={76} />}

      <div
        className="quote"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: quotation }}
      />

      {hasLargeQuoteStyle && (
        <Icon name={IconName.BLOCKQUOTE} width={76} direction={DirectionType.UP} />
      )}
    </blockquote>

    <div className="author-details">
      {authorPhoto && (
        // eslint-disable-next-line react/prop-types
        <ResponsiveImage src={authorPhoto.src} alt={authorPhoto.alt} ratio={1} px={64} />
      )}

      <div className="quote-detail">
        {author && (
          <TextNew.Details cid="quotation-author" color={authorTextColor}>
            {author}
          </TextNew.Details>
        )}
        {note && <TextNew.PrimaryFootnote>{note}</TextNew.PrimaryFootnote>}
      </div>
    </div>
  </div>
);

QuotationBlock.defaultProps = {
  authorTextColor: Colors.BRAND,
  hasLargeQuoteStyle: true,
};

QuotationBlock.propTypes = {
  quotation: PropTypes.string.isRequired,
  author: PropTypes.string,
  note: PropTypes.string,
  authorTextColor: PropTypes.oneOf([...Colors.propTypes, ...AccentColors.propTypes]),
  authorPhoto: PropTypes.shape({
    ...imageShape,
  }),
  hasLargeQuoteStyle: PropTypes.bool,
};

export const ComponentProps = QuotationBlock;

export default withFunctionalClassName('atom','QuotationBlock')(QuotationBlock);
