import { handleActionsAsync } from 'common/src/app/util/asyncReducer';
import { GET_MEMBER_PASSWORD_VALIDATION_CONFIG } from 'common/src/app/actions/resources/accountActions';
import PasswordValidationConfig from 'common/src/app/validation/passwordValidationConfig';
import { defaultPasswordValidationConfig } from 'common/src/app/validation/passwordValidationConstants';

const initialState: { passwordValidationConfig: PasswordValidationConfig } = {
  passwordValidationConfig: defaultPasswordValidationConfig,
};

const accountReducer = handleActionsAsync(
  {
    [GET_MEMBER_PASSWORD_VALIDATION_CONFIG]: (
      state: any,
      { payload }: { payload: { data: PasswordValidationConfig } },
    ) => {
      const passwordValidationConfig = payload.data;

      if (!passwordValidationConfig) {
        return { ...state };
      }

      return {
        ...state,
        passwordValidationConfig,
      };
    },
  },
  initialState,
);

export default accountReducer;
