import {
  TOGGLE_BASKET_ACTIVE,
  SET_BASKET_ACTIVE,
  HIFI_MODAL_TRIGGERED,
} from '../../../actions/components/shopBasketActions';

const shop = (
  state = {
    isHifiModalTriggered: false,
  },
  action,
) => {
  switch (action.type) {
    case TOGGLE_BASKET_ACTIVE:
      return {
        ...state,
        isBasketActive: !state.isBasketActive,
      };
    case SET_BASKET_ACTIVE:
      return {
        ...state,
        isBasketActive: action.payload,
      };
    case HIFI_MODAL_TRIGGERED:
      return {
        ...state,
        isHifiModalTriggered: true,
      };
    default:
      return state;
  }
};

export default shop;
