import React from 'react';
import PropTypes from 'prop-types';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import TextNew, { FontSizes } from '../TextNew';

/* Namecopy returns the users name, Must be wrapper in FormValue */

const NameCopy = ({ greeting, name, deviceState }) => (
  <TextNew.Serif
    suppress
    cid="title"
    size={deviceState <= DeviceState.MD ? FontSizes.MD : FontSizes.XL}
  >
    {greeting} {name}
  </TextNew.Serif>
);

NameCopy.propTypes = {
  greeting: PropTypes.node,
  name: PropTypes.string,
  deviceState: PropTypes.number,
};

export default withDeviceState()(NameCopy);
