import handleActions from 'redux-actions/lib/handleActions';
import { SET_PASSWORD_VISIBILITY } from '../../../actions/components/passwordVisibilityActions';

const passwordInputFieldReducer = handleActions(
  {
    [SET_PASSWORD_VISIBILITY]: (state, { payload }) => payload,
  },
  false,
);

const passwordInputFormReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PASSWORD_VISIBILITY: {
      return {
        ...state,
        [action.meta.field]: passwordInputFieldReducer(state[action.meta.field], action),
      };
    }
    default:
      return state;
  }
};

const passwordInputReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PASSWORD_VISIBILITY: {
      return {
        ...state,
        [action.meta.form]: passwordInputFormReducer(state[action.meta.form], action),
      };
    }
    default:
      return state;
  }
};

export default passwordInputReducer;
