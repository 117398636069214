/* global WP_DEFINE_IS_NODE */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import formFieldPropTypes from 'common/src/app/util/form/formFieldPropTypes';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import { isIE } from 'common/src/app/util/browserUtil';
import numberFieldProps from 'components/atoms/utils/numberFieldProps';
import decimalFieldProps from 'components/atoms/utils/decimalFieldProps';
import { removeKey } from 'common/src/app/util/objectUtils';
import Icon from '../Icon';

import './input.scss';

/**
 * The <input> tag specifies an input field where the user can enter data.
 */
const Input = ({
  input: inputProps = {},
  autocomplete = 'on',
  asyncValidating,
  meta,
  placeholder = '',
  isInputWrapper = true,
  placeholderAbbreviation = '',
  descriptorType = 'animated',
  inputRef,
  showIconLockWhenDisabled,
  // should not show up in props
  form, // eslint-disable-line no-unused-vars
  dataTestid, // eslint-disable-line no-unused-vars
  hasValue,
  convertOnChange,
  suppress,
  label,
  id,
  ariaLabel,
  labelProps,
  ...props
}) => {
  const ieHack = {};
  /*
   * on IE, use onInput instead of onChange. See:
   * https://github.com/facebook/react/issues/7211
   * https://github.com/facebook/react/issues/7027
   * https://github.com/omcljs/om/issues/704
   */
  if (convertOnChange && !WP_DEFINE_IS_NODE && isIE()) {
    ieHack.onInput = inputProps.onChange;
    ieHack.onChange = null;
  }

  // Create a unique id for the input if it does not have an id yet. Using react hooks here so it doesn't happen on each render
  const [numberedId] = useState(() => uniqueId('input-'));

  // 'conditionalNumberProps' is added to ensure numeric input but to disable number change on scroll.
  const conditionalNumberProps = numberFieldProps(props.type);

  // 'conditionalDecimalProps' is added to ensure decimal input but to disable number change on scroll.
  const conditionalDecimalProps = decimalFieldProps(props.type);

  return (
    <div
      className={functionalComponentClassName(ComponentType.ATOM, 'input-wrapper', null, {
        [descriptorType]: !!descriptorType,
        active: meta && meta.active,
        dirty: meta && meta.dirty,
        error: meta && meta.error,
        pristine: meta && meta.pristine,
        valid: meta && meta.valid,
        'has-value': hasValue || (inputProps && !!inputProps.value),
        'has-placeholder': placeholder && !!placeholder,
        touched: meta && meta.touched,
        'is-input-wrapper': isInputWrapper,
        asyncValidating,
      })}
      data-testid={dataTestid && `${dataTestid}-wrapper`}
    >
      <label
        className={label ? 'input-label' : 'screen-reader'}
        htmlFor={id || numberedId}
        {...labelProps}
      >
        {label || ariaLabel || placeholder}
      </label>
      <input
        autoComplete={autocomplete}
        {...removeKey('descriptorType', props)}
        {...inputProps}
        {...ieHack}
        {...conditionalNumberProps}
        {...conditionalDecimalProps}
        aria-label={label || ariaLabel || placeholder}
        placeholder={placeholder}
        data-testid={dataTestid}
        data-hj-suppress={suppress}
        ref={inputRef}
        className={functionalComponentClassName(ComponentType.ATOM, 'Input')}
        id={id || numberedId}
      />
      {props.disabled && showIconLockWhenDisabled ? <Icon name={IconName.LOCK} /> : null}
      {placeholder && (
        <div className="descriptor-wrapper" aria-hidden="true">
          {descriptorType === descriptorTypes.STATIC && (
            <span className="descriptor">
              {placeholderAbbreviation && (
                <span className="abbreviation">{placeholderAbbreviation}</span>
              )}
            </span>
          )}
          {descriptorType === descriptorTypes.ANIMATED && (
            <span className="descriptor">
              <span className="mask" />
              {placeholder}
            </span>
          )}
          {descriptorType === descriptorTypes.GHOST && null}
        </div>
      )}
    </div>
  );
};

export const descriptorTypes = {
  ANIMATED: 'animated',
  STATIC: 'static',
  GHOST: 'ghost',
};

descriptorTypes.PropTypes = Object.values(descriptorTypes);

Input.defaultProps = {
  showIconLockWhenDisabled: true,
  convertOnChange: true,
};

Input.propTypes = {
  showIconLockWhenDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  /**
   *  This is a temporary workaround for the password input layout issues
   */
  isInputWrapper: PropTypes.bool,
  placeholderAbbreviation: PropTypes.node,
  /**
   * Input descriptorType
   */
  descriptorType: PropTypes.oneOf(descriptorTypes.PropTypes),
  inputRef: PropTypes.func,
  ...formFieldPropTypes,
  hasValue: PropTypes.bool,
  convertOnChange: PropTypes.bool,
  // Suppress is used for both our 3rd party tracking services (hotjar and glassbox)
  // - For hotjar we add a data-atrribute of data-hj-suppres
  // - For glassbox - we dont append anything - because all input fields are supressed by default
  suppress: PropTypes.bool,
  id: PropTypes.string,
  /**
   * Introduced for the WeightInput
   *
   * The input has for example a placeholder "00" but this needs to be an accessible translation
   * for what the input field is. With this hiddenLabel we can put a correct naming for that
   * input field which is hidden for the client but accessible by the screen reader
   */
  ariaLabel: PropTypes.string,
  /**
   * Optional labelProps when using the DownShift component
   */
  labelProps: PropTypes.object,
};

export default Input;
