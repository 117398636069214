import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import { getValue } from 'common/src/app/util/injector';
import { AUTHENTICATION_MANAGER } from 'common/src/app/data/Injectables';
import {
  LOGIN_RETURN_SESSION_PROP,
  IMPLICIT_ROUTE_CALLBACK,
} from 'common/src/app/data/AuthConstants';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import Loader from '../../atoms/Loader';

import './implicit-redirect-callback.scss';

class ImplicitRedirectCallback extends Component {
  componentDidMount() {
    const authenticationManager = getValue(AUTHENTICATION_MANAGER);

    authenticationManager.getUser().then(user => {
      let returnUrl = '/';

      // eslint-disable-next-line dot-notation
      if (window['sessionStorage'] && sessionStorage[LOGIN_RETURN_SESSION_PROP]) {
        // prevent redirect loops
        if (sessionStorage[LOGIN_RETURN_SESSION_PROP] !== IMPLICIT_ROUTE_CALLBACK) {
          returnUrl = sessionStorage[LOGIN_RETURN_SESSION_PROP];
        }
        sessionStorage.removeItem(LOGIN_RETURN_SESSION_PROP);
      }

      if (user) {
        this.props.historyPush({ pathname: returnUrl });
      } else {
        this.props.addError({
          errorCode: 'error.implicitRedirect.failed',
          statusCode: 500,
          errorMessage: this.context.getMessage('implicitRedirectCallback.errorMessage'),
        });
      }
    });
  }

  render() {
    return (
      <div {...componentClassNameProp(ComponentType.TEMPLATE, this)}>
        <Loader />
      </div>
    );
  }
}

ImplicitRedirectCallback.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

ImplicitRedirectCallback.propTypes = {
  historyPush: PropTypes.func.isRequired,
  addError: PropTypes.func.isRequired,
};

export default ImplicitRedirectCallback;
