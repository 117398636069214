import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';
import {
  hidePassword,
  showPassword,
} from 'common/src/app/actions/components/passwordVisibilityActions';
import { descriptorTypes } from '../Input';
import PasswordInput from './PasswordInput';

const outerPropTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  visibilityFrom: PropTypes.string,
  placeholder: PropTypes.string,
  showVisibilityToggle: PropTypes.bool,
  descriptorType: PropTypes.oneOf(descriptorTypes.PropTypes),
};

const ConnectedPasswordInput = connect(
  (state, props) => ({
    isVisible:
      (state.view.components.passwordInput[props.form] &&
        state.view.components.passwordInput[props.form][props.visibilityFrom || props.name]) ||
      false,
  }),
  {
    hidePassword,
    showPassword,
  },
)(PasswordInput);
ConnectedPasswordInput.propTypes = {
  ...outerPropTypes,
  form: PropTypes.string.isRequired,
};

const FormNameProvider = props => {
  const { form } = useContext(ReduxFormContext);
  return <ConnectedPasswordInput {...props} form={form} />;
};
FormNameProvider.propTypes = outerPropTypes;

export default FormNameProvider;
