import moment from 'moment';
import createAction from 'redux-actions/lib/createAction';
import { userIdSelector } from '../../selectors/userAccountSelectors';
import { GATEWAY_FOOD_AUTH } from '../../data/Injectables';
import { plannedDayByDateSelector, FORMAT_DATE } from '../../util/plannerActionUtils';
import { apiPost, apiPatch, apiDelete } from './apiActions/apiRequest';
import { mergeRecipe } from '../../util/mergeItemsInEntity';
import { PLANNED_DAY } from '../../data/entityTypes';
import { setEntity } from '../entities/entityActions';
import { getPlannerDayFoodStats, getPlannerDayFood } from './plannerFoodSummaryActions';

// TODO: SWO-4170 No longer pass the recipe slug and characteristics when the API is updated
// to return them
export const POST_RECIPE = 'plannerRecipeActions/POST_RECIPE';
export const addRecipe =
  (recipeId, mealCategoryId, slug, characteristics, portion) => (dispatch, getState) => {
    const state = getState();
    const planDate = state.planner.activePlannedDay || moment().format(FORMAT_DATE);
    const createPlannedDayByDateSelector = plannedDayByDateSelector(planDate);
    const userId = userIdSelector(state);
    const plannedDay = createPlannedDayByDateSelector(state);

    return dispatch(
      apiPost(POST_RECIPE, GATEWAY_FOOD_AUTH, `/members/${userId}/planned-recipes`, {
        plannedDayId: plannedDay ? plannedDay.id : 0,
        recipeId,
        mealCategoryId,
        slug,
        planDate,
        multiplePortionOrFractionOfSynValue: portion,
        syns: 0,
        characteristics,
      }),
    )
      .then(result => {
        const promises = [dispatch(getPlannerDayFoodStats(planDate))];
        if (state.entities.plannedDay?.[result.plannedDayId]) {
          const date = moment(result.planDateUtc).format('YYYY-MM-DD');
          promises.push(dispatch(setEntity(PLANNED_DAY, date, result, mergeRecipe)));
        } else {
          promises.push(dispatch(getPlannerDayFood(planDate)));
        }
        return promises;
      })
      .catch(e => {
        throw new Error(e);
      });
  };

export const UPDATE_RECIPE = 'plannerRecipeActions/UPDATE_RECIPE';
export const updateRecipe = (id, mealCategoryId, portion) => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  return dispatch(
    apiPatch(UPDATE_RECIPE, GATEWAY_FOOD_AUTH, `/members/${userId}/planned-recipes/${id}`, {
      mealCategoryId,
      multiplePortionOrFractionOfSynValue: portion,
    }),
  ).then(result => {
    const date = moment(result.planDate).format('YYYY-MM-DD');
    return dispatch(setEntity(PLANNED_DAY, date, result, mergeRecipe));
  });
};

export const DELETE_RECIPE = 'plannerRecipeActions/DELETE_RECIPE';
export const deleteRecipe = (activeDate, id) => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  return dispatch(
    apiDelete(DELETE_RECIPE, GATEWAY_FOOD_AUTH, `/members/${userId}/planned-recipes/${id}`),
  ).then(() => dispatch(setEntity(PLANNED_DAY, activeDate, { id, delete: true }, mergeRecipe)));
};

export const OPEN_MODAL_RECIPE_TRACKING = 'plannerActions/OPEN_MODAL_RECIPE_TRACKING';
export const openModalRecipeTracking = createAction(OPEN_MODAL_RECIPE_TRACKING);

export const SET_RECIPE_MODAL_OPEN = 'plannerActions/SET_RECIPE_MODAL_OPEN';
export const setRecipeModalOpen = createAction(SET_RECIPE_MODAL_OPEN);

export const STORE_RECIPE_CONTENT_ID = 'plannerRecipeActions/STORE_RECIPE_CONTENT_ID';
export const storeRecipeContentId = createAction(STORE_RECIPE_CONTENT_ID);
