import { apiPost } from './resources/apiActions/apiRequest';
import { getValue } from '../util/injector';
import { GATEWAY_CONTENT_V2 } from '../data/Injectables';

export const getAntiForgeryToken = () => () =>
  getValue(GATEWAY_CONTENT_V2).get('/aft', null, {
    credentials: 'include',
  });

export const SEND_CONTACT = 'accountActions/SEND_CONTACT';

const sendContact = values => dispatch =>
  dispatch(getAntiForgeryToken()).then(result =>
    dispatch(
      apiPost(SEND_CONTACT, GATEWAY_CONTENT_V2, '/contact', values, {
        headers: {
          // eslint-disable-next-line no-underscore-dangle
          'X-XSRF-Token': result.data.__RequestVerificationToken,
        },
        credentials: 'include',
      }),
    ),
  );

export default sendContact;
