import { connect } from 'react-redux';
import { compose } from 'redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import FormNames from 'common/src/app/data/enum/FormNames';
import { SessionStorageKeys } from 'common/src/app/data/enum/BrowserStorageKeys';
import RevealEmail from './RevealEmail';

const enhanced = enhancedReduxForm({
  form: FormNames.RE_RECOVER_EMAIL,
  destroyOnUnmount: false,
});

const mapStateToProps = () => state => {
  const userEmail = state.view?.components?.membershipCardCountry?.email;

  return {
    userEmail,
    setSessionEmail: () => {
      sessionStorage.setItem(
        SessionStorageKeys.RECOVERED_EMAIL_LOGIN,
        JSON.stringify({ [SessionStorageKeys.USER_EMAIL]: userEmail }),
      );
    },
  };
};

const connected = connect(mapStateToProps);

export default compose(enhanced, connected)(RevealEmail);
