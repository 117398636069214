import defaultReducers from 'common/src/app/reducers/defaultReducers';
import securityQuestions from 'common/src/app/reducers/securityQuestionsReducer';
import combineReducersNamed from 'common/src/app/util/reducers/combineReducersNamed';
import view from './viewReducer';
import registration from './registrationReducer';
import registrationFlow from './registrationFlow';
import emailConfirmation from './emailConfirmationReducer';
import identity from './identityReducer';
import resetPassword from './resetPasswordReducer';
import transfer from './transferReducer';
import multiFactorAuthentication from './multiFactorAuthenticationReducer';

const appReducer = combineReducersNamed({
  ...defaultReducers,
  registrationFlow,
  securityQuestions,
  view,
  registration,
  emailConfirmation,
  identity,
  resetPassword,
  transfer,
  multiFactorAuthentication,
})('');

export default appReducer;
