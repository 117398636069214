import React, { ChangeEvent, FocusEvent } from 'react';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import HeightInputImperial from './components/HeightInputImperial';
import HeightInputMetric from './components/HeightInputMetric';
import HeightInputValidation from './components/HeightInputValidation';
import HeightInputUnitToggle from './components/HeightInputUnitToggle';
import './height-input.scss';

type HeightInputProps = {
  legend: string;
  toggleHeightUnit: boolean;
  heightUnit: number;
  showValidationErrors: boolean;
  values: {
    metres?: number | null;
    centimetres?: number | null;
    fullInches?: number | null;
    feet?: number | null;
    remainingInches?: number | null;
  };
  onBlur: (e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLSelectElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => void;
};

const HeightInput = ({
  toggleHeightUnit,
  heightUnit,
  legend,
  showValidationErrors,
  values,
  ...restProps
}: HeightInputProps): JSX.Element => (
  <>
    <fieldset className="atom-height-input">
      {toggleHeightUnit && <HeightInputUnitToggle heightUnit={heightUnit} {...restProps} />}
      <legend className="screen-reader">{legend}</legend>
      <div className="height-inputs">
        {heightUnit === MeasureUnit.METRIC ? (
          <HeightInputMetric values={values} {...restProps} />
        ) : (
          <HeightInputImperial values={values} {...restProps} />
        )}
      </div>
      {showValidationErrors && <HeightInputValidation heightUnit={heightUnit} />}
    </fieldset>
  </>
);

HeightInput.defaultProps = {
  toggleHeightUnit: false,
  showValidationErrors: true,
};

export default HeightInput;
