import React, { useEffect, useState } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { TextTypes } from 'components/atoms/TextNew';
import classNames from 'classnames';
import AdyenCheckout from '@adyen/adyen-web';
import type { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import type { StoredPaymentMethod } from '@adyen/adyen-web/dist/types/types';
import adyen from './adyen.svg';

import './adyen-prebuilt-payment.scss';

export const filterDigitalStoredCard = (paymentMethods: StoredPaymentMethod[]) => {
  const excludeDigitalStoredCard = [
    'mc_applepay',
    'mc_googlepay',
    'visa_applepay',
    'visa_googlepay',
    'maestro_applepay',
    'maestro_googlepay',
  ];

  return paymentMethods.filter(
    item => item?.brand && !excludeDigitalStoredCard.includes(item.brand),
  );
};

type AdyenPrebuiltPaymentProps = {
  /*
   * Possible configurations of Adyens prebuilt solution.
   * Drop-in shows all payment methods as a list that are enabled. Card
   * (known in adyen docs as component) renders a single credit card form
   * without other payment methods (used in the manage card section)
   * See https://docs.adyen.com/online-payments/prebuilt-ui for more info
   */
  checkoutExperience?: 'dropin' | 'card';
  /**
   * Generated by paymentActions/ADYEN_CLIENT_SESSION, see
   * https://docs.adyen.com/online-payments/web-drop-in/optional-configuration
   * for full list of options
   */
  config?: CoreOptions;
  /**
   * These parameters are specific to Drop-in and are only accepted on the
   * dropin instance. For full list see https://docs.adyen.com/online-payments/web-drop-in/optional-configuration
   */
  dropInConfig?: {
    /**
     * Shows or hides payment methods with stored card details. Defaults to true.
     */
    showStoredPaymentMethods: boolean;
    /** Shows or hides regular (not stored) payment methods. Set to false if
     * you only want to show payment methods with stored card details. Defaults
     * to true.
     * /
    showPaymentMethods: boolean;
    /**
     * Allows the shopper to remove a stored payment method. Defaults to false.
     * If using this prop, you must also implement the
     */
    showRemovePaymentMethodButton: boolean;
    /** Called when a shopper removes a stored payment method. To remove the
     * selected payment method we call the disable endpoint
     */
    onDisableStoredPaymentMethod?: (storedPaymentMethodId: string) => void;
  };
  /**
   * An id passed within the query string by the Pre-built checkout when
   * returning from 3d auth
   */
  sessionId?: string;
  /**
   * URL which the user should be redirected back to if taken off to complete
   * 3d auth
   */
  redirectResult?: string;
  /**
   * When called it updates the contents of the Pre-built checkout (used in
   * scenarios where we clear the inputs on error)
   */
  retrigger?: boolean;
  /**
   * When called it unmount the Pre-built checkout from the DOM
   */
  unmount?: boolean;
  /**
   * Inform member of purchasing a 0 balance transaction to add card to payment list
   */
  footer: JSX.Element;
  paymentStatus: string;
  authFailed: boolean;
};

/**
 * Component which mounts Adyens Pre-built checkout
 *
 * See https://docs.adyen.com/online-payments/web-drop-in for more information
 * on this package
 */
const AdyenPrebuiltPayment = (
  {
    unmount,
    checkoutExperience = 'dropin',
    config,
    sessionId,
    redirectResult,
    retrigger,
    dropInConfig,
    footer,
    paymentStatus,
    authFailed,
  }: AdyenPrebuiltPaymentProps,
  {},
  className: string,
): JSX.Element => {
  const [checkoutInstance, setCheckoutInstance] = useState<any>();

  useEffect(() => {
    const startCheckout = async () => {
      if (config) {
        const checkout = await AdyenCheckout(config);

        // Hiding the digital saved card to encourage members to select a card from their digital wallet
        checkout.paymentMethodsResponse.storedPaymentMethods = filterDigitalStoredCard(
          checkout.paymentMethodsResponse.storedPaymentMethods,
        );

        const instance = checkout.create(checkoutExperience, dropInConfig || {});
        instance.mount('#card-container');
        setCheckoutInstance(instance);
      }
    };

    const finalizeCheckout = async () => {
      if (config) {
        const checkout = await AdyenCheckout({ ...config, session: { id: sessionId } });
        checkout.submitDetails({ details: { redirectResult } });
      }
    };

    /* empty the form (used in cases such as a failed payment attempt to
     allow a user to re-enter details and get the correct validation) */
    if (retrigger) {
      // eslint-disable-next-line no-unused-expressions
      checkoutInstance?.update();
    }
    /* called to unmount the payment component when leaving the current
    route to avoid errors */
    if (unmount) {
      // eslint-disable-next-line no-unused-expressions
      checkoutInstance?.unmount();
    }

    /* if there is not a sessionId(used for 3d auth) and not an existing checkout
    instance then create a one */
    if (!checkoutInstance && !sessionId) {
      startCheckout().catch();
    }

    /* if there if a sessionId then the user is returning from 3d auth look to
       finalize checkout */
    /* if the 3d auth is not successful set automatic retrigger to be false and show try again button  */
    if (sessionId && !retrigger && paymentStatus !== 'Refused') {
      finalizeCheckout().catch();
    }
  }, [
    config,
    sessionId,
    redirectResult,
    checkoutInstance,
    retrigger,
    unmount,
    dropInConfig,
    checkoutExperience,
    footer,
    authFailed,
  ]);

  return (
    <div
      className={classNames(className, {
        'auth-failed': authFailed,
      })}
    >
      <div
        className="adyen-prebuilt-checkout"
        data-testid="adyen-prebuilt-checkout"
        id="card-container"
      />
      {!authFailed && (
        <div className="adyen-secured">
          {footer && footer}

          <TextNew
            type={TextTypes.FORMAL_LIGHT}
            dataTestid="payment-secured"
            cid="payment-secure"
            localeId="adyenPrebuiltPayment.adyenDisclaimer"
          />
          <img src={adyen} alt={'adyen'} className="adyen-logo" data-testid="adyen-logo" />
        </div>
      )}
    </div>
  );
};

AdyenPrebuiltPayment.defaultProps = {
  checkoutExperience: 'dropin',
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'AdyenPrebuiltPayment',
)(AdyenPrebuiltPayment);
