import React from 'react';
import PropTypes from 'prop-types';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import FormikError from '../../../../../../FormikError';

const WeightInputValidation = ({ weightUnit }) => {
  if (weightUnit === MeasureUnit.IMPERIAL) {
    return (
      <>
        <FormikError name="stones" />
        <FormikError name="fullPounds" />
      </>
    );
  }

  return (
    <>
      <FormikError name="kilos" />
      <FormikError name="grams" />
    </>
  );
};

WeightInputValidation.propTypes = {
  weightUnit: PropTypes.oneOf([MeasureUnit.IMPERIAL, MeasureUnit.METRIC]),
};

export default WeightInputValidation;
