import React, { useRef } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  userDetailFields,
  emailFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { elementToScrollOnError } from 'common/src/app/validation/validationUtils';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Button from '../../atoms/Button';
import LocaleMessage from '../../atoms/LocaleMessage';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import Row from '../../atoms/Row';
import FormGroup from '../../molecules/FormGroup';
import TextNew, { FontWeight, FontSizes } from '../../atoms/TextNew';
import Input from '../../atoms/Input';
import EmailInput from '../../atoms/EmailInput/EmailInput';
import ConfirmTerms from '../../molecules/ConfirmTerms';
import NextStep from '../../molecules/NextStep';

import errorBoundary from '../../hoc/ErrorBoundary';

const Introduction = (
  {
    handleSubmit,
    isInvite,
    submitting = false,
    wizardSubmitting,
    deviceState,
    isGroupRegister,
    validateStep,
    errorScrollOrder,
    scrollToElement,
  },
  { getMessage },
  className,
) => {
  const fieldRefs = useRef([]);
  const customHandleSubmit = async event => {
    event.preventDefault();
    const { element } = await elementToScrollOnError({
      fieldRefs,
      submitHandler: handleSubmit,
      fieldOrder: errorScrollOrder,
      validateFormCallback: validateStep,
    });
    scrollToElement(element);
  };
  return (
    <form onSubmit={customHandleSubmit} className={className} autoComplete="off" noValidate>
      <TextNew.Serif
        localeId="introduction.greeting"
        size={deviceState <= DeviceState.MD ? FontSizes.MD : FontSizes.XL}
        cid="title"
      />
      <TextNew.Sans.MD
        weight={FontWeight.LIGHT}
        localeId="introduction.subtitle"
        cid="subtitle"
        size={deviceState <= DeviceState.MD ? FontSizes.SM : FontSizes.MD}
      />
      <FormGroup label={<LocaleMessage id="introduction.groupLabels.name" />} type="stacked">
        <Row column>
          <EnhancedField
            name={userDetailFields.FIRST_NAME}
            placeholder={getMessage('introduction.placeholders.firstName')}
            component={Input}
            type="text"
            inputRef={ref => (fieldRefs.current[userDetailFields.FIRST_NAME] = ref)}
            suppress
          />
          <FormErrorMessages fields={[userDetailFields.FIRST_NAME]} />
          <EnhancedField
            name={userDetailFields.LAST_NAME}
            placeholder={getMessage('introduction.placeholders.lastName')}
            component={Input}
            type="text"
            inputRef={ref => (fieldRefs.current[userDetailFields.LAST_NAME] = ref)}
            suppress
          />
          <FormErrorMessages fields={[userDetailFields.LAST_NAME]} />
        </Row>
      </FormGroup>

      <EmailInput
        isInvite={isInvite}
        title={'introduction.emailLabel'}
        forwardRef={{
          emailRef: ref => (fieldRefs.current[emailFields.EMAIL_ADDRESS] = ref),
          confirmEmailRef: ref => (fieldRefs.current[emailFields.CONFIRM_EMAIL_ADDRESS] = ref),
        }}
      />

      <Row justifystart column className={className}>
        <ConfirmTerms
          showPrivacyPromise
          showTermsAgreement={false}
          isGroupRegister={isGroupRegister}
        />
      </Row>

      <NextStep descriptionLocale="introduction.nextDescription">
        <Button
          ripple
          type="submit"
          disabled={submitting || wizardSubmitting}
          isLoading={submitting || wizardSubmitting}
        >
          <LocaleMessage id="general.cta.continue" />
        </Button>
      </NextStep>
    </form>
  );
};

Introduction.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

Introduction.propTypes = {
  isInvite: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  wizardSubmitting: PropTypes.bool,
  isGroupRegister: PropTypes.bool,
  deviceState: PropTypes.number,
  validateStep: PropTypes.func,
  errorScrollOrder: PropTypes.array,
  scrollToElement: PropTypes.func,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.TEMPLATE, 'Introduction'),
)(Introduction);
