import React from 'react';
import PropTypes from 'prop-types';
import { MemberTypeStrings } from 'common/src/app/data/enum/MemberType';
import AgeRanges from 'common/src/app/data/enum/AgeRanges';
import Pages from 'common/src/app/data/enum/Pages';
import toUpper from 'lodash/toUpper';
import Link from 'react-router/lib/Link';
import LocaleMessage from '../../../../atoms/LocaleMessage';
import TextNew from '../../../../atoms/TextNew';

const MedicalCheckAgeError = ({ isSixteenToSeventeen, registrationType, publicHost }) => (
  <>
    <TextNew.PrimaryElegant
      localeId={`medicalCheck.blockingErrors.ageNotAllowed.${registrationType}.main`}
      localeParams={{
        ...AgeRanges[toUpper(registrationType)],
      }}
    />
    {isSixteenToSeventeen && (
      <TextNew.PrimaryOriginal>
        <Link to={`${publicHost}${Pages.SHOP_LANDING_MEMBERSHIP}`} target="_blank">
          <LocaleMessage
            id={`medicalCheck.blockingErrors.ageNotAllowed.${registrationType}.findOutMoreCTA`}
          />
        </Link>
      </TextNew.PrimaryOriginal>
    )}
  </>
);

MedicalCheckAgeError.propTypes = {
  isSixteenToSeventeen: PropTypes.bool,
  registrationType: PropTypes.oneOf([MemberTypeStrings.GROUP, MemberTypeStrings.ONLINE]),
  publicHost: PropTypes.string.isRequired,
};

export default MedicalCheckAgeError;
