import pick from 'lodash/pick';
import { createAction } from 'redux-actions';

export const STORE_IDENTITY = 'identityActions/STORE_IDENTITY';
export const CLAIMS_TO_STORE = [
  'name',
  'email',
  'sub',
  'preferred_username',
  'account_state',
  'amr',
  'role',
];

export const storeIdentity = createAction(STORE_IDENTITY, ticket => {
  if (ticket) {
    const {
      identities: [identity],
    } = ticket;

    if (identity) {
      return pick(identity.parsedClaims, CLAIMS_TO_STORE);
    }
  }

  return null;
});
