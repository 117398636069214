import composeDate from './composeDate';
import decomposeDate from './decomposeDate';

import composeDuration from './composeDuration';
import decomposeDuration from './decomposeDuration';

import composeHeightImperialToMetric from './composeHeightImperialToMetric';
import composeHeightMetric from './composeHeightMetric';
import composeWeightImperialToMetric from './composeWeightImperialToMetric';
import composeWeightImperialWithStonesToMetric from './composeWeightImperialWithStonesToMetric';
import composeWeightMetric from './composeWeightMetric';
import decomposeHeightImperialToMetric from './decomposeHeightImperialToMetric';
import decomposeHeightMetric from './decomposeHeightMetric';
import decomposeWeightImperialToMetric from './decomposeWeightImperialToMetric';
import decomposeWeightImperialWithStonesToMetric from './decomposeWeightImperialWithStonesToMetric';
import decomposeWeightMetric from './decomposeWeightMetric';
import decomposeCheckboxesToBitwiseArray from './decomposeCheckboxesToBitwiseArray';

import composeCheckboxesToBitwise from './composeCheckboxesToBitwise';
import composeCheckboxesToArray from './composeCheckboxesToArray';
import decomposeCheckboxesToArray from './decomposeCheckboxesToArray';
import decomposeCheckboxesToBitwise from './decomposeCheckboxesToBitwise';
import composeCheckboxesToBitwiseArray from './composeCheckboxesToBitwiseArray';

import composeCheckboxesToCommaDelimitedString from './composeCheckboxesToCommaDelimitedString';
import decomposeCheckboxesToCommaDelimitedString from './decomposeCheckboxesToCommaDelimitedString';

/**
 * Formatter functions that can be used to combine multiple form values into a single
 * value using the `<CompositeInput />` component.
 * @module enhanced-redux-form/compositeInputFormatters
 * @category forms
 */

/**
 * Object with definitions of formatter methods to combine multiple inputs into
 * a single value. Can be used as value for the 'formatter' prop of the
 * CompositeInput component.
 *
 * Please note: this object is patched at the bottom of this file to include the keys
 * in the values.
 */
const formatters = {
  DATE: {
    compose: composeDate,
    decompose: decomposeDate,
  },
  HEIGHT_FEET_TO_CM: {
    compose: composeHeightImperialToMetric,
    decompose: decomposeHeightImperialToMetric,
  },
  HEIGHT_CM: {
    compose: composeHeightMetric,
    decompose: decomposeHeightMetric,
  },
  WEIGHT_POUNDS_TO_KILO: {
    compose: composeWeightImperialToMetric,
    decompose: decomposeWeightImperialToMetric,
  },
  WEIGHT_STONES_POUNDS_TO_KILO: {
    compose: composeWeightImperialWithStonesToMetric,
    decompose: decomposeWeightImperialWithStonesToMetric,
  },
  WEIGHT_KILO: {
    compose: composeWeightMetric,
    decompose: decomposeWeightMetric,
  },
  CHECKBOXES_TO_BITWISE: {
    compose: composeCheckboxesToBitwise,
    decompose: decomposeCheckboxesToBitwise,
  },
  CHECKBOXES_TO_ARRAY: {
    compose: composeCheckboxesToArray,
    decompose: decomposeCheckboxesToArray,
  },
  DURATION: {
    compose: composeDuration,
    decompose: decomposeDuration,
  },
  CHECKBOXES_TO_BITWISE_ARRAY: {
    compose: composeCheckboxesToBitwiseArray,
    decompose: decomposeCheckboxesToBitwiseArray,
  },
  CHECKBOXES_TO_COMMA_DELIMITED_STRING: {
    compose: composeCheckboxesToCommaDelimitedString,
    decompose: decomposeCheckboxesToCommaDelimitedString,
  },
};

// Patch the formatters object so the name can be extracted from each value
Object.keys(formatters).forEach(name => {
  formatters[name] = {
    name,
    formatter: formatters[name],
  };
});

export default formatters;
