/*
 * These files contains constants used for authentication in frontend and backend.
 * Should not contain any sensitive data
 */
export const LOGIN_RETURN_SESSION_PROP = 'loginReturnUrl';
export const AUTH_CODE_LOGIN_CALLBACK_ROUTE = '/login';
export const IMPLICIT_ROUTE_POPUP = '/implicit-popup';
export const IMPLICIT_ROUTE_FRAME = '/implicit-frame';
export const IMPLICIT_ROUTE_CALLBACK = '/implicit-callback';
export const IMPLICIT_ROUTE_SILENT_RENEW = '/implicit-silent-renew';
/**
 * TODO: GR-397 we need to implement a proper solution to renewing access tokens after
 * we makes changes to accountState. This is a temporary solution to get transfers working.
 */
export const IMPLICIT_ROUTE_SILENT_RENEW_TOKEN = '/implicit-silent-renew-token';
export const OID_SERVER_SCOPES = ['node'];
export const OID_CLIENT_SCOPES = ['profile', 'email', 'api'];
// this is the name of the legacy cookie for authentication. it combined the access and id tokens
export const TOKEN_COOKIE = 'authToken';
// current cookie names for access and id tokens. they are split up into two cookies
// if you make changes to the constants, make sure to also update the clear-client-session.html
export const STATE_COOKIE = 'authState';
export const INITIAL_USER_STATE_COOKIE = 'ius';
