import BodyMagicAwards from 'common/src/app/data/enum/BodyMagicAwards';
/**
 * Retrieves asset data
 * @param awardId
 */
const getAssetData = awardId => awardDataMap[awardId] || null;
export default getAssetData;

/**
 * Type of awards, also used for collections
 * @type {{NORMAL: number, WEIGHT: number}}
 */
export const AwardType = {
  NORMAL: 0,
  WEIGHT: 1,
  SLIMMER: 2,
  BODY_MAGIC: 3,
  LEARNING_CONTENT: 4,
};
export const AwardTypeString = {
  0: 'normal',
  1: 'weight',
  2: 'slimmer',
  3: 'body_magic',
  4: 'learning_content',
};

/**
 * Award display type determines which info to render in award component
 * @type {{IMAGE: string, LABEL: string, FULL_CONTENT: string}}
 */
export const AwardDisplayType = {
  IMAGE: 'image-only',
  FULL_CONTENT: 'full-content',
};

/**
 * Constant name is mapped to backend enum value
 */
export const AwardMap = {
  HELLO: 0,
  PROFILE_COMPLETED: 1,
  FIRST_POST: 2,
  FABULOUS: 3,
  LIFELINE: 4,
  CLUB: 5,
  TARGET: 8,
  FOOD_DUDE: 9,
  GRADUATE: 10,
  BRONZE: 11,
  SILVER: 12,
  GOLD: 13,
  PLATINUM: 14,
  HERO: 15,
  NAVIGATION_KNOW_HOW: 16,
  LOST_10_PERCENT: 21,
  WEIGHT_AWARD: 255,
  HALL_OF_FAME_SLIMMER_OF_THE_WEEK: 107,
  HALL_OF_FAME_SLIMMER_OF_THE_MONTH: 108,
  HALL_OF_FAME_NEW_SLIMMER_OF_THE_WEEK: 109,
  GROUP_SLIMMER_OF_THE_WEEK: 110,
  GROUP_SLIMMER_OF_THE_MONTH: 111,
  BODY_MAGIC_AWARD_PLACEHOLDER: 112,
  FOOD_OPTIMISING_AWARD: 22,
  MAGIC_MOVER: 23,
};

// Map body magic awards to the community equivilent
export const BodyMagicGoalTypeToAwardMap = {
  [BodyMagicAwards.BRONZE]: AwardMap.BRONZE,
  [BodyMagicAwards.SILVER]: AwardMap.SILVER,
  [BodyMagicAwards.GOLD]: AwardMap.GOLD,
  [BodyMagicAwards.PLATINUM]: AwardMap.PLATINUM,
};

/**
 * Award data mapping
 *
 * if AwardType.NORMAL, a locale reference (plus locale entry in the award component) is needed
 * if AwardType.WEIGHT a locale is optional, otherwise will display default message
 */
const awardDataMap = {
  [AwardMap.HELLO]: {
    locale: 'hello',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.PROFILE_COMPLETED]: {
    locale: 'profileCompleted',
    type: AwardType.NORMAL,
    svgSrc: './basics-completed.svg',
  },
  [AwardMap.FIRST_POST]: {
    locale: 'firstPost',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.FABULOUS]: {
    locale: 'fabulous',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.LIFELINE]: {
    locale: 'lifeline',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.CLUB]: {
    locale: 'club',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.LOST_10_PERCENT]: {
    locale: 'lost_10Percent',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './club-10.svg',
  },
  [AwardMap.TARGET]: {
    locale: 'target',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './target.svg',
  },
  [AwardMap.FOOD_DUDE]: {
    locale: 'foodDude',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.GRADUATE]: {
    locale: 'graduate',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.BRONZE]: {
    locale: 'bronze',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './body-magic-award-bronze.svg',
  },
  [AwardMap.SILVER]: {
    locale: 'silver',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './body-magic-award-silver.svg',
  },
  [AwardMap.GOLD]: {
    locale: 'gold',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './body-magic-award-gold.svg',
  },
  [AwardMap.PLATINUM]: {
    locale: 'platinum',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './body-magic-award-platinum.svg',
  },
  [AwardMap.BODY_MAGIC_AWARD_PLACEHOLDER]: {
    locale: 'bodyMagicAwardPlaceholder',
    type: AwardType.NORMAL,
    svgSrc: './body-magic-start-award.svg',
  },
  [AwardMap.HERO]: {
    locale: 'hero',
    type: AwardType.NORMAL,
    svgSrc: './short-logo.svg',
  },
  [AwardMap.WEIGHT_AWARD]: {
    type: AwardType.WEIGHT,
    displayOverride: true,
  },
  [AwardMap.HALL_OF_FAME_SLIMMER_OF_THE_WEEK]: {
    locale: 'slimmerOfTheWeek',
    type: AwardType.SLIMMER,
    color: 3,
    svgSrc: './slimmerOfTheWeek.svg',
  },
  [AwardMap.HALL_OF_FAME_SLIMMER_OF_THE_MONTH]: {
    locale: 'slimmerOfTheMonth',
    type: AwardType.SLIMMER,
    color: 4,
    svgSrc: './slimmerOfTheMonth.svg',
  },
  [AwardMap.GROUP_SLIMMER_OF_THE_WEEK]: {
    locale: 'slimmerOfTheWeek',
    type: AwardType.NORMAL,
    displayOverride: true,
    color: 3,
    svgSrc: './slimmer-week.svg',
  },
  [AwardMap.GROUP_SLIMMER_OF_THE_MONTH]: {
    locale: 'slimmerOfTheMonth',
    type: AwardType.NORMAL,
    displayOverride: true,
    color: 4,
    svgSrc: './slimmer-month.svg',
  },
  [AwardMap.HALL_OF_FAME_NEW_SLIMMER_OF_THE_WEEK]: {
    locale: 'fabulous',
    type: AwardType.SLIMMER,
    color: 1,
    svgSrc: './fabulous.svg',
  },
  [AwardMap.NAVIGATION_KNOW_HOW]: {
    locale: 'navigationKnowHow',
    type: AwardType.NORMAL,
    svgSrc: './navigation-know-how.svg',
  },
  [AwardMap.FOOD_OPTIMISING_AWARD]: {
    locale: 'foodOptimisingAward',
    type: AwardType.NORMAL,
    svgSrc: './food-optimising-award.svg',
  },
  [AwardMap.MAGIC_MOVER]: {
    locale: 'magicMoverAward',
    type: AwardType.NORMAL,
    displayOverride: true,
    svgSrc: './magic-mover-award.svg',
  },
};

/**
 * These collections are used to drive which awards are grouped together on the members profile screen
 */
export const awardCollection = {
  [AwardType.WEIGHT]: [AwardMap.WEIGHT_AWARD, AwardMap.LOST_10_PERCENT, AwardMap.TARGET],
  [AwardType.NORMAL]: [
    AwardMap.HELLO,
    AwardMap.PROFILE_COMPLETED,
    AwardMap.FIRST_POST,
    AwardMap.FABULOUS,
    AwardMap.LIFELINE,
    AwardMap.CLUB,
    AwardMap.GROUP_SLIMMER_OF_THE_WEEK,
    AwardMap.GROUP_SLIMMER_OF_THE_MONTH,
    AwardMap.FOOD_DUDE,
    AwardMap.GRADUATE,
    AwardMap.HERO,
    AwardMap.NAVIGATION_KNOW_HOW,
    AwardMap.HALL_OF_FAME_SLIMMER_OF_THE_WEEK,
    AwardMap.HALL_OF_FAME_SLIMMER_OF_THE_MONTH,
    AwardMap.HALL_OF_FAME_NEW_SLIMMER_OF_THE_WEEK,
    AwardMap.FOOD_OPTIMISING_AWARD,
  ],
  [AwardType.BODY_MAGIC]: [
    AwardMap.BRONZE,
    AwardMap.SILVER,
    AwardMap.GOLD,
    AwardMap.PLATINUM,
    AwardMap.MAGIC_MOVER,
  ],
};

export const GroupMonthAwards = {
  NONE: 1,
  THIS_MONTH: 2,
  LAST_MONTH: 3,
};

// The object keys the weigh-ins/weight API call requires
export const GroupMonthAwardsKeys = {
  SLIMMER_OF_THE_WEEK: 'slimmerOfTheWeek',
  SLIMMER_OF_THE_MONTH: 'slimmerOfTheMonth',
};

export const UpcommingAwardClass = {
  UPCOMING_AWARD_WEIGHT: 'upcoming-award-weight',
  UPCOMING_AWARD: 'upcoming-award',
};
