import { SET_RENDER_MODE } from '../actions/renderActions';
import {
  SET_ENVIRONMENT_CONFIG,
  SET_VIEWER_COUNTRY,
  SET_WEBVIEW_COOKIE,
  SET_FREE_2_GO,
  SET_RECAPTCHA_TOKEN,
  SET_RECAPTCHA_STATUS,
  SET_IS_IN_APP_BROWSER,
} from '../actions/configActions';

const configReducer = (
  state = {
    renderMode: 'client',
    viewerCountry: null,
    environmentConfig: {},
    isWebView: false,
    isFree2GoApp: false,
    recaptchaToken: null,
    recaptchaExecuted: null,
    isInAppBrowser: false,
  },
  action,
) => {
  switch (action.type) {
    case SET_RENDER_MODE:
      return {
        ...state,
        renderMode: action.payload,
      };
    case SET_ENVIRONMENT_CONFIG:
      return {
        ...state,
        environmentConfig: action.payload,
      };
    case SET_VIEWER_COUNTRY:
      return {
        ...state,
        viewerCountry: action.payload,
      };
    case SET_WEBVIEW_COOKIE:
      return {
        ...state,
        isWebView: action.payload,
      };
    case SET_FREE_2_GO:
      return {
        ...state,
        isFree2GoApp: action.payload,
      };
    case SET_RECAPTCHA_TOKEN:
      return {
        ...state,
        recaptchaToken: action.payload,
      };
    case SET_RECAPTCHA_STATUS:
      return {
        ...state,
        recaptchaExecuted: action.payload,
      };
    case SET_IS_IN_APP_BROWSER:
      return {
        ...state,
        isInAppBrowser: action.payload,
      };
    default:
      return state;
  }
};

export default configReducer;
