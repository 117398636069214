import { conformToMask } from 'text-mask-core';
import { PHONE_NUMBER_MASK } from '../../data/regexPatterns';

const PhoneNumberDefault = {
  MASK: new Array(20).fill(/\d/), // 20 numbers
  OPTIONS: { guide: false },
};

export const phoneNumberFormatter = (
  value,
  mask = PhoneNumberDefault.MASK,
  options = PhoneNumberDefault.OPTIONS,
) => conformToMask(value, mask, options).conformedValue;

export const phoneNumberByCountryFormatter = (value, country) => {
  // If there is no value, then return an empty string
  if (!value) return '';

  // Trim any leading or trailing whitespace
  const trimmedValue = value.trim();

  const mask = PHONE_NUMBER_MASK[country] || undefined;

  return phoneNumberFormatter(trimmedValue, mask);
};
