/**
 * Converts values from centimetres to { metres: 0, centimetres: 0 }
 * @param {string} centimetres A string that contains an integer representing
 * the amount of centimetres
 *
 * @returns {object} The resulting number value in { metres: 0, centimetres: 0 }
 */
const decomposeHeightMetric = centimetres => {
  if (typeof centimetres === 'undefined') {
    return { metres: '', centimetres: '' };
  }
  const parsedCentimetres = centimetres || 0;

  const parsedMetres = Math.floor(parsedCentimetres / 100);
  const wholeCentimetres = Math.floor(parsedCentimetres % 100);

  return {
    metres: parsedMetres,
    centimetres: wholeCentimetres,
  };
};

export default decomposeHeightMetric;
