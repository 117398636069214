const isQuotaExceededError = (error: Error) => {
  const errorCodes = [
    22, // non Firefox
    1014, // Firefox
  ];

  const errorNames = [
    'QuotaExceededError', // non Firefox
    'NS_ERROR_DOM_QUOTA_REACHED', // Firefox
  ];

  return (
    error instanceof DOMException &&
    (errorCodes.includes(error.code) || errorNames.includes(error.name))
  );
};

export default isQuotaExceededError;
