import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer'; // eslint-disable-line
import { getVariantUrl } from 'common/src/app/util/imageSizeUtils';
import testImageUrl from 'common/src/app/util/imageUtils/testImageUrl';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Loader from '../Loader';
import './image.scss';

type DefaultImageProps = {
  isBackgroundImage: boolean;
  useWebp: boolean;
  localClassName: string;
};

type ImageProps = {
  src?: string;
  alt?: string;
  className?: string;
  inView?: boolean;
  usePlaceholder?: boolean;
  ratio?: number;
  dataTestid?: string;
  children?: React.ReactNode;
} & DefaultImageProps;

const PX_SIZE_CAP = 2000;
const IMAGE_THRESHOLD = 160;

const empty1pxImage =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const dimensionSizeCap = (size: number, useThreshold: boolean) =>
  Math.min(
    useThreshold ? Math.ceil(size / IMAGE_THRESHOLD) * IMAGE_THRESHOLD : Math.round(size),
    PX_SIZE_CAP,
  );

const Image = ({
  src = '',
  alt,
  ratio,
  isBackgroundImage,
  children,
  inView,
  usePlaceholder,
  className,
  useWebp,
  localClassName,
  dataTestid,
}: ImageProps): JSX.Element => {
  if (!src || typeof src !== 'string' || typeof window === 'undefined') {
    console.log('src must be passed in and be a type of string');
  }

  if (!isBackgroundImage && !ratio) {
    console.log('Ratio must be passed in correctly to calculate image size');
  }

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  useEffect(() => {
    testImageUrl(src).then(result => setImageLoaded(result));
  }, [src]);

  const divRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [currentRef, setCurrentRef] =
    useState<React.RefObject<HTMLDivElement | HTMLImageElement | SVGSVGElement>>();
  useEffect(() => {
    let currentRefObject: React.RefObject<HTMLDivElement | HTMLImageElement | SVGSVGElement>;
    if (isBackgroundImage) {
      currentRefObject = divRef;
    } else {
      currentRefObject = !usePlaceholder || imageLoaded ? imageRef : svgRef;
    }
    setCurrentRef(previousState => {
      if (currentRefObject === previousState) return previousState;
      return currentRefObject;
    });
  }, [imageLoaded, isBackgroundImage, usePlaceholder]);

  const defaultWidth = ratio && ratio < 1 ? Math.floor(300 * ratio) : 300;
  const defaultHeight = ratio && ratio > 1 ? Math.floor(300 / ratio) : 300;
  const { width = defaultWidth, height = defaultHeight } = useResizeObserver({
    ref: currentRef,
  });

  const finalWidth = dimensionSizeCap(width, isBackgroundImage);
  const finalHeight = dimensionSizeCap(height, isBackgroundImage);

  const zeroSizeImage = finalWidth === 0 || finalHeight === 0;
  const cropSrc =
    !zeroSizeImage && inView
      ? getVariantUrl({
          url: src,
          width: finalWidth,
          ratio: ratio || finalWidth / finalHeight,
          useWebp,
        })
      : empty1pxImage;

  if (isBackgroundImage) {
    return (
      <div
        className={`${className}-background ${localClassName}`}
        ref={divRef}
        style={{
          backgroundImage: `url(${cropSrc})`,
        }}
        data-testid={dataTestid}
      >
        {children}
      </div>
    );
  }

  const imageHeight = ratio ? Math.floor(finalWidth / ratio) : finalHeight;

  const hiddenImageStyles: Pick<CSSProperties, 'width' | 'height'> = {
    width: `${finalWidth}px`,
    height: `${imageHeight}px`,
  };

  return (
    <>
      {children}
      {usePlaceholder && !imageLoaded && (
        <svg
          className="placeholder-image"
          data-testid="placeholder-image"
          aria-label={alt}
          width={`${finalWidth}px`}
          height={`${imageHeight}px`}
          ref={svgRef}
        >
          <rect width={`${finalWidth}px`} height={`${imageHeight}px`} fill="#fff" />
        </svg>
      )}
      <img
        data-testid={dataTestid}
        width={`${finalWidth}px`}
        height={`${imageHeight}px`}
        style={usePlaceholder && !imageLoaded ? hiddenImageStyles : undefined}
        className={classNames(className, { 'atom-image-hidden': usePlaceholder && !imageLoaded })}
        ref={imageRef}
        src={cropSrc}
        alt={alt}
      />
    </>
  );
};

const defaultImageProps: DefaultImageProps = {
  isBackgroundImage: false,
  useWebp: true,
  localClassName: '',
};

type LazyLoadImageWrapperProps = {
  isLazy: boolean;
};

const LazyLoadImageWrapper = (
  { isLazy, localClassName, ...props }: LazyLoadImageWrapperProps & ImageProps,
  _context: unknown,
  className: string,
  dataTestid: string,
) => {
  const [ref, inView] = useInView({
    rootMargin: '200px',
    threshold: 0,
    triggerOnce: true,
  });
  const classes = localClassName
    ? `${className}-wrapper ${localClassName}`
    : `${className}-wrapper`;
  return (
    <div ref={ref} className={classes}>
      {!inView && isLazy && <Loader />}
      <Image
        dataTestid={dataTestid}
        inView={isLazy ? inView : true}
        {...props}
        className={className}
        localClassName={localClassName}
      />
    </div>
  );
};

const LazyLoadImageWrapperE = withFunctionalClassName(
  ComponentType.ATOM,
  'Image',
)(LazyLoadImageWrapper);

const HookWrapper = (props: LazyLoadImageWrapperProps & ImageProps): JSX.Element => (
  <>{typeof window !== 'undefined' && <LazyLoadImageWrapperE {...props} />}</>
);

const DefaultHookWrapperProps: LazyLoadImageWrapperProps & DefaultImageProps = {
  isLazy: false,
  ...defaultImageProps,
};

HookWrapper.defaultProps = DefaultHookWrapperProps;

export default HookWrapper;
