import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import TextNew, { ElementTypes } from '../../atoms/TextNew';

import './locale-bulleted-list.scss';

const LocaleBulletedList = ({ children, localeId, localeParams }, { getMessage }, className) => {
  const items = getMessage(localeId, localeParams).split('\n');

  return (
    <section className={className}>
      <TextNew.PrimaryElegant element={ElementTypes.UL} localeParams={localeParams}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}

        {children}
      </TextNew.PrimaryElegant>
    </section>
  );
};

LocaleBulletedList.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

LocaleBulletedList.propTypes = {
  children: PropTypes.node,
  localeId: PropTypes.string.isRequired,
  localeParams: PropTypes.object,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'LocaleBulletedList',
)(LocaleBulletedList);
