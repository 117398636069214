import { handleActions } from 'redux-actions';
import { GET_VOUCHER_INFO } from 'common/src/app/actions/resources/transferActions';

const initialState = {
  voucherInfo: null,
};

export default handleActions(
  {
    // Online to group, how many weeks voucher you are eligible for
    [GET_VOUCHER_INFO]: (state, response) => ({
      ...state,
      voucherInfo: response.payload.data,
    }),
  },
  initialState,
);
