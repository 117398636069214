import { loginFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { push as historyPush } from 'react-router-redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { openModal } from 'common/src/app/actions/components/modalActions';
import { makeGetGeneralFormErrorCode } from 'common/src/app/enhanced-redux-form/reducers';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import * as ValidateOn from 'common/src/app/enhanced-redux-form/data/ValidateOn';
import { required } from 'common/src/app/util/form/basic-validations';
import FormNames from 'common/src/app/data/enum/FormNames';
import { login } from 'common/src/app/actions/resources/accountActions';
import Pages from 'common/src/app/data/enum/Pages';
import LoginErrorCode from 'common/src/app/data/enum/LoginErrorCode';
import LocalStorageKeys, { SessionStorageKeys } from 'common/src/app/data/enum/BrowserStorageKeys';
import { groupRegistrationActive } from 'common/src/app/selectors/featureFlagSelectors';
import Login from './Login';

const LoginValidation = {
  [loginFields.USER_NAME]: {
    validators: [
      {
        rule: required,
        message: { locale: 'login.validators.username' },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
  [loginFields.PASSWORD]: {
    validators: [
      {
        rule: required,
        message: { locale: 'login.validators.password' },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

const enhanced = enhancedReduxForm(
  {
    form: FormNames.LOGIN,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmit: ({ userName, rememberMe, password }, dispatch, { location }) => {
      localStorage.removeItem(LocalStorageKeys.MFA_VERIFY);

      if (rememberMe) {
        localStorage.setItem(LocalStorageKeys.LOGIN, JSON.stringify({ userName, rememberMe }));
      } else {
        localStorage.removeItem(LocalStorageKeys.LOGIN);
      }

      return dispatch(login({ userName, rememberMe, password }, location));
    },
  },
  LoginValidation,
);

const mapStateToProps = () => {
  const getGeneralFormErrorCode = makeGetGeneralFormErrorCode();
  const getErrorsForFields = makeGetErrorsForFields();

  return (state, props) => {
    const generalErrorCode = getGeneralFormErrorCode(state.enhancedForm, props);
    const errors = getErrorsForFields(state.enhancedForm.validation, {
      form: FormNames.LOGIN,
      fields: [loginFields.USER_NAME],
    });

    const { isWebView } = state.config;

    return {
      generalErrorCode,
      legacyLoginAttempt: !!errors.find(
        error => error.code === LoginErrorCode.LOGIN_VALIDATORS_LEGACY,
      ),
      isWebView,
      isGroupRegOpen: groupRegistrationActive(state),
    };
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openModal: (...args) => dispatch(openModal(...args)),
  setInitialValuesOnMount: isWebView => {
    let persistedLoginDetails;

    try {
      persistedLoginDetails = {
        ...JSON.parse(sessionStorage.getItem(LocalStorageKeys.LOGIN)),
        ...JSON.parse(localStorage.getItem(SessionStorageKeys.RECOVERED_EMAIL_LOGIN)),
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    const { userName, rememberMe } = persistedLoginDetails || {};
    const { form } = ownProps;

    return Promise.all([
      dispatch(change(form, loginFields.USER_NAME, userName)),
      dispatch(change(form, loginFields.REMEMBER_ME, isWebView || rememberMe)),
    ]);
  },
  redirectToLegacyLoginFallbackPage: () => dispatch(historyPush(Pages.LEGACY_LOGIN)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(enhanced, connected)(Login);
