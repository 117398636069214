import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import FormNames from 'common/src/app/data/enum/FormNames';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import SubscriptionComparisonTable from 'components/molecules/SubscriptionComparisonTable';
import PackageGift from 'components/molecules/PackageGift';
import RegionPicker from 'components/molecules/RegionPicker';
import SelectedPackageCost from 'components/molecules/SelectedPackageCost';
import Pages from 'common/src/app/data/enum/Pages';
import NextStep from 'components/molecules/NextStep';
import TextNew, { ElementTypes, FontWeight, FontSizes } from 'components/atoms/TextNew';
import subscriptionPackageShape from 'common/src/app/util/proptypes/apiEntities/subscriptionPackage';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import classNames from 'classnames';
import Voucher from 'components/molecules/Voucher';

import './checkout.scss';

/**
 * This page is part of the registration wizard flow
 * and functions as a separate page when an account has already been created but payment
 * was somehow not completed.
 */
const Checkout = (
  {
    handleSubmit,
    wizardSubmitting,
    submitting,
    packages,
    voucherGift,
    packageFeatures,
    showVoucher,
    selectedPackage,
    deviceState,
    isInWizard,
    location: { pathname } = {},
    clearVoucherCodeErrors,
  },
  context,
  className,
) => {
  const limboCheckout = pathname === Pages.REGISTRATION_CHECKOUT;
  const nextStepLocale = limboCheckout ? 'payment' : 'packageSelection';

  const [selectedPackageDetails] = packages.filter(({ id }) => id === selectedPackage);

  return (
    <form name={FormNames.CHECKOUT} onSubmit={handleSubmit} noValidate>
      <section
        className={classNames(className, {
          'is-in-wizard': isInWizard,
        })}
      >
        <header className="checkout-header">
          <TextNew.Serif
            element={ElementTypes.H1}
            localeId="checkout.title"
            size={deviceState <= DeviceState.MD ? FontSizes.MD : FontSizes.XL}
            cid="title"
          />
        </header>
        {selectedPackageDetails && limboCheckout && !voucherGift && (
          <SelectedPackageCost selectedPackage={selectedPackageDetails} voucherGift={voucherGift} />
        )}
        {voucherGift ? (
          <>
            <TextNew.PrimaryElegant
              cid="gift"
              localeId="checkout.voucherGift"
              localeParams={{
                MONTHS: moment.duration(voucherGift?.duration).months(),
              }}
            />
            <PackageGift {...voucherGift} />
          </>
        ) : (
          <>
            <TextNew.Sans.MD
              weight={FontWeight.LIGHT}
              localeId="checkout.subTitle"
              cid="subtitle"
              size={deviceState <= DeviceState.MD ? FontSizes.SM : FontSizes.MD}
            />
            {Configuration.hasRegionPicker && (
              <>
                <EnhancedField
                  name={shopFields.REGION}
                  component={RegionPicker}
                  handleRegionClick={clearVoucherCodeErrors}
                />
                <FormErrorMessages fields={[shopFields.REGION]} />
              </>
            )}
            <TextNew.Sans.LG cid="package-header" localeId="checkout.packages.title" />
            <SubscriptionComparisonTable
              limboCheckout={limboCheckout}
              packages={Object.values(packages)}
              packageFeatures={packageFeatures}
            />
            <FormErrorMessages fields={[shopFields.PACKAGE]} />
            <TextNew.FormalLight cid="disclaimer" localeId="checkout.packages.disclaimer" />
          </>
        )}

        {showVoucher && <Voucher products={selectedPackageDetails} />}
        <NextStep
          titleLocale={`checkout.next.title.${nextStepLocale}`}
          descriptionLocale={`checkout.next.description.${nextStepLocale}`}
        >
          <Button
            ripple
            type="submit"
            cid="submit-button"
            disabled={submitting || wizardSubmitting}
            isLoading={submitting || wizardSubmitting}
          >
            <LocaleMessage id={'general.cta.continue'} />
          </Button>

          <FormErrorMessages showGeneral />
        </NextStep>
      </section>
    </form>
  );
};

Checkout.defaultProps = {
  showVoucher: true,
};

Checkout.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  wizardSubmitting: PropTypes.bool,
  packages: PropTypes.arrayOf(PropTypes.shape(subscriptionPackageShape)).isRequired,
  hasDiscount: PropTypes.bool,
  showVoucher: PropTypes.bool,
  voucherGift: PropTypes.object,
  voucherCode: PropTypes.string,
  deviceState: PropTypes.number,
  isInWizard: PropTypes.bool,
  selectedPackage: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  clearVoucherCodeErrors: PropTypes.func,
  packageFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      packageFeatureText: PropTypes.string,
      bronze: PropTypes.bool,
      silver: PropTypes.bool,
      gold: PropTypes.bool,
    }),
  ),
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'Checkout')(Checkout),
);
