import React from 'react';
import PropTypes from 'prop-types';
import MeasureType from 'common/src/app/data/enum/MeasureType';
import { measurementFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import MeasurementCompositeInput from '../../organisms/MeasurementCompositeInput';

// This component should be wrapped with a <FormValueProvider
const WeightMeasurementUnit = ({ name, formValues: { weightUnit }, ...props }) => (
  <MeasurementCompositeInput unit={weightUnit} type={MeasureType.WEIGHT} name={name} {...props} />
);

WeightMeasurementUnit.defaultProps = {
  formName: measurementFields.INITIAL_WEIGHT,
};

WeightMeasurementUnit.propTypes = {
  abbreviationType: PropTypes.string,
  formValues: PropTypes.shape({
    weightUnit: PropTypes.number,
  }),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

WeightMeasurementUnit.defaultProps = {
  name: measurementFields.INITIAL_WEIGHT,
  abbreviationType: 'longToShort',
};

export default WeightMeasurementUnit;
