/* global WP_DEFINE_IS_NODE */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Wrapper from 'components/atoms/Wrapper';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { isIOS } from 'common/src/app/util/deviceUtil';

import './successfully-registered-free-2-go.scss';

const SuccessfullyRegisteredFree2Go = (
  { free2GoAppIsReleased, androidAppUrl, iosAppUrl },
  {},
  className,
) => {
  const [appLink, setAppLink] = useState(androidAppUrl);
  useEffect(() => {
    if (!WP_DEFINE_IS_NODE && isIOS) {
      setAppLink(iosAppUrl);
    }
  }, [iosAppUrl]);

  return (
    <div className={className}>
      <Wrapper.MD>
        {free2GoAppIsReleased ? (
          <div className="content-block">
            <header>
              <TextNew.Serif.LG
                cid="title"
                localeId="successfullyRegisteredFree2Go.released.title"
                element={ElementTypes.H1}
              />
            </header>
            <TextNew.PrimaryOriginal
              cid="body"
              localeId="successfullyRegisteredFree2Go.released.description"
            />
            <Button href={appLink} ripple>
              <LocaleMessage id="successfullyRegisteredFree2Go.released.buttonLabel" />
            </Button>
          </div>
        ) : (
          <div className="content-block">
            <header>
              <TextNew.Loud
                cid="title"
                localeId="successfullyRegisteredFree2Go.comingSoon.title"
                element={ElementTypes.H1}
              />
            </header>
            <TextNew.PrimaryOriginal
              cid="body"
              localeId="successfullyRegisteredFree2Go.comingSoon.description"
            />
          </div>
        )}
      </Wrapper.MD>
    </div>
  );
};

SuccessfullyRegisteredFree2Go.propTypes = {
  free2GoAppIsReleased: PropTypes.bool.isRequired,
  androidAppUrl: PropTypes.string,
  iosAppUrl: PropTypes.string,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'SuccessfullyRegisteredFree2Go',
)(SuccessfullyRegisteredFree2Go);
