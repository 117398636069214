/* global WP_DEFINE_IS_NODE */
import Configuration from 'common/src/app/config/Configuration';
import Pages from 'common/src/app/data/enum/Pages';
import { setSEO } from '../actions/seoActions';

const PATHNAME_BLACKLIST = [Pages.LEARNING_MODULE_FOOD_OPTIMISING_ESSENTIALS_FREE_FOOD_DAY];

export default ({ getState, dispatch }) =>
  next =>
  action => {
    if (!WP_DEFINE_IS_NODE) {
      const location = getState().routeHistory[0];

      if (PATHNAME_BLACKLIST.includes(location.pathname)) return next(action);
    }

    if (
      action.payload &&
      action.payload.data &&
      action.payload.data.seo &&
      action.payload.data.seo.metaTitle
    ) {
      const {
        routing: { locationBeforeTransitions },
      } = getState();
      const { seo } = action.payload.data;

      dispatch(setSEO(seo, locationBeforeTransitions.pathname));
    }

    if (!WP_DEFINE_IS_NODE) {
      const state = getState();
      const location = state.routeHistory[0];

      if (state.seo[location.pathname] && state.seo[location.pathname].metaTitle) {
        document.title = `${state.seo[location.pathname].metaTitle} | ${Configuration.pageTitle}`;
      }
    }

    return next(action);
  };
