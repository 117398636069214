import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from '../../atoms/LocaleMessage';
import Row from '../../atoms/Row';
import Input, { descriptorTypes } from '../../atoms/Input';
import TextNew from '../../atoms/TextNew';

import './height-metric-field-group.scss';

const METERS_LENGTH = 1;
const CENTIMETERS_LENGTH = 2;

const HeightMetricFieldGroup = ({ abbreviation, disabled }, { getMessage }) => (
  <Row className={functionalComponentClassName(ComponentType.MOLECULE, 'HeightMetricFieldGroup')}>
    <EnhancedField
      component={Input}
      name={getMessage('general.measureUnit.height.metres.label')}
      type="number"
      min="0"
      max="2"
      ariaLabel={getMessage('general.measureUnit.height.metres.label')}
      placeholder="0"
      placeholderAbbreviation=""
      descriptorType={descriptorTypes.STATIC}
      onKeyPress={event => handleFieldGroupInput(event, METERS_LENGTH)}
      disabled={disabled}
    />
    <TextNew.Formal>.</TextNew.Formal>
    <EnhancedField
      component={Input}
      name={getMessage('general.measureUnit.height.centimetres.label')}
      type="number"
      min="0"
      max="99"
      ariaLabel={getMessage('general.measureUnit.height.centimetres.label')}
      placeholder="00"
      placeholderAbbreviation={
        <LocaleMessage
          id={`general.measureUnit.height.metres.${abbreviation ? 'labelAbbreviation' : 'label'}`}
        />
      }
      descriptorType={descriptorTypes.STATIC}
      onKeyPress={event => handleFieldGroupInput(event, CENTIMETERS_LENGTH)}
      disabled={disabled}
    />
  </Row>
);

HeightMetricFieldGroup.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

HeightMetricFieldGroup.propTypes = {
  disabled: PropTypes.bool,
  abbreviation: PropTypes.bool,
};

export default HeightMetricFieldGroup;
