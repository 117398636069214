/**
 * Retrieves the right category and name for a POST entity.
 * 2) Slimming Group
 * @param trackingData
 * @param getState
 * @returns {{trackingData: *}}
 */
const updateTrackingDataCategory = (trackingData, getState) => {
  const state = getState();
  const data = {};
  const pageTracking = state.tracking.pageData.find(
    page => page.pathname === state.tracking.persistentData.pageUrl,
  );

  if (pageTracking && pageTracking.data.contentGroupName) {
    data.category = pageTracking.data.contentGroupName;
  }

  return { ...trackingData, ...data };
};

export default updateTrackingDataCategory;
