import PropTypes from 'prop-types';

/**
 * Reusable object to use in `propTypes` for a `price` prop.
 * @type {Object}
 * @category templating
 */
const priceShape = {
  amount: PropTypes.number,
  currencyCode: PropTypes.string,
};

export default priceShape;
