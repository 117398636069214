/**
 * Closes any open tags
 * @param text
 * @returns {string} Parsed text
 */
const ensureAllUbbTagsClosed = (text?: string): string | undefined => {
  if (!text?.trim()) return text;

  const properties = ['b', 'u', 'i', 'r'];
  let parsedText = text;

  for (let i = 0, l = properties.length; i < l; i++) {
    const propertyName = properties[i];
    const openTags = text.split(`[${propertyName}]`).length - 1;
    const closeTags = text.split(`[/${propertyName}]`).length - 1;
    parsedText += `[/${propertyName}]`.repeat(openTags - closeTags > 0 ? openTags - closeTags : 0);
  }

  return parsedText;
};

/**
 * Replaces UBB codes to HTML tags
 * @param returnPromise returns a promise (useful when chaining transformations)
 * @param input
 */
export const transformUbbToHTML = (
  input?: string,
  returnPromise = false,
): string | undefined | Promise<string | undefined> => {
  let output = ensureAllUbbTagsClosed(input);

  if (!output?.trim()) return output;

  output = output
    .replace(/\[r]/g, '<p>')
    .replace(/\[\/r]/g, '</p>')
    .replace(/\[i]/g, '<em class="italic">')
    .replace(/\[\/i]/g, '</em>')
    .replace(/\[b]/g, '<strong class="bold">')
    .replace(/\[\/b]/g, '</strong>')
    .replace(/\[u]/g, '<span class="underline">')
    .replace(/\[\/u]/g, '</span>')
    .replace(/<div><\/div>/g, '<div>&nbsp;</div>');

  if (returnPromise) {
    return Promise.resolve(output);
  }

  return output;
};

export const stripUbb = (input?: string): string | undefined =>
  !input?.trim()
    ? input
    : input
        .replace(/\[r]/g, '')
        .replace(/\[\/r]/g, ' ')
        .replace(/\[i]/g, '')
        .replace(/\[\/i]/g, '')
        .replace(/\[b]/g, '')
        .replace(/\[\/b]/g, '')
        .replace(/\[u]/g, '')
        .replace(/\[\/u]/g, '')
        .replace(/<div><\/div>/g, '');

export const stripHTMLTags = (input?: string): string | undefined =>
  !input?.trim() ? input : input.replace(/<[^>]*(>|$)|&nbsp;|\r?\n|\r/g, '');
