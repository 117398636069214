import { createSelector } from 'reselect';

import PasswordValidationConfig from 'common/src/app/validation/passwordValidationConfig';

export type PartialState = {
  account: {
    passwordValidationConfig: PasswordValidationConfig;
  };
};

export const passwordValidationConfigSelector = createSelector(
  (state: PartialState) => state.account,
  account => account.passwordValidationConfig,
);
