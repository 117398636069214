import React from 'react';
import TextNew, { TextTypes } from '../../../../../../TextNew';
import LocaleMessage from '../../../../../../LocaleMessage';
import Select from '../../../../../../Select';
import InputNew from '../../../../atoms/InputNew';
import HeightInputLabel from '../HeightInputLabel';

interface HeightInputImperialProps extends React.InputHTMLAttributes<HTMLInputElement> {
  abbreviate: boolean;
  disabled?: boolean;
  unitsToDisplay: string[];
  values: {
    fullInches?: number | null;
    feet?: number | null;
    remainingInches?: number | null;
  };
}

const halfInchOptions = [
  {
    title: '0',
    value: 0,
  },
  {
    title: '½',
    value: 0.5,
  },
];

const HeightInputImperial = ({
  abbreviate,
  unitsToDisplay,
  disabled,
  values,
  ...restProps
}: HeightInputImperialProps): JSX.Element => (
  <>
    {unitsToDisplay.includes('feet') && (
      <>
        <InputNew
          id="height-feet"
          data-testid="height-feet"
          descriptor="static"
          name="feet"
          type="number"
          min="0"
          max="9"
          label={
            <>
              <span aria-hidden="true">
                <HeightInputLabel heightUnit="feet" abbreviate={abbreviate} />
              </span>
              <span className="screen-reader">
                <HeightInputLabel heightUnit="feet" abbreviate={true} />
              </span>
            </>
          }
          placeholder="00"
          value={values?.feet || ''}
          disabled={disabled}
          {...restProps}
          field={undefined} // Typescript incorrectly inferred that field is required. Setting to undefined to satisfy TS error.
        />
        <TextNew type={TextTypes.FORMAL} ariaHidden cid="dot">
          .
        </TextNew>
      </>
    )}
    <InputNew
      id="height-fullInches"
      data-testid="height-fullInches"
      descriptor="static"
      name="fullInches"
      type="number"
      value={values?.fullInches || ''}
      max="11"
      min="0"
      placeholder="00"
      label={
        <>
          <span aria-hidden="true">
            <HeightInputLabel heightUnit="inches" abbreviate={abbreviate} />
          </span>
          <span className="screen-reader">
            <HeightInputLabel heightUnit="inches" abbreviate={true} />
          </span>
        </>
      }
      disabled={disabled}
      {...restProps}
      field={undefined} // Typescript incorrectly inferred that field is required. Setting to undefined to satisfy TS error.
    />
    <label className="screen-reader" htmlFor="height-remainingInches">
      <LocaleMessage id="general.measureUnit.height.inches.labelHalfInch" />
    </label>
    <Select
      id="height-remainingInches"
      data-testid="height-remainingInches"
      value={values?.remainingInches && values.remainingInches >= 0.5 ? '0.5' : '0'}
      name="remainingInches"
      options={halfInchOptions}
      input={restProps}
    />
  </>
);

HeightInputImperial.defaultProps = {
  unitsToDisplay: ['feet', 'inches'],
  abbreviate: true,
};

export default HeightInputImperial;
