/* eslint-disable react/prop-types */
/* global WP_DEFINE_DEVELOPMENT, WP_DEFINE_IS_NODE */
import querystring from 'querystring';
import { push as historyPush } from 'react-router-redux';
import findChildStepRoutes from '../utils/findChildStepRoutes';

/* eslint-disable class-methods-use-this */
class ReactRouterWizardRoutingAdapter {
  stepRoutes = null;

  formHistoryPush = null;

  initialize = props => (dispatch, getState) => {
    this.formHistoryPush =
      (props.router && props.router.push) || (path => dispatch(historyPush(path)));

    const wizardRoute = props.routes.find(route => route.isWizardForm);

    if (!wizardRoute) {
      throw new Error('Could not find wizard form in routes configuration.');
    }
    this.stepRoutes = findChildStepRoutes(wizardRoute, getState());

    return this.stepRoutes.map(step => ({
      path: step.path,
      hideInProgress: !!step.wizardHideInProgress,
      stepIndex: step.wizardFormOrder,
    }));
  };

  redirectToFirstStep = props => (dispatch, getState) => {
    // if the last route in the routes array is the wizard form route, no step route is active
    if (props.routes[props.routes.length - 1].isWizardForm) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { path, wizardStepDisabled: stepIsDisabledCheck } of this.stepRoutes) {
        if (!stepIsDisabledCheck || !stepIsDisabledCheck(getState)) {
          const query = props?.location?.query || {};
          return this.formHistoryPush(`${path}?${querystring.stringify(query)}`);
        }
      }
    }

    return null;
  };

  getStepInfo = (wizardName, router) => (dispatch, getState) => {
    // validate arguments
    if (!router) {
      throw new ReferenceError(
        'Expected router to be set on react context when using ReactRouterWizardRoutingAdapter',
      );
    }

    // get the target wizard from state
    const state = getState();
    const wizard = state.enhancedForm.wizard[wizardName];
    if (!wizard || !wizard.steps) {
      throw new ReferenceError(`Could not find wizard "${wizardName}" in wizard reducer`);
    }

    // get the current path
    const { routes } = router;
    const { path } = routes[routes.length - 1];

    // add the isDisabledCheck to the step objects
    // this check is not in state because it is a non-serializable function
    // instead we read it from the route config
    const steps = wizard.steps.map(step => {
      const stepRoute = this.stepRoutes.find(route => route.wizardFormOrder === step.stepIndex);
      if (!stepRoute) {
        throw new ReferenceError(`Could not find wizard step with index ${step.stepIndex}`);
      }

      return {
        ...step,
        isDisabledCheck: stepRoute.wizardStepDisabled,
      };
    });

    // find the index of the step that matches the current path
    const activeStepIndex = steps.findIndex(step => path.startsWith(step.path));
    if (activeStepIndex < 0) {
      throw new ReferenceError(`Could not find step in wizard "${wizardName}" with path "${path}"`);
    }

    // detect if steps may be skipped. This is only when the development flag 'allowWizardSkip'
    // is set in the query params
    let allowStepSkipping = false;
    if (WP_DEFINE_DEVELOPMENT) {
      const { allowWizardSkip } = state.routing.locationBeforeTransitions.query;
      allowStepSkipping = typeof allowWizardSkip !== 'undefined';
    }

    return { activeStepIndex, steps, allowStepSkipping };
  };

  gotoStep =
    (stepIndex, nextFrameIfWeb = false) =>
    () => {
      const stepRoute = this.stepRoutes.find(route => route.wizardFormOrder === stepIndex);
      if (!stepRoute) {
        throw new ReferenceError(`Could not find wizard step with index ${stepIndex}`);
      }

      if (nextFrameIfWeb && !WP_DEFINE_IS_NODE) {
        requestAnimationFrame(() => this.formHistoryPush(stepRoute.path));
      } else {
        this.formHistoryPush(stepRoute.path);
      }
    };
}

export default ReactRouterWizardRoutingAdapter;
