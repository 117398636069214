/* global 'template','RecoverEmailSecurityQuestion' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import TextNew, { FontWeight, ElementTypes } from 'components/atoms/TextNew';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import InputType from 'common/src/app/data/enum/InputType';
import Input from 'components/atoms/Input';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import Label from 'components/atoms/Label';
import DomEventTracker from 'components/atoms/DomEventTracker';
import { DataLayerKeys } from 'common/src/app/data/enum/Tracking';
import { RoleType } from 'common/src/app/data/enum/AccessibilityEnum';
import AccountRecoveryErrorModal from '../../molecules/AccountRecoveryErrorModal';

import './recover-email-security-question.scss';

const RecoverEmailSecurityQuestion = (
  { submitting = false, hasBlockingError, handleSubmit, securityQuestion, openModal },
  { getMessage },
  className,
) => {
  const forgettenSecurityDetail = () =>
    openModal(
      ModalNames.CONTENT,
      {
        showCloseButton: false,
        showWrapperCloseButton: false,
        children: (
          <AccountRecoveryErrorModal
            bodyLocale="recoverEmailSecurityQuestion.modal.forgot.body"
            titleLocale="recoverEmailSecurityQuestion.modal.forgot.title"
          />
        ),
      },
      true,
    );

  return (
    <form onSubmit={handleSubmit} className={className} autoComplete="off" noValidate>
      <TextNew.Loud element={ElementTypes.h1} localeId="recoverEmailSecurityQuestion.title" />
      <TextNew.PrimaryElegant localeId="recoverEmailSecurityQuestion.body" />
      <div className="question">
        <Label text={securityQuestion} />
        <EnhancedField
          name={userDetailFields.SECURITY_ANSWER}
          placeholder={getMessage('general.securityQuestion.securityAnswerPlaceholder')}
          component={Input}
          type={InputType.TEXT}
        />
        <FormErrorMessages fields={[userDetailFields.SECURITY_ANSWER]} />
        <FormErrorMessages showGeneral />
        <Button
          role={RoleType.BUTTON}
          isImitationLink
          cid="forgot-button"
          onClick={forgettenSecurityDetail}
        >
          <TextNew.Sans.XS
            element={ElementTypes.SPAN}
            weight={FontWeight.LIGHT}
            localeId="recoverEmailSecurityQuestion.forgot"
          />
        </Button>
      </div>
      <Row justifycenter>
        <DomEventTracker
          meta={{
            [DataLayerKeys.SECURITY_QUESTION_FAIL]: 1,
          }}
        >
          <Button
            ripple
            type={InputType.SUBMIT}
            cid="next-step"
            disabled={submitting || hasBlockingError}
            isLoading={submitting}
          >
            <LocaleMessage id="general.cta.continue" />
          </Button>
        </DomEventTracker>
      </Row>
    </form>
  );
};

RecoverEmailSecurityQuestion.propTypes = {
  submitting: PropTypes.bool,
  hasBlockingError: PropTypes.bool,
  handleSubmit: PropTypes.func,
  securityQuestion: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

RecoverEmailSecurityQuestion.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName('template','RecoverEmailSecurityQuestion')(RecoverEmailSecurityQuestion);
