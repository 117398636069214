import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { push as historyPush } from 'react-router-redux';
import FormNames from 'common/src/app/data/enum/FormNames';
import { userIdentitySelector } from 'common/src/app/selectors/userAccountSelectors';
import Pages from 'common/src/app/data/enum/Pages';
import { getValue } from 'common/src/app/util/injector';
import { AUTHENTICATION_MANAGER } from 'common/src/app/data/Injectables';
import WebHost from 'common/src/app/data/enum/WebHost';
import { identityServerLogout } from 'common/src/app/actions/resources/accountActions';
import { INIT_SELF_BLOCKING, withInitAction } from '@mediamonks/react-redux-component-init';
import Logout from './Logout';

const EMPTY_OBJECT = {};

const enhanced = enhancedReduxForm({
  form: FormNames.LOGOUT,
  onSubmit: (values, dispatch, { logoutId }) =>
    logoutId &&
    dispatch(identityServerLogout(logoutId, true)).then(() =>
      dispatch(historyPush(Pages.LOGGED_OUT)),
    ),
});

const mapStateToProps = state => {
  const user = userIdentitySelector(state);
  const { email } = user || EMPTY_OBJECT;

  return {
    userName: email,
    environmentConfig: state.config.environmentConfig || EMPTY_OBJECT,
    returnUri: state.logout?.postLogoutRedirectUri || Pages.LOGIN,
    showLogoutPrompt: state.logout?.showLogoutPrompt,
    logoutId: state.logout?.logoutId,
  };
};
const connected = connect(mapStateToProps);

const addInitAction = withInitAction(
  ['location'],
  {
    clientOnly: ({ location }, dispatch, getState) => {
      const { logoutId } = location.query;
      if (logoutId) {
        return dispatch(identityServerLogout(logoutId));
      }

      // Trigger the logout with end-session when the logoutId is not in place
      const accountHost = getState().config.environmentConfig.web[WebHost.ACCOUNT].host;
      const loggedOutPath = `${accountHost}${Pages.LOGGED_OUT}`;
      getValue(AUTHENTICATION_MANAGER).logout(loggedOutPath);

      return Promise.resolve();
    },
  },
  {
    initSelf: INIT_SELF_BLOCKING,
  },
);

export default compose(addInitAction, connected, enhanced)(Logout);
