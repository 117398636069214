import createAction from 'redux-actions/lib/createAction';

export const SET_SEO = 'seoActions/SET_SEO';
export const CLEAR_SEO = 'seoActions/CLEAR_SEO';

export const setSEO = createAction(SET_SEO, (seo, route) => ({ seo, route }));
export const clearSEO = createAction(CLEAR_SEO);

export const manualSetSEO = seo => (dispatch, getState) =>
  dispatch(setSEO(seo, getState().routing.locationBeforeTransitions.pathname));
