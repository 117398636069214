/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import {
  packagesRegionCode,
  regionCode,
  regionCodeToString,
} from 'common/src/app/data/enum/Regions';
import RadioStyleType from 'components/atoms/RadioButton/RadioStyleType';
import LocaleMessage from '../../atoms/LocaleMessage';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import FormGroup from '../FormGroup';
import Accordion from '../../atoms/Accordion';
import RadioButtonGroup from '../RadioButtonGroup/index';
import TextNew, { ElementTypes, FontWeight } from '../../atoms/TextNew';
import RadioButton from '../../atoms/RadioButton';

import './region-picker.scss';

const RegionNotes = ({ region, isOutsideEurope }) => (
  <aside className="region-notes">
    <section className="notes">
      <section className="note">
        <TextNew.Sans.SM
          element={ElementTypes.H3}
          localeId={`regionPicker.regions.${region}.note.internationalDeliveryPolicy.title`}
          weight={FontWeight.BOLD}
        />
        <TextNew.PrimaryFootnote
          localeId={`regionPicker.regions.${region}.note.internationalDeliveryPolicy.description`}
        />
      </section>

      {isOutsideEurope && (
        <section className="note">
          <TextNew.Sans.SM
            element={ElementTypes.H3}
            localeId={`regionPicker.regions.${region}.note.currencyConversion.title`}
            weight={FontWeight.BOLD}
          />
          <TextNew.PrimaryFootnote
            localeId={`regionPicker.regions.${region}.note.currencyConversion.description`}
          />
        </section>
      )}

      <section className="note">
        <TextNew.Sans.SM
          element={ElementTypes.H3}
          localeId={`regionPicker.regions.${region}.note.barcodeScanner.title`}
          weight={FontWeight.BOLD}
        />
        <TextNew.PrimaryFootnote
          localeId={`regionPicker.regions.${region}.note.barcodeScanner.description`}
        />
      </section>
    </section>
  </aside>
);

RegionNotes.propTypes = {
  region: PropTypes.oneOf(['outside', 'europe']),
  isOutsideEurope: PropTypes.bool,
};

/**
 * Component to choose between regions
 */
const RegionPicker = (
  { getPackagesForRegion, handleRegionClick, ...restProps },
  context,
  className,
) => {
  const regionNote =
    restProps.input.value === packagesRegionCode.OUTSIDE_EUROPE ? 'outside' : 'europe';

  const outsideEurope = regionCodeToString[regionCode.OUTSIDE_EUROPE];

  return (
    <div className={className}>
      <FormGroup label={<LocaleMessage id="regionPicker.groupLabels.region" />}>
        <RadioButtonGroup {...restProps} type={RadioStyleType.PILL}>
          <ul className="regions-list">
            {Object.keys(packagesRegionCode).map(region => (
              <TextNew.FormalOriginal
                cid="region-item-container"
                element={ElementTypes.LI}
                key={region}
              >
                <RadioButton
                  label={
                    <TextNew.FormalOriginal
                      localeId={`regionPicker.regions.${
                        regionCodeToString[packagesRegionCode[region]]
                      }.name`}
                    />
                  }
                  value={packagesRegionCode[region]}
                  onClick={() => {
                    getPackagesForRegion(packagesRegionCode[region]);
                    handleRegionClick && handleRegionClick();
                  }}
                />
              </TextNew.FormalOriginal>
            ))}
          </ul>
        </RadioButtonGroup>

        <Accordion isCollapsed={restProps.input.value === packagesRegionCode.UK} id={regionNote}>
          <RegionNotes region={regionNote} isOutsideEurope={regionNote === outsideEurope} />
        </Accordion>

        <FormErrorMessages fields={[shopFields.REGION]} />
      </FormGroup>
    </div>
  );
};

RegionPicker.propTypes = {
  getPackagesForRegion: PropTypes.func.isRequired,
  handleRegionClick: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'RegionPicker')(RegionPicker);
