/**
 * Converts a list of checkboxes with names 'value-1', 'value-2', 'value-3', etc... to an comma
 * delimited string
 *
 * @param {object} values The values of all the checkboxes
 * @returns {array}
 */
const composeCheckboxesToCommaDelimitedString = values =>
  Object.keys(values)
    .reduce((result, fieldName) => {
      if (values[fieldName]) {
        result.push(fieldName);
      }
      return result;
    }, [])
    .toString();

export default composeCheckboxesToCommaDelimitedString;
