import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import iconSizing from 'common/src/app/data/iconSizing';
import debugLib from 'debug';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Unit from './Unit';

import './icon.scss';

const debug = debugLib('SlimmingWorld:Icon');
/**
 * Icon component based on sprite file
 *
 * Usage: Generate sprite from assets with 'npm run icon-sprite'
 * Or run them independently:
 * Convert assets to sprite 'npm run icon-sprite:convert'
 * Optimize sprite.svg 'npm run icon-sprite:optimize'
 *
 * @param name
 * @param width
 * @param height
 * @param scale
 * @param inline
 * @param direction
 * @param rounded
 * @param title
 */

const Icon = (
  { name, width, height, scale, rounded, unitType },
  context,
  className,
  dataTestid,
) => {
  let defaultHeight;
  let defaultWidth;
  let renderWidth;
  let renderHeight;

  try {
    defaultHeight = iconSizing[name].height;
    defaultWidth = iconSizing[name].width;
  } catch (err) {
    debug(
      `${name} isn't an icon, please add in the icon component and run 'yarn icon-sprite': ${err}`,
    );
  }

  if (width && height) {
    renderWidth = width;
    renderHeight = height;
  } else if (width && !height) {
    renderWidth = width;
    renderHeight = (defaultHeight / defaultWidth) * width;
  } else if (!width && height) {
    renderWidth = (defaultWidth / defaultHeight) * height;
    renderHeight = height;
  } else if (scale) {
    renderWidth = scale * defaultWidth;
    renderHeight = scale * defaultHeight;
  } else {
    renderWidth = defaultWidth;
    renderHeight = defaultHeight;
  }

  if (rounded) {
    renderWidth = Math.max(renderWidth, renderHeight);
    renderHeight = Math.max(renderWidth, renderHeight);
  }

  return (
    <span
      aria-hidden="true"
      data-testid={dataTestid}
      className={className}
      style={{
        width: `${renderWidth}${unitType}`,
        height: `${renderHeight}${unitType}`,
      }}
    >
      <svg data-testid={`${dataTestid}-svg`} className={`icon icon-${name}`}>
        <use xlinkHref={`#${name}`} href={`#${name}`} />
      </svg>
    </span>
  );
};

Icon.defaultProps = {
  direction: DirectionType.DOWN,
  unitType: Unit.PIXEL,
  ariaHidden: true,
};

Icon.propTypes = {
  /*
   * Name of the icon to use
   */
  name: PropTypes.string.isRequired,
  /*
   * sizing is performed on either width or height depending on which is large
   * the other plain is then scaled to the correct percentage of the larger plain
   */
  scale: PropTypes.number,
  /*
   * sizing is performed on either width or height depending on which is large
   * the other plain is then scaled to the correct percentage of the larger plain
   */
  width: PropTypes.number,
  /*
   * sizing is performed on either width or height depending on which is large
   * the other plain is then scaled to the correct percentage of the larger plain
   */
  height: PropTypes.number,
  /*
   * unit type (pixel, percentage, em)
   */
  unitType: PropTypes.oneOf(Unit.propTypes),
  /*
   * add background and color white
   */
  rounded: PropTypes.bool,
  /*
   * add svg inline
   */
  inline: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  /*
   * direction of icon
   */
  // eslint-disable-next-line react/no-unused-prop-types
  direction: PropTypes.oneOf([
    DirectionType.UP,
    DirectionType.RIGHT,
    DirectionType.DOWN,
    DirectionType.LEFT,
  ]),
};

export default memo(
  withFunctionalClassName(ComponentType.ATOM, 'Icon', [
    'inline',
    'rounded',
    'direction-{direction}',
  ])(Icon),
);
