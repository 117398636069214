/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';

import LocaleMessage from 'components/atoms/LocaleMessage';
import {
  ContactFormSubjectCategory,
  ContactFormSubjectIds,
} from 'common/src/app/data/enum/ContactFormCategory';
import TextNew, { Colors, FontWeight } from '../../atoms/TextNew';
import './contact-us-success-message.scss';

const onlineSupportSubjects = {
  [ContactFormSubjectIds.ONLINE_TECHNICAL_HELP]:
    ContactFormSubjectCategory[ContactFormSubjectIds.ONLINE_TECHNICAL_HELP],
  [ContactFormSubjectIds.GROUP_TECHNICAL_HELP]:
    ContactFormSubjectCategory[ContactFormSubjectIds.GROUP_TECHNICAL_HELP],
  [ContactFormSubjectIds.PUBLIC_ONLINE_MEMBERSHIP]:
    ContactFormSubjectCategory[ContactFormSubjectIds.PUBLIC_ONLINE_MEMBERSHIP],
};

class ContactUsSucessMessage extends Component {
  componentDidMount() {
    const { scrollToElement, disableScrollTo = false } = this.props;

    !disableScrollTo && scrollToElement(this.successMessage, -70);
  }

  render() {
    const { subjectId, showLongResponseMessage } = this.props;

    return (
      <div
        ref={el => (this.successMessage = el)}
        {...componentClassNameProp('molecule', this)}
      >
        <TextNew.Serif.LG
          cid="thank-you"
          localeId="contactUsSuccessMessage.header"
          color={Colors.BRAND}
        />
        <TextNew.Sans.SM
          localeId="contactUsSuccessMessage.success"
          weight={FontWeight.LIGHT}
          cid="success"
        />

        {showLongResponseMessage ? (
          <TextNew.Sans.SM localeId="contactUsSuccessMessage.veryBusy" weight={FontWeight.LIGHT} />
        ) : (
          <TextNew.Sans.SM
            weight={FontWeight.LIGHT}
            localeParams={{
              DISCLAIMER:
                subjectId in onlineSupportSubjects ? (
                  ''
                ) : (
                  <LocaleMessage id="contactUsSuccessMessage.body.workDayDisclaimer" />
                ),
            }}
            localeId="contactUsSuccessMessage.body.default"
          />
        )}
      </div>
    );
  }
}

ContactUsSucessMessage.propTypes = {
  disableScrollTo: PropTypes.bool,
  scrollToElement: PropTypes.func,
  subjectId: PropTypes.string,
  showLongResponseMessage: PropTypes.bool,
};

export default ContactUsSucessMessage;
