import { groupMember } from '../selectors/accountStateSelectors';
import { addressFields } from '../data/enum/FieldNames/AccountFieldNames';
import * as validationTriggers from '../data/validationTriggers';
import { getApiNoCache } from '../actions/externalApiActions';
import { POSTCODE_IO_VALIDATE } from '../data/externalApis';
import * as ValidateOn from '../enhanced-redux-form/data/ValidateOn';
import { countryCodeValue, countryCode } from '../data/enum/Countries';
import * as AddressFormats from '../data/AddressFormats';
import { POSTAL_CODES } from '../data/regexPatterns';
import CountryPostalCode from '../data/enum/CountryPostalCode';
import getCountry from '../util/getCountry';
import { addressLineMaxLength } from './validationUtils';

/** The maximum allowed character length for an address line field */

const ADDRESS_LINE_MAX_LENGTH = 30;

/** The array of address form fields that we need to validate against
 - e.g. the validation of the postcode format, if it is required or not and the max input length */

const addressFieldsArray = [
  addressFields.ADDRESS_LINE_1,
  addressFields.ADDRESS_LINE_2,
  addressFields.ADDRESS_LINE_3,
  addressFields.CITY_OR_TOWN,
  addressFields.STATE,
  addressFields.ZIP_OR_POSTAL,
  addressFields.COUNTY,
  addressFields.COUNTRY,
];

/** List of fields that are 'required' for Group member addresses */
const requiredGroupAddressField = [addressFields.ZIP_OR_POSTAL];

/**
 * Returns amended fieldnames
 *
 * @param {string} addressField address fieldname
 * @param {string} addressType address type - one of billing, shipping or null
 *
 * @returns {string} concatenation of addressType and addressField (addressType.addressFeild - e.g. billingAddress.postalCode)
 */
const formatAddressType = (addressField, addressType) =>
  addressType ? `${addressType}.${addressField}` : addressField;

/**
 * Gets the validation rules for a specific field and region
 *
 * @param {string} country the country to match the rule for
 * @param {string} fieldName the field to match the rule for
 * @param {string} rule the rule to be matched
 *
 * @returns {(string|Undefined)} any matching rule for the field specific to the country
 */
const getCountryFieldRules = (country, fieldName, rule) => {
  const countryCodeString = countryCodeValue[country] || countryCode.DEFAULT;
  // For IRELAND we need to do the validation for the COUNTY_IE field
  const fieldNameValue =
    country === countryCode.IRELAND && fieldName === addressFields.COUNTY
      ? addressFields.COUNTY_IE
      : fieldName;
  const currentRules =
    countryCodeString && AddressFormats[countryCodeString.toUpperCase()][fieldNameValue];

  return currentRules && currentRules.find(fieldRule => fieldRule === rule);
};

/**
 * Helper function to determine if the postcode is BFPO valid
 *
 * @param {string} postcode
 *
 * @returns {boolean}
 */
export const isBfpoPostcode = postcode => {
  const postcodeUpper = postcode.toUpperCase();
  return postcodeUpper.startsWith('BF1') || postcodeUpper.startsWith('BF2');
};

/**
 * Helper function to determine if a UK postcode is valid, skips checks if
 * postcode is a BFPO postcode
 *
 * @param {string} postcode
 *
 * @returns {boolean}
 */
export const isValidUkPostcode = postcode => async dispatch => {
  if (!isBfpoPostcode(postcode)) {
    return dispatch(getApiNoCache(POSTCODE_IO_VALIDATE, postcode));
  }

  return Promise.resolve(true);
};

/**
 * If value not undefined or whitespace, the field is for postCode and country is UK
 * the postcode will be validated by a call to PostCodes.io (excluding BFPO)
 *
 * @param {string} value the field value
 * @param {object} values the form values
 * @param {string} fieldName the field name
 * @param {function} dispatch from React Redux
 *
 * @returns {Promise<boolean>} a promise which resolves to a boolean indicating whether the postcode is valid
 */
export const createIsValidUkPostcodeRule = (value, values, fieldName, dispatch) =>
  new Promise(resolve => {
    const addressType = fieldName.substring(0, fieldName.indexOf('.'));

    const isZipOrPostalCodeField =
      fieldName === formatAddressType(addressFields.ZIP_OR_POSTAL, addressType);

    const countryValue = addressType
      ? values?.[addressType]?.[addressFields.COUNTRY]
      : values?.[addressFields.COUNTRY];
    const isUKAddress = countryValue === countryCode.GB;

    if (isNullOrWhitespace(value) || !isZipOrPostalCodeField || !isUKAddress) {
      resolve(true);
      return;
    }

    dispatch(isValidUkPostcode(value)).then(result => {
      resolve(result);
    });
  });

/**
 * Validates the format of a postcode against set rules for a specific country
 *
 * @param {string} value the value of the form field
 * @param {string} fieldName the name of the form field
 * @param {string} addressType one of billing, shipping or null
 * @param {string} country the address country
 *
 * @returns {boolean}
 */
export const createIsValidPostcodeFormatRule = (value, fieldName, addressType, country) => {
  const zipCodeOrPostalCode =
    country === countryCode.US ? validationTriggers.ZIP_CODE : validationTriggers.POSTAL_CODE;

  return getCountryFieldRules(country, fieldName, zipCodeOrPostalCode)
    ? POSTAL_CODES[country].test(value)
    : true;
};

/**
 * Validates the maximum length of an addresses address lines: addressLine1, addressLine2 and addressLine3
 *
 * @param {string} value the value of the form field
 * @param {string} fieldName the name of the form field
 *
 * @returns {boolean}
 */
export const maximumLenghForAddressLinesRule = (value, fieldName) => {
  if (
    fieldName.includes(addressFields.ADDRESS_LINE_1) ||
    fieldName.includes(addressFields.ADDRESS_LINE_2) ||
    fieldName.includes(addressFields.ADDRESS_LINE_3)
  ) {
    return !addressLineMaxLength(value, ADDRESS_LINE_MAX_LENGTH);
  }

  // The fieldName is NOT one of the addressline fields
  // - and therefore does not need to validate its max length - return true
  return true;
};

/**
 * Applies required field rule according to fieldName, country and member type
 *
 * @param {string} value the field value
 * @param {string} fieldName the field name
 * @param {string} country the address country
 * @param {function} dispatch from React Redux
 *
 * @returns {boolean}
 */
export const createRequiredFieldRule = (value, fieldName, country, dispatch) => {
  const isGroupMember = dispatch(groupMember());
  const isRequiredGroupField = isGroupMember && requiredGroupAddressField.indexOf(fieldName) > -1;
  const isRequiredOnlineField =
    !isGroupMember && getCountryFieldRules(country, fieldName, validationTriggers.REQUIRED);

  return isRequiredGroupField || isRequiredOnlineField ? !isNullOrWhitespace(value) : true;
};

const isNullOrWhitespace = value => (!value || !value.trim()) && value !== 0;

/**
 * Validates an address object against country specific rules
 *
 * @param {string} addressType Address type prefix for object to be validated (e.g. billingAddress)
 * @param {*} validateOn One or more of ValidateOn.BLUR, ValidateOn.CHANGE, ValidateOn.FOCUS
 *
 * @returns an object with validation fields filled for address type and region
 */
const address = (addressType = '', validateOn = [ValidateOn.BLUR, ValidateOn.CHANGE]) =>
  addressFieldsArray.reduce((obj, fieldName) => {
    const fieldNameWithType = formatAddressType(fieldName, addressType);

    let country = '';

    // eslint-disable-next-line no-param-reassign
    obj[fieldNameWithType] = {
      validators: [
        // Adds IdealPostcodes.io validation to UK postcode field
        {
          rule: (value, values, fieldname, dispatch) =>
            createIsValidUkPostcodeRule(value, values, fieldname, dispatch),
          message: {
            locale: `validation.errors.${addressFields.ZIP_OR_POSTAL}.invalid`,
          },
        },
        // Adds required validation to all fields
        {
          rule: (value, values, fieldname, dispatch) => {
            country = getCountry(values, addressType);
            return createRequiredFieldRule(value, fieldName, country, dispatch);
          },
          message: {
            locale: `validation.errors.${fieldName}.required`,
            params: {
              POSTAL_CODE: () => CountryPostalCode[countryCodeValue[country]] || 'Zipcode',
            },
          },
        },
        // Adds format validation to UK & US postcode/zipcode fields
        {
          rule: (value, values) => {
            country = getCountry(values, addressType);
            return createIsValidPostcodeFormatRule(value, fieldName, addressType, country);
          },
          message: {
            locale: `validation.errors.${fieldName}.formatError`,
            params: {
              POSTAL_CODE: () => CountryPostalCode[countryCodeValue[country]] || 'Zipcode',
            },
          },
        },
        // Adds maximum character length validation to the address line fields
        // - addressLine1, addressLine2 and addressLine3
        {
          rule: value => maximumLenghForAddressLinesRule(value, fieldName),
          message: {
            locale: `validation.errors.${fieldName}.maxLength`,
          },
        },
      ],
      validateOn,
    };
    return obj;
  }, {});

export default address;
