import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import TextNew from 'components/atoms/TextNew';
import MultiFactorAuthenticationForm from '../../../../../organisms/MultiFactorAuthenticationForm';

const componentName = 'multi-factor-authentication-verify';
const MultiFactorAuthenticationVerify = ({ location }, context, className) => (
  <div className={className}>
    <TextNew.Loud
      localeId="multiFactorAuthenticationVerify.title"
      dataTestid={`${componentName}-title`}
    />
    <TextNew.Sans.SM localeId="multiFactorAuthenticationVerify.body" />
    <MultiFactorAuthenticationForm location={location} />
  </div>
);

MultiFactorAuthenticationVerify.propTypes = {
  location: PropTypes.string,
};

export default withDeviceState()(
  withFunctionalClassName(
    ComponentType.TEMPLATE,
    'MultiFactorAuthenticationVerify',
  )(MultiFactorAuthenticationVerify),
);
