export type ValidationProps = {
  code: string;
  fields?: {
    field: string;
    code: string;
  }[];
};

export const emailValidationErrorCodes = {
  VALIDATION_ERROR: 'validation-error',
  REQUIRED: 'required',
  UNKNOWN: 'unknown',
  TOKEN_INVALID: 'token.invalid',
  NOT_FOUND: 'not-found',
  ANTI_FORGERY: 'antiforgery',
  TOKEN_ALREADY_PROCESSED: 'token.alreadyprocessed',
  NO_ERROR: 'no-error',
};

/**
 * Returns the message prefix for a given validation error.
 */
export const getLocaleMessagePrefix = (validationError: ValidationProps): string | null => {
  let error = validationError;
  const copy = 'emailVerificationResult';

  if (!error || !error.code) {
    return null;
  }

  if (error.code === emailValidationErrorCodes.NO_ERROR) {
    return copy;
  }

  if (error.code === emailValidationErrorCodes.VALIDATION_ERROR) {
    // If error.code is VALIDATION_ERROR, use the first field error as the new error.
    const [fieldError] = error.fields || [];
    if (!fieldError || fieldError.code === emailValidationErrorCodes.NO_ERROR) {
      return null;
    }
    error = fieldError;
  }

  switch (error.code) {
    case emailValidationErrorCodes.REQUIRED:
    case emailValidationErrorCodes.UNKNOWN:
      return `${copy}.errors.required`;
    case emailValidationErrorCodes.TOKEN_INVALID:
    case emailValidationErrorCodes.NOT_FOUND:
    case emailValidationErrorCodes.ANTI_FORGERY:
      return `${copy}.errors.invalid`;
    case emailValidationErrorCodes.TOKEN_ALREADY_PROCESSED:
      return `${copy}.errors.alreadyprocessed`;
    default:
      return null;
  }
};

export const getValidationCode = (validationCode: string): ValidationProps => ({
  code: validationCode,
  fields: [],
});
