import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { formValueSelector } from 'redux-form';
import Configuration from 'common/src/app/config/Configuration';
import { makePackagesWithDiscountSelector } from 'common/src/app/selectors/packageVoucherSelectors';
import { getSubscriptionPackageFeaturesSelector } from 'common/src/app/selectors/subscriptionPackageFeaturesSelector';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import FormNames from 'common/src/app/data/enum/FormNames';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { getPackages } from 'common/src/app/actions/resources/shopActions';
import { getSubscriptionPackageFeatures } from 'common/src/app/actions/resources/accountActions';

import UpgradePackage from './UpgradePackage';

const EMPTY_OBJECT = {};

const mapStateToProps = () => {
  const checkoutFormSelector = formValueSelector(FormNames.CHECKOUT);
  const packagesWithDiscountSelector = makePackagesWithDiscountSelector();

  return state => {
    const regionCode = Configuration.hasRegionPicker
      ? checkoutFormSelector(state, shopFields.REGION)
      : Configuration.defaultRegion;

    // Filter the subscriptioon packages
    // - so that we only have bronze, silver and gold
    const packages = packagesWithDiscountSelector(state, regionCode)?.filter(
      item =>
        item?.name?.toLowerCase() === 'bronze' ||
        item?.name?.toLowerCase() === 'silver' ||
        item?.name?.toLowerCase() === 'gold',
    );

    // The package data for the online registration comparison table
    const packageFeatures = getSubscriptionPackageFeaturesSelector(state);

    return {
      packages,
      packageFeatures: packageFeatures || EMPTY_OBJECT,
    };
  };
};

// we should have the packages at this point already in state!
// but we can call to get them with the region we have
const addInitAction = withInitAction({
  prepared: ({ regionCode }, dispatch) =>
    Promise.all([
      dispatch(getPackages({ regionCode: regionCode || Configuration.defaultRegion })),
      dispatch(getSubscriptionPackageFeatures()),
    ]),
});

export default compose(addInitAction, connect(mapStateToProps))(UpgradePackage);
