import { createSelector } from 'reselect';
import ActivityStatus from '../data/enum/ActivityStatus';
import { isWithinDateRange } from '../util/dateUtils';
import { isActivityQualifying } from '../util/activityUtils';
import { userIdSelector } from './userAccountSelectors';
import { WEARABLE_DEVICE } from '../data/entityTypes';

export const selectAllAwards = state => state.entities.plannerActivityGoal;
export const allActivities = state => state.entities.plannerActivity;
export const latestCompletedAward = state => state.planner?.latestCompletedAward;
export const durationFields = state =>
  state.form.addActivity?.fields?.['_composite-input-duration'] ||
  state.form.addOwnActivity?.fields?.['_composite-input-duration'];

export const activeAwardSelector = createSelector(
  selectAllAwards,
  awards =>
    (awards && Object.values(awards).find(goal => goal.status === ActivityStatus.COMMITTED)) || {},
);

export const plannerActivitySelector = createSelector(
  (state, id) => state.entities?.plannerActivity?.[id],
  activity => activity || { status: 0, duration: '' },
);

export const achievedAwardSelector = createSelector(
  [selectAllAwards, latestCompletedAward],
  (awards, latestCompletedAwardId) =>
    (awards && Object.values(awards).find(award => award.id === latestCompletedAwardId)) || {},
);

const activeAward = state => activeAwardSelector(state);

export const committedAwardActvities = createSelector(
  [allActivities, activeAward],
  (activities, award) =>
    (activities &&
      Object.values(activities).filter(activity => {
        const dateRange = { start: award.startDateUTC, end: award.endDateUTC };
        return isWithinDateRange(dateRange, activity.plannedDateUTC);
      })) ||
    {},
);

export const plannerDurationActiveSelector = createSelector(
  durationFields,
  duration => duration?.minutes?.active || duration?.hours?.active,
);

export const hasMagicMoverAwardSelector = createSelector(
  state => state.planner?.hasMagicMoverAward,
  hasMagicMoverAward => hasMagicMoverAward,
);

export const hasSavedCompletedActivitiesSelector = createSelector(
  state => state.planner?.hasSavedCompletedActivities,
  hasSavedCompletedActivities => hasSavedCompletedActivities,
);

export const wearableDeviceSelector = createSelector(
  userIdSelector,
  state => state.entities[WEARABLE_DEVICE] || {},
  (userId, wearableDeviceEntities) => {
    if (userId && wearableDeviceEntities[userId]) {
      return wearableDeviceEntities[userId];
    }
    return null;
  },
);

const activityGoalSelector = createSelector(
  (state, item) => state.entities.plannerActivityGoal?.[item?.activityGoalId],
  activityGoal => activityGoal,
);

export const awardStarColorIdSelector = createSelector(
  activityGoalSelector,
  (state, item) => item,
  (activityGoal, item) => {
    const activityQualifies = isActivityQualifying(item, activityGoal);
    if (!activityQualifies || !activityGoal) {
      return null;
    }
    return activityGoal?.activityGoalType;
  },
);
