import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setRecaptchaToken, setRecaptchaStatus } from 'common/src/app/actions/configActions';
import { updateSubmissionCount } from 'common/src/app/actions/resources/groupAccountActions';
import ReCaptchaCheckbox, { ReCaptchaCheckboxProps } from './ReCaptchaCheckbox';

interface RootState {
  config: {
    environmentConfig?: {
      recaptcha?: {
        siteKey?: string;
      };
    };
  };
}

interface StateProps {
  defaultSiteKey?: string;
}

interface DispatchProps {
  setToken: typeof setRecaptchaToken;
  setRecaptchaStatus: typeof setRecaptchaStatus;
  resetSubmissionCount: () => void;
}

const mapStateToProps = (state: RootState): StateProps => ({
  defaultSiteKey: state.config.environmentConfig?.recaptcha?.siteKey,
});

const mapDispatchToProps: DispatchProps = {
  setToken: setRecaptchaToken,
  setRecaptchaStatus,
  resetSubmissionCount: () => updateSubmissionCount({ reset: true }),
};

type ConnectedProps = Omit<ReCaptchaCheckboxProps, keyof DispatchProps>;

const ConnectedReCaptchaCheckbox = connect<StateProps, DispatchProps, ConnectedProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(ReCaptchaCheckbox);

export default compose<React.ComponentType<ConnectedProps>>(
  connect(mapStateToProps, mapDispatchToProps),
)(ConnectedReCaptchaCheckbox);
