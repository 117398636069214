import React, { useContext } from 'react';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';

/** @module */

/**
 * Will wrap the given react component such that it reads the name of the current form
 * from the ReduxFormContext. This name will be injected into `props`
 * as the `form` prop
 *
 * @function injectFormNameToProps
 * @param WrappedComponent The component that should ge the formname injected
 * @returns The wrapped react component
 */
const injectFormNameToProps = WrappedComponent => {
  const InjectFormNameWrapper = props => {
    const { form } = useContext(ReduxFormContext);
    return <WrappedComponent {...props} form={form} />;
  };

  InjectFormNameWrapper.displayName = `injectFormNameToProps(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return InjectFormNameWrapper;
};

export default injectFormNameToProps;
