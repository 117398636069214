/* global 'atom','SynsAllowanceBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import { genderToString } from 'common/src/app/data/enum/Gender';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import './syns-allowance-block.scss';

/**
 * SYNS ALLOWANCE BLOCK
 *
 * syn logic US:
 * < 225
 * - female 10
 * - male 15
 *
 * >= 225 && < 280
 * - female 15
 * - male 20
 *
 * > 280
 * - female 20
 * - male 25
 *
 * syn logic UK:
 * < 225
 * - female 15
 * - male 25
 *
 * >= 225 && < 280
 * - female 20
 * - male 30
 *
 * > 280
 * - female 25
 * - male 35
 *
 * See confluence:
 * https://slimmingworlddigital.atlassian.net/wiki/display/SW2/Syn+Allowance+between+markets
 */
const SynsAllowanceBlock = (
  { firstName, gender, currentWeight, openModal },
  context,
  className,
) => {
  const openSynsAllowanceModal = () => openModal(ModalNames.SYNS_ALLOWANCE, true, true, true);
  const synsValues = [...Configuration.synValue].reverse().find(e => e.weight <= currentWeight);
  const synsAmount = synsValues[genderToString[gender]];

  return (
    <div className={className}>
      <div className="content-container">
        <TextNew.Confident
          cid="greeting"
          localeId="synsAllowance.greeting"
          localeParams={{ USER: firstName }}
        />
        <TextNew.Strong localeId="synsAllowance.description" />
        <button className="cta-open-syns-modal" onClick={openSynsAllowanceModal}>
          <TextNew.FormalOriginal element={ElementTypes.SPAN} localeId="synsAllowance.ctaLabel" />
        </button>
      </div>
      <div className="syns-container">
        {!currentWeight ? (
          <div className="inner">
            <TextNew.Strong
              cid="no-syns"
              color={Colors.PRIMARY_DARK}
              localeId="synsAllowance.noWeighInData"
            />
          </div>
        ) : (
          <div className="inner">
            <TextNew.Loud cid="syns" color={Colors.BRAND}>
              {synsAmount}
            </TextNew.Loud>
            <TextNew.Formal color={Colors.PRIMARY_DARK} localeId="synsAllowance.syns" />
          </div>
        )}
      </div>
    </div>
  );
};

SynsAllowanceBlock.propTypes = {
  firstName: PropTypes.string.isRequired,
  currentWeight: PropTypes.number.isRequired,
  gender: PropTypes.number.isRequired,
  openModal: PropTypes.func,
};

export default withFunctionalClassName('atom','SynsAllowanceBlock')(SynsAllowanceBlock);
