/* global 'molecule' */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import {
  countryCode,
  countryCodeValue,
  countryCodeToString,
} from 'common/src/app/data/enum/Countries';
import * as AddressFormats from 'common/src/app/data/AddressFormats';
import { ukPostCodeFormatter } from 'common/src/app/util/form/ukPostCodeFormatter';
import Configuration from 'common/src/app/config/Configuration';
import states from 'common/src/app/data/statedata';
import ReadOnlyField from 'components/molecules/ReadOnlyField';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import FormGroup from '../FormGroup';
import Input from '../../atoms/Input';
import Select from '../../atoms/Select';

class AddressForm extends PureComponent {
  componentDidMount() {
    if (this.props.country !== countryCode.IRELAND) return;

    this.props.getIrishCounties();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country === this.props.country) {
      return;
    }
    // eslint-disable-next-line
    if (this.props.country === countryCode.IRELAND && this.props.irishCounties.length === 0) {
      this.props.getIrishCounties();
    }
    this.props.handleCountryChange();
  }

  render() {
    const { getMessage } = this.context;
    const {
      fieldNamePrefix = '',
      irishCounties,
      country,
      showCountry,
      disableCountry,
      handleCountryChange,
      countries,
      readOnly,
      label,
      forwardRef,
      onCountryChanged,
      isLoading,
    } = this.props;

    if (isLoading) return null;

    const countryCodeSelected = countryCodeValue[country] || countryCode.DEFAULT;
    const countryCodeStringSelected = countryCodeToString[country] || countryCode.DEFAULT;

    const selectOptions = countryCodeSelected === countryCode.US ? states : irishCounties || [];

    const fieldsFormat = Object.keys(AddressFormats[countryCodeSelected]);
    const fields = readOnly ? AddressFormats.reorderCountry(fieldsFormat) : fieldsFormat;

    const fieldRefMap = {
      [addressFields.ADDRESS_LINE_1]: forwardRef?.addressLine1Ref,
      [addressFields.CITY_OR_TOWN]: forwardRef?.cityRef,
      [addressFields.COUNTY]: forwardRef?.countyRef,
      [addressFields.ZIP_OR_POSTAL]: forwardRef?.zipRef,
      [addressFields.COUNTRY]: forwardRef?.countryRef,
    };

    const handleCountryChanged = () => {
      handleCountryChange();
      onCountryChanged && onCountryChanged();
    };

    return (
      <div {...componentClassNameProp('molecule', this, readOnly && 'readOnly')}>
        <FormGroup label={label} type="stacked">
          {fields.map(field => {
            switch (field) {
              case addressFields.ADDRESS_LINE_1:
              case addressFields.ADDRESS_LINE_2:
              case addressFields.ADDRESS_LINE_3:
              case addressFields.CITY_OR_TOWN:
              case addressFields.COUNTY:
              case addressFields.ZIP_OR_POSTAL: {
                return (
                  <Fragment key={field}>
                    <EnhancedField
                      component={readOnly ? ReadOnlyField : Input}
                      name={field}
                      placeholder={
                        field === addressFields.ZIP_OR_POSTAL
                          ? getMessage(
                              `addressForm.placeholders.${field}.${countryCodeStringSelected.toLowerCase()}`,
                            )
                          : getMessage(`addressForm.placeholders.${field}`)
                      }
                      format={
                        field === addressFields.ZIP_OR_POSTAL && country === countryCode.GB
                          ? ukPostCodeFormatter
                          : undefined
                      }
                      type="text"
                      inputRef={fieldRefMap[field]}
                      suppress
                    />
                    <FormErrorMessages fields={[`${fieldNamePrefix}${field}`]} />
                  </Fragment>
                );
              }

              case addressFields.COUNTY_IE: {
                return selectOptions?.length > 0 ? (
                  <Fragment key={field}>
                    <EnhancedField
                      component={readOnly ? ReadOnlyField : Select}
                      name={addressFields.COUNTY}
                      title="County"
                      placeholder={getMessage(`addressForm.placeholders.${field}`)}
                      type="text"
                      options={selectOptions}
                      suppress
                    />
                    <FormErrorMessages fields={[`${fieldNamePrefix}${addressFields.COUNTY}`]} />
                  </Fragment>
                ) : null;
              }

              case addressFields.STATE: {
                return selectOptions?.length > 0 ? (
                  <Fragment key={field}>
                    <EnhancedField
                      component={readOnly ? ReadOnlyField : Select}
                      name={addressFields.STATE}
                      title="State"
                      placeholder={getMessage(`addressForm.placeholders.${field}`)}
                      type="text"
                      options={selectOptions}
                      suppress
                    />
                    <FormErrorMessages fields={[`${fieldNamePrefix}${addressFields.STATE}`]} />
                  </Fragment>
                ) : null;
              }

              case addressFields.COUNTRY: {
                return showCountry && countries && countries.length > 0 ? (
                  <Fragment key={field}>
                    <EnhancedField
                      component={readOnly ? ReadOnlyField : Select}
                      name={addressFields.COUNTRY}
                      title="Country"
                      placeholder={getMessage(`addressForm.placeholders.${field}`)}
                      options={countries}
                      onChange={handleCountryChanged}
                      disabled={disableCountry}
                      selectRef={fieldRefMap[addressFields.COUNTRY]}
                      suppress
                    />
                    <FormErrorMessages fields={[`${fieldNamePrefix}${addressFields.COUNTRY}`]} />
                  </Fragment>
                ) : null;
              }
              default:
                throw new Error(`Unknown field type "${field}" in addressFormat market config`);
            }
          })}
        </FormGroup>
      </div>
    );
  }
}

AddressForm.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

AddressForm.defaultProps = {
  addressFormat: Configuration.defaultRegion,
  showCountry: true,
  disableCountry: false,
};

AddressForm.propTypes = {
  readOnly: PropTypes.bool,
  fieldNamePrefix: PropTypes.string,
  country: PropTypes.string,
  irishCounties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  getIrishCounties: PropTypes.func,
  showCountry: PropTypes.bool,
  disableCountry: PropTypes.bool,
  handleCountryChange: PropTypes.func,
  countries: PropTypes.array,
  label: PropTypes.string,
  forwardRef: PropTypes.object,
  onCountryChanged: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default AddressForm;
