import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SubmissionError } from 'redux-form';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import RecoverEmailErrorCode from 'common/src/app/data/enum/RecoverEmailErrorCode';
import { verifySecurityAnswer } from 'common/src/app/actions/resources/accountActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import Pages from 'common/src/app/data/enum/Pages';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ModalNames from 'common/src/app/data/enum/ModalNames';

import AccountRecoveryErrorModal from '../../molecules/AccountRecoveryErrorModal';
import RecoverEmailSecurityQuestion from './RecoverEmailSecurityQuestion';

const enhanced = enhancedReduxForm(
  {
    form: FormNames.RE_SECURITY_QUESTION,
    destroyOnUnmount: false,
    onSubmit: async (values, dispatch, { state }) => {
      await dispatch(verifySecurityAnswer(values))
        .then(result => {
          const { verifiedIdentityToken } = result;

          // No matching verified token has been returned
          // This means that the security question was answered incorrectly
          if (verifiedIdentityToken === null) {
            throw new SubmissionError(Pages.RE_SECURITY_QUESTION);
          }
        })
        .catch(error => {
          const {
            error: { code, message },
          } = JSON.parse(error.response.text);
          if (code === RecoverEmailErrorCode.SECURITY_QUESTION_MAX_RETRIES) {
            dispatch(
              openModal(
                ModalNames.CONTENT,
                {
                  showCloseButton: false,
                  showWrapperCloseButton: false,
                  children: (
                    <AccountRecoveryErrorModal
                      bodyLocale={message}
                      showContactMessage={true}
                      reduxState={state}
                    />
                  ),
                },
                true,
                false,
                false,
              ),
            );
            throw new SubmissionError(Pages.RE_SECURITY_CHECK);
          }
          /** If error code does not match 'security-question-max-retries' then
           * throw the error so it can be visable to the user * */
          throw error;
        });
    },
  },
  {
    ...createSimpleRequiredValidation(userDetailFields.SECURITY_ANSWER),
  },
);

const mapStateToProps = () => state => {
  const securityQuestion = state.view.components?.emailRecovery?.securityQuestion;

  return {
    securityQuestion,
    state,
  };
};

const connected = connect(mapStateToProps, dispatch => ({
  openModal: (...args) => {
    dispatch(openModal(...args));
  },
}));

export default compose(connected, enhanced)(RecoverEmailSecurityQuestion);
