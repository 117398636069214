import React from 'react';
import { ColorRed400 } from '@slimming-world/ui-library-tokens';
import { IconIdea } from '@slimming-world/ui-library-components';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import TextNew, { ElementTypes, FontFamily, FontSizes, TextTypes } from '../../atoms/TextNew';

import './next-step.scss';

const NextStep = (
  {
    titleLocale,
    descriptionLocale,
    children,
  }: { titleLocale?: string; descriptionLocale: string; children: React.ReactNode },
  {},
  className: string,
): JSX.Element => (
  <aside className={className}>
    <>
      <header>
        <span className="lightbulb">
          <IconIdea width={18} color={ColorRed400} />
        </span>
        <TextNew
          localeId={titleLocale || 'nextStep.defaultTitle'}
          family={FontFamily.SERIF}
          element={ElementTypes.H3}
          size={FontSizes.SM}
        />
      </header>
      <TextNew type={TextTypes.PRIMARY_ELEGANT} localeId={descriptionLocale} />
      {children}
    </>
  </aside>
);

export default withFunctionalClassName(ComponentType.MOLECULE, 'NextStep')(NextStep);
