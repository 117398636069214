const FormNames = {
  // Account
  LOGIN: 'login',
  LOGOUT: 'logout',
  LOGGED_IN: 'loggedIn',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  CHANGE_EMAIL: 'changeEmail',
  CHANGE_PASSWORD: 'changePassword',
  ONLINE_REGISTRATION: 'onlineRegistration',
  GROUP_REGISTRATION: 'groupRegistration',
  CARD_CHECK: 'cardCheck',
  START_AFRESH: 'startAfresh',
  INTRODUCTION: 'introduction',
  MEDICAL_CHECK: 'medicalCheck',
  PERSONAL_DETAILS: 'personalDetails',
  SUMMARY: 'summary',
  VOUCHER: 'voucher',
  CHECKOUT: 'checkout',
  PACKAGE_SELECTION: 'packageSelection',
  REGION_SELECTION: 'regionSelection',
  GROUP_REG_REGION_SELECTION: 'groupRegRegionSelection',
  MEDICAL_DIETARY_MODAL: 'medicalDietaryModal',
  NOT_YOU: 'notYou',

  CREATE_POST: 'createPost',
  CREATE_COMMENT: 'createComment',
  EDITABLE_TAG_LIST: 'editableTagList',
  WEIGH_IN_TODO_LIST: 'weighInTodoList',
  MEMBER_PROFILE_UPDATE_BIOGRAPHY: 'memberProfileUpdateBiography',
  CONTACT_FORM: 'contactForm',
  EDIT_MEMBER_PROFILE: 'editMemberProfile',
  FOOD_OPTIMISING_DIARY_APP_SIGN_UP: 'foodOptimisingDiaryAppSignUp',
  SEARCH_FILTER: 'searchFilter',

  COMMUNITY_OVERVIEW_NAV: 'communityOverviewNav',

  RE_RECOVER_EMAIL: 'recoverEmail',
  RE_SECURITY_CHECK: 'recoverEmailDOB',
  RE_SECURITY_QUESTION: 'securityQuestion',
  RE_CHANGE_EMAIL: 'changeEmail',

  TRANSFER_LAUNCHER: 'transfer-check',

  SETTINGS_MEASUREMENTUNIT: 'measurementUnit',
  SETTINGS_MEDICALDETAILS: 'medicalDetails',
  SETTINGS_ORDER_HISTORY: 'orderHistory',
  SETTINGS_PURCHASE_RETURNS: 'purchaseReturns',
  SETTINGS_DIETARYREQUIREMENTS: 'dietaryRequirements',
  SETTINGS_UPDATE_PERSONAL: 'updatePersonal',
  SETTINGS_UPDATE_ACCOUNT: 'updateAccount',
  SETTINGS_UPDATE_EMAILS: 'updateEmails',
  SETTINGS_HALL_OF_FAME: 'hallOfFame',
  SETTINGS_UPDATE_GROUP_CARD: 'updateGroupCard',

  SETTINGS_WEIGHIN_DAY: 'updateWeighinDay',
  SETTINGS_WEIGHT_LOSS_JOURNEY: 'weightLossJourney',
  SETTINGS_UPDATE_PREGNANCY: 'pregnancy',

  // Start / restart journey - group members
  NEW_GROUP_JOURNEY: 'newGroupWeightLossJourney',
  NEW_GROUP_JOURNEY_START_DATE: 'startDateWLJ',
  NEW_GROUP_JOURNEY_CURRENT_HEIGHT: 'currentHeightWLJ',
  NEW_GROUP_JOURNEY_CURRENT_WEIGHT: 'currentWeightWLJ',
  NEW_GROUP_JOURNEY_TARGET_WEIGHT: 'targetWeightWLJ',
  NEW_GROUP_JOURNEY_START_WEIGHT: 'startWeightWLJ',
  NEW_GROUP_JOURNEY_CONFIRMATION: 'confirmationtWLJ',

  // Start journey - online members
  SETTINGS_RESTART_JOURNEY: 'newOnlineWeightLossJourney',
  SETTINGS_CURRENT_WEIGHT_WLJ: 'currentWeightWLJ',
  SETTINGS_TARGET_WEIGHT_WLJ: 'targetWeightWLJ',
  SETTINGS_WEIGH_IN_DAY_WLJ: 'weighInDayWLJ',

  // Shared confirmation screen
  SETTINGS_NEW_JOURNEY_CONFIRMED: 'newJourneyConfirmed',

  PB_PERSONAL_DETAILS: 'profilePersonalDetails',
  PB_GROUP_DETAILS: 'groupDetails',
  PB_TARGET_WEIGHT: 'targetWeight',
  PB_MEDICAL_CONDITIONS: 'medicalConditions',
  PB_DIETARY_PREFERENCES: 'dietaryPreferences',
  PB_SELECT_GROUP: 'selectGroup',

  MY_PROFILE: 'myProfile',

  // WeighIn form names
  WI_WEIGH_IN: 'weighIn',
  WI_ENTER_WEIGHT: 'enterWeight',
  WI_CONFIRM_WEIGHT: 'confirmWeight',
  WI_AWARD: 'award',
  WI_GOALS: 'goals',
  WI_MOOD: 'mood',
  WI_PERSONAL_FEEDBACK: 'personalFeedback',
  WI_PERSONAL_NOTE: 'personalNote',
  WI_SKIP: 'skip',
  WI_HISTORY_TABLE: 'weighInHistory',

  FIRSTWEIGHIN_CHECK: 'firstWeighInConfirm',
  FIRSTWEIGHIN_CHECK_WELCOME: 'firstWeighInWelcome',
  FIRSTWEIGHIN_WEIGHT_CONFIRM: 'firstWeighInWeightConfirm',
  FIRSTWEIGHIN_HEIGHT_CONFIRM: 'firstWeighInHeightConfirm',
  FIRSTWEIGHIN_BMI_OK_PROCEED: 'firstWeighInSuccessProceed',
  FIRSTWEIGHIN_BMI_TOO_LOW: 'firstWeighInBmiTooLow',
  FIRSTWEIGHIN_REFUND_CLOSE: 'firstWeighInRefundClose',

  AWI_WEIGH_IN: 'addMissingWeighIn',
  AWI_ADD_WEIGHT: 'addMissingWeight',
  AWI_ADD_WEIGHT_CONFIRMATION: 'addMissingWeightConfirmation',
  AWI_ADD_WEIGHT_UPDATED: 'addMissingWeightUpdated',

  EWI_WEIGH_IN: 'editWeighIn',
  EWI_EDIT_WEIGHT: 'editWeight',
  EWI_EDIT_WEIGHT_CONFIRMATION: 'editWeightConfirmation',
  EWI_EDIT_WEIGHT_UPDATED: 'editWeightUpdated',

  EDIT_START_WEIGHT: 'editStartWeight',
  EDIT_TARGET_WEIGHT: 'editTargetWeight',

  SEARCH_GLOBAL: 'searchGlobal',
  SEARCH_RECIPES: 'searchRecipes',
  SEARCH_COMMUNITY: 'searchCommunity',
  SEARCH_FEATURES: 'searchFeatures',
  SEARCH_SUCCESS_STORIES: 'searchSuccessStories',

  LEGACY_WEIGH_INS_IMPORT: 'legacyWeighInsImport',

  // E-Shop form names
  SHOP_BASKET: 'shopBasket',
  SHOP_PRODUCT_DETAIL: 'productDetail',
  SHOP_CHECKOUT: 'shopCheckout',
  SHOP_CHECKOUT_PERSONAL_DETAILS: 'shopCheckoutPersonalDetails',
  SHOP_CHECKOUT_YOUR_ADDRESS: 'shopCheckoutYourAddress',
  SHOP_CHECKOUT_SHIPPING_ADDRESS: 'shopCheckoutShippingAddress',
  SHOP_CHECKOUT_BILLING_ADDRESS: 'shop_checkoutBillingAddress',

  // Extend membership form names
  EXTEND_MEMBERSHIP_PACKAGE_SELECTION: 'packageSelection',
  EXTEND_MEMBERSHIP_CONFIRM_ADDRESS: 'confirmAddress',
  EXTEND_MEMBERSHIP_SHIPPING_ADDRESS_MODAL: 'shippingAddress',

  // Food Planner
  FOOD_SEARCH: 'foodSearch',
  RECIPE_SEARCH: 'recipeSearch',
  ACTIVITY_SEARCH: 'activitySearch',
  ACTIVITY_SEARCH_BOOKMARKS: 'activitySearchBookmarks',
  ADD_FOOD: 'addFood',
  COPY_FOOD: 'copyFood',
  EDIT_PLAN: 'editPlan',
  SYNS_ESTIMATOR: 'synsEstimator',
  SYNS_ESTIMATOR_ALLOWANCE: 'synsEstimatorAllowance',
  SYNS_ESTIMATOR_NUTRITIONAL_INFO: 'synsEstimatorNutritionalInfo',
  SYNS_ESTIMATOR_UPLOAD_PHOTOS: 'synsEstimatorUploadPhotos',
  BODY_MAGIC_START_AWARD: 'bodyMagicStartAward',

  ADD_ACTIVITY: 'addActivity',
  ADD_OWN_ACTIVITY: 'addOwnActivity',
  CREATE_CUSTOM_FOOD: 'createCustomFood',

  // Healthy Extras
  HEALTHY_EXTRAS: 'healthyExtras',

  RECIPE_INSTRUCTION_SELECTION: 'recipeInstructionSelection',
  PREGNANCY_NOTIFICATION_MODAL: 'pregnancyNotificationModal',
  NEWSLETTER_SIGNUP: 'newsletterSignUp',

  // Public site forms
  PUBLIC_GROUP_SEARCH: 'publicGroupSearch',
  PUBLIC_BMI_CALCULATOR: 'bmiCalculator',
  PUBLIC_SEVEN_DAY_MENU: 'publicSevenDayMenu',
  PUBLIC_CYPRUS_GROUP_SEARCH: 'publicCyprusGroupSearch',
  PUBLIC_ICELAND_RANGE: 'publicIcelandRange',
  PUBLIC_ICELAND_OUR_RANGE: 'publicIcelandOurRange',

  COMPETITION_ENTRY: 'competitionEntry',
  COMPETITION_ENTRY_SUBMIT: 'competitionEntrySubmit',
  COMPETITION_ENTRY_DETAILS: 'competitionEntryDetails',
  COMPETITION_ENTRY_QUESTIONNAIRE: 'competitionEntryQuestionnaire',
  COMPETITION_ENTRY_PHOTOS: 'competitionEntryPhotos',

  // Advertising Artwork
  ADS_DETAIL: 'adsDetail',

  // Multi Factor Authentication
  MULTI_FACTOR_AUTHENTICATION_FORM: 'multiFactorAuthenticationForm',

  PODCAST_OVERVIEW: 'podcastOverview',
};

export default FormNames;
