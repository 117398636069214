import createAction from 'redux-actions/lib/createAction';
import { apiPost, apiGet, apiPut } from './apiActions/apiRequest';
import { GATEWAY_GROUP_ACCOUNT, GATEWAY_ACCOUNT_AUTH } from '../../data/Injectables';
import { getValue } from '../../util/injector';

/**
 * AFT for group account api calls
 * @return {object}
 * Use the returned result.data in the head of subsequent calls:
 * {
 *   headers: {
 *    'X-XSRF-Token': result.data,
 *   },
 *   credentials: 'include',
 * }
 *
 */
export const getAntiForgeryToken = () => () =>
  getValue(GATEWAY_GROUP_ACCOUNT).get('/antiforgery', null, {
    credentials: 'include',
  });

/**
 * Action to check if membership card, pin and email address are already registered
 * @param {string} membershipCard
 * @param {string} pin
 * @param {string} email
 *
 * @returns {object}
 */
export const SUBMISSION_COUNT = 'groupAccountActions/SUBMISSION_COUNT';
export const updateSubmissionCount = createAction(SUBMISSION_COUNT);

export const CARD_CHECK_SAVE = 'groupAccountActions/CARD_CHECK_SAVE';
export const saveCardResults = createAction(CARD_CHECK_SAVE);

export const CARD_CHECK = 'groupAccountActions/CARD_CHECK';

export const cardCheck = values => dispatch =>
  dispatch(getAntiForgeryToken())
    .then(cardResult => {
      // This increments a count in state by 1
      dispatch(updateSubmissionCount());
      return cardResult.data;
    })
    .then(result =>
      dispatch(
        apiPost(CARD_CHECK, GATEWAY_GROUP_ACCOUNT, '/cards', values, {
          headers: {
            'X-XSRF-Token': result,
          },
          credentials: 'include',
        }),
      ).then(cardResult => {
        dispatch(saveCardResults(cardResult.data));
        return cardResult.data;
      }),
    );

/**
 * Action to check if account matched users details
 * @param {string} FirstName
 * @param {string} LastName
 * @param {string} DateOfBirth
 * @param {string} Postcode
 * @param {string} CardToken
 *
 * @return {object}
 *
 * Card token should be return from the previous cardCheck function call
 */
export const SAVE_ACCOUNTS_EMAIL = 'groupAccountActions/SAVE_ACCOUNTS_EMAIL';
export const saveAccountsEmail = createAction(SAVE_ACCOUNTS_EMAIL);

/**
 * Save new email
 * @param {string} SecurityQuestionAnswer
 * @param {string} CardToken
 * @param {string} Email
 *
 * @return {object}
 *
 * Card token should be return from the previous cardCheck funtion call
 */

export const VERIFIED_IDENTITY_TOKEN = 'groupAccountActions/VERIFIED_IDENTITY_TOKEN';
export const saveVerifiedIdentityToken = createAction(VERIFIED_IDENTITY_TOKEN);

export const GET_GROUP_MEMBERSHIP_DETAILS = 'groupAccountActions/GET_GROUP_MEMBERSHIP_DETAILS';

export const getGroupMembershipDetails = () => dispatch =>
  dispatch(
    apiGet(
      GET_GROUP_MEMBERSHIP_DETAILS,
      GATEWAY_ACCOUNT_AUTH,
      '/accounts/me/group-membership-details',
    ),
  );

export const UPDATE_CARD = 'groupAccountActions/UPDATE_CARD';

export const updateCard = values => dispatch =>
  dispatch(apiPut(UPDATE_CARD, GATEWAY_ACCOUNT_AUTH, '/accounts/me/card-number', values));
