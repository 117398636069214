/* global 'page','Payment' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { ProgressIndicator } from '@slimming-world/ui-library-components';
import BackLink from 'components/molecules/BackLink';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Pages from 'common/src/app/data/enum/Pages';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import Header from 'components/organisms/Header';
import Wrapper from 'components/atoms/Wrapper';
import SlimmingWorldLogo from 'components/atoms/SlimmingWorldLogo';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';

import './payment.scss';

const Payment = (
  { children, deviceState, background, location: { pathname } },
  context,
  className,
) => {
  const isComplete = pathname === Pages.REGISTRATION_FINISHED;

  return (
    <div className={className}>
      <Header
        center={<SlimmingWorldLogo />}
        bottom={
          <>
            {!isComplete && (
              <BackLink hasValidBackRoute={true} labelVisible={deviceState > DeviceState.LG} />
            )}
            <ProgressIndicator
              className="progress-indicator"
              width={220}
              percent={isComplete ? 100 : 90}
            />
          </>
        }
      />

      <BackgroundImageWrapper
        className={className}
        image={deviceState >= DeviceState.LG && background ? background : ''}
      >
        <Wrapper width="ls" padding="xxl" background="primary-light">
          {children}
        </Wrapper>
      </BackgroundImageWrapper>
    </div>
  );
};

Payment.propTypes = {
  background: ImagePropTypes,
  children: PropTypes.node,
  deviceState: PropTypes.number,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withDeviceState()(withFunctionalClassName('page','Payment')(Payment));
