import React from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormNames from 'common/src/app/data/enum/FormNames';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import TextNew, { Colors, ElementTypes, TextTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import GroupedPasswordInput from 'components/molecules/GroupedPasswordInput';
import LocaleMessage from 'components/atoms/LocaleMessage';
import errorBoundary from 'components/hoc/ErrorBoundary';
import Pages from 'common/src/app/data/enum/Pages';
import PasswordValidationConfig from 'common/src/app/validation/passwordValidationConfig';

type ChangePasswordProps = {
  submitting: boolean;
  handleSubmit: () => void;
  currentUser: string;
  submitSucceeded: boolean;
  fromUrl: string;
  disallowListPasswordError?: {
    message: string;
  };
  passwordValidationConfig: PasswordValidationConfig;
};

const ChangePassword = (
  {
    submitting = false,
    handleSubmit,
    currentUser,
    submitSucceeded,
    fromUrl,
    disallowListPasswordError,
    passwordValidationConfig,
  }: ChangePasswordProps,
  {},
  className: string,
): JSX.Element | null => {
  if (!passwordValidationConfig) {
    return null;
  }

  return (
    <form className={className} onSubmit={handleSubmit} autoComplete="off">
      {submitSucceeded ? (
        <>
          <TextNew
            type={TextTypes.LOUD}
            element={ElementTypes.H1}
            localeId="changePassword.confirmation"
          />
          <TextNew type={TextTypes.FORMAL} localeId="changePassword.youHaveChangedYourPassword" />
          <Row justifycenter>
            <Button
              ripple
              type="button"
              id="signIn"
              href={Pages.LOGIN}
              disabled={submitting}
              isLoading={submitting}
            >
              <LocaleMessage id="login.signIn" />
            </Button>
          </Row>
        </>
      ) : (
        <>
          <TextNew
            type={TextTypes.LOUD}
            element={ElementTypes.H1}
            localeId="changePassword.title"
          />
          <TextNew
            type={TextTypes.PRIMARY_ELEGANT}
            localeId="changePassword.introText"
            localeParams={{ NAME: currentUser }}
          />
          <TextNew type={TextTypes.PRIMARY_ELEGANT} localeId="changePassword.infoText" />

          <GroupedPasswordInput
            formName={FormNames.CHANGE_PASSWORD}
            customPasswordValidation={
              disallowListPasswordError && (
                <TextNew type={TextTypes.ERROR} color={Colors.BRAND}>
                  {disallowListPasswordError.message}
                </TextNew>
              )
            }
            passwordValidationConfig={passwordValidationConfig}
            showAll
          />
          <Row justifycenter>
            <Button type="submit" disabled={submitting}>
              <LocaleMessage id="changePassword.submitButton" />
            </Button>
          </Row>
          <FormErrorMessages showGeneral />
        </>
      )}
    </form>
  );
};

type ChangePasswordElementType = (props: ChangePasswordProps) => JSX.Element;

export default compose<ChangePasswordElementType>(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.TEMPLATE, 'ChangePassword'),
)(ChangePassword);
