/* global 'atom' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import Icon from '../Icon';
import Input from '../Input';

import './password-input.scss';

class PasswordInput extends Component {
  componentDidMount() {
    const { hidePassword, form, name, showVisibilityToggle } = this.props;

    if (showVisibilityToggle) {
      hidePassword(form, name);
    }
  }

  togglePassword = () => {
    const { showPassword, hidePassword, isVisible, form, name } = this.props;

    if (isVisible) {
      hidePassword(form, name);
    } else {
      showPassword(form, name);
    }
  };

  render() {
    const {
      isVisible,
      showVisibilityToggle,
      showPassword, // eslint-disable-line no-unused-vars
      hidePassword, // eslint-disable-line no-unused-vars
      visibilityFrom, // eslint-disable-line no-unused-vars
      ...props
    } = this.props;
    const passwordType = isVisible ? 'text' : 'password';
    const iconType = isVisible ? 'hide' : 'open';

    return (
      <div {...componentClassNameProp('atom', this, null, ['is-input-wrapper'])}>
        <EnhancedField
          type={passwordType}
          isInputWrapper={false}
          component={Input}
          suppress
          {...props}
        />
        {showVisibilityToggle && !props.disabled ? (
          <button
            aria-expanded={isVisible}
            className="password-toggle"
            onClick={this.togglePassword}
            type="button"
          >
            <div className="screen-reader">
              <LocaleMessage id={`passwordInput.${isVisible ? 'hide' : 'show'}`} />
            </div>
            <Icon name={`eye-${iconType}`}>{iconType}</Icon>
          </button>
        ) : null}
      </div>
    );
  }
}

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  /**
   * If true, show a visibility toggle over the input
   */
  showVisibilityToggle: PropTypes.bool,
  /**
   * Passed by redux connect()
   */
  isVisible: PropTypes.bool.isRequired,
  /**
   * Passed by redux connect()
   */
  form: PropTypes.string.isRequired,
  showPassword: PropTypes.func.isRequired,
  hidePassword: PropTypes.func.isRequired,
  /**
   * Used to link visibility icon to two password fields, only used in connect
   */
  visibilityFrom: PropTypes.string,
};

export default PasswordInput;
