import moment from 'moment';
import Activity from 'common/src/app/interfaces/Activity';
import ActivityGoal from 'common/src/app/interfaces/ActivityGoal';
import { convertDurationToMinutes } from 'common/src/app/util/formatDuration';
import { convertBitwiseToArrayOfFlags } from 'common/src/app/util/bitwiseUtils';
import {
  AllActivityTypesEnum,
  AllActivityVideoTypes,
  SegmentDurationEnum,
  SegmentDurationTypes,
} from 'common/src/app/data/enum/ActivityVideoTypes';

export const arrayToListSentence = (
  wordArray: string[],
  wordConnector?: string,
  unit?: string,
): string => {
  const hasMultipleWords = wordArray.length > 1;
  const formattedUnit = unit ? ` ${unit}` : '';
  const formattedConnector = wordConnector && hasMultipleWords ? ` ${wordConnector}` : '';

  if (hasMultipleWords) {
    const [last] = wordArray.slice(-1);
    wordArray.pop();
    return `${wordArray.join(', ')}${formattedConnector} ${last}${formattedUnit}`;
  }
  return unit ? `${wordArray[0]}${formattedConnector}${formattedUnit}` : `${wordArray[0]}`;
};

export const activityBitwiseToSentence = ({
  flags,
  bitwise,
  flagLabels,
  wordConnector,
  unit,
}: {
  flags: Record<number, number>;
  bitwise: number;
  flagLabels: Record<number, string>;
  wordConnector?: string;
  unit?: string;
}): string => {
  const matchingFlags = convertBitwiseToArrayOfFlags(flags, bitwise).map(
    (type: number) => flagLabels[type],
  );
  return arrayToListSentence(matchingFlags, wordConnector, unit);
};

export const formatSegmentDuration = (segmentDuration: number): string =>
  activityBitwiseToSentence({
    flags: SegmentDurationEnum,
    flagLabels: SegmentDurationTypes,
    bitwise: segmentDuration,
    wordConnector: 'or',
    unit: 'minutes',
  });

export const formatActivityType = (activityType: number): string =>
  activityBitwiseToSentence({
    flags: AllActivityTypesEnum,
    flagLabels: AllActivityVideoTypes,
    bitwise: activityType,
    wordConnector: 'and',
  });

export const isActivityQualifying = (activity: Activity, activityGoal: ActivityGoal): boolean => {
  if (!activity || !activity.duration || !activityGoal) return false;
  const plannedDate = moment(activity.plannedDateUTC).format('YYYY-MM-DD');
  const isActivityDuringAward =
    plannedDate >= moment(activityGoal.startDateUTC).format('YYYY-MM-DD') &&
    plannedDate <= moment(activityGoal.endDateUTC).format('YYYY-MM-DD');
  const durationInMins = convertDurationToMinutes(activity.duration);
  const minimumSessionTime = activityGoal?.goalDetails?.measures?.minimumSessionTime || 0;

  return durationInMins >= minimumSessionTime && isActivityDuringAward;
};
