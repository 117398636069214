import { createSelector } from 'reselect';
import { userDetailFields, emailFields } from '../data/enum/FieldNames/AccountFieldNames';
import AccountState from '../data/enum/AccountState';
import { hasFlag } from '../util/bitwiseUtils';

export const initialIntroductionValuesSelector = createSelector(
  state => state.registration,
  registration => ({
    [userDetailFields.FIRST_NAME]: registration?.invite?.firstName || '',
    [userDetailFields.LAST_NAME]: registration?.invite?.lastName || '',
    [emailFields.EMAIL_ADDRESS]: registration?.invite?.email || '',
    [emailFields.CONFIRM_EMAIL_ADDRESS]: registration?.invite?.email || '',
  }),
);

export const initialMedicalCheckValuesSelector = createSelector(
  state => state.registration,
  registration => ({
    [userDetailFields.FIRST_NAME]: registration?.invite?.firstName || '',
    [userDetailFields.LAST_NAME]: registration?.invite?.lastName || '',
  }),
);

export const initialPersonalDetailsValuesSelector = createSelector(
  state => state.registration,
  registration => ({
    [emailFields.EMAIL_ADDRESS]: registration?.invite?.email || '',
    [emailFields.CONFIRM_EMAIL_ADDRESS]: registration?.invite?.email || '',
  }),
);

export const isInviteSelector = createSelector(
  state => state.registration?.invite?.id,
  inviteId => !!inviteId,
);

export const hasValidSubscriptionSelector = createSelector(
  // eslint-disable-next-line camelcase
  state => state?.registration.accountState || state?.identity.user.account_state,
  accountState =>
    hasFlag(accountState, AccountState.ONLINE_SUBSCRIPTION_VALID) ||
    hasFlag(accountState, AccountState.GROUP_MEMBERSHIP_VALID),
);
