import handleActions from 'redux-actions/lib/handleActions';
import { REGISTER_DATE_SELECTED } from '../../../actions/components/dayPickerActions';

const initialState = {
  dayPickerSelectedDate: new Date(),
};

export default handleActions(
  {
    [REGISTER_DATE_SELECTED]: (state, { payload }) => ({
      ...state,
      dayPickerSelectedDate: payload,
    }),
  },
  initialState,
);
