import createAction from 'redux-actions/lib/createAction';
import debugLib from 'debug';
import InteractionType from '../../data/enum/InteractionType';
import { GATEWAY_COMMUNITY_AUTH } from '../../data/Injectables';
import dedupeAsyncThunk from '../../util/dedupeAsyncThunk';
import { getEntityTypeFromRelatedEntityKind } from '../../data/enum/RelatedEntityKind';
import { setEntity } from '../entities/entityActions';
import { apiDelete, apiGet, apiPost } from './apiActions/apiRequest';
import { userAccountSelector } from '../../selectors/userAccountSelectors';
import { BOOKMARKS, FAVOURITES } from '../../data/collectionIds';
import { USER_FAVOURITE, PLANNER_ACTIVITY_TYPE, POST } from '../../data/entityTypes';
import { collectionRemoveRefs, collectionUnshift } from '../entities/collectionActions';
import { getBookmarkTileTypeFromSubjectKind } from './bookmarkActions';
import { getPlannerActivityType } from './plannerActivitySummaryActions';
import apiGetCollection from './apiActions/apiGetCollection';

const debug = debugLib('SlimmingWorld:interactionActions');

export const ADD_INTERACTIONS = 'interactionActions/ADD_INTERACTIONS';
export const GET_LIKES_FOR_ARTICLE = 'interactionActions/GET_LIKES_FOR_ARTICLE';
export const LIKE = 'interactionActions/LIKE';
export const BOOKMARK = 'interactionActions/BOOKMARK';
export const PIN = 'interactionActions/PIN';
export const READS = 'interactionActions/READS';
export const GET_FAVOURITES = 'interactionActions/GET_FAVOURITES';
export const FAVOURITE = 'interactionActions/FAVOURITES';
export const GET_USER_INTERACTIONS = 'interactionActions/GET_USER_INTERACTIONS';
export const REQUEST_USER_INTERACTIONS = 'interactionActions/REQUEST_USER_INTERACTIONS';
export const ADD_INTERACTION = 'interactionActions/ADD_INTERACTION';
export const REMOVE_INTERACTION = 'interactionActions/REMOVE_INTERACTION';

export const getUserInteractions = dedupeAsyncThunk(() => (dispatch, getState) => {
  const { interactions } = getState();

  if (interactions.loadedInitial) {
    return Promise.resolve();
  }

  return dispatch(
    createAction(GET_USER_INTERACTIONS)(
      dispatch(
        apiGet(REQUEST_USER_INTERACTIONS, GATEWAY_COMMUNITY_AUTH, '/aggregators/interactioninfo'),
      ).then(({ data }) =>
        Object.keys(data).forEach(interactionType =>
          dispatch(addInteractions(interactionType, data[interactionType])),
        ),
      ),
    ),
  );
});

export const addInteractions = createAction(
  ADD_INTERACTIONS,
  (interactionType, interactions) => ({ interactions }),
  interactionType => ({ interactionType }),
);

const addInteraction = createAction(
  ADD_INTERACTION,
  promise => promise,
  (
    promise,
    interactionType,
    interactionSubjectId,
    interactionSubjectKind,
    interactionSubjectTitle = '',
  ) => ({
    interactionType,
    interactionSubjectId,
    interactionSubjectKind,
    interactionSubjectTitle,
  }),
);

const removeInteraction = createAction(
  REMOVE_INTERACTION,
  promise => promise,
  (
    promise,
    interactionType,
    interactionSubjectId,
    interactionSubjectKind,
    interactionSubjectTitle = '',
  ) => ({
    interactionType,
    interactionSubjectId,
    interactionSubjectKind,
    interactionSubjectTitle,
  }),
);

export const getLikesForArticle = (articleType, articleId) => dispatch =>
  dispatch(
    apiGet(GET_LIKES_FOR_ARTICLE, GATEWAY_COMMUNITY_AUTH, '/aggregators/count', {
      articleId,
      types: [0],
    }),
  )
    .then(({ data }) => {
      dispatch(setEntity(articleType, articleId, { likesCount: data.likes }, true));
    })
    .catch(e => {
      debug(`Unable to get likes for article ${articleId}`);
      debug(e);
    });

export const like = (subjectId, subjectKind, subjectTitle) => (dispatch, getState) =>
  dispatch(
    addInteraction(
      dispatch(
        apiPost(LIKE, GATEWAY_COMMUNITY_AUTH, '/profiles/me/likes', { subjectId, subjectKind }),
      ).then(() => {
        const entityType = getEntityTypeFromRelatedEntityKind(subjectKind);
        const state = getState();
        const user = userAccountSelector(state);
        const { entities } = state;
        if (entities[entityType] && entities[entityType][subjectId]) {
          const { likesCount = 0 } = entities[entityType][subjectId];
          dispatch(
            setEntity(
              entityType,
              subjectId,
              {
                likesCount: likesCount + 1,
                lastLike: {
                  id: null,
                  userId: user.id,
                  userInfo: {
                    userName: user.userName,
                    avatarUrl: user.avatar,
                    firstName: user.firstName,
                  },
                },
              },
              true,
            ),
          );
        }
      }),
      InteractionType.LIKE,
      subjectId,
      subjectKind,
      subjectTitle,
    ),
  );

export const unlike = (subjectId, subjectKind, subjectTitle) => (dispatch, getState) =>
  dispatch(
    removeInteraction(
      dispatch(apiDelete(LIKE, GATEWAY_COMMUNITY_AUTH, `/profiles/me/likes/${subjectId}`)).then(
        () => {
          const entityType = getEntityTypeFromRelatedEntityKind(subjectKind);
          const { entities } = getState();
          if (entities[entityType] && entities[entityType][subjectId]) {
            const { likesCount = 0 } = entities[entityType][subjectId];
            dispatch(
              setEntity(
                entityType,
                subjectId,
                {
                  likesCount: Math.max(0, likesCount - 1),
                  lastLike: null,
                },
                true,
              ),
            );
          }
        },
      ),
      InteractionType.LIKE,
      subjectId,
      subjectTitle,
    ),
  );

export const bookmark = (subjectId, subjectKind, subjectTitle) => (dispatch, getState) =>
  dispatch(
    addInteraction(
      dispatch(
        apiPost(BOOKMARK, GATEWAY_COMMUNITY_AUTH, '/profiles/me/bookmarks', {
          subjectId,
          subjectKind,
        }),
      ),
      InteractionType.BOOKMARK,
      subjectId,
      subjectKind,
      subjectTitle,
    ),
  ).then(res => {
    const state = getState();

    // only add to collection if the collection is loaded in state
    // otherwise, the bookmarks page will request an up-to-date collection itself
    if (state.collections[BOOKMARKS]) {
      const entityType = getBookmarkTileTypeFromSubjectKind(subjectKind);

      dispatch(collectionUnshift(BOOKMARKS, [{ id: subjectId, type: entityType }]));
    }

    return res;
  });

export const unbookmark = (subjectId, subjectTitle) => (dispatch, getState) =>
  dispatch(
    removeInteraction(
      dispatch(apiDelete(BOOKMARK, GATEWAY_COMMUNITY_AUTH, `/profiles/me/bookmarks/${subjectId}`)),
      InteractionType.BOOKMARK,
      subjectId,
      subjectTitle,
    ),
  ).then(res => {
    const state = getState();

    // only remove from collection if the collection is loaded in state
    // otherwise, the bookmarks page will request an up-to-date collection itself
    if (state.collections[BOOKMARKS]) {
      dispatch(collectionRemoveRefs(BOOKMARKS, [{ id: subjectId }]));
    }

    return res;
  });

export const read = (subjectId, subjectKind) => dispatch =>
  dispatch(
    addInteraction(
      dispatch(
        apiPost(READS, GATEWAY_COMMUNITY_AUTH, '/profiles/me/reads', { subjectId, subjectKind }),
      ),
      InteractionType.READ,
      subjectId,
      subjectKind,
    ),
  );

export const unread = subjectId => dispatch => {
  const id = [].concat(subjectId);
  return dispatch(
    removeInteraction(
      dispatch(apiDelete(READS, GATEWAY_COMMUNITY_AUTH, `/profiles/me/reads/${id.join(',')}`)),
      InteractionType.READ,
      subjectId,
    ),
  );
};

export const pin = (subjectId, subjectKind) => (dispatch, getState) =>
  dispatch(
    addInteraction(
      dispatch(apiPost(PIN, GATEWAY_COMMUNITY_AUTH, `/posts/${subjectId}/pin`)),
      InteractionType.PIN,
      subjectId,
      subjectKind,
    ),
  ).then(() => {
    const entityType = getEntityTypeFromRelatedEntityKind(subjectKind);
    const { entities } = getState();
    const entity = entities[entityType][subjectId];
    let pinType = 'isPinned';

    // If is part of a community group set isCommunityGroupPinned property
    if (entityType === POST && entity.communityGroupId !== null) {
      pinType = 'isCommunityGroupPinned';
    }

    if (entities[entityType] && entity) {
      dispatch(
        setEntity(
          entityType,
          subjectId,
          {
            [pinType]: true,
          },
          true,
        ),
      );
    }
  });

export const unpin = (subjectId, subjectKind) => (dispatch, getState) =>
  dispatch(
    removeInteraction(
      dispatch(apiDelete(PIN, GATEWAY_COMMUNITY_AUTH, `/posts/${subjectId}/pin`)),
      InteractionType.PIN,
      subjectId,
      subjectKind,
    ),
  ).then(() => {
    const entityType = getEntityTypeFromRelatedEntityKind(subjectKind);
    const { entities } = getState();
    const entity = entities[entityType][subjectId];
    let pinType = 'isPinned';

    // If is part of a community group set isCommunityGroupPinned property
    if (entityType === POST && entity.communityGroupId !== null) {
      pinType = 'isCommunityGroupPinned';
    }

    if (entities[entityType] && entity) {
      dispatch(
        setEntity(
          entityType,
          subjectId,
          {
            [pinType]: false,
          },
          true,
        ),
      );
    }
  });

export const getFavourites = () =>
  apiGetCollection(
    GET_FAVOURITES,
    GATEWAY_COMMUNITY_AUTH,
    '/profiles/me/favourites',
    FAVOURITES,
    {},
    {
      entityType: USER_FAVOURITE,
      storeEntities: false,
    },
  );

export const favourite = (subjectId, subjectKind, subjectTitle) => dispatch =>
  dispatch(
    addInteraction(
      dispatch(
        apiPost(FAVOURITE, GATEWAY_COMMUNITY_AUTH, '/profiles/me/favourites', {
          subjectId,
          subjectKind,
        }),
      ),
      InteractionType.FAVOURITE,
      subjectId,
      subjectKind,
      subjectTitle,
    ),
  )
    .then(() =>
      dispatch(getPlannerActivityType(subjectId, true)).then(() =>
        dispatch(setEntity(PLANNER_ACTIVITY_TYPE, subjectId, { favourite: true }, true)),
      ),
    )
    .catch(e => {
      debug(`Unable to set favourite for ${subjectId}`);
      debug(e);
    });

export const unfavourite = (subjectId, subjectTitle) => (dispatch, getState) =>
  dispatch(
    removeInteraction(
      dispatch(
        apiDelete(FAVOURITE, GATEWAY_COMMUNITY_AUTH, `/profiles/me/favourites/${subjectId}`),
      ),
      InteractionType.FAVOURITE,
      subjectId,
      subjectTitle,
    ),
  ).then(result => {
    const state = getState();

    // only remove from collection if the collection is loaded in state
    // otherwise, the favourites page will request an up-to-date collection itself
    if (state.collections[FAVOURITES]) {
      dispatch(collectionRemoveRefs(FAVOURITES, [{ id: subjectId }]));
    }
    dispatch(setEntity(PLANNER_ACTIVITY_TYPE, subjectId, { favourite: false }, true));

    return result;
  });
