import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormSection from 'redux-form/lib/FormSection';
import Link from 'react-router/lib/Link';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import classNames from 'classnames';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { countryCodeValue } from 'common/src/app/data/enum/Countries';
import * as AddressFormats from 'common/src/app/data/AddressFormats';
import parseGMapsAddressComponents from 'common/src/app/util/form/parseGMapsAddressComponents';
import { API_GOOGLE_MAPS } from 'common/src/app/data/externalApis';
import AddressForm from '../AddressForm';
import Button from '../../atoms/Button';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import TextNew from '../../atoms/TextNew';
import { InputNew } from '../../atoms/Form';
import LocaleMessage from '../../atoms/LocaleMessage';
import Icon from '../../atoms/Icon';
import Loader from '../../atoms/Loader';
import Label from '../../atoms/Label';
import './address-lookup.scss';

class AddressLookup extends Component {
  state = {
    mapsApiLoaded: false,
    address: '',
  };

  componentDidMount() {
    this.props.getApi(API_GOOGLE_MAPS).then(() => {
      this.setState({ mapsApiLoaded: true });
    });
  }

  handleInputSwitchClick = () => {
    setTimeout(() => {
      this.props.manualInput ? this.props.inputSearch() : this.props.inputManual();
    }, 100);
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = addressResponse => {
    const { setAddressField, country } = this.props;

    geocodeByAddress(addressResponse).then(results => {
      if (results) {
        const address = parseGMapsAddressComponents(results?.[0]?.address_components, country); // eslint-disable-line camelcase
        Object.keys(address).forEach(field => setAddressField(field, address[field]));
        this.props.inputManual();
      }
    });
  };

  renderSearchInput() {
    const { name, searchPlaceholder, country, label, suppress } = this.props;

    const { mapsApiLoaded } = this.state;

    if (!mapsApiLoaded) {
      return <Loader />;
    }

    return (
      <section className="manual-address-entry-fieldset" cid="manual-address-entry-fieldset">
        <section className="field-elements">
          <Label htmlFor="geosuggest__input--billingAddress" text={label} />
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            searchOptions={{
              componentRestrictions: { country: [country || marketConfig.defaultCountry] },
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <>
                <InputNew
                  {...getInputProps({
                    placeholder: searchPlaceholder,
                  })}
                />
                {loading && <Loader />}
                {suggestions.length > 0 && (
                  <ul
                    role="listbox"
                    className={classNames('autocomplete-dropdown-container', {
                      'sw-masked-element': suppress,
                    })}
                  >
                    {suggestions.map(suggestion => {
                      const className = suggestion.active && 'active';

                      return (
                        <li
                          key={suggestion.place_id}
                          role="option"
                          aria-selected={suggestion.active}
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          {suggestion.description}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </>
            )}
          </PlacesAutocomplete>
        </section>

        <FormErrorMessages
          fields={Object.keys(
            AddressFormats[
              countryCodeValue[country] || countryCodeValue[marketConfig.defaultCountry]
            ],
          ).map(field => `${name}.${field}`)}
          id="billingAddressError"
        >
          <LocaleMessage id="addressLookup.searchErrorMessage" />
        </FormErrorMessages>
      </section>
    );
  }

  render() {
    const { name, manualInput, country, formName, showCountry, label, addressFieldRefs } =
      this.props;
    const modifiers = ['manual-input', 'state=has-geo-suggest-focus'];
    return (
      <div
        {...componentClassNameProp(ComponentType.MOLECULE, this, modifiers)}
        ref={addressFieldRefs.addressLookupRef}
      >
        <FormSection name={name}>
          {!manualInput ? this.renderSearchInput() : null}

          <AddressForm
            label={label}
            formName={formName}
            fieldNamePrefix={`${name}.`}
            country={country}
            showCountry={showCountry}
            forwardRef={addressFieldRefs}
          />

          {manualInput ? (
            <Link onClick={this.handleInputSwitchClick}>
              <TextNew.Formal>
                <Icon name={IconName.MAGNIFY_GLASS} />
                <LocaleMessage id="addressLookup.searchToggle.search" />
              </TextNew.Formal>
            </Link>
          ) : (
            <Button onClick={this.handleInputSwitchClick} ripple type="button" id="addressLookup">
              <Icon name={IconName.BLOCK_ARROW} direction={DirectionType.DOWN} />
              <LocaleMessage id="addressLookup.searchToggle.manual" />
            </Button>
          )}
        </FormSection>
      </div>
    );
  }
}

AddressLookup.defaultProps = {
  showCountry: true,
};

AddressLookup.propTypes = {
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  setAddressField: PropTypes.func.isRequired,
  manualInput: PropTypes.bool.isRequired,
  getApi: PropTypes.func.isRequired,
  inputManual: PropTypes.func.isRequired,
  inputSearch: PropTypes.func.isRequired,
  country: PropTypes.string,
  showCountry: PropTypes.bool,
  label: PropTypes.string,
  addressFieldRefs: PropTypes.object,
  // Suppress is used for our 3rd party tracking service - glassbox
  // - To mask the field we append a className of 'sw-masked-element'
  suppress: PropTypes.bool,
};

export default AddressLookup;
