import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Pages from 'common/src/app/data/enum/Pages';
import { SessionStorageKeys } from 'common/src/app/data/enum/BrowserStorageKeys';
import IconName from 'common/src/app/data/enum/IconName';
import IconButton from 'components/molecules/IconButton';
import errorBoundary from 'components/hoc/ErrorBoundary';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import NameCopy from '../../atoms/NameCopy';
import Row from '../../atoms/Row';
import './welcome-back.scss';

const WelcomeBack = ({ firstName, email, clearEmailField }, context, className) => (
  <div className={className}>
    <NameCopy name={firstName} greeting={<LocaleMessage id="welcomeBack.title" />} />
    <TextNew.Sans.SM localeId="welcomeBack.greatNews" cid="great-news" />
    <TextNew.FormalOriginal localeId="welcomeBack.greeting" />

    <Row className="step-action" justifycenter>
      <Button
        ripple
        onClick={() => {
          sessionStorage.setItem(
            SessionStorageKeys.RECOVERED_EMAIL_LOGIN,
            JSON.stringify({ [SessionStorageKeys.USER_EMAIL]: email }),
          );
        }}
        to={Pages.LOGIN}
      >
        <LocaleMessage id="welcomeBack.login" />
      </Button>
    </Row>
    <Row className="step-action" justifycenter>
      <IconButton
        onClick={() => clearEmailField()}
        icon={IconName.CHEVRON}
        iconDirection={DirectionType.LEFT}
      >
        <TextNew.Formal localeId="welcomeBack.backToRegistration" />
      </IconButton>
    </Row>
  </div>
);

WelcomeBack.propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string,
  clearEmailField: PropTypes.func,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.TEMPLATE, 'WelcomeBack'),
)(WelcomeBack);
