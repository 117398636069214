import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import currencySymbolProvider from 'common/src/app/util/currencySymbolProvider';
import calculateDiscountedPackagePrice from 'common/src/app/util/calculateDiscountedPackagePrice';
import subscriptionPackageShape from 'common/src/app/util/proptypes/apiEntities/subscriptionPackage';
import TextNew, { FontWeight } from 'components/atoms/TextNew';
import RadioButton from '../../atoms/RadioButton';

import './subscription-package.scss';

const EMPTY_ARRAY = [];

const SubscriptionPackage = (
  { id, name, price, hideRadioButton, discount, gift, isFreePackage },
  context,
  className,
) => {
  const [discountPrice] =
    !isFreePackage && discount ? calculateDiscountedPackagePrice(discount, price) : EMPTY_ARRAY;

  return (
    <div className={className}>
      {!hideRadioButton && <RadioButton value={id} name={name} cid="subscription-package-radio" />}
      <div
        className={classNames('package-wrapper', {
          'has-discount': discount,
          'has-gift': gift,
          'is-bronze-package': name?.toLowerCase() === 'bronze',
          'is-silver-package': name?.toLowerCase() === 'silver',
          'is-gold-package': name?.toLowerCase() === 'gold',
        })}
      >
        <div className="package-information">
          {name && <TextNew.Sans.XS weight={FontWeight.BOLD}>{name}</TextNew.Sans.XS>}
          {price && !isFreePackage && (
            <TextNew.Sans.XS weight={FontWeight.BOLD}>
              {currencySymbolProvider(price.currencyCode)}
              {discount ? discountPrice : price.amount}
            </TextNew.Sans.XS>
          )}
        </div>
      </div>
    </div>
  );
};

SubscriptionPackage.propTypes = {
  ...subscriptionPackageShape,
  discount: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    isRelative: PropTypes.bool.isRequired,
    productIds: PropTypes.arrayOf(PropTypes.any),
  }),
  gift: PropTypes.shape({
    duration: PropTypes.string.isRequired,
  }),
  hideRadioButton: PropTypes.bool,
  isFreePackage: PropTypes.bool,
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'SubscriptionPackage',
)(SubscriptionPackage);
