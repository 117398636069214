import React from 'react';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import FormikError from '../../../../../../FormikError';

type HeightInputValidationProps = {
  heightUnit: number;
};

const HeightInputValidation = ({ heightUnit }: HeightInputValidationProps): JSX.Element => {
  if (heightUnit === MeasureUnit.IMPERIAL) {
    return (
      <>
        <FormikError name="feet" formik={undefined} errorName={undefined} params={undefined} />
        <FormikError
          name="fullinches"
          formik={undefined}
          errorName={undefined}
          params={undefined}
        />
      </>
    );
  }

  return (
    <>
      <FormikError name="metres" formik={undefined} errorName={undefined} params={undefined} />
      <FormikError name="centimetres" formik={undefined} errorName={undefined} params={undefined} />
    </>
  );
};

export default HeightInputValidation;
