import React from 'react';
import PropTypes from 'prop-types';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import VideoWithThumbnailPropTypes from 'common/src/app/util/proptypes/apiEntities/videoWithThumbnail';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { ElementTypes } from '../../../../../atoms/TextNew';
import RichTextBlock from '../RichTextBlock';
import Image from '../../../../../atoms/Image';
import Icon from '../../../../../atoms/Icon';
import Button from '../../../../../atoms/Button';
import './video-with-thumbnail-block.scss';

const VideoWithThumbnailBlock = (
  {
    video,
    openModal,
    ratio,
    title,
    videoCaption,
    brightcoveAccountId,
    brightcovePlayerId,
    trackVideoEvent,
  },
  context,
  className,
  dataTestid,
) => {
  const openVideoModal = () =>
    openModal(
      ModalNames.VIDEO,
      {
        video: video.video,
        brightcoveAccountId,
        brightcovePlayerId,
        trackVideoEvent,
      },
      false,
      false,
      true,
    );

  return (
    <div data-testid={dataTestid} className={className}>
      {title && (
        <TextNew.Confident cid="title" element={ElementTypes.H2} dataTestid="video-title">
          {title}
        </TextNew.Confident>
      )}
      <div className="video-container">
        <Button isTertiary onClick={openVideoModal}>
          <div className="screen-reader">Play video</div>
          <Icon name={IconName.PLAY} width={60} height={60} />
        </Button>
        {video?.videoThumbnail?.src ? (
          <Image src={video.videoThumbnail.src} alt={video.videoThumbnail.alt} ratio={ratio} />
        ) : null}
      </div>
      {videoCaption ? <RichTextBlock text={videoCaption} dataTestid="video-caption" /> : null}
    </div>
  );
};

VideoWithThumbnailBlock.defaultProps = {
  ratio: 16 / 9,
};

VideoWithThumbnailBlock.propTypes = {
  ...VideoWithThumbnailPropTypes,
  /**
   * Passed by the connect method
   */
  openModal: PropTypes.func,
  /**
   * Video caption
   */
  videoCaption: PropTypes.string,
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  /**
   * Action to expose video information to the data layer
   */
  trackVideoEvent: PropTypes.func,
};

export default withFunctionalClassName(
  ComponentType.ATOM,
  'VideoWithThumbnailBlock',
)(VideoWithThumbnailBlock);
