/* global 'template','CardCheck' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import MembershipCardInput from 'components/atoms/MembershipCardInput';
import EmailInput from 'components/atoms/EmailInput';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import ReCaptchaCheckbox from 'components/atoms/ReCaptchaCheckbox';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import {
  membershipCardFields,
  shopFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import GroupRegRegionPicker from 'components/molecules/GroupRegRegionPicker';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import './card-check.scss';

// Show the recaptcha component when the form has been submitted 10 or more times
const FORM_SUBMISSION_COUNT_TO_TRIGGER_RECAPTCHA_CHECK = 10;

const CardCheck = (
  {
    submitting,
    hasBlockingError,
    handleSubmit,
    submissionCount,
    isInvite,
    skipCardAndPinDuringRegistration,
    setFieldValue,
    recaptchaToken,
  },
  {},
  className,
) => (
  <div className={className}>
    <TextNew.Loud localeId="cardCheck.title" element={ElementTypes.h1} />
    <TextNew.PrimaryElegant localeId="cardCheck.intro" element={ElementTypes.P} />
    <TextNew.PrimaryElegant localeId="cardCheck.body" element={ElementTypes.P} />

    <form onSubmit={handleSubmit} className={className} autoComplete="off" noValidate>
      <EmailInput
        isInvite={isInvite}
        title="cardCheck.emailLabel"
        information={<LocaleMessage id="cardCheck.emailInformation" />}
      />

      <EnhancedField name={shopFields.REGION} component={GroupRegRegionPicker} />

      {!skipCardAndPinDuringRegistration && <MembershipCardInput />}

      {!skipCardAndPinDuringRegistration &&
        submissionCount >= FORM_SUBMISSION_COUNT_TO_TRIGGER_RECAPTCHA_CHECK && (
          <>
            <ReCaptchaCheckbox
              resetTokenSubmissionCount
              onChange={() => setFieldValue([membershipCardFields.RECAPTCHA], true)}
            />
            <FormErrorMessages fields={[membershipCardFields.RECAPTCHA]} />
          </>
        )}

      <Row justifycenter>
        <Button
          ripple
          type="submit"
          cid="next-step"
          disabled={
            submitting ||
            hasBlockingError ||
            (submissionCount >= FORM_SUBMISSION_COUNT_TO_TRIGGER_RECAPTCHA_CHECK && !recaptchaToken)
          }
          isLoading={submitting}
        >
          <LocaleMessage id="general.cta.next" />
        </Button>
      </Row>
      <FormErrorMessages showGeneral />
    </form>
  </div>
);

CardCheck.propTypes = {
  submitting: PropTypes.bool,
  hasBlockingError: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submissionCount: PropTypes.number,
  isInvite: PropTypes.bool,
  skipCardAndPinDuringRegistration: PropTypes.bool,
  setFieldValue: PropTypes.func,
  recaptchaToken: PropTypes.string,
};

export default withFunctionalClassName('template','CardCheck')(CardCheck);
