import React, { Component } from 'react';
import camelCase from 'lodash/camelCase';
import getDisplayName from 'recompose/getDisplayName';
import { getClient } from 'common/src/app/util/sentry/sentry-client';
import HandleErrorComponent from '../../molecules/HandleErrorComponent';
import LocaleMessage from '../../atoms/LocaleMessage';

/**
 * HoC Error Boundary component
 *
 * This component uses the basic react error Boundary,
 * wrapped into a higher order component.
 *
 * @param {bool} displayErrorComponent - true will display custom error component,
 * false returns normal component decorated with errorBoundary prop
 *
 * @param {bool} wholePageError - true will display error for the whole page
 *
 * @param {bool} isColumn - true will change the layout
 *
 * If using the custom error component, the title will be derived from:
 * "componentNameInCamelCase.errorBoundaryTitle" - please make sure this is available
 */

const errorBoundary =
  ({ displayErrorComponent = true, wholePageError = null, isColumn = false } = {}) =>
  WrappedComponent => {
    class HandleErrorBoundary extends Component {
      constructor(props) {
        super(props);
        this.state = { error: false };
      }

      static getDerivedStateFromError(error) {
        return { error };
      }

      // eslint-disable-next-line class-methods-use-this
      getComponentName = () => getDisplayName(WrappedComponent)?.replace(/Connect|Pure/gi, '');

      componentDidCatch(error) {
        this.setState(() => ({ error }));
        const sentryClient = getClient();
        if (sentryClient) {
          sentryClient.captureException(error, {
            extra: {
              component: this.getComponentName(),
              componentProps: this.props,
              source: 'Error captured in ErrorBoundary',
            },
          });
        }
      }

      render() {
        const theComponent = this.getComponentName();
        const errorBoundaryTitle = (
          <LocaleMessage id={`${camelCase(theComponent)}.errorBoundaryTitle`} />
        );

        const { error } = this.state;
        if (error && displayErrorComponent) {
          return (
            <HandleErrorComponent
              title={errorBoundaryTitle}
              componentName={theComponent}
              errorBoundary={error}
              wholePageError={wholePageError}
              isColumn={isColumn}
            />
          );
        }
        return <WrappedComponent errorBoundary={error} {...this.props} />;
      }
    }

    HandleErrorBoundary.displayName = `handleErrorBoundary(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    return HandleErrorBoundary;
  };

export default errorBoundary;
