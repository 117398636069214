import { defaultCountry } from 'common/src/app/config/market/market.configdefinitions';
import { addressFields } from '../data/enum/FieldNames/AccountFieldNames';

/**
 * Returns users country code or the default country code
 * @param {object} current form values
 * @param {object} addressType form prefix
 */
const getCountry = (values, addressType) => {
  const addressTypePrefix = values[addressType];
  const value = addressTypePrefix?.[addressFields.COUNTRY]
    ? addressTypePrefix[addressFields.COUNTRY]
    : values[addressFields.COUNTRY];

  return value || defaultCountry;
};

export default getCountry;
