/* global 'organism' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContactUsFieldNames from 'common/src/app/data/enum/FieldNames/ContactUsFieldNames';
import {
  ContactFormSubjectIds,
  getSubjectOptions,
} from 'common/src/app/data/enum/ContactFormCategory';
import InputType from 'common/src/app/data/enum/InputType';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import Textarea from '../../atoms/Textarea';
import Button from '../../atoms/Button';
import Select from '../../atoms/Select';
import FormikError from '../../atoms/FormikError';
import TextNew, { Colors, ElementTypes, FontWeight } from '../../atoms/TextNew';
import ContactUsSuccessMessage from '../../molecules/ContactUsSuccessMessage';

import ContactUsMemberForm from './components/molecules/ContactUsMemberForm';

import './contact-us-form.scss';

class ContactUsForm extends Component {
  componentDidMount() {
    window.addEventListener('load', this.onLoad);
  }

  onLoad = () => {
    const { scrollToElement } = this.props;
    if (this.contactForm && scrollToElement) {
      scrollToElement(this.contactForm, 0, false);
    }
  };

  componentWillUnmount() {
    const { setSucessMessage } = this.props;
    setSucessMessage && setSucessMessage(null);
  }

  render() {
    const { getMessage } = this.context;
    const {
      errors,
      successMessageSubjectId,
      values,
      userType,
      handleSubmit,
      handleChange,
      handleBlur,
      children,
      loading,
      hasErrorBoundary,
    } = this.props;

    const { contactFormCategory } = values;

    const dataTestid = componentClassNameProp('organism', this)['data-testid'];

    const selectOptions = [
      { title: getMessage('contactUsForm.placeholder.subject'), value: '' },
      ...getSubjectOptions({ userType, getMessage }),
    ];

    let localeMessageType = '';
    if (
      contactFormCategory === ContactFormSubjectIds.ONLINE_TECHNICAL_HELP ||
      contactFormCategory === ContactFormSubjectIds.GROUP_TECHNICAL_HELP
    ) {
      localeMessageType = 'TechnicalHelp';
    }

    return (
      <div {...componentClassNameProp('organism', this)} ref={el => (this.contactForm = el)}>
        {successMessageSubjectId ? (
          <header>
            <ContactUsSuccessMessage subjectId={successMessageSubjectId} />
          </header>
        ) : (
          <>
            <TextNew.Sans.SM
              weight={FontWeight.LIGHT}
              localeId="contactUsForm.body"
              localeParams={{
                PREFER: (
                  <TextNew.Sans.SM
                    key="contact-us-prefer-email"
                    weight={FontWeight.NORMAL}
                    localeId="contactUsForm.prefer"
                    element={ElementTypes.SPAN}
                  />
                ),
              }}
            />
            <form onSubmit={handleSubmit}>
              <Select
                customLabel={getMessage('contactUsForm.placeholder.subject')}
                defaultValue={contactFormCategory}
                dataTestid={`${dataTestid}-select`}
                onChange={handleChange}
                name={ContactUsFieldNames.CONTACT_FORM_CATEGORY}
                options={selectOptions}
                disabled={hasErrorBoundary}
              />

              {contactFormCategory !== '' && (
                <>
                  {children}
                  <Textarea
                    data-testid={`${dataTestid}-message`}
                    rows="4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={ContactUsFieldNames.MESSAGE}
                    type={InputType.TEXT}
                    placeholder={getMessage(`contactUsForm.contactMessage${localeMessageType}`)}
                  />
                  <FormikError
                    dataTestid={`${dataTestid}-error`}
                    name={ContactUsFieldNames.MESSAGE}
                  />
                  <Button
                    type={InputType.SUBMIT}
                    disabled={loading}
                    dataTestid={`${dataTestid}-button`}
                  >
                    <TextNew.Sans.SM
                      element={ElementTypes.SPAN}
                      color={Colors.PRIMARY_LIGHT}
                      localeId="contactUsForm.cta"
                    />
                  </Button>
                </>
              )}
            </form>
          </>
        )}

        {errors?.generalError && (
          <TextNew.Error localeId={errors.generalError} cid="error" color={Colors.BRAND} />
        )}
      </div>
    );
  }
}

ContactUsForm.propTypes = {
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  userType: PropTypes.string,
  successMessageSubjectId: PropTypes.string,
  setSucessMessage: PropTypes.func,
  loading: PropTypes.bool,
  hasErrorBoundary: PropTypes.bool,
  scrollToElement: PropTypes.func,
};

ContactUsForm.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export { ContactUsMemberForm };
export default ContactUsForm;
