import { createSelector } from 'reselect';
import createAction from 'redux-actions/lib/createAction';
import Pages from 'common/src/app/data/enum/Pages';
import { RESET_AUTH_QUERY } from 'common/src/server/util/AuthenticationHelper/constants';
import { apiPost } from './apiActions/apiRequest';

import { GATEWAY_PAYMENT_AUTH } from '../../data/Injectables';

export const REMOVE_PURCHASE_PAYMENT = 'paymentActions/REMOVE_PURCHASE_PAYMENT';

export const removePayment = createAction(REMOVE_PURCHASE_PAYMENT);

/**
 * After the payment is created we store the details in session storage to allow
 * recovery on hard refresh within the session this stores a number of thing such as voucherCode and checkout details (shop)
 * @param {string} purchaseId
 * @param {object} values
 */
export const savePurchaseInfo = (purchaseId, values) => {
  if (typeof sessionStorage !== 'undefined') {
    // Clean out old purchaseId if new one is created
    if (sessionStorage.getItem('purchaseId')) {
      sessionStorage.removeItem('purchaseId');
    }

    const purchaseInfo = {
      purchaseId,
      ...values,
    };

    sessionStorage.setItem('purchaseId', JSON.stringify(purchaseInfo));
  }
};

export const purchaseInfoSelector = createSelector(
  state => (state.payment && state.payment.purchaseId) || null,
  purchaseId =>
    purchaseId && typeof sessionStorage !== 'undefined'
      ? JSON.parse(sessionStorage.getItem(purchaseId))
      : {},
);

/**
 * Clean out anything within the purchaseId sessionStorage. Called on payment success or re-creation of the purchaseId
 */
export const deletePurchaseInfo = purchaseId => sessionStorage.removeItem(purchaseId);

export const ADYEN_CLIENT_SESSION = 'paymentActions/ADYEN_CLIENT_SESSION';

/**
 * Creates an Adyen client session based on a purchaseId. This also returns the full config which is passed
 * into Adyen's Prebuilt checkout component. See
 * https://docs.adyen.com/online-payments/web-drop-in#create-payment-session for
 * details on how this is created.
 * @param {string} purchaseId -
 * @param {string} returnURL - returnURL used on the event a user has to complete 3d auth
 * @param {string} recaptchaToken
 * @returns {Function} dispatch function
 */
export const adyenClientSession = (purchaseId, returnURL, recaptchaToken) => dispatch =>
  dispatch(
    apiPost(
      ADYEN_CLIENT_SESSION,
      GATEWAY_PAYMENT_AUTH,
      `/purchases/${purchaseId}/adyen-client-session`,
      {
        recaptchaToken,
        returnURL,
      },
    ),
  );

export const ADYEN_CLIENT_RESPONSE = 'paymentActions/ADYEN_CLIENT_RESPONSE';
/**
 * Endpoint used to grant users who have had a Authorised or Received response
 * back from payment temporary access
 */
/**
 * Calls the backend when the frontend gets on of the following result code:
 * - Authorised
 * - Received
 *   It is used to cover cases where Adyens callback to the backend is
 *   delayed and allow us to grant the user temporary access in this use case
 * @param {string} merchantReference - included within the config returned from
 * adyenClientSession
 * @param {string} resultCode
 * @returns {any}
 */
export const adyenClientResponse = (merchantReference, resultCode) => dispatch => {
  // backend calls the Received status Pending so this needs to be converted over

  dispatch(
    apiPost(ADYEN_CLIENT_RESPONSE, GATEWAY_PAYMENT_AUTH, `/purchases/client-response`, {
      resultCode,
      merchantReference,
    }),
  );
};

/**
 * Endpoint used to disable a certain card in adyen to no longer appear in
 * checkout or be used for subscription payment
 * @param {string} recurringDetailReference - return within the ADYEN_GET_CARDS
 */
export const ADYEN_DISABLE_CARD = 'paymentActions/ADYEN_DISABLE_CARD';
export const adyenDisableCard = recurringDetailReference => dispatch => {
  dispatch(
    apiPost(ADYEN_CLIENT_RESPONSE, GATEWAY_PAYMENT_AUTH, `/purchases/adyen-disable-card`, {
      recurringDetailReference,
      contract: 'oneClick',
    }),
  );
};

/**
 * Endpoint used to update the card which the monthly autonew is paid from.
 * @param {string} recurringDetailReference - return within the ADYEN_GET_CARDS
 */
export const ADYEN_CHANGE_DEFAULT_CARD = 'paymentActions/ADYEN_CHANGE_DEFAULT_CARD';
export const adyenChangeDefaultCard = recurringDetailReference => dispatch =>
  dispatch(
    apiPost(
      ADYEN_CLIENT_RESPONSE,
      GATEWAY_PAYMENT_AUTH,
      `/purchases/use-card-for-subscription-renewal`,
      {
        recurringDetailReference,
      },
    ),
  );

/**
 * Endpoint used to raise a zero payment to then mount adyens prebuilt
 * components and allow a member to update card details from the account
 * settings area
 */
export const ADYEN_CLIENT_CARD_UPDATE_SESSION = 'paymentActions/ADYEN_CLIENT_CARD_UPDATE_SESSION';
export const adyenClientCardUpdateSession = returnUrl => dispatch =>
  dispatch(
    apiPost(
      ADYEN_CLIENT_CARD_UPDATE_SESSION,
      GATEWAY_PAYMENT_AUTH,
      `/purchases/adyen-client-for-card-update-session?returnUrl=${returnUrl}`,
    ),
  );

/**
 * Endpoint used to get all of a user saved cars to allow them edit within the
 * Orders & Payments screens
 */
export const ADYEN_GET_CARDS = 'paymentActions/ADYEN_GET_CARDS';
export const adyenGetCards = () => dispatch =>
  dispatch(
    apiPost(ADYEN_GET_CARDS, GATEWAY_PAYMENT_AUTH, `/purchases/adyen-get-cards`, {
      contract: 'oneclick',
    }),
  );

/**
 * Redirect a member to the payment form on submit
 */
export const startPayment = () => {
  window.location.href = `${Pages.REGISTRATION_PAYMENT}?${RESET_AUTH_QUERY}`;
  /**
   * After redirecting when the page is still visible for a while, the page needs to keep its
   * current (submitting) state. When returning a Promise that does not resolve, it will cause
   * the form that has this action onSubmit to get { submitting: true }.
   */
  return new Promise(() => {});
};
