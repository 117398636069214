import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import {
  changePassword,
  getMemberPasswordValidationConfig,
} from 'common/src/app/actions/resources/accountActions';
import { userIdentitySelector } from 'common/src/app/selectors/userAccountSelectors';
import FormNames from 'common/src/app/data/enum/FormNames';
import { passwordFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import { newPasswordAndConfirm } from 'common/src/app/validation/accountValidation';
import {
  PartialState,
  passwordValidationConfigSelector,
} from 'common/src/app/selectors/accountSelector';
import ChangePassword from './ChangePassword';
import { Config } from '../../../../../../../../../types/config';

type ReduxState = {
  enhancedForm: {
    validation: unknown;
  };
  config: Config;
  identity: {
    user: {
      name: string;
    };
  };
} & PartialState;

const ChangePasswordValidation = {
  ...createSimpleRequiredValidation(passwordFields.OLD_PASSWORD, null, FormNames.CHANGE_PASSWORD),
  ...createSimpleRequiredValidation(passwordFields.NEW_PASSWORD, null, FormNames.CHANGE_PASSWORD),
  ...newPasswordAndConfirm,
};

const enhanced = enhancedReduxForm(
  {
    form: FormNames.CHANGE_PASSWORD,
    onSubmit: (
      values: { oldPassword: string; newPassword: string },
      dispatch: (func: () => void) => void,
    ) => dispatch(changePassword(values.oldPassword, values.newPassword)),
    generalErrorMessage: { locale: 'changePassword.generalErrorMessage' },
  } as any,
  ChangePasswordValidation,
);

const mapStateToProps = (
  state: ReduxState,
  ownProps: { location: { query: { from: string } } },
) => {
  const { name: currentUser = '' } = userIdentitySelector(state);
  const getErrorsForFields: (state: any, props: any) => any = makeGetErrorsForFields();
  const passwordError = getErrorsForFields(state.enhancedForm.validation, {
    form: FormNames.CHANGE_PASSWORD,
    fields: [passwordFields.NEW_PASSWORD],
  });

  const passwordValidationConfig = passwordValidationConfigSelector(state);

  return {
    currentUser,
    fromUrl: ownProps.location.query.from || state.config.environmentConfig.web.member.host,
    disallowListPasswordError: passwordError.find(
      // Only return appropriate errors served by the API and avoid FE errors (no error codes)
      (error: { code: string }) =>
        error.code && (error.code === 'password.isblacklisted' || 'password.notchanged'),
    ),
    passwordValidationConfig,
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: (_: any, dispatch: any) => dispatch(getMemberPasswordValidationConfig()),
});

export default compose(addInitAction, connected, enhanced)(ChangePassword);
