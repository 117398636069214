/* global 'molecule','ExistingLegacyAccountPromptModal' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { membershipCardFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import FormNames from 'common/src/app/data/enum/FormNames';
import Configuration from 'common/src/app/config/Configuration';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import CheckBox from 'components/molecules/CheckBox';

import './existing-legacy-account-prompt-modal.scss';

const ExistingLegacyAccountPromptModal = ({ submitting = false, handleSubmit }, {}, className) => (
  <form onSubmit={handleSubmit} name={FormNames.START_AFRESH} className={className}>
    <TextNew.Confident
      element={ElementTypes.H1}
      color={Colors.DARK_TONE}
      localeId="existingLegacyAccountPromptModal.legacy.title"
    />
    <TextNew.PrimaryElegant
      cid="additionalText"
      localeId="existingLegacyAccountPromptModal.legacy.intro"
    />
    <TextNew.PrimaryElegant
      cid="additionalText"
      localeId="existingLegacyAccountPromptModal.legacy.description"
    />

    <Button href={Configuration.legacyLoginUrl}>
      <LocaleMessage
        id="existingLegacyAccountPromptModal.legacy.button"
        params={{
          GROUP_SERVICE: Configuration.groupServiceName,
        }}
      />
    </Button>

    <TextNew.Confident
      element={ElementTypes.H2}
      color={Colors.DARK_TONE}
      localeId="existingLegacyAccountPromptModal.fresh.title"
    />

    <TextNew.PrimaryElegant
      cid="additionalText"
      localeId="existingLegacyAccountPromptModal.fresh.intro"
    />

    <EnhancedField name={membershipCardFields.CONFIRM_START_AFRESH} component={CheckBox}>
      <LocaleMessage id="existingLegacyAccountPromptModal.fresh.checkBox" />
    </EnhancedField>

    <Button type="submit" disabled={submitting} isGhostDark>
      <LocaleMessage id="existingLegacyAccountPromptModal.fresh.button" />
    </Button>

    <FormErrorMessages fields={[membershipCardFields.CONFIRM_START_AFRESH]} />
  </form>
);

ExistingLegacyAccountPromptModal.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

ExistingLegacyAccountPromptModal.defaultProps = {
  [membershipCardFields.CONFIRM_START_AFRESH]: false,
};

export default withFunctionalClassName('molecule','ExistingLegacyAccountPromptModal')(ExistingLegacyAccountPromptModal);
