import Pages from './Pages';

const ProductType = {
  BOOK: 0,
  HIFI: 1,
  MAGAZINE: 2,
  SUBSCRIPTION: 3,
};

export const ProductCategoryUrl = {
  [Pages.SHOP_LANDING_MEMBERSHIP]: ProductType.SUBSCRIPTION,
  [Pages.SHOP_LANDING_FOOD]: ProductType.HIFI,
  [Pages.SHOP_LANDING_PRINT]: [ProductType.BOOK, ProductType.MAGAZINE],
};

export const convertProductCategoryUrl = ({ location }) =>
  /**
   * Null returns all products this is required for print as the
   * api only allows filtering by one type, print is both magazine & books
   * TODO [PUB-633] Change when api allows filtering by multiple categories
   */
  location === Pages.SHOP_LANDING_PRINT ? null : ProductCategoryUrl[location];

export default ProductType;
