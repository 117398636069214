import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { validateForm } from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import Configuration from 'common/src/app/config/Configuration';
import FormNames from 'common/src/app/data/enum/FormNames';
import {
  addressFields,
  userDetailFields,
  shopFields,
  termsFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import { phoneNumberValidation } from 'common/src/app/validation/accountValidation';
import address from 'common/src/app/validation/addressValidation';
import {
  makeSelectedPackageSelector,
  voucherSelector,
  makePackagesWithDiscountSelector,
} from 'common/src/app/selectors/packageVoucherSelectors';
import matchRoute from 'common/src/app/util/routeCheckUtils';
import Pages from 'common/src/app/data/enum/Pages';
import { isInviteSelector } from 'common/src/app/selectors/registrationSelector';

import Summary from './Summary';

const EMPTY_OBJECT = {};

const addressLookupFormName = addressFields.BILLING_ADDRESS;

const summaryValidation = {
  ...address(addressFields.BILLING_ADDRESS),
  ...phoneNumberValidation({
    addressType: addressFields.BILLING_ADDRESS,
    isRequired: true,
    transfers: true,
  }),
  ...createSimpleRequiredValidation(termsFields.TERMS_OF_USE),
  ...createSimpleRequiredValidation(termsFields.USER_SUBSCRIPTION),
};

const blockingAddressFields = [
  addressFields.ADDRESS_LOOKUP,
  addressFields.COUNTRY,
  addressFields.ADDRESS_LINE_1,
  addressFields.CITY_OR_TOWN,
  addressFields.COUNTY,
  addressFields.ZIP_OR_POSTAL,
];

const errorScrollOrder = [
  `${addressLookupFormName}.${addressFields.ADDRESS_LOOKUP}`,
  `${addressLookupFormName}.${addressFields.COUNTRY}`,
  `${addressLookupFormName}.${addressFields.ADDRESS_LINE_1}`,
  `${addressLookupFormName}.${addressFields.CITY_OR_TOWN}`,
  `${addressLookupFormName}.${addressFields.COUNTY}`,
  `${addressLookupFormName}.${addressFields.ZIP_OR_POSTAL}`,
  userDetailFields.PHONE_NUMBER,
  termsFields.TERMS_OF_USE,
];

const enhancer = enhancedReduxForm(
  {
    form: FormNames.SUMMARY,
    destroyOnUnmount: false,
    enableReinitialize: true,
  },
  summaryValidation,
);

const mapStateToProps = initialState => {
  const summaryFormSelector = formValueSelector(FormNames.SUMMARY);

  const selectedPackageSelector = makeSelectedPackageSelector(FormNames.SUMMARY);

  const onRegistrationRoute = matchRoute(Pages.REGISTRATION_SUMMARY, initialState);

  const initialValues = {
    ...initialState?.form[FormNames.CHECKOUT]?.values,
    ...initialState?.form[FormNames.SUMMARY]?.values,
  };

  return state => {
    const regionCode = Configuration.hasRegionPicker
      ? summaryFormSelector(state, shopFields.REGION)
      : Configuration.defaultRegion;

    const voucherCode = summaryFormSelector(state, shopFields.VOUCHER_CODE);
    const voucher = voucherSelector(state, voucherCode);

    const selectedPackage = selectedPackageSelector(state, regionCode) || EMPTY_OBJECT;

    // This is just for online registration
    const packagesWithDiscountSelector = makePackagesWithDiscountSelector();

    let packages = packagesWithDiscountSelector(state, regionCode);

    // Filter the packages - for online registration
    // - so that we only have bronze, silver and gold
    if (onRegistrationRoute) {
      packages = packages?.filter(
        item =>
          item?.name?.toLowerCase() === 'bronze' ||
          item?.name?.toLowerCase() === 'silver' ||
          item?.name?.toLowerCase() === 'gold',
      );
    }
    return {
      selectedPackage,
      voucherCode,
      voucherGift: voucher?.gift,
      wizardSubmitting: !!state?.enhancedForm?.wizard?.[FormNames.ONLINE_REGISTRATION]?.submitting,
      initialValues,
      onRegistrationRoute,
      packages,
      isInvite: isInviteSelector(state),
      addressLookupFormName,
      blockingAddressFields,
      errorScrollOrder,
    };
  };
};

const mapDispatchToProps = dispatch => ({
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
  validateStep: () => dispatch(validateForm(summaryValidation, FormNames.SUMMARY)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose(connector, enhancer)(Summary);
