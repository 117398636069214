import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import { DEFAULT_MAX_AGE } from 'common/src/app/data/cachingDefaults';
import Configuration from 'common/src/app/config/Configuration';
import AccountLogin from 'bundle-loader?lazy&reactHot&name=AccountLogin!./index';
import Login from 'bundle-loader?lazy&reactHot&name=Login!./components/templates/Login';
import AlreadyLoggedIn from 'bundle-loader?lazy&reactHot&name=AlreadyLoggedIn!./components/templates/AlreadyLoggedIn';
import Logout from 'bundle-loader?lazy&reactHot&name=Logout!./components/templates/Logout';
import LoggedOut from 'bundle-loader?lazy&reactHot&name=LoggedOut!./components/templates/LoggedOut';
import ResetPassword from 'bundle-loader?lazy&reactHot&name=ResetPassword!./components/templates/ResetPassword';
import ChangePassword from 'bundle-loader?lazy&reactHot&name=ChangePassword!./components/templates/ChangePassword';
import ForgotPassword from 'bundle-loader?lazy&reactHot&name=ForgotPassword!./components/templates/ForgotPassword';
import ChangeEmail from 'bundle-loader?lazy&reactHot&name=ChangeEmail!./components/templates/ChangeEmail';
import ConfirmEmailChange from 'bundle-loader?lazy&reactHot&name=ConfirmEmailChange!./components/templates/ConfirmEmailChange';
import ConfirmedEmail from 'bundle-loader?lazy&reactHot&name=ConfirmedEmail!./components/templates/ConfirmedEmail';
import LegacyLogin from 'bundle-loader?lazy&reactHot&name=ChangeEmail!./components/templates/LegacyLogin';
import DualAccess from 'bundle-loader?lazy&reactHot&name=ChangeEmail!./components/templates/DualAccess';
import MultiFactorAuthenticationRegister from 'bundle-loader?lazy&reactHot&name=MultiFactorAuthenticationRegister!./components/templates/MultiFactorAuthenticationRegister';
import MultiFactorAuthenticationVerify from 'bundle-loader?lazy&reactHot&name=MultiFactorAuthenticationRegister!./components/templates/MultiFactorAuthenticationVerify';
import {
  hasIdentity,
  isLoggedIn,
  hasTwoFactorIdentity,
  hasIdentityNotMfaRegistered,
} from '../../../config/accountRouteRequirements';

const routes = (
  <Route {...getRouteComponentProp(AccountLogin)}>
    <IndexRoute {...getRouteComponentProp(Login)} title="Login | {pageTitle}" />

    <Route
      path={Pages.LOGIN}
      requirements={[isLoggedIn]}
      {...getRouteComponentProp(Login)}
      title="Login | {pageTitle}"
      maxAge={DEFAULT_MAX_AGE}
    />
    <Route
      path={Pages.ALREADY_LOGGED_IN}
      title="Logged in | {pageTitle}"
      {...getRouteComponentProp(AlreadyLoggedIn)}
    />
    <Route
      requirements={[hasIdentity]}
      path={Pages.LOGOUT}
      title="Logout | {pageTitle}"
      {...getRouteComponentProp(Logout)}
    />
    <Route
      path={Pages.LOGGED_OUT}
      title="Logged Out | {pageTitle}"
      {...getRouteComponentProp(LoggedOut)}
    />
    <Route
      path={Pages.RESET_PASSWORD}
      {...getRouteComponentProp(ResetPassword)}
      title="Reset Password | {pageTitle}"
    />
    <Route
      path={Pages.FORGOT_PASSWORD}
      {...getRouteComponentProp(ForgotPassword)}
      title="Forgot Password | {pageTitle}"
    />
    <Route
      requirements={[hasIdentity]}
      path={Pages.CHANGE_PASSWORD}
      title="Change Password | {pageTitle}"
      {...getRouteComponentProp(ChangePassword)}
    />
    <Route
      requirements={[hasIdentity]}
      path={Pages.CHANGE_EMAIL}
      title="Change Email | {pageTitle}"
      {...getRouteComponentProp(ChangeEmail)}
    />
    <Route
      path={Pages.CONFIRM_EMAIL_CHANGE}
      {...getRouteComponentProp(ConfirmEmailChange)}
      title="Confirm Email Change | {pageTitle}"
    />
    <Route
      path={Pages.CONFIRMED_EMAIL}
      {...getRouteComponentProp(ConfirmedEmail)}
      title="Email Confirmed | {pageTitle}"
    />
    <Route
      path={Pages.DUAL_ACCESS}
      {...getRouteComponentProp(DualAccess)}
      title="Dual Access | {pageTitle}"
    />
    {Configuration.legacyLoginUrl !== '' && (
      <Route
        path={Pages.LEGACY_LOGIN}
        {...getRouteComponentProp(LegacyLogin)}
        title="Legacy login | {pageTitle}"
      />
    )}
    <Route
      requirements={[hasIdentityNotMfaRegistered]}
      path={Pages.MFA_REGISTER}
      {...getRouteComponentProp(MultiFactorAuthenticationRegister)}
      title="Multi Factor Authentication Register | {pageTitle}"
    />
    <Route
      requirements={[hasTwoFactorIdentity]}
      path={Pages.MFA_VERIFY}
      {...getRouteComponentProp(MultiFactorAuthenticationVerify)}
      title="Multi Factor Authentication Verify | {pageTitle}"
    />
  </Route>
);

export default routes;
