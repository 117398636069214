import PropTypes from 'prop-types';
import priceShape from './price';

/**
 * Reusable object to use in `propTypes` for a `subscriptionPackageShape` prop.
 * @type {Object}
 * @category templating
 */
const subscriptionPackageShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  duration: PropTypes.string,
  price: PropTypes.shape(priceShape),
  createdDateUTC: PropTypes.string,
  startDateUTC: PropTypes.string,
};

export default subscriptionPackageShape;
