const decomposeCheckboxesToCommaDelimitedString = (values, currentValues = {}) => {
  const newValues = {};
  // clear old values
  Object.keys(currentValues).forEach(key => (newValues[key] = values?.includes(key) ? true : null));
  // set new values
  // eslint-disable-next-line no-unused-expressions
  values?.split(',').forEach(key => {
    newValues[key] = true;
  });

  return newValues;
};

export default decomposeCheckboxesToCommaDelimitedString;
