import { connect } from 'react-redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { passwordFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import { changeEmail } from 'common/src/app/actions/resources/accountActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import { newEmail } from 'common/src/app/validation/accountValidation';
import ChangeEmail from './ChangeEmail';

const ChangeEmailValidation = {
  ...createSimpleRequiredValidation(passwordFields.PASSWORD, null, FormNames.CHANGE_EMAIL),
  ...newEmail,
};

const enhancedChangeEmail = enhancedReduxForm(
  {
    form: FormNames.CHANGE_EMAIL,
    onSubmit: (values, dispatch) => dispatch(changeEmail(values.password, values.newEmail)),
    generalErrorMessage: { locale: 'changeEmail.generalErrorMessage' },
  },
  ChangeEmailValidation,
)(ChangeEmail);

const mapStateToProps = (state, ownProps) => ({
  fromUrl: ownProps.location.query.from || state.config.environmentConfig.web.member.host,
});

const connectedChangeEmail = connect(mapStateToProps)(enhancedChangeEmail);

export default connectedChangeEmail;
