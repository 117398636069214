import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import componentType from 'common/src/app/data/enum/ComponentType';

import './riddle-block.scss';

/**
 * Renders the Riddle Component
 *
 * This component initializes the riddle manually because the
 * riddle embed script only initializes riddle on the DOMContentLoaded event.
 *
 * See code in: https://www.riddle.com/embed/build-embedjs/embedV2.js
 */

const RiddleBlock = ({ riddleId, autoScroll }) => {
  const riddleTarget = useRef();
  const [hasLoaded, setHasLoaded] = useState(false);
  const isRiddleV1 = () => /^\d+$/.test(riddleId);

  useEffect(() => {
    /**
     * We need to add the riddle script within this component rather than the configartion scripts section
     * which places it in the html and page renderer. In testing this caused the page to become unresponsive,
     * hence we moved to this component.
     */
    const script = document.createElement('script');
    script.src = 'https://www.riddle.com/embed/build-embedjs/embedV2.js';
    script.async = true;
    script.onload = () => setHasLoaded(true);
    document.body.appendChild(script);

    return function resetRiddle() {
      riddleTarget.current = null;

      if (window.riddleAPI) {
        window.riddleAPI.riddles = [];
      }
      if (window.riddle2API) {
        window.riddle2API.riddles = [];
      }
    };
  }, []);

  useEffect(() => {
    if (riddleTarget.current) {
      /**
       * We need to initialize a riddle manually because the riddle script
       * that is added in the PageRenderer is only initializing new riddle
       * quizzes on the DOMContentLoaded event. We are checking to see if this
       * a v1 riddle or v2 to initilze if correctly
       */

      if (isRiddleV1() && window.riddleAPI) {
        window.riddleAPI.init(riddleTarget.current);
      } else if (window.riddle2API) {
        window.riddle2API.initRiddle(riddleTarget.current);
      }
    }
  }, [hasLoaded]);

  return (
    <section className={functionalComponentClassName(componentType.ATOM, 'riddle-block')}>
      <div
        data-rid-id={riddleId}
        data-auto-scroll={autoScroll}
        data-auto-scroll-offset={72}
        // Save reference to be able to initialize the riddle quiz
        ref={riddleTarget}
      >
        <iframe
          title="Quiz"
          className="riddle-frame"
          src={`https://www.riddle.com/embed/a/${riddleId}`}
        />
      </div>
    </section>
  );
};

RiddleBlock.propTypes = {
  riddleId: PropTypes.string.isRequired,
  autoScroll: PropTypes.bool,
};

RiddleBlock.defaultProps = {
  autoScroll: true,
};

export default RiddleBlock;
