import React from 'react';
import PropTypes from 'prop-types';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import * as gender from 'common/src/app/data/enum/Gender';
import FemaleOnlyQuestions from '../../../../molecules/FemaleOnlyQuestions';

const ToggleFemaleOnlyQuestions = ({
  isGroupRegister,
  formValues: { [userDetailFields.GENDER]: genderValue },
}) => genderValue === gender.FEMALE && <FemaleOnlyQuestions isGroupRegister={isGroupRegister} />;

ToggleFemaleOnlyQuestions.propTypes = {
  formValues: PropTypes.shape({
    [userDetailFields.GENDER]: PropTypes.number,
  }),
  isGroupRegister: PropTypes.bool.isRequired,
};

export default ToggleFemaleOnlyQuestions;
