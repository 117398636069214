import { arrayPush, change } from 'redux-form';
import createAction from 'redux-actions/lib/createAction';
import { basketProductsSelector } from '../../selectors/basketProductsSelector';
import BasketFields from '../../data/enum/FieldNames/BasketFields';
import FormNames from '../../data/enum/FormNames';
import basketItemCountSelector from '../../selectors/basketItemCountSelector';

export const REMOVE_BASKET_ITEM = 'basketActions/REMOVE_BASKET_ITEM';
export const ADD_BASKET_ITEM = 'basketActions/ADD_BASKET_ITEM';
export const INCREMENT_QUANTITY = 'basketActions/INCREMENT_QUANTITY';
export const DECREMENT_QUANTITY = 'basketActions/DECREMENT_QUANTITY';

// eslint-disable-next-line import/prefer-default-export
export const addBasketItem =
  (id, quantity = 1, category) =>
  (dispatch, getState) => {
    const items = basketProductsSelector(getState());
    const existingItemIndex = items.findIndex(item => item.id === id);

    if (existingItemIndex === -1) {
      dispatch(arrayPush(FormNames.SHOP_BASKET, BasketFields.ITEMS, { id, quantity, category }));
    } else {
      dispatch(
        change(
          FormNames.SHOP_BASKET,
          `items[${existingItemIndex}].quantity`,
          items[existingItemIndex].quantity + quantity,
        ),
      );
    }
  };

export const TOGGLE_BASKET_ACTIVE = 'shopActions/TOGGLE_BASKET_ACTIVE';
export const toggleBasketActive = createAction(TOGGLE_BASKET_ACTIVE, () => null);

export const SET_BASKET_ACTIVE = 'shopActions/SET_BASKET_ACTIVE';
export const setBasketActive = createAction(SET_BASKET_ACTIVE, (active = true) => !!active);

export const HIFI_MODAL_TRIGGERED = 'shopActions/HIFI_MODAL_TRIGGERED';
export const setHifiModalTriggered = createAction(HIFI_MODAL_TRIGGERED);

export const activateBasketIfEmpty = () => (dispatch, getState) => {
  const state = getState();
  const totalBasketQuantity = basketItemCountSelector(state);
  if (totalBasketQuantity.itemCount < 1) {
    return dispatch(setBasketActive());
  }
  return null;
};

export const UPDATE_REMAINING_ITEMS = 'shopActions/UPDATE_REMAINING_ITEMS';
export const setRestrictedItems = createAction(UPDATE_REMAINING_ITEMS);

export const updateRemainingItems =
  (remainingItems, quantity = 0) =>
  dispatch =>
    dispatch(setRestrictedItems(remainingItems - quantity));
