import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { openModal } from 'common/src/app/actions/components/modalActions';
import { userAccountSelector } from 'common/src/app/selectors/userAccountSelectors';
import { currentWeightSelector } from 'common/src/app/selectors/userProfileSelectors';
import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import SynsAllowanceBlock from './SynsAllowanceBlock';

const connected = connect(
  state => {
    const accountData = userAccountSelector(state);
    const firstName = accountData && accountData.firstName;
    const gender = accountData && accountData.gender;
    const currentWeight = currentWeightSelector(state);
    const weightUnit = (accountData && accountData.weightUnit) || MeasureUnit.IMPERIAL;

    return {
      firstName,
      currentWeight,
      gender,
      weightUnit,
    };
  },
  {
    openModal,
  },
);

export default compose(connected)(SynsAllowanceBlock);
