import handleActions from 'redux-actions/lib/handleActions';
import { LOGOUT } from '../actions/resources/accountActions';

/**
 * NOTE: We do not import this constant from logoutResponseActions because
 * we are not allowed to import this action file in the web build.
 */
const SET_LOGOUT_RESPONSE = 'logoutResponseActions/SET_LOGOUT_RESPONSE';

/**
 * This reducer contains the logout response as parsed from the location query,
 * if it is present. Otherwise, this reducer will be empty.
 *
 * @function logoutReducer
 * @private
 */
const logoutReducer = handleActions(
  {
    [SET_LOGOUT_RESPONSE]: (
      state,
      {
        payload: {
          Data: { PostLogoutRedirectUri },
        },
      },
    ) => ({
      ...state,
      postLogoutRedirectUri: PostLogoutRedirectUri,
    }),
    [LOGOUT]: (state, { payload }) => ({
      ...payload,
    }),
  },
  {},
);

export default logoutReducer;
