import GatewayError, { ParsedResponseType } from '../../../../net/gateway/GatewayError';

const buildMessageFromParsedResponse = (
  parsedResponse?: ParsedResponseType,
): string | undefined => {
  if (!parsedResponse) return undefined;

  if (parsedResponse.message) return parsedResponse.message;

  if (typeof parsedResponse.error === 'string') return parsedResponse.error;

  const parsedResponseErrorObject = parsedResponse.error;
  if (!!parsedResponseErrorObject?.code || !!parsedResponseErrorObject?.message) {
    const messageParts = [];

    parsedResponseErrorObject?.code && messageParts.push(parsedResponseErrorObject.code);
    parsedResponseErrorObject?.message && messageParts.push(parsedResponseErrorObject.message);
    (parsedResponseErrorObject?.fields?.length ?? 0) > 0 &&
      messageParts.push(
        `Fields: ${parsedResponseErrorObject?.fields
          ?.map(fieldItem => fieldItem.field)
          .join(', ')}`,
      );

    return messageParts.join(' - ');
  }

  return undefined;
};

const getBackEndService = (requestUrl?: string): string | undefined => {
  if (!requestUrl?.trim()) {
    return undefined;
  }

  let hostName = '';
  try {
    hostName = new URL(requestUrl.toLowerCase().trim()).hostname;
  } catch (e) {
    /* Should not fall over whilst reporting an error */
  }

  const knownDomains = ['slimmingworld.co.uk', 'swlabs.digital'];
  if (!knownDomains.some(domain => hostName?.includes(domain))) {
    return hostName;
  }

  return hostName.split('.')[0];
};

const buildErrorMessage = (error: GatewayError): string => {
  const errorFromParsedResponse = buildMessageFromParsedResponse(error.response.parsed);

  if (errorFromParsedResponse) {
    return errorFromParsedResponse;
  }

  if (!error.response.ok && error.response.text) {
    return `${error.response.text?.trim()} (${error.response.status})`;
  }

  return error.message;
};

const addBackEndService = (errorMessage: string, requestUrl: string): string => {
  const backEndService = getBackEndService(requestUrl);

  if (backEndService) {
    return `[BE: ${backEndService}] ${errorMessage}`;
  }

  return errorMessage;
};

const getXhrErrorMessage = (error: GatewayError): string =>
  addBackEndService(buildErrorMessage(error), error.request.url);

export default getXhrErrorMessage;
