import createAction from 'redux-actions/lib/createAction';
import { push as historyPush } from 'react-router-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import Pages from 'common/src/app/data/enum/Pages';
import { dietaryFields, medicalFields } from '../../data/enum/FieldNames/AccountFieldNames';
import FormNames from '../../data/enum/FormNames';
import { apiGet, apiPut, apiPatch, apiPost, apiHead } from './apiActions/apiRequest';

import AccountState from '../../data/enum/AccountState';
import {
  userIdSelector,
  userAccountSelector,
  userAndEntityIdSelector,
} from '../../selectors/userAccountSelectors';
import { getPrivacySelector } from '../../selectors/userProfileSelectors';
import { getValue } from '../../util/injector';
import Medical from '../../data/enum/Medical';
import Dietary from '../../data/enum/Dietary';

import {
  GATEWAY_ACCOUNT_AUTH,
  GATEWAY_COMMUNITY_AUTH,
  GATEWAY_COMMUNITY_V4_AUTH,
} from '../../data/Injectables';
import { ACCOUNT, PROFILE, WEIGH_IN, PRIVACY_SETTINGS } from '../../data/entityTypes';
import apiGetEntity from './apiActions/apiGetEntity';
import dedupeAsyncThunk from '../../util/dedupeAsyncThunk';
import getDefaultApiEntityTransform from './apiActions/getDefaultApiEntityTransform';
import { setEntity } from '../entities/entityActions';
import { addEntitiesFromApiData } from './apiActions/apiGetCollection';
import authenticate from '../../util/auth/authenticate';
import WebHost from '../../data/enum/WebHost';

export const GET_PROFILE = 'profileActions/GET_PROFILE';
export const GET_LOGGED_IN_MEMBER = 'profileActions/GET_LOGGED_IN_MEMBER';
export const GET_PRIVACY_SETTINGS = 'profileActions/GET_PRIVACY_SETTINGS';
export const CHECK_USER_NAME_REGISTERED = 'profileActions/CHECK_USER_NAME_REGISTERED';
export const BUILD_PROFILE = 'profileActions/BUILD_PROFILE';
export const UPDATE_AVATAR = 'profileActions/UPDATE_AVATAR';
export const UPDATE_USER_NAME = 'profileActions/UPDATE_USERNAME';
export const UPDATE_USER_TIME_ZONE = 'profileActions/UPDATE_USER_TIME_ZONE';
export const AVATAR_PREVIEW = 'profileActions/AVATAR_PREVIEW';
export const UPDATE_PROFILE = 'accountActions/UPDATE_PROFILE';
export const UPDATE_PRIVACY_SETTINGS = 'profileActions/PrivacySettings';
export const UPDATE_MEDICAL_DIETARY = 'profileActions/UPDATE_MEDICAL_DIETARY';

export const IMPORT_LEGACY_WEIGH_IN_HISTORY = 'profileActions/IMPORT_LEGACY_WEIGH_IN_HISTORY';
export const DONT_IMPORT_LEGACY_WEIGH_IN_HISTORY =
  'profileActions/DONT_IMPORT_LEGACY_WEIGH_IN_HISTORY';
export const DISPLAY_ERROR_IMPORTING_MESSAGE = 'profileActions/DISPLAY_ERROR_IMPORTING_MESSAGE';
export const SUBMIT_EVENT = 'profileActions/SUBMIT_EVENT';

export const displayErrorImportingMessageAction = createAction(DISPLAY_ERROR_IMPORTING_MESSAGE);

export const importLegacyWeighInHistoryAction = createAction(IMPORT_LEGACY_WEIGH_IN_HISTORY);

export const dontImportLegacyWeighInHistoryAction = createAction(
  DONT_IMPORT_LEGACY_WEIGH_IN_HISTORY,
);

// Import the legacy members weigh-in history
export const importLegacyWeighInHistory =
  (userName = 'me') =>
  (dispatch, getState) =>
    getValue(GATEWAY_COMMUNITY_AUTH)
      .post(`/profiles/${userName}/import-legacy`, null, { getState })
      .then(result => dispatch(importLegacyWeighInHistoryAction(result)));

// Don't import the legacy members weigh-in history
export const dontImportLegacyWeighInHistory = redirectTo => dispatch => {
  dispatch(dontImportLegacyWeighInHistoryAction());
  dispatch(historyPush(redirectTo));
};

// Save the error message if the import of legacy weigh-ins fails
export const displayErrorImportingMessage = () => dispatch => {
  dispatch(displayErrorImportingMessageAction());
};

const checkUserNameRegisteredAction = createAction(CHECK_USER_NAME_REGISTERED);

export const avatarPreview = createAction(
  AVATAR_PREVIEW,
  ({ payload }) => payload,
  ({ form }) => ({ form }),
);

// Add the member to a Slimming Group

export const getProfile = dedupeAsyncThunk(
  (getFresh = false, id, awardsCount) =>
    async (dispatch, getState) => {
      await authenticate();
      const { entityId } = userAndEntityIdSelector(getState(), id);
      return dispatch(
        apiGetEntity(
          GET_PROFILE,
          GATEWAY_COMMUNITY_V4_AUTH,
          `/profiles/${entityId}`,
          PROFILE,
          entityId,
          {
            caching: getFresh ? false : undefined,
            requestData: {
              awardsCount,
            },
          },
        ),
      ).then(({ entity }) => {
        if (entity.weighIns) {
          dispatch(
            addEntitiesFromApiData(
              entity.weighIns,
              WEIGH_IN,
              undefined,
              getDefaultApiEntityTransform(`/profiles/${entityId}`, GET_PROFILE),
            ),
          );
        }
      });
    },
  true,
);

const ACCOUNTS_CHECK_USER_NAME = 'profileActions/ACCOUNTS_CHECK_USER_NAME';

export const checkUserNameRegistered = userName => dispatch =>
  dispatch(
    apiHead(ACCOUNTS_CHECK_USER_NAME, GATEWAY_ACCOUNT_AUTH, '/accounts', {
      userName,
    }),
  )
    .catch(error => {
      if (error.response && error.response.status === 404) {
        return true;
      }
      throw error;
    })
    .then(response => {
      const result = typeof response === 'boolean' ? response : false;
      dispatch(checkUserNameRegisteredAction({ data: result }));
      return result;
    });

export const buildProfile = (values, isGroupMember) => (dispatch, getState) => {
  const state = getState();
  const memberId = userIdSelector(getState());
  const redirectTo = `${Pages.LEARNING_MODULE_FOOD_OPTIMISING_ESSENTIALS}?resetAuth=1`;
  const {
    medical,
    diet,
    targetWeight,
    startWeight,
    weighInDay,
    dateJoinedGroupUTC,
    ...enhancedProfile
  } = values || {};

  // Get the account and account state
  const account = userAccountSelector(state);
  let accountState = account?.accountState;

  if (accountState) {
    const accountStateType = isGroupMember
      ? AccountState.GROUP_PROFILE_COMPLETE
      : AccountState.ONLINE_PROFILE_COMPLETE;
    // eslint-disable-next-line no-bitwise
    accountState |= accountStateType; // Add the profile complete value
  }

  /**
   * Group Details
   * If the member is going through the group profile builder flow
   * we allow them to pick the data that they joined group, the day they go and
   * the weight at the start of group (which is optional)
   * If its the online profile builder - set this to null in the action call
   * as they don't have group details
   */
  const consultantLedGroupProfile = {
    dateJoinedGroupUTC,
    dayOfGroup: weighInDay,
    weightAtStartOfGroup: startWeight,
  };

  return dispatch(
    apiPost(BUILD_PROFILE, GATEWAY_COMMUNITY_V4_AUTH, `/profiles/${memberId}/`, {
      targetWeight,
      medical: medical > 0 ? medical : Medical.none,
      diet: diet > 0 ? diet : Dietary.nopreference,
      consultantLedGroupProfile: isGroupMember ? consultantLedGroupProfile : null,
      enhancedProfile: { ...enhancedProfile },
    }),
  )
    .then(() =>
      dispatch(
        setEntity(
          ACCOUNT,
          memberId,
          {
            isProfileCompleted: isGroupMember
              ? AccountState.GROUP_PROFILE_COMPLETE
              : AccountState.ONLINE_PROFILE_COMPLETE,
            accountState,
          },
          true,
        ),
      ),
    )
    .then(() => dispatch(getProfile(true)))
    .then(() => {
      const returnHost = state.config.environmentConfig.web[WebHost.MEMBER].host;
      window.location.href = returnHost + redirectTo;
    });
};

export const updateProfile = values => (dispatch, getState) => {
  const memberId = userIdSelector(getState());
  return dispatch(
    apiPatch(UPDATE_PROFILE, GATEWAY_COMMUNITY_AUTH, `/profiles/${memberId}`, values),
  ).then(() => dispatch(setEntity(PROFILE, memberId, values, true)));
};

export const getPrivacySettings = dedupeAsyncThunk(
  (getFresh = false, id) =>
    async (dispatch, getState) => {
      await authenticate();
      const { entityId } = userAndEntityIdSelector(getState(), id);
      const options = {};

      options.transformEntity = entity =>
        Object.keys(entity).reduce(
          (accumulator, key) =>
            Number.isNaN(Number(key))
              ? accumulator
              : { ...accumulator, [entity[key].privacySetting]: entity[key].accessLevel },
          {},
        );

      options.caching = getFresh ? false : undefined;

      return dispatch(
        apiGetEntity(
          GET_PRIVACY_SETTINGS,
          GATEWAY_COMMUNITY_AUTH,
          `/profiles/${entityId}/privacy-settings`,
          PRIVACY_SETTINGS,
          entityId,
          options,
        ),
      );
    },
  true,
);

export const updatePrivacySettings = privacyOptionsDTO => (dispatch, getState) => {
  const memberId = userIdSelector(getState());
  const previousEntityState = getPrivacySelector(getState(), { id: memberId });
  const entityData = {
    [privacyOptionsDTO.privacySetting]: privacyOptionsDTO.accessLevel,
  };

  // Already set new state before executing call
  dispatch(setEntity(PRIVACY_SETTINGS, memberId, entityData, true));

  return dispatch(
    apiPatch(
      UPDATE_PRIVACY_SETTINGS,
      GATEWAY_COMMUNITY_AUTH,
      `/profiles/${memberId}/privacy-settings`,
      [privacyOptionsDTO],
    ),
  )
    .then(() => dispatch(setEntity(PRIVACY_SETTINGS, memberId, entityData, true)))
    .catch(() => dispatch(setEntity(PRIVACY_SETTINGS, memberId, previousEntityState, true)));
};

export const submitEvent = eventType => dispatch =>
  dispatch(
    apiPost(SUBMIT_EVENT, GATEWAY_COMMUNITY_AUTH, `/profiles/me/events/${eventType}`, {
      eventType,
    }),
  );

export const GET_PROFILE_HEIGHT = 'profileActions/GET_PROFILE_HEIGHT';

export const getProfileHeight = () => (dispatch, getState) => {
  const memberId = userIdSelector(getState());

  return dispatch(
    apiGet(GET_PROFILE_HEIGHT, GATEWAY_COMMUNITY_AUTH, `/profiles/${memberId}/height`),
  );
};

export const updateMedicalDietaryConditions = () => (dispatch, getState) => {
  const state = getState();
  const memberId = userIdSelector(state);

  // Form values
  const medicalConditionsFormSelector = formValueSelector(FormNames.PB_MEDICAL_CONDITIONS);
  const dietaryPreferencesFormSelector = formValueSelector(FormNames.PB_DIETARY_PREFERENCES);

  // Medical conditions
  const medicalConditionsResult = medicalConditionsFormSelector(
    state,
    medicalFields.ARE_MEDICAL_CONDITIONS,
  );
  const medicalConditions = medicalConditionsResult > 0 ? medicalConditionsResult : Medical.none;

  // Dietary preferences
  const dietaryPreferencesResult = dietaryPreferencesFormSelector(
    state,
    dietaryFields.HAS_PREFERENCE,
  );
  const dietaryPreferences =
    dietaryPreferencesResult > 0 ? dietaryPreferencesResult : Dietary.nopreference;

  const data = {
    medical: medicalConditions,
    diet: dietaryPreferences,
  };

  return dispatch(
    apiPatch(UPDATE_PROFILE, GATEWAY_COMMUNITY_AUTH, `/profiles/${memberId}`, data),
  ).then(() => dispatch(setEntity(PROFILE, memberId, data, true)));
};

export const updateProfilePregnantStatus =
  ({ actionType, isPregnant }, userId = null) =>
  (dispatch, getState) => {
    const memberId = userIdSelector(getState());
    const queryParam = actionType !== null ? `?actionType=${actionType}` : '';
    return dispatch(
      apiPut(
        UPDATE_PROFILE,
        GATEWAY_COMMUNITY_AUTH,
        `/profiles/${userId || memberId}/is-pregnant${queryParam}`,
        {
          isPregnant,
        },
      ),
    ).then(() => dispatch(setEntity(PROFILE, memberId, { isPregnant }, true)));
  };

export const GET_PROFILE_PROGRESS = 'accountActions/GET_PROFILE_PROGRESS';
export const getProfileProgress = () => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  return dispatch(
    apiGet(GET_PROFILE_PROGRESS, GATEWAY_COMMUNITY_AUTH, `/incompleteprofiles/${userId}`),
  ).catch(() => null);
};

export const UPDATE_PROFILE_PROGRESS = 'accountActions/UPDATE_PROFILE_PROGRESS';
export const updateProfileProgress = profileProgressData => (dispatch, getState) => {
  const userId = userIdSelector(getState());
  return dispatch(
    apiPut(UPDATE_PROFILE_PROGRESS, GATEWAY_COMMUNITY_AUTH, `/incompleteprofiles/`, {
      ...profileProgressData,
      id: userId,
    }),
  ).catch(() => null);
};

export const GET_SUGGESTED_USERNAMES = 'profileActions/GET_SUGGESTED_USERNAMES';
export const getSuggestedUsernames = () => (dispatch, getState) => {
  const memberId = userIdSelector(getState());

  return dispatch(
    apiGet(
      GET_SUGGESTED_USERNAMES,
      GATEWAY_COMMUNITY_AUTH,
      `/profiles/${memberId}/suggested-usernames`,
    ),
  ).then(results => {
    dispatch(
      setEntity(
        PROFILE,
        memberId,
        {
          suggestedUsernames: results?.data,
        },
        true,
      ),
    );
    return results;
  });
};
