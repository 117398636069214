import { addressFields } from './enum/FieldNames/AccountFieldNames';
import * as validationTriggers from './validationTriggers';

export const GB = {
  [addressFields.COUNTRY]: [validationTriggers.REQUIRED],
  [addressFields.ADDRESS_LINE_1]: [validationTriggers.REQUIRED, validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_2]: [validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_3]: [validationTriggers.MAX_LENGTH],
  [addressFields.CITY_OR_TOWN]: [validationTriggers.REQUIRED],
  [addressFields.COUNTY]: [validationTriggers.REQUIRED],
  [addressFields.ZIP_OR_POSTAL]: [validationTriggers.REQUIRED, validationTriggers.POSTAL_CODE],
};

/*
 * Reorder so that the country field is displayed last
 * (this needs to remain first within the form because country selection changes which fields
 * are displayed on the form.)
 */
export const reorderCountry = fields => {
  const [country, ...rest] = fields;
  return [...rest, country];
};

export const IRELAND = {
  [addressFields.COUNTRY]: [validationTriggers.REQUIRED],
  [addressFields.ADDRESS_LINE_1]: [validationTriggers.REQUIRED, validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_2]: [validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_3]: [validationTriggers.MAX_LENGTH],
  [addressFields.CITY_OR_TOWN]: [validationTriggers.REQUIRED],
  [addressFields.COUNTY_IE]: [validationTriggers.REQUIRED],
  [addressFields.ZIP_OR_POSTAL]: [],
};

export const CYPRUS = {
  [addressFields.COUNTRY]: [validationTriggers.REQUIRED],
  [addressFields.ADDRESS_LINE_1]: [validationTriggers.REQUIRED, validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_2]: [validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_3]: [validationTriggers.MAX_LENGTH],
  [addressFields.CITY_OR_TOWN]: [validationTriggers.REQUIRED],
  [addressFields.ZIP_OR_POSTAL]: [],
};

export const US = {
  [addressFields.COUNTRY]: [validationTriggers.REQUIRED],
  [addressFields.ADDRESS_LINE_1]: [validationTriggers.REQUIRED, validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_2]: [validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_3]: [validationTriggers.MAX_LENGTH],
  [addressFields.CITY_OR_TOWN]: [validationTriggers.REQUIRED],
  [addressFields.STATE]: [validationTriggers.REQUIRED],
  [addressFields.ZIP_OR_POSTAL]: [validationTriggers.REQUIRED, validationTriggers.ZIP_CODE],
};

export const DEFAULT = {
  [addressFields.COUNTRY]: [validationTriggers.REQUIRED],
  [addressFields.ADDRESS_LINE_1]: [validationTriggers.REQUIRED, validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_2]: [validationTriggers.MAX_LENGTH],
  [addressFields.ADDRESS_LINE_3]: [validationTriggers.MAX_LENGTH],
  [addressFields.CITY_OR_TOWN]: [validationTriggers.REQUIRED],
  [addressFields.ZIP_OR_POSTAL]: [validationTriggers.REQUIRED],
};
