import { getProfile } from 'common/src/app/actions/resources/profileActions';
import { AWARD, LEARNING_MODULE_AWARDS } from '../../data/entityTypes';
import {
  GATEWAY_COMMUNITY_AUTH,
  GATEWAY_COMMUNITY_V2_AUTH,
  GATEWAY_LEARNING_CONTENT_AUTH,
  GATEWAY_CONTENT_V2_AUTH,
} from '../../data/Injectables';
import apiGetCollection from './apiActions/apiGetCollection';
import { apiPost, apiGet } from './apiActions/apiRequest';
import { awardsCollectionId, learningAwardsCollectionId } from '../../data/collectionIds';
import { userAndEntityIdSelector, userIdSelector } from '../../selectors/userAccountSelectors';
import dedupeAsyncThunk from '../../util/dedupeAsyncThunk';

export const GET_AWARDS = 'awardActions/GET_AWARDS';
export const GET_LEARNING_AWARDS = 'awardActions/GET_LEARNING_AWARDS';
export const GET_LEARNING_CONTENT_AWARDS = 'awardActions/GET_LEARNING_CONTENT_AWARDS';

export const getAwards = dedupeAsyncThunk(
  (getFresh = false, limit = 10, id = 'me', collectionId = awardsCollectionId) =>
    (dispatch, getState) => {
      const { entityId } = userAndEntityIdSelector(getState(), id);
      return dispatch(
        apiGetCollection(
          GET_AWARDS,
          GATEWAY_COMMUNITY_AUTH,
          `/profiles/${id}/awards`,
          collectionId({ userId: entityId }),
          {},
          {
            entityType: AWARD,
            requestData: {
              limit,
            },
            caching: getFresh ? false : undefined,
          },
        ),
      );
    },
  true,
);

export const POST_AWARD = 'awardActions/POST_AWARD';

export const postAward = awardId => async (dispatch, getState) => {
  const state = getState();
  const profileId = userIdSelector(state);

  try {
    await dispatch(
      apiPost(POST_AWARD, GATEWAY_COMMUNITY_V2_AUTH, `/profiles/${profileId}/awards`, {
        awardTypeId: awardId,
      }),
    ).then(() => dispatch(getProfile(true)));
    return true;
  } catch (error) {
    return false;
  }
};

export const getLearningAwards = dedupeAsyncThunk(
  (id = 'me', collectionId = learningAwardsCollectionId) =>
    async (dispatch, getState) => {
      const { entityId } = userAndEntityIdSelector(getState(), id);

      try {
        await dispatch(
          apiGet(GET_LEARNING_AWARDS, GATEWAY_LEARNING_CONTENT_AUTH, `/modules/${id}/progress`),
        ).then(learningAwards => {
          const learningAwardsFilter = learningAwards?.data?.filter(
            learningAward => learningAward.isComplete,
          );
          if (learningAwardsFilter.length > 0) {
            const moduleIds = learningAwardsFilter.map(award => award.moduleId);
            dispatch(
              apiGetCollection(
                GET_LEARNING_CONTENT_AWARDS,
                GATEWAY_CONTENT_V2_AUTH,
                `/learning-content-awards/search?moduleIds=${moduleIds?.join(',')}`,
                collectionId({ userId: entityId }),
                {},
                {
                  entityType: LEARNING_MODULE_AWARDS,
                },
              ),
            );
          }
        });
        return true;
      } catch (error) {
        return false;
      }
    },
  true,
);
