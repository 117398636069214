import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import MembershipCard from 'common/src/app/data/enum/MembershipCard';
import { membershipCardFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import Input from '../Input';
import InformationToggle from '../../molecules/InformationToggle';
import Row from '../Row';
import LocaleMessage from '../LocaleMessage';
import FormErrorMessages from '../FormErrorMessages';
import FormGroup from '../../molecules/FormGroup';

const MembershipCardInput = ({}, {}, className) => (
  <div className={className}>
    <FormGroup label={<LocaleMessage id="membershipCardInput.cardTitle" />}>
      <Row column>
        <EnhancedField
          component={Input}
          placeholder={<LocaleMessage id="membershipCardInput.cardPlaceholder" />}
          type="number"
          autoComplete="nope" // https://tinyurl.com/yarnru9l
          name={membershipCardFields.CARD_NUMBER}
        />
        <FormErrorMessages fields={[membershipCardFields.CARD_NUMBER]} />
        <InformationToggle
          label={<LocaleMessage id="membershipCardInput.cardInformationLabel" />}
          information={<LocaleMessage id="membershipCardInput.cardInformation" />}
        />
      </Row>
    </FormGroup>
    <FormGroup type="inline" label={<LocaleMessage id="membershipCardInput.pinTitle" />}>
      <EnhancedField
        component={Input}
        placeholder={<LocaleMessage id="membershipCardInput.pinPlaceholder" />}
        maxLength={MembershipCard.PINMAXLENGTH}
        type="number"
        autoComplete="nope" // https://tinyurl.com/yarnru9l
        name={membershipCardFields.PIN}
      />

      <FormErrorMessages fields={[membershipCardFields.PIN]} />
      <InformationToggle
        label={<LocaleMessage id="membershipCardInput.informationLabel" />}
        information={<LocaleMessage id="membershipCardInput.pinInformation" />}
      />
    </FormGroup>
  </div>
);
export default withFunctionalClassName(
  ComponentType.ATOM,
  'MembershipCardInput',
)(MembershipCardInput);
