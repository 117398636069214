import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import VideoPropTypes from 'common/src/app/util/proptypes/apiEntities/video';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import VideoType from 'common/src/app/data/enum/VideoType';
import trackingShape from 'common/src/app/util/proptypes/apiEntities/tracking';
import IconName from 'common/src/app/data/enum/IconName';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { RoleType } from 'common/src/app/data/enum/AccessibilityEnum';
import Icon from '../../../../../atoms/Icon';
import Button from '../../../../../atoms/Button';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import './video-block.scss';

// Note: Usage of VideoBlock is deprecated and will be removed in a future version!
// Instead you can use the VideoWithThumbnailBlock

const VideoBlock = (
  {
    video,
    openModal,
    videoThumbnail,
    trackingData,
    trackVideoEvent,
    showVideoThumbnail,
    title,
    videoCaption,
    width,
    height,
    videoButtonId,
    videoName,
    brightcoveAccountId,
    brightcovePlayerId,
  },
  context,
  className,
  dataTestid,
) => {
  const openVideoModal = () =>
    openModal(
      ModalNames.VIDEO,
      {
        video,
        brightcoveAccountId,
        brightcovePlayerId,
        trackingData,
        trackVideoEvent,
      },
      false,
      false,
      true,
    );

  let videoThumbnailSet;

  if (video?.videoThumbnail?.src) {
    videoThumbnailSet = video.videoThumbnail;
  } else if (videoThumbnail && videoThumbnail.src) {
    videoThumbnailSet = videoThumbnail;
  }

  return (
    <div className={className} data-testid={dataTestid}>
      {title && (
        <TextNew.Confident cid="title" element={ElementTypes.H2}>
          {title}
        </TextNew.Confident>
      )}

      <div className="video-container" id={videoName}>
        {/* Show the default youtube thumbnail if a videoThumbnail was not passed in
        and the video type is youtube and showVideoThumbnail is set to true */}
        {
          // eslint-disable-next-line no-underscore-dangle
          showVideoThumbnail && !videoThumbnail && video._type !== VideoType.BRIGHTCOVE ? (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              cid="video-thumbnail"
              role={RoleType.PRESENTATION}
              src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
            />
          ) : null
        }
        {videoThumbnailSet ? (
          <ResponsiveImage
            cid="video-thumbnail"
            src={videoThumbnailSet.src}
            alt={videoThumbnailSet.alt}
            ratio={16 / 9}
          />
        ) : null}
        <Button isTertiary onClick={openVideoModal} id={videoButtonId}>
          <span className="screen-reader">
            <LocaleMessage id="general.video.play" />
          </span>
          <Icon name={IconName.PLAY} width={width} height={height} />
        </Button>
      </div>
      {videoCaption ? <RichTextBlock text={videoCaption} /> : null}
    </div>
  );
};

VideoBlock.defaultProps = {
  showVideoThumbnail: true,
  width: 60,
  height: 60,
};

VideoBlock.propTypes = {
  ...VideoPropTypes,
  videoThumbnail: ImagePropTypes,
  /**
   * Passed by the connect method
   */
  openModal: PropTypes.func,
  /**
   * Tracking data
   */
  trackingData: PropTypes.shape(trackingShape),
  /**
   * Action to expose video information to the data layer
   */
  trackVideoEvent: PropTypes.func,
  showVideoThumbnail: PropTypes.bool,
  /**
   * Video caption
   */
  videoCaption: PropTypes.string,
  /**
   * Video width
   */
  width: PropTypes.number,
  /**
   * Video height
   */
  height: PropTypes.number,
  videoButtonId: PropTypes.string,
  videoName: PropTypes.string,
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.ATOM, 'VideoBlock')(VideoBlock);
