/* global 'molecule' */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { componentClassNameProp } from 'common/src/app/util/componentClassNameUtils';
import LocaleMessage from 'components/atoms/LocaleMessage';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import StaticProgressBar from 'components/atoms/StaticProgressBar';
import './iframe-logout.scss';

const TIME_TO_HANDLE_LOADED_STATE = 250;
const TIME_TO_ANIMATE = 500;
const TIME_TO_HANDLE_LOGOUT = 1000;
class IframeLogout extends Component {
  constructor() {
    super();
    this.state = {
      totalClientFrames: 0,
      totalClientFramesLoaded: 0,
    };
  }

  componentDidMount() {
    this.initIframeLogout(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.initIframeLogout();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  initIframeLogout = () => {
    const { showLogoutPrompt, signOutIframeUrl } = this.props;
    if (!showLogoutPrompt && signOutIframeUrl) {
      this.handleIframeLogout();
    }
  };

  setTimer = timer => {
    this.timer = setTimeout(() => {
      this.setState({ totalClientFramesLoaded: this.state.totalClientFrames }, () => {
        setTimeout(() => {
          window.location.href = this.props.postLogoutRedirectUri;
        }, TIME_TO_ANIMATE);
      });
    }, timer);
  };

  handleIframeLogout = () => {
    let masterIframeLoaded = false;
    const masterIframe = this.iframe;

    const checkIframesLoaded = () => {
      const { totalClientFrames, totalClientFramesLoaded } = this.state;
      if (
        masterIframeLoaded &&
        totalClientFramesLoaded > 0 &&
        totalClientFrames === totalClientFramesLoaded
      ) {
        // Give the user a little bit of experience that something is happening, before redirecting
        clearTimeout(this.timer);
        this.setTimer(TIME_TO_HANDLE_LOGOUT);
      }
    };

    let totalClientFrames = 0;
    masterIframe.onload = () => {
      const parentFrameDocument =
        masterIframe.contentDocument || masterIframe.contentWindow.document;
      const clientIframes = parentFrameDocument.querySelectorAll('iframe');
      totalClientFrames = clientIframes.length;
      this.setState({ totalClientFrames });
      masterIframeLoaded = true;
      checkIframesLoaded();
    };

    // Retrieve the messages from the iframe postMessage
    let totalClientFramesLoaded = 0;
    window.addEventListener('message', event => {
      // This is the message from the clear-client-session.html
      const message = event?.data;
      if (message && message === 'child-frame-is-loaded') {
        clearTimeout(this.loadedTimer);

        totalClientFramesLoaded += 1;
        this.loadedTimer = setTimeout(() => {
          this.setState({ totalClientFramesLoaded }, checkIframesLoaded);
        }, TIME_TO_HANDLE_LOADED_STATE + TIME_TO_ANIMATE * totalClientFramesLoaded);
      }
    });

    // This page should not hang. If that happens we need to trigger a manual redirect
    this.setTimer(
      TIME_TO_HANDLE_LOADED_STATE + TIME_TO_ANIMATE * totalClientFrames + TIME_TO_HANDLE_LOGOUT,
    );
  };

  render() {
    const { signOutIframeUrl } = this.props;
    const { totalClientFrames, totalClientFramesLoaded } = this.state;

    return (
      <>
        <iframe
          title="Sign out"
          {...componentClassNameProp('molecule', this)}
          ref={ref => (this.iframe = ref)}
          src={signOutIframeUrl}
        />
        <TextNew.Loud element={ElementTypes.H1}>
          <LocaleMessage id="iFrameLogout.loggingOut" />
        </TextNew.Loud>

        <StaticProgressBar value={totalClientFramesLoaded} maxValue={totalClientFrames} />
      </>
    );
  }
}

IframeLogout.propTypes = {
  signOutIframeUrl: PropTypes.string,
  postLogoutRedirectUri: PropTypes.string,
  showLogoutPrompt: PropTypes.bool,
};

export default IframeLogout;
