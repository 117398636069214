import handleActions from 'redux-actions/lib/handleActions';
import { INPUT_MANUAL, INPUT_SEARCH } from '../../../actions/components/addressLookupActions';

const initialState = {
  manualInput: false,
};

const addressLookupReducer = handleActions(
  {
    [INPUT_MANUAL]: () => ({
      manualInput: true,
    }),
    [INPUT_SEARCH]: () => ({
      manualInput: false,
    }),
  },
  initialState,
);

export default addressLookupReducer;
