export default {
  CONFIRM: 'ConfirmModal',
  CONTENT: 'ContentModal',
  GALLERY: 'GalleryModal',
  VIDEO: 'VideoModal',
  SYNS_ALLOWANCE: 'SynsAllowanceModal',
  EDIT_PROFILE: 'EditProfileModal',
  RECIPE_DETAIL: 'RecipeDetailModal',
  FOOD_PLANNER_MODAL: 'FoodPlannerModal',
  FOOD_PLAN_SELECT_MODAL: 'FoodPlanSelectModal',
  CUSTOM_FOOD_MODAL: 'CustomFoodModal',
  PREGNANCY_NOTIFICATION_MODAL: 'PregnancyNotificationModal',
  ACTIVITY_PLANNER_MODAL: 'ActivityPlannerModal',
  AWARD_SUCCESS_MODAL: 'AwardSuccessModal',
  SYNS_ESTIMATOR_FOOD_CONFIRM_MODAL: 'SynsEstimatorFoodConfirmationModal',
  PUBLIC_GROUP_EXPLAINED_MODAL: 'GroupSessionExplained',
  INSTAGRAM_IMAGE_MODAL: 'InstagramImageModal',
  REGISTRATION_ERROR: 'RegistrationError',
  EXISTING_LEGACY_ACCOUNT_PROMPT_MODAL: 'ExistingLegacyAccountPromptModal',
  SUCCESSFULLY_REGISTERED_FREE2GO: 'SuccessfullyRegisteredFree2Go',
  SKIP_GROUP_CONFIRM_MODAL: 'SkipGroupConfirmModal',
  SHIPPING_ADDRESS_MODAL: 'ShippingAddressModal',
  PERSONAL_DETAILS_MODAL: 'PersonalDetailsModal',
  BILLING_ADDRESS_MODAL: 'BillingAddressModal',
  ORDER_CONFIRMATION_MODAL: 'OrderConfirmationModal',
  MAGIC_MOVER_MODAL: 'MagicMoverModal',
  ACTIVITY_VIDEO_INTRO_MODAL: 'ActivityVideoIntroModal',
};
