/* global 'template','InvitationError' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ApplicationError from '../ApplicationError';
import Row from '../../atoms/Row';
import Button from '../../atoms/Button';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import './invitation-errors.scss';

const InvitationError = ({ publicHost }) => (
  <ApplicationError>
    <TextNew.Loud element={ElementTypes.H1} localeId={`InvitationError.title`} />
    <TextNew.PrimaryElegant localeId="InvitationError.description" />
    <Row justifycenter>
      <Button href={publicHost} ripple>
        <LocaleMessage id="InvitationError.button.label" />
      </Button>
    </Row>
  </ApplicationError>
);

InvitationError.propTypes = {
  publicHost: PropTypes.string.isRequired,
};

export default withFunctionalClassName('template','InvitationError')(InvitationError);
