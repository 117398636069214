import linkifyIt from 'linkify-it';
import flatten from 'lodash/flatten';
import { SEGMENT_RAW, SEGMENT_URL } from '../../data/enum/SegmentType';
import { processMatches } from './index';

const linkify = linkifyIt();

/**
 * Detects urls in all SEGMENT_RAW message segments using the `linkify-it` module and converts them
 * into a SEGMENT_URL.
 * @param input {Array<Object>} An array of message segments
 * @returns {Array<Object>} The processed array of message segments
 */
const detectUrl =
  (customLinkfy = linkify) =>
  input =>
    flatten(
      input.map(segment => {
        const { type, data } = segment;

        switch (type) {
          case SEGMENT_RAW: {
            return processMatches(customLinkfy.match(data), segment, SEGMENT_URL);
          }
          default:
            return segment;
        }
      }),
    );

export default detectUrl;
