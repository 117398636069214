import PropTypes from 'prop-types';
import React from 'react';
import TextNew, { ElementTypes } from '../../../../../../TextNew';
import LocaleMessage from '../../../../../../LocaleMessage';
import Select from '../../../../../../Select';
import InputNew from '../../../../atoms/InputNew';
import WeightInputLabel from '../WeightInputLabel';

const halfPoundOptions = [
  {
    title: '0',
    value: 0,
  },
  {
    title: '½',
    value: 0.5,
  },
];

const WeightInputImperial = ({
  abbreviate,
  unitsToDisplay,
  disabled,
  values,
  weightprefixid,
  ...restProps
}) => {
  const weightPrefix = weightprefixid || 'targetWeight';
  return (
    <>
      {unitsToDisplay.includes('stone') && (
        <>
          <InputNew
            id={`${weightPrefix}-stones`}
            data-testid={`${weightPrefix}-stones`}
            descriptor="static"
            name="stones"
            type="number"
            min="0"
            max="50"
            label={
              <>
                <span aria-hidden="true">
                  <WeightInputLabel weightUnit="stone" abbreviate={abbreviate} />
                </span>
                <span className="screen-reader">
                  <WeightInputLabel weightUnit="stone" abbreviate={true} />
                </span>
              </>
            }
            placeholder="00"
            value={values?.stones || ''}
            disabled={disabled}
            {...restProps}
          />
          <TextNew.Formal element={ElementTypes.SPAN} ariaHidden cid="dot">
            .
          </TextNew.Formal>
        </>
      )}
      <InputNew
        id={`${weightPrefix}-fullPounds`}
        data-testid={`${weightPrefix}-fullPounds`}
        descriptor="static"
        name="fullPounds"
        type="number"
        value={values?.fullPounds || ''}
        max="13"
        min="0"
        placeholder="00"
        label={
          <>
            <span aria-hidden="true">
              <WeightInputLabel weightUnit="pounds" abbreviate={abbreviate} />
            </span>
            <span className="screen-reader">
              <WeightInputLabel weightUnit="pounds" abbreviate={true} />
            </span>
          </>
        }
        disabled={disabled}
        {...restProps}
      />
      <label className="screen-reader" htmlFor={`${weightPrefix}-remainingPounds`}>
        <LocaleMessage id="general.measureUnit.weight.pounds.labelHalfPound" />
      </label>
      <Select
        id={`${weightPrefix}-remainingPounds`}
        data-testid={`${weightPrefix}-remainingPounds`}
        value={`${values?.remainingPounds}`}
        name="remainingPounds"
        options={halfPoundOptions}
        input={restProps}
        suppress
      />
    </>
  );
};

WeightInputImperial.defaultProps = {
  unitsToDisplay: ['stone', 'pounds'],
  abbreviate: true,
};

WeightInputImperial.propTypes = {
  abbreviate: PropTypes.bool,
  disabled: PropTypes.bool,
  unitsToDisplay: PropTypes.array,
  weightprefixid: PropTypes.string,
  values: PropTypes.shape({
    fullPounds: PropTypes.number,
    stones: PropTypes.number,
    remainingPounds: PropTypes.number,
  }),
};

export default WeightInputImperial;
