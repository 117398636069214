import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import DeviceStateType from 'common/src/app/types/DeviceState';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Configuration from 'common/src/app/config/Configuration';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import Wrapper from '../../atoms/Wrapper';

import './header.scss';

const TOP_OFFSET = 0;
const HEADER_WITH_NOTIFICATION_HEIGHT = 105;

const getDistanceBetweenTop = (el: HTMLElement) => el && el.getBoundingClientRect().top;
const getHeight = (el: HTMLElement) => el && el.getBoundingClientRect().height;

/**
 * Used on pages with a wizard navigation, rather than header / navigation
 * ideally used on forms
 *
 * This will automatically set the component to fixed
 *
 * todo nice to have combined with a scroll manager when this component will not be
 * at the top of the page
 */

type HeaderProps = {
  useWrapper?: boolean;
  children?: ReactNode;
  center?: ReactNode;
  top?: ReactNode;
  bottom?: ReactNode;
  isFixed: boolean;
  height: number;
  deviceState?: DeviceStateType;
  hasMenu?: boolean;
  emailConfirmed: boolean;
  homeOnboarderPaused: boolean;
  onboarderLoaded: boolean;
  register?: (height: number) => void;
  headerToggleFixed?: (isFixed: boolean, height: number) => void;
  unregister?: () => void;
};

const Header = (
  {
    useWrapper = true,
    children,
    center,
    top,
    bottom,
    isFixed: initialIsFixed,
    height: initialHeight,
    deviceState = DeviceState.XL as DeviceStateType,
    hasMenu,
    homeOnboarderPaused,
    onboarderLoaded,
    register,
    emailConfirmed,
    headerToggleFixed,
    unregister,
  }: HeaderProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => {
  const [paddingBottom, setPaddingBottom] = useState(HEADER_WITH_NOTIFICATION_HEIGHT);
  const [isCurrentlyFixed, setIsCurrentlyFixed] = useState(initialIsFixed);
  const [height, setHeight] = useState(initialHeight);

  const fakePaddingDivRef = useRef<HTMLDivElement | null>(null);
  const wholeNavWrapperRef = useRef<HTMLDivElement | null>(null);

  const isFixedFunc = () => {
    if (!fakePaddingDivRef.current) return false;

    const fromTop = getDistanceBetweenTop(fakePaddingDivRef.current);
    return fromTop <= TOP_OFFSET;
  };

  const recalculateHeaderRender = () => {
    const isFixed = isFixedFunc();
    const totalHeaderHeight =
      wholeNavWrapperRef?.current?.getBoundingClientRect().height ??
      HEADER_WITH_NOTIFICATION_HEIGHT;

    if (paddingBottom !== totalHeaderHeight || totalHeaderHeight !== initialHeight) {
      setPaddingBottom(totalHeaderHeight);
      setHeight(totalHeaderHeight);
      headerToggleFixed && headerToggleFixed(isFixed, totalHeaderHeight);
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      const currentHeight = wholeNavWrapperRef.current ? getHeight(wholeNavWrapperRef.current) : 0;
      const isFixed = isFixedFunc();

      if (isCurrentlyFixed !== initialIsFixed || currentHeight !== initialHeight) {
        register && register(currentHeight);
        headerToggleFixed && headerToggleFixed(isFixed, currentHeight);
      }

      setIsCurrentlyFixed(isFixed);
      setHeight(currentHeight);
    });
  }, [
    headerToggleFixed,
    initialHeight,
    initialIsFixed,
    isCurrentlyFixed,
    register,
    wholeNavWrapperRef,
    fakePaddingDivRef,
    deviceState,
  ]);

  useEffect(() => {
    if (!wholeNavWrapperRef.current) return;

    const totalHeaderHeight = wholeNavWrapperRef.current.getBoundingClientRect().height;
    if (paddingBottom !== totalHeaderHeight) {
      setPaddingBottom(totalHeaderHeight);
    }
    setHeight(totalHeaderHeight);
  }, [wholeNavWrapperRef, fakePaddingDivRef, deviceState, paddingBottom, height]);

  useEffect(() => {
    // Added timers to allow getBoundingClientRect to get height correctly, from initial render.
    // we are using two timeouts due to timings how the getBoundingClientRect is calculated between
    // chrome and edge using 250 and 500 to reduce layout shift and catch difference in the browsers.
    const transitionTimerChrome = setTimeout(() => recalculateHeaderRender(), 250);
    const transitionTimerEdge = setTimeout(() => recalculateHeaderRender(), 500);

    return () => {
      clearTimeout(transitionTimerChrome);
      clearTimeout(transitionTimerEdge);
    };
  }, []);

  useEffect(
    () => () => {
      unregister && unregister();
    },
    [unregister],
  );

  // This fix is necessary to ensure the "Continue onboarder tour" row
  // remains visible and not hidden behind the fixed position header
  const continueOnboarderFix = onboarderLoaded && !emailConfirmed && homeOnboarderPaused;

  const isMenuFixed = deviceState > DeviceState.XXXL && Configuration.menuFixed;
  const heightValue = isCurrentlyFixed ? height : 0;

  const hasWrapper = (
    content: ReactNode,
    wrapperProps?: {
      [key: string]: unknown;
    },
  ) =>
    useWrapper ? (
      <Wrapper width="xl" {...wrapperProps}>
        {content}
      </Wrapper>
    ) : (
      content
    );

  return (
    <header className={className} data-testid={dataTestId}>
      <div
        ref={fakePaddingDivRef}
        style={{
          paddingBottom: continueOnboarderFix ? paddingBottom : heightValue,
        }}
      />
      <div
        ref={wholeNavWrapperRef}
        className={classNames('header-content', {
          'has-menu': hasMenu,
          'is-fixed': isCurrentlyFixed,
          'menu-fixed': isMenuFixed,
          'using-wrapper': useWrapper,
        })}
      >
        {top && hasWrapper(top)}

        {hasWrapper(
          <>
            {children}
            {center && <div className="header-center">{center}</div>}
          </>,
          {
            background: 'primary-light',
            cid: 'main-content',
          },
        )}
        {bottom && hasWrapper(bottom, { background: 'primary-light', cid: 'bottom-content' })}
      </div>
    </header>
  );
};

type HeaderType = (props: HeaderProps) => JSX.Element;

export default compose<HeaderType>(
  withDeviceState(),
  withFunctionalClassName(ComponentType.ORGANISM, 'Header'),
)(Header);
