import GatewayError from '../../../../net/gateway/GatewayError';

const ID_SUBSTITUTE = '/{ID}/';
const ID_REGEX = /(\/\d+\/)|(\/\d+$)|(\/GO-\w+\/)|(\/GO-\w+$)|(\/me\/)|(\/me$)/gi;

const removeQueryString = (url: string): string => url.split('?')[0];
const removeIds = (url: string): string => url.replace(ID_REGEX, ID_SUBSTITUTE);

const buildXhrFingerprint = (error: GatewayError): string[] => [
  '{{ default }}',
  removeIds(removeQueryString(error.request.url)), // Removing the IDs prevents the fingerprint from being too specific and hindering aggregation
  error.request.method,
  `${error.response.status}`,
];

export default buildXhrFingerprint;
