import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import { makeGetGeneralFormError } from 'common/src/app/enhanced-redux-form/reducers';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import FormErrorMessages from './FormErrorMessages';

const outerPropTypes = {
  id: PropTypes.string,
};

// export this connected version so we can pass the 'form' prop ourselves
export const ConnectedFormErrorMessages = connect(
  () => {
    const getErrorsForFields = makeGetErrorsForFields();
    const getGeneralFormError = makeGetGeneralFormError();

    return (state, props) => ({
      errors: getErrorsForFields(state.enhancedForm.validation, props),
      generalError: getGeneralFormError(state.enhancedForm, props),
    });
  },
  { scrollToElement },
)(FormErrorMessages);

ConnectedFormErrorMessages.propTypes = {
  fields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]),
  showGeneral: PropTypes.bool,
  form: PropTypes.string.isRequired,
};

ConnectedFormErrorMessages.defaultProps = {
  fields: [],
  showGeneral: false,
};

const ErrorsProvider = props => {
  const { form } = useContext(ReduxFormContext);
  return <ConnectedFormErrorMessages {...props} form={form} />;
};

ErrorsProvider.propTypes = {
  ...outerPropTypes,
  fields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]),
  showGeneral: PropTypes.bool,
};

export default ErrorsProvider;
