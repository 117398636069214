/* global WP_DEFINE_DEVELOPMENT */
import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { actionTypes } from '@mediamonks/react-redux-component-init';
import { createEpicMiddleware } from 'redux-observable';
import persistStore from 'redux-persist/lib/persistStore';
import thunk from 'redux-thunk';
import serviceConfig from '../../app/config/service.configdefinitions';
import checkFSA from '../../app/util/checkFSAMiddleware';
import clientAuthenticationReduxMiddleware from './auth/clientAuhenticationReduxMiddleware';
import { asyncAction } from '../../app/util/asyncActionMiddleware';
import enhancedReduxFormMiddleware from '../../app/enhanced-redux-form/enhancedReduxFormMiddleware';
import { getClient } from '../../app/util/sentry/sentry-client';
import createSentryMiddleware from '../../app/util/sentry/middleware';
import reduxConfig from '../../app/config/redux.configdefinitions';
import reduxListenersMiddleware from '../../app/util/redux-listeners-middleware';
import * as reduxPersistTransformFilters from '../../app/config/reduxPersistTransformFilters';
import { KEY_PREFIX } from '../../app/data/persistCookieSettings';
import ReduxPersistClientCookieStorage from './ReduxPersistClientCookieStorage';
import pageLoadedMiddleware from '../../app/util/tracking/pageLoadedMiddleware';
import seoMiddleware from '../../app/util/seoMiddleware';
import { actionLoggerMiddleware } from './react-render-perf/actionLogger';

const createStoreInstance = (
  reducer,
  history,
  reduxListenersSetup = [],
  rootEpic = null,
  functionalCookiesEnabled,
) => {
  /* eslint-disable dot-notation */
  const middleware = [
    asyncAction,
    thunk,
    // asyncTrackingMiddleware, // disable until we can batch the tracking
    reduxListenersMiddleware(reduxListenersSetup),
    pageLoadedMiddleware,
    seoMiddleware,
    routerMiddleware(history),
    enhancedReduxFormMiddleware,
  ];
  if (rootEpic) {
    middleware.push(createEpicMiddleware(rootEpic));
  }
  if (reduxConfig.useSentryMiddleware) {
    middleware.unshift(createSentryMiddleware(getClient));
  }
  if (WP_DEFINE_DEVELOPMENT) {
    middleware.push(checkFSA);
  }
  if (serviceConfig.useClientAuthentication) {
    middleware.push(clientAuthenticationReduxMiddleware);
  }
  if (WP_DEFINE_DEVELOPMENT && localStorage.getItem('reactRenderPerf')) {
    middleware.push(actionLoggerMiddleware);
  }

  const composeEnhancers =
    (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] &&
      window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
        maxAge: 200,
        actionsDenylist: [
          '@@redux-form/REGISTER_FIELD',
          '@@redux-form/UNREGISTER_FIELD',
          actionTypes.INIT_COMPONENT,
        ],
      })) ||
    compose;
  const additionalEnhancers = [];
  const storeEnhancer = composeEnhancers(applyMiddleware(...middleware), ...additionalEnhancers);
  const store = createStore(reducer, JSON.parse(window['state']), storeEnhancer);

  if (reduxConfig.persistKeys.length && functionalCookiesEnabled) {
    const userId = store.getState().authentication?.userPermissionState?.userId;
    const userIdString = userId ? `${userId}.` : '';
    const keyPrefix = `${KEY_PREFIX}${userIdString}`;

    persistStore(store, {
      storage: new ReduxPersistClientCookieStorage(),
      transforms: reduxConfig.persistFilters.map(key => reduxPersistTransformFilters[key]),
      whitelist: reduxConfig.persistKeys,
      keyPrefix,
    });
  }
  return store;
  /* eslint-enable dot-notation */
};

export default createStoreInstance;
