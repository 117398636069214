import { connect } from 'react-redux';
import { compose } from 'redux';
import { getConfirmEmail } from 'common/src/app/actions/resources/accountActions';
import ConfirmedEmail from './ConfirmedEmail';

const mapDispatchToProps = {
  getConfirmEmail,
};

const connected = connect(null, mapDispatchToProps);

export default compose(connected)(ConfirmedEmail);
