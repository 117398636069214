import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import CmsDirectionType from 'common/src/app/data/enum/CmsDirectionType';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';
import RichTextBlock from '../RichTextBlock';
import TextNew, { ElementTypes } from '../../../../../atoms/TextNew';
import Icon from '../../../../../atoms/Icon';
import BackgroundImageWrapper from '../../../../../atoms/BackgroundImageWrapper';
import './rich-text-with-image-block.scss';

/**
 * Rich text with image block
 */

const renderMobileBackground = deviceState => {
  if (deviceState <= DeviceState.MD) {
    return <Icon width={268} name={IconName.MOBILE_SUPPORT_ARROW} />;
  }
  return null;
};

const RichTextWithImageBlock = (
  {
    title,
    text,
    image,
    backgroundImage,
    deviceState,
    imageVerticalPosition,
    imageHorizontalPosition,
  },
  context,
  className,
  dataTestId,
) => (
  <div
    className={classNames(
      className,
      `align-image-${imageVerticalPosition}-${imageHorizontalPosition}`.toLowerCase(),
    )}
    data-testid={dataTestId}
  >
    <BackgroundImageWrapper
      image={deviceState >= DeviceState.XL && backgroundImage ? backgroundImage.src : ''}
    >
      {backgroundImage ? renderMobileBackground(deviceState) : null}
      {title && !backgroundImage ? (
        <TextNew.Loud cid="title" key="title" element={ElementTypes.H2}>
          {title}
        </TextNew.Loud>
      ) : null}
      {image ? (
        <ResponsiveImage
          breakpoints={{
            768: { vw: 100, ratio: 1 },
            other: { px: 488 },
          }}
          src={image.src}
          alt={image.alt}
        />
      ) : null}

      {backgroundImage ? (
        <div className="tile">
          <TextNew.Loud element={ElementTypes.H2}>{title}</TextNew.Loud>
          <RichTextBlock text={text} />
        </div>
      ) : (
        [<RichTextBlock key="body" text={text} />]
      )}
    </BackgroundImageWrapper>
  </div>
);

RichTextWithImageBlock.defaultProps = {
  imageVerticalPosition: CmsDirectionType.TOP,
  imageHorizontalPosition: CmsDirectionType.LEFT,
};

RichTextWithImageBlock.propTypes = {
  /**
   * The title content (HTML)
   */
  title: PropTypes.string.isRequired,
  /**
   * The main copy (HTML)
   */
  text: PropTypes.string.isRequired,
  /**
   * The image content
   */
  image: PropTypes.shape(imageShape),
  backgroundImage: PropTypes.shape(imageShape),
  deviceState: PropTypes.number,
  /**
   * Image horizontal position in 2-column layout (non-mobile)
   */
  imageVerticalPosition: PropTypes.oneOf([CmsDirectionType.TOP, CmsDirectionType.BOTTOM]),
  /**
   * Image vertical position in 2-column layout (non-mobile)
   */
  imageHorizontalPosition: PropTypes.oneOf([CmsDirectionType.LEFT, CmsDirectionType.RIGHT]),
};

const RichTextWithImageBlockWithClassName = withFunctionalClassName(
  ComponentType.ATOM,
  'rich-text-with-image-block',
)(RichTextWithImageBlock);

export default withDeviceState()(RichTextWithImageBlockWithClassName);
