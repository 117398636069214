/* global 'page','RecoverEmail' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import RegistrationTemplate from 'components/organisms/RegistrationTemplate';

import './recover-email.scss';

const RecoverEmail = (
  {
    children,
    stepPaths,
    lastMountedStepIndex,
    publicHost,
    deviceState,
    background,
    isSuccessStepIndex,
  },
  { getMessage },
  className,
) => {
  if (isSuccessStepIndex) {
    lastMountedStepIndex = stepPaths.length; // eslint-disable-line no-param-reassign
  }

  const pageDetails =
    stepPaths.length > 0 &&
    [
      { title: getMessage('recoverEmail.steps.recoverEmailDOB') },
      { title: getMessage('recoverEmail.steps.securityQuestion') },
      { title: getMessage('recoverEmail.steps.revealEmail') },
    ].map((data, index) => ({ ...data, url: stepPaths[index] }));

  const regTemplate = (
    <RegistrationTemplate
      publicHost={publicHost}
      children={children}
      pageDetails={pageDetails}
      lastMountedStepIndex={lastMountedStepIndex}
    />
  );

  return deviceState >= DeviceState.LG ? (
    <BackgroundImageWrapper className={className} image={background && background}>
      {regTemplate}
    </BackgroundImageWrapper>
  ) : (
    <div className={className}>{regTemplate}</div>
  );
};

RecoverEmail.propTypes = {
  background: ImagePropTypes,
  isSuccessStepIndex: PropTypes.bool,
  children: PropTypes.node,
  stepPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
  lastMountedStepIndex: PropTypes.number.isRequired,
  publicHost: PropTypes.string.isRequired,
  deviceState: PropTypes.number,
};

RecoverEmail.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withDeviceState()(withFunctionalClassName('page','RecoverEmail')(RecoverEmail));
