/**
 * __important: __ The Shop uses the keys in this object to map locale messages. Please don't
 * change the names of the keys in this map.
 */
export default {
  SUCCESS: 0,
  REFUSED: 1,
  CANCELLED: 2,
  PENDING: 3,
  ERROR: 4,
};
