import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import LocaleMessage from '../../atoms/LocaleMessage';
import Input, { descriptorTypes } from '../../atoms/Input';
import Select from '../../atoms/Select';
import Row from '../../atoms/Row';

import './height-imperial-field-group.scss';

const FEET_LENGTH = 1;
const INCHES_LENGTH = 2;

const HeightImperialFieldGroup = ({ abbreviation, disabled }, { getMessage }) => (
  <Row className={functionalComponentClassName(ComponentType.MOLECULE, 'HeightImperialFieldGroup')}>
    <EnhancedField
      component={Input}
      name={getMessage('general.measureUnit.height.feet.label')}
      id="_composite-input-height.feet"
      type="number"
      min="0"
      max="9"
      ariaLabel={getMessage('general.measureUnit.height.feet.label')}
      placeholder="0"
      placeholderAbbreviation={
        <LocaleMessage
          id={`general.measureUnit.height.feet.${abbreviation ? 'labelAbbreviation' : 'label'}`}
        />
      }
      descriptorType={descriptorTypes.STATIC}
      onKeyPress={event => handleFieldGroupInput(event, FEET_LENGTH)}
      disabled={disabled}
    />
    <EnhancedField
      component={Input}
      name={getMessage('general.measureUnit.height.inches.label')}
      id="_composite-input-height.inches"
      type="number"
      min="0"
      max="12"
      ariaLabel={getMessage('general.measureUnit.height.inches.label')}
      placeholder="00"
      placeholderAbbreviation={
        <LocaleMessage
          id={`general.measureUnit.height.inches.${abbreviation ? 'labelAbbreviation' : 'label'}`}
        />
      }
      descriptorType={descriptorTypes.STATIC}
      onKeyPress={event => handleFieldGroupInput(event, INCHES_LENGTH)}
      disabled={disabled}
    />
    <EnhancedField
      component={Select}
      name="halfInch"
      customLabel="Half Inch"
      type="number"
      options={[
        {
          title: '0',
          value: 0,
          ariaLabel: getMessage('general.measureUnit.height.inches.labelZeroInch'),
        },
        {
          title: '½',
          value: 0.5,
          ariaLabel: getMessage('general.measureUnit.height.inches.labelHalfInch'),
        },
      ]}
      disabled={disabled}
      value={0}
    />
  </Row>
);

HeightImperialFieldGroup.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

HeightImperialFieldGroup.propTypes = {
  disabled: PropTypes.bool,
  abbreviation: PropTypes.bool,
};

export default HeightImperialFieldGroup;
