const termsAgreement = {
  online: {
    termsOfUse: {
      url: '/terms-of-use',
      includeLinkToConfirmPrivacyPromise: '1',
      button: '1',
      postButtononline: '1',
    },
    receiveSupportEmails: {
      preAgreement: '1',
    },
  },
  group: {
    termsOfUse: {
      url: '/terms-of-use',
      includeLinkToConfirmPrivacyPromise: '1',
      button: '1',
      postButtononline: '1',
    },
    receiveSupportEmails: {
      preAgreement: '1',
    },
    receiveConsultantSupportEmails: {
      preAgreement: '1',
    },
  },
  free2Go: {
    termsOfUse: {
      url: '/terms-of-use',
      includeLinkToConfirmPrivacyPromise: '1',
      button: '1',
    },
  },
};

export default termsAgreement;
