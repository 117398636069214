const SearchFields = {
  QUERY: 'keywords',
  BOOKMARKED: 'bookmarked',
  CATEGORY: 'category',
  SORT: 'sort',
  STATUS: 'status',
  SINCE_ID: 'sinceId',
};

export default SearchFields;
