import { connect } from 'react-redux';
import LegacyLogin from './LegacyLogin';

export default connect(state => {
  const queryString = state?.routeHistory?.[1]?.query?.ReturnUrl;
  const appReferral = new URLSearchParams(queryString).get('redirect_uri');

  return {
    isWebView: state?.config?.isWebView,
    appReferral,
  };
})(LegacyLogin);
