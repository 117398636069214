/**
 * Strips Draft.js markup from a string value.
 * E.g. '[r]The text value[/r]' would become 'The text value'
 * @param {string} value The string to remove Draft.js markup from
 * @returns The string value with Draft.js markup stripped
 */
export default value => {
  if (!value || typeof value !== 'string') return value;

  const draftJsRegex = /\[\/?\w+\]/g;
  const allMatches = value.match(draftJsRegex);

  if (!Array.isArray(allMatches)) return value;

  const filteredMatches = allMatches.filter(currentMatch => {
    const pairTag = currentMatch.startsWith('[/')
      ? `[${currentMatch.slice(2)}`
      : `[/${currentMatch.slice(1)}`;
    return allMatches.includes(pairTag);
  });

  return filteredMatches.reduce((returnString, currentMatch, index) => {
    const isLastItem = filteredMatches.length === index + 1;
    return returnString.replace(currentMatch, currentMatch === '[/r]' && !isLastItem ? '\\n' : '');
  }, value);
};
