export const DEFAULT_MAX_LENGTH = 64;

export const defaultPasswordValidationConfig = {
  maxLength: DEFAULT_MAX_LENGTH,
  requireDigit: true,
  requiredLength: 12,
  requiredUniqueCharacters: 1,
  requireLetter: true,
  requireLowerCase: false,
  requireNonAlphanumeric: false,
  requireUppercase: false,
};
