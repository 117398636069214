import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import BlockingFormError from './BlockingFormError';

const outerPropTypes = {
  /**
   * The field names to check for error codes in the validation
   */
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * If this errorCode is present in one of the fields specified in 'fields', this
   * error will display
   */
  errorCode: PropTypes.string.isRequired,
};

const ConnectedBlockingFormError = connect(() => {
  const getErrorsForFields = makeGetErrorsForFields();

  return (state, { showError, ...props }) => {
    const errors = getErrorsForFields(state.enhancedForm.validation, props);

    return {
      showError: !!errors.find(error => error.code === props.errorCode) || showError,
    };
  };
}, null)(BlockingFormError);

ConnectedBlockingFormError.propTypes = {
  ...outerPropTypes,
  form: PropTypes.string.isRequired,
};

const FormNameProvider = props => {
  const { form } = useContext(ReduxFormContext);
  return <ConnectedBlockingFormError {...props} form={form} />;
};
FormNameProvider.propTypes = outerPropTypes;

export default FormNameProvider;
