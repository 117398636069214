import { prepareComponent, withInitAction } from '@mediamonks/react-redux-component-init';
import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import InvitationError from './InvitationError';
import ApplicationError from '../ApplicationError';

const connected = connect(state => ({
  publicHost: state.config.environmentConfig.web.public.host,
}));

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(prepareComponent(ApplicationError)),
});

export default compose(addInitAction, connected)(InvitationError);
