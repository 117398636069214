import { getClient } from '../../util/sentry/sentry-client';

export type FieldValidationErrorType = {
  field: string;
  code: string;
  message: string;
};

export type ParsedResponseErrorObjectType = {
  code?: string;
  message?: string;
  fields?: FieldValidationErrorType[];
};

export type ParsedResponseType = {
  message?: string;
  error: string | ParsedResponseErrorObjectType;
};

export type GatewayRequestType = {
  url: string;
  method: string;
  mode: unknown;
  redirect: unknown;
  body: unknown;
  headers: unknown;
  credentials: unknown;
};

class GatewayError extends Error {
  request: GatewayRequestType;

  response: {
    status: number;
    text?: string;
    parsed?: ParsedResponseType;
    ok: boolean;
  };

  /**
   * Create a gateway error.
   * @param {string} message - The error message.
   * @param {Object | null} [requestOptions] - Optional. The request options that cause the error.
   * @param {boolean} responseOk - Was the response successful (200 status)
   * @param {number} responseStatus - The HTTP status code of the response.
   * @param {string} responseText - The textual response.
   * @param {Object} parsedResponse - The parsed response body (JSON).
   */
  constructor(
    message: string,
    requestOptions: GatewayRequestType,
    responseOk: boolean,
    responseStatus: number,
    responseText?: string,
    parsedResponse?: ParsedResponseType,
  ) {
    super(message);

    this.name = 'GatewayError';

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, new.target);
    }

    this.request = requestOptions;
    this.response = {
      status: responseStatus,
      text: responseText,
      parsed: parsedResponse,
      ok: responseOk,
    };

    const sentryClient = getClient();
    sentryClient?.addBreadcrumb({
      type: 'debug',
      category: 'gateway',
      message: 'New GatewayError',
      level: 'warning',
      data: {
        requestMethod: requestOptions.method,
        requestUrl: requestOptions.url,
        requestBody: requestOptions.body,
        responseStatus,
        responseBody: responseText?.substring(0, 1024),
        errorEncountered: `${message}`,
      },
    });
  }
}

export default GatewayError;
