/**
 * Check an object (valuesToCheck) against another object of keys
 * only returning those values that have a key in the keys object, and that have a value
 * 
 * In order to transform larger objects of data with extra information we don't need
 * e.g. the whole of a users account object only getting addressfields to set as initial values
 *
 * @example
 * keys: {
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    postcode: 'postcode',
 * }
 * values: {
    postcode: 's7 80h',
    addressLine1: null,
    name: 'joe',
 *  }
 * @returns { postcode: 's7 80h' }
 */

const returnOnlyPresentValues = (keys, valuesToCheck) => {
  let values = {};

  Object.keys(keys).map(item => {
    const key = keys[item];
    const value = valuesToCheck[keys[item]];

    if (value && Object.keys(values).length !== 0) {
      return (values = {
        [key]: value,
        ...values,
      });
    }
    if (value) {
      return (values = { [key]: value });
    }
    return null;
  });
  return values;
};

export default returnOnlyPresentValues;
