import { connect } from 'react-redux';
import free2GoAppReleasedEnum from 'common/src/app/data/free2GoAppReleasedEnum';

import SuccessfullyRegisteredFree2Go from './SuccessfullyRegisteredFree2Go';

const mapStateToProps = state => ({
  androidAppUrl: state.config.environmentConfig.free2GoApp.androidAppUrl,
  iosAppUrl: state.config.environmentConfig.free2GoApp.iosAppUrl,
  free2GoAppIsReleased:
    state.config.environmentConfig.free2GoApp.isReleased === free2GoAppReleasedEnum.RELEASED,
});

export default connect(mapStateToProps)(SuccessfullyRegisteredFree2Go);
