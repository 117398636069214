import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { API_COMPONENT_PUBLIC_NAVIGATION_REGISTER_CARD } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { contentComponentSelector } from 'common/src/app/selectors/publicSelectors';
import PublicNavigationLinks from './PublicNavigationLinks';

const mapStateToProps = state => ({
  publicHost: state.config.environmentConfig.web.public.host,
  accountHost: state.config.environmentConfig.web.account.host,
  showMenu: state.view.components.header?.showMenu,
  cardImage: contentComponentSelector(
    state,
    API_COMPONENT_PUBLIC_NAVIGATION_REGISTER_CARD,
    'image',
  ),
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_PUBLIC_NAVIGATION_REGISTER_CARD)),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(PublicNavigationLinks);
