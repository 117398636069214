import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import CompositeInput from 'common/src/app/enhanced-redux-form/components/CompositeInput';

import MeasureUnit from 'common/src/app/data/enum/MeasureUnit';
import MeasureType from 'common/src/app/data/enum/MeasureType';

import compositeInputFormatters from 'common/src/app/enhanced-redux-form/compositeInputFormatters';
import { VALIDATE_AND_CHANGE } from 'common/src/app/enhanced-redux-form/data/ComposeOn';

import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';

import CSSTransition from '../../molecules/CSSTransition';
import HeightImperialFieldGroup from '../../molecules/HeightImperialFieldGroup';
import HeightMetricFieldGroup from '../../molecules/HeightMetricFieldGroup';
import WeightMetricFieldGroup from '../../molecules/WeightMetricFieldGroup';
import WeightImperialFieldGroup from '../../molecules/WeightImperialFieldGroup';

import './measurement-composite-input.scss';

/*
 *  Complete Measurement component.
 *  Take any one of the WeightMeasurementUnit / HeightMeasurementUnit
 *  ENUMS, and returns the correct component with correct formater.
 *
 *  Will take values from the FormValueProvider (weightUnit, heightUnit),
 *  or a predefined prop 'existingMeasurementPref'
 *
 *  Component can be set to animate when a new choice is passed in, via the prop animate
 */

const MeasurementCompositeInput = ({
  animated,
  name,
  unit,
  abbreviationType,
  type,
  standoutStyle,
  deviceState,
  disabled,
  hidePlaceholder,
  hideStonesUsePounds,
  ...props
}) => {
  let measurementComponent;
  let formatter;
  let abbreviate;
  let selectArrowType;

  // If standout style is set change the type of dropdown appear
  selectArrowType = standoutStyle
    ? (selectArrowType = 'chevron-large')
    : (selectArrowType = Configuration.selectIconName);
  // Set abbreviation based on viewport width
  if (abbreviationType === 'shortToLong') {
    abbreviate = deviceState <= DeviceState.MD;
  } else if (abbreviationType === 'longToShort') {
    abbreviate = deviceState >= DeviceState.MD;
  }
  // Assign correct measurement input based on type passed
  if (type === MeasureType.WEIGHT && unit === MeasureUnit.IMPERIAL) {
    // Hide stones from the input and use the pounds version

    formatter =
      Configuration.imperialWeightUseStones && !hideStonesUsePounds
        ? compositeInputFormatters.WEIGHT_STONES_POUNDS_TO_KILO
        : compositeInputFormatters.WEIGHT_POUNDS_TO_KILO;

    measurementComponent = (
      <WeightImperialFieldGroup
        abbreviate={abbreviate}
        key={0}
        disabled={disabled}
        selectArrowType={selectArrowType}
        hidePlaceholder={hidePlaceholder}
        hideStonesUsePounds={hideStonesUsePounds}
        {...props}
      />
    );
  } else if (type === MeasureType.WEIGHT && unit === MeasureUnit.METRIC) {
    formatter = compositeInputFormatters.WEIGHT_KILO;
    measurementComponent = (
      <WeightMetricFieldGroup
        abbreviate={abbreviate}
        disabled={disabled}
        key={2}
        hidePlaceholder={hidePlaceholder}
        {...props}
      />
    );
  } else if (type === MeasureType.HEIGHT && unit === MeasureUnit.IMPERIAL) {
    formatter = compositeInputFormatters.HEIGHT_FEET_TO_CM;
    measurementComponent = (
      <HeightImperialFieldGroup key={3} disabled={disabled} {...props} abbreviation={abbreviate} />
    );
  } else if (type === MeasureType.HEIGHT && unit === MeasureUnit.METRIC) {
    formatter = compositeInputFormatters.HEIGHT_CM;
    measurementComponent = (
      <HeightMetricFieldGroup key={4} disabled={disabled} {...props} abbreviation={abbreviate} />
    );
  } else {
    throw new Error("MeasurementCompositeInput wasn't passed any values.");
  }

  return (
    <div
      className={functionalComponentClassName(
        ComponentType.MOLECULE,
        'MeasurementCompositeInput',
        null,
        { 'standout-style': standoutStyle },
      )}
    >
      <CompositeInput
        abbreviationType={abbreviationType}
        name={name}
        formatter={formatter}
        composeOn={VALIDATE_AND_CHANGE}
      >
        {animated ? (
          <CSSTransition animateIn={true} animationType="fade">
            {measurementComponent}
          </CSSTransition>
        ) : (
          measurementComponent
        )}
      </CompositeInput>
    </div>
  );
};

MeasurementCompositeInput.defaultProps = {
  abbreviationType: 'shortToLong',
};

MeasurementCompositeInput.propTypes = {
  /*
  * If set shortToLong, the abbreviation
  will be used on mobile but not desktop and vise versa
  */
  abbreviationType: PropTypes.oneOf(['shortToLong', 'longToShort']),
  type: PropTypes.oneOf([MeasureType.HEIGHT, MeasureType.WEIGHT]),
  unit: PropTypes.oneOf([MeasureUnit.IMPERIAL, MeasureUnit.METRIC]),
  name: PropTypes.string,
  /*
   * Large measurement input style(used within weigh-in). By default the measurement input
   * does not have any addtional styles
   */
  standoutStyle: PropTypes.bool,
  animated: PropTypes.bool,
  deviceState: PropTypes.number,

  // Allow input fields to be disabled
  disabled: PropTypes.bool,
  // Allow the place holders to be hidden
  hidePlaceholder: PropTypes.bool,
  // Allow the stones input to be hidden
  hideStonesUsePounds: PropTypes.bool,
};
export default withDeviceState()(MeasurementCompositeInput);
