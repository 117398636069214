import { handleActions } from 'redux-actions';
import { PERSIST_HIDE_NAV } from '../actions/resources/navigationActions';

const initialState = {};

export default handleActions(
  {
    [PERSIST_HIDE_NAV]: (state, { payload }) => ({
      ...state,
      hideNav: payload,
    }),
  },
  initialState,
);
