import moment from 'moment';
import ageRanges from 'common/src/app/data/enum/AgeRanges';

/**
 * can take a complete ISO-8601 date like '2011-02-02T00:00:00Z' @returns number
 */
const getCurrentAge = value => moment().diff(value, 'years');

export const isAgeOver = (value, checkValue) => getCurrentAge(value) >= checkValue;
export const isAgeUnder = (value, checkValue) => getCurrentAge(value) <= checkValue;

export const isWithinAgeRange = (value, minRequiredAge, maxRequiredAge) =>
  isAgeOver(value, minRequiredAge) && isAgeUnder(value, maxRequiredAge);

export const isEligibleToRegisterAsAFree2GoMember = value =>
  isAgeOver(value, ageRanges.GROUP.FREE_2_GO_MIN) &&
  isAgeUnder(value, ageRanges.GROUP.FREE_2_GO_MAX);

export default getCurrentAge;
