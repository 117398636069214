import compose from 'redux/lib/compose';
import { connect } from 'react-redux';

import { getPackages, clearPackageField } from 'common/src/app/actions/resources/shopActions';

import RegionPicker from './RegionPicker';

const mapDispatchToProps = dispatch => ({
  getPackagesForRegion: regionCode => {
    dispatch(clearPackageField());
    dispatch(getPackages({ regionCode }));
  },
});

const connector = connect(null, mapDispatchToProps);

export default compose(connector)(RegionPicker);
