const MeasureUnit = {
  IMPERIAL: 0,
  METRIC: 1,
};

export const MeasureUnitString = {
  IMPERIAL: `0`,
  METRIC: `1`,
};

export default MeasureUnit;
