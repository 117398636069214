import PropTypes from 'prop-types';
import priceShape from './apiEntities/price';

const propType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.shape(priceShape),
  discount: PropTypes.shape({
    amount: PropTypes.number,
    isRelative: PropTypes.bool,
    packageIds: PropTypes.array,
  }),
});

export default propType;
