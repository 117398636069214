import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import {
  getIrishCounties,
  GET_IRISH_COUNTIES,
} from 'common/src/app/actions/resources/irishCountyActions';
import { addressFields, shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { IRISH_COUNTIES } from 'common/src/app/data/entityTypes';
import { makeCollectionSelector } from 'common/src/app/selectors/collectionSelector';
import { countryCode } from 'common/src/app/data/enum/Countries';
import FormNames from 'common/src/app/data/enum/FormNames';
import countries from 'common/src/app/data/countrydata';
import voucherDeliveryCountriesData from 'common/src/app/data/voucherDeliveryCountriesData';
import { regionCode as region } from 'common/src/app/data/enum/Regions';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';

import AddressForm from './AddressForm';

const mapStateToProps = () => {
  const collectionSelector = makeCollectionSelector();

  return (state, { formName, country, voucherDelivery }) => {
    const countryFormSelector = formValueSelector(formName);
    const customCountry =
      country || countryFormSelector(state, addressFields.COUNTRY) || marketConfig.defaultCountry;
    const list = collectionSelector(state, { collectionId: IRISH_COUNTIES }).entities;
    const irishCounties = list.map(({ title }) => ({ title, value: title }));

    const checkoutFormSelector = formValueSelector(FormNames.SUMMARY);
    const selectedRegionCode = checkoutFormSelector(state, shopFields.REGION);

    const isLoadingIrishCounties = makeIsLoadingSelector(GET_IRISH_COUNTIES);

    // If UK or Europe is selected we need to disable the United States drop down option
    let modifiedCountries;

    if (selectedRegionCode === region.UK || selectedRegionCode === region.EUROPE) {
      modifiedCountries = countries.reduce((acc, cur) => {
        if (cur.value === 'US') {
          cur.disabled = true; // eslint-disable-line no-param-reassign
        }
        acc.push(cur);
        return acc;
      }, []);
    }

    return {
      irishCounties,
      country: customCountry,
      countries: voucherDelivery ? voucherDeliveryCountriesData : modifiedCountries || countries,
      isLoading: isLoadingIrishCounties(state),
    };
  };
};

const mapDispatchToProps = (dispatch, { formName, country, fieldNamePrefix }) => ({
  getIrishCounties: () => dispatch(getIrishCounties()),
  handleCountryChange: () => {
    if (country === countryCode.IRELAND) {
      dispatch(
        change(
          formName,
          fieldNamePrefix !== undefined
            ? `${fieldNamePrefix}${addressFields.COUNTY}`
            : addressFields.COUNTY,
          '',
        ),
      );
      dispatch(
        change(
          formName,
          fieldNamePrefix !== undefined
            ? `${fieldNamePrefix}${addressFields.COUNTY_IE}`
            : addressFields.COUNTY_IE,
          '',
        ),
      );
    }

    if (country !== countryCode.US) {
      dispatch(
        change(
          formName,
          fieldNamePrefix !== undefined
            ? `${fieldNamePrefix}${addressFields.STATE}`
            : addressFields.STATE,
          '',
        ),
      );
    }
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose(connector)(AddressForm);
