/* eslint-disable import/prefer-default-export */

export const API_COMPONENT_COMMUNITY_HEADER = 'community-header-image';
export const API_COMPONENT_MEMBER_PODCAST_HEADER = 'member-podcast-header-image';
export const API_COMPONENT_MY_SLIMMING_GROUP_HEADER = 'my-slimmingworld-group-header';
export const API_COMPONENT_COMING_SOON_HEADER = 'feature-coming-soon-image';
export const API_COMPONENT_FOOTER = 'footer';
export const API_COMPONENT_MEMBER_NAVIGATION = 'header';
export const API_COMPONENT_ACCOUNT_LOGIN_BACKGROUND = 'account-login-background';
export const API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND = 'online-registration-background';
export const API_COMPONENT_STATIC_PAGE_BACKGROUND = 'static-page-background';
export const API_COMPONENT_CONTACT_BACKGROUND = 'contact-us-background';
export const API_COMPONENT_APPLICATION_ERROR_BACKGROUND = 'application-error-background';
export const API_COMPONENT_PROFILE_BUILDER_BACKGROUND = 'profile-builder-background';
export const API_COMPONENT_GROUP_ONBOARDER_BACKGROUND = 'group-onboarder-background';
export const API_COMPONENT_APP_SIGNUP_BACKGROUND = 'food-optimising-diary-app-sign-up-background';
export const API_COMPONENT_ACTION_PLAN_BACKGROUND = 'actionplan-background-image';
export const API_COMPONENT_MEMBER_SITE_APP_PROMO = 'member-site-app-promo';
export const API_COMPONENT_FREE2GO_LANDING_BACKGROUND = 'free2go-background-image';
export const API_COMPONENT_EXPIRED_MEMBER_BACKGROUND = 'expired-member-background-image';
export const API_COMPONENT_EXPIRED_MEMBER_MOBILE = 'expired-member-mobile';

// Public image components
export const API_COMPONENT_PUBLIC_MAGAZINE_UPSELL = 'public-magazine-upsell';
export const API_COMPONENT_PUBLIC_ICELAND_UPSELL = 'public-iceland-upsell';
export const API_COMPONENT_ICELAND_PROMO_BLOCK = 'iceland-promo-block';
export const API_COMPONENT_PUBLIC_BMICALC_UPSELL = 'public-bmi-calc-upsell';
export const API_COMPONENT_PUBLIC_ICELAND_BONUS_CARD = 'iceland-bonus-card';
export const API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IR = 'public-iceland-upsell-ir';
export const API_COMPONENT_PUBLIC_ICELAND_DEAL_COMING_SOON =
  'iceland-freebie-deal-coming-soon-banner';
export const API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IE = 'public-iceland-upsell-ir';
export const API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_IE_PAST =
  'public-iceland-upsell-ir-old-offer';
export const API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION = 'public-iceland-product-promotion';
export const API_COMPONENT_PUBLIC_ICELAND_PRODUCT_PROMOTION_PAST =
  'public-iceland-upsell-old-offer';
export const API_COMPONENT_PUBLIC_ICELAND_FOOD_WAREHOUSE_LOGO =
  'public-iceland-food-warehouse-logo';
export const API_COMPONENT_PUBLIC_NO_GROUPS = 'public-no-groups-image';
export const API_COMPONENT_MENTAL_HEALTH_PROMOTION = 'mental-health-promotion';
export const API_COMPONENT_2020_SUCCESS_BOOK = '2020-success-book';
export const API_COMPONENT_PUBLIC_SEVEN_DAY_MENU_SIGNUP_IMAGE = 'public-seven-day-menu-signup';
export const API_COMPONENT_OBESITY_CAMPAIGN_BACKGROUND = 'obesity-campaign-background';

// Public headers images
export const API_COMPONENT_PUBLIC_HEADER_RECIPES = 'public-header-recipes';
export const API_COMPONENT_PUBLIC_HEADER_HOW_IT_WORKS = 'public-header-how-it-works';
export const API_COMPONENT_PUBLIC_HEADER_COMPARISON = 'public-header-comparison';
export const API_COMPONENT_PUBLIC_HEADER_ONLINE = 'public-header-online';
export const API_COMPONENT_PUBLIC_HEADER_OFFERS = 'public-header-special-offers';
export const API_COMPONENT_PUBLIC_HEADER_MENU = 'public-header-seven-day-menu';
export const API_COMPONENT_PUBLIC_HEADER_MAGAZINE = 'public-header-magazine';
export const API_COMPONENT_PUBLIC_HEADER_WHAT_HAPPENS_IN_GROUP =
  'public-header-what-happens-in-group';

export const API_COMPONENT_PUBLIC_FOOTER = 'public-footer';
export const API_COMPONENT_PUBLIC_HEADER_SCIENCE = 'public-header-the-science';
export const API_COMPONENT_PUBLIC_NAVIGATION_REGISTER_CARD = 'public-navigation-register-card';
export const API_COMPONENT_PUBLIC_HOMEPAGE_ARTICLE = 'public-homepage-article-promotion';
export const API_COMPONENT_CONTACT_US_BACKGROUND = 'contact-us-new-background-image';
export const API_COMPONENT_MEMBER_PUBLICITY_ENTRY_OVERVIEW_BACKGROUND =
  'member-publicity-entry-overview-background';
export const API_COMPONENT_MAGAZINE_COMPETITION_BACKGROUND = 'magazine-competitions';

export const API_COMPONENT_ICELAND_INFORMATION_SHEET = 'iceland-information-sheet';

// Re-activation
export const API_COMPONENT_REACTIVATION_BACKGROUND_IMAGE = 'reactivation-background';

export const API_COMPONENT_GROUP_TO_ONLINE_LANDING_HEADER = 'reactivation-landing-header';

// Shop - Delivery information - (includes delivery delay and address check information)
export const API_COMPONENT_SHOP_DELIVERY_DELAY_AND_ADDRESS_INFORMATION =
  'shop-delivery-information';

// Diabetes UK
export const API_COMPONENT_DIABETES_UK_LOGO = 'diabetes-uk-logo';
