import React from 'react';
import classNames from 'classnames';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import SubscriptionPackages from 'common/src/app/enums/SubscriptionPackages';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import LocaleMessage from 'components/atoms/LocaleMessage';

import './package-indicator.scss';

const PackageIndicator = (
  { subscriptionPackage }: { subscriptionPackage: SubscriptionPackages },
  _context: any,
  className: string,
) => (
  <>
    <i
      className={classNames(className, subscriptionPackage)}
      data-testid={`package-indicator-${subscriptionPackage}`}
      aria-hidden="true"
    />
    <span className="screen-reader">
      <LocaleMessage id="packageIndicator.altText" params={{ PACKAGE: subscriptionPackage }} />
    </span>
  </>
);

export default withFunctionalClassName(ComponentType.ATOM, 'PackageIndicator')(PackageIndicator);
