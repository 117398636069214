import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import * as dateFormats from 'common/src/app/data/enum/dateFormats';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Configuration from 'common/src/app/config/Configuration';
import { handleFieldGroupInput } from 'common/src/app/util/form/inputEventUtil';
import Input from '../../atoms/Input';
import './date-field-group.scss';

const DAY_LENGTH = 2;
const MONTH_LENGTH = 2;
const YEAR_LENGTH = 4;
/**
 * 3 enhancedFields (dd/mm/yy or yyyy) date component, which can be reordered for market
 * this is set in marketConfig (located packages/common/src/app/config/market.whatever.json)
 */
const DateFieldGroup = (props, context, className) => {
  const fields = {
    DD: (
      <EnhancedField
        component={Input}
        placeholder="DD"
        min="1"
        max="31"
        type="number"
        id="_composite-input-dateOfBirth.date"
        name="date"
        key="date"
        onKeyPress={event => handleFieldGroupInput(event, DAY_LENGTH)}
        suppress
      />
    ),
    MM: (
      <EnhancedField
        component={Input}
        placeholder="MM"
        min="1"
        max="12"
        type="number"
        id="_composite-input-dateOfBirth.month"
        name="month"
        key="month"
        onKeyPress={event => handleFieldGroupInput(event, MONTH_LENGTH)}
        suppress
      />
    ),
    YYYY: (
      <EnhancedField
        component={Input}
        placeholder="YYYY"
        min="1900"
        max="2900"
        type="number"
        id="_composite-input-dateOfBirth.year"
        name="year"
        key="year"
        onKeyPress={event => handleFieldGroupInput(event, YEAR_LENGTH)}
        suppress
      />
    ),
  };
  return (
    <div className={className}>
      {dateFormats[Configuration.dateFormat].split('/').map(date => fields[date.toUpperCase()])}
    </div>
  );
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'DateFieldGroup')(DateFieldGroup);
