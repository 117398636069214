/**
 * Sets an error message based on the provided payment error.
 *
 * This error handling is used in the SubscriptionUpdatePaymentBlock and AdyenPayment
 *
 * @param {Object} paymentError - An object containing a message property which describes the payment error.
 * @param {string} paymentError.message - The message describing the payment error.
 * @param {Function} setError - A function that sets the error message.
 * @returns {void} - This function does not return anything; it calls setError with a string argument.
 */

import type AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError.js';

// Extending AdyenCheckoutError as the type is unknown by default but we want to explicity say it is a string
export interface AdyenCheckoutErrorCause {
  statusCode?: string;
}
export interface AdyenCheckoutErrorExtended extends AdyenCheckoutError {
  cause: AdyenCheckoutErrorCause;
  name: string;
  message: string;
}

function adyenSetError(
  paymentError: AdyenCheckoutErrorExtended,
  setError: (message: string) => void,
) {
  if (paymentError.message === 'The session has expired.') {
    return setError('sessionError');
  }

  if (
    paymentError.message === 'AbortError: User closed the Payment Request UI.' ||
    paymentError.message === 'ApplePay UI dismissed' ||
    paymentError.cause?.statusCode === 'CANCELED'
  ) {
    return setError('cancelError');
  }

  return setError('generalError');
}

export default adyenSetError;
