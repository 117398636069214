import React from 'react';
import PropTypes from 'prop-types';
import './static-input.scss';

const StaticInput = ({ name, label, id = name, ...props }) => (
  <div className="atom-static-input">
    <label htmlFor={id} className="descriptor-wrapper">
      {label}
    </label>
    <input id={id} name={name} {...props} />
  </div>
);

StaticInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  labelHidden: PropTypes.bool,
};

export default StaticInput;
