import { connect } from 'react-redux';
import { compose } from 'redux';

import TransferToOnlineRedirect from './TransferToOnlineRedirect';

const mapStateToProps = (state: any) => ({
  memberHost: state.config.environmentConfig.web.member.host,
});

const connected = connect(mapStateToProps, null);

export default compose(connected)(TransferToOnlineRedirect);
