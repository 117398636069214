/* global WP_DEFINE_IS_NODE */

/**
 * Utilities to detect the user's device
 * @module
 */

/**
 * @param device
 * @returns {boolean}
 */
function getDevice(device) {
  if (WP_DEFINE_IS_NODE) {
    return false;
  }

  const ua = window.navigator.userAgent;
  return ua.match(device);
}

/**
 * Utilities to detect the user's ios version
 * @module
 */

/**
 * @returns {string|null|boolean}
 */
function getOsVersion() {
  if (WP_DEFINE_IS_NODE) {
    return false;
  }

  const ua = window.navigator.userAgent;
  const osVersion = ua.match('OS (.*) like Mac OS');

  if (osVersion && osVersion[1]) {
    return osVersion[1];
  }

  return null;
}

/**
 * True if this is an android device
 * @type {boolean}
 */
export const isAndroid = getDevice(/Android/i);

/**
 * True if this is an iOS device
 * @type {boolean}
 */
export const isIOS = getDevice(/iPhone|iPad|iPod/i);

/**
 * True if this is a mobile device
 * @type {boolean}
 */
export const isAnyTouchDevice = isAndroid || isIOS;

/**
 * True if this is an IOS device with IOS version 9
 * @type {boolean}
 */
export const isIOSNine = getOsVersion() && getOsVersion().startsWith('9');
