import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import WebHost from 'common/src/app/data/enum/WebHost';
import Pages from 'common/src/app/data/enum/Pages';
import IframeLogout from './IframeLogout';

const mapStateToProps = state => {
  const accountHost = state.config.environmentConfig.web[WebHost.ACCOUNT].host;
  const loggedOutPath = `${accountHost}${Pages.LOGGED_OUT}`;
  return {
    showLogoutPrompt: state.logout?.showLogoutPrompt,
    signOutIframeUrl: state.logout?.signOutIframeUrl,
    postLogoutRedirectUri: state.logout?.postLogoutRedirectUri || loggedOutPath,
  };
};

const connected = connect(mapStateToProps);

export default compose(connected)(IframeLogout);
