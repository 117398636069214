import { connect } from 'react-redux';
import { change } from 'redux-form';
import compose from 'redux/lib/compose';
import { push as historyPush } from 'react-router-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import FormNames from 'common/src/app/data/enum/FormNames';
import {
  userDetailFields,
  emailFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import Pages from 'common/src/app/data/enum/Pages';

import WelcomeBack from './WelcomeBack';

const mapStateToProps = state => {
  const introductionSelector = formValueSelector(FormNames.INTRODUCTION);

  return {
    firstName: introductionSelector(state, userDetailFields.FIRST_NAME),
    email: introductionSelector(state, emailFields.EMAIL_ADDRESS),
  };
};

const mapDispatchToProps = dispatch => ({
  clearEmailField: () => {
    dispatch(change(FormNames.INTRODUCTION, emailFields.EMAIL_ADDRESS, null));
    dispatch(change(FormNames.INTRODUCTION, emailFields.CONFIRM_EMAIL_ADDRESS, null));
    dispatch(historyPush(Pages.REGISTRATION_INTRODUCTION));
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(WelcomeBack);
