/* global 'molecule','CardRetryError' */
import React from 'react';
import Link from 'react-router/lib/Link';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import TextNew from 'components/atoms/TextNew';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import LocaleMessage from 'components/atoms/LocaleMessage';

/**
 * Failed email address flow
 */
const CardRetryError = (props, context, className) => (
  <div className={className}>
    <Wrapper padding="md">
      <TextNew.Strong cid="title">
        <LocaleMessage id="cardCheck.cardRetryError.body" />{' '}
        <Link href={`mailto:${marketConfig.supportEmail}`} target="_blank">
          {marketConfig.supportEmail}
        </Link>
      </TextNew.Strong>
    </Wrapper>
  </div>
);

export default withFunctionalClassName('molecule','CardRetryError')(CardRetryError);
