/* global 'atom','AccountFrozenModalContent' */
import React from 'react';
import Link from 'react-router/lib/Link';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import LocaleMessage from '../LocaleMessage';
import Button from '../Button';
import TextNew, { ElementTypes } from '../TextNew';
import Row from '../Row';

import './account-frozen-modal-content.scss';

const AccountFrozenModalContent = (props, context, className) => (
  <section className={className}>
    <header>
      <TextNew.Confident element={ElementTypes.H2} localeId="accountFrozenModalContent.title" />
    </header>

    <TextNew.PrimaryOriginal localeId="accountFrozenModalContent.description" />

    <Row aligncenter column>
      <TextNew.PrimaryOriginal>
        <Link href={`mailto:${marketConfig.supportEmail}`} target="_blank">
          {marketConfig.supportEmail}
        </Link>
      </TextNew.PrimaryOriginal>
      <TextNew.PrimaryOriginal localeId="accountFrozenModalContent.or" />
    </Row>

    <Row justifycenter>
      <Button href={`mailto:${marketConfig.supportEmail}`} target="_blank">
        <LocaleMessage id="accountFrozenModalContent.button" />
      </Button>
    </Row>
  </section>
);

export default withFunctionalClassName('atom','AccountFrozenModalContent')(AccountFrozenModalContent);
