import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import {
  resetPasswordHandler,
  verifyPasswordToken,
  getMemberPasswordValidationConfig,
} from 'common/src/app/actions/resources/accountActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import { passwordFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { newPasswordAndConfirm } from 'common/src/app/validation/accountValidation';
import {
  PartialState,
  passwordValidationConfigSelector,
} from 'common/src/app/selectors/accountSelector';
import ResetPassword from './ResetPassword';
import { Config } from '../../../../../../../../../types/config';

type ReduxState = {
  config: Config;
  resetPassword: {
    error?: string;
  };
} & PartialState;

const validationRules = {
  ...newPasswordAndConfirm,
};

const enhanced = enhancedReduxForm(
  {
    form: FormNames.RESET_PASSWORD,
    onSubmit: (
      values: { newPassword: string },
      dispatch: (func: () => void) => void,
      { location }: { location: { hash: string; query: { userId: string } } },
    ) => {
      const userId = location.query.userId || '';
      const token = (location.hash || '#').substring(1);

      return dispatch(resetPasswordHandler(values.newPassword, userId, token));
    },
    generalErrorMessage: { locale: 'resetPassword.generalErrorMessage' },
  } as any,
  validationRules,
);

const mapStateToProps = (state: ReduxState) => {
  const getErrorsForFields = makeGetErrorsForFields();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Current combination of Redux/Reselect doesn't support multiple args
  const passwordError = getErrorsForFields(state.enhancedForm.validation, {
    form: FormNames.RESET_PASSWORD,
    fields: [passwordFields.NEW_PASSWORD],
  });

  const disallowListPasswordError = passwordError.find(
    // Only return appropriate errors served by the API and avoid FE errors (no error codes)
    (error: { code: string }) =>
      error.code && (error.code === 'password.isblacklisted' || 'password.notchanged'),
  );

  const passwordValidationConfig = passwordValidationConfigSelector(state);

  return {
    memberHost: state.config.environmentConfig.web.member.host,
    validationError: state.resetPassword?.error,
    disallowListPasswordError,
    passwordValidationConfig,
  };
};

const mapDispatchToProps = {
  verifyPasswordToken,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: (_: any, dispatch: any) => dispatch(getMemberPasswordValidationConfig()),
});

export default compose(addInitAction, enhanced, connected)(ResetPassword);
