/* global 'molecule','AccountRecoveryErrorModal' */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Wrapper from 'components/atoms/Wrapper';
import Button from 'components/atoms/Button';
import TextNew from 'components/atoms/TextNew';
import LocaleMessage from 'components/atoms/LocaleMessage';
import WebHostLink from 'components/atoms/WebHostLink';
import WebHost from 'common/src/app/data/enum/WebHost';
import Pages from 'common/src/app/data/enum/Pages';
import { createPath } from 'common/src/app/util/routeUtils';
import { dataLayerCustomEvent } from 'common/src/app/util/tracking/dataLayerUtils';
import { DataLayerKeys } from 'common/src/app/data/enum/Tracking';
import './account-recovery-error-modal.scss';

/**
 * Failed email recovery flow
 */
const AccountRecoveryErrorModal = (
  { titleLocale, bodyLocale, showContactMessage, reduxState },
  context,
  className,
) => {
  useEffect(() => {
    dataLayerCustomEvent({ [DataLayerKeys.ACCOUNT_RECOVERY_FAILED]: 1 }, reduxState);
  }, [reduxState]);

  return (
    <div className={className}>
      <Wrapper padding="md">
        <header>
          <TextNew.Serif.MD localeId={titleLocale} />
        </header>
        <TextNew.Sans.SM cid="body" localeId={bodyLocale} />
        {showContactMessage && (
          <TextNew.Sans.SM localeId="accountRecoveryErrorModal.contactSupportMessage" />
        )}
        <WebHostLink webHost={WebHost.PUBLIC} link={createPath(Pages.CONTACT_US)}>
          <Button>
            <LocaleMessage id="accountRecoveryErrorModal.contactSupport" />
          </Button>
        </WebHostLink>
      </Wrapper>
    </div>
  );
};

AccountRecoveryErrorModal.propTypes = {
  titleLocale: PropTypes.string,
  bodyLocale: PropTypes.string,
  showContactMessage: PropTypes.bool,
  reduxState: PropTypes.object,
};

AccountRecoveryErrorModal.defaultProps = {
  titleLocale: 'accountRecoveryErrorModal.title',
  bodyLocale: 'accountRecoveryErrorModal.body',
  showContactMessage: false,
};

export default withFunctionalClassName('molecule','AccountRecoveryErrorModal')(AccountRecoveryErrorModal);
