import { createSelector } from 'reselect';
import { PAGE } from '../data/entityTypes';
import { API_STATIC_FAQ } from '../data/apiStatics';
import { makeCollectionPaginationViewSelector } from './collectionPaginationViewSelector';
import { SEARCH_RESULTS } from '../data/collectionPaginationViews';

export const selectTypicalDay = state => state.entities.typicalDay;
export const typicalDaySelector = createSelector(selectTypicalDay, recipes =>
  Object.values(recipes || {}),
);

export const selectCarouselRecipies = state => state.entities.colorCarousel;

export const typicalSevenDayMenuLunches = createSelector(
  [(state, lunch) => state.entities.sevenDayMenu?.[lunch.slug]],
  lunch => lunch,
);

export const getFaqData = state => state.entities[PAGE]?.[API_STATIC_FAQ];
export const formatFAQDataSelector = createSelector(getFaqData, faqs => faqs);

export const getBmi = state => state.view.publicBmi?.bmi;
export const bmiSelector = createSelector(getBmi, bmi => bmi);

export const getStaticPage = (state, slug) => state.entities.page?.[slug];
export const staticPageSelector = createSelector(getStaticPage, staticPage => staticPage);

export const getContentComponent = (state, slug, item) =>
  item ? state.entities.component?.[slug]?.[item] : state.entities.component?.[slug];

export const contentComponentSelector = createSelector(
  getContentComponent,
  contentComponent => contentComponent,
);

export const getInstagramPosts = state => state.view.components.instagramTrending?.posts;
export const instagramPostSelector = createSelector(
  getInstagramPosts,
  instagramPosts => instagramPosts,
);

export const getPageMedia = (state, contentIndex, slug, mediaType) =>
  state.entities.page?.[slug]?.nestedContent?.[contentIndex]?.[mediaType];

export const pageImageSelector = createSelector(getPageMedia, pageMedia => pageMedia);

export const landingGridSelector = (state, gridCount) => {
  const EMPTY_ARRAY = [];

  const collectionPaginationViewSelector = makeCollectionPaginationViewSelector(SEARCH_RESULTS);
  const results = collectionPaginationViewSelector(state);

  return {
    gridContents: {
      results: results?.entities || EMPTY_ARRAY,
      pagination: results?.pagination || EMPTY_ARRAY,
      gridColumnCount: gridCount ? state.view.components.grid?.layout : undefined,
    },
  };
};
