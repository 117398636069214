/**
 * Calculates applied voucher discount
 * @param discount
 * @param price
 * @returns {Array} [discountPrice, discountAmount]
 */
export default function calculateDiscountedPrice(discount, price) {
  const formatAmount = amount => amount.toFixed(2).replace(/\.00/, '');

  // Calculate relative from the price (percentage-wise)
  if (discount.isRelative) {
    const calculatedDiscount = (price.amount / 100) * discount.amount;
    return [formatAmount(price.amount - calculatedDiscount), formatAmount(calculatedDiscount)];
  }

  // Just subtract the amount from the price
  return [formatAmount(price.amount - discount.amount), formatAmount(discount.amount)];
}
