import { connect } from 'react-redux';
import * as Redux from 'redux';
import { registerDeepLinks } from 'common/src/app/actions/components/deepLinkActions';
import { currentRoute } from 'common/src/app/util/routeCheckUtils';
import DeepLink from './DeepLink';

import {
  ReduxState,
  PropsFromState,
  PropsFromDispatch,
  OwnProps,
  LinkBlock,
} from './DeepLinkTypes';

const DEFAULT_HEADER_BAR_HEIGHT = 64;

const mapStateToProps = (state: ReduxState): PropsFromState => ({
  headerHeight: state.view.components.header?.height ?? DEFAULT_HEADER_BAR_HEIGHT,
  currentRoute: currentRoute(state),
});

const mapDispatchToProps = (dispatch: Redux.Dispatch<any>): PropsFromDispatch => ({
  registerDeepLink: (linkBlock: LinkBlock) => dispatch(registerDeepLinks(linkBlock)),
});

export default connect<PropsFromState, PropsFromDispatch, OwnProps, ReduxState>(
  mapStateToProps,
  mapDispatchToProps,
)(DeepLink);
