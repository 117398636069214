import React, { memo } from 'react';
import Checkout from '../../../../Payment/components/templates/Checkout';

/**
 * Package selection
 * This wrapper is for the wizard form (Wraps the checkout page)
 */
const PackageSelection = memo(props => (
  <Checkout
    isInWizard
    {...props}
    // do not show the voucher on the first screen
    showVoucher={false}
  />
));
PackageSelection.displayName = 'PackageSelection';

export default PackageSelection;
