/* global 'page','StaticContent' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import BlockContent from 'components/molecules/BlockContent';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import './static-content.scss';

const StaticContent = ({ page, deviceState, background }, {}, className) => {
  if (!page) {
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  }

  const { title, description, nestedContent } = page;

  return (
    <BackgroundImageWrapper
      className={className}
      image={deviceState >= DeviceState.XL && background ? background : ''}
    >
      <div className="content-wrapper">
        <Wrapper padding="sm" width="md">
          {title ? <TextNew.Loud element={ElementTypes.H1}>{title}</TextNew.Loud> : null}

          {description ? <TextNew.PrimaryElegant>{description}</TextNew.PrimaryElegant> : null}
        </Wrapper>

        {nestedContent ? <BlockContent data={nestedContent} /> : null}
      </div>
    </BackgroundImageWrapper>
  );
};

StaticContent.propTypes = {
  background: ImagePropTypes,
  page: PropTypes.shape({
    nestedContent: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  deviceState: PropTypes.number,
};

export default withDeviceState()(withFunctionalClassName('page','StaticContent')(StaticContent));
