import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { functionalComponentClassName } from 'common/src/app/util/componentClassNameUtils';
import Link from 'react-router/lib/Link';
import { emailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import FormGroup from 'components/molecules/FormGroup';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import TextNew, { Colors } from 'components/atoms/TextNew';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import Pages from 'common/src/app/data/enum/Pages';
import LocaleMessage from 'components/atoms/LocaleMessage';
import errorBoundary from 'components/hoc/ErrorBoundary';
import { compose } from 'redux';

const onLoginClick = e => {
  e.preventDefault();
  window.history.back();
};

const renderForgotPasswordLink = location => {
  const linkProps =
    location?.state?.prevPath === Pages.LOGIN
      ? {
          onClick: onLoginClick,
        }
      : {
          to: Pages.LOGIN,
        };

  return (
    <TextNew.Formal>
      <Link {...linkProps}>
        <LocaleMessage id="forgotPassword.backLink" />
      </Link>
    </TextNew.Formal>
  );
};

const ForgotPassword = (
  { submitting = false, handleSubmit, submitSucceeded, location },
  { getMessage },
) => (
  <form
    onSubmit={handleSubmit}
    className={functionalComponentClassName(ComponentType.TEMPLATE, 'ForgotPassword')}
  >
    <TextNew.Loud localeId="forgotPassword.title" />
    <TextNew.PrimaryElegant localeId="forgotPassword.introText" />
    <FormGroup>
      <EnhancedField
        name={emailFields.EMAIL_ADDRESS}
        id="email"
        placeholder={getMessage('forgotPassword.emailPlaceholder')}
        component={Input}
        type="email"
      />
      <FormErrorMessages fields={['email']} id="forgotPasswordError" />
    </FormGroup>

    {submitSucceeded ? (
      <TextNew.Success color={Colors.SUCCESS} localeId="forgotPassword.confirmationMessage" />
    ) : (
      <FormErrorMessages showGeneral id="forgotPasswordSuccessful" />
    )}

    <Row justifycenter>
      {!submitSucceeded && (
        <Button ripple type="submit" disabled={submitting} id="submitButton">
          <LocaleMessage id="forgotPassword.submitButton" />
        </Button>
      )}
    </Row>
    <Row justifycenter>{renderForgotPasswordLink(location)}</Row>
  </form>
);

ForgotPassword.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

ForgotPassword.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  location: PropTypes.object,
};

export default compose(errorBoundary({ wholePageError: true }))(ForgotPassword);
