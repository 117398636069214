import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RESET_AUTH_QUERY } from 'common/src/server/util/AuthenticationHelper/constants';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import TextNew from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import LocaleMessage from 'components/atoms/LocaleMessage';
import Loader from 'components/atoms/Loader';
import './finished.scss';

const Finished = (
  { memberHost, skipPayment, packageDetail, init, loginRedirect },
  { getMessage },
  className,
) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      await init();
      setLoading(false);
    };

    getData().catch(() => setLoading(false));
  }, [init]);

  const buttonLink = `${memberHost}${Pages.PROFILE_BUILDER_ONLINE}?${RESET_AUTH_QUERY}`;
  const boughtProduct = packageDetail?.name;

  /* if the user does not have any package take that back to login */
  if (!loading && !skipPayment && packageDetail && Object.keys(packageDetail).length === 0) {
    return loginRedirect();
  }

  return (
    <form className={className}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TextNew.Loud localeId={`finished.${skipPayment ? 'paymentSkipped.title' : 'title'}`} />
          <TextNew.PrimaryElegant
            localeId={`finished.${
              skipPayment || !boughtProduct ? 'paymentSkipped.description' : 'description'
            }`}
            localeParams={{
              PACKAGE_NAME: boughtProduct || getMessage('finished.fallbackPackageName'),
            }}
          />
          <Row justifycenter>
            <Button href={buttonLink} ripple type="submit">
              <LocaleMessage id="finished.btnContinue" />
            </Button>
          </Row>
        </>
      )}
    </form>
  );
};

Finished.propTypes = {
  init: PropTypes.func,
  memberHost: PropTypes.string.isRequired,
  packageDetail: PropTypes.shape({
    name: PropTypes.string,
  }),
  /**
   * When registering via an invite, it's possible to skip the payment step.
   */
  skipPayment: PropTypes.string,
  loginRedirect: PropTypes.func,
};

Finished.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(ComponentType.TEMPLATE, 'Finished')(Finished);
