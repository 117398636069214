/* global 'template','RecoverEmailDOB' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import DateFieldGroup from 'components/molecules/DateFieldGroup';
import CompositeInput from 'common/src/app/enhanced-redux-form/components/CompositeInput';
import compositeInputFormatters from 'common/src/app/enhanced-redux-form/compositeInputFormatters';
import * as ComposeOn from 'common/src/app/enhanced-redux-form/data/ComposeOn';
import LocaleMessage from 'components/atoms/LocaleMessage';
import FormGroup from 'components/molecules/FormGroup';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import InputType from 'common/src/app/data/enum/InputType';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';

const RecoverEmailDOB = ({ submitting = false, hasBlockingError, handleSubmit }, {}, className) => (
  <form onSubmit={handleSubmit} className={className} autoComplete="off" noValidate>
    <TextNew.Loud element={ElementTypes.h1} localeId="recoverEmailDOB.title" />

    <TextNew.PrimaryElegant localeId="recoverEmailDOB.desc" />

    <FormGroup label={<LocaleMessage id="recoverEmailDOB.label" />} type="inline">
      <CompositeInput
        name={userDetailFields.DATE_OF_BIRTH}
        formatter={compositeInputFormatters.DATE}
        composeOn={ComposeOn.VALIDATE_AND_BLUR}
      >
        <DateFieldGroup />
      </CompositeInput>
      <FormErrorMessages fields={[userDetailFields.DATE_OF_BIRTH]} />
      <FormErrorMessages showGeneral />
    </FormGroup>

    <Row justifycenter>
      <Button
        ripple
        type={InputType.SUBMIT}
        cid="next-step"
        disabled={submitting || hasBlockingError}
        isLoading={submitting}
      >
        <LocaleMessage id="general.cta.continue" />
      </Button>
    </Row>
  </form>
);

RecoverEmailDOB.propTypes = {
  submitting: PropTypes.bool,
  hasBlockingError: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

RecoverEmailDOB.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName('template','RecoverEmailDOB')(RecoverEmailDOB);
