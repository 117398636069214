import { useEffect } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

const Pollyfills = (): null => {
  useEffect(() => {
    window.ResizeObserver = window.ResizeObserver || ResizeObserver;
  }, []);

  return null;
};

export default Pollyfills;
