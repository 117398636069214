import { regionCode } from 'common/src/app/data/enum/Regions';

import Product from '../../types/Product';

export const generateVoucherError = (products: Product | Product[]) => {
  if (Array.isArray(products)) {
    return 'voucher.errors.voucherCode.basketItemsNotEligible';
  }

  if (products?.subscriptionProductId && products?.regionCode === regionCode.OUTSIDE_EUROPE) {
    return 'voucher.errors.voucherCode.regionNotEligible';
  }

  if (products?.subscriptionProductId && products?.regionCode !== regionCode.OUTSIDE_EUROPE) {
    return 'voucher.errors.voucherCode.packageNotEligible';
  }

  return 'voucher.errors.voucherCode.default';
};
