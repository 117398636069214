import { ONLY_ALPHANUMERIC, MATCH_WHITESPACE } from '../data/regexPatterns';
/* eslint-disable import/prefer-default-export */

/**
 * Returns an object to be used as props for a link tag, that based on the url returns a different
 * attribute name. If the url is absolute, it will use the `href` prop to not link internally.
 * Otherwise it will use the `to` attribute to have it picked up by React Router.
 *
 * @param {string} url Either a relative or absolute url
 * @return {object}
 */
export const getLinkPropsFromUrl = url => {
  const prop = /(?:https?:)?\/\//i.test(url) ? 'href' : 'to';
  return {
    [prop]: url,
  };
};

/**
 * Takes a string and converts to a URL-friendly string
 * Truncates length at 64 charachters
 * @param {string}
 * @return {string}
 */
export const stringToUrl = string => {
  const stripChars = string.replace(ONLY_ALPHANUMERIC, '');
  const reduceLength = stripChars.substring(0, 64);
  const addDash = reduceLength.replace(MATCH_WHITESPACE, '-');
  return addDash.toLowerCase();
};

/**
 * Get specific url parameter
 * @param location
 * @param name
 * @returns {string}
 */
export const getUrlParameter = (location, name) => {
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
