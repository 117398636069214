import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { INIT_SELF_BLOCKING, withInitAction } from '@mediamonks/react-redux-component-init';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import Pages from 'common/src/app/data/enum/Pages';
import serviceConfig from 'common/src/app/config/service.configdefinitions';
import WebHost from 'common/src/app/data/enum/WebHost';
import PolicyContent from './PolicyContent';

const mapStateToProps = (state, { location }) => {
  const path = location?.pathname.replace('/', '');

  return {
    page: staticPageSelector(state, path),
    publicHost: state.config.environmentConfig.web.public.host,
  };
};

const connected = connect(mapStateToProps);

const addInitAction = withInitAction(
  ['location.pathname'],
  {
    prepared: ({ location: { pathname } }, dispatch) =>
      dispatch(
        getStaticContent(pathname, serviceConfig.webHost !== WebHost.MEMBER, entity => {
          const { nestedContent, ...rest } = entity;

          // Remove Umbraco cookie lists and add OneTrust cookie list on cookies policy page
          if (pathname === Pages.COOKIESPOLICY && serviceConfig.oneTrustCookieConsentId) {
            nestedContent.splice(-3);
            nestedContent.push({
              title: 'Cookie list',
              text: '<div id="ot-sdk-cookie-policy"></div>',
              _type: 'RichTextBlock',
            });
          }
          return {
            nestedContent,
            ...rest,
          };
        }),
      ),
  },
  { initSelf: INIT_SELF_BLOCKING },
);

export default compose(addInitAction, connected)(PolicyContent);
