/* global WP_DEFINE_IS_NODE, WP_DEFINE_IS_NATIVE */
import createAction from 'redux-actions/lib/createAction';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import debugLib from 'debug';
import { stringify, parse } from 'qs';
import RedirectError from '../util/RedirectError';
import { INITIAL_USER_STATE_COOKIE } from '../../server/util/AuthenticationHelper/constants';
import { getValue } from '../util/injector';
import { AUTHENTICATION_MANAGER } from '../data/Injectables';

const debug = debugLib('SlimmingWorld:authenticationActions');

let atobNode = null;
if (WP_DEFINE_IS_NODE) {
  atobNode = require('atob'); // eslint-disable-line global-require
}

const decodeBase64 = encodedStr => (encodedStr ? (atobNode || atob)(encodedStr) : '');

export const QUERY_PARAM_USER_ID_HINT = 'uidHint';
export const SET_AUTH_TOKENS = 'authenticationActions/SET_AUTH_TOKENS';
export const SET_INITIAL_USER_STATE = 'authenticationActions/SET_INITIAL_USER_STATE';
export const CHANGE_INITIAL_USER_STATE = 'authenticationActions/CHANGE_INITIAL_USER_STATE';
export const SET_USER_PERMISSION_STATE = 'authenticationActions/SET_USER_PERMISSION_STATE';

export const setInitialUserState = encodedStr =>
  createAction(SET_INITIAL_USER_STATE)(
    parse(decodeBase64(encodedStr), { ignoreQueryPrefix: true }),
  );

export const changeInitialUserState =
  (fieldName, newValue, prevState = {}) =>
  dispatch => {
    const newState = {
      ...prevState,
      [fieldName]: newValue,
    };
    const encodedStateString = btoa(stringify(newState));
    Cookies.set(INITIAL_USER_STATE_COOKIE, encodedStateString);
    return dispatch(createAction(CHANGE_INITIAL_USER_STATE)(newState));
  };

export const setAuthTokens = (idToken, accessToken) => (dispatch, getState) => {
  const state = getState();
  const currentSub = state.authentication?.userInfo?.sub;
  const decodedAccessToken = jwtDecode(accessToken);
  const decodedIdToken = jwtDecode(idToken);

  if (WP_DEFINE_IS_NODE) {
    /*
     * A user id hint may be set in the query string. We may use this when linking from another
     * microservice. We can now detect early if the current user id in the access token does
     * not match the user id we expect the user to be logged in as. If so, redirect with a
     * 'resetAuth' query to force getting new authentication tokens from account.
     */
    const userIdHint = state.routing.locationBeforeTransitions.query[QUERY_PARAM_USER_ID_HINT];
    if (userIdHint && userIdHint !== decodedAccessToken.sub) {
      debug(
        `Authentication token user id (${decodedAccessToken.sub}) does not match the hinted user id (${userIdHint}). Clearing tokens`,
      );
      const { query, pathname } = state.routing.locationBeforeTransitions;
      const newQuery = { ...(query || {}), resetAuth: 1 };
      delete newQuery[QUERY_PARAM_USER_ID_HINT];

      throw new RedirectError(`${pathname}?${stringify(newQuery)}`);
    } else if (userIdHint) {
      debug('Authentication token user id matches hinted user id. Continue page render.');
    }
  }

  if (!WP_DEFINE_IS_NODE && !WP_DEFINE_IS_NATIVE) {
    if (currentSub && currentSub !== decodedAccessToken.sub) {
      /*
       * If we reach this part of the code, it means that the user id from the client authentication
       * does not match the user id in the server authentication. This may happen when the user
       * switched to a different account on the OIDC endpoint through another microservice.
       */
      debug('Authentication token mismatch. Forcing token refresh on server');
      const newLocationSearch =
        window.location.search.length > 1
          ? `${window.location.search}&resetAuth=1`
          : '?resetAuth=1';

      getValue(AUTHENTICATION_MANAGER).removeUserState();
      window.location.href = `${window.location.pathname}${newLocationSearch}`;
      return null;
    }
  }

  return dispatch(
    createAction(SET_AUTH_TOKENS)({
      idToken,
      accessToken,
      decodedAccessToken,
      decodedIdToken,
    }),
  );
};

export const setUserPermissionState = createAction(
  SET_USER_PERMISSION_STATE,
  ({ accountState, subscriptionType, roles, userId, memberType, profileComplete }) => ({
    userId,
    accountState,
    roles,
    subscriptionType,
    memberType,
    profileComplete,
  }),
);
