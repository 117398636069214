const OnboarderStatus = {
  INIT: 'init',
  RUNNING: 'running',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
};

OnboarderStatus.propTypes = Object.values(OnboarderStatus);

export default OnboarderStatus;
