import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { API_COMPONENT_APPLICATION_ERROR_BACKGROUND } from 'common/src/app/data/apiComponents';
import serviceConfig from 'common/src/app/config/service.configdefinitions';
import WebHost from 'common/src/app/data/enum/WebHost';
import ApplicationError from './ApplicationError';

// on account, link to public on errors, otherwise keep on the same host;
const webHost = serviceConfig.webHost === WebHost.ACCOUNT ? WebHost.PUBLIC : serviceConfig.webHost;

const mapStateToProps = state => ({
  publicHost: state.config.environmentConfig.web[webHost]?.host,
  background: state.entities[COMPONENT]?.[API_COMPONENT_APPLICATION_ERROR_BACKGROUND]?.image,
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_APPLICATION_ERROR_BACKGROUND)),
});

const connected = connect(mapStateToProps);

export default compose(addInitAction, connected)(ApplicationError);
