/* global 'atom','RichTextsAndRichTextsWithIconsListBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import RichTextWithIconLinkAndTitleBlock from '../RichTextWithIconLinkAndTitleBlock';
import RichTextBlock from '../RichTextBlock';
import BlockContentComponentMap from '../../../enum/BlockTypes';
import './rich-text-and-rich-texts-with-icon-list-block.scss';

/**
 * RichText with icon, link and title block
 */
const RichTextsAndRichTextsWithIconsListBlock = ({ content, title }, context, className) => {
  // eslint-disable-next-line no-underscore-dangle
  const contentBlock = (block, type) => block._type === type;

  const listItems = content.filter(item =>
    contentBlock(item, BlockContentComponentMap.RICH_TEXTS_WITH_ICONS_AND_TITLES_LIST_BLOCK),
  );

  return (
    <div className={className}>
      <TextNew cid="title" element={ElementTypes.H1}>
        {title}
      </TextNew>
      <div className="row">
        {content
          .filter(item => contentBlock(item, BlockContentComponentMap.RICH_TEXT_BLOCK))
          .map((block, idx) => (
            <RichTextBlock key={BlockContentComponentMap.RICH_TEXT_BLOCK + idx} text={block.text} />
          ))}
        {listItems ? <RichTextWithIconLinkAndTitleBlock content={listItems} /> : null}
      </div>
    </div>
  );
};

RichTextsAndRichTextsWithIconsListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};

export default withFunctionalClassName('atom','RichTextsAndRichTextsWithIconsListBlock')(RichTextsAndRichTextsWithIconsListBlock);
