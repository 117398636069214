import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export type ReCaptchaCheckboxProps = {
  setLoading: (loading: boolean) => void;
  setToken: (token: string | null) => void;
  setRecaptchaStatus: (status: boolean) => void;
  onChange?: () => void;
  size?: 'normal' | 'compact' | 'invisible';
  defaultSiteKey?: string;
  siteKey?: string;
  resetTokenSubmissionCount?: boolean;
  resetSubmissionCount: () => void;
};

const ReCaptchaCheckbox = ({
  setLoading,
  setToken,
  setRecaptchaStatus,
  onChange,
  size = 'normal',
  defaultSiteKey,
  siteKey,
  resetTokenSubmissionCount = false,
  resetSubmissionCount,
}: ReCaptchaCheckboxProps): JSX.Element => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (size === 'invisible') {
      setToken(null);
      setLoading(true);
      recaptchaRef?.current?.execute();
      setRecaptchaStatus(true);
    }
  }, [size, setRecaptchaStatus]);

  const change = (token: string | null) => {
    if (token) {
      setToken(token);
      if (resetTokenSubmissionCount) {
        // Group registration card check
        // - reset the submission count
        // - set the recaptchaToken to null
        resetSubmissionCount();
        setToken(null);
      }
    }
    setLoading(false);
    return onChange?.();
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      badge="inline"
      className="g-recaptcha"
      sitekey={siteKey || defaultSiteKey || ''}
      onChange={change}
      theme="light"
      size={size}
    />
  );
};

export default ReCaptchaCheckbox;
