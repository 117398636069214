/* global 'template','MedicalCheck' */
import React, { useEffect, useRef } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import HideOnError from 'common/src/app/enhanced-redux-form/components/HideOnError';
import FormValueProvider from 'common/src/app/enhanced-redux-form/components/FormValueProvider';
import CompositeInput from 'common/src/app/enhanced-redux-form/components/CompositeInput';
import compositeInputFormatters from 'common/src/app/enhanced-redux-form/compositeInputFormatters';
import * as ComposeOn from 'common/src/app/enhanced-redux-form/data/ComposeOn';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { elementToScrollOnError } from 'common/src/app/validation/validationUtils';
import Configuration from 'common/src/app/config/Configuration';
import * as gender from 'common/src/app/data/enum/Gender';
import { isWithinAgeRange } from 'common/src/app/util/ageUtils/ageUtils';
import {
  userDetailFields,
  medicalFields,
  measurementFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import InformationToggle from 'components/molecules/InformationToggle';
import {
  ERROR_HAS_EATING_DISORDER,
  ERROR_AGE_NOT_ALLOWED,
  ERROR_IS_PREGNANT,
  ERROR_BMI_NOT_ALLOWED,
  ERROR_BMI_TOO_LOW,
  ERROR_BMI_DANGEROUS,
} from 'common/src/app/data/validationErrorCodes';
import IconName from 'common/src/app/data/enum/IconName';
import { MemberTypeStrings } from 'common/src/app/data/enum/MemberType';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import LocaleMessage from '../../atoms/LocaleMessage';
import TextNew, { FontWeight, FontSizes } from '../../atoms/TextNew';
import FormGroup from '../../molecules/FormGroup';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import Input from '../../atoms/Input';
import BlockingFormError from '../../molecules/BlockingFormError';
import LocaleBulletedList from '../../molecules/LocaleBulletedList';
import Row from '../../atoms/Row';
import Button from '../../atoms/Button';
import RadioButton from '../../atoms/RadioButton';
import RadioButtonGroup from '../../molecules/RadioButtonGroup';
import DateFieldGroup from '../../molecules/DateFieldGroup';
import NextStep from '../../molecules/NextStep';
import MedicalCheckGreeting from './components/MedicalCheckGreeting';
import SubmitButton from './components/SubmitButton';
import MedicalCheckAgeError from './components/MedicalCheckAgeError';
import ToggleFemaleOnlyQuestions from './components/ToggleFemaleOnlyQuestions';
import WeightHeightQuestions from './components/WeightHeightQuestions';
import errorBoundary from '../../hoc/ErrorBoundary';
import './medical-check.scss';

const MedicalCheck = (
  {
    submitting = false,
    hasBlockingError,
    hasBmiError,
    handleSubmit,
    publicHost,
    resetFemaleOnlyData,
    isGroupRegister,
    isFree2GoRegistration,
    setFree2GoState,
    dateOfBirth,
    setInitialDisclaimer,
    firstName,
    deviceState,
    validateStep,
    errorScrollOrder,
    compositeErrors,
    scrollToElement,
  },
  { getMessage },
  className,
) => {
  const fieldRefs = useRef([]);
  const registrationType = isGroupRegister ? MemberTypeStrings.GROUP : MemberTypeStrings.ONLINE;
  const isSixteenToSeventeen = isWithinAgeRange(dateOfBirth, 16, 17);

  useEffect(() => {
    isGroupRegister && setFree2GoState(dateOfBirth);
  });

  const customHandleSubmit = async event => {
    event.preventDefault();
    const { element } = await elementToScrollOnError({
      fieldRefs,
      compositeErrors,
      submitHandler: handleSubmit,
      fieldOrder: errorScrollOrder,
      validateFormCallback: validateStep,
    });
    scrollToElement(element);
  };

  return (
    <form onSubmit={customHandleSubmit} className={className} autoComplete="off" noValidate>
      <FormValueProvider fields={[userDetailFields.FIRST_NAME]}>
        <MedicalCheckGreeting firstName={firstName} />
      </FormValueProvider>
      {isGroupRegister ? (
        <>
          <TextNew.PrimaryElegant localeId="medicalCheck.groupDataCollectionInfo1" />
          <TextNew.PrimaryElegant localeId="medicalCheck.groupDataCollectionInfo2" />
        </>
      ) : (
        <TextNew.Sans.MD
          weight={FontWeight.LIGHT}
          localeId="medicalCheck.subtitle"
          cid="subtitle"
          size={deviceState <= DeviceState.MD ? FontSizes.SM : FontSizes.MD}
        />
      )}

      {isGroupRegister ? (
        <FormGroup label={<LocaleMessage id="medicalCheck.groupLabels.name" />} type="stacked">
          <Row column>
            <EnhancedField
              name={userDetailFields.FIRST_NAME}
              placeholder={getMessage('medicalCheck.placeholders.firstName')}
              component={Input}
              type="text"
              inputRef={ref => (fieldRefs.current[userDetailFields.FIRST_NAME] = ref)}
              suppress
            />
            <FormErrorMessages fields={[userDetailFields.FIRST_NAME]} />
            <EnhancedField
              name={userDetailFields.LAST_NAME}
              placeholder={getMessage('medicalCheck.placeholders.lastName')}
              component={Input}
              type="text"
              inputRef={ref => (fieldRefs.current[userDetailFields.LAST_NAME] = ref)}
              suppress
            />
            <FormErrorMessages fields={[userDetailFields.LAST_NAME]} />
          </Row>
        </FormGroup>
      ) : null}

      <FormGroup label={<LocaleMessage id="medicalCheck.groupLabels.dateOfBirth" />} type="inline">
        <CompositeInput
          name={userDetailFields.DATE_OF_BIRTH}
          formatter={compositeInputFormatters.DATE}
          composeOn={ComposeOn.VALIDATE_AND_BLUR}
          inputRef={ref => (fieldRefs.current[userDetailFields.DATE_OF_BIRTH] = ref)}
        >
          <DateFieldGroup />
        </CompositeInput>
        <FormErrorMessages fields={[userDetailFields.DATE_OF_BIRTH]} />
      </FormGroup>
      <BlockingFormError
        errorCode={ERROR_AGE_NOT_ALLOWED}
        fields={[userDetailFields.DATE_OF_BIRTH]}
      >
        <div className="error-content-wrapper">
          <MedicalCheckAgeError
            registrationType={registrationType}
            isSixteenToSeventeen={isSixteenToSeventeen}
            publicHost={publicHost}
          />
        </div>
      </BlockingFormError>

      <HideOnError errorCodes={[ERROR_AGE_NOT_ALLOWED]} fields={[userDetailFields.DATE_OF_BIRTH]}>
        <FormGroup
          label={<LocaleMessage id="medicalCheck.groupLabels.gender" />}
          forwardRef={ref => (fieldRefs.current[userDetailFields.GENDER] = ref)}
        >
          <EnhancedField
            name={userDetailFields.GENDER}
            component={RadioButtonGroup}
            type="icon"
            color={Configuration.generalButtonColor}
            suppress
          >
            <RadioButton
              width={100}
              height={100}
              icon={IconName.FEMALE}
              label={<LocaleMessage id="medicalCheck.options.gender.female" />}
              value={gender.FEMALE}
              suppress
            />
            <RadioButton
              width={100}
              height={100}
              icon={IconName.MALE}
              label={<LocaleMessage id="medicalCheck.options.gender.male" />}
              value={gender.MALE}
              onClick={resetFemaleOnlyData}
              suppress
            />
          </EnhancedField>
          <InformationToggle
            label={<LocaleMessage id="medicalCheck.gender.label" />}
            information={<LocaleMessage id="medicalCheck.gender.information" />}
          />
          <FormErrorMessages fields={[userDetailFields.GENDER]} />
        </FormGroup>
        {!isFree2GoRegistration && (
          <FormValueProvider fields={[userDetailFields.GENDER]}>
            <ToggleFemaleOnlyQuestions isGroupRegister={isGroupRegister} />
          </FormValueProvider>
        )}

        <HideOnError errorCodes={[ERROR_IS_PREGNANT]} fields={[userDetailFields.IS_PREGNANT]}>
          {!isGroupRegister && (
            <>
              <FormGroup
                label={<LocaleMessage id="medicalCheck.groupLabels.hasMedicalConditions" />}
                forwardRef={ref => (fieldRefs.current[medicalFields.HAS_EATING_DISORDER] = ref)}
              >
                <TextNew.PrimaryElegant localeId="medicalCheck.eatingDisordersCopy" />
                <EnhancedField
                  name={medicalFields.HAS_EATING_DISORDER}
                  component={RadioButtonGroup}
                  type="button"
                  color={Configuration.generalButtonColor}
                  suppress
                >
                  <RadioButton
                    label={<LocaleMessage id="medicalCheck.options.toggle.yes" />}
                    value="1"
                    suppress
                  />
                  <RadioButton
                    label={<LocaleMessage id="medicalCheck.options.toggle.no" />}
                    value="0"
                    suppress
                  />
                </EnhancedField>
                <FormErrorMessages fields={[medicalFields.HAS_EATING_DISORDER]} />
              </FormGroup>

              <BlockingFormError
                errorCode={ERROR_HAS_EATING_DISORDER}
                fields={[medicalFields.HAS_EATING_DISORDER]}
              >
                <div className="error-content-wrapper">
                  {[...Array(4).keys()].map(index => (
                    <TextNew.PrimaryElegant
                      key={`eating-disorder-para-${index + 1}`}
                      localeId={`medicalCheck.blockingErrors.hasEatingDisorder.para${index + 1}`}
                    />
                  ))}
                  <Row justifycenter>
                    <Button href={publicHost}>
                      <LocaleMessage id="medicalCheck.blockingErrors.hasEatingDisorder.button" />
                    </Button>
                  </Row>
                </div>
              </BlockingFormError>
            </>
          )}

          <HideOnError
            errorCodes={[ERROR_HAS_EATING_DISORDER]}
            fields={[medicalFields.HAS_EATING_DISORDER]}
          >
            {!isGroupRegister && (
              <>
                <FormGroup
                  className="medical-conditions"
                  forwardRef={ref =>
                    (fieldRefs.current[medicalFields.HAS_MEDICAL_CONDITIONS] = ref)
                  }
                >
                  <TextNew.PrimaryElegant localeId="medicalCheck.liverKidneyDiseaseCancer.copy" />
                  <LocaleBulletedList localeId="medicalCheck.liverKidneyDiseaseCancer.conditions" />
                  <EnhancedField
                    name={medicalFields.HAS_MEDICAL_CONDITIONS}
                    component={RadioButtonGroup}
                    type="button"
                    color={Configuration.generalButtonColor}
                    suppress
                  >
                    <RadioButton
                      label={<LocaleMessage id="medicalCheck.options.toggle.yes" />}
                      value="1"
                      onClick={setInitialDisclaimer}
                      suppress
                    />
                    <RadioButton
                      label={<LocaleMessage id="medicalCheck.options.toggle.no" />}
                      value="0"
                      suppress
                    />
                  </EnhancedField>
                  <FormErrorMessages fields={[medicalFields.HAS_MEDICAL_CONDITIONS]} />
                </FormGroup>
              </>
            )}

            <FormValueProvider
              fields={[
                medicalFields.HAS_MEDICAL_CONDITIONS,
                medicalFields.HAS_READ_CONDITIONS_DISCLAIMER,
              ]}
            >
              <WeightHeightQuestions
                hasBmiError={hasBmiError}
                isGroupRegister={isGroupRegister}
                isFree2GoRegistration={isFree2GoRegistration}
                registrationType={registrationType}
                forwardRef={{
                  weightRef: ref => (fieldRefs.current[measurementFields.INITIAL_WEIGHT] = ref),
                  heightRef: ref => (fieldRefs.current[measurementFields.HEIGHT] = ref),
                }}
              />
            </FormValueProvider>
          </HideOnError>
        </HideOnError>
      </HideOnError>
      <HideOnError
        errorCodes={[ERROR_BMI_TOO_LOW, ERROR_BMI_DANGEROUS, ERROR_BMI_NOT_ALLOWED]}
        fields={[measurementFields.INITIAL_WEIGHT]}
      >
        {isGroupRegister ? (
          <Row className="submit-button-container" justifycenter>
            <SubmitButton submitting={submitting} hasBlockingError={hasBlockingError} />
          </Row>
        ) : (
          <NextStep descriptionLocale="medicalCheck.nextDescription">
            <SubmitButton submitting={submitting} hasBlockingError={hasBlockingError} />
          </NextStep>
        )}
      </HideOnError>
    </form>
  );
};

MedicalCheck.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  hasBlockingError: PropTypes.bool.isRequired,
  hasBmiError: PropTypes.bool.isRequired,
  publicHost: PropTypes.string.isRequired,
  resetFemaleOnlyData: PropTypes.func,
  isGroupRegister: PropTypes.bool,
  isFree2GoRegistration: PropTypes.bool,
  setFree2GoState: PropTypes.func,
  dateOfBirth: PropTypes.string,
  setInitialDisclaimer: PropTypes.func,
  firstName: PropTypes.string,
  deviceState: PropTypes.number,
  validateStep: PropTypes.func,
  errorScrollOrder: PropTypes.array,
  compositeErrors: PropTypes.array,
  scrollToElement: PropTypes.func,
};

MedicalCheck.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName('template','MedicalCheck'),
)(MedicalCheck);
