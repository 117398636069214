/* global 'template','PersonalDetails' */
import React, { useRef } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  userDetailFields,
  addressFields,
  passwordFields,
  termsFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { elementToScrollOnError } from 'common/src/app/validation/validationUtils';
import { regionCodeToCountryCode } from 'common/src/app/data/enum/Regions';
import { phoneNumberByCountryFormatter } from 'common/src/app/util/form/phoneNumberFormatter';
import FormValueProvider from 'common/src/app/enhanced-redux-form/components/FormValueProvider';
import FormNames from 'common/src/app/data/enum/FormNames';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import passwordValidationConfigShape from 'common/src/app/validation/passwordValidationConfigShape';
import { DEFAULT_MAX_LENGTH } from 'common/src/app/validation/passwordValidationConstants';
import SecurityQuestionAnswerEntry from 'components/molecules/SecurityQuestionAnswerEntry';
import GroupedPasswordInput from 'components/molecules/GroupedPasswordInput';

import SecurityAddressOrPostcode from '../../molecules/SecurityAddressOrPostcode';
import TextNew, { Colors, FontWeight, FontSizes } from '../../atoms/TextNew';
import NameCopy from '../../atoms/NameCopy';
import Input from '../../atoms/Input';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import Button from '../../atoms/Button';
import LocaleMessage from '../../atoms/LocaleMessage';
import Row from '../../atoms/Row';
import FormGroup from '../../molecules/FormGroup';
import InformationToggle from '../../molecules/InformationToggle';
import AddressLookup from '../../molecules/AddressLookup';
import ConfirmTerms from '../../molecules/ConfirmTerms';
import NextStep from '../../molecules/NextStep';
import errorBoundary from '../../hoc/ErrorBoundary';
import './personal-details.scss';

const PersonalDetails = (
  {
    submitting = false,
    handleSubmit,
    disallowListPasswordError,
    firstName,
    securityQuestions,
    isGroupRegister,
    region,
    isFree2GoRegistration,
    wizardSubmitting,
    country,
    route: { showCountry },
    deviceState,
    validateStep,
    errorScrollOrder,
    scrollToElement,
    addressLookupFormName,
    blockingAddressFields,
    passwordValidationConfig,
  },
  { getMessage },
  className,
) => {
  const fieldRefs = useRef([]);

  const addressFieldRefs = blockingAddressFields.reduce(
    (currentObj, field) => ({
      ...currentObj,
      [`${field}Ref`]: ref => (fieldRefs.current[`${addressLookupFormName}.${field}`] = ref),
    }),
    {},
  );

  const customHandleSubmit = async event => {
    event.preventDefault();
    const { errors, currentError, element } = await elementToScrollOnError({
      fieldRefs,
      submitHandler: handleSubmit,
      fieldOrder: errorScrollOrder,
      validateFormCallback: validateStep,
    });
    if (errors.length > 0) {
      let targetElement = element;
      if (currentError?.includes(addressLookupFormName)) {
        const addressFieldErrors = errors.filter(field => field.includes(addressLookupFormName));
        targetElement =
          addressFieldErrors.length === blockingAddressFields.length - 1
            ? fieldRefs.current[`${addressLookupFormName}.${addressFields.ADDRESS_LOOKUP}`]
            : targetElement;
      }
      scrollToElement(targetElement);
    }
  };

  if (!passwordValidationConfig) {
    return null;
  }

  return (
    <form
      method="post"
      onSubmit={customHandleSubmit}
      className={className}
      noValidate
      autoComplete="off"
    >
      <NameCopy
        greeting={<LocaleMessage id="personalDetails.greeting" params={{ NAME: firstName }} />}
      />
      <TextNew.Sans.MD
        weight={FontWeight.LIGHT}
        localeId="personalDetails.subCopy"
        cid="subtitle"
        size={deviceState <= DeviceState.MD ? FontSizes.SM : FontSizes.MD}
      />

      <FormGroup label={<LocaleMessage id="personalDetails.passwordLabel" />} type="stacked">
        <TextNew.PrimaryElegant
          cid="password-helper"
          localeId="personalDetails.passwordHelper"
          localeParams={{
            MIN_LENGTH: passwordValidationConfig.requiredLength,
            MAX_LENGTH: passwordValidationConfig.maxLength || DEFAULT_MAX_LENGTH,
          }}
        />

        <GroupedPasswordInput
          customPasswordValidation={
            disallowListPasswordError && (
              <TextNew.Error color={Colors.BRAND}>
                {disallowListPasswordError.message}
              </TextNew.Error>
            )
          }
          showAll={false}
          formName={FormNames.PERSONAL_DETAILS}
          forwardRef={{
            passwordRef: ref => (fieldRefs.current[passwordFields.PASSWORD] = ref),
            confirmPasswordRef: ref => (fieldRefs.current[passwordFields.CONFIRM_PASSWORD] = ref),
          }}
          passwordValidationConfig={passwordValidationConfig}
        />
      </FormGroup>

      {Object.keys(securityQuestions).length !== 0 && (
        <SecurityQuestionAnswerEntry
          securityQuestions={securityQuestions}
          forwardRef={{
            questionRef: ref => (fieldRefs.current[userDetailFields.SECURITY_QUESTION] = ref),
            answerRef: ref => (fieldRefs.current[userDetailFields.SECURITY_ANSWER] = ref),
          }}
        />
      )}
      {isGroupRegister && !isFree2GoRegistration && (
        <>
          <div className="contact-phone-number">
            <PhoneNumberPropsWrapper
              region={region}
              phonePlaceHolder={getMessage('personalDetails.contact.placeholder')}
              label={<LocaleMessage id="personalDetails.contact.label" />}
            />

            <InformationToggle
              id="telephone-input-info"
              label={<LocaleMessage id="personalDetails.contact.informationToggleLabel" />}
              information={<LocaleMessage id="personalDetails.contact.information" />}
            />
            <FormGroup type="stacked">
              <FormErrorMessages fields={[userDetailFields.PHONE_NUMBER]} />
            </FormGroup>
            <SecurityAddressOrPostcode
              region={region}
              forwardRef={{
                zipRef: ref => (fieldRefs.current[addressFields.ZIP_OR_POSTAL] = ref),
                addressRef: ref => (fieldRefs.current[addressFields.ADDRESS_LINE_1] = ref),
              }}
            />
          </div>
        </>
      )}

      {/* Only display this here for group registering members as online registering members will confirm the terms on the summary page */}
      {isGroupRegister && (
        <ConfirmTerms
          title={
            <TextNew.Confident
              cid="confirm-terms-title"
              localeId="personalDetails.confirmTerms.title"
            />
          }
          isGroupRegister={isGroupRegister}
          isFree2GoRegistration={isFree2GoRegistration}
          forwardRef={ref => (fieldRefs.current[termsFields.TERMS_OF_USE] = ref)}
        />
      )}

      {!isGroupRegister && !isFree2GoRegistration && (
        <>
          <FormGroup type="stacked">
            <FormValueProvider fields={[addressFields.COUNTRY]}>
              <AddressLookup
                name={addressFields.BILLING_ADDRESS}
                formName={FormNames.SUMMARY}
                label={getMessage('summary.registration.billing.label')}
                searchPlaceholder={getMessage('summary.address.searchPlaceholder')}
                country={country}
                showCountry={showCountry}
                suppress
                addressFieldRefs={addressFieldRefs}
              />
            </FormValueProvider>
          </FormGroup>
          <InformationToggle
            label={<LocaleMessage id="summary.delivery.informationToggleLabel" />}
            information={<LocaleMessage id="summary.delivery.information" />}
          />

          <FormGroup type="inline">
            <FormValueProvider fields={[addressFields.COUNTRY]}>
              <PhoneNumberPropsWrapper
                label={<LocaleMessage id="summary.contact.label" type="inline" />}
                country={country}
                phonePlaceHolder={getMessage('summary.contact.placeholder')}
              />
            </FormValueProvider>

            <InformationToggle
              label={<LocaleMessage id="summary.contact.informationToggleLabel" />}
              information={<LocaleMessage id="summary.contact.information" />}
            />
            <FormErrorMessages fields={[userDetailFields.PHONE_NUMBER]} id="phoneNumberError" />
          </FormGroup>
        </>
      )}

      {isGroupRegister ? (
        <Row className="submit-button-container" justifycenter>
          <Button
            ripple
            type="submit"
            disabled={submitting || wizardSubmitting}
            isLoading={submitting || wizardSubmitting}
          >
            <LocaleMessage id="general.cta.continue" />
          </Button>
        </Row>
      ) : (
        <NextStep descriptionLocale="personalDetails.nextDescription">
          <Button
            ripple
            type="submit"
            disabled={submitting || wizardSubmitting}
            isLoading={submitting || wizardSubmitting}
          >
            <LocaleMessage id="general.cta.continue" />
          </Button>
        </NextStep>
      )}
    </form>
  );
};

const PhoneNumberPropsWrapper = ({ country, region, phonePlaceHolder, ...restProps }) => {
  // We need to get the country from the region
  const countryToUse = country || (region && regionCodeToCountryCode[region]);

  return (
    <EnhancedField
      name={userDetailFields.PHONE_NUMBER}
      placeholder={phonePlaceHolder}
      descriptorType="static"
      component={Input}
      type="tel"
      country={countryToUse}
      format={value => phoneNumberByCountryFormatter(value, countryToUse)}
      suppress
      {...restProps}
    />
  );
};

PhoneNumberPropsWrapper.propTypes = {
  country: PropTypes.string,
  region: PropTypes.number,
  phonePlaceHolder: PropTypes.string,
};

PersonalDetails.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

PersonalDetails.propTypes = {
  submitting: PropTypes.bool,
  disallowListPasswordError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  isGroupRegister: PropTypes.bool,
  securityQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  country: PropTypes.string,
  route: PropTypes.shape({
    showCountry: PropTypes.bool,
  }),
  region: PropTypes.number, // for group members
  isFree2GoRegistration: PropTypes.bool,
  wizardSubmitting: PropTypes.bool,
  deviceState: PropTypes.number,
  validateStep: PropTypes.func,
  errorScrollOrder: PropTypes.array,
  scrollToElement: PropTypes.func,
  addressLookupFormName: PropTypes.string,
  blockingAddressFields: PropTypes.array,
  passwordValidationConfig: passwordValidationConfigShape,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName('template','PersonalDetails'),
)(PersonalDetails);
