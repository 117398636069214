import React from 'react';
import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import {
  emailAndConfirm,
  membershipCardPin,
  membershipCardNumber,
} from 'common/src/app/validation/accountValidation';
import FormNames from 'common/src/app/data/enum/FormNames';
import { cardCheck } from 'common/src/app/actions/resources/groupAccountActions';
import { push as historyPush } from 'react-router-redux';
import membershipCardCheckEnum from 'common/src/app/data/membershipCardCheckEnum';
import Pages from 'common/src/app/data/enum/Pages';
import createSimpleRequiredValidation, {
  stripSpaces,
} from 'common/src/app/validation/validationUtils';
import {
  shopFields,
  membershipCardFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import {
  initialPersonalDetailsValuesSelector,
  isInviteSelector,
} from 'common/src/app/selectors/registrationSelector';
import { getRecaptchaToken } from 'common/src/app/selectors/recaptchaSelector';
import { openModal } from 'common/src/app/actions/components/modalActions';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import ExistingLegacyAccountPromptModal from 'components/molecules/ExistingLegacyAccountPromptModal';

import CardCheck from './CardCheck';

const CardCheckValidation = {
  ...emailAndConfirm,
  ...membershipCardPin,
  ...membershipCardNumber({ requiredField: true }),
  ...createSimpleRequiredValidation(shopFields.REGION),
};

// These enum/routes will take the member to the group registration error page
const cardCheckErrorRoutes = {
  [membershipCardCheckEnum.EXISTING_ACCOUNT_VALID_ONLINE_SUBSCRIPTION]: Pages.GR_REGISTRATION_ERROR,
  [membershipCardCheckEnum.EXISTING_ACCOUNT_NO_VALID_ONLINE_OR_GROUP]: Pages.GR_REGISTRATION_ERROR,
  [membershipCardCheckEnum.CONTACT_SUPPORT]: Pages.GR_CARD_TRIES_EXCEEDED,
  [membershipCardCheckEnum.CARD_AND_PIN_COMBINATION_NOT_VALID]: Pages.GR_REGISTRATION_ERROR,
  [membershipCardCheckEnum.EXISTING_ACCOUNT_NEW_CARD]: Pages.GR_REGISTRATION_ERROR,
};

const mapStateToProps = state => ({
  submissionCount: state.view?.components?.membershipCardCountry?.count,
  isInvite: isInviteSelector(state),
  initialValues: initialPersonalDetailsValuesSelector(state),
  skipCardAndPinDuringRegistration: state.registration?.invite?.skipCardAndPinDuringRegistration,
  recaptchaToken: getRecaptchaToken(state),
});

const connected = connect(mapStateToProps, null);

const enhanced = enhancedReduxForm(
  {
    form: FormNames.CARD_CHECK,
    destroyOnUnmount: false,
    onSubmit: async (values, dispatch, { skipCardAndPinDuringRegistration, handleSubmit }) => {
      // If we are skipping card and pin entry during registration, we don't need to validate the card and pin
      if (!skipCardAndPinDuringRegistration) {
        // strip spaces
        const newValues = {
          ...values,
          [membershipCardFields.CARD_NUMBER]: stripSpaces(values[membershipCardFields.CARD_NUMBER]),
        };

        await dispatch(cardCheck(newValues)).then(result => {
          const resultFlow = result?.flow;

          // If the member has an existing legacy group account:
          // display the ExistingLegacyAccountPromptModal
          if (resultFlow === membershipCardCheckEnum.ACCOUNT_EXISTS_IN_THE_LEGACY_SYSTEM) {
            dispatch(
              openModal(
                ModalNames.CONTENT,
                {
                  disableCloseOnBackdrop: true,
                  showWrapperCloseButton: false,
                  showCloseButton: false,
                  children: <ExistingLegacyAccountPromptModal handleSubmit={handleSubmit} />,
                },
                false,
                false,
                false,
              ),
            );
          }

          // Send the member down the email recovery route
          if (resultFlow === membershipCardCheckEnum.CARD_ASSOCIATED_WITH_ANOTHER_EMAIL_ADDRESS) {
            dispatch(
              historyPush({
                pathname: Pages.RE_RECOVER_EMAIL,
              }),
            );
          }

          // Check which error the member has received and send them down the correct route
          if (resultFlow in cardCheckErrorRoutes) {
            dispatch(
              historyPush({
                pathname: `${Pages.GR_CARD_CHECK}`,
                query: {
                  modal: cardCheckErrorRoutes[resultFlow],
                  flow: resultFlow,
                },
              }),
            );
          }
        });
      }
    },
    generalErrorMessage: { locale: 'cardCheck.generalErrorMessage' },
  },
  CardCheckValidation,
);

export default compose(connected, enhanced)(CardCheck);
