import handleActions from 'redux-actions/lib/handleActions';

import {
  CARD_CHECK_SAVE,
  SAVE_ACCOUNTS_EMAIL,
  VERIFIED_IDENTITY_TOKEN,
  SUBMISSION_COUNT,
} from '../../../actions/resources/groupAccountActions';

const initialState = {
  flow: null,
  cardVerification: null,
  countryCode: null,
  count: 0,
};

export default handleActions(
  {
    [CARD_CHECK_SAVE]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SUBMISSION_COUNT]: (state, { payload }) => ({
      ...state,
      count: payload?.reset ? 0 : state.count + 1,
    }),
    [SAVE_ACCOUNTS_EMAIL]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [VERIFIED_IDENTITY_TOKEN]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);
