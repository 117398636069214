/* global 'template','Summary' */
import React, { useRef } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import subscriptionPackageShape from 'common/src/app/util/proptypes/apiEntities/subscriptionPackage';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import {
  userDetailFields,
  shopFields,
  termsFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { elementToScrollOnError } from 'common/src/app/validation/validationUtils';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import InputType from 'common/src/app/data/enum/InputType';
import { phoneNumberByCountryFormatter } from 'common/src/app/util/form/phoneNumberFormatter';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import LocaleMessage from '../../atoms/LocaleMessage';
import TextNew, { ElementTypes, FontWeight, FontSizes } from '../../atoms/TextNew';
import Input, { descriptorTypes } from '../../atoms/Input';
import Button from '../../atoms/Button';
import UpgradePackage from '../../molecules/UpgradePackage';
import ConfirmTerms from '../../molecules/ConfirmTerms';
import NextStep from '../../molecules/NextStep';
import errorBoundary from '../../hoc/ErrorBoundary';

import './summary.scss';

const Summary = (
  {
    submitting,
    handleSubmit,
    wizardSubmitting,
    selectedPackage,
    voucherGift,
    voucherCode,
    onRegistrationRoute,
    packages,
    isInvite,
    deviceState,
    errorScrollOrder,
    scrollToElement,
    validateStep,
  },
  context,
  className,
) => {
  // This component is shared and is accessed via:
  // - Online registration or transfer to online
  const fieldRefs = useRef([]);
  // Create a ref for each field that will prevent potential members progressing to the next wizard step
  // These refs are used to calculate which field we should scroll to if a particular field has an error

  const customHandleSubmit = async event => {
    event.preventDefault();
    const { element } = await elementToScrollOnError({
      fieldRefs,
      submitHandler: handleSubmit,
      fieldOrder: errorScrollOrder,
      validateFormCallback: validateStep,
    });
    const targetElement = element;
    scrollToElement(targetElement);
  };

  return (
    <form onSubmit={customHandleSubmit} autoComplete="off">
      <section className={className}>
        <header>
          <TextNew.Serif
            cid="summary-header"
            element={ElementTypes.H1}
            localeId={isInvite ? `summary.registration.inviteTitle` : `summary.registration.title`}
            size={deviceState <= DeviceState.MD ? FontSizes.MD : FontSizes.XL}
          />
        </header>

        <article className="order-summary">
          {voucherGift && (
            <TextNew.PrimaryElegant
              localeId="summary.registration.orderSummary.voucherGift"
              localeParams={{
                MONTHS: moment.duration(voucherGift?.duration).months(),
              }}
            />
          )}
        </article>

        {isInvite && (
          <TextNew.Sans.MD
            weight={FontWeight.LIGHT}
            cid="order-summary-subtitle"
            localeId={`summary.registration.orderSummary.invite`}
          />
        )}

        {selectedPackage?.description && (
          <UpgradePackage
            selectedPackage={selectedPackage}
            voucherGift={voucherGift}
            voucherCode={voucherCode}
            packages={packages}
            onRegistrationRoute={onRegistrationRoute}
          />
        )}

        <EnhancedField component={Input} type="hidden" name={shopFields.PACKAGE} />

        <ConfirmTerms
          title={
            <TextNew.Confident
              cid="confirm-terms-title"
              localeId="personalDetails.confirmTerms.title"
            />
          }
          forwardRef={ref => (fieldRefs.current[termsFields.TERMS_OF_USE] = ref)}
        />

        <NextStep
          titleLocale={
            isInvite || voucherGift?.duration
              ? 'summary.next.title.buildProfile'
              : 'summary.next.title.payment'
          }
          descriptionLocale={
            isInvite || voucherGift?.duration
              ? 'summary.next.description.buildProfile'
              : 'summary.next.description.payment'
          }
        >
          <Button
            ripple
            type={InputType.SUBMIT}
            disabled={submitting || wizardSubmitting}
            isLoading={submitting || wizardSubmitting}
          >
            <LocaleMessage id="general.cta.continue" />
          </Button>
        </NextStep>

        <FormErrorMessages showGeneral />
      </section>
    </form>
  );
};

// Selected package
const summaryPackageProps = {
  ...subscriptionPackageShape,
  id: PropTypes.string,
  name: PropTypes.string,
};

const PhoneNumberPropsWrapper = ({ country, phonePlaceHolder, ...restProps }) => (
  <EnhancedField
    descriptorType={descriptorTypes.STATIC}
    name={userDetailFields.PHONE_NUMBER}
    placeholder={phonePlaceHolder}
    component={Input}
    type="tel"
    country={country}
    format={value => phoneNumberByCountryFormatter(value, country)}
    suppress
    {...restProps}
  />
);

PhoneNumberPropsWrapper.propTypes = {
  country: PropTypes.string,
  phonePlaceHolder: PropTypes.string,
};

Summary.propTypes = {
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  wizardSubmitting: PropTypes.bool,
  selectedPackage: PropTypes.shape(summaryPackageProps),
  voucherCode: PropTypes.string,
  voucherGift: PropTypes.shape({
    duration: PropTypes.string,
  }),

  onRegistrationRoute: PropTypes.bool,
  packages: PropTypes.arrayOf(PropTypes.shape(subscriptionPackageShape)),
  isInvite: PropTypes.bool,
  deviceState: PropTypes.number,
  errorScrollOrder: PropTypes.array,
  scrollToElement: PropTypes.func,
  validateStep: PropTypes.func,
};

Summary.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName('template','Summary'),
)(Summary);
