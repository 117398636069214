import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import formValueSelector from 'redux-form/lib/formValueSelector';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { validateForm } from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import * as ValidateOn from 'common/src/app/enhanced-redux-form/data/ValidateOn';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import { makeGetErrorsForFields } from 'common/src/app/enhanced-redux-form/reducers/validationReducer';
import {
  passwordFields,
  userDetailFields,
  addressFields,
  termsFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import FormNames from 'common/src/app/data/enum/FormNames';
import { getSecurityQuestions } from 'common/src/app/actions/resources/securityQuestionActions';
import { getMemberPasswordValidationConfig } from 'common/src/app/actions/resources/accountActions';
import { GROUP } from 'common/src/app/data/enum/registrationFlowTypes';
import { isOnRegistrationFlow } from 'common/src/app/selectors/registrationFlowTypeSelector';
import { passwordValidationConfigSelector } from 'common/src/app/selectors/accountSelector';
import address from 'common/src/app/validation/addressValidation';
import {
  passwordAndConfirm,
  securityAnswer,
  phoneNumberValidation,
} from 'common/src/app/validation/accountValidation';
import Configuration from 'common/src/app/config/Configuration';
import createPostcodeValidation from 'common/src/app/validation/createPostcodeValidation';
import { isFree2GoRegisteringMember } from 'common/src/app/selectors/free2GoSelector';
import { countryCode } from 'common/src/app/data/enum/Countries';
import PersonalDetails from './PersonalDetails';

const addressLookupFormName = addressFields.BILLING_ADDRESS;

const personalDetailsValidation = {
  ...passwordAndConfirm,
  ...securityAnswer,
  ...createSimpleRequiredValidation(userDetailFields.SECURITY_QUESTION),
  ...createPostcodeValidation({
    fieldName: addressFields.ZIP_OR_POSTAL,
    countryCode: countryCode.GB,
  }),
  ...createSimpleRequiredValidation(addressFields.ADDRESS_LINE_1),
  ...createSimpleRequiredValidation(termsFields.TERMS_OF_USE, ValidateOn.CHANGE),
  ...createSimpleRequiredValidation(termsFields.USER_SUBSCRIPTION, ValidateOn.CHANGE),
  ...address(addressFields.BILLING_ADDRESS),
  ...phoneNumberValidation({
    addressType: addressFields.BILLING_ADDRESS,
  }),
};

const blockingAddressFields = [
  addressFields.ADDRESS_LOOKUP,
  addressFields.COUNTRY,
  addressFields.ADDRESS_LINE_1,
  addressFields.CITY_OR_TOWN,
  addressFields.COUNTY,
  addressFields.ZIP_OR_POSTAL,
];

const errorScrollOrder = [
  passwordFields.PASSWORD,
  passwordFields.CONFIRM_PASSWORD,
  userDetailFields.SECURITY_QUESTION,
  userDetailFields.SECURITY_ANSWER,
  `${addressLookupFormName}.${addressFields.ADDRESS_LOOKUP}`,
  `${addressLookupFormName}.${addressFields.COUNTRY}`,
  `${addressLookupFormName}.${addressFields.ADDRESS_LINE_1}`,
  `${addressLookupFormName}.${addressFields.CITY_OR_TOWN}`,
  `${addressLookupFormName}.${addressFields.COUNTY}`,
  `${addressLookupFormName}.${addressFields.ZIP_OR_POSTAL}`,
  userDetailFields.PHONE_NUMBER,
  termsFields.TERMS_OF_USE,
];

const enhanced = enhancedReduxForm(
  {
    form: FormNames.PERSONAL_DETAILS,
    destroyOnUnmount: false,
    onSubmit: values => ({
      ...values,
      [userDetailFields.SECURITY_ANSWER]: values.securityQuestionAnswer.trim(),
    }),
  },
  personalDetailsValidation,
);

const mapStateToProps = state => {
  const personalDetailsFormSelector = formValueSelector(FormNames.PERSONAL_DETAILS);
  const getErrorsForFields = makeGetErrorsForFields();
  const medicalCheckSelector = formValueSelector(FormNames.MEDICAL_CHECK);
  const introductionSelector = formValueSelector(FormNames.INTRODUCTION);
  const isGroupRegister = isOnRegistrationFlow(state, GROUP);
  const passwordError = getErrorsForFields(state.enhancedForm.validation, {
    form: FormNames.PERSONAL_DETAILS,
    fields: [passwordFields.PASSWORD],
  });

  const { securityQuestions } = state;
  // Get the region from the card check form
  const region = state.form[FormNames.CARD_CHECK]?.values.region || 3;

  const isFree2GoRegistration = isFree2GoRegisteringMember(state) || false;

  const wizardSubmitting =
    !!state.enhancedForm?.wizard?.[
      isGroupRegister() ? FormNames.GROUP_REGISTRATION : FormNames.ONLINE_REGISTRATION
    ]?.submitting;

  const country =
    personalDetailsFormSelector(
      state,
      `${addressFields.BILLING_ADDRESS}.${addressFields.COUNTRY}`,
    ) || marketConfig.defaultCountry;

  const passwordValidationConfig = passwordValidationConfigSelector(state);

  return {
    isGroupRegister: isGroupRegister(),
    securityQuestions,
    disallowListPasswordError: passwordError.find(error => error.code === 'password.isblacklisted'),
    firstName:
      medicalCheckSelector(state, userDetailFields.FIRST_NAME) ||
      introductionSelector(state, userDetailFields.FIRST_NAME),
    hasGroups: Configuration.hasGroups,
    region,
    country,
    isFree2GoRegistration,
    wizardSubmitting,
    errorScrollOrder,
    addressLookupFormName,
    blockingAddressFields,
    passwordValidationConfig,
  };
};

const mapDispatchToProps = dispatch => ({
  validateStep: () => dispatch(validateForm(personalDetailsValidation, FormNames.PERSONAL_DETAILS)),
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: (_, dispatch) =>
    Promise.all([dispatch(getSecurityQuestions()), dispatch(getMemberPasswordValidationConfig())]),
});

export default compose(addInitAction, connected, enhanced)(PersonalDetails);
