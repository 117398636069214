import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormNames from 'common/src/app/data/enum/FormNames';
import FormErrorMessages from 'components/atoms/FormErrorMessages';
import TextNew, { ElementTypes, TextTypes, Colors } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Row from 'components/atoms/Row';
import Pages from 'common/src/app/data/enum/Pages';
import LocaleMessage from 'components/atoms/LocaleMessage';
import errorBoundary from 'components/hoc/ErrorBoundary';
import GroupedPasswordInput from 'components/molecules/GroupedPasswordInput';
import PasswordValidationConfig from 'common/src/app/validation/passwordValidationConfig';

import './reset-password.scss';

type ResetPasswordProps = {
  verifyPasswordToken: (userId: string, token: string) => void;
  handleSubmit: () => void;
  submitSucceeded: boolean;
  validationError?: string;
  memberHost: string;
  location: {
    hash: string;
    query: {
      userId: string;
    };
  };
  submitting: boolean;
  disallowListPasswordError?: {
    message: string;
  };
  passwordValidationConfig: PasswordValidationConfig;
};

const ResetPassword = (
  {
    verifyPasswordToken,
    handleSubmit,
    submitSucceeded,
    validationError,
    memberHost,
    location,
    submitting = false,
    disallowListPasswordError,
    passwordValidationConfig,
  }: ResetPasswordProps,
  { getMessage }: { getMessage: (id: string, params?: Record<string, string>) => string },
  className: string,
): JSX.Element | null => {
  useEffect(() => {
    const userId = location.query.userId || '';
    const token = (location.hash || '#').substring(1);
    verifyPasswordToken(userId, token);
  }, [location.query.userId, location.hash, verifyPasswordToken]);

  if (!passwordValidationConfig) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className={className}>
      <TextNew type={TextTypes.LOUD} element={ElementTypes.H1} localeId="resetPassword.title" />
      {validationError ? (
        <>
          <TextNew type={TextTypes.PRIMARY_ORIGINAL} localeId="resetPassword.errorVerifyToken" />
          <a className="text-link" href={memberHost}>
            <TextNew
              type={TextTypes.FORMAL}
              localeId="resetPassword.linkLabel"
              element={ElementTypes.SPAN}
            />
          </a>
        </>
      ) : (
        <>
          <TextNew type={TextTypes.PRIMARY_ELEGANT} localeId="resetPassword.introText" />

          <GroupedPasswordInput
            formName={FormNames.RESET_PASSWORD}
            customPasswordValidation={
              disallowListPasswordError && (
                <TextNew type={TextTypes.ERROR} color={Colors.BRAND}>
                  {disallowListPasswordError.message}
                </TextNew>
              )
            }
            passwordValidationConfig={passwordValidationConfig}
          />

          <FormErrorMessages fields={['userId', 'token']} />

          {submitSucceeded ? (
            <>
              <TextNew type={TextTypes.FORMAL} localeId="resetPassword.succeeded" key="text" />
              <TextNew type={TextTypes.FORMAL}>
                <a key="backlink" href={Pages.LOGIN}>
                  {<LocaleMessage id="resetPassword.backLink" />}
                </a>
              </TextNew>
            </>
          ) : (
            <>
              <Row justifycenter key="row">
                <Button type="submit" disabled={submitting} id="resetPassword">
                  <LocaleMessage id="resetPassword.submitButton" />
                </Button>
              </Row>
              <FormErrorMessages showGeneral key="error" />
            </>
          )}
        </>
      )}
    </form>
  );
};

ResetPassword.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

type ResetPasswordElementType = (props: ResetPasswordProps) => JSX.Element;

export default compose<ResetPasswordElementType>(
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.TEMPLATE, 'ResetPassword'),
)(ResetPassword);
