import { createSelector } from 'reselect';

/**
 * Gets the free2Go value from state
 */
export const isFree2GoRegisteringMember = createSelector(
  [state => state.registration?.free2Go],
  free2Go => free2Go,
);

export default isFree2GoRegisteringMember;
