import handleActions from 'redux-actions/lib/handleActions';
import {
  GET_ACCOUNT_STATE,
  SET_ACCOUNT_STATE,
} from 'common/src/app/actions/resources/accountActions';
import { SET_INVITE_REGISTRATION } from 'common/src/app/actions/inviteActions';
import {
  SAVE_REFERER,
  SAVE_UTM,
  SAVE_PREFILL_SUBSCRIPTION,
  SAVE_INITIAL_SELECTED_SUBSCRIPTION,
  SAVE_UPGRADED_SUBSCRIPTION,
  TOGGLE_INITAL_SELECTED_VOUCHER,
  REGISTRATION_FLOW_TYPE,
  REGISTRATION_FREE_2_GO,
} from 'common/src/app/actions/registrationActions';

const initialState = {
  referer: null,
  source: null,
  campaign: null,
  prefillSubscriptionPackage: null,
  accountState: null,
  invite: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    label: null,
    legacyMemberId: null,
    skipPaymentDuringRegistration: null,
    skipCardAndPinDuringRegistration: null,
  },
  initialSelectedSubscriptionPackage: null,
  upgradedSubscriptionPackage: null,
  hasInitialSelectedVoucher: false,
  flow: null,
  free2Go: false,
};

export default handleActions(
  {
    [SAVE_REFERER]: (state, { payload: { referer } }) => ({
      ...state,
      referer,
    }),
    [SAVE_UTM]: (state, { payload: { campaign, source } }) => ({
      ...state,
      campaign,
      source,
    }),
    [SAVE_PREFILL_SUBSCRIPTION]: (state, { payload: { subscriptionSlug } }) => ({
      ...state,
      prefillSubscriptionPackage: subscriptionSlug,
    }),
    [SET_INVITE_REGISTRATION]: (state, { payload }) => ({
      ...state,
      invite: {
        ...payload,
      },
    }),
    [GET_ACCOUNT_STATE]: (state, { payload: { data } }) => ({
      ...state,
      accountState: data?.accountState > 0 ? data.accountState : null,
    }),
    [SET_ACCOUNT_STATE]: (state, { payload }) => ({
      ...state,
      accountState: payload,
    }),
    [SAVE_INITIAL_SELECTED_SUBSCRIPTION]: (state, { payload: { subscriptionSlug } }) => ({
      ...state,
      initialSelectedSubscriptionPackage: subscriptionSlug,
    }),
    [SAVE_UPGRADED_SUBSCRIPTION]: (state, { payload: { subscriptionSlug } }) => ({
      ...state,
      upgradedSubscriptionPackage: subscriptionSlug,
    }),
    [TOGGLE_INITAL_SELECTED_VOUCHER]: state => ({
      ...state,
      hasInitialSelectedVoucher: !state.hasInitialSelectedVoucher,
    }),
    [REGISTRATION_FLOW_TYPE]: (state, { payload }) => ({
      ...state,
      flow: payload,
    }),
    [REGISTRATION_FREE_2_GO]: (state, { payload }) => ({
      ...state,
      free2Go: payload,
    }),
  },
  initialState,
);
