import createAction from 'redux-actions/lib/createAction';
import { apiGet } from './apiActions/apiRequest';
import { GATEWAY_ACCOUNT } from '../../data/Injectables';

export const GET_SECURITY_QUESTIONS = 'securityQuestionActions/GET_SECURITY_QUESTIONS';
export const getSecurityQuestions =
  (includeUsersSelection = false) =>
  async dispatch => {
    const questions = await dispatch(
      apiGet(
        GET_SECURITY_QUESTIONS,
        GATEWAY_ACCOUNT,
        `/security-questions`,
        {
          includeUsersSelection,
        },
        {
          credentials: 'include',
        },
      ),
    );

    return dispatch(
      saveParsedSecurityQuestions(
        questions.data.map(q => ({
          title: q.question,
          value: q.id,
        })),
      ),
    );
  };

export const SAVE_SECURITY_QUESTIONS = 'securityQuestionActions/SAVE_SECURITY_QUESTIONS';
export const saveParsedSecurityQuestions = createAction(SAVE_SECURITY_QUESTIONS);
