import handleActions from 'redux-actions/lib/handleActions';
import { GET_AUTHENTICATOR_DETAILS } from 'common/src/app/actions/resources/multiFactorAuthenticationActions';

const initialState = {
  key: null,
  email: null,
  environment: null,
};

export default handleActions(
  {
    [GET_AUTHENTICATOR_DETAILS]: (state, { payload: { data } }) => ({
      ...state,
      ...data,
    }),
  },
  initialState,
);
