export const STATE_COOKIE = 'authState';
export const INITIAL_USER_STATE_COOKIE = 'ius';
// amount of seconds the auth token cookie will expire before the token itself expires
export const AUTH_TOKEN_EXPIRATION_OFFSET = 60;
export const JWT_CLOCK_TOLERANCE = 120;
// this is the name of the legacy cookie for authentication. it combined the access and id tokens
export const TOKEN_COOKIE = 'authToken';
// current cookie names for access and id tokens. they are split up into two cookies
export const LOGIN_ENDPOINT = '/login';
export const LOGOUT_ENDPOINT = '/logout';
export const RESET_AUTH_QUERY = 'resetAuth';
export const OIDC_REQUEST_TIMEOUT = 30000;
export const ASPNET_IDENTITY_APP_COOKIE = '.AspNetCore.Identity.Application';
export const ASPNET_TWO_FACTOR_APP_COOKIE = 'Identity.TwoFactorUserId';
export const IDSRV_SESSION_COOKIE = 'idsrv.session';
// this is used for swagger
export const ASPNET_DOCS_COOKIE = '.AspNetCore.Docs';
export const QUERY_VALUE_LOGOUT = 'logout';
export const USER_PERMISSION_TOKEN_COOKIE = 'upt';

export const WEB_VIEW_REDIRECT_URI = 'com.slimmingworld-app.uk://callback';
export const WEB_VIEW_FREE_2_GO_REDIRECT_URI = 'com.free2go://callback';

// Cookie to indicate that the member is browsing the site within an in app browser
export const IS_IN_APP_BROWSER_COOKIE = 'isInAppBrowser';
