import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { push as historyPush } from 'react-router-redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import FormNames from 'common/src/app/data/enum/FormNames';
import { validateForm } from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import { scrollToElement } from 'common/src/app/actions/scrollActions';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import {
  userDetailFields,
  emailFields,
} from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { emailAndConfirm } from 'common/src/app/validation/accountValidation';
import Pages from 'common/src/app/data/enum/Pages';
import { checkEmailAlreadyBeenRegistered } from 'common/src/app/actions/resources/accountActions';
import {
  initialIntroductionValuesSelector,
  isInviteSelector,
} from 'common/src/app/selectors/registrationSelector';
import Introduction from './Introduction';

const introductionValidation = {
  ...createSimpleRequiredValidation(userDetailFields.FIRST_NAME),
  ...createSimpleRequiredValidation(userDetailFields.LAST_NAME),
  ...emailAndConfirm,
};

const errorScrollOrder = [
  userDetailFields.FIRST_NAME,
  userDetailFields.LAST_NAME,
  emailFields.EMAIL_ADDRESS,
  emailFields.CONFIRM_EMAIL_ADDRESS,
];

const enhanced = enhancedReduxForm(
  {
    form: FormNames.INTRODUCTION,
    destroyOnUnmount: false,
    onSubmit: async (values, dispatch) => {
      await dispatch(checkEmailAlreadyBeenRegistered(values.email)).then(response => {
        const emailRegistered = response;

        if (emailRegistered) {
          dispatch(
            historyPush({
              pathname: Pages.WELCOME_BACK,
            }),
          );
        }
      });
    },
  },
  introductionValidation,
);

const mapStateToProps = state => ({
  introductionValidation,
  publicHost: state.config.environmentConfig.web.public.host,
  initialValues: { ...initialIntroductionValuesSelector(state) },
  isInvite: isInviteSelector(state),
  errorScrollOrder,
  compositeErrors: state.enhancedForm.validation?.introduction?.hasCompositeError,
});

const mapDispatchToProps = dispatch => ({
  validateStep: () => dispatch(validateForm(introductionValidation, FormNames.INTRODUCTION)),
  scrollToElement: (...args) => dispatch(scrollToElement(...args)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected, enhanced)(Introduction);
