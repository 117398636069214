import Product from '../../types/Product';
import Voucher from '../../types/Voucher';

/**
 * Establishes whether or not a voucher applies to one or more products
 * @param voucher {Object} An object that includes a 'discounts' property which contains one, or more, arrays of product IDs
 * @param products {Array|Object} Either a list or a single product, containing an associated ID
 * @returns {boolean}
 */
export const voucherAppliesToProducts = (voucher: Voucher, products: Product[] | Product) => {
  const { discounts = [] } = voucher;
  const discountableProductIds: string[] = [];

  discounts.forEach(discount => discountableProductIds.push(...discount?.productIds));

  if (Array.isArray(products)) {
    return !!products.find(product => discountableProductIds.some(id => id === product?.id));
  }

  return discountableProductIds.some(id => id === products?.id);
};
