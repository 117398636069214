import { kilogramsToPounds } from '../../util/conversionUtils/conversionUtils';

/**
 * Converts values from kilos to pounds with stones
 *
 * @returns {object} The resulting object value in { stones, fullPounds, remainingPounds }
 */
const decomposeWeightImperialWithStonesToMetric = kilos => {
  if (typeof kilos === 'undefined') {
    return { stones: '', fullPounds: '', remainingPounds: '' };
  }

  const parsedKilos = kilos || 0;

  const pounds = kilogramsToPounds(parsedKilos);
  const stones = Math.floor(pounds / 14);
  const poundsWithoutStones = pounds % 14;
  const fullPounds = Math.floor(poundsWithoutStones);
  const remainingPounds = parseFloat((poundsWithoutStones % 1).toFixed(2));

  return { stones, fullPounds, remainingPounds };
};

export default decomposeWeightImperialWithStonesToMetric;
