import React from 'react';
import Route from 'react-router/lib/Route';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';
import RecoverEmail from 'bundle-loader?lazy&reactHot&name=RecoverEmail!./index';
import RecoverEmailDOB from 'bundle-loader?lazy&reactHot&name=RecoverEmailDOB!./components/templates/RecoverEmailDOB';
import RevealEmail from 'bundle-loader?lazy&reactHot&name=RevealEmail!./components/templates/RevealEmail';
import RecoverEmailSecurityQuestion from 'bundle-loader?lazy&reactHot&name=RecoverEmailSecurityQuestion!./components/templates/RecoverEmailSecurityQuestion';
import { noAccount } from 'common/src/app/config/routeRequirements';

const routes = (
  <>
    <Route
      path={Pages.RE_RECOVER_EMAIL}
      {...getRouteComponentProp(RecoverEmail)}
      isWizardForm
      title="Registration | {pageTitle}"
    >
      <Route
        path={Pages.RE_SECURITY_CHECK}
        {...getRouteComponentProp(RecoverEmailDOB)}
        wizardFormOrder={0}
        requirements={[noAccount]}
        title="Security Check | Registration | {pageTitle}"
      />
      <Route
        path={Pages.RE_SECURITY_QUESTION}
        {...getRouteComponentProp(RecoverEmailSecurityQuestion)}
        wizardFormOrder={1}
        requirements={[noAccount]}
        title="Security Question | Registration | {pageTitle}"
      />
      <Route
        path={Pages.RE_EMAIL_ACCESS}
        {...getRouteComponentProp(RevealEmail)}
        wizardFormOrder={2}
        requirements={[noAccount]}
        title="Access to email | Registration | {pageTitle}"
      />
    </Route>
  </>
);

export default routes;
