import { reducer as form } from 'redux-form';
import { initReducer as init } from '@mediamonks/react-redux-component-init';
import { routerReducer as routing } from 'react-router-redux';
import pageStatus from './pageStatusReducer';
import serviceConfig from '../config/service.configdefinitions';
import config from './configReducer';
import routeHistory from './routeHistoryReducer';
import asyncReducer from './asyncReducer';
import payment from './paymentReducer';
import shopCheckout from './shopCheckoutReducer';
import enhancedForm from '../enhanced-redux-form/reducers';
import entities from './entitiesReducer';
import logout from './logoutReducer';
import nonSerializable from './nonSerializableReducer';
import error from './errorReducer';
import notifications from './notificationsReducer';
import performance from './performanceReducer';
import collections from './collectionsReducer';
import tracking from './trackingReducer';
import deviceState from './deviceStateReducer';
import seo from './seoReducer';
import externalApis from './externalApiReducer';
import authentication from './authenticationReducer';
import featureSupport from './featureSupportReducer';
import persistHideNav from './persistHideNavReducer';
import account from './accountReducer';

/**
 * Object containing all the default reducers that should be included in the main reducer
 * by every microservice.
 */
export default {
  async: asyncReducer,
  collections,
  config,
  deviceState,
  enhancedForm,
  entities,
  error,
  form,
  init,
  logout,
  nonSerializable,
  notifications,
  pageStatus,
  payment,
  shopCheckout,
  performance,
  routing,
  routeHistory,
  persistHideNav,
  seo,
  tracking,
  externalApis,
  featureSupport,
  account,
  ...(serviceConfig.useClientAuthentication || serviceConfig.useServerAuthentication
    ? { authentication }
    : {}),
};
