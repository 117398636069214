import { getKeyByValue } from 'common/src/app/util/objectUtils';

/**
 * Check that the bitwise value contains the passed in flag
 *
 * @param value the whole bitwise value, typically returned from the api
 * @param flag - the value (flag) which you'd like to check
 * @returns {integer} - 0 or 1 (0 = false, 1 = true)
 * @example bitwiseFlagCheck(item.category, STRENGTHENING)
 */

/* eslint-disable no-bitwise  */
export const hasFlag = (value, flag) => (value & flag) === flag;

/**
 * return Returns an object of flags(key, value pairs). With only ones that are
 * within the array
 *
 * @param sourceData | array of flags e.g. [1, 42, 40, 32]
 * @param flags | Object of complete set of flags eg {0: 'bill', 1: 'dave', 2: 'shela', 3: 'john', 4: 'shazza', 5: 'tracy'}
 * @returns set of availbe flags | {0: 'bill', 1: 'dave',3: 'john', 5: 'tracy'}
 * @example availableFlags([1,4], flags)
 */
export const availableFlags = (sourceData, flags) =>
  sourceData &&
  sourceData.length > 0 &&
  sourceData.reduce((acc, cur) => {
    Object.keys(flags).forEach(flagIndex => {
      if (hasFlag(cur, 1 << parseInt(flagIndex, 10))) {
        acc[flagIndex] = flags[flagIndex]; // eslint-disable-line
      }
    });
    return acc;
  }, {});

/**
 * return a single bitwise value based of an array of flags
 * @param {string} flags | array of flags e.g. [1, 5, 18, 4]]
 * @returns {number} | bitwise value
 * @example convertArrayToBitwise([1, 5, 18, 4])
 * */
export const convertArrayToBitwise = flags => flags.reduce((a, b) => a | (1 << b), 0);

/**
 * returns an array of indexs based on the bitwise that should
 * correspond to a enum of key,values pairs. For this function to work correctly
 * your key,value pairs must be in numberic order.
 * @param {number} | bitwise value
 * @returns {array} | flags | []
 * @example convertBitwiseToArray(3)
 * @returns [0, 1] this would then match up with a enum in numeric order
 *  'value1': 1, < index 0
 * 'value2': 2, < index 1
 * */
export const convertBitwiseToArray = bitwise =>
  parseInt(bitwise, 10)
    .toString(2)
    .split('')
    .reverse()
    .map((val, index) => (val === '1' ? index : null))
    .filter(val => val !== null);

/**
 * returns an array of flags which are contained within the given bitwise value.
 * @param {number} | bitwise | 3
 * @returns {array} | flags | [1, 2]
 * @example convertBitwiseToArrayOfFlags(3)
 * @returns [1, 2]
 * */
export const convertBitwiseToArrayOfFlags = (flags, bitwise) =>
  // loop over all of the flags and filter out any that are not within the bitwise
  // value given
  Object.values(flags).filter(flag => hasFlag(bitwise, flag));

/**
 * Convert an array of string values into an array of corresponding flag values
 * which then is passed into `convertArrayToBitwise` to return a single
 * bitwise value.
 * @param {array} values | array of string values which will match up a value within
 * the flags object. e.g. ["cardio", "buildingStrength"]
 * @param {object} flags | object which includes flag value and string value
 * e.g
 * {ActivityTypeEnum = {cardio: 1, buildingStrength: 2, balanceFlexibility: 3 };
 * @returns {number} | bitwise value e.g 10
 */
export const toBitwise = (values, flags) =>
  values.length === 0 ? null : values.map(a => flags[a]).reduce((a, b) => a + b, 0);

/**
 * Function which takes a single bitwise and converts into a array of strings
 * values by matching the flag value to its matching key.String value must be key see flags.
 * example.
 * @param {number} bitwise
 * @param {object} flags | object which includes flag values and string value
 * e.g
 * {ActivityTypeEnum = {cardio: 1, buildingStrength: 2, balanceFlexibility: 3 };
 * @returns {array} | e.g. ['cardio', 'buildingStrength']
 */
export const toArray = (flags, bitwise) => {
  if (!bitwise) return [];

  // return an array of matching flags within the value passed in
  const bitwiseArray = convertBitwiseToArrayOfFlags(flags, bitwise);
  // loop over the flags array and match up with string values
  return bitwiseArray.map(flag => getKeyByValue(flags, parseInt(flag, 10)));
};
