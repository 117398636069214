import CompositeInputFormatterError from '../CompositeInputFormatterError';

/**
 * Converts values from the WeightMetricFieldGroup input into a
 * number value in kg.
 *
 * @returns {number} The resulting number value in kg
 */
const composeWeightMetric = ({ kilos, grams }) => {
  if (typeof kilos === 'undefined' && typeof grams === 'undefined') {
    return kilos;
  }
  const parsedKilos = kilos ? parseInt(kilos, 10) : 0;
  const parsedGrams = grams ? parseFloat(`0.${grams}`) : 0;

  if (Number.isNaN(parsedKilos)) {
    throw new CompositeInputFormatterError({ kilos: 'Kilos should be a number' });
  }
  if (Number.isNaN(parsedGrams)) {
    throw new CompositeInputFormatterError({ grams: 'Grams should be a number' });
  }
  if (parsedGrams > 99) {
    throw new CompositeInputFormatterError({ grams: 'Grams should be smaller than 99' });
  }
  return parsedKilos + parsedGrams;
};

export default composeWeightMetric;
