import UserRole, { ElevatedRole } from '../data/enum/UserRole';

/**
 * Checks if the member is an administrator or a site moderator
 *
 * @param accountRoles
 * @returns {boolean}
 */

export default function isAdministratorOrModerator(accountRoles) {
  return (
    accountRoles &&
    (accountRoles.includes(UserRole.SITE_MODERATOR) ||
      accountRoles.includes(UserRole.ADMINISTRATOR))
  );
}

export const hasElevatedRoles = roles => ElevatedRole.some(el => roles.includes(el));
