import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { groupMember } from 'common/src/app/selectors/accountStateSelectors';
import Configuration from 'common/src/app/config/Configuration';
import FormNames from 'common/src/app/data/enum/FormNames';
import formValueSelector from 'redux-form/lib/formValueSelector';
import { defaultCountry } from 'common/src/app/config/market/market.configdefinitions';
import {
  userDetailFields,
  membershipCardFields,
  emailFields,
  passwordFields,
  medicalFields,
  measurementFields,
  dietaryFields,
  journeyFields,
} from '../data/enum/FieldNames/AccountFieldNames';
import WeighInFields, { FirstWeighInConfirmFields } from '../data/enum/FieldNames/WeighInFields';
import Pages from '../data/enum/Pages';
import WeightHeightRanges from '../data/enum/WeightHeightRanges';
import { countryCode } from '../data/enum/Countries';
import { PHONE_NUMBER_REGEX, PASSWORD_REGEX } from '../data/regexPatterns';
import { minLength, equals, equalsIgnoreCase, required } from '../util/form/basic-validations';
import { checkUserNameRegistered } from '../actions/resources/profileActions';
import { userAccountSelector } from '../selectors/userAccountSelectors';
import { passwordValidationConfigSelector } from '../selectors/accountSelector';
import * as ValidateOn from '../enhanced-redux-form/data/ValidateOn';
import getCountry from '../util/getCountry';
import { isAgeOver } from '../util/ageUtils/ageUtils';
import {
  ERROR_AGE_NOT_ALLOWED,
  ERROR_IS_PREGNANT,
  ERROR_HAS_MEDICAL_CONDITIONS,
  ERROR_HAS_READ_CONDITIONS_DISCLAIMER,
  ERROR_HAS_EATING_DISORDER,
} from '../data/validationErrorCodes';
import {
  regexTester,
  bmiLowRule,
  bmiDangerousRule,
  bmiNotAllowedRule,
  weighInValidation,
  createRequiredRule,
  editWeighInValidation,
  weightUpperRange,
  weightLowerRange,
  membershipCardNumberLength,
  usernameValid,
  usernameMaxLength,
  usernameMinLength,
} from './validationUtils';
import { isBeforeOrAfter, isPastOrCurrentDate } from '../util/dateUtils';
import AgeRanges, { journeyStartAge } from '../data/enum/AgeRanges';
import isPasswordValid from './passwordValidation';

export const getCurrentPage = () => (dispatch, getState) => {
  const state = getState();
  return state.routing.locationBeforeTransitions.pathname;
};

export const getCurrentModal = () => (dispatch, getState) => {
  const state = getState();
  return state.routing.locationBeforeTransitions.query.modal;
};

export const getDOB = () => (dispatch, getState) => {
  const state = getState();
  const userAccount = userAccountSelector(state);

  return state.entities.profile?.[userAccount.id]?.dateOfBirth;
};

export const getShopCheckoutCountryCodeOrDefaultCountryCode = () => (dispatch, getState) => {
  const state = getState();
  const checkoutValueSelector = formValueSelector(FormNames.SHOP_CHECKOUT);

  return checkoutValueSelector(state, 'yourAddress')?.country || defaultCountry;
};

const birthDateMustBeInPast = {
  rule: value => moment().isAfter(value.replace('T00:00:00Z', '')),
  message: { locale: `validation.errors.${userDetailFields.DATE_OF_BIRTH}.past` },
};

const maxAge = {
  rule: value => {
    const isOverMaxAge = moment().diff(value, 'years') > AgeRanges.GROUP_AND_ONLINE.MAX;

    return !isOverMaxAge;
  },
  message: { locale: `validation.errors.${userDetailFields.DATE_OF_BIRTH}.maxAge` },
};

export const dateOfBirthVal = {
  [userDetailFields.DATE_OF_BIRTH]: {
    validators: [
      createRequiredRule(userDetailFields.DATE_OF_BIRTH),
      birthDateMustBeInPast,
      maxAge,
      {
        rule: (value, values, fieldname, dispatch) => {
          const requiredAge =
            dispatch(getCurrentPage()) === Pages.GR_MEDICAL_CHECK
              ? AgeRanges.GROUP.REQUIRED
              : AgeRanges.ONLINE.REQUIRED;

          return isAgeOver(value, requiredAge);
        },
        code: ERROR_AGE_NOT_ALLOWED,
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const accountRecoveryBirthdate = {
  [userDetailFields.DATE_OF_BIRTH]: {
    validators: [createRequiredRule(userDetailFields.DATE_OF_BIRTH), birthDateMustBeInPast],
    validateOn: [ValidateOn.BLUR],
  },
};

const telephoneExamples = {
  [countryCode.GB]: '01234 567 890',
  [countryCode.US]: '012 345 6789',
  [countryCode.IRELAND]: '012 3456789',
  [countryCode.CYPRUS]: '01 234567',
};

let country;
let phoneNumberRegex;

export const phoneNumberValidation = ({
  addressType = '',
  transfers = false,
  shopCheckout = false,
}) => ({
  [userDetailFields.PHONE_NUMBER]: {
    validators: [
      {
        rule: (value, values, fieldName, dispatch) =>
          // if a user as not got a memberType of group
          (!dispatch(groupMember()) &&
            // and they are not signing up to become a group member
            dispatch(getCurrentPage()) !== Pages.GR_PERSONAL_DETAILS) ||
          // or transfering to online
          transfers
            ? required(value)
            : true,
        message: { locale: `validation.errors.${userDetailFields.PHONE_NUMBER}.required` },
      },
      {
        rule: (value, values, fieldName, dispatch) => {
          if (
            // signing up to become a group member
            dispatch(getCurrentPage()) === Pages.GR_PERSONAL_DETAILS
          ) {
            return true;
          }

          // If we are in the shop checkout
          // -We need get the country code value from the shop checkout state
          // this is because the country code drop down list is not displayed on this form.
          if (shopCheckout) {
            country = dispatch(getShopCheckoutCountryCodeOrDefaultCountryCode());
          } else {
            country = getCountry(values, addressType);
          }

          phoneNumberRegex = PHONE_NUMBER_REGEX[country];

          // we need to have a regex - otherwise it is not possible to validate it
          return value && phoneNumberRegex ? phoneNumberRegex.test(value) : true;
        },
        message: {
          locale: `validation.errors.${userDetailFields.PHONE_NUMBER}.invalid`,
          params: { PHONE_EXAMPLE: () => telephoneExamples[country] },
        },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
});

export const pregnancy = {
  [userDetailFields.IS_PREGNANT]: {
    validators: [
      createRequiredRule(userDetailFields.IS_PREGNANT),
      {
        rule: (value, values, fieldName, dispatch) =>
          dispatch(getCurrentPage()) !== Pages.GR_MEDICAL_CHECK ? value !== '1' : true,
        code: ERROR_IS_PREGNANT,
      },
    ],
    validateOn: [ValidateOn.CHANGE],
  },
};

export const simpleMedical = {
  [medicalFields.HAS_MEDICAL_CONDITIONS]: {
    validators: [createRequiredRule(medicalFields.HAS_MEDICAL_CONDITIONS)],
    validateOn: [ValidateOn.CHANGE],
  },
};

export const hasReadConditionsDisclaimer = {
  [medicalFields.HAS_READ_CONDITIONS_DISCLAIMER]: {
    validators: [
      {
        rule: (value, values) => value && values[medicalFields.HAS_MEDICAL_CONDITIONS] === '1',
        code: ERROR_HAS_READ_CONDITIONS_DISCLAIMER,
      },
    ],
    validateOn: [ValidateOn.CHANGE],
  },
};

export const hasMedicalConditions = {
  [medicalFields.HAS_MEDICAL_CONDITIONS]: {
    validators: [
      createRequiredRule(medicalFields.HAS_MEDICAL_CONDITIONS),
      {
        rule: (value, values) =>
          value === 0 || (value === 1 && values[medicalFields.ARE_MEDICAL_CONDITIONS]),
        message: {
          locale: `validation.errors.${medicalFields.HAS_MEDICAL_CONDITIONS}.noConditions`,
        },
        code: ERROR_HAS_MEDICAL_CONDITIONS,
      },
    ],
  },
};

export const hasEatingDisorder = {
  [medicalFields.HAS_EATING_DISORDER]: {
    validators: [
      createRequiredRule(medicalFields.HAS_EATING_DISORDER),
      {
        rule: value => value !== '1',
        code: ERROR_HAS_EATING_DISORDER,
      },
    ],
    validateOn: [ValidateOn.CHANGE],
  },
};

export const hasDietaryPref = {
  [dietaryFields.NO_PREFERENCE]: {
    validators: [
      createRequiredRule(dietaryFields.NO_PREFERENCE),
      {
        rule: (value, values) =>
          value === 0 || (value === 1 && values[dietaryFields.HAS_PREFERENCE]),
        message: { locale: `validation.errors.${dietaryFields.NO_PREFERENCE}.noPreference` },
      },
    ],
  },
};

export const emailVal = {
  [emailFields.EMAIL_ADDRESS]: {
    validators: [
      createRequiredRule(emailFields.EMAIL_ADDRESS),
      {
        rule: isEmail,
        message: { locale: `validation.errors.${emailFields.EMAIL_ADDRESS}.validator` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const emailAndConfirm = {
  ...emailVal,
  [emailFields.CONFIRM_EMAIL_ADDRESS]: {
    validators: [
      createRequiredRule(emailFields.EMAIL_ADDRESS),
      {
        rule: (value, values) => equalsIgnoreCase(value, values[emailFields.EMAIL_ADDRESS]),
        message: { locale: `validation.errors.${emailFields.CONFIRM_EMAIL_ADDRESS}.match` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const newEmail = {
  [emailFields.NEW_EMAIL_ADDRESS]: {
    validators: [
      createRequiredRule(emailFields.NEW_EMAIL_ADDRESS),
      {
        rule: isEmail,
        message: { locale: `validation.errors.${emailFields.NEW_EMAIL_ADDRESS}.validator` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

const getPasswordValidationConfig = () => (dispatch, getState) =>
  passwordValidationConfigSelector(getState());

export const password = {
  [passwordFields.PASSWORD]: {
    validators: [
      createRequiredRule(passwordFields.PASSWORD),
      {
        rule: (value, values, fieldName, dispatch) => {
          const passwordValidationConfig = dispatch(getPasswordValidationConfig());

          return !!isPasswordValid(value, passwordValidationConfig);
        },
        code: '',
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const passwordAndConfirm = {
  ...password,
  [passwordFields.CONFIRM_PASSWORD]: {
    validators: [
      createRequiredRule(passwordFields.CONFIRM_PASSWORD),
      {
        rule: (value, values) => equals(value, values[passwordFields.PASSWORD]),
        message: { locale: `validation.errors.${passwordFields.CONFIRM_PASSWORD}.match` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const newPassword = {
  [passwordFields.NEW_PASSWORD]: {
    validators: [
      createRequiredRule(passwordFields.NEW_PASSWORD),
      {
        rule: (value, values, fieldName, dispatch) => {
          const passwordValidationConfig = dispatch(getPasswordValidationConfig());

          return !!isPasswordValid(value, passwordValidationConfig);
        },
        code: '',
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const newPasswordAndConfirm = {
  ...newPassword,
  [passwordFields.CONFIRM_PASSWORD]: {
    validators: [
      createRequiredRule(passwordFields.CONFIRM_PASSWORD),
      {
        rule: (value, values) => equals(value, values[passwordFields.NEW_PASSWORD]),
        message: { locale: `validation.errors.${passwordFields.CONFIRM_PASSWORD}.match` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const newPasswordAndConfirmMismatch = {
  [passwordFields.CONFIRM_PASSWORD]: {
    validators: [
      createRequiredRule(passwordFields.CONFIRM_PASSWORD),
      {
        rule: (value, values) => equals(value, values[passwordFields.NEW_PASSWORD]),
        message: { locale: `validation.errors.${passwordFields.CONFIRM_PASSWORD}.match` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const getUsername = () => (dispatch, getState) => userAccountSelector(getState()).userName;

export const securityQuestion = {
  [userDetailFields.SECURITY_QUESTION]: {
    validators: [
      createRequiredRule(userDetailFields.SECURITY_QUESTION),
      {
        rule: value => required(value),
        message: { locale: `validation.errors.${userDetailFields.SECURITY_QUESTION}.required` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const securityAnswer = {
  [userDetailFields.SECURITY_ANSWER]: {
    validators: [
      createRequiredRule(userDetailFields.SECURITY_ANSWER),
      {
        rule: value => !minLength(value.trim(), 2),
        message: {
          locale: `validation.errors.${userDetailFields.SECURITY_ANSWER}.minlength`,
          params: { LENGTH: 2 },
        },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const username = {
  [userDetailFields.USER_NAME]: {
    validators: [
      createRequiredRule(userDetailFields.USER_NAME),
      {
        rule: value => usernameValid(value),
        message: { locale: `validation.errors.${userDetailFields.USER_NAME}.invalid` },
      },
      {
        rule: value => !usernameMaxLength(value),
        message: {
          locale: `validation.errors.${userDetailFields.USER_NAME}.maxlength`,
          params: { LENGTH: 19 },
        },
      },
      {
        rule: value => !usernameMinLength(value),
        message: {
          locale: `validation.errors.${userDetailFields.USER_NAME}.minlength`,
          params: { LENGTH: 3 },
        },
      },
      {
        rule: (value, values, fieldName, dispatch) =>
          value !== dispatch(getUsername())
            ? dispatch(checkUserNameRegistered(value)).then(response => !!response)
            : true,
        message: { locale: `validation.errors.${userDetailFields.USER_NAME}.alreadyregistered` },
      },
    ],
    validateOn: [ValidateOn.BLUR, ValidateOn.CHANGE],
  },
};

export const initialWeight = (form, validateOn = [ValidateOn.BLUR]) => ({
  [measurementFields.INITIAL_WEIGHT]: {
    validators: [
      {
        rule: value => required(value),
        message: { locale: 'validation.errors.initialWeight.required' },
      },
      weightUpperRange(form),
      weightLowerRange(form),
      bmiLowRule(form, measurementFields.INITIAL_WEIGHT),
      bmiNotAllowedRule(form, measurementFields.INITIAL_WEIGHT),
    ],
    validateOn,
  },
});

export const hasField = fieldName => (dispatch, getState) => {
  const state = getState();
  return fieldName in state.form?.transferToOnlineChecks?.registeredFields;
};

export const journeyStartWeightValidation = (
  form,
  validateOn = [ValidateOn.BLUR, ValidateOn.CHANGE],
) => ({
  [journeyFields.JOURNEY_START_WEIGHT]: {
    validators: [
      {
        rule: value => required(value),
        message: { locale: 'validation.errors.startWeight.required' },
      },
      weightUpperRange(form),
      weightLowerRange(form),
    ],
    validateOn,
  },
});

export const journeyCurrentWeightValidation = (
  form,
  isGroupMember,
  validateOn = [ValidateOn.BLUR, ValidateOn.CHANGE],
) => ({
  [journeyFields.JOURNEY_CURRENT_WEIGHT]: {
    validators: [
      {
        rule: value => required(value),
        message: { locale: 'validation.errors.currentWeight.required' },
      },
      weightUpperRange(form),
      weightLowerRange(form),
      bmiLowRule(form, journeyFields.JOURNEY_CURRENT_WEIGHT, isGroupMember),
      bmiNotAllowedRule(form, journeyFields.JOURNEY_CURRENT_WEIGHT, isGroupMember),
    ],
    validateOn,
  },
});

export const weightRangeOnly = (form, validateOn = [ValidateOn.BLUR, ValidateOn.CHANGE]) => ({
  [FirstWeighInConfirmFields.INITIAL_WEIGHT]: {
    validators: [weightUpperRange(form), weightLowerRange(form)],
    validateOn,
  },
});

export const targetWeight = (
  form,
  validateOn = [ValidateOn.BLUR, ValidateOn.CHANGE],
  transfers = false,
) => ({
  [measurementFields.TARGET_WEIGHT]: {
    validators: [
      {
        rule: (value, values, fieldName, dispatch) =>
          (!dispatch(groupMember()) || transfers) &&
          dispatch(getCurrentModal()) !== Pages.NEW_GROUP_JOURNEY_TARGET_WEIGHT
            ? required(value)
            : true,
        message: { locale: 'validation.errors.targetWeight.required' },
      },
      weightUpperRange(form),
      weightLowerRange(form),
      bmiDangerousRule(form, measurementFields.TARGET_WEIGHT),
      bmiLowRule(form, measurementFields.TARGET_WEIGHT),
    ],
    validateOn,
  },
});

export const weighInWeight = (edit = false, form) => ({
  [WeighInFields.CURRENT_WEIGHT]: {
    validators: [
      createRequiredRule(WeighInFields.CURRENT_WEIGHT),
      edit ? editWeighInValidation() : weighInValidation(),
      weightUpperRange(form),
      weightLowerRange(form),
    ],
    validateOn: [ValidateOn.BLUR, ValidateOn.CHANGE],
  },
});

export const height = form => ({
  [measurementFields.HEIGHT]: {
    validators: [
      createRequiredRule(measurementFields.HEIGHT),
      {
        rule: value => value > WeightHeightRanges.HEIGHT_LOWER_LIMIT,
        message: { locale: 'validation.errors.height.tooLow' },
      },
      {
        rule: value => value < WeightHeightRanges.HEIGHT_UPPER_LIMIT,
        message: { locale: 'validation.errors.height.tooHigh' },
      },
      bmiLowRule(form),
      bmiNotAllowedRule(form),
    ],
    validateOn: [ValidateOn.BLUR],
  },
});

export const membershipCardNumber = ({ requiredField }) => ({
  [membershipCardFields.CARD_NUMBER]: {
    validators: requiredField
      ? [
          createRequiredRule(membershipCardFields.CARD_NUMBER),
          membershipCardNumberLength(requiredField),
        ]
      : [membershipCardNumberLength(requiredField)],
    validateOn: [ValidateOn.BLUR],
  },
});

export const membershipCardPin = {
  [membershipCardFields.PIN]: {
    validators: [
      createRequiredRule(membershipCardFields.PIN),
      {
        rule: value => value.length === 5,
        message: { locale: `validation.errors.${membershipCardFields.PIN}.length` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const groupJoinDateVal = {
  [userDetailFields.GROUP_JOIN_DATE]: {
    validators: [
      createRequiredRule(userDetailFields.GROUP_JOIN_DATE),
      {
        rule: value => !isBeforeOrAfter(value),
        message: { locale: `validation.errors.groupJoinDate.past` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};

export const journeyStartDateVal = {
  [journeyFields.JOURNEY_START_DATE]: {
    validators: [
      createRequiredRule(journeyFields.JOURNEY_START_DATE),
      {
        rule: value => isPastOrCurrentDate(value),
        message: { locale: `validation.errors.journeyStartDate.past` },
      },
      {
        rule: (value, values, fieldname, dispatch) => {
          const dateOfBirth = dispatch(getDOB());
          const earliestPossible = moment(dateOfBirth).add(journeyStartAge, 'years');
          return moment(value).isAfter(earliestPossible);
        },
        message: { locale: `validation.errors.journeyStartDate.ageInvalid` },
      },
      {
        // Must explicitly return here or this rule fails
        // eslint-disable-next-line arrow-body-style
        rule: value => {
          return moment(value).isAfter(Configuration.earliestJourneyStart);
        },
        message: { locale: `validation.errors.journeyStartDate.longAgo` },
      },
    ],
    validateOn: [ValidateOn.BLUR],
  },
};
