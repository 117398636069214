/* global 'molecule','PregnancyWarning' */
import React from 'react';
import PropTypes from 'prop-types';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import TextNew from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import Button from '../../atoms/Button';
import './pregnancy-warning.scss';

const PregnancyWarning = (
  { formValues: { [userDetailFields.IS_PREGNANT]: isPregnant }, isGroupRegister },
  {},
  className,
) => {
  const groupLocale = isGroupRegister ? 'group' : 'online';

  return (
    isPregnant === '1' && (
      <div className={className}>
        <TextNew.PrimaryOriginal localeId={`pregnancyWarning.${groupLocale}.title`} />
        <TextNew.PrimaryOriginal localeId={`pregnancyWarning.${groupLocale}.message`} />
        <TextNew.PrimaryOriginal localeId={`pregnancyWarning.${groupLocale}.additionalMessage`} />
        <Button isGhostDark href={marketConfig.pregnancyCTAUrl} target="_blank">
          <LocaleMessage id={`pregnancyWarning.${groupLocale}.button`} />
        </Button>
      </div>
    )
  );
};

PregnancyWarning.propTypes = {
  isGroupRegister: PropTypes.bool,
  formValues: PropTypes.shape({
    [userDetailFields.IS_PREGNANT]: PropTypes.string,
  }),
};
PregnancyWarning.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName('molecule','PregnancyWarning')(PregnancyWarning);
