export const ERROR_IS_PREGNANT = 'isPregnant';
export const ERROR_HAS_MEDICAL_CONDITIONS = 'hasMedicalConditions';
export const ERROR_HAS_READ_CONDITIONS_DISCLAIMER = 'hasReadConditionsDisclaimer';
export const ERROR_HAS_EATING_DISORDER = 'hasEatingDisorder';
export const ERROR_BMI_NOT_ALLOWED = 'bmiNotAllowed';
export const ERROR_BMI_TOO_LOW = 'bmiTooLow';
export const ERROR_BMI_DANGEROUS = 'bmiDangerous';
export const ERROR_AGE_NOT_ALLOWED = 'ageNotAllowed';
export const ERROR_BABY_AGE_NOT_ALLOWED = 'babyAgeNotAllowed';
export const BASKET_CANNOT_GET_SHIPPING = 'basketCannotGetShipping';
export const INITIAL_WEIGHT_REQUIRED = 'initialWeightRequired';
