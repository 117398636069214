/* global 'molecule','FemaleOnlyQuestions' */
import React from 'react';
import PropTypes from 'prop-types';
import HideOnError from 'common/src/app/enhanced-redux-form/components/HideOnError';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormValueProvider from 'common/src/app/enhanced-redux-form/components/FormValueProvider';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import Configuration from 'common/src/app/config/Configuration';
import { ERROR_IS_PREGNANT } from 'common/src/app/data/validationErrorCodes';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import TextNew from '../../atoms/TextNew';
import Button from '../../atoms/Button';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import FormGroup from '../FormGroup';
import BlockingFormError from '../BlockingFormError';
import RadioButton from '../../atoms/RadioButton';
import RadioButtonGroup from '../RadioButtonGroup';
import LocaleMessage from '../../atoms/LocaleMessage';
import BreastFeedingWarning from '../BreastFeedingWarning';
import PregnancyWarning from '../PregnancyWarning';

import './female-only-questions.scss';

const FemaleOnlyQuestions = ({ isGroupRegister }, {}, className) => (
  <div className={className}>
    <FormGroup label={<LocaleMessage id="medicalCheck.groupLabels.isPregnant" />}>
      <EnhancedField
        name={userDetailFields.IS_PREGNANT}
        component={RadioButtonGroup}
        type="button"
        color={Configuration.generalButtonColor}
      >
        <RadioButton
          label={<LocaleMessage id="medicalCheck.options.toggle.yes" />}
          value="1"
          suppress
        />
        <RadioButton
          label={<LocaleMessage id="medicalCheck.options.toggle.no" />}
          value="0"
          suppress
        />
      </EnhancedField>
      <FormErrorMessages fields={[userDetailFields.IS_PREGNANT]} />
    </FormGroup>
    {isGroupRegister && (
      <FormValueProvider fields={[userDetailFields.IS_PREGNANT]}>
        <PregnancyWarning isGroupRegister />
      </FormValueProvider>
    )}
    <BlockingFormError errorCode={ERROR_IS_PREGNANT} fields={[userDetailFields.IS_PREGNANT]}>
      <div className="error-content-wrapper">
        <TextNew.Details
          cid="error-text"
          localeId="medicalCheck.blockingErrors.isPregnant.primary1"
        />
        <TextNew.PrimaryElegant
          cid="error-text"
          localeId="medicalCheck.blockingErrors.isPregnant.primary2"
        />
        <TextNew.PrimaryElegant
          cid="error-text"
          localeId="medicalCheck.blockingErrors.isPregnant.secondary1"
        />
        <TextNew.PrimaryElegant
          cid="error-text"
          localeId="medicalCheck.blockingErrors.isPregnant.secondary2"
        />
        <Button href={marketConfig.pregnancyCTAUrl} target="_blank">
          <LocaleMessage id="medicalCheck.blockingErrors.isPregnant.mumsCTA" />
        </Button>
      </div>
    </BlockingFormError>
    <HideOnError errorCodes={[ERROR_IS_PREGNANT]} fields={[userDetailFields.IS_PREGNANT]}>
      <FormGroup
        label={<LocaleMessage id="medicalCheck.groupLabels.isBreastfeeding" />}
        cid="breast-feeding"
      >
        <EnhancedField
          name={userDetailFields.IS_BREASTFEEDING}
          component={RadioButtonGroup}
          type="button"
          color={Configuration.generalButtonColor}
        >
          <RadioButton
            label={<LocaleMessage id="medicalCheck.options.toggle.yes" />}
            value="1"
            suppress
          />
          <RadioButton
            label={<LocaleMessage id="medicalCheck.options.toggle.no" />}
            value="0"
            suppress
          />
        </EnhancedField>
        <FormErrorMessages fields={[userDetailFields.IS_BREASTFEEDING]} />
      </FormGroup>
      <FormValueProvider fields={[userDetailFields.IS_BREASTFEEDING]}>
        <BreastFeedingWarning isGroupRegister={isGroupRegister} />
      </FormValueProvider>
    </HideOnError>
  </div>
);

FemaleOnlyQuestions.propTypes = {
  isGroupRegister: PropTypes.bool,
};

export default withFunctionalClassName('molecule','FemaleOnlyQuestions')(FemaleOnlyQuestions);
