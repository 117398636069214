import { handleActionsAsync } from '../util/asyncReducer';
import {
  PROCESS_STAGE_STATUS_CONFIG,
  RUN_STATUS_CHECK,
  RUN_PAGE_STATUS_CHECK,
} from '../actions/pageStatusActions';

const pageStatusReducer = handleActionsAsync(
  {
    [PROCESS_STAGE_STATUS_CONFIG]: (state, { payload: { paths } }) => ({
      ...state,
      pages: paths.reduce((result, page) => {
        // eslint-disable-next-line no-param-reassign
        result[page] = {
          status: 'idle',
        };
        return result;
      }, {}),
    }),
    [RUN_STATUS_CHECK]: {
      pending: state => ({ ...state, running: true }),
      resolved: state => ({ ...state, running: false }),
    },
    [RUN_PAGE_STATUS_CHECK]: {
      pending: (state, { meta: { page } }) => ({
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            status: 'running',
          },
        },
      }),
      rejected: (state, { payload, meta: { page } }) => {
        const newState = {
          error: payload.error.error,
          status: 'error',
        };

        return {
          ...state,
          pages: {
            ...state.pages,
            [page]: {
              ...state.pages[page],
              ...newState,
            },
          },
        };
      },
      resolved: (state, { payload, meta: { page } }) => {
        const newState = { ...payload };
        if (payload.redirect) {
          newState.status = 'ignored';
          newState.reason = 'redirect';
        } else if (payload.error) {
          newState.status = 'error';
        } else if (!payload.status) {
          newState.status = 'completed';
        }

        return {
          ...state,
          pages: {
            ...state.pages,
            [page]: {
              ...state.pages[page],
              ...newState,
            },
          },
        };
      },
    },
  },
  {
    pages: {},
    running: false,
  },
);

export default pageStatusReducer;
