import moment from 'moment';
import { createAction } from 'redux-actions';
import { change, reset } from 'redux-form';
import { userIdSelector } from '../../selectors/userAccountSelectors';
import { GATEWAY_FOOD_AUTH } from '../../data/Injectables';
import { PLANNED_DAY_OVERVIEW, MEAL_CATEGORY, PLANNED_DAY } from '../../data/entityTypes';
import { retriggerAnimation } from '../components/lottieActions';
import { mealCategoryCollectionId } from '../../data/collectionIds';
import { debug } from '../../util/plannerActionUtils';
import { YYYYMMDD } from '../../data/enum/dateFormats';
import { closeModal } from '../components/modalActions';
import { apiDelete, apiPatch, apiPost } from './apiActions/apiRequest';
import { deleteFoodMultiple } from './plannerFoodActions';
import { getDaysBetween } from '../../util/getTimeBetween';
import { deleteRecipe } from './plannerRecipeActions';
import { CopyFoodFields, CopyFoodValues } from '../../data/enum/FieldNames/CopyFoodFieldNames';
import FormNames from '../../data/enum/FormNames';
import apiGetCollection from './apiActions/apiGetCollection';
import { setEntity, removeEntities } from '../entities/entityActions';
import apiGetEntity from './apiActions/apiGetEntity';
import authenticate from '../../util/auth/authenticate';

const plannerRefreshFood = async (dispatch, activeDate) => {
  await dispatch(getPlannerDayFood(activeDate));
  await dispatch(getPlannerDayFoodStats(activeDate));
  await dispatch(retriggerAnimation(true));
  return dispatch(setSummaryLoading(false));
};

// Get the users meal categories
export const GET_USER_MEAL_CATEGORIES = 'plannerFoodSummaryActions/GET_USER_MEAL_CATEGORIES';
export const getUserMealCategories =
  (limit = 12) =>
  async (dispatch, getState) => {
    await authenticate();
    const userId = userIdSelector(getState());
    return dispatch(
      apiGetCollection(
        GET_USER_MEAL_CATEGORIES,
        GATEWAY_FOOD_AUTH,
        `/mealcategories/${userId}`,
        mealCategoryCollectionId({ userId, limit }),
        {
          limit,
        },
        {
          transformResponse: response => ({
            data: response,
          }),
          entityType: MEAL_CATEGORY,
        },
      ),
    );
  };

// Create a new empty planned day
export const CREATE_PLANNED_DAY = 'plannerFoodSummaryActions/CREATE_PLANNED_DAY';
export const createPlannedDay =
  (planDate, isSP, plannedDayToReuseId, mealCategoryIdsToReuse) => (dispatch, getState) => {
    const userId = userIdSelector(getState());
    return dispatch(
      apiPost(CREATE_PLANNED_DAY, GATEWAY_FOOD_AUTH, `/members/${userId}/days`, {
        isSP,
        planDate,
        plannedDayToReuseId,
        mealCategoryIdsToReuse,
      }),
    ).then(() =>
      Promise.all([
        dispatch(setSummaryLoading(true)),
        dispatch(getPlannerDayFood(planDate)),
        dispatch(getPlannerDayFoodStats(planDate)),
        dispatch(retriggerAnimation(true)),
      ]).then(() => dispatch(setSummaryLoading(false))),
    );
  };

// Delete all food for planned day
export const DELETE_DAY = 'plannerFoodSummaryActions/DELETE_DAY';
export const deleteDay = (activeDay, activeDayId) => async (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);

  await dispatch(
    apiDelete(DELETE_DAY, GATEWAY_FOOD_AUTH, `/members/${userId}/days/${activeDayId}`),
  ).catch(err => debug(err));
  await dispatch(setSummaryLoading(true));
  await dispatch(closeModal());
  await dispatch(removeEntities([activeDay], PLANNED_DAY));
  await dispatch(getPlannerDayFood(activeDay));
  await dispatch(getPlannerDayFoodStats(activeDay));
  return dispatch(setSummaryLoading(false));
};

// Returns the foods and recipes tracked by a user based on date passed in
export const GET_PLANNED_DAY_FOOD = 'plannerFoodSummaryActions/GET_PLANNED_DAY_FOOD';
export const getPlannerDayFood =
  (date, includeFood = true) =>
  async (dispatch, getState) => {
    await authenticate();
    const state = getState();
    const selectedDate = state.planner.activePlannedDay || moment().format(YYYYMMDD);
    const getFresh = date === selectedDate;
    const userId = userIdSelector(getState());

    return dispatch(
      apiGetEntity(
        GET_PLANNED_DAY_FOOD,
        GATEWAY_FOOD_AUTH,
        `/members/${userId}/days/date/${date}`,
        PLANNED_DAY,
        date,
        {
          requestData: {
            includeFood,
          },
          transformResponse: entity => ({ data: entity }),
          caching: getFresh ? false : undefined,
          requestOptions: {
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
            },
          },
        },
      ),
    ).catch(err => debug(err.message || 'Error when requesting planned day'));
  };

// Returns the syns, healthy extras allowance and the users current totals for a planned day
export const GET_PLANNED_DAY_FOOD_STATS = 'plannerFoodSummaryActions/GET_PLANNED_DAY_FOOD_STATS';
export const getPlannerDayFoodStats = date => async (dispatch, getState) => {
  await authenticate();
  const state = getState();
  const selectedDate = state.planner.activePlannedDay || moment().format(YYYYMMDD);
  const getFresh = date === selectedDate;
  const userId = userIdSelector(getState());

  return dispatch(
    apiGetEntity(
      GET_PLANNED_DAY_FOOD_STATS,
      GATEWAY_FOOD_AUTH,
      `/members/${userId}/plannedfoods/totalbydate/${date}`,
      PLANNED_DAY_OVERVIEW,
      date,
      {
        transformResponse: result => ({ data: result }),
        transformEntity: entity => ({ ...entity, date }),
        caching: getFresh ? false : undefined,
        requestOptions: {
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        },
      },
    ),
  ).catch(err => debug(err.message || 'Error when requesting planned day overview'));
};

// Switch a currently existing planned day between Extra easy SP & Extra easy
export const UPDATE_PLAN_TYPE = 'plannerFoodSummaryActions/UPDATE_PLAN_TYPE';
export const updatePlanType = (planId, isSP) => (dispatch, getState) => {
  const state = getState();
  const userId = userIdSelector(state);
  const date = state.planner.activePlannedDay || moment().format(YYYYMMDD);
  return dispatch(
    apiPatch(UPDATE_PLAN_TYPE, GATEWAY_FOOD_AUTH, `/members/${userId}/days/${planId}`, {
      isSP,
    }),
  ).then(() =>
    Promise.all([
      dispatch(setSummaryLoading(true)),
      dispatch(setEntity(PLANNED_DAY, date, { isSP }, true)),
      dispatch(getPlannerDayFoodStats(date)),
      dispatch(retriggerAnimation(true)),
    ]).then(() => dispatch(setSummaryLoading(false))),
  );
};

export const SET_SUMMARY_LOADING = 'plannerFoodSummaryActions/SET_SUMMARY_LOADING';
export const setSummaryLoading = createAction(SET_SUMMARY_LOADING);

export const SET_SUMMARY_LOADING_HEIGHT = 'plannerFoodSummaryActions/SET_SUMMARY_LOADING_HEIGHT';
export const setSummaryLoadingHeight = createAction(SET_SUMMARY_LOADING_HEIGHT);

export const SET_SUMMARY_HEIGHT = 'plannerFoodSummaryActions/SET_SUMMARY_HEIGHT';
export const setSummaryHeight = createAction(SET_SUMMARY_HEIGHT);

// The day the user currently has active on the daily summary page
export const UPDATE_ACTIVE_PLANNED_DAY = 'plannerFoodSummaryActions/UPDATE_ACTIVE_PLANNED_DAY';
export const updateActiveDate = createAction(UPDATE_ACTIVE_PLANNED_DAY);

// The day the user currently wants to copy food to on the daily summary page
export const UPDATE_COPY_FOOD_PLANNED_DAY =
  'plannerFoodSummaryActions/UPDATE_COPY_FOOD_PLANNED_DAY';
export const updateCopyFoodPlannedDay = createAction(UPDATE_COPY_FOOD_PLANNED_DAY);

// Editing food state
export const SET_EDIT_FOOD = 'plannerFoodSummaryActions/SET_EDIT_FOOD';
export const setEditFood = createAction(SET_EDIT_FOOD);

// User has copied food
export const UPDATE_FOOD_COPIED = 'plannerFoodSummaryActions/UPDATE_FOOD_COPIED';
export const updateFoodCopied = createAction(UPDATE_FOOD_COPIED);

export const UPDATE_ACTIVE_PORTION = 'plannerFoodSummaryActions/UPDATE_ACTIVE_PORTION';
export const updateActivePortion = createAction(UPDATE_ACTIVE_PORTION);

// Update the dayoffset of the carousel, this allows us to keep the current day active
// when the user leaves the page
export const UPDATE_PLANNER_OFFSET = 'plannerFoodSummaryActions/UPDATE_PLANNER_OFFSET';
export const updatePlannerOffset = createAction(UPDATE_PLANNER_OFFSET);

// Update the dayoffset active slide out of three that display
export const UPDATE_PLANNER_ACTIVE_SLIDE = 'plannerFoodSummaryActions/UPDATE_PLANNER_ACTIVE_SLIDE';
export const updatePlannerActiveSlide = createAction(UPDATE_PLANNER_ACTIVE_SLIDE);

// The default category when adding food, this changes when a member selects
// a new category from the planner entry component
export const UPDATE_ACTIVE_MEAL_CATEGORY = 'plannerFoodSummaryActions/UPDATE_ACTIVE_MEAL_CATEGORY';
export const updateActiveMealCategory = createAction(UPDATE_ACTIVE_MEAL_CATEGORY);

export const UPDATE_FIRST_LOAD = 'plannerActions/UPDATE_FIRST_LOAD';
export const updateFirstLoad = createAction(UPDATE_FIRST_LOAD);

export const UPDATE_MAX_SYNS_WARNING = 'plannerActions/UPDATE_MAX_SYNS_WARNING';
export const updateMaxSynsWarning = createAction(UPDATE_MAX_SYNS_WARNING);

export const REMOVE_MAX_SYNS_WARNING = 'plannerActions/REMOVE_MAX_SYNS_WARNING';
export const removeMaxSynsWarning = createAction(REMOVE_MAX_SYNS_WARNING);

export const UPDATE_ITEM_ADDED = 'plannerActions/UPDATE_ITEM_ADDED';
export const updateItemAdded = createAction(UPDATE_ITEM_ADDED);

export const updateDateField = date => dispatch => {
  const selectedDateFormatted = moment(date).format(YYYYMMDD);

  return dispatch(updateCopyFoodPlannedDay(selectedDateFormatted));
};

export const changeCheckedMealCategory = categoryId => (dispatch, getState) => {
  const state = getState();
  const mealCategories = state.entities?.mealCategory;

  if (categoryId === CopyFoodValues.ALL_FOOD) {
    mealCategories &&
      Object.values(mealCategories).map(({ id }) =>
        dispatch(
          change(
            FormNames.COPY_FOOD,
            `_composite-input-${CopyFoodFields.FOOD_CATEGORIES}.${id}`,
            false,
          ),
        ),
      );
  }

  return dispatch(
    change(
      FormNames.COPY_FOOD,
      `_composite-input-${CopyFoodFields.FOOD_CATEGORIES}.${CopyFoodValues.ALL_FOOD}`,
      false,
    ),
  );
};

export const copyFoodToAnotherDay = mealCategoryIdsToReuse => async (dispatch, getState) => {
  const state = getState();
  const planDate = state.planner?.copyFoodPlannedDay;
  const activeDayDate = state.planner?.activePlannedDay;
  const isSP = state.entities?.plannedDay?.[activeDayDate]?.isSP;
  const plannedDayToReuseId = state.entities?.plannedDay?.[activeDayDate]?.id;
  const offset = getDaysBetween(moment().startOf('day'), planDate);

  await dispatch(setSummaryLoading(true));
  await dispatch(closeModal());
  await dispatch(createPlannedDay(planDate, isSP, plannedDayToReuseId, mealCategoryIdsToReuse));
  await dispatch(updateActiveDate(planDate));
  await dispatch(updateFoodCopied(true));
  await dispatch(updatePlannerOffset(offset));
  return plannerRefreshFood(dispatch, planDate);
};

export const editFood = (foodList, recipeList) => async (dispatch, getState) => {
  const state = getState();
  const activeDate = state.planner?.activePlannedDay;
  const plannedDayId = state.entities?.plannedDay?.[activeDate]?.id;

  await dispatch(setSummaryLoading(true));

  if (recipeList && recipeList.length > 0) {
    for (let index = 0; index < recipeList.length; index++) {
      await dispatch(deleteRecipe(activeDate, recipeList[index]));
    }
  }
  foodList && foodList.length > 0 && (await dispatch(deleteFoodMultiple(foodList, plannedDayId)));

  await dispatch(setEditFood(false));
  await dispatch(closeModal());

  return plannerRefreshFood(dispatch, activeDate);
};

export const clearEditPlanForm = () => dispatch =>
  Promise.all([dispatch(reset(FormNames.EDIT_PLAN)), dispatch(setEditFood(false))]);
