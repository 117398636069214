import React from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Colors from 'common/src/app/data/enum/Colors';
import sizePropTypes from 'common/src/app/util/proptypes/sizePropTypes';
import './wrapper.scss';

export const WrapperSizes = {
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
};
WrapperSizes.propTypes = Object.values(WrapperSizes);

const WrapperWithoutClassName = ({ children, reference }, context, className, dataTestid) => (
  <div className={className} ref={reference} data-testid={dataTestid}>
    {children}
  </div>
);

WrapperWithoutClassName.defaultProps = {
  width: 'lg',
  background: 'transparent',
};

WrapperWithoutClassName.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  width: PropTypes.oneOf(sizePropTypes), // eslint-disable-line react/no-unused-prop-types
  padding: PropTypes.oneOf(sizePropTypes), // eslint-disable-line react/no-unused-prop-types
  paddingBottom: PropTypes.oneOf(sizePropTypes), // eslint-disable-line react/no-unused-prop-types
  background: PropTypes.oneOf(Colors.propTypes), // eslint-disable-line react/no-unused-prop-types
  reference: PropTypes.func,
};

const Wrapper = withFunctionalClassName(ComponentType.ATOM, 'Wrapper', [
  '{width}-width',
  '{padding}-padding',
  '{paddingBottom}-padding-bottom',
  '{background}-background',
  '{className}',
])(WrapperWithoutClassName);

WrapperSizes.propTypes.forEach(size => {
  Wrapper[size.toUpperCase()] = props => <Wrapper width={size} {...props} />;
});

export default Wrapper;
