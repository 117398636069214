/* global 'template','LoggedOut' */
import React from 'react';
import Link from 'react-router/lib/Link';
import TextNew from 'components/atoms/TextNew';
import Pages from 'common/src/app/data/enum/Pages';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

const LoggedOut = (props, context, className) => (
  <div className={className}>
    <TextNew.Loud localeId="loggedOut.title" />
    <TextNew.PrimaryOriginal localeId="loggedOut.subTitle" />
    <TextNew.Formal>
      <Link className="text-link" href={Pages.LOGIN}>
        <LocaleMessage id="loggedOut.linkLabel" />
      </Link>
    </TextNew.Formal>
  </div>
);

export default withFunctionalClassName('template','LoggedOut')(LoggedOut);
