/* global 'molecule','BreastFeedingWarning' */
import React from 'react';
import PropTypes from 'prop-types';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { userDetailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import TextNew from '../../atoms/TextNew';
import LocaleMessage from '../../atoms/LocaleMessage';
import Button from '../../atoms/Button';
import './breast-feeding-warning.scss';

const BreastFeedingWarning = (
  { formValues: { [userDetailFields.IS_BREASTFEEDING]: isBreastfeeding } },
  {},
  className,
) =>
  isBreastfeeding === '1' && (
    <div className={className}>
      <TextNew.PrimaryOriginal localeId="breastFeedingWarning.title" />
      <TextNew.PrimaryOriginal localeId="breastFeedingWarning.additionalMessage" />
      <TextNew.PrimaryOriginal localeId="breastFeedingWarning.buttonMessage" />
      <Button isGhostDark href={marketConfig.pregnancyCTAUrl} target="_blank">
        <LocaleMessage id={`breastFeedingWarning.button`} />
      </Button>
    </div>
  );
BreastFeedingWarning.propTypes = {
  formValues: PropTypes.shape({
    [userDetailFields.IS_BREASTFEEDING]: PropTypes.string,
  }),
};

export default withFunctionalClassName('molecule','BreastFeedingWarning')(BreastFeedingWarning);
