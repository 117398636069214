const LocalStorageKeys = {
  LOGIN: 'login',
  USER_NAME: 'userName',
  REMEMBER_ME: 'rememberMe',
  MFA: 'mfa',
};

export const SessionStorageKeys = {
  RECOVERED_EMAIL_LOGIN: 'login',
  USER_EMAIL: 'userName',
};

export default LocalStorageKeys;
