import createAction from 'redux-actions/lib/createAction';
import { GATEWAY_ACCOUNT } from '../data/Injectables';
import { apiGet } from './resources/apiActions/apiRequest';

export const GET_INVITE_REGISTRATION = 'registrationActions/GET_INVITE_REGISTRATION';
export const SET_INVITE_REGISTRATION = 'registrationActions/SET_INVITE_REGISTRATION';

export const setInviteRegistration = createAction(SET_INVITE_REGISTRATION);

export const getInviteRegistration = inviteId => dispatch =>
  dispatch(apiGet(GET_INVITE_REGISTRATION, GATEWAY_ACCOUNT, `/invites/${inviteId}`)).then(
    ({ data }) => dispatch(setInviteRegistration(data)),
  );
