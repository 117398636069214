import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import { emailFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import FormGroup from '../../molecules/FormGroup';
import FormErrorMessages from '../FormErrorMessages';
import Input from '../Input';
import InformationToggle from '../../molecules/InformationToggle';
import LocaleMessage from '../LocaleMessage';
import Row from '../Row';

const EmailInput = ({ isInvite, title, information, forwardRef }, { getMessage }) => (
  <FormGroup label={<LocaleMessage id={title} />} type="stacked">
    <Row column>
      <EnhancedField
        placeholder={getMessage('emailInput.emailPlaceholder')}
        name={emailFields.EMAIL_ADDRESS}
        component={Input}
        type="email"
        disabled={isInvite}
        inputRef={forwardRef?.emailRef}
        suppress
      />
      <FormErrorMessages fields={[emailFields.EMAIL_ADDRESS]} />
      <EnhancedField
        placeholder={getMessage('emailInput.emailConfirmPlaceholder')}
        name={emailFields.CONFIRM_EMAIL_ADDRESS}
        component={Input}
        type="email"
        onPaste={e => e.preventDefault()}
        disabled={isInvite}
        inputRef={forwardRef?.confirmEmailRef}
        suppress
      />
      <FormErrorMessages fields={[emailFields.CONFIRM_EMAIL_ADDRESS]} />
    </Row>
    {information && (
      <InformationToggle
        label={<LocaleMessage id="emailInput.informationToggleLabel" />}
        information={information}
      />
    )}
  </FormGroup>
);

EmailInput.defaultProps = {
  information: <LocaleMessage id="emailInput.emailInformation" />,
};

EmailInput.propTypes = {
  isInvite: PropTypes.bool,
  title: PropTypes.string,
  information: PropTypes.object,
  forwardRef: PropTypes.object,
};

EmailInput.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(ComponentType.ATOM, 'EmailInput')(EmailInput);
