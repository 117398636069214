import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND } from 'common/src/app/data/apiComponents';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import Payment from './Payment';

const connected = connect(state => ({
  background: state.entities?.[COMPONENT]?.[API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND]?.image,
}));

const addInitAction = withInitAction({
  prepared: ({}, dispatch) => dispatch(getComponent(API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND)),
});

export default compose(addInitAction, connected)(Payment);
