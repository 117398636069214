import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { ReduxFormContext } from 'redux-form/lib/ReduxFormContext';
import { inputManual, inputSearch } from 'common/src/app/actions/components/addressLookupActions';
import { getApi } from 'common/src/app/actions/externalApiActions';
import AddressLookup from './AddressLookup';

const ConnectedAddressLookup = connect(
  state => ({
    manualInput: state.view.components.addressLookup.manualInput,
  }),
  (dispatch, { name, form }) => ({
    setAddressField: (field, value) => dispatch(change(form, `${name}.${field}`, value)),
    getApi: api => dispatch(getApi(api)),
    inputManual: () => dispatch(inputManual()),
    inputSearch: () => dispatch(inputSearch()),
  }),
)(AddressLookup);

ConnectedAddressLookup.defaultProps = {
  showCountry: true,
};
ConnectedAddressLookup.propTypes = {
  form: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  showCountry: PropTypes.bool,
};

const AddressLookupProvider = props => {
  const { form } = useContext(ReduxFormContext);
  return <ConnectedAddressLookup {...props} form={form} />;
};

AddressLookupProvider.propTypes = {
  name: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
};
export default AddressLookupProvider;
