import { connect } from 'react-redux';
import compose from 'redux/lib/compose';

import MembershipCardInput from './MembershipCardInput';

const connected = connect(state => ({
  cardValues: state.form.cardCheck?.values,
}));

export default compose(connected)(MembershipCardInput);
