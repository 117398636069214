/* global WP_DEFINE_IS_NODE */
import { getValue } from '../injector';
import { AUTHENTICATION_MANAGER } from '../../data/Injectables';

/**
 * Gets the accessToken from the user. When no valid accessToken is available, it
 * either throws an error (on Node), or does an explicit redirect (on Browser).
 *
 * @return {*} Promise ith accessToken
 */
export default function authenticate() {
  if (WP_DEFINE_IS_NODE) {
    // we don't have an access token on the server side. So this should return null
    return Promise.resolve(null);
  }

  // else
  const authenticationManager = getValue(AUTHENTICATION_MANAGER);
  return authenticationManager.getUser().then(user => {
    if (user) {
      return user.access_token;
    }

    authenticationManager.executeRedirectSignin();

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Access token required, redirecting to sign in');
  });
}
