/* global 'atom','ImageBlock' */
import React from 'react';
import PropTypes from 'prop-types';
import imageShape from 'common/src/app/util/proptypes/apiEntities/image';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from '../RichTextBlock';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';

/**
 * Displays a image block
 */
// eslint-disable-next-line react/prop-types
const ImageBlock = ({ image: { src, alt }, caption }, context, className) => (
  <div className={className}>
    <ResponsiveImage src={src} alt={alt} title={alt} />
    {caption ? <RichTextBlock text={caption} /> : null}
  </div>
);

ImageBlock.propTypes = {
  image: PropTypes.shape({
    ...imageShape,
  }),
  caption: PropTypes.string,
};

export default withFunctionalClassName('atom','ImageBlock')(ImageBlock);
