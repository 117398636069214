import React from 'react';
import { connect } from 'react-redux';
import WebHost from 'common/src/app/data/enum/WebHost';
import WebHostLink from './WebHostLink';

export { WebHost };

interface OwnProps {
  children: React.ReactNode;
  webHost?: string;
  link?: string;
  newWindow?: string;
  className?: string;
  innerRef?: React.RefObject<any>;
  onClick?: () => void;
  shouldPrependWebHost?: boolean;
}

interface PropsFromState {
  environmentConfig: any;
}

export type WebHostLinkProps = OwnProps & PropsFromState;

interface ReduxState {
  config: { environmentConfig: any };
}
const mapStateToProps = (state: ReduxState): PropsFromState => ({
  environmentConfig: state.config.environmentConfig,
});

export default connect<PropsFromState, Record<string, unknown>, OwnProps, ReduxState>(
  mapStateToProps,
)(WebHostLink);
