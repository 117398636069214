import { compose } from 'redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import { forgotPasswordHandler } from 'common/src/app/actions/resources/accountActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import { emailVal } from 'common/src/app/validation/accountValidation';
import ForgotPassword from './ForgotPassword';

const ForgotPasswordValidation = {
  ...emailVal,
};

const enhanced = enhancedReduxForm(
  {
    form: FormNames.FORGOT_PASSWORD,
    onSubmit: (values, dispatch) => dispatch(forgotPasswordHandler(values.email)),
    generalErrorMessage: { locale: 'forgotPassword.generalErrorMessage' },
  },
  ForgotPasswordValidation,
);

export default compose(enhanced)(ForgotPassword);
