import shop from './shopBasketReducer';
import productDetail from './productDetailReducer';
import header from './HeaderReducer';
import passwordInput from './passwordInputReducer';
import modal from './modalReducer';
import addressLookup from './addressLookupReducer';
import deepLinks from './deepLinkReducer';
import scrollManager from './scrollManagerReducer';
import grid from './gridViewReducer';
import lottie from './lottieReducer';
import dayPicker from './dayPickerReducer';
import contactUs from './contactUsReducer';
import membershipCardCountry from './membershipCardCountryReducer';
import combineReducersNamed from '../../../util/reducers/combineReducersNamed';
import instagramTrending from './instagramTrendingReducer';
import filter from './filterReducer';
import emailRecovery from './emailRecoveryReducer';

export default combineReducersNamed({
  passwordInput,
  addressLookup,
  header,
  lottie,
  dayPicker,
  scrollManager,
  shop,
  productDetail,
  modal,
  deepLinks,
  membershipCardCountry,
  grid,
  instagramTrending,
  contactUs,
  filter,
  emailRecovery,
});
