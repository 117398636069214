import { ONLY_SINGLE_DIGITS } from '../../data/regexPatterns';
import CompositeInputFormatterError from '../CompositeInputFormatterError';

/**
 * Converts values from the HeightMetricFieldGroup input into a number value in centimetres
 * @example User entry: 1.3, value: 130cm
 * @example User entry: 1.33, value: 133cm
 * @param {object} formValues
 * @param {string} formValues.metres A string (that contains an integer) or an integer representing
 * the amount of metres
 * @param {string} formValues.centimetres A string (that contains an integer) or an integer representing
 * the amount entered into the centimetres box
 * @returns {number} The resulting number value in centimetres
 *
 * @example
 */

const setCentimeters = centimetres => {
  // checking single digit and converting to 10cm e.g. 5 to 50
  if (ONLY_SINGLE_DIGITS.test(centimetres)) {
    return centimetres * 10;
  }

  return centimetres;
};

/**
 * Takes an object containing both metre and centimetre values and converts these to a single metric value
 * @param {object} heightValues
 * @param {string|number} heightValues.metres
 * @param {string|number} heightValues.centimetres
 * @returns {number|undefined}
 *
 */

const composeHeightMetric = ({ metres, centimetres }) => {
  if (!metres && !centimetres) return undefined;

  let metresValue = Number(metres);
  let centimetresValue = Number(centimetres);

  metresValue = !Number.isNaN(metresValue) ? metresValue : 0;
  centimetresValue = !Number.isNaN(centimetresValue) ? Number(setCentimeters(centimetres)) : 0;

  if (metresValue >= 3) {
    throw new CompositeInputFormatterError({ metres: 'Metres should be smaller than 3' });
  }

  if (centimetresValue > 99) {
    throw new CompositeInputFormatterError({
      centimetres: 'Centimetres should be smaller than 100',
    });
  }

  return metresValue * 100 + centimetresValue;
};

export default composeHeightMetric;
