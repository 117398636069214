/* global WP_DEFINE_IS_NODE */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import TextNew, { ElementTypes } from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import Wrapper from 'components/atoms/Wrapper';
import LocaleMessage from 'components/atoms/LocaleMessage';
import { isIOS } from 'common/src/app/util/deviceUtil';

import './free-2-go-landing.scss';

const Free2GoLanding = ({ free2GoAppIsReleased, androidAppUrl, iosAppUrl }, {}, className) => {
  const [appLink, setAppLink] = useState(androidAppUrl);
  useEffect(() => {
    if (!WP_DEFINE_IS_NODE && isIOS) {
      setAppLink(iosAppUrl);
    }
  }, [iosAppUrl]);

  return (
    <div className={className}>
      <Wrapper.MD>
        {free2GoAppIsReleased ? (
          <div className="content-block">
            <header>
              <TextNew.Loud
                cid="title"
                localeId="free2GoLanding.released.title"
                element={ElementTypes.H1}
              />
            </header>
            <TextNew.PrimaryOriginal cid="body" localeId="free2GoLanding.released.description" />
            <Button href={appLink} ripple>
              <LocaleMessage id="free2GoLanding.released.buttonLabel" />
            </Button>
          </div>
        ) : (
          <div className="content-block">
            <header>
              <TextNew.Loud
                cid="title"
                localeId="free2GoLanding.comingSoon.title"
                element={ElementTypes.H1}
              />
            </header>
            <TextNew.PrimaryOriginal cid="body" localeId="free2GoLanding.comingSoon.description" />
          </div>
        )}
      </Wrapper.MD>
    </div>
  );
};

Free2GoLanding.propTypes = {
  free2GoAppIsReleased: PropTypes.bool.isRequired,
  androidAppUrl: PropTypes.string,
  iosAppUrl: PropTypes.string,
};

export default withFunctionalClassName(ComponentType.TEMPLATE, 'Free2GoLanding')(Free2GoLanding);
