import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import FormNames from 'common/src/app/data/enum/FormNames';
import Pages from 'common/src/app/data/enum/Pages';
import prependWebHost from 'common/src/app/util/prependWebHost';
import WebHost from 'common/src/app/data/enum/WebHost';
import { getValue } from 'common/src/app/util/injector';
import { AUTHENTICATION_MANAGER } from 'common/src/app/data/Injectables';
import { getAccount } from 'common/src/app/actions/resources/accountActions';
import AccountState from 'common/src/app/data/enum/AccountState';

import AlreadyLoggedIn from './AlreadyLoggedIn';

const enhanced = enhancedReduxForm({
  form: FormNames.LOGGED_IN,
  onSubmit: (values, dispatch, { accountRegisterPath }) =>
    getValue(AUTHENTICATION_MANAGER).logout(accountRegisterPath),
});

const mapDispatchToProps = dispatch => ({
  getMemberType: userId => dispatch(getAccount(false, userId)),
});

const mapStateToProps = state => {
  const { email, sub } = state?.authentication?.userInfo || {};
  const config = state.config.environmentConfig || {};
  const name = state?.identity?.user?.name;
  const memberPath = config ? prependWebHost(config, Pages.HOME, WebHost.MEMBER) : '';
  const accountHost = state.config.environmentConfig.web[WebHost.ACCOUNT].host;

  const accountRegisterPath = `${accountHost}${Pages.REGISTRATION}`;

  const account = state?.entities?.account?.[sub];
  const memberType = account && account.memberType;
  const neverPaid = account && account.accountState === AccountState.NONE;

  return {
    memberPath,
    accountRegisterPath,
    name,
    userName: email,
    memberType,
    userId: sub,
    neverPaid,
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected, enhanced)(AlreadyLoggedIn);
