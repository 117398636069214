/* global WP_DEFINE_IS_NODE */
import debugLib from 'debug';
import jwtDecode from 'jwt-decode';
import { USER_PERMISSION_TOKEN_COOKIE } from '../../server/util/AuthenticationHelper/constants';
import { userAccountSelector } from '../selectors/userAccountSelectors';
import { subscriptionTypeSelector } from '../selectors/accountStateSelectors';
import { MemberTypeStrings } from '../data/enum/MemberType';

const debug = debugLib('SlimmingWorld:userPermissionStateUtil');

/**
 * For now all micro services has the subscription type 'online'
 *
 * @param accessToken
 * @returns {{subscriptionType: string}}
 */
// eslint-disable-next-line no-unused-vars
export const getUserPermissionStateData = accessToken => {
  const {
    account_state: accountState,
    role: roles,
    sub: userId,
    member_type: memberType,
    profile_complete: profileComplete,
  } = jwtDecode(accessToken);

  if (!accountState) {
    debug('No account_state detected');
  }

  return {
    userId,
    accountState,
    roles,
    subscriptionType: getAccountType(accountState),
    memberType,
    profileComplete,
  };
};

export const getUserPermissionStoredData = ({ getState, req }) => {
  // Get the data from the cookie
  const cookieData = req?.signedCookies?.[USER_PERMISSION_TOKEN_COOKIE];

  if (WP_DEFINE_IS_NODE && cookieData) {
    return {
      userId: cookieData.userId,
      accountState: cookieData.accountState,
      roles: cookieData.roles,
      subscriptionType: getAccountType(cookieData.accountState),
      memberType: cookieData.memberType,
      profileComplete: cookieData.profileComplete,
    };
  }

  const userPermissionStateData = getState().authentication?.userPermissionState;

  if (userPermissionStateData) {
    return {
      userId: userPermissionStateData.userId,
      accountState: userPermissionStateData.accountState,
      roles: userPermissionStateData.roles,
      subscriptionType: getAccountType(userPermissionStateData.accountState),
      memberType: userPermissionStateData.memberType,
      profileComplete: userPermissionStateData.profileComplete,
    };
  }

  const { accountState, roles, id, memberType, profileComplete } = userAccountSelector(getState());

  return {
    userId: id,
    accountState,
    roles,
    subscriptionType: getAccountType(accountState),
    memberType,
    profileComplete,
  };
};

export const getAccountType = accountState =>
  subscriptionTypeSelector(accountState) || MemberTypeStrings.PUBLIC;
