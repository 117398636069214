import debugLib from 'debug';
import camelCase from 'lodash/camelCase';
import { LOCATION_CHANGE } from 'react-router-redux';
import InteractionType from '../../data/enum/InteractionType';
import { ADD_INTERACTION } from '../../actions/resources/interactionActions';
import { dataLayerCustomEvent } from './dataLayerUtils';
import { getEntityTypeFromRelatedEntityKind } from '../../data/enum/RelatedEntityKind';
import { isFulfilled, isInteraction } from '../actionFilters';
import { entityNameMap, entityCategoryMap } from './entityTrackingMaps';
import updateTrackingDataCategory from './updateTrackingDataCategory';
import { POST } from '../../data/entityTypes';
import Pages from '../../data/enum/Pages';
import { DataLayerKeys } from '../../data/enum/Tracking';
import {
  PAGE_LOAD_COMPLETE,
  TRACK_DOM_EVENT,
  setTrackingPersistentData,
} from '../../actions/trackingActions';
import { marketName } from '../../config/market/market.configdefinitions';
import { trackPageView } from './index';
import trackingConfig from './tracking.configdefinitions';

export const debug = debugLib('SlimmingWorld:foodActions');
let eventFired = false;

// These are the routes that we do not want to be pushed to the dataLayer
// - they are mainly the entry points into a flow
// e.g.: when the REGISTRATION entry point is hit /register/
// this will automatically route the member to step 1 in the registration flow:
// register/package-selection
const blacklistedRoutes = [
  Pages.REGISTRATION, // the entry point into online registration
  Pages.GROUP_REGISTRATION, // the entry point into group registration
  Pages.WEIGHIN, // the entry point into the weighin flow
  Pages.EDIT_WEIGHIN, // the entry point into editing a weighin
  Pages.ADD_WEIGHIN, // the entry point for adding a missed weighin
  Pages.ACCOUNT_SETTINGS_WEIGHT_LOSS_JOURNEY, // the restart journey page within account settings
  Pages.RE_RECOVER_EMAIL, // the entry point into email recovery
  Pages.NEW_GROUP_JOURNEY, // the entry point into group start journey
];

const sharedListeners = (actionType = PAGE_LOAD_COMPLETE) => [
  /**
   * Initialisation phase
   */
  {
    listener: {
      actionType: LOCATION_CHANGE,
      filter: () => !eventFired,
    },
    callback: (_, getState, dispatch) => {
      dispatch(
        setTrackingPersistentData({
          [DataLayerKeys.MARKET]: marketName,
          [DataLayerKeys.PAGE_SOURCE]: trackingConfig.dataLayerSitePrefix,
        }),
      );

      eventFired = true;
    },
  },
  /**
   * PageView
   */
  {
    listener: { actionType },
    callback: (actionData, getState, dispatch) => {
      const state = getState();
      const location = state.routeHistory[0];

      // If the route pathname is on the blacklist
      // - the page view will not be pushed to the datalayer
      if (blacklistedRoutes.includes(location.pathname)) {
        return;
      }

      const { locationBeforeTransitions } = state.routing;
      const isModal = locationBeforeTransitions.query.modal || location.query.modal;

      // If routeHistory length is 1 or smaller, we're at the landing page, so use the
      // original referrer. Otherwise, take the previous page we were on.
      let { referrer } = document;
      if (state.routeHistory.length > 1) {
        const historyEntry = state.routeHistory[1];
        referrer = `${document.location.origin}${historyEntry.pathname.replace(/\/$/gi, '')}${
          historyEntry.search
        }${historyEntry.hash}`;
      }

      const url = `${document.location.origin}${location.pathname}${location.search}${location.hash}`;
      const pathname = isModal ? getState().tracking.persistentData.pathname : location.pathname;

      const { data } = state.tracking.pageData.find(page => page.pathname === pathname) || {
        data: {},
      };

      dispatch(setTrackingPersistentData({ [DataLayerKeys.PATHNAME]: pathname }));

      if (pathname) {
        const sanitizedPathname = pathname.replace(/\/$/gi, '');
        const sanitizedUrl = url.replace(/\/$/gi, '');

        dispatch(
          setTrackingPersistentData({
            [DataLayerKeys.PATHNAME]: sanitizedPathname,
            [DataLayerKeys.REFERRER]: referrer,
            [DataLayerKeys.PAGE_ROUTE]: `/${trackingConfig.dataLayerSitePrefix}${sanitizedPathname}`,
            [DataLayerKeys.FULL_URL]: sanitizedUrl,
            [DataLayerKeys.PAGE_URL]: sanitizedPathname,
            [DataLayerKeys.PAGE_TITLE]: document.title,
          }),
        );
      }

      trackPageView(data, getState());
    },
  },
  /**
   * Track DOM event
   */
  {
    listener: { actionType: TRACK_DOM_EVENT },
    callback: ({ meta }, getState) => dataLayerCustomEvent({ ...meta }, getState()),
  },
  /**
   * Track bookmark interaction
   */
  {
    listener: {
      actionType: ADD_INTERACTION,
      filter: [isFulfilled, isInteraction(InteractionType.BOOKMARK)],
    },
    callback: (
      { meta: { interactionSubjectId, interactionSubjectKind, interactionSubjectTitle } },
      getState,
    ) => {
      const entityType = getEntityTypeFromRelatedEntityKind(interactionSubjectKind);
      let trackingData = {
        category: entityCategoryMap[entityType],
        trackingName: [entityNameMap[entityType]],
      };

      if (entityType === POST) {
        trackingData = updateTrackingDataCategory(trackingData, getState);
      }

      dataLayerCustomEvent(
        {
          category: entityType,
          entityId: interactionSubjectId,
          [trackingData.trackingName]: interactionSubjectTitle,
          [camelCase(`${DataLayerKeys.BOOKMARK}-${trackingData.category}`)]: 1,
        },
        getState(),
      );
    },
  },
];

export default sharedListeners;
