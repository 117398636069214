import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { getLastDayOfMonth } from 'common/src/app/util/dateUtils';
import InputType from 'common/src/app/data/enum/InputType';
import FormGroup from '../../../../../../molecules/FormGroup';
import InputNew from '../../../atoms/InputNew';

import './date-input-group.scss';

type FieldSetProps = React.DetailedHTMLProps<
  React.FieldsetHTMLAttributes<HTMLFieldSetElement>,
  HTMLFieldSetElement
>;

type DateInputGroupProps = FieldSetProps & {
  onDateChange: (date?: dayjs.Dayjs) => void;
  date?: dayjs.Dayjs;
  yearMinLength?: boolean;
};

type DateItem = {
  day?: number;
  month?: number;
  year?: number;
};

const LONGEST_MONTH = 31;
const MINIMUM_YEAR = 1900;
const MAXIMUM_YEAR = 2900;

const DateInputGroup = (
  { onDateChange, date, yearMinLength, ...fieldSetProps }: DateInputGroupProps,
  _context: unknown,
  className: string,
  dataTestId: string,
): JSX.Element => {
  const [dateFromProps, setDateFromProps] = useState(date);
  const [currentDateValues, setCurrentDateValues] = useState<DateItem>({
    day: date?.date(),
    month: date ? date.month() + 1 : undefined,
    year: date?.year(),
  });
  const [maxDay, setMaxDay] = useState<number>(
    () => getLastDayOfMonth(date?.year(), date?.month()) ?? LONGEST_MONTH,
  );

  useEffect(() => {
    if (date?.isValid() && !date.isSame(dateFromProps)) {
      setDateFromProps(date);
      setCurrentDateValues({
        day: date.date(),
        month: date.month() + 1,
        year: date.year(),
      });
    }
  }, [date]);

  const validateDateTimeObject = (dateItem: DateItem): DateItem => {
    const { day, month, year } = dateItem;
    const maxDayOfMonth = getLastDayOfMonth(year, month) ?? LONGEST_MONTH;

    const newDateItem: DateItem = {
      day: day && day >= 1 && day <= maxDayOfMonth ? day : undefined,
      month: month && month >= 1 && month <= 12 ? month : undefined,
      year: year && year >= MINIMUM_YEAR && year <= MAXIMUM_YEAR ? year : undefined,
    };

    return newDateItem;
  };

  useEffect(() => {
    setMaxDay(getLastDayOfMonth(currentDateValues.year, currentDateValues.month) ?? LONGEST_MONTH);

    if (onDateChange) {
      if (
        currentDateValues.year === undefined &&
        currentDateValues.month === undefined &&
        currentDateValues.day === undefined
      ) {
        setDateFromProps(undefined);
        onDateChange(undefined);
        return;
      }

      const { day, month, year } = validateDateTimeObject(currentDateValues);
      // When the year passed to Dayjs is undefined, it substitutes in 2001
      const newDate = dayjs(year !== undefined ? `${year}-${month}-${day}` : 'invalid');
      if (!newDate.isSame(date)) {
        setDateFromProps(newDate);
        onDateChange(newDate);
      }
    }
  }, [currentDateValues]);

  const handleOnChange = (onChangeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = onChangeEvent.target;
    const value = Number.parseInt(eventTarget.value, 10);
    setCurrentDateValues(prev => ({
      ...prev,
      [eventTarget.name]: Number.isNaN(value) ? undefined : value,
    }));
  };

  const { day, month, year } = currentDateValues;

  return (
    <FormGroup type="inline" className={className} {...fieldSetProps}>
      <div className="dob-inputs" data-testid={dataTestId}>
        <InputNew
          value={day || ''}
          placeholder="Day"
          type={InputType.NUMBER}
          name="day"
          onChange={handleOnChange}
          min="1"
          max={maxDay}
          id={`${dataTestId}-day`}
          data-testid="day"
          title="day"
          aria-label="day"
          data-hj-suppress={true}
          field={undefined}
        />
        <InputNew
          value={month || ''}
          placeholder="Month"
          type={InputType.NUMBER}
          name="month"
          onChange={handleOnChange}
          min="1"
          max="12"
          id={`${dataTestId}-month`}
          data-testid="month"
          title="month"
          aria-label="month"
          data-hj-suppress={true}
          field={undefined}
        />
        <InputNew
          value={year || ''}
          placeholder="Year"
          type={InputType.NUMBER}
          name="year"
          onChange={handleOnChange}
          min={MINIMUM_YEAR}
          max={MAXIMUM_YEAR}
          id={`${dataTestId}-year`}
          data-testid="year"
          minLength={yearMinLength && '4'}
          title="year"
          aria-label="year"
          data-hj-suppress={true}
          field={undefined}
        />
      </div>
    </FormGroup>
  );
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'DateInputGroup')(DateInputGroup);
