import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { formValueSelector, change } from 'redux-form';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { createPackagePurchase, getPackages } from 'common/src/app/actions/resources/shopActions';
import { getSubscriptionPackageFeatures } from 'common/src/app/actions/resources/accountActions';

import enhancedReduxForm from 'common/src/app/enhanced-redux-form';
import createSimpleRequiredValidation from 'common/src/app/validation/validationUtils';
import Configuration from 'common/src/app/config/Configuration';
import {
  voucherSelector,
  makePackagesWithDiscountSelector,
} from 'common/src/app/selectors/packageVoucherSelectors';
import { getSubscriptionPackageFeaturesSelector } from 'common/src/app/selectors/subscriptionPackageFeaturesSelector';
import { startPayment, savePurchaseInfo } from 'common/src/app/actions/resources/paymentActions';
import {
  handleSubmitErrors,
  clearValidation,
} from 'common/src/app/enhanced-redux-form/actions/enhancedFormActions';
import { applyGiftedTime } from 'common/src/app/actions/resources/voucherActions';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import FormNames from 'common/src/app/data/enum/FormNames';
import Pages from 'common/src/app/data/enum/Pages';
import {
  saveSelectedSubscription,
  toggleInitialSelectedVoucher,
} from 'common/src/app/actions/registrationActions';

import Checkout from './Checkout';

const EMPTY_OBJECT = {};
const SKIP_REASON_VOUCHER = 'voucher';

/**
 * Validations
 */
const checkoutValidation = {
  ...(Configuration.hasRegionPicker && createSimpleRequiredValidation(shopFields.REGION)),
  ...createSimpleRequiredValidation(shopFields.PACKAGE),
};

const enhancer = enhancedReduxForm(
  {
    form: FormNames.CHECKOUT,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: (values, dispatch, { isInWizard, voucherGift, voucherCode }) => {
      if (!isInWizard) {
        if (voucherGift?.duration) {
          return dispatch(applyGiftedTime(voucherCode))
            .then(() => {
              document.location.href = `${Pages.REGISTRATION_FINISHED}?skipReason=${SKIP_REASON_VOUCHER}`;
            })
            .catch(({ response }) => {
              const {
                parsed: { error },
              } = response;

              dispatch(handleSubmitErrors(FormNames.CHECKOUT, { message: error?.message }));
            });
        }

        dispatch(
          createPackagePurchase(null, values[shopFields.PACKAGE], values[shopFields.VOUCHER_CODE]),
        ).then(result => {
          savePurchaseInfo(result.data, {
            [shopFields.PACKAGE]: values[shopFields.PACKAGE],
            [shopFields.VOUCHER_CODE]: values[shopFields.VOUCHER_CODE],
            [shopFields.REGION]: values[shopFields.REGION],
          });

          return dispatch(startPayment());
        });
      }

      dispatch(saveSelectedSubscription(values[shopFields.PACKAGE]));
      dispatch(change(FormNames.VOUCHER, shopFields.VOUCHER_CODE, null));

      if (values[shopFields.VOUCHER_CODE]) {
        dispatch(toggleInitialSelectedVoucher());
      }

      return values;
    },
  },
  checkoutValidation,
);

const mapStateToProps = () => {
  const checkoutFormSelector = formValueSelector(FormNames.CHECKOUT);
  const packagesWithDiscountSelector = makePackagesWithDiscountSelector();

  return state => {
    const voucherCode = checkoutFormSelector(state, shopFields.VOUCHER_CODE);
    const voucher = voucherSelector(state, voucherCode);
    const selectedPackage = checkoutFormSelector(state, shopFields.PACKAGE);

    const regionCode = Configuration.hasRegionPicker
      ? checkoutFormSelector(state, shopFields.REGION)
      : Configuration.defaultRegion;

    // Filter the subscription packages
    // - so that we only have bronze, silver and gold
    const packages = packagesWithDiscountSelector(state, regionCode)?.filter(
      item =>
        item?.name?.toLowerCase() === 'bronze' ||
        item?.name?.toLowerCase() === 'silver' ||
        item?.name?.toLowerCase() === 'gold',
    );

    const hasDiscount = packages.some(p => p?.discount);

    // The package data for the online registration comparison table
    const packageFeatures = getSubscriptionPackageFeaturesSelector(state);

    return {
      packages,
      selectedPackage,
      hasDiscount,
      voucherGift: voucher?.gift,
      voucherCode,
      regionCode,
      wizardSubmitting: !!state.enhancedForm?.wizard?.onlineRegistration?.submitting,
      packageFeatures: packageFeatures || EMPTY_OBJECT,

      // initial form values
      initialValues: {
        [shopFields.REGION]: Configuration.defaultRegion,
        [shopFields.PACKAGE]: packages?.[0]?.id,
      },
    };
  };
};

const mapDispatchToProps = dispatch => ({
  clearVoucherCodeErrors: () =>
    dispatch(clearValidation(FormNames.CHECKOUT, [shopFields.VOUCHER_CODE_EDIT])),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction({
  prepared: (_, dispatch) =>
    Promise.all([
      dispatch(getPackages({ regionCode: Configuration.defaultRegion })),
      dispatch(getSubscriptionPackageFeatures()),
    ]),
})(FormNames.CHECKOUT);

export default compose(addInitAction, connector, enhancer)(Checkout);
