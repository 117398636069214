/**
 * Creates parsering chop table for mention related content
 * @param {Text string which represents the text containing mention objects to chop} textToChop
 */
const chopMemberNames = textToChop => {
  const startSymbol = '<@!';
  const endSymbol = '>';
  const paddingSize = startSymbol.length + endSymbol.length;
  const chopResult = [];

  if (!textToChop) return chopResult;

  const text = textToChop;

  let from = 0;
  const block = text.split(startSymbol);
  block.forEach(element => {
    const i = element.indexOf(endSymbol);
    if (i > 0) {
      const id = element.slice(0, i);
      const size = id.length + paddingSize;
      chopResult.push({ id, from, size });
      from += startSymbol.length;
    }
    from += element.length;
  });

  return chopResult;
};

export default chopMemberNames;
