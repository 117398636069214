const CommonValues = {
  FALSE: 'FALSE',
  TRUE: 'TRUE',
  PASS: 'PASS',
  FAIL: 'FAIL',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  VOUCHER_CODE_USED: 'voucherCodeUsed',
  PACKAGE_SELECTED: 'packageSelected',
  PACKAGE_ADD_ON_SELECTED: 'packageAddOnSelected',
  POST_ID: 'postId',
  TITLE: 'title',
};

export default CommonValues;
