import { GATEWAY_DEAL, GATEWAY_ACCOUNT_AUTH } from '../../data/Injectables';
import { VOUCHER } from '../../data/entityTypes';
import apiGetEntity from './apiActions/apiGetEntity';
import { apiPost } from './apiActions/apiRequest';

export const APPLY_VOUCHER = 'voucherActions/APPLY_VOUCHER';
export const APPLY_GIFTED_TIME = 'voucherActions/APPLY_GIFTED_TIME';

export const applyVoucher = voucherCode => (dispatch, getState) => {
  const accessToken = getState()?.authentication?.accessToken;
  const requestOptions = accessToken && {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return dispatch(
    apiGetEntity(
      APPLY_VOUCHER,
      GATEWAY_DEAL,
      `/vouchers/${voucherCode.trim()}`,
      VOUCHER,
      {
        findEntity: entity => entity.code === voucherCode.toLowerCase(),
        getId: entity => entity.code.toLowerCase(),
      },
      {
        caching: false,
        requestOptions,
      },
    ),
  );
};

export const applyGiftedTime = voucherCode => (dispatch, getState) => {
  const accessToken = getState()?.authentication?.accessToken;
  const options = accessToken && {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return dispatch(
    apiPost(
      APPLY_GIFTED_TIME,
      GATEWAY_ACCOUNT_AUTH,
      `/vouchers/apply-gifted-time-voucher`,
      {
        voucherCode,
      },
      options,
    ),
  );
};
