import { countryCode } from 'common/src/app/data/enum/Countries';

export const regionCode = {
  US: 0,
  UK: 1,
  EUROPE: 2,
  ROI: 3,
  OUTSIDE_EUROPE: 255,
};

export const regionCodeToString = {
  [regionCode.US]: 'us',
  [regionCode.UK]: 'uk',
  [regionCode.EUROPE]: 'europe',
  [regionCode.ROI]: 'roi',
  [regionCode.OUTSIDE_EUROPE]: 'outside',
};

export const packagesRegionCode = {
  UK: regionCode.UK,
  EUROPE: regionCode.EUROPE,
  OUTSIDE_EUROPE: regionCode.OUTSIDE_EUROPE,
};

// Region codes for group registration
export const groupRegistrationRegionCode = {
  UK: regionCode.UK,
  ROI: regionCode.ROI,
  OUTSIDE_EUROPE: regionCode.OUTSIDE_EUROPE,
};

// Map the region code to a country code
export const regionCodeToCountryCode = {
  [regionCode.UK]: countryCode.GB,
  [regionCode.ROI]: countryCode.IRELAND,
  [regionCode.OUTSIDE_EUROPE]: countryCode.DEFAULT,
};
