import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import { COMPONENT } from 'common/src/app/data/entityTypes';
import { getComponent } from 'common/src/app/actions/resources/componentActions';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import enhancedFormWizard from 'common/src/app/enhanced-redux-form/enhancedFormWizard';
import Pages from 'common/src/app/data/enum/Pages';
import { setRegistrationFlowType } from 'common/src/app/actions/registrationActions';
import { createAccount, getAccountState } from 'common/src/app/actions/resources/accountActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import { ONLINE } from 'common/src/app/data/enum/registrationFlowTypes';
import ReactRouterWizardRoutingAdapter from 'common/src/app/enhanced-redux-form/enhancedFormWizard/ReactRouterWizardRoutingAdapter';
import { API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND } from 'common/src/app/data/apiComponents';
import RegistrationTemplate from 'components/organisms/RegistrationTemplate';
import OnlineRegistration from './OnlineRegistration';

const enhanced = enhancedFormWizard({
  name: FormNames.ONLINE_REGISTRATION,
  routingAdapter: new ReactRouterWizardRoutingAdapter(),
  onSubmit: (dispatch, values) => {
    const processedValues = Object.keys(values).reduce((processed, field) => {
      const value = values[field];
      // flatten objects (created by FormSection)
      if (typeof value === 'object') {
        return {
          ...processed,
          ...value,
        };
      }
      processed[field] = value; // eslint-disable-line no-param-reassign
      return processed;
    }, {});

    return dispatch(
      createAccount(
        processedValues,
        Pages.REGISTRATION_CHECKOUT,
        Pages.REGISTRATION_FINISHED,
        false,
      ),
    ).then(() => dispatch(setRegistrationFlowType(null)));
  },
  generalErrorMessage: { locale: 'onlineRegistration.generalErrorMessage' },
});

const mapStateToProps = state => ({
  publicHost: state.config.environmentConfig.web.public.host,
  background: state.entities?.[COMPONENT]?.[API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND]?.image,
});

const mapDispatchToProps = dispatch => ({
  setFlowType: () => dispatch(setRegistrationFlowType(ONLINE)),
});

const addInitAction = withInitAction({
  prepared: ({}, dispatch) =>
    Promise.all([
      dispatch(getAccountState()),
      dispatch(getComponent(API_COMPONENT_ONLINE_REGISTRATION_BACKGROUND)),
      dispatch(prepareComponent(RegistrationTemplate)),
    ]),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(addInitAction, connected, enhanced)(OnlineRegistration);
