/* global 'molecule','GroupRegRegionPicker' */
import React from 'react';
import { shopFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { groupRegistrationRegionCode, regionCodeToString } from 'common/src/app/data/enum/Regions';
import LocaleMessage from '../../atoms/LocaleMessage';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import FormGroup from '../FormGroup';
import RadioButtonGroup from '../RadioButtonGroup';
import TextNew, { ElementTypes } from '../../atoms/TextNew';
import RadioButton from '../../atoms/RadioButton';

import './group-reg-region-picker.scss';

/**
 * Component to choose between regions
 */

const GroupRegRegionPicker = ({ ...restProps }, context, className) => (
  <div className={className}>
    <FormGroup label={<LocaleMessage id="groupRegRegionPicker.label" />} cid="gr-region-picker">
      <RadioButtonGroup {...restProps}>
        <ul className="regions-list">
          {Object.keys(groupRegistrationRegionCode).map(region => (
            <TextNew.FormalOriginal element={ElementTypes.LI} key={region}>
              <div className="region-item-container">
                <LocaleMessage
                  id={`groupRegRegionPicker.regions.${
                    regionCodeToString[groupRegistrationRegionCode[region]]
                  }`}
                />
                <RadioButton value={groupRegistrationRegionCode[region]} suppress />
              </div>
            </TextNew.FormalOriginal>
          ))}
        </ul>
      </RadioButtonGroup>
      <FormErrorMessages fields={[shopFields.REGION]} />
    </FormGroup>
  </div>
);

export default withFunctionalClassName('molecule','GroupRegRegionPicker')(GroupRegRegionPicker);
