import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import InputType from 'common/src/app/data/enum/InputType';
import { addressFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';

// The full list of countries
import countries from 'common/src/app/data/countrydata';

// Limited set of countries
// - currently only set to GB, CY and IE
// - used within the moveToGroup flow
import voucherDeliveryCountriesData from 'common/src/app/data/voucherDeliveryCountriesData';

import { countryCode } from 'common/src/app/data/enum/Countries';

import InputNew from '../../atoms/InputNew';
import FormGroup from '../../../../../molecules/FormGroup';
import Select from '../../../../Select';
import Counties from './components/atoms/Counties';

/**
 * Address form group component that creates a set of inputs
 */

/** Fields that return a list of options and/or change depending on the
 * country.
 * For example if you selected Ireland as your country the list of
 * countries could change as a result of that
 */
const dynamaicFields = [addressFields.COUNTY, addressFields.COUNTRY, addressFields.STATE];

const AddressFormGroup = (
  { fields, values, defaultCountry, formProps, errorMessage, label, isVoucherDeliveryAddress },
  { getMessage },
  className,
) => {
  const [activeCountry, setActiveCountry] = useState(countryCode.GB);

  const postalLocale =
    activeCountry && countryCode && Object.values(countryCode).includes(activeCountry)
      ? `zip.${activeCountry.toLowerCase()}`
      : 'zip.default';

  useEffect(() => {
    /** Set default country so that the postalLocale & country/state input adjusts correctly * */
    setActiveCountry(defaultCountry);
    return () => setActiveCountry(defaultCountry);
  }, [defaultCountry]);

  const getSelect = (field, index) => {
    if (field === addressFields.COUNTY || field === addressFields.STATE) {
      return (
        <Counties
          activeCountry={activeCountry}
          county={values[field]}
          formProps={formProps}
          errorMessage={errorMessage}
          key={`contact-details-counties-${index}`}
        />
      );
    }

    return (
      <Select
        name={addressFields.COUNTRY}
        options={isVoucherDeliveryAddress ? voucherDeliveryCountriesData : countries}
        value={values[field]}
        placeholder={getMessage(`addressFormGroup.placeholders.${field}`)}
        suppress
        key={`contact-details-country-list-${index}`}
        {...formProps}
      />
    );
  };

  return (
    <FormGroup type="stacked" className={className} label={label}>
      {fields?.map((field, index) =>
        dynamaicFields.includes(field) ? (
          getSelect(field, index)
        ) : (
          <Fragment key={`container_${field}_${index}`}>
            <Field
              component={InputNew}
              stacked
              name={field}
              value={values && values[field]}
              type={InputType.TEXT}
              data-hj-suppress={true}
              placeholder={getMessage(
                `addressFormGroup.placeholders.${
                  field === addressFields.ZIP_OR_POSTAL ? postalLocale : field
                }`,
              )}
              aria-label={getMessage(
                `addressFormGroup.placeholders.${
                  field === addressFields.ZIP_OR_POSTAL ? postalLocale : field
                }`,
              )}
              {...formProps}
              data-testid={field}
              key={`field_${field}_${index}`}
            />
            {errorMessage &&
              React.cloneElement(errorMessage, {
                errorName: getMessage(
                  `addressFormGroup.placeholders.${
                    field === addressFields.ZIP_OR_POSTAL ? postalLocale : field
                  }`,
                ),
                name: field,
              })}
          </Fragment>
        ),
      )}
    </FormGroup>
  );
};

AddressFormGroup.defaultProps = {
  fields: [
    addressFields.COUNTRY,
    addressFields.ADDRESS_LINE_1,
    addressFields.ADDRESS_LINE_2,
    addressFields.ADDRESS_LINE_3,
    addressFields.CITY_OR_TOWN,
    addressFields.COUNTY,
    addressFields.ZIP_OR_POSTAL,
  ],
};

AddressFormGroup.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

AddressFormGroup.propTypes = {
  fields: PropTypes.array,
  errorMessage: PropTypes.node,
  values: PropTypes.object,
  formProps: PropTypes.object,
  defaultCountry: PropTypes.string,
  label: PropTypes.string,
  // This indicates if we are asking the member for:
  // - their address - to be used to send them a voucher for group
  // - this will currently only be true for the moveToGroup transfer flow
  isVoucherDeliveryAddress: PropTypes.bool,
};

export default AddressFormGroup;
