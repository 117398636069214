import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import Pages from 'common/src/app/data/enum/Pages';
import WebHost from 'common/src/app/data/enum/WebHost';
import membershipCardCheckEnum from 'common/src/app/data/membershipCardCheckEnum';
import RegistrationError from './RegistrationError';

const mapStateToProps = () => state => {
  const accountHost = state.config.environmentConfig.web[WebHost.ACCOUNT].host;
  const accountLoginUrl = `${accountHost}${Pages.LOGIN}`;
  const memberHost = state.config.environmentConfig.web.member.host;
  // The subscription status is where the member can update their card and pin
  const updateGroupCardUrl = `${memberHost}${Pages.ACCOUNT_SETTINGS_SUBSCRIPTION_STATUS}`;
  const { query } = state.routing.locationBeforeTransitions;

  return {
    accountLoginUrl,
    updateGroupCardUrl,
    updateGroupCard: query?.flow === `${membershipCardCheckEnum.EXISTING_ACCOUNT_NEW_CARD}`,
  };
};

const connected = connect(mapStateToProps);

export default compose(connected)(RegistrationError);
