import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import EnhancedField from 'common/src/app/enhanced-redux-form/components/EnhancedField';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { termsFields } from 'common/src/app/data/enum/FieldNames/AccountFieldNames';
import Pages from 'common/src/app/data/enum/Pages';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import termsAgreement from 'common/src/app/data/termsAgreement';
import Row from '../../atoms/Row';
import CheckBox from '../CheckBox';
import TextNew, { FontWeight } from '../../atoms/TextNew';
import FormErrorMessages from '../../atoms/FormErrorMessages';
import LocaleMessage from '../../atoms/LocaleMessage';

import './confirm-terms.scss';

const ConfirmTerms = (
  {
    isGroupRegister,
    showPostButton,
    showPrivacyPromise,
    showTermsAgreement,
    title,
    isFree2GoRegistration,
    forwardRef,
  },
  context,
  className,
) => {
  const memberTypeLocale = isGroupRegister ? 'group' : 'online';

  return (
    <>
      {title}
      {showPrivacyPromise && (
        <Row justifystart column className={className}>
          <TextNew.PrimaryOriginal
            localeId={`confirmTerms.privacyPromise.preButton${memberTypeLocale}`}
          >
            <Link href={Pages.PRIVACYPOLICY} target={Pages.PRIVACYPOLICY} id="privacyPromise">
              <LocaleMessage id={`confirmTerms.privacyPromise.button`} />
            </Link>
            <LocaleMessage id={`confirmTerms.privacyPromise.postButton${memberTypeLocale}`} />
          </TextNew.PrimaryOriginal>
        </Row>
      )}

      {isFree2GoRegistration && (
        <Row justifystart column className={className}>
          {Object.keys(termsAgreement.free2Go).map(section => (
            <span className="agreement" key={section}>
              <EnhancedField component={CheckBox} name={section}>
                <TextNew.PrimaryOriginal
                  localeId={`confirmTerms.${section}.preButton${memberTypeLocale}`}
                >
                  {termsAgreement[memberTypeLocale][section].includeLinkToConfirmPrivacyPromise && (
                    <>
                      <Link
                        href={Pages.PRIVACYPOLICY}
                        target={Pages.PRIVACYPOLICY}
                        id="privacyPromise"
                      >
                        <LocaleMessage id={`confirmTerms.${section}.button2`} />
                      </Link>
                      <>
                        <LocaleMessage
                          id={`confirmTerms.${section}.preButton2${memberTypeLocale}`}
                        />
                        <Link
                          href={termsAgreement[memberTypeLocale][section].url}
                          target={termsAgreement[memberTypeLocale][section].url}
                          id="termsOfUse"
                        >
                          {termsAgreement[memberTypeLocale][section].button && (
                            <LocaleMessage id={`confirmTerms.${section}.button`} />
                          )}
                        </Link>
                      </>
                    </>
                  )}
                </TextNew.PrimaryOriginal>
              </EnhancedField>
              <FormErrorMessages fields={[section]} />
            </span>
          ))}
        </Row>
      )}

      {showTermsAgreement && !isFree2GoRegistration && (
        <Row justifystart column className={className}>
          {/* Display the pre agreement messages before displaying the agreement check box's */}
          {Object.keys(termsAgreement[memberTypeLocale]).map(
            section =>
              termsAgreement[memberTypeLocale][section]?.preAgreement && (
                <div className="pre-agreement-terms" key={section}>
                  <TextNew.Sans.SM
                    weight={FontWeight.LIGHT}
                    localeId={`confirmTerms.${section}.preAgreement${memberTypeLocale}`}
                  />
                </div>
              ),
          )}

          {Object.keys(termsAgreement[memberTypeLocale]).map(section => (
            <span className="agreement" key={section}>
              <EnhancedField
                component={CheckBox}
                name={section}
                checkboxRef={section === termsFields.TERMS_OF_USE ? forwardRef : null}
              >
                <TextNew.PrimaryOriginal
                  localeId={`confirmTerms.${section}.preButton${memberTypeLocale}`}
                >
                  {termsAgreement[memberTypeLocale][section].includeLinkToConfirmPrivacyPromise && (
                    <>
                      <Link
                        href={Pages.PRIVACYPOLICY}
                        target={Pages.PRIVACYPOLICY}
                        id="privacyPromise"
                      >
                        <LocaleMessage id={`confirmTerms.${section}.button2`} />
                      </Link>
                      <>
                        <LocaleMessage
                          id={`confirmTerms.${section}.preButton2${memberTypeLocale}`}
                        />
                        <Link
                          href={termsAgreement[memberTypeLocale][section].url}
                          target={termsAgreement[memberTypeLocale][section].url}
                          id="termsOfUse"
                        >
                          {termsAgreement[memberTypeLocale][section].button && (
                            <LocaleMessage id={`confirmTerms.${section}.button`} />
                          )}
                        </Link>
                      </>
                    </>
                  )}

                  {!termsAgreement[memberTypeLocale][section].includeLinkToConfirmPrivacyPromise &&
                    termsAgreement[memberTypeLocale][section].url && (
                      <>
                        <LocaleMessage
                          id={`confirmTerms.${section}.preButton${memberTypeLocale}`}
                        />
                        <Link
                          href={termsAgreement[memberTypeLocale][section].url}
                          target={termsAgreement[memberTypeLocale][section].url}
                          id="termsOfUse"
                        >
                          {termsAgreement[memberTypeLocale][section].button && (
                            <LocaleMessage id={`confirmTerms.${section}.button`} />
                          )}
                        </Link>
                      </>
                    )}
                  {showPostButton &&
                    termsAgreement[memberTypeLocale][section][`postButton${memberTypeLocale}`] && (
                      <LocaleMessage id={`confirmTerms.${section}.postButton${memberTypeLocale}`} />
                    )}
                </TextNew.PrimaryOriginal>
              </EnhancedField>

              <FormErrorMessages fields={[section]} id="termsOfUseError" />
            </span>
          ))}
        </Row>
      )}
    </>
  );
};

ConfirmTerms.defaultProps = {
  isGroupRegister: false,
  showPostButton: true,
  showPrivacyPromise: false,
  showTermsAgreement: true,
  isFree2GoRegistration: false,
};

ConfirmTerms.propTypes = {
  isGroupRegister: PropTypes.bool.isRequired,
  showPostButton: PropTypes.bool,
  showPrivacyPromise: PropTypes.bool,
  showTermsAgreement: PropTypes.bool,
  title: PropTypes.node,
  isFree2GoRegistration: PropTypes.bool,
  forwardRef: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.MOLECULE, 'ConfirmTerms')(ConfirmTerms);
